import styled, { css } from 'styled-components';

import {
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
} from '@polichat/flamboyant';
import { isMobile } from 'react-device-detect';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0.3rem 1rem;
  padding: 0 1rem;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`;

export const SectionName = styled.h3`
  color: #666;
  font-size: 1.1rem;
  font-weight: bold;
  margin-bottom: 1rem;
`;

export const SectionContent = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;

  @media (max-width: 800px) {
    gap: 1rem;
  }
`;

export const InputsContainer = styled.div`
  display: grid;
  grid-template-columns: 0.9fr 1fr;
  column-gap: 3rem;
  row-gap: 1rem;

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    gap: 0.5rem;
  }
`;

export const InputItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  & .form-group {
    //display: auto !important;
  }

  & label {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    & i {
      margin-right: 0.8rem;
    }
  }
`;
export const ImageUploadContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  max-height: 7rem;
  margin: 0.3rem;
`;

export const MultiSelectorContainer = styled(InputItem)`
  z-index: 1000;
  &:hover,
  &:focus {
    border: none;
    outline: unset;
  }
`;

export const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0 1rem;
  & p {
    margin: 0.5rem;
    margin-left: 1rem;
    margin-right: 0;
  }

  @media (max-width: 800px) {
    width: 100%;
    justify-content: space-around;

    & p {
      margin: 0;
    }
  }
`;

export const ButtonContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: center;
`;

export const PhoneContainer = styled.div``;

export const ContrastBackgroundContainer = styled.div`
  background-color: var(--secondary-background-color);
  border-radius: 5px !important;
  padding: 3% 5%;
  display: flex;
  justify-content: space-between;
`;

export const ActionGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  ${!isMobile && 'width: 50%'};
  gap: 3rem;
  margin: 0.5rem auto 0 auto;

  & button:first-child {
    margin: 0 1rem;
  }

  @media (max-width: 600px) {
    gap: 1rem;
    & button:first-child {
      margin: 0;
    }
  }
`;

export const InlineInput = styled(Input)`
  max-width: 70px !important;
  max-height: 2em;
  display: inline;
  border-color: var(--border-color) !important;
`;

export const CheckBoxContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 600px) {
    flex-direction: column;
    margin-top: 2rem;

    & label {
      font-size: 0.9rem;
    }

    & button {
      margin-top: 1rem;
    }
  }
`;

export const RadioContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 600px) {
    flex-direction: column;
    margin-top: 2rem;

    & label {
      margin-top: 1rem;
      font-size: 0.9rem;
    }

    & button {
      margin-top: 1rem;
    }
  }
`;

export const CustomModalHeader = styled(ModalHeader)`
  & h5 {
    justify-content: center !important;
  }
  & i {
    padding: 1rem;
    margin-right: 1rem;
  }
  & button {
    margin-right: -0.5rem !important;
    margin-top: -2rem !important;
  }
`;

export const CustomModal = styled(Modal)`
  & .modal-content {
    padding: 2rem;

    & .modal-body {
      text-align: center;
      margin: 1rem;
      padding: 0;
    }
  }
`;

export const CustomModalBody = styled(ModalBody)`
  display: flex;
  justify-content: center;
`;

export const QrCodeContainer = styled.div`
  background-color: white;
  width: fit-content;
  padding: 0.5rem;
`;
