import React from 'react';
import PageMainHeader from '../../common/pages/PageMainHeader/PageMainHeader.component';
import PageMainBody from '../../common/pages/PageMainBody/PageMainBody.component';
import { bindActionCreators } from 'redux';
import * as GeneralActions from '../../../store/modules/general/actions';
import { connect } from 'react-redux';
import image from '../../../assets/images/open-box.png';

function WelcomePlan() {
  return (
    <>
      <PageMainHeader title='Planos' icon='poli-icon pi-list-customers-line' />
      <PageMainBody>
        <div className='shoppingScreenImage AnimationImage01'>
          <img className='img-fluid imagePay' src={image} alt='Caixa de planos' />
          <h2 style={{ marginTop: '45px', lineHeight: '35px' }}>
            Explore seus planos
          </h2>
        </div>
      </PageMainBody>
    </>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(GeneralActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(WelcomePlan);
