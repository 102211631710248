import { Button, Row, Col } from '@polichat/flamboyant';

import history from 'src/services/history';

import Illustration from 'src/assets/images/illustrations/documentation-step-channel.svg';

interface Step3Props {
  channel_id: number;
}
export function Step3(props: Step3Props) {
  function handleRedirect() {
    history.push(`/tools/channel/360Dialog/${props.channel_id}`);
  }

  return (
    <div style={{ margin: '2rem' }}>
      <Row>
        <Col md={6}>
          <img src={Illustration} />
        </Col>
        <Col md={6}>
          <h4 style={{ fontSize: '1.5rem', fontWeight: 500 }}>
            Canal criado com sucesso!
          </h4>
          <p>
            O seu canal já está pronto, agora basta adicionar os usuários que
            poderão acessar esse canal por meio do botão abaixo
          </p>
        </Col>
      </Row>
      <Row>
        <Col
          md={{
            offset: 4,
            size: 6,
          }}
          sm="12"
        >
          <Button onClick={handleRedirect}>Editar usuários no Canal</Button>
        </Col>
      </Row>
    </div>
  );
}
