import { useState } from 'react';
import { ButtonType } from '../types';
import { LabelWithSpan } from '../../FormMessagesBuilder.styles';
import { ModalButton } from './ModalButton';
import {
  Button,
  Icon,
  Row,
  Col,
  FormGroup,
  Label,
  CustomInput,
} from '@polichat/flamboyant';

export interface CreateButtonButtonsMessageProps {
  onUpdate(values: any): void;
  initialValue: ButtonType[];
  setMessageButtons(value: any): void;
}

const buttonsType = [
  { id: 1, type: 'reply', description: 'Responder', limit: 3 },
  { id: 2, type: 'action', description: 'Ação', limit: 2 },
];

export function CreateButtonButtonsMessage(
  props: CreateButtonButtonsMessageProps
) {
  const messageButtons = props.initialValue;

  const [isDisabledAddButton, setIsDisabledAddButton] = useState(false);
  // const [buttonButtonsMessageType, setButtonButtonsMessageType] = useState(
  //   () => {
  //     const initialState = messageButtons?.length
  //       ? buttonsType.filter((btn) => btn.type === messageButtons[0].type)[0]
  //       : buttonsType[0];
  //     return initialState;
  //   }
  // );
  const buttonButtonsMessageType = {
    id: 1,
    type: 'reply',
    description: 'Responder',
    limit: 3,
  };

  const updateButtonData = (buttonData: any) => {
    const { id } = buttonData;
    let filteredButtons = messageButtons?.filter((btn) => btn.id !== id);
    filteredButtons.push(buttonData);
    filteredButtons = filteredButtons.sort((a, b) => {
      if (a.id < b.id) return -1;
      if (a.id > b.id) return 1;
      return 0;
    });
    props.setMessageButtons(filteredButtons);
  };

  // const handleChangeButtonType = ({ target: { name, value } }: any) => {
  //   setButtonButtonsMessageType(
  //     buttonsType.filter((btn) => btn.type === value)[0]
  //   );
  //   props.setMessageButtons([
  //     {
  //       id: new Date().getTime(),
  //       type: value,
  //       text: '',
  //       actionType: value === 'reply' ? '' : 'call',
  //       actionText: '',
  //     },
  //   ]);

  //   setIsDisabledAddButton(false);
  // };

  const handleAddButton = () => {
    if (isDisabledAddButton) return;
    props.setMessageButtons([
      ...messageButtons,
      {
        id: new Date().getTime(),
        type: buttonButtonsMessageType.type,
        text: '',
        actionType: 'call',
        actionText: '',
      },
    ]);

    messageButtons?.length + 1 >= buttonButtonsMessageType.limit &&
      setIsDisabledAddButton(true);
  };

  const handleRemoveButton = (id: any) => {
    if (messageButtons?.length <= 0) return;
    props.setMessageButtons(
      messageButtons?.filter((button) => button.id != id)
    );
    setIsDisabledAddButton(false);
  };
  return (
    <>
      <Row>
        <Col sm={12} md={6}>
          <LabelWithSpan>
            <Label>Atenção:</Label>
            <span>
              Você pode adicionar até {buttonButtonsMessageType.limit} botões
            </span>
          </LabelWithSpan>
        </Col>
      </Row>
      {messageButtons?.map((button) => (
        <Row key={button.id}>
          <ModalButton
            id={button.id}
            removeButton={handleRemoveButton}
            onUpdate={updateButtonData}
            initialValue={button}
            isEdit={false}
          />
        </Row>
      ))}
      <Button
        color="secondary-link"
        onClick={handleAddButton}
        disabled={false || isDisabledAddButton}
      >
        <Icon
          icon="poli-icon pi-add-circle-line"
          size={20}
          position={'relative'}
          top={'2px'}
          right={'2px'}
        />
        <span>Adicionar botão</span>
      </Button>
    </>
  );
}
