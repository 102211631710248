import { useState } from 'react';
import { additionalPlans } from '../../../../../services/tools/channels/additionalPlans/request';

import { DtoHireAdditionalChannel } from '../../../../../services/tools/channels/additionalPlans/dto';

export function useCustomerHireAdditionalChannel() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [hiredChannelInfo, setHiredChannelInfo] = useState<
    DtoHireAdditionalChannel | undefined
  >();

  // ---------------------------------------------
  // Functions
  async function hireAdditionalChannels(
    customer_id: number,
    additional_id: number,
    quantity: number
  ) {
    try {
      setLoading(true);
      setError(false);
      const result = await additionalPlans.postBuyAdditionalChannel({
        customer_id,
        additional_id,
        quantity,
      });
      if (!result.success) {
        setError(true);
      }
      setHiredChannelInfo(result);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  }
  // ---------------------------------------------
  // Effects
  // ---------------------------------------------
  // Transformations
  // ---------------------------------------------
  // API

  return {
    loading,
    error,
    hiredChannelInfo,
    hireAdditionalChannels,
  };
}
