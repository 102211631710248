import React, { useRef, useContext } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ChatActions from '../../../../../store/modules/chat/actions';

import { Button, Icon } from '@polichat/flamboyant';

import { Link } from 'react-router-dom';

import CartImage from './src/CartImage';
import Avatar from '../../../../common/profile/Avatar';
import ContactName from '../../../../common/profile/ContactName';
import PayImage from './src/PayImage';
import Animation from '../../../../common/animation';

import ClickOutsideEvent from '../../../../../events/ClickOutsideEvent';

import { usePoliPayGatewaysService } from '../cart/hooks/usePoliPayGateways';
import { CustomerChannelBalanceContext } from '../../contexts/customerChannelBalance/context';

function CartChatModal({
  //variáveis do redux
  chat,
  hasCatalog,
  //actions
  closeCartChatModal,
  showCartButton,
  fetchCatalog,
  createShopppingCart,
  userData,
}) {
  const contact = chat.contact;

  const wrapperRef = useRef(null);
  ClickOutsideEvent(wrapperRef, closeCartChatModal);

  // PoliPay status and testing time
  const poliPayStatus = {
    notStarted: 0,
    active: 1,
    testing: 2,
    disable: 3,
  };

  //get context
  const { isUserAbleToSendMessage, loading: isLoadingCustomerChannelContext } =
    useContext(CustomerChannelBalanceContext);

  const handleShowCartOptions = () => {
    createShopppingCart({ contact_id: chat.id, products: [], address: null });

    closeCartChatModal();
  };

  const handleCancel = () => {
    closeCartChatModal();
  };

  const { isLoadingGateways, hasPoliPayGatewayActive } =
    usePoliPayGatewaysService();
  if (
    (hasPoliPayGatewayActive ||
      isLoadingGateways ||
      isLoadingCustomerChannelContext) &&
    isUserAbleToSendMessage
  ) {
    return (
      <div className="payment-chat-modal" id="cart-chat-modal" ref={wrapperRef}>
        <div className="payment-chat-subsection payment-chat-preview">
          <div className="icon-wrapper">
            <CartImage />
          </div>
        </div>
        {isLoadingGateways || isLoadingCustomerChannelContext ? (
          <div className="payment-chat-subsection-heading">
            <Animation size="full" icon="chat-loading" />
          </div>
        ) : (
          <>
            <div className="payment-chat-subsection-heading">
              <span className="payment-chat-title">Carrinho de:</span>
              <div className="contactInfo">
                <Avatar
                  className="contact"
                  title={contact.name}
                  url={contact.picture}
                  size={36}
                />
                <div className="contactNameContainer">
                  <ContactName name={contact.name} />
                </div>
              </div>
            </div>
            <div className="payment-chat-buttons-subsection">
              <Button
                color="secondary"
                width="total"
                className="payment-chat-button"
                onClick={handleShowCartOptions}
              >
                Criar carrinho
              </Button>
              <Button
                color="danger"
                width="total"
                className="payment-chat-button"
                onClick={handleCancel}
              >
                Cancelar
              </Button>
            </div>
          </>
        )}
      </div>
    );
  } else if (!isUserAbleToSendMessage) {
    return (
      <div
        className="payment-chat-modal"
        id="payment-chat-modal"
        ref={wrapperRef}
      >
        <PayImage />
        <p>
          Seu acesso atingiu o limite de créditos contratados no período, é
          necessário adicionar créditos extras ou aguardar o próximo mês.
          Entre em contato com nossa área comercial.
        </p>
      </div>
    );
  } else {
    return (
      <div className="payment-chat-modal" id="cart-chat-modal" ref={wrapperRef}>
        <div className="payment-chat-subsection payment-chat-preview">
          <div className="icon-wrapper">
            <CartImage />
          </div>
        </div>
        <div className="payment-chat-subsection">
          <div>
            <p>
              É necessário que a empresa possua um catálogo e formas de
              pagamento configurados para utilizar esta funcionalidade.
            </p>
            {userData.superRole === 'agent' ? (
              <p>
                Peça para algum gestor configurar, clicando no ícone do Poli
                Shopping no dashboard da plataforma.
              </p>
            ) : (
              <p>
                <Link to="/shopping/payment-settings">Clique aqui</Link> para
                ativar uma nova configuração e começar a usar o PoliPay.
              </p>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  chat: state.chat?.chats?.find((chat) => chat.open === true),
  userData: state.chat?.config?.user,
  hasCatalog: state.chat?.catalog ? true : false,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(ChatActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CartChatModal);
