import { useDispatch } from 'react-redux';
import { closeAllOpenedChats, showBlockOne } from '../../../store/modules/chat/actions';
import { ArrowLeft } from '@poli-ink/react'

export function BackToComponent() {
  const dispatch = useDispatch();

  return (
    <div
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();

        // Retira o estilo de chat ativo dos itens
        let allChatList = document.querySelectorAll('.chatListItem');
        let topMobile = document.querySelector('.topMobile')?.classList;
        let topNav = document.querySelector('.topNavigationContainer')?.classList;

        if (topMobile) {
          topMobile.remove('hidden');
        }
        if (topNav) {
          topNav.remove('hidden');
        }

        for (let item of allChatList) {
          if (item && item.classList.contains('activeChatItem')) {
            item.classList.remove('activeChatItem');
          }
        }
        //Usado para fechar os chats abertos no estado global
        dispatch(closeAllOpenedChats());

        // Usado para voltar a mostrar o bloco 1 e ocultar os demais blocos
        dispatch(showBlockOne());

        // Retirar a HASH com ID ao voltar
        if (window && window.location.hash) {
          const title = `Chats`;
          const url = window.location.pathname;
          window.history.pushState({}, title, url);
        }
      }}
      className="backMobileButton"
    >
      <ArrowLeft className="backMobileIcon" />
    </div>
  );
}
