import React, { useState, useEffect } from 'react';
import {
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  UncontrolledTooltip,
  Icon,
} from '@polichat/flamboyant';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import ChannelsService from '../../../../../services/tools/channels/channels';
import * as ChatActions from '../../../../../store/modules/chat/actions';

export function ChannelsMonitor() {
  const [allChannels, setAllChannels] = useState([]);
  const [show, setShow] = useState(false);
  const customer_id = useSelector((state) => state.general.current_customer_id);

  const toggle = () => setShow((prevState) => !prevState);

  const onGetChannels = async () => {
    const result = await ChannelsService.getAllChannels({
      customer_id,
      size: 100,
    });
    setAllChannels(result.data);
  };

  useEffect(() => {
    onGetChannels();
  }, [show]);

  let counterDisconnected = 0;
  const someOne = (
    <>
      <div>
        <div className="selectorVisitorBoxHeader plateBoxHeader">
          <span className="plateBoxName">
            <Icon icon="poli-icon pi-channels-line" />
            <span>Seus Canais</span>
          </span>
          <span className="plateBoxUpLine" onClick={toggle}>
            <Icon icon="poli-icon pi-simple-arrow-down-line" />
          </span>
        </div>
        <div className="selectorVisitorBoxBody plateBoxBody">
          <ul className="plateBoxContainer">
            {allChannels
              ? allChannels.map((channel) => {
                  // let { connection } = channel;
                  const { type, status, id, name, icon_fill } = channel;

                  // if (type === 'webchat') connection = status

                  // Canal Conectado
                  if (status === 1) {
                    return (
                      <li key={id} className="plateBoxItem channelOk">
                        <Icon icon={icon_fill} />
                        <span>{name}</span>
                        <Icon
                          icon="poli-icon pi-info-fill infoChannelIcon"
                          id={'channel' + id}
                        />
                        <UncontrolledTooltip
                          placement="bottom"
                          target={'channel' + id}
                        >
                          Canal Conectado
                        </UncontrolledTooltip>
                      </li>
                    );
                  } else {
                    // Canal desconectado
                    counterDisconnected++;
                    return (
                      <li key={id} className="plateBoxItem channelProblem">
                        <Icon icon={icon_fill} />
                        <span>{name}</span>
                        <Icon
                          icon="poli-icon pi-info-fill infoChannelIcon"
                          id={'channel' + id}
                        />
                        <UncontrolledTooltip
                          placement="bottom"
                          target={'channel' + id}
                        >
                          Canal Desconectado
                        </UncontrolledTooltip>
                      </li>
                    );
                  }
                })
              : 'Nenhum canal foi encontrado.'}
          </ul>
        </div>
      </div>
    </>
  );

  return (
    <Dropdown
      isOpen={show}
      toggle={toggle}
      className="visitorsNotification"
      size={'normal-icon'}
      color={'neutral'}
    >
      <DropdownToggle
        color={'neutral'}
        border="border-none"
        id="tooltipChannelMonitor"
        size={'normal-icon'}
        caret
      >
        <UncontrolledTooltip placement="bottom" target="tooltipChannelMonitor">
          Status dos Canais
        </UncontrolledTooltip>
        <Icon
          size={24}
          icon="poli-icon pi-channels-line"
          position={'relative'}
          top={'0px'}
          right={'0px'}
        />
        {counterDisconnected !== 0 ? (
          <span
            style={{
              position: 'absolute',
              top: '2px',
              right: '-5px',
              background: 'var(--red-polichat)',
              borderRadius: '100%',
              fontSize: '0.6rem',
              padding: '3px 0px 0px 0px',
              fontWeight: 'bold',
              color: 'var(--primary-background-color)',
              // display: 'flex',
              alignItems: 'center',
              textAlign: 'center',
              height: '20px',
              width: '20px',
              display: 'inline',
            }}
            title={`${counterDisconnected} canais desconectados`}
          >
            {counterDisconnected}
          </span>
        ) : (
          ''
        )}
      </DropdownToggle>
      <DropdownMenu className="plateBox">{someOne}</DropdownMenu>
    </Dropdown>
  );
}

const mapStateToProps = (state) => ({
  channels: state?.general?.user?.channels,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(ChatActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ChannelsMonitor);
