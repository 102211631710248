import * as React from 'react';
import { LogsChannelService } from '../../../../../../../services/tools/channels/logsChannels/logs/channels';
import { DtoData } from '../../../../../../../services/tools/channels/logsChannels/types/dtoAllChannels';

export interface useAllChannelsProps {
  customerFilter: number;
}

export function useAllChannels(props: useAllChannelsProps) {
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState<DtoData[]>();
  const showDeletedChannels = 1;

  const getChannels = React.useCallback(async (customer: number) => {
    try {
      setLoading(true);
      const response = await LogsChannelService.getAllChannels({
        customer_id: customer,
        withDeleted: showDeletedChannels,
      });
      setData(response);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  React.useEffect(() => {
    getChannels(props.customerFilter);
  }, [getChannels, props.customerFilter]);

  return { loading, data };
}
