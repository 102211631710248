import React, { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Icon,
  FormGroup,
  Label,
  CustomText,
  CustomPhoneInput,
  Button,
  Row,
  Col,
  CustomInput,
} from '@polichat/flamboyant';
import { toast } from 'react-toastify';
// import Illustration from '../../../../../../assets/images/illustrations/documentation-step-channel.svg';
import history from '../../../../../../services/history';
import ChannelsService from '../../../../../../services/tools/channels/channels';

import ButtonDoubts from '../../common/ButtonDoubts/ButtonDoubts.component';
import ActionGroupButtons from '../../ActionGroupButtons/ActionGroupButtons.component';

import ChannelAddWabaDocuments from '../../ChannelAddWaba/ChannelAddWabaDocuments';

import { Container, HeadingTextContainer } from './GatherDocuments.styles';
import { Illustration } from 'src/assets/illustratorElement/illustrators';

const GatherDocuments = () => {
  const customer_id = useSelector((state) => state.general.current_customer_id);
  const wlConfig = useSelector((state) => state?.whitelabel?.config);
  const [processDocuments, processDocumentsSet] = useState(false);

  const [id, setId] = useState('');
  const [channelName, setChannelName] = useState('');
  const [channelPhone, setChannelPhone] = useState('');
  const [disableInputs, setDisableInputs] = useState(false);

  const [migrateChannelToPoliBSP, setMigrateChannelToPoliBSP] = useState(false);

  // This variables are used to receive state of migrate from polizat to waba
  useEffect(() => {
    if (history && history?.location?.state?.data) {
      setId(history?.location?.state?.data?.id);
      setChannelName(history?.location?.state?.data?.channelName);
      setChannelPhone(history?.location?.state?.data?.channelPhone);

      setDisableInputs(true);
      return;
    }
  }, [onload]);

  const handleDocumentsSelect = () => {
    processDocumentsSet(true);
  };

  const handleProccessDocumentsNext = () => {
    processDocumentsSet(true);
    processSelectedSet(true);
  };

  const handleProccessNewNext = () => {
    processDocumentsSet(false);
    processSelectedSet(true);
  };

  const handleCloseModal = () => {
    processDocumentsSet(false);
  };

  // Actions to Actions Group Button component
  const goBack = () => history.goBack();
  const handleNextAction = async () => {
    if (!channelName || !channelName.length) {
      toast.error('O campo Nome do Canal é obrigatório');
      return;
    }

    if (!channelPhone || !channelPhone.length || channelPhone.length < 11) {
      toast.error('Telefone inválido!');
      return;
    }

    try {
      let result;

      const channelConfigs = {
        customer_id,
        channel_id: 6,
        name: channelName,
        phone: channelPhone,
        api: 10,
      };

      result = await ChannelsService.createChannel(channelConfigs);

      if (result?.status === 201 || result?.status === 200) {
        if (migrateChannelToPoliBSP) {
          history.push({
            pathname: `/tools/channels/migrate/${result.data.id}`,
          });
        } else {
          history.push({
            pathname: `/tools/channels/edit/${result.data.id}`,
            state: result.data,
          });
        }
      } else {
        if (result?.status === 400) {
          toast.error(result.data.error[0].msg);
        } else if (result?.status === 422 || result?.status === 403) {
          if (migrateChannelToPoliBSP && result.data.data.id) {
            history.push({
              pathname: `/tools/channels/migrate/${result.data.data.id}`,
            });
          } else {
            toast.error(result.data.message);
          }
        } else {
          toast.error(result.data.error.message);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  console.log('wlstate hide', wlConfig.hide_doubts_button, wlConfig.hide_channel_approval_documents_button)

  return (
    <Container>
      {!wlConfig.hide_doubts_button && <ButtonDoubts />}

      <HeadingTextContainer>
        Para iniciarmos o processo de homologação com o Facebook, precisamos de
        alguns documentos que comprovem o nome legal, endereço, representantes e
        telefone.{' '}
        <strong>
          Estas são algumas sugestões. Colete o máximo de documentação que
          conseguir, esse processo poderá ser feito posteriormente ao cadastro
          do seu canal Waba.{' '}
        </strong>
        {!wlConfig.hide_channel_approval_documents_button && <Button
          onClick={handleDocumentsSelect}
          className="py-0 px-2"
          size="sm"
          color="primary"
        >
          Documentos que você vai precisar
        </Button>}
        <ChannelAddWabaDocuments
          isOpen={processDocuments}
          handleCloseModal={handleCloseModal}
          handleProccessClassicNext={handleProccessDocumentsNext}
          handleProccessNewNext={handleProccessNewNext}
        />
      </HeadingTextContainer>

      <div data-tour={'HelpHero_Migrate_WhatsApp_Step03'}>
        <Row>
          <Col md="6">
            <FormGroup className="mt-3">
              <Label for="channelName">
                <Icon
                  icon="poli-icon pi-channels-fill"
                  color="var(--green-polichat)"
                  size={20}
                />{' '}
                Nome do Canal
              </Label>
              <CustomText
                type="text"
                name="text"
                id="channelName"
                placeholder="Ex. Ativo 1"
                value={channelName}
                onChange={(e) => setChannelName(e?.target?.value)}
                required
                maxLength={50}
                disabled={disableInputs}
              />
            </FormGroup>

            <FormGroup className="mt-5">
              <Label for="channelPhone">
                <Icon
                  icon="poli-icon pi-smartphone-fill"
                  color="var(--green-polichat)"
                  size={20}
                />{' '}
                Telefone com DDD e código de país
              </Label>
              <CustomPhoneInput
                country={'br'}
                autoFormat={true}
                value={channelPhone}
                onChange={(value) => setChannelPhone(value)}
                placeholder="+55 (62) 9999-9999"
                required
                disabled={disableInputs}
              />
            </FormGroup>
          </Col>
          <Col md="6" className="text-center">
            <Illustration imgName="documentation-step-channel" />
          </Col>
        </Row>
      </div>
      <Row>
        <br />
      </Row>
      <Row>
        <Col>
          <FormGroup check inline>
            <CustomInput
              type="checkbox"
              id="migrate_channel"
              value={migrateChannelToPoliBSP}
              onChange={() => {
                setMigrateChannelToPoliBSP((prev) => !prev);
              }}
            />
            <Label for="migrate_channel" style={{ margin: '0' }}>
              Trazer canal de outro BSP
            </Label>
          </FormGroup>
        </Col>
      </Row>

      <ActionGroupButtons onClickNext={handleNextAction} onClickBack={goBack} />
    </Container>
  );
};

export default GatherDocuments;
