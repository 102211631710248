import React from 'react';
import { Icon } from '@polichat/flamboyant';

function PageTitle({ title, icon }) {
  return (
    <div className="shoppingPageTitle">
      <Icon icon={icon} size={30} color={'var(--page-title)'} />
      <h6 style={{color:'var(--page-title)'}}>{title}</h6>
    </div>
  );
}

export default PageTitle;
