import {Col, FormGroup, Label, CustomInput} from '@polichat/flamboyant';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import WabaService from '../../../../../../services/tools/template-waba/template-waba'

function SelectLanguageWaba({ onUpdate , initialValue, isEdit}) {
  const customer_id = useSelector((state) => state.general.current_customer_id);
  const [languagesList, setLanguagesList] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState({
    language:  initialValue ? initialValue : 46
  });

  useEffect(()=>{
    async function fetchLanguagesWaba(){
      const result = await WabaService.getWabaLanguages({customer_id});
      result && setLanguagesList(result);
    }
    fetchLanguagesWaba();
  },[])

  const handleInputChange = ({target: {name, value}}) => {
    setSelectedLanguage({
      [name]: Number(value),
    });
    handleLanguageData({
      [name]: Number(value),
    })
  };

  const handleLanguageData = (data) => {
    onUpdate(data);
  }


  return(

    <Col sm={12} md={6}>
      <FormGroup>
        <Label>Idioma:</Label>
        <CustomInput
          type="select"
          id="language"
          name="language"
          value={selectedLanguage.language}
          onChange={handleInputChange}
          required
          disabled={isEdit}
          >
          {languagesList?.map(language => (
          <option key={language.id} value={language.id}
          >
            {language.language}
          </option>))}

        </CustomInput>
      </FormGroup>
    </Col>

  );
}

export default SelectLanguageWaba;
