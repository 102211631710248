import { ChatAlert, ChatAlertsUser } from '../../../types/alerts';

export const actionNames = {
  FETCH_CHAT_ALERTS_REQUEST: '@alerts/FETCH_CHAT_ALERTS_REQUEST',
  FETCH_CHAT_ALERTS_SUCCESS: '@alerts/FETCH_CHAT_ALERTS_SUCCESS',
  FETCH_CHAT_ALERTS_FAILURE: '@alerts/FETCH_CHAT_ALERTS_FAILURE',
  CLOSE_CHAT_ALERT: '@alerts/CLOSE_CHAT_ALERT',
};

/**
 * Buscar alertas
 */
export function fetchChatAlertsRequest(payload: { user: ChatAlertsUser }) {
  return {
    type: actionNames.FETCH_CHAT_ALERTS_REQUEST,
    payload,
  };
}

export function fetchChatAlertsSuccess(alertsData: ChatAlert[]) {
  return {
    type: actionNames.FETCH_CHAT_ALERTS_SUCCESS,
    payload: alertsData,
  };
}

export function fetchChatAlertsFailure() {
  return {
    type: actionNames.FETCH_CHAT_ALERTS_FAILURE,
  };
}

export function closeChatAlert(alert_id: number) {
  return {
    type: actionNames.CLOSE_CHAT_ALERT,
    payload: {
      alert_id,
    },
  };
}
