import { ChangeEventHandler, useState } from 'react';
import { Row, Col, FormGroup, Label } from '@polichat/flamboyant';

import { OptionsContainer } from '../FormMessagesBuilder.styles';

import CustomTextAreaWithEditor from '../../../common/CustomTextAreaWithEditor.component';

import { Container } from './CreateBody.styles';

import AddVariable from '../common/variable/AddVariable.component';
import { TextArea } from '../../../common/CustomTextAreaWithEditor.styles';

interface CreateBodyProps {
  onUpdate: (body: string) => void;
  initialValue: string;
  isEdit: boolean;
}

function CreateBody({ onUpdate, initialValue, isEdit }: CreateBodyProps) {
  const [bodyText, setTextData] = useState(initialValue);

  const handleInputChange = (value: string) => {
    setTextData(value);
    onUpdate(value);
  };

  const handleAddVariable = (data: { variable: string }) => {
    const { variable } = data;
    const newText = bodyText + variable;

    setTextData(newText);
    onUpdate(newText);
  };

  return (
    <Row>
      <Col sm={12}>
        <FormGroup>
          <Label>Mensagem:</Label>
          <Container>
            <CustomTextAreaWithEditor
              id="body-message"
              maxLength={1024}
              onChange={handleInputChange}
              placeholder="Corpo da mensagem"
              required
              showCharCounter
              value={bodyText}
              disabled={false}
            >
              <AddVariable onUpdate={handleAddVariable} isEdit={isEdit} />
            </CustomTextAreaWithEditor>
            <OptionsContainer></OptionsContainer>
          </Container>
        </FormGroup>
      </Col>
    </Row>
  );
}

export default CreateBody;
