import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
export interface QuickMessageFormValues {
  text: string;
  tag: string;
  shortcut?: string;
}

const validationSchema = Yup.object().shape({
  tag: Yup.string()
    .matches(/^\S*$/, 'Não pode conter espaços em branco')
    .required('Campo obrigatorio')
    .min(4, 'Minimo 4 caracteres')
    .max(50, 'Maximo 50 caracteres'),
  text: Yup.string()
    .required('Campo obrigatorio')
    .min(4, 'Minimo 4 caracteres'),
  shortcut: Yup.string().max(1, 'Máximo 1 caracter').optional().nullable(),
});

export function useQuickMessageForm() {
  const form = useForm<QuickMessageFormValues>({
    defaultValues: {
      text: '',
      tag: '',
      shortcut: '',
    },
    resolver: yupResolver(validationSchema),
  });

  return {
    ...form,
  };
}
