export type MultiSelectorOption = {
  value: number;
  label: string;
};
export function getMultiSelectorOptionArrayFromUserArray(
  data: any
): MultiSelectorOption[] {
  if (!data) return [];

  return data.map((item: any) => ({ value: item.id, label: item.name }));
}

export function getUserArrayFromSelectedOption(
  data: MultiSelectorOption[]
): number[] {
  if (!data) return [];

  return data.map((item: any) => item.value);
}
