import {
  ChangeEvent,
  FC,
  ReactComponentElement,
  ReactElement,
  ReactNode,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';

import { Button, Icon } from '@polichat/flamboyant';

import TemplateWabaService from '../../../../../../../services/tools/template-waba/template-waba';

import {
  PreviewContainer,
  ImagePreview,
  VideoPreview,
  DocumentPreview,
  Main,
  UploadingAlert,
  MediaSelector,
} from './MediaUpload.styles';
import { toast } from 'react-toastify';
import { StyledComponent } from 'styled-components';

interface MediaUploadProps {
  initialValue?: any;
  onUpdate: (media: any) => void;
  isEdit?: boolean;
}

type MediaFormat = 'IMAGE' | 'DOCUMENT' | 'VIDEO';

export default function MediaUpload({
  initialValue,
  onUpdate,
  isEdit,
}: MediaUploadProps) {
  const customer_id = useSelector(
    (state: any) => state.general?.current_customer_id
  );
  const { mediaUrl, mediaFormat } = initialValue;

  const mediaFormatList: { mediaFormat: MediaFormat; accept: string }[] = [
    { mediaFormat: 'IMAGE', accept: 'image/*' },
    { mediaFormat: 'VIDEO', accept: 'video/*' },
    { mediaFormat: 'DOCUMENT', accept: '.pdf, .doc, .docx, .xls, .xlsx' },
  ];
  const [uploading, setUploading] = useState(false);
  const [removing, setRemoving] = useState(false);
  const [localMedia, setLocalMedia] = useState(mediaUrl);
  const [localMediaFormat, setLocalMediaFormat] = useState<MediaFormat | null>(
    mediaFormat
  );

  const inputFile = useRef<HTMLInputElement>(null);

  const fileUpload = async (
    file: any
  ): Promise<{
    access_url: any;
    fileData: { fileName: string; fileSize: string; fileExtension: string };
  } | null> => {
    let { name: fileName, size: fileSize, type: fileExtension } = file;

    const lastOccurrenceIndex = fileName.lastIndexOf('.');
    const namePart = fileName.substr(0, lastOccurrenceIndex);
    const extensionPart = fileName.substr(lastOccurrenceIndex);
    //tratamento nome do arquivo
    fileName = `${namePart
      .toLowerCase()
      .normalize('NFD')
      .replace(/[^a-zA-Z0-9s]/g, '')}${extensionPart}`;
    //extensão do arquivo
    fileExtension = fileExtension.split('/')[1];
    //preview
    let preview = URL.createObjectURL(file);

    try {
      const result = await TemplateWabaService.getTemplateAwsUrlAssigned({
        customer_id,
        mediaData: {
          fileName,
          fileContent: file.type,
        },
      });

      if (!result) throw new Error('Invalid result from getSignedUrl');

      const { signed_url_request, access_url } = result;

      // const formData = new FormData();
      // formData.append('file', file, fileName);
      const configOptions = {
        headers: {
          'Content-Type': file.type,
        },
      };
      const putResult = await axios.put(
        signed_url_request,
        file,
        configOptions
      );

      return {
        ...putResult,
        access_url,
        fileData: { fileName, fileSize, fileExtension },
      };
    } catch (error) {
      console.error(
        'MediaUploadHeader file, error when get url or put midia',
        error
      );

      return null;
    }
  };

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      try {
        const file = e.target.files[0] ?? null;
        setUploading(true);
        const uploadResults = await fileUpload(file);

        if (!uploadResults) throw new Error('Invalid result from fileUpload');

        const { access_url: newMedia, fileData } = uploadResults;

        if (!newMedia) throw new Error('Invalid result from fileUpload');

        setLocalMedia(newMedia);
        onUpdate({
          target: [
            { name: 'mediaUrl', value: newMedia },
            { name: 'fileData', value: fileData },
          ],
        });
      } catch {
        toast('Erro ao fazer upload do arquivo');
      } finally {
        setUploading(false);
      }
    }
  };

  const handleRemoveMedia = async (e: Event) => {
    e.preventDefault();
    setRemoving(true);
    const result = await TemplateWabaService.deleteTemplateAwsUrlAssignedFile({
      customer_id,
      access_url_delete: localMedia,
    });
    setRemoving(false);
    setLocalMedia('');
    setLocalMediaFormat(null);
    onUpdate({ target: { name: 'mediaUrl', value: '' } });
  };

  const handleMediaClick = (typeMedia: MediaFormat) => {
    const selectedMediaType = mediaFormatList.find(
      (mediaType) => mediaType.mediaFormat === typeMedia
    );

    if (!selectedMediaType) return;

    if (inputFile.current) inputFile.current.accept = selectedMediaType.accept;

    setLocalMediaFormat(selectedMediaType.mediaFormat);
    onUpdate({
      target: { name: 'mediaFormat', value: selectedMediaType.mediaFormat },
    });

    inputFile.current?.click();
  };

  const DocumentView = () => {
    return (
      <DocumentPreview>
        <Icon icon="poli-icon pi-file-fill" size={75} color="var(--gray)" />
      </DocumentPreview>
    );
  };

  const PreviewImage = () => {
    return <ImagePreview src={localMedia} />;
  };

  const PreviewVideo = () => {
    return <VideoPreview src={localMedia} />;
  };

  const PreviewDocument = () => {
    return <DocumentView />;
  };

  const components: Record<MediaFormat, ReactElement> = {
    IMAGE: <PreviewImage />,
    VIDEO: <PreviewVideo />,
    DOCUMENT: <PreviewDocument />,
  };

  // @ts-ignore
  const MakePreview = ({ format }) => {
    // @ts-ignore
    return <>{components[format]}</>;
  };

  return (
    <Main>
      {uploading && <UploadingAlert>carregando..</UploadingAlert>}
      {removing && <UploadingAlert>removendo..</UploadingAlert>}
      {localMedia !== '' && localMediaFormat ? (
        <PreviewContainer>
          <MakePreview format={localMediaFormat} />
          {!isEdit && (
            <Button outline color="danger" onClick={handleRemoveMedia}>
              <Icon icon="poli-icon pi-delete-line" size={22} />
            </Button>
          )}
        </PreviewContainer>
      ) : (
        <>
          <MediaSelector
            title="Selecione uma imagem"
            onClick={() => handleMediaClick('IMAGE')}
          >
            <Icon icon="poli-icon pi-photo-fill" size={60} />
            <span>Imagem</span>
          </MediaSelector>

          <MediaSelector
            // @ts-ignore
            value="VIDEO"
            title="Selecione um video"
            onClick={() => handleMediaClick('VIDEO')}
          >
            <Icon icon="poli-icon pi-video-line" size={60} />
            <span>Video</span>
          </MediaSelector>

          {/* <MediaSelector
            title="Selecione um documento"
            onClick={() => handleMediaClick('DOCUMENT')}
          >
            <Icon icon="poli-icon pi-file-line" size={60} />
            <span>Documento</span>
          </MediaSelector> */}
        </>
      )}
      <input
        type="file"
        onChange={handleFileChange}
        ref={inputFile}
        style={{ display: 'none' }}
      />
    </Main>
  );
}
