import request from '../api-gateway/request';

async function getIntegrationRequest(params) {
  const { driver, customer_id, tokenPoli } = params;

  return request({
    method: 'GET',
    url: `/integration/${driver}?customer_id=${customer_id}`,
    headers: {
      Authorization: `Bearer ${tokenPoli}`,
    },
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      //error not found (404) means customer doesn't have integration so return empty data
      if (error.response.status === 404) {
        return {};
      }
      //other errors throw error
      throw new Error(error);
    });
}

async function postIntegrationRequest(params) {
  const { customer_id, tokenPoli, integrationData } = params;

  return request({
    method: 'POST',
    url: `/integration?customer_id=${customer_id}`,
    headers: {
      Authorization: `Bearer ${tokenPoli}`,
    },
    data: integrationData,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      //error precondition failed (412) means error on client data
      if (error.response.status === 412) {
        return {
          code: error.response.data.code,
          message: error.response.data.message,
        };
      }
      //other errors throw error
      throw new Error(error);
    });
}

async function deleteIntegrationRequest(params) {
  const { driver, customer_id, tokenPoli } = params;

  return request({
    method: 'DELETE',
    url: `/integration/${driver}?customer_id=${customer_id}`,
    headers: {
      Authorization: `Bearer ${tokenPoli}`,
    },
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // //error not found (404) means customer doesn't have integration so return empty data
      // if (error.response.status === 404) {
      //   return {
      //     code: error.response.data.code,
      //     message: error.response.data.message,
      //   };
      // }
      //other errors throw error
      throw error;
    });
}

async function genAuthUrl(params) {
  const { tokenPoli, driver } = params;

  return request({
    method: 'GET',
    url: `/integration/${driver}/gen-auth-url`,
    headers: {
      Authorization: `Bearer ${tokenPoli}`,
    },
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      //error not found (404) means customer doesn't have integration so return empty data
      if (error.response.status === 404) {
        return {};
      }
      //other errors throw error
      throw new Error(error);
    });
}

export default {
  getIntegrationRequest,
  postIntegrationRequest,
  deleteIntegrationRequest,
  genAuthUrl
};
