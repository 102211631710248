import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  Button, CustomInput, FormGroup, Icon, Input, InputGroup
} from '@polichat/flamboyant';
import { Link } from 'react-router-dom';

import { toast } from 'react-toastify';

import { getListCustomer } from '../../../../services/credits/request';
import history from '../../../../services/history';
import ChannelsService from '../../../../services/tools/channels/channels';
import formatPhone from '../../../../utils/formatPhone';
import SelectCustomer from '../../../admin/plans/Credit/components/SelectCustomer/SelectCustomer.component';
import Animation from '../../../common/animation';
import PageMainBody from '../../../common/pages/PageMainBody/PageMainBody.component';
import PageMainHeader from '../../../common/pages/PageMainHeader/PageMainHeader.component';
import Pagination from '../../../shopping/TablePagination';
import IconMessenger from '../../common/IconMessenger.component';
import { ApiList } from '../../../../enums/channels';
import {
  ActionGroup, ChannelCard, ChannelDetails, ChannelInfoContainer, ChannelName, ChannelNotFound, ChannelsContainer, ChannelStatus, ChannelType, ChannelUuid, Container,
  Filter, IconContainer, PaginationContainer
} from './ChannelList.styles';
let handleOnSearchFilterChangeTimer;
let handleOnTypeFilterChangeTimer;
let handleOnStatusFilterChangeTimer;

const ChannelList = ({
  channels,
  pagination,
  loading,
  handleOnPageChange,
  handleOnStatusFilterChange,
  handleOnTypeFilterChange,
  handleOnSearchFilterChange,
  handleOnCustomerFilterChange,
}) => {
  const [channelsLocal, channelsLocalSet] = useState(channels);
  const customerId = useSelector((state) => state.general.current_customer_id);

  useEffect(() => {
    channelsLocalSet(channels);
  }, [channels]);

  const currentCustomer = useSelector(
    (state) => state.general.current_customer_id
  );

  // Checa se o usuario eh administrador
  const isUserAdmin = useSelector(
    (state) => state.general.user.superRole === 'adm'
  );

  // Filtros - Status e tipo de canal
  const [statusFilter, setStatusFilter] = useState('');
  const [apiFilter, setApiFilter] = useState('');
  const [typeFilter, setTypeFilter] = useState('');
  const [searchFilter, setSearchFilter] = useState('');
  const [customerFilter, setCustomerFilter] = useState(currentCustomer);
  const [customers, setCustomers] = useState([]);

  useEffect(() => {
    const getCustomer = async () => {
      try {
        const result = await getListCustomer();
        if (result?.data.success && Array.isArray(result?.data?.data)) {
          const _resData = result.data.data;
          // Order Name
          _resData.sort((a, b) => {
            const _aName = a.name?.toString().length > 0 ? a.name : 'Sem Nome';
            const _bName = b.name?.toString().length > 0 ? b.name : 'Sem Nome';
            return _aName.localeCompare(_bName);
          });

          setCustomers(_resData);
        }
      } catch (error) {
        console.error('Error on Channel List: getListCustomer', error);
      }
    };
    if (isUserAdmin) {
      getCustomer();
    }
  }, []);

  // #grt23

  const checkIsPolizapToWaba = (channel) => {
    if (
      channel?.homologation &&
      (channel?.migrationToWabaStatus === 'started' ||
        channel?.migrationToWabaStatus === 'finished')
    ) {
      return true;
    }

    return false;
  };

  // Propriedades da paginação
  const { currentPage, registersPerPage, totalPages, totalRegisters } =
    pagination;
  const { error } = channels;

  // Url usada para direcionar rota
  // const { url } = useRouteMatch();

  /** FUNÇÕES */
  const formatStatus = (status) => {
    if (status === 1) {
      return (
        <>
          <Icon icon="poli-icon pi-smille-fill" color="#00c28b" />
          <span style={{ color: '#00c28b' }}>Conectado</span>
        </>
      );
    }
    return (
      <>
        <Icon icon="poli-icon pi-sad-fill" color="#ff665d" />
        <span style={{ color: '#ff665d' }}>Desconectado</span>
      </>
    );
  };

  const handleEditChannel = (channel) => {
    if (channel.info_360) {
      history.push({
        pathname: `/tools/channel/360Dialog/${channel.id}`,
        state: {
          customer_id_to_search: isUserAdmin ? channel.customer_id : null,
        },
      });
      return;
    }

    let historyState = channel;
    if (isUserAdmin) {
      historyState = {
        ...historyState,
        customer_id_to_search: customerFilter,
      };
    }

    history.push({
      pathname: `/tools/channels/edit/${channel.id}`,
      state: historyState,
    });
  };

  const handleChannelStatusChange = async (channel) => {
    const { id, status, customer_id, channel_id } = channel;
    const newStatus = status === 1 ? 0 : 1;
    try {
      const { data } = await ChannelsService.updateChannel({
        id,
        status: newStatus,
        customer_id,
        channel_id,
      });
      const newChannels = channelsLocal.map((channel) => {
      if (channel.id == id) {
        channel.status = newStatus;
      }
      return channel;
    });
      channelsLocalSet(newChannels);
    } catch (error) {
      console.error(`Erro on ChannelList, fn handleChannelStatusChange`, error);
      toast.error(
        <p style={{ color: 'var(--danger)' }}>
          Erro ao editar canal!
          <br />
          Se erro persistir entre em contato com o suporte.
        </p>
      );
    }

  };

  // atualiza o filtro de empresas

  const handleOnChangeCustomerFilter = (e) => {
    const statusFilterObj = {};
    const customerValue = e;
    setCustomerFilter(customerValue);
    if (statusFilter !== 'all') {
      statusFilterObj.status = statusFilter;
    }

    if (typeFilter.length > 0 && typeFilter !== 'all') {
      statusFilterObj.channel_id = typeFilter;
    }

    if (searchFilter.length > 0) {
      statusFilterObj.q = searchFilter;
    }

    statusFilterObj.customer = customerValue;

    handleOnCustomerFilterChange(statusFilterObj);
  };

  useEffect(() => {
    handleOnChangeCustomerFilter(customerFilter);
  }, [customerFilter]);

  // atualiza o filtro de status
  const handleOnChangeStatusFilter = (e) => {
    const statusFilterObj = {};
    const inputValue = e.target.value;
    setStatusFilter(inputValue);

    if (inputValue !== 'all') {
      statusFilterObj.status = inputValue;
    }

    if (typeFilter.length > 0 && typeFilter !== 'all') {
      statusFilterObj.channel_id = typeFilter;
    }

    if (searchFilter.length > 0) {
      statusFilterObj.q = searchFilter;
    }

    clearTimeout(handleOnStatusFilterChangeTimer);
    handleOnStatusFilterChangeTimer = setTimeout(() => {
      handleOnStatusFilterChange(statusFilterObj);
    }, 600);
  };
  // atualiza o filtro de canais
  const handleOnChangeTypeFilter = (e) => {
    const typeFilterObj = {};
    const inputValue = e.target.value;
    setTypeFilter(inputValue);

    if (inputValue !== 'all' && inputValue != 1) {
      typeFilterObj.channel_id = inputValue;
    }

    if (inputValue == 1) {
      typeFilterObj.isZapiAndWaba = true;
    }

    if (statusFilter.length > 0 && statusFilter !== 'all') {
      typeFilterObj.status = statusFilter;
    }

    if (searchFilter.length > 0) {
      typeFilterObj.q = searchFilter;
    }

    clearTimeout(handleOnTypeFilterChangeTimer);
    handleOnTypeFilterChangeTimer = setTimeout(() => {
      handleOnTypeFilterChange(typeFilterObj);
    }, 600);
  };

  const handleOnChangeSearchFilter = (e) => {
    const searchFilterObj = {};
    function formatPhoneNumber(number) {
      const formatedNumber = number.replace(/(\-)|(\()|(\))|(\+)/g, '');
      return formatedNumber;
    }
    const formatedNumber = formatPhoneNumber(e.target.value);
    setSearchFilter(e.target.value);
    searchFilterObj.q = formatedNumber;
    if (statusFilter.length > 0 && statusFilter !== 'all') {
      searchFilterObj.status = statusFilter;
    }

    if (typeFilter.length > 0 && typeFilter !== `all`) {
      searchFilterObj.channel_id = typeFilter;
    }

    clearTimeout(handleOnSearchFilterChangeTimer);
    handleOnSearchFilterChangeTimer = setTimeout(() => {
      handleOnSearchFilterChange(searchFilterObj);
    }, 600);
  };

  const handleOnPageChangeMiddleware = (data) => {
    const dataObj = {
      page: data.currentPage,
    };

    if (statusFilter !== 'all') {
      dataObj.status = statusFilter;
    }

    if (typeFilter !== 'all') {
      dataObj.channel_id = typeFilter;
    }

    if (searchFilter.length > 0) {
      dataObj.q = searchFilter;
    }

    handleOnPageChange(dataObj);
  };

  return (
    <>
      <PageMainHeader title="Seus Canais" icon="poli-icon pi-channels-line">
        {isUserAdmin && (
          <SelectCustomer
            customers={customers ?? []}
            value={customerFilter ?? 0}
            onChange={setCustomerFilter}
          />
        )}

        <Link
          style={{ textDecoration: 'none' }}
          to={{
            pathname: `/tools/channels/add`,
          }}
        >
          <Button
            color="primary"
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Icon icon={'poli-icon pi-add-line'} size={20} />
            <span>Adicionar Canal</span>
          </Button>
        </Link>
      </PageMainHeader>

      <PageMainBody>
        <Container>
          <Filter>
            <FormGroup>
              <CustomInput
                type="select"
                id="filtro-status"
                name="customSelect"
                value={statusFilter}
                onChange={handleOnChangeStatusFilter}
              >
                <optgroup label="Filtrar por status de canal">
                  <option value="all">Todos os status de canais</option>
                  <option value="1">Ativos</option>
                  <option value="0">Inativos</option>
                </optgroup>
              </CustomInput>
            </FormGroup>

            <FormGroup>
              <CustomInput
                type="select"
                id="filtro-tipo"
                name="customSelect"
                value={typeFilter}
                onChange={handleOnChangeTypeFilter}
              >
                <optgroup label="Filtrar por tipo de canal">
                  <option value="all">Todos os tipos de canais</option>
                  <option value="1">WhatsApp</option>
                  <option value="2">Facebook</option>
                  <option value="4">Webchat</option>
                  <option value="5">Instagram</option>
                </optgroup>
              </CustomInput>
            </FormGroup>

            <InputGroup className="input-group-search">
              <Input
                type="text"
                placeholder="Buscar..."
                id="filtro-busca"
                value={searchFilter}
                onChange={handleOnChangeSearchFilter}
              />
            </InputGroup>
          </Filter>

          {loading && (
            <Animation icon="chat-loading" top={'50%'} />
          )}

          {!loading && !error ? (
            <>
              <ChannelsContainer>
                {channelsLocal?.map((channel) => (
                  <ChannelCard key={channel.id} className="position-relative">
                    <IconContainer>
                      {channel.channel_id == 2 ? (
                        <IconMessenger size="100" />
                      ) : (
                        <Icon
                          icon={channel.extra.icon}
                          color={channel.extra.color}
                          size="100"
                        />
                      )}
                      {channel.api === ApiList.waba && <strong>360</strong>}
                      {channel.api === ApiList.cloudapi && (
                        <strong>BSP Poli</strong>
                      )}
                    </IconContainer>
                    <ChannelInfoContainer>
                      <ChannelStatus status={channel.status}>
                        {channel.api == 4
                          ? channel.config &&
                            JSON.parse(channel.config).status == 203
                            ? formatStatus(1)
                            : formatStatus(0)
                          : formatStatus(channel.status)}
                      </ChannelStatus>

                      <ChannelDetails>
                        <ChannelType>
                          {channel.channel_id == 2 ? (
                            <IconMessenger size="25" />
                          ) : (
                            <Icon
                              icon={channel.extra.icon}
                              color={channel.extra.color}
                              size="25"
                            />
                          )}
                          <span>{channel.extra.typeName}</span>
                        </ChannelType>

                        <ChannelName>{channel.name}</ChannelName>

                        <ChannelUuid>
                          {channel.phone && formatPhone(channel.phone)}
                        </ChannelUuid>
                      </ChannelDetails>
                      <ActionGroup>
                        {checkIsPolizapToWaba(channel) ||
                        !channel?.homologation ? (
                          <Button
                            color={channel.status === 1 ? 'danger' : 'success'}
                            outline={channel.status === 1 ? false : true}
                            width="total"
                            onClick={() => handleChannelStatusChange(channel)}
                          >
                            {channel.status === 1 ? 'Desativar' : 'Ativar'}
                          </Button>
                        ) : null}

                        <Button
                          color={
                            channel?.homologation &&
                            channel?.migrationToWabaStatus !== 'started' &&
                            channel?.migrationToWabaStatus !== 'finished'
                              ? 'primary'
                              : 'success'
                          }
                          width="total"
                          onClick={() => handleEditChannel(channel)}
                        >
                          {channel?.homologation &&
                          channel?.migrationToWabaStatus !== 'started' &&
                          channel?.migrationToWabaStatus !== 'finished'
                            ? 'Continuar homologação'
                            : 'Editar'}
                        </Button>
                      </ActionGroup>
                    </ChannelInfoContainer>

                    {/*<div className="poli" data-beta="remover">*/}
                    {/*  <button*/}
                    {/*    className="btn btn-link btn-sm"*/}
                    {/*    onClick={async () => {*/}
                    {/*      if (*/}
                    {/*        confirm(*/}
                    {/*          'Tem certeza que deseja remover?\nNão tem retorno!'*/}
                    {/*        )*/}
                    {/*      ) {*/}
                    {/*        const { id, customer_id } = channel;*/}
                    {/*        try {*/}
                    {/*          const res = await ChannelsService.deleteChannel({*/}
                    {/*            id,*/}
                    {/*            customer_id,*/}
                    {/*          });*/}
                    {/*          if (res && res.err) {*/}
                    {/*            throw true;*/}
                    {/*          }*/}
                    {/*          channelsLocalSet(*/}
                    {/*            channelsLocal.filter(*/}
                    {/*              (item) => item.id !== channel.id*/}
                    {/*            )*/}
                    {/*          );*/}
                    {/*        } catch (error) {*/}
                    {/*          alert('Ocorreu um erro!');*/}
                    {/*        }*/}
                    {/*      }*/}
                    {/*    }}*/}
                    {/*  >*/}
                    {/*    remover*/}
                    {/*  </button>*/}
                    {/*</div>*/}
                  </ChannelCard>
                ))}
              </ChannelsContainer>
            </>
          ) : (
            <>
              {!loading && (
                <ChannelNotFound>
                  <span>{error}</span>
                </ChannelNotFound>
              )}
            </>
          )}

          {totalPages > 0 && (
            <PaginationContainer>
              <Pagination
                totalItens={totalRegisters}
                limitItens={registersPerPage}
                pageSide={2}
                onPageChange={handleOnPageChangeMiddleware}
              />
            </PaginationContainer>
          )}
        </Container>
      </PageMainBody>
    </>
  );
};

export default ChannelList;
