import { toast } from 'react-toastify';

export const actionNames = {
  START_VISITORS_SERVICE: '@visitors/START_VISITORS_SERVICE',
  START_VISITORS_SERVICE_FAILURE: '@visitors/START_VISITORS_SERVICE_FAILURE',
  START_VISITORS_SERVICE_SUCCESS: '@visitors/START_VISITORS_SERVICE_SUCCESS',

  FETCH_SESSIONS_WEBCHAT: '@visitors/FETCH_SESSIONS_WEBCHAT',
  NEW_VISITOR_WEBCHAT: '@visitors/NEW_VISITOR_WEBCHAT',
  UPDATED_VISITOR_WEBCHAT: '@visitors/UPDATED_VISITOR_WEBCHAT',

  STORE_FROM_VISITOR: '@visitors/STORE_FROM_VISITOR',
  STORE_FROM_VISITOR_SUCCESS: '@visitors/STORE_FROM_VISITOR_SUCCESS',
  STORE_FROM_VISITOR_FAILURE: '@visitors/STORE_FROM_VISITOR_FAILURE',

  REMOVE_READ_TO_OPEN: '@visitors/REMOVE_READ_TO_OPEN',
  TRY_OPEN_VISITOR_CHAT_ERROR: '@visitors/TRY_OPEN_VISITOR_CHAT_ERROR',
};

/**
 * Inicializar o serviço
 * @return {type: string}
 */
export function startVisitorsService() {
  return {
    type: actionNames.START_VISITORS_SERVICE,
  };
}
/**
 * Falha ao iniciar serviço
 * @returns {type: string}
 */
export function startVisitorsServiceFailure() {
  return {
    type: actionNames.START_VISITORS_SERVICE_FAILURE,
  };
}
/**
 * Sucesso ao iniciar serviço
 * @param {[]} payload - vetor das sessões de visitantes do site
 * @returns {type: string, payload: []}
 */
export function startVisitorsServiceSuccess(payload) {
  return {
    type: actionNames.START_VISITORS_SERVICE_SUCCESS,
    payload,
  };
}
/**
 * Recebe as sessões de visitors/webchat a cada 30s
 * @param {[]} payload - vetor das sessões de visitantes do site
 * @returns {type: string, payload: []}
 */
export function fetchSessionsWebchat(payload) {
  return {
    type: actionNames.FETCH_SESSIONS_WEBCHAT,
    payload,
  };
}
/**
 *
 * @param {*} payload
 * @returns
 */
export function newVisitorWebchat(payload) {
  return {
    type: actionNames.NEW_VISITOR_WEBCHAT,
    payload,
  };
}
/**
 *
 * @param {*} payload
 * @returns
 */
export function updatedVisitorWebchat(payload) {
  return {
    type: actionNames.UPDATED_VISITOR_WEBCHAT,
    payload,
  };
}
/**
 *
 * @param {*} payload
 * @returns
 */
export function storeFromVisitor(payload) {
  return {
    type: actionNames.STORE_FROM_VISITOR,
    payload,
  };
}
export function storeFromVisitorSuccess(payload) {
  return {
    type: actionNames.STORE_FROM_VISITOR_SUCCESS,
    payload,
  };
}
export function storeFromVisitorFailure(payload) {
  return {
    type: actionNames.STORE_FROM_VISITOR_FAILURE,
    payload,
  };
}

export function tryOpenVisitorChatError(payload) {
  return {
    type: actionNames.TRY_OPEN_VISITOR_CHAT_ERROR,
    payload,
  };
}

export function removeReadToOpenChatVisitor(payload) {
  return {
    type: actionNames.REMOVE_READ_TO_OPEN,
    payload,
  };
}
