import React from 'react';
import { Icon } from '@polichat/flamboyant';
import { IconPlusIcon } from '../../../../../assets/illustratorElement/iconPlusIcon/iconPlusIcon';

import CombinedLogo from '../../../../../assets/images/poli-plus-whatsapp.svg';
import {
  Container,
  LogoContainer,
  StepsContainer,
  InformationalTextContainer,
  StepsItemContainer,
  StepItem,
} from './ProgressBar.styles';

const steps = [
  // { id: 0, name: 'Início' },
  { id: 1, name: 'Dados Canal' },
  { id: 2, name: 'Submissão WhatsApp' },
  { id: 3, name: 'Salvar Informações' },
];

// Lógica do componente que renderizará as etapas da homologação
const stepItemsComponent = ({ currentStep }) =>
  steps.map(({ id, name }) => (
    <StepItem key={id} passed={id < currentStep} className={id < currentStep}>
      {id < currentStep ? (
        <Icon icon="poli-icon pi-finish-fill" size={24} />
      ) : (
        <Icon icon="poli-icon pi-finish-line" size={24} />
      )}
      <span>{name}</span>
    </StepItem>
  ));

const ProgressBar = ({ currentStep = 0, title = '' }) => (
  <Container>
    {/* <LogoContainer>
      <div
        className="mainLogo"
        style={{ width: '50px', backgroundColor: 'inherit' }}
      />

      <div style={{ marginTop: '15px' }}>
        <Icon icon="poli-icon pi-add-fill" size={25} />
      </div>

      <div style={{ marginTop: '5px' }}>
        <Icon
          icon={'poli-icon pi-whatsapp-fill'}
          color={'var(--success)'}
          size={45}
        />
      </div>
    </LogoContainer> */}
    <IconPlusIcon
      firstIcon="onlyLogo"
      secondIcon="poli-icon pi-whatsapp-api-fill"
      secondColor="var(--success)"
    />

    <StepsContainer>
      <InformationalTextContainer>
        {title}
        {title === '' && currentStep === 0 && 'Perfil do canal WhatsApp'}
      </InformationalTextContainer>
      {currentStep > 0 && (
        <StepsItemContainer>
          {stepItemsComponent({ currentStep })}
        </StepsItemContainer>
      )}
    </StepsContainer>
  </Container>
);

export default ProgressBar;
