import {
  Container,
  LogoContainer,
  StepsContainer,
} from '../../../channels/CertificationWabaByPoli/ProgressBar/ProgressBar.styles';
import { IconPlusIcon } from 'src/assets/illustratorElement/iconPlusIcon/iconPlusIcon';
export interface ProgressBarMigrateChannelToPoliProps {
  current_step?: number;
  title?: string;
}
export function ProgressBarMigrateChannelToPoli(
  props: ProgressBarMigrateChannelToPoliProps
) {
  // ---------------------------------------------
  // Transformations
  // ---------------------------------------------
  // Render

  return (
    <Container style={{ padding: '0' }}>
      <LogoContainer>
        <IconPlusIcon
          firstIcon="onlyLogo"
          secondIcon="poli-icon pi-whatsapp-api-fill"
          secondColor="var(--success)"
        />
      </LogoContainer>
      {/* <div>STEP:: {props.current_step}</div> */}

      {props.title && (
        <StepsContainer>
          <h5>{props.title}</h5>
        </StepsContainer>
      )}
    </Container>
  );
}
