export enum ExportStatusIds {
  PENDING = 1,
  COMPLETED = 2,
  FAILED = 3,
  EXPIRED = 0
}

export enum ExportStatusTitle {
  IN_PROGRESS = 'Em andamento',
  COMPLETE = 'Completo',
  EXPIRED = 'Expirado',
  EXPORT_ERROR = 'Erro na exportação'
}
