import { Button, Icon, Label } from '@polichat/flamboyant';
import { useEffect, useState } from 'react';
import {
  ContrastBackgroundContainer,
  LabelWithSpan
} from '../FormMessagesBuilder.styles';

import CreateBodyList from './body/CreateBodyList.component';
import CreateButtonList from './button/CreateButtonList.component';
import CreateFooterList from './footer/CreateFooterList.component';
import CreateHeaderList from './header/CreateHeaderList.component';
import CreateOptionList from './option/CreateOptionList.component';
import CreateSectionList from './section/CreateSectionList.component';

function FormTemplateList({ onUpdate, initialValue, isEdit }) {
  const { header, body, button, footer, section, option } = initialValue;

  const [messageButton, setMessageButton] = useState(button);
  const [messageHeader, setMessageHeader] = useState(header);
  const [messageBody, setMessageBody] = useState(body);
  const [messageFooter, setMessageFooter] = useState(footer);
  const [messageSection, setMessageSection] = useState(section);
  const [messageOption] = useState(option);

  const [isDisabledAddButton, setIsDisabledAddButton] = useState(false);
  const [countRows, setCountRows] = useState(1);
  const [isDisabledRemoveOption, setDisabledRemoveOption] = useState(true);

  const updateHeaderData = (headerData) => {
    setMessageHeader(headerData);
  };

  const updateBodyData = (bodyData) => {
    setMessageBody(bodyData);
  };

  const updateFooterData = (footerData) => {
    setMessageFooter(footerData);
  };

  const updateButtonData = (buttonData) => {
    setMessageButton(buttonData);
  };

  const updateSectionData = (sectionData) => {
    const { id } = sectionData;
    let filteredSection = messageSection.filter((sec) => sec.id !== id);
    filteredSection.push(sectionData);
    filteredSection = filteredSection.sort((a, b) => {
      if (a.id < b.id) return -1;
      if (a.id > b.id) return 1;
      return 0;
    });
    setMessageSection(filteredSection);
  };

  const updateOptionData = (optionData, index, indexKey) => {
    let section = [...messageSection];
    section[index].rows[indexKey].messageOption = optionData;
    setMessageSection(section);
  };

  const handleAddSection = () => {
    if (isDisabledAddButton) return;
    setMessageSection([
      ...messageSection,
      {
        id: Math.floor(Date.now() + Math.random()).toString(10),
        text: '',
        rows: [
          {
            id: Math.floor(Date.now() + Math.random()).toString(10),
            messageOption: [],
          },
        ],
      },
    ]);
    setCountRows(countRows + 1);
    countRows == 10 && setIsDisabledAddButton(true);
  };

  const handleAddOptionInSection = (sectionIndex) => {
    if (isDisabledAddButton) return;
    let _sectionMessage = [...messageSection];
    let section = messageSection[sectionIndex];

    section.rows = [
      ...section.rows,
      {
        id: Math.floor(Date.now() + Math.random()).toString(10),
        messageOption: [],
      },
    ];

    _sectionMessage.splice(sectionIndex, 1, section);
    setMessageSection(_sectionMessage);
    setCountRows(countRows + 1);
    countRows == 10 && setIsDisabledAddButton(true);
  };

  const handleRemoveSection = (id) => {
    if (messageSection.length <= 0) return;
    function found(section) {
      return section.id === id;
    }

    let newSection = [];

    messageSection.map(() => {
      newSection = messageSection.find(found);
      return newSection;
    });

    let rowSize = newSection.rows.length;

    setCountRows(countRows - rowSize);
    setMessageSection(messageSection.filter((section) => section.id != id));
    setIsDisabledAddButton(false);
  };

  const handleRemoveOption = (index, id, indexKey) => {
    if (messageSection[index].rows.length <= 0) return;
    let section = [...messageSection];
    const removeOptionInSection = section[index].rows.filter((option) => {
      return option.id != id;
    });
    section[index].rows = removeOptionInSection;

    setCountRows(countRows - 1);
    setMessageSection(section);
    setIsDisabledAddButton(false);
  };

  const handleDisableRemoveOption = () => {
    if (countRows <= 2) {
      return setDisabledRemoveOption(true);
    } else {
      return setDisabledRemoveOption(false);
    }
  };

  useEffect(() => {
    handleDisableRemoveOption();
  }, [countRows]);

  useEffect(() => {
    if (isEdit) {
      let i = 1;
      messageSection.map(function (sec) {
        sec.rows.map(function (row) {
          i++;
        });
      });
      setCountRows(i);
    }
  }, []);

  useEffect(() => {
    if (!isEdit) {
      handleAddSection();
    }
  }, []);

  useEffect(() => {
    onUpdate({
      header: messageHeader,
      body: messageBody,
      footer: messageFooter,
      button: messageButton,
      section: messageSection,
      option: messageOption,
    });
  }, [
    messageHeader,
    messageBody,
    messageFooter,
    messageButton,
    messageSection,
    messageOption,
  ]);

  return (
    <>
      <CreateHeaderList
        onUpdate={updateHeaderData}
        initialValue={messageHeader}
      />
      <CreateBodyList onUpdate={updateBodyData} initialValue={messageBody} />
      <CreateFooterList
        onUpdate={updateFooterData}
        initialValue={messageFooter}
      />
      <CreateButtonList
        onUpdate={updateButtonData}
        initialValue={messageButton}
      />
      <LabelWithSpan>
        <Label>
          {/* TODO: Melhorar esta mensagem de aviso de forma que fique mais usual para o cliente. */}
          Atenção:{' '}
          <span>
            Você precisa ter no mínimo uma seção e para cada seção no mínimo uma
            opção. <br/>
            Para os canais Z-API é permitido criar até 10 seções.<br/>
            Já para canais Z-API é permitido a criação de apenas 1 seção.<br/>
            Máximo: 10 Opções
            <br/>
          </span>
          {/* <span>
            Para os canais Z-API é permitido a criação de apenas uma seção.{' '}
          </span> */}
        </Label>
      </LabelWithSpan>
      <br></br>
      {messageSection?.map((section, index) => (
        <div key={section.id}>
          <ContrastBackgroundContainer>
            <CreateSectionList
              id={section.id}
              removeSection={handleRemoveSection}
              onUpdate={updateSectionData}
              initialValue={section}
              isDisabledRemove={isDisabledRemoveOption}
            />

            {section?.rows?.map((rows, indexKey) => (
              <div key={rows.id}>
                <CreateOptionList
                  id={rows[indexKey]}
                  removeOption={() =>
                    handleRemoveOption(index, rows.id, indexKey)
                  }
                  onUpdate={(event) => {
                    updateOptionData(event, index, indexKey);
                  }}
                  initialValue={rows.messageOption}
                  isDisabledRemove={isDisabledRemoveOption}
                />
              </div>
            ))}
            <Button
              color="secondary-link"
              onClick={() => handleAddOptionInSection(index)}
              disabled={isDisabledAddButton}
            >
              <Icon
                icon="poli-icon pi-add-circle-line"
                size={20}
                position={'relative'}
                top={'2px'}
                right={'2px'}
              />
              <span>Adicionar Opção</span>
            </Button>
          </ContrastBackgroundContainer>
          <br></br>
        </div>
      ))}

      <Button
        color="secondary-link"
        onClick={handleAddSection}
        disabled={isDisabledAddButton}
      >
        <Icon
          icon="poli-icon pi-add-circle-line"
          size={20}
          position={'relative'}
          top={'2px'}
          right={'2px'}
        />
        <span>Adicionar Seção</span>
      </Button>
    </>
  );
}

export default FormTemplateList;
