import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import * as GeneralActions from '../../../store/modules/general/actions';
import { connect } from 'react-redux';
import { Button, Icon, Table } from '@polichat/flamboyant';
import history from '../../../services/history';
import PageMainHeader from '../../common/pages/PageMainHeader/PageMainHeader.component';
import PageMainBody from '../../common/pages/PageMainBody/PageMainBody.component';
import { getServices } from '../../../utils/admin/services/services';
import { useRouteMatch } from 'react-router-dom';
import Animation from '../../common/animation';

function ServiceList() {

  const { url } = useRouteMatch();
  const [services, setServices] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  async function fetchData() {
    const services = await getServices();

    if(services?.length <= 0){
      history.push({ pathname: `/admin/welcome-service` });
    }

    setServices(services);
    setIsLoading(false);
  }

  useEffect(() => {
    fetchData();

    return () => {
      setServices([]);
    }
  }, []);

  return (
    <>
      <PageMainHeader title='Serviços' icon='poli-icon pi-catalogo-line'>
        <Button color='primary' onClick={() => {
          history.push({ pathname: `${url}/new` });
        }}><Icon icon='poli-icon pi-add-line' /> Adicionar serviço </Button>
      </PageMainHeader>
      <PageMainBody>
        {isLoading ?
          <Animation icon='chat-loading' top={'50%'} />
          :
        <Table responsive bordered striped hover>
          <thead>
          <tr>
            <th className='text-center'>Serviço</th>
            <th className='text-center'>Preço</th>
            <th className='text-center'>Descrição</th>
            <th className='text-center'>Status</th>
            <th className='text-center' />
          </tr>
          </thead>
          <tbody>
            {
              services?.map((service, index) => {
                return <tr key={index}>
                  <td className="text-center">{service?.name}</td>
                  <td className="text-center">{(Number(service?.value))?.toLocaleString()}</td>
                  <td className="text-center">{service?.description}</td>
                  <td style={service?.status === 1 ? { color: 'var(--success)' } : { color: 'var(--attention)' }} className='text-center'>
                    {service?.status === 1 ? 'Ativo' : 'Pausado'}
                  </td>
                  <td className="text-center">
                    <Button color='secondary' className='btn-sm' style={{ marginLeft: '5px' }} onClick={() => {
                      history.push({ pathname: `${url}/${service.id}/edit` });
                    }}><Icon icon='poli-icon pi-pencil-line' /> Editar </Button>
                  </td>
                </tr>
              })
            }
          </tbody>
        </Table>}
      </PageMainBody>
    </>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(GeneralActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ServiceList);
