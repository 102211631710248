import {
	DropdownToggle,
	UncontrolledDropdown
} from '@polichat/flamboyant';
import { useEffect, useState } from "react";
import { useSelector } from 'react-redux';

import { SoundOff, SoundOn } from '@poli-ink/react';

export const ToggleNotifications = () => {
	const [enabled, setEnabled] = useState<boolean>(true)

	// @ts-ignore
	const isWhiteLabel = useSelector((state) => state?.whitelabel?.config?.is_white_label);

	// Quando aplicação montar, checamos se o valor já está em localStorage
	useEffect(() => {
		const localStorageEnabled = localStorage.getItem('@poli/notificationsEnabled')

		if (localStorageEnabled) {
			// Se tiver, atualizamos o useState com ele
			setEnabled(localStorageEnabled === 'true')
		}
		else {
			// Se não tiver, criamos o valor no localStorage
			localStorage.setItem('@poli/notificationsEnabled', enabled.toString())
		}
	}, [])

	const handleToggleNotifications = (event: any) => {
		event.preventDefault()
		event.stopPropagation()

		setEnabled((prev: boolean) => {
			const new_value: boolean = !prev

			// Sempre que clicar no botão, também atualizamos o localStorage
			localStorage.setItem('@poli/notificationsEnabled', new_value.toString())

			return new_value
		})
	}

	return (
		<>
			{ isWhiteLabel ? (
				<button className="itemDropdownMenu dropdown-item" onClick={handleToggleNotifications}>
						{
							enabled 
								? <SoundOn width={16} height={16} fill={'var(--green-polichat)'} /> 
								: <SoundOff width={16} height={16} fill={'var(--green-polichat)'} />
						}

						<p className="alternative">
							{enabled ? 'Desligar' : 'Ligar'} notificações
						</p>

						{/*  Span vazio para espaçamento no CSS */}
						<span></span>
				</button>
			) : (
				<UncontrolledDropdown>
					<DropdownToggle
							color="neutral"
							id="notification-enabled"
							name="notification-enabled"
							value={enabled}
							onClick={handleToggleNotifications}
						>

						{
							enabled 
								? <SoundOn width={16} height={16} /> 
								: <SoundOff width={16} height={16} />
						}

						<span className="nameusersstatus"> 
							{enabled ? 'Desligar' : 'Ligar'} notificações
						</span>

						{/*  Span vazio para espaçamento no CSS */}
						<span></span>
				
					</DropdownToggle>
					
				</UncontrolledDropdown>
			)}
		</>
	)
}
