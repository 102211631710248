import { ChatAlert } from '../../../types/alerts';
import { actionNames } from './actions';
import { selectAlert } from './functions';

export interface ChatAlertsState {
  alert: ChatAlert | null;
  hasAsyncRequest: boolean;
  loading: boolean;
}

const initialState: ChatAlertsState = {
  alert: null,
  hasAsyncRequest: false,
  loading: false,
};

export default function alerts(
  state = initialState,
  action?: { type: string; payload: unknown }
): ChatAlertsState {
  switch (action?.type) {
    case actionNames.FETCH_CHAT_ALERTS_REQUEST: {
      return {
        ...state,
        hasAsyncRequest: true,
        loading: true,
      };
    }

    case actionNames.FETCH_CHAT_ALERTS_SUCCESS: {
      return {
        ...state,
        alert: selectAlert(action?.payload),
        hasAsyncRequest: false,
        loading: false,
      };
    }

    case actionNames.FETCH_CHAT_ALERTS_FAILURE: {
      return {
        ...state,
        hasAsyncRequest: false,
        loading: false,
      };
    }

    case actionNames.CLOSE_CHAT_ALERT: {
      return {
        ...state,
        alert: null,
      };
    }

    default:
      return state;
  }
}
