import {
  Icon,
  //Label,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from '@polichat/flamboyant';
import Animation from '../animation';

import { Search } from '@poli-ink/react';

/**
 * Este arquivo e responsavel apenas por atualizar o state da aplicacao.
 * As regras de search estao no arquivo ApplySearchFilters
 */
function InputSearch(props) {
  return (
    <InputGroup>
      <Input
        className="f-control"
        style={{
          position: "relative",
          boxSizing: "border-box"
        }}
        {...{
          key: props.key || null,
          type: 'text',
          onChange: props.onChange,
          value: props.value,
          name: props.name,
          placeholder: props.placeholder,
          id: 'searchInput',
        }}
      />
      <InputGroupAddon
        addonType="append" 
        style={{
          position: 'absolute',
          top: 3,
          right: 1,
          zIndex: '998',
          width: 44,
          height: 32,
        }}>
        <InputGroupText>
          {props.loading ? (
            <Animation icon="loading" extraClass="spinnerSearch" />
          ) : props.value == '' ? (
            <Search className="newIconColor" width="16" height="16" />
          ) : (
            <Icon
              icon="poli-icon pi-close-line"
              size={20}
              cursor="pointer"
              onClick={() => {
                props.clearSearch('');
              }}
            />
          )}
        </InputGroupText>
      </InputGroupAddon>
    </InputGroup>
  );
}

export default InputSearch;
