import React, { useRef } from 'react';
import { Icon } from '@polichat/flamboyant';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as ChatActions from '../../../../../store/modules/chat/actions';

import ClickOutsideEvent from '../../../../../events/ClickOutsideEvent';

function FinishedChatModal({ closeFinishChatModal }) {
  const wrapperRef = useRef(null);

  ClickOutsideEvent(wrapperRef, closeFinishChatModal);

  return (
    <div className="finish-chat-modal" id="finish-chat-modal" ref={wrapperRef}>
      <div className="finish-chat-subsection">
        <Icon icon="pi-talks-line" className="finish-chat-icon" size={80} />
      </div>

      <div className="finish-chat-subsection" style={{ marginTop: '0.8rem' }}>
        <span className="finish-chat-title">Este chat já está fechado.</span>

        <div className="finish-chat-subsection" style={{ marginTop: '1rem' }}>
          <span className="finish-chat-description">
            Não é possível encerrá-lo novamente.
          </span>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  chat: state.chat?.chats?.find((chat) => chat.open === true),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(ChatActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FinishedChatModal);
