import { getWabasFromDebugToken } from './request/getWabasFromDebugToken';
import { mountAndGetInfos } from './utils/mountAndGetInfos';
import { mountReturnResponse } from './utils/mountReturnResponse';
import { ReturnResponse } from './types/ReturnResponse';

async function sendToken(token: string, requestFromWabaService: boolean): Promise<ReturnResponse[] | undefined> {
  const waba_ids = await getWabasFromDebugToken(token, requestFromWabaService);
  if (waba_ids) {
    const infoAllData = await mountAndGetInfos(waba_ids, requestFromWabaService);
    const returnResData = mountReturnResponse(infoAllData);
    return returnResData;
  }

  return;
}

export { sendToken };
