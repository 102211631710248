import styled, { keyframes } from 'styled-components';

interface LoaderMainProps {
  color: string
  size: number
  top: number
}

export function LoaderMain(props: LoaderMainProps) {
  const scale = (props?.size ? props?.size : 3.2) / 3; // Calcula a escala proporcional
  const color = props?.color ? props.color : 'var(--green-polichat)'; // Muda a cor do Loading
  const top = props?.top ?? '';
  const desiredWidth = 75 * scale; // Aplica a escala na largura padrão
  const desiredHeight = 30 * scale; // Aplica a escala na altura padrão

  const animateLoader = keyframes`
  0% {
    left: calc(75px * ${scale});
    top: 0;
  }
  80% {
    left: 0;
    top: 0;
  }
  85% {
    left: 0;
    top: calc(-15px * ${scale});
    width: calc(15px * ${scale});
    height: calc(15px * ${scale});
  }
  90% {
    width: calc(30px * ${scale});
    height: calc(11px * ${scale});
  }
  95% {
    left: calc(75px * ${scale});
    top: calc(-15px * ${scale});
    width: calc(15px * ${scale});
    height: calc(15px * ${scale});
  }
  100% {
    left: calc(75px * ${scale});
    top: 0;
  }
`;
  const LoaderWalk = styled.div`
    width: ${desiredWidth}px;
    height: ${desiredHeight}px;
    position: absolute;
    left: 50%;
    top: ${top};
    transform: translate(-50%, -50%) scale(${scale});

    > div {
      content: '';
      width: ${15 * scale}px;
      height: ${15 * scale}px;
      background: ${color};
      border-radius: 100%;
      position: absolute;
      animation: ${animateLoader} 2.3s linear infinite;
    }

    > div:nth-of-type(1) {
      animation-delay: -0.46s;
    }

    > div:nth-of-type(2) {
      animation-delay: -0.92s;
    }

    > div:nth-of-type(3) {
      animation-delay: -1.38s;
    }

    > div:nth-of-type(4) {
      animation-delay: -1.84s;
    }
  `;
  return (
    <LoaderWalk>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </LoaderWalk>
  );
}
