import {
  Row,
  Col,
  FormGroup,
  Label,
  Input,
} from '@polichat/flamboyant';
import {
  LabelWithSpan,
  OptionsContainer,
} from '../../FormMessagesBuilder.styles';

export interface CreateFooterButtonsMessageProps {
  onUpdate(values: any): void;
  initialValue: {
    text: string;
  };
  setFooterData(value: any): void;
}

export interface TargetProps {
  target: {
    name: string
    value: any
  }
}


export function CreateFooterButtonsMessage(props: CreateFooterButtonsMessageProps) {
  const footerData = props.initialValue

  const handleInputChange = ({ target: { name, value } }: TargetProps) => {
    props.setFooterData({
      ...footerData,
      [name]: value,
    });
    handleFooterData({
      ...footerData,
      [name]: value,
    });
  };

  const handleFooterData = (data: any) => {
    props.onUpdate(data);
  };

  return (
    <Row>
      <Col sm={12}>
        <FormGroup>
          <LabelWithSpan>
            <Label>
              Rodapé:{' '}
              <span>
                Adicione uma linha curta de texto na parte inferior do seu
                modelo de mensagem{' '}
              </span>
            </Label>
          </LabelWithSpan>
          <Input
            type="text"
            id="footer-message"
            name="text"
            placeholder="Rodapé da mensagem"
            value={footerData.text}
            onChange={handleInputChange}
            showCharCounter
            maxLength={60}
            disabled={false}
          />
          <OptionsContainer></OptionsContainer>
        </FormGroup>
      </Col>
    </Row>
  );
}
