import React from 'react';
import Avatar from '../../common/profile/Avatar';
import { Icon } from '@polichat/flamboyant';

import { connect } from 'react-redux';

import { bindActionCreators } from 'redux';
import * as ChatActions from '../../../store/modules/chat/actions';

import formatPhone from '../../../utils/formatPhone';

function ContactListItem({ props }) {
  // const open = props.open;

  return (
    <div className="contactListItem">
      <div className="contactInfoContainer">
        <div className="avatarContainer">
          <Avatar
            className="contact"
            title={props.name}
            url={props.avatarUrl}
            size={50}
            extraClass="avatarContactSidebar"
          />
        </div>

        <div className="phoneContainer">
          <span>{props.name}</span>
          <span>
            {props.contact.type === 'user'
              ? formatPhone(props.phoneNumber)
              : 'Grupo'}
          </span>
        </div>
      </div>

      <Icon size={20} icon="poli-icon pi-simple-arrow-right-line" />
    </div>
  );
}

const mapStateToProps = (state, ownProps) => ({
  props: ownProps,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(ChatActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ContactListItem);
