import React from 'react';
import { Icon, UncontrolledTooltip } from '@polichat/flamboyant';
import { useFlags } from 'flagsmith/react';
import { connect, useSelector } from 'react-redux';

import { bindActionCreators } from 'redux';
import * as ChatActions from '../../../../store/modules/chat/actions';
import { isMobile } from 'react-device-detect';

function Tags({
  allTags,
  chat,
  chatDetails,
  showDetailsComponent,
  openTagInDetails,
  showBlockThree,
}) {
  const flag = useFlags(['stack_flags']);
  const chatTags = chat?.tags;
  const canLoadTags = chatTags && allTags && allTags.length && chatTags.length;

  const current_customer_id = useSelector(
    (state) => state.general.current_customer_id
  );
  const stackFlags =
    JSON.parse(flag.stack_flags?.value)
      ?.map(Number)
      .includes(current_customer_id) || flag.stack_flags?.value === '[]';

  function handleTagDetails(showDetails) {
    if (showDetails === true) {
      showDetails = false;
    } else if (showDetails === undefined || showDetails === false) {
      showDetails = true;
    }
    showDetailsComponent(showDetails);
    openTagInDetails(showDetails);
    isMobile && showBlockThree();
  }

  const findTag = (tag_id) => {
    let tag = allTags.find((t) => t.id === tag_id);

    return tag;
  };

  if (canLoadTags) {
    let renderTags = chatTags?.map((tag, index) => {
      let t = findTag(tag.tag_id);

      if (t && stackFlags && index <= 4 && !isMobile) {
        return (
          <React.Fragment key={index}>
            <div
              className="tagBall"
              key={index}
              title={t.name}
              style={{
                backgroundColor: `${t.color}`,
                marginLeft: `${chatTags?.length > 6 ? '-6px' : '0px'}`,
              }}
              id={'Tooltip' + t.id}
            />
            <UncontrolledTooltip placement="bottom" target={'Tooltip' + t.id}>
              {t.name.length > 35 ? `${t.name.substring(0, 32)}...` : t.name}
            </UncontrolledTooltip>
          </React.Fragment>
        );
      } else if (t && stackFlags && index <= 3 && chatTags?.length >= 4 && isMobile) {
        return (
          <React.Fragment key={index}>
            <div
              className="tagBall"
              key={index}
              title={t.name}
              style={{
                backgroundColor: `${t.color}`,
                marginLeft: `${chatTags?.length > 5 ? '-6px' : '0px'}`,
              }}
              id={'Tooltip' + t.id}
            />
            <UncontrolledTooltip placement="bottom" target={'Tooltip' + t.id}>
              {t.name.length > 35 ? `${t.name.substring(0, 32)}...` : t.name}
            </UncontrolledTooltip>
          </React.Fragment>
        );
      } else if (
        t &&
        stackFlags &&
        index <= 4 &&
        chatTags?.length <= 5 &&
        isMobile
      ) {
        return (
          <React.Fragment key={index}>
            <div
              className="tagBall"
              key={index}
              title={t.name}
              style={{
                backgroundColor: `${t.color}`,
              }}
              id={'Tooltip' + t.id}
            />
            <UncontrolledTooltip placement="bottom" target={'Tooltip' + t.id}>
              {t.name.length > 35 ? `${t.name.substring(0, 32)}...` : t.name}
            </UncontrolledTooltip>
          </React.Fragment>
        );
      } else if (
        t &&
        stackFlags &&
        index <= 5 &&
        chatTags?.length <= 6 &&
        !isMobile
      ) {
        return (
          <React.Fragment key={index}>
            <div
              className="tagBall"
              key={index}
              title={t.name}
              style={{
                backgroundColor: `${t.color}`,
              }}
              id={'Tooltip' + t.id}
            />
            <UncontrolledTooltip placement="bottom" target={'Tooltip' + t.id}>
              {t.name.length > 35 ? `${t.name.substring(0, 32)}...` : t.name}
            </UncontrolledTooltip>
          </React.Fragment>
        );
      } else if (t && !stackFlags) {
        return (
          <React.Fragment key={index}>
            <Icon
              icon={'poli-icon pi-point-fill'}
              key={index}
              title={t.name}
              color={t.color}
              size={15}
              id={'Tooltip' + t.id}
            />
            <UncontrolledTooltip placement="bottom" target={'Tooltip' + t.id}>
              {t.name.length > 35 ? `${t.name.substring(0, 32)}...` : t.name}
            </UncontrolledTooltip>
          </React.Fragment>
        );
      }
    });

    return (
      <>
        {renderTags}
        {chatTags?.length >= 7 && stackFlags && !isMobile ? (
          <div
            onClick={() => {
              handleTagDetails(chatDetails);
            }}
            className="seeMoreTags"
          >
            + Ver mais
          </div>
        ) : (
          (chatTags?.length >= 6 &&
          stackFlags &&
          isMobile) && (
            <div
              onClick={() => {
                handleTagDetails(chatDetails);
              }}
              className="seeMoreTags"
            >
              + Ver mais
            </div>
          )
        )}
      </>
    );
  }

  return <></>;
}

const mapStateToProps = (state, ownProps) => ({
  allTags: state.chat?.config?.general?.tags,
  chat: state.chat?.chats?.find((chat) => chat.id === ownProps.chat_id),
  props: ownProps,
  chatDetails: state.chat.showDetails,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(ChatActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Tags);
