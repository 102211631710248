import { put, all, call, takeLatest } from 'redux-saga/effects';
import {
  requestWhiteLabelConfigFailure,
  requestWhiteLabelConfigSuccess,
  actionNames,
} from './actions';

import { wlConfigRequest } from '../../../services/white-label/request';

function* whiteLabelConfigRequest() {
  try {
    let result = yield call(wlConfigRequest);
    yield put(requestWhiteLabelConfigSuccess(result));
  } catch (e) {
    console.log('login error', e);
    yield put(requestWhiteLabelConfigFailure(e));
  }
}

export default all([
  takeLatest(actionNames.REQUEST_WL_CONFIG, whiteLabelConfigRequest),
]);
