import {
  Col,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  CustomInput,
  CustomPassword,
  UncontrolledTooltip,
  Alert,
  Button,
  Icon,
} from '@polichat/flamboyant';
import Animation from '../../../../common/animation';
import logoPagSeguro from '../../../../../assets/images/polipay/pagseguro.svg';

import PageMainHeader from '../../../../common/pages/PageMainHeader/PageMainHeader.component';
import PageMainBody from '../../../../common/pages/PageMainBody/PageMainBody.component';
import CancelButton from '../../../common/CancelButton';
import SaveButton from '../../../common/SaveButton';
import { Logo } from '../gateways.style';

import usePagSeguroConfig from './usePagSeguroConfig';
import history from '../../../../../services/history';

function PagSeguroSettings() {
  const {
    isRequestLoading,
    isPageLoading,
    email,
    token,
    picture,
    customer_name,
    includeInstallment,
    maxInstallmentNoInterest,
    handleEmail,
    handleToken,
    handlePicture,
    handleCustomerName,
    handleIncludeInstallment,
    handleSubmit,
    handleMaxInstallmentsNoInterest,
  } = usePagSeguroConfig();

  return (
    <>
      <PageMainHeader title="Integrações" icon="poli-icon pi-pay-fill" />
      <PageMainBody>
        <div>
          <Logo src={logoPagSeguro} alt="Pagseguro" width={30} />
          <span>
            <strong>PagSeguro</strong>
          </span>
        </div>
        <div>
          {isRequestLoading && <Animation icon="chat-loading" />}
        </div>
        {isPageLoading ? (
          <Animation icon="chat-loading" size="large" />
        ) : (
          <Form
            className="catalogForm"
            onSubmit={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleSubmit();
            }}
          >
            <Row form>
              <Col lg={6}>
                <FormGroup>
                  <Label for="email">E-mail*</Label>
                  <Input
                    type="email"
                    name="email"
                    id="email"
                    value={email}
                    onChange={(e) => handleEmail(e.target.value)}
                    placeholder="E-mail da sua conta no PagSeguro"
                    required
                  />
                </FormGroup>
              </Col>

              <Col lg={6}>
                <FormGroup>
                  <Label for="token">Token de acesso*</Label>

                  <CustomPassword
                    type="password"
                    name="token"
                    id="token"
                    value={token}
                    onChange={(e) => handleToken(e.target.value)}
                    placeholder="Token de acesso da sua conta no PagSeguro"
                    required
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col lg={12}>
                <FormGroup>
                  <Label form="name">Nome:*</Label>
                  <Input
                    id="name"
                    type="text"
                    name="name"
                    value={customer_name}
                    onChange={(e) => handleCustomerName(e.target.value)}
                    placeholder="Nome da sua empresa na tela de checkout"
                    required
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col lg={12}>
                <FormGroup>
                  <Label form="picture" id="tooltip-label-picture">
                    Link da Foto:{' '}
                  </Label>
                  <UncontrolledTooltip
                    placement="right"
                    target={'tooltip-label-picture'}
                    autohide={false}
                  >
                    <div>
                      <h5 style={{ fontSize: '16px' }}>
                        Esse campo é opcional.
                      </h5>
                      <p style={{ textAlign: 'left' }}>
                        Insira o link da imagem hospedada. Verifique se o seu
                        link é com <strong>.jpg</strong> ou{' '}
                        <strong>.png</strong> no final, por exemplo: <br />
                        <strong>
                          https://img.techpowerup.org/200506/mesa-centro-supreme-preto.jpg
                        </strong>
                      </p>
                      <p style={{ textAlign: 'left' }}>
                        Sugestões de site para hospedar a imagem:
                        <br />*
                        <a
                          href="https://www.techpowerup.org/upload"
                          target="blank"
                        >
                          https://www.techpowerup.org/upload
                        </a>
                        <br />*
                        <a href="https://postimages.org/" target="blank">
                          https://postimages.org/
                        </a>
                      </p>
                    </div>
                  </UncontrolledTooltip>
                  <Input
                    id="picture"
                    type="url"
                    name="picture"
                    value={picture}
                    onChange={(e) => handlePicture(e.target.value)}
                    placeholder="Logomarca da sua empresa na tela de checkout"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col lg={12}>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    margin: 'auto',
                  }}
                >
                  <Alert color="primary" style={{ display: 'inline' }}>
                    <strong>ATENÇÃO: </strong> O número de parcelas,{' '}
                    <strong>sem juros</strong>, escolhido nesta configuração
                    será aplicado à todos os produtos cadastrados dentro do
                    PoliPay.
                  </Alert>
                </div>
              </Col>
              <Col lg={8}>
                <p>
                  Você escolhe o número máximo de parcelas que quer oferecer,
                  seus
                  <strong> compradores pagam sem juros </strong> e você absorve
                  o custo de parcelamento.
                </p>
              </Col>
              <Col lg={4}>
                <Button
                  onClick={() =>
                    window.open(
                      'https://pagseguro.uol.com.br/para-seu-negocio/taxas-e-tarifas#selling-simulation'
                    )
                  }
                >
                  Taxas e Tarifas do PagSeguro
                  <Icon
                    icon="pi-external-link-line"
                    size={20}
                    position={'relative'}
                    top={'0'}
                    right={'-5px'}
                  />
                </Button>
              </Col>
            </Row>
            <Row form style={{ marginBottom: '15px' }}>
              <Col>
                <FormGroup check inline>
                  <CustomInput
                    type="checkbox"
                    id="includeInstallment"
                    checked={includeInstallment}
                    onChange={(e) => handleIncludeInstallment(e.target.checked)}
                  />
                  <Label style={{ margin: '0' }}>Incluir parcelamento?</Label>
                </FormGroup>
              </Col>
            </Row>
            {includeInstallment ? (
              <Row form>
                <Col lg={6}>
                  <FormGroup>
                  <Label for="email">Quantidade de Parcelas Sem Juros *</Label>
                  <CustomInput
                    type="select"
                    name="maxInstallmentNoInterest"
                    id="maxInstallmentNoInterest"
                    value={maxInstallmentNoInterest}
                    onChange={(e) =>
                      handleMaxInstallmentsNoInterest(e.target.value)
                    }
                    placeholder="Quantidade de parcelas sem Juros"
                    required
                  >
                    <optgroup label="Quantas vezes sem juros?">
                        <option value={2}>2 x sem juros</option>
                        <option value={3}>3 x sem juros</option>
                        <option value={4}>4 x sem juros</option>
                        <option value={5}>5 x sem juros</option>
                        <option value={6}>6 x sem juros</option>
                        <option value={7}>7 x sem juros</option>
                        <option value={8}>8 x sem juros</option>
                        <option value={9}>9 x sem juros</option>
                        <option value={10}>10 x sem juros</option>
                        <option value={11}>11 x sem juros</option>
                        <option value={12}>12 x sem juros</option>
                      </optgroup>
                    </CustomInput>
                  </FormGroup>
                </Col>
              </Row>
            ) : null}

            <Row form className="buttonsCenterContainer">
              <CancelButton
                onClick={(e) => {
                  e.preventDefault();
                  history.goBack({
                    formProductType: null,
                  });
                }}
              />

              <Button
                gradient="primary"
                width="quarter"
                title="confirmar"
                style={{
                  cursor: !email || !token ? 'not-allowed' : 'pointer',
                }}
                disabled={!email || !token ? true : false}
              >
                Confirmar
              </Button>
            </Row>
          </Form>
        )}
      </PageMainBody>
    </>
  );
}

export default PagSeguroSettings;
