import axios from 'axios';
import ObjectToQueryString from '../../../lib/ObjectToQueryString';
import poliConfig from 'src/utils/poliConfig';

const defaultHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

const defaultConfig = {
  headers: defaultHeaders,
  withCredentials: true,
};

const checkConfig = (params = null) => {
  return axios.post(
    poliConfig.poliAppLaravelUrl +
      `/shopping/settings/payment/getSession` +
      (params ? '?' + ObjectToQueryString(params) : ''),
    {},
    defaultConfig
  );
};

const fetchConfig = () => {
  return axios.get(
    poliConfig.poliAppLaravelUrl + `/shopping/settings/payment`,
    defaultConfig
  );
};

const storeConfig = (params = null) => {
  return axios.post(
    poliConfig.poliAppLaravelUrl +
      `/shopping/settings/payment` +
      (params ? '?' + ObjectToQueryString(params) : ''),
    {},
    defaultConfig
  );
};
const fetchConfigMercadoPago = () => {
  return axios.get(
    poliConfig.poliAppLaravelUrl + `/shopping/gateway/mercadopago`,
    defaultConfig
  );
};

const storeConfigMercadoPago = (params = null) => {
  return axios.post(
    poliConfig.poliAppLaravelUrl +
      `/shopping/gateway/mercadopago`,
     params,
    defaultConfig
  );
};



export default { checkConfig, storeConfig, fetchConfig,storeConfigMercadoPago,fetchConfigMercadoPago };
