import { Icon }from '@polichat/flamboyant';
import ContactForm from '../contactForm';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';

function ContactEdit({ chatConfig }) {
  let show;
  if (chatConfig) {
    if (chatConfig.openEditContact) {
      show = true;
    } else {
      show = false;
    }
  }

  if (show) {
    return (
      <div className="contactContent">
        <div className="headerComponent">
          {!isMobile && (
            <div className="titleAddContact">
              <div className="smallCircle">
                <Icon
                  icon={'poli-icon pi-perfil-line'}
                  size={30}
                  color="var(--green-polichat"
                />
              </div>
              <h4>Editar Contato</h4>
            </div>
          )}
        </div>
        <ContactForm icon="poli-icon pi-add-circle-fill" type="edit" />
      </div>
    );
  } else {
    return <></>;
  }
}

const mapStateToProps = (state) => ({
  chatConfig: state.chat?.config,
});

export default connect(mapStateToProps)(ContactEdit);
