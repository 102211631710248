import { useCallback } from 'react';
import {
  Button,
  Icon,
  Modal,
  ModalBody,
  ModalFooter,
} from '@polichat/flamboyant';
import { CustomModalHeader } from './SessionExpiredModal.styles';

export const SessionExpiredModal = ({ showModal }: { showModal: boolean }) => {
  const redirectToLogin = useCallback(() => {
    window.location.href = '/login?force_login=true';
  }, []);

  return (
    <Modal isOpen={showModal}>
      <CustomModalHeader>
        <Icon
          icon="poli-icon pi-conection-fill"
          color="var(--danger)"
          size={60}
        />
        <span style={{ color: 'var(--danger)' }}>Sessão expirada!</span>
      </CustomModalHeader>
      <ModalBody>
        <p>Sua sessão foi expirada.</p>
        <p>
          Isso pode acontecer após deixar a tela de chat aberta por longos
          períodos de tempo.
        </p>
        <p>
          Por favor, clique no botão abaixo para fazer login novamente e
          continuar conversando com seus contatos.
        </p>
      </ModalBody>
      <ModalFooter>
        <Button onClick={redirectToLogin} color="primary">
          <span style={{ fontSize: '1.2rem' }}>Login</span>
        </Button>
      </ModalFooter>
    </Modal>
  );
};
