import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem;
  padding: 0 1rem;
  height: calc(100% - 20rem);

  @media (max-width: 800px) {
    height: auto;
  }
`;

export const TextInfos = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.9rem;
  margin-bottom: 1rem;
`;

export const InputsContainer = styled.div`
  display: grid !important;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    gap: 0.75rem;
  }
`;

export const InputItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  & .form-group {
    display: auto !important;
  }

  & label {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    & i {
      margin-right: 0.8rem;
    }
  }
`;
