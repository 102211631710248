import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media(max-width: 600px){
    padding: 0 0.5rem;
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;
`;

export const Title = styled.span`
  color: var(--primary-font-color);
  text-transform: capitalize;
  margin: 0 0.8rem;
  font-size: 1.2rem;
  font-weight: bold;
`;

export const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content:flex-end;
  gap: 0.5rem;

  @media(max-width: 600px){
    flex-wrap: wrap;
    justify-content: flex-start;
  }
`;
