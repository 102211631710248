import { useEffect, useState } from 'react';
import { Button, Icon } from '@polichat/flamboyant';

import { useCustomerVerifyChannelPlan } from '../useCustomerVerifyChannelPlan';

import { ModalHireAdditionalChannel } from '../ModalHireAdditionalChannel';

import MigrateButton from '../../MigrateButton';

interface ValidatePlanBeforeMigrateWabaProps {
  currentCustomer: number;
  children: any;
  migrationToWabaButtonData: any;
}

/**
 * NÃO ESTIVER EM PROCESSO DE MIGRAÇÃO
 *  VERIFICA SE TEM CANAL WABA NO PLANO
 *    SIM:: BOTÃO MIGRAR -> FLUXO NORMAL (obs: se for pro /channel/add/waba >> enviar o validated)
 *    NÃO:: ABRIR MODAL DE COMPRA DE CREDITO -> FLUXO DE MIGRAR
 */

export function ValidatePlanBeforeMigrateWaba(
  props: ValidatePlanBeforeMigrateWabaProps
) {
  /**
   * additional identifier param
   * DOCS: https://poli-digital.atlassian.net/browse/PS-1697
   */
  const WABA_ADDITIONAL_ID = 3;

  const { currentCustomer, migrationToWabaButtonData } = props;

  const {
    loading: loadingCustomerChannelPlanInfo,
    error: errorVerifyCustomerChannelPlanInfo,
    channelPlanInfo,
    blocked,
    verifyCustomerCanAddChannel,
  } = useCustomerVerifyChannelPlan();

  const [shouldOpenHireModal, setShouldOpenHireModal] = useState(false);

  const [
    isModalHireChannelAdditionalOpen,
    setIsModalHireChannelAdditionalOpen,
  ] = useState(false);

  async function verifyCustomerPlan() {
    const { should_buy, error, blocked } = await verifyCustomerCanAddChannel(
      currentCustomer,
      WABA_ADDITIONAL_ID
    );

    if (!error) {
      setShouldOpenHireModal(should_buy || blocked);
      setRenderMigrateButton(!should_buy && !blocked);
    }
  }

  useEffect(() => {
    (async () => {
      //se canal já está migrando redirecionar para página do processo
      if (migrationToWabaButtonData.processStatus === 'started') {
        setShouldOpenHireModal(false);
        setRenderMigrateButton(true);
      } else {
        await verifyCustomerPlan();
      }
    })();
  }, []);

  const [renderMigrateButton, setRenderMigrateButton] = useState(false);

  const handleCloseHireModal = () => {
    setRenderMigrateButton(true);
  };

  const handleOpenHireChannelBeforeMigrate = () => {
    !errorVerifyCustomerChannelPlanInfo &&
      setIsModalHireChannelAdditionalOpen(true);
    return;
  };
  return (
    <>
      <div>
        {loadingCustomerChannelPlanInfo && (
          <Button
            disabled
            gradient="primary"
            className="migrate-button-container"
          >
            <Icon
              icon="poli-icon pi-whatsapp-business-line"
              color="#fff"
              size="20"
            />
            Carregando...
          </Button>
        )}

        {shouldOpenHireModal && !renderMigrateButton && (
          <Button
            gradient="primary"
            className="migrate-button-container"
            onClick={() => handleOpenHireChannelBeforeMigrate()}
          >
            <Icon
              icon="poli-icon pi-whatsapp-business-line"
              color="#fff"
              size="20"
            />
            Migrar para Waba
          </Button>
        )}

        {errorVerifyCustomerChannelPlanInfo && (
          <>
            <Button
              gradient="primary"
              className="migrate-button-container"
              onClick={() => verifyCustomerPlan()}
            >
              <Icon
                icon="poli-icon pi-whatsapp-business-line"
                color="#fff"
                size="20"
              />
              Migrar para Waba
            </Button>
          </>
        )}

        {renderMigrateButton && (
          <MigrateButton
            id={migrationToWabaButtonData.id}
            channelPhone={migrationToWabaButtonData.channelPhone}
            channelName={migrationToWabaButtonData.channelName}
            processStatus={migrationToWabaButtonData.processStatus}
            validatedPlan={true}
          />
        )}
      </div>

      {/* MODAL TO HIRE ADDITIONAL CHANNEL */}
      {isModalHireChannelAdditionalOpen && (
        <ModalHireAdditionalChannel
          modalIsOpen={isModalHireChannelAdditionalOpen}
          // @ts-ignore
          toggleModal={() => {
            setIsModalHireChannelAdditionalOpen((prev) => !prev);
          }}
          // @ts-ignore
          channelPlanInfo={channelPlanInfo}
          closeModalAction={handleCloseHireModal}
          customerId={currentCustomer}
          blocked={blocked}
        />
      )}
    </>
  );
}
