import { DtoPoliAdsMetric } from '../../../services/meta-ads/ad/types';
import { DtoMetaAdsPaginatedResult } from '../../../services/meta-ads/dto/dtoMetaAdsPaginatedResult';
import { MetaAdsPaginationParams } from '../../../services/meta-ads/dto/dtoMetaAdsPaginationParams';
import { DtoPoliAdsPost } from '../../../services/meta-ads/post/types';
import { DtoDeprecatedCustomer } from '../../../services/meta-business/businessManager/types';
import {
  PaginationInfo,
  PoliAdsMetricItem,
} from './metrics/useMetaAdMetricService/types';

export function getOrganizationIdFromDtoDeprecatedCustomer(
  dto: DtoDeprecatedCustomer
) {
  if (!dto.organization_id) return undefined;

  return dto.organization_id;
}

export function getPoliAdsMetricFromDtoMetaAdsMetricData(
  data: DtoPoliAdsMetric[] | DtoPoliAdsPost[]
): PoliAdsMetricItem[] {
  const items = data?.map((item) => ({
    //TODO: how to fix type error ??
    // @ts-ignore
    id: item.ads_id || item.post_id,
    title: item.title,
    description: item.description,
    messages_counter: item.messages,
    format: item.format || null,
    resources_url: item.resources_url || null,
  }));

  return items;
}

/**
 *
 * @param data
 * @returns
 */
export function getPaginationServiceFormatFromSearchParams(
  data: PaginationInfo
): MetaAdsPaginationParams {
  let offset;

  if (data.page) {
    offset = data.page - 1;
  }

  return {
    limit: data.per_page || undefined,
    offset,
  };
}

export function getPaginationInfoFromServiceResponse(
  data: Partial<
    DtoMetaAdsPaginatedResult<DtoPoliAdsMetric[] | DtoPoliAdsPost[]>
  >
): PaginationInfo {
  let page;

  if (data.current_page || data.current_page === 0) {
    page = data.current_page + 1;
  }
  const _paginationInfo: PaginationInfo = {
    page,
    per_page: data?.data?.length,
    total_pages: data.total_pages,
  };

  return _paginationInfo;
}
