import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Form, FormGroup, Row, Col, Button } from '@polichat/flamboyant';
import { toast } from 'react-toastify';

import history from '../../../../services/history';
import WabaService from '../../../../services/tools/template-waba/template-waba';
import ChannelsService from '../../../../services/tools/channels/channels';

import PageMainHeader from '../../../common/pages/PageMainHeader/PageMainHeader.component';
import PageMainBody from '../../../common/pages/PageMainBody/PageMainBody.component';

import {
  TemplateSelectBuilderType,
  TemplateNameInput,
  TemplateOptionSpecificBonds,
  TemplateTagInput,
  TemplateShortcutInput,
  TemplateCategoryInput,
  TemplateTitleInput,
  TemplateLanguageInput,
  TemplateTitleVariableInput,
  TemplateMessageInput,
  TemplateMessageVariableOneInput,
  TemplateMessageVariableTwoInput,
  TemplateBaseboardInput,
  TemplateButtonInput,
  TemplateButtonVariableInput,
} from './common';

import SelectChannelWaba from './waba/channel/SelectChannelWaba.component';
import FormTemplateWaba from './waba/FormTemplateWaba.component';
import MessagePreview from '../preview/MessagePreview.component';

import { Container, FormContainer } from './FormMessagesBuilder.styles';
import { useWhiteLabel } from 'src/hooks/useWhiteLabel';

const MessageBuilderImportTemplate = ({ data }) => {
  const whitelabel = useWhiteLabel();
  const languageId_ptbr = 46;
  const waba_channel_id = 6;
  const customerID = useSelector((state) => state.general?.customer_id);
  const isEdit = data ? true : false;

  const [formTemplateData, setFormTemplateData] = useState({
    templateId: data?.id || '',
    templateChannelCustomer: data?.channel_customer_id || '',

    templateType: data?.template_waba ? 'waba' : 'waba',
    templateName: data?.template_name || '',
    templateTag: data?.tag || '',
    templateShortcut: data?.keyboard_shortcut || '',
    templateCategory: data?.template_category || '',
    templateTitle: data?.template_title || '',
    templateLanguage: data?.template_language || '',
    templateTitleVariable: data?.template_titleVariable || '',
    templateMessage: data?.template_message || '',
    templateMessageVariableOne: data?.template_messageVariableOne || '',
    templateMessageVariableTwo: data?.template_messageVariableTwo || '',
    templateBaseboard: data?.template_baseboard || '',
    templateButton: data?.template_button || '',
    templateButtonVariable: data?.template_buttonVariable || '',
    attendants: data?.users || [],
    departments: data?.departments || [],
    buttons: data?.buttons || [],
    wabaValues: {
      header: data?.header || { type: 'none', text: '', mediaUrl: '' },
      body: {
        text: data?.body || typeof data?.text === 'string' ? data.text : '',
      },
      footer: { text: data?.footer || '' },
      buttons: data?.buttons || [],
      options: {
        language: data?.language || languageId_ptbr,
        category: data?.category || 'none',
      },
    },
  });

  const [templatesType, setTemplatesType] = useState([
    { id: 1, type: 'waba', description: 'Template', active: true },
  ]);

  const disableTemplate = (id) => {
    setTemplatesType(
      templatesType.map((template) => {
        if (template.id === id) {
          template.active = false;
        }
        return template;
      })
    );
  };

  const [wabaChannelsList, setWabaChannelsList] = useState([]);
  const [channelsInitialValue, setChannelsInitialValue] = useState({});
  useEffect(() => {
    async function fetchWabaChannels() {
      try {
        const result = await ChannelsService.getAllChannels({
          customer_id: customerID,
          // filtro por canal tipo waba
          // channel_id: waba_channel_id,
        });
        if (result.data.length > 0) {
          // handleFormTemplateData({
          // });
          setChannelsInitialValue({
            templateChannelCustomer: result.data[0].id,
          });
          setWabaChannelsList(
            result.data.filter(
              (channel) =>
                (channel.api === 33 || channel.api === 34 || channel.api === 10) &&
                // || channel.channel_id === 6
                !channel.homologation
            )
          );
        } else {
          disableTemplate(1);
        }
      } catch (error) {
        console.error(
          'Error in MessageBuilderImportTemplate component when getAllChannels from channel service',
          error
        );
      }
    }
    fetchWabaChannels();
  }, []);

  const handleFormTemplateData = (inputsData) => {
    setFormTemplateData({
      ...formTemplateData,
      ...inputsData,
    });
  };

  const handleFormWabaData = (formWabaData) => {
    setFormTemplateData({
      ...formTemplateData,
      ...formWabaData,
      wabaValues: {
        ...formWabaData,
      },
    });
  };

  const handleSubmitTemplate = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    let params = {};

    if (formTemplateData.templateType === 'waba') {
      //validation header
      if (
        formTemplateData.wabaValues?.header.type === 'media' &&
        formTemplateData.wabaValues?.header.mediaUrl === ''
      ) {
        toast.warning('Escolha um arquivo para a template');
        return;
      }
      if (formTemplateData.wabaValues?.header.type !== 'media') {
        if (formTemplateData.wabaValues?.header.mediaUrl !== '') {
          //remover a midia da AWS
          WabaService.deleteTemplateAwsUrlAssignedFile({
            access_url_delete: formTemplateData.wabaValues?.header.mediaUrl,
          });
        }
      }
      if (
        formTemplateData.wabaValues?.buttons.length > 0 &&
        formTemplateData.wabaValues.buttons.some(
          (button) =>
            button.actionType === 'call' && button.actionText.length < 12
        )
      ) {
        toast.warning(
          'O telefone do botão ligar deve ser preenchido e válido!'
        );
        return;
      }

      params = {
        channel_id:
          formTemplateData.templateChannelCustomer ||
          channelsInitialValue.templateChannelCustomer,
        formData: {
          // general template data
          type: formTemplateData.templateType,
          tag: formTemplateData.templateTag,
          name: formTemplateData.templateName,
          shortcut: formTemplateData.templateShortcut,
          category: formTemplateData.TemplateCategory,
          title: formTemplateData.TemplateTitleInput,
          language: formTemplateData.TemplateLanguageInput,
          variable: formTemplateData.TemplateTitleVariableInput,
          message: formTemplateData.TemplateMessageInput,
          messageVariableOne: formTemplateData.TemplateMessageVariableOneInput,
          messageVariableTwo: formTemplateData.TemplateMessageVariableTwoInput,
          baseboard: formTemplateData.TemplateBaseboardInput,
          button: formTemplateData.TemplateButtonInput,
          buttonVariable: formTemplateData.TemplateButtonVariableInput,
          departments: formTemplateData.departments,
          users: formTemplateData.attendants,
          //waba specific data
          header: formTemplateData.wabaValues?.header,
          body: formTemplateData.wabaValues?.body?.text,
          footer: formTemplateData.wabaValues?.footer?.text,
          buttons: formTemplateData.wabaValues?.buttons,
          category: formTemplateData.wabaValues?.options?.category,
          language: formTemplateData.wabaValues?.options?.language,
        },
      };
      if (isEdit) {
        const { formData, channel_id } = params;
        delete params.channel_id;
        params = {
          template_id: formTemplateData.templateId,
          formData: { ...formData, channel_id },
        };

        const onToastClose = () => history.push('/tools/messages-builder');
        const result = await WabaService.editTemplateWaba(params);

        if (result.error) {
          toast.error('Erro ao editar template!');
        }
        if (result.success) {
          toast.success('Template editado!', { onClose: onToastClose });
        }

        return;
      }

      const result = await WabaService.createTemplateWaba(params);
      if (result.error) {
        toast.error('Erro ao cadastrar template!');
      }
      if (result.success) {
        toast.success('Template enviada para análise!', {
          onClose: onToastClose,
        });
      }
    } else if (formTemplateData.templateType === 'quick') {
      toast.info('Formulário em desenvolvimento');
    }
  };

  return (
    <>
      <PageMainHeader
        title={`Construtor de ${whitelabel.isWl ? 'Mensagem rápida' : 'Template'}`}
        icon="poli-icon pi-message-builder-fill"
      />
      <PageMainBody>
        <Container>
          <FormContainer>
            <p>
              Comece a construir seu modelo de mensagem aqui, depois você poderá
              utiliza-lo como mensagem rápida, template ou então automatizá-lo
              com o Bot.
            </p>
            <Form onSubmit={handleSubmitTemplate}>
              <FormGroup>
                <Row>
                  <TemplateSelectBuilderType
                    onUpdate={handleFormTemplateData}
                    initialValue={{
                      initialTemplateType: formTemplateData.templateType,
                      templateTypeList: templatesType,
                    }}
                    isEdit={isEdit}
                  />
                  <TemplateNameInput
                    onUpdate={handleFormTemplateData}
                    initialValue={formTemplateData.templateName}
                    isEdit={isEdit}
                  />
                </Row>

                <Row>
                  {formTemplateData.templateType === 'waba' && (
                    <SelectChannelWaba
                      onUpdate={handleFormTemplateData}
                      initialValue={{
                        initialChannelCustomer:
                          channelsInitialValue.templateChannelCustomer,
                        channelsWabaList: wabaChannelsList,
                      }}
                      isEdit={isEdit}
                    />
                  )}
                  <TemplateOptionSpecificBonds
                    haveOffSet={
                      formTemplateData.templateType === 'waba' ? false : true
                    }
                    onUpdate={handleFormTemplateData}
                    initialValue={{
                      departments: formTemplateData.departments,
                      attendants: formTemplateData.attendants,
                    }}
                  />
                </Row>

                <Row>
                  <TemplateTagInput
                    onUpdate={handleFormTemplateData}
                    initialValue={formTemplateData.templateTag}
                  />
                  <TemplateShortcutInput
                    onUpdate={handleFormTemplateData}
                    initialValue={formTemplateData.templateShortcut}
                  />
                </Row>

                {/* <Row>
                  <TemplateCategoryInput
                    onUpdate={handleFormTemplateData}
                    initialValue={formTemplateData.templateCategory}
                  />
                </Row> */}

                <FormGroup>
                  {formTemplateData.templateType === 'waba' && (
                    <FormTemplateWaba
                      onUpdate={handleFormWabaData}
                      initialValue={formTemplateData.wabaValues}
                      isEdit={isEdit}
                    />
                  )}
                </FormGroup>

                {/*<Row>
                  <TemplateTitleInput
                    onUpdate={handleFormTemplateData}
                    initialValue={formTemplateData.templateTitle}
                  />
                  <TemplateLanguageInput
                    onUpdate={handleFormTemplateData}
                    initialValue={formTemplateData.templateLanguage}
                  />
                </Row>

                <Row>
                  <TemplateTitleVariableInput
                    onUpdate={handleFormTemplateData}
                    initialValue={formTemplateData.templateTitleVariable}
                  />
                  <TemplateMessageInput
                    onUpdate={handleFormTemplateData}
                    initialValue={formTemplateData.templateMessage}
                  />
                </Row> */}

                {/* <Row>
                  <TemplateMessageVariableOneInput
                    onUpdate={handleFormTemplateData}
                    initialValue={formTemplateData.templateMessageVariableOne}
                  />
                  <TemplateMessageVariableTwoInput
                    onUpdate={handleFormTemplateData}
                    initialValue={formTemplateData.templateMessageVariableTwo}
                  />
                </Row>

                <Row>
                  <TemplateBaseboardInput
                    onUpdate={handleFormTemplateData}
                    initialValue={formTemplateData.templateBaseboard}
                  />
                </Row>

                <Row>
                  <TemplateButtonInput
                    onUpdate={handleFormTemplateData}
                    initialValue={formTemplateData.templateButton}
                  />
                  <TemplateButtonVariableInput
                    onUpdate={handleFormTemplateData}
                    initialValue={formTemplateData.templateButtonVariable}
                  />
                </Row> */}
              </FormGroup>

              {/* Confirm subimt */}
              <Row>
                <Col sm={12} md={{ size: 6, offset: 3 }}>
                  <FormGroup>
                    <Button gradient="primary">
                      {formTemplateData.templateType === 'waba'
                        ? 'Salvar e enviar para aprovação'
                        : 'Salvar'}
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </FormContainer>
          <MessagePreview data={formTemplateData} />
        </Container>
      </PageMainBody>
    </>
  );
};

export default MessageBuilderImportTemplate;
