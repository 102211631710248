import { ApiList, ChannelList } from '../../enums/channels';

const channelTypeNames = Object.freeze({
  WABA: 'WABA',
  WHATSAPP: 'WhatsApp',
  MESSENGER: 'Messenger',
  MAIL: 'Mail',
  INSTAGRAM: 'Instagram',
  WEBCHAT: 'WebChat',
  INVALID: 'Canal Inválido!',
});

function getChannelExtra(data) {
  if (!data || (!data.api && !data.channel_id))
    return {
      icon: 'poli-icon pi-alert-fill',
      color: '#FF0000',
      typeName: channelTypeNames.INVALID,
    };

  if (data.api === ApiList.waba || data.api === ApiList.cloudapi)
    return {
      icon: 'poli-icon pi-whatsapp-business-fill',
      color: '#00C48C',
      typeName: channelTypeNames.WABA,
    };

  switch (data.channel_id) {
    case ChannelList.whatsapp:
      return {
        icon: 'poli-icon pi-whatsapp-fill',
        color: '#00C48C',
        typeName: channelTypeNames.WHATSAPP,
      };
    case ChannelList.waba:
      return {
        icon: 'poli-icon pi-whatsapp-fill',
        color: '#00C48C',
        typeName: channelTypeNames.WABA,
      };
    case ChannelList.facebook:
      return {
        icon: 'poli-icon pi-messenger-fill',
        color: '#0084F4',
        typeName: channelTypeNames.MESSENGER,
      };

    case ChannelList.email:
      return {
        icon: 'poli-icon pi-mail-fill',
        color: '#0084F4',
        typeName: channelTypeNames.MAIL,
      };

    case ChannelList.webchat:
      return {
        icon: 'poli-icon pi-webchat-fill',
        color: '#FFCA5D',
        typeName: channelTypeNames.WEBCHAT,
      };

    case ChannelList.instagram:
      return {
        icon: 'poli-icon pi-instagram-fill',
        color: '#ff665d',
        typeName: channelTypeNames.INSTAGRAM,
      };

    default:
      return {
        icon: 'poli-icon pi-alert-fill',
        color: '#FF0000',
        typeName: channelTypeNames.INVALID,
      };
  }
}

export { channelTypeNames, getChannelExtra };
