import React, { useEffect, useRef, useState } from 'react';

import AuthLayout from '../../components/layouts/authLayout';
import loadingMain from '../../lotties/loading-main.json';
import { useDispatch, useSelector } from 'react-redux';
import { requestLogin } from '../../store/modules/auth/actions';

import {
  Button,
  Icon,
  Input,
  Form,
  CustomPassword,
  LabelCustom,
} from '@polichat/flamboyant';
import {
  LoginText,
  LoginCheckBox,
  LoginButtonContainer,
  MensagemErro,
} from './stylesLogin';
import Animation from '../../components/common/animation';


function Login() {
  const [inputEmail, setInputEmail] = useState('');
  const inputPassword = useRef(null);
  const dispatch = useDispatch();
  const loginState = useSelector((state) => state.auth.login);

  const [invalidEmailInput, setInvalidEmailInput] = useState(false);
  const [invalidPasswordInput, setInvalidPasswordInput] = useState(false);
  const [generalLoginError, setGeneralLoginError] = useState(false);
  const [messageLoginError, setMessageLoginError] = useState(null);

  const isWhiteLabel = useSelector((state) => state?.whitelabel?.config?.is_white_label);
  const wlConfig = useSelector((state) => state?.whitelabel?.config);

  useEffect(() => {
    if (
      typeof loginState.error === 'undefined' ||
      Object.keys(loginState.error ?? {}).length === 0
    )
      return;

    switch (Number(loginState.error.code)) {
      case 401:
        messageError(loginState.error?.response?.data?.message);
        setInvalidEmailInput(true);
        setInvalidPasswordInput(true);
        break;
      default:
        setGeneralLoginError(true);
        break;
    }
  }, [loginState]);

  useEffect(() => {
    document.title = isWhiteLabel ? "Página de Login" : "Poli - Página de Login";
  }, [isWhiteLabel]);

  const messageError = (message) => {
    if (message == 'Limit exceeded login') {
      setMessageLoginError(
        'Você ultrapassou o limite de tentativas! Aguarde 15min.'
      );
    } else {
      setMessageLoginError(null);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(requestLogin(inputEmail, inputPassword.current.value));
  };

  const loginLoading = () => {
    return (
      <AuthLayout>
        <Animation
          icon="loading"
          size={50}
          style={{
            border: '6px solid transparent',
            borderColor: 'var(--background-spinner)',
            borderTopColor: 'var(--green-polichat)',
          }}
        />
        <div style={{ marginTop: 40 }}></div>
      </AuthLayout>
    );
  };

  const loginTela = () => {
    return (
      <AuthLayout title="Seja bem-vindo!">
        <Form onSubmit={handleSubmit}>
          <LoginText>
            <div>
              <Icon
                size={25}
                icon={'poli-icon pi-mail-line'}
                invalid={invalidEmailInput}
              ></Icon>
            </div>
            <span>
              <LabelCustom invalid={invalidEmailInput}>E-mail:</LabelCustom>
            </span>
          </LoginText>

          <Input
            width="half"
            type="email"
            value={inputEmail}
            invalid={invalidEmailInput}
            onChange={(e) => {
              setInputEmail(e.target.value);
              if (invalidEmailInput) {
                setInvalidEmailInput(false);
              }
            }}
          ></Input>

          <LoginText>
            <div>
              <Icon
                size={25}
                icon={'poli-icon pi-key-line'}
                invalid={invalidPasswordInput}
              ></Icon>
            </div>
            <span>
              <LabelCustom invalid={invalidPasswordInput}>Senha:</LabelCustom>
            </span>
          </LoginText>

          <CustomPassword
            type="password"
            width="half"
            innerRef={inputPassword}
            invalid={invalidPasswordInput}
            onChange={() => {
              if (invalidPasswordInput) {
                setInvalidPasswordInput(false);
              }
            }}
          ></CustomPassword>

          <MensagemErro>
            {messageLoginError
              ? messageLoginError
              : invalidEmailInput || invalidPasswordInput
              ? 'Email/Senha inválidos'
              : generalLoginError
              ? 'Erro ao tentar logar'
              : ''}
          </MensagemErro>

          <LoginCheckBox>
            {/* <Input type="checkbox" id="checkboxConectar"></Input> */}
            {/* <span className="textCheckbox">Mantenha-me conectado.</span> */}
            <a className="linkCheckbox" href="/recover-password">
              Esqueci minha senha
            </a>
          </LoginCheckBox>

          <LoginButtonContainer className="center">
            <Button style={{ width: '100%' }} type="submit">
              Login
            </Button>
          </LoginButtonContainer>
        </Form>
      </AuthLayout>
    );
  };

  if (loginState.loading) {
    if(wlConfig.is_white_label && wlConfig.login_url && wlConfig.login_url != ''){
      return (<Loading />);
    }
    return loginLoading();
  } else {
    /**
     * verifica se existe uma url customizada para fazer login e redireciona
     */
    if(window.location.href.includes(wlConfig.spa_url) && window.location.pathname.includes('login') && wlConfig.is_white_label && wlConfig.login_url && wlConfig.login_url != ''){
      window.location=wlConfig.login_url;
      return;
    }
    return loginTela();
  }

}

const Loading = () => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
      }}
    >
      <Animation
        icon="loading"
        size={50}
        style={{
          border: '6px solid transparent',
          borderColor: 'var(--background-spinner)',
          borderTopColor: 'var(--green-polichat)',
        }}
      />
    </div>
  );
};

export default Login;
