import React, { useEffect, useState }from 'react';
import { useSelector } from 'react-redux';

import logo from '../../assets/images/logo-polichat.svg';
import loginNewPoli from '../../assets/images/newIdentity/logo-polichat.svg';
//import chatshubLogo from '../../assets/images/whitelabel/chatshub/chatshub-horizontal.png';
import messenger from '../../assets/images/messenger-svg.svg';
import webchat from '../../assets/images/webchat-svg.svg';
import indicationImage from '../../assets/images/auth-image-indication.svg';
import chatshubBanner from '../../assets/images/whitelabel/chatshub/chatshub-banner-transparent.png';
import newPoliBanner from '../../assets/images/newIdentity/new-identity-poli-banner.png';
import wpp from '../../assets/images/wpp-svg.svg';
import PageLogin from '../../pages/auth/login';
import { isMobile } from 'react-device-detect';
import { useFlags } from 'flagsmith/react';

import {
  Button,
  Icon,
  CustomInput,
  Container,
  Row,
  Col,
  CustomPassword,
} from '@polichat/flamboyant';
import {
  ContainerMaster,
  AdsContainer,
  AdsIconContainer,
  AdsItemIcon,
  AdsItemHeader,
  AdsItemSingleText,
  AdsItemText,
  AdsItemContainer,
  CallToAction,
  CallToActionIcon,
  AdsItemTextFooter,
  LoginContainer,
  LoginDiv,
  LoginButtonCookies,
  ContainerMain,
} from './styles';

function AuthLayout({ children, title, titleColor }) {
  const wlState = useSelector((state) => state?.whitelabel);
  const [isWhitelabel, setIsWhitelabel] = useState(false);

  useEffect(() => {
    const _isWl = wlState?.config?.is_white_label;

    if (_isWl) {
      setIsWhitelabel(_isWl);
    }
  }, [wlState]);

  const col1 = (
    <div className='banner-container'>
      {isWhitelabel
        ?(
          <div
            className='login-banner'
            alt="Imagem do Banner"
          ></div>
        )
        :(
          <div
            className='login-banner'
            alt="Imagem de indicação"
            onClick={() => {
              window.open('https://poli.digital/ligacao-chat/', '_blank');
            }}
          ></div>
        )
      }
    </div>
  );
  const col2 = (
    <LoginContainer>
      <LoginDiv>
        <a href="/login">
          <div className="form-logo"></div>
        </a>
        <span style={{ color: titleColor ? titleColor : 'var(--textDefault)', fontSize: '32px' }}>
          {title}
        </span>
      </LoginDiv>
      {children}
      <LoginButtonCookies className="login-footer">
        {!isWhitelabel && (
          <a
            style={{ 'font-size': '14px', color: '#767676' }}
            href="https://polichat.com.br/privacidade-e-seguranca/"
          >
            Confira nossa politica de segurança e privacidade
          </a>
        )}
      </LoginButtonCookies>
    </LoginContainer>
  );

  let container = null;

  if (isMobile) {
    container = (
      <Container className="mobile">
        <Row>
          <Col sm="12">{col2}</Col>
        </Row>
      </Container>
    );
  } else {
    container = (
      <Container>
        <Row>
          <Col sm="7" className="p-0">
            {col1}
          </Col>
          <Col sm="5" className="p-0">
            {col2}
          </Col>
        </Row>
      </Container>
    );
  }
  return <ContainerMaster>{container}</ContainerMaster>;
}

export default AuthLayout;
