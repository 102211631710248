import { useCallback, useState } from 'react';
import { useRegisterMetaService } from 'src/components/tools/channels/hooks/useRegisterMetaService';
import { MetaService } from '../../../../../services/meta-business/meta';
import {
  getDtoPhoneCodeMetaFromVerifyCodeFormValues,
  getDtoRequestCodeFromRequestCodeFormValues,
} from '../../helpers';
import { RequestCodeFormValues } from '../useLocalRequestCodeForm/types';
import { VerifyCodeFormValues } from '../useLocalVerifyCodeForm/types';

export interface UseMetaServiceProps {
  phone_id: number;
}
export function useMetaService(props: UseMetaServiceProps) {
  const [loading, setLoading] = useState(false);

  const { loading: loadingRegister, register } = useRegisterMetaService({
    phone_id: props.phone_id,
  });
  // ---------------------------------------------
  // Functions

  const code = useCallback(
    async (
      values: RequestCodeFormValues,
      onSuccess: (status: number) => void,
      onError: (value?: string) => void
    ) => {
      const dto = getDtoRequestCodeFromRequestCodeFormValues(values);
      try {
        setLoading(true);
        const response = await MetaService.RequestCode(props.phone_id, dto);
        onSuccess(response.status!);
      } catch (error) {
        // @ts-ignore
        onError(error?.response?.data?.message ? error?.response?.data?.message : null);
      } finally {
        setLoading(false);
      }
    },
    [props.phone_id]
  );

  const verify = useCallback(
    async (
      values: VerifyCodeFormValues,
      onSuccess: () => void,
      onError: (value?: string) => void
    ) => {
      try {
        setLoading(true);
        const dto = getDtoPhoneCodeMetaFromVerifyCodeFormValues(values);
        const response = await MetaService.VerifyCode(props.phone_id, dto);
        onSuccess();
      } catch (error) {
        // @ts-ignore
        onError(error?.response?.data?.message ? error?.response?.data?.message : null);
      } finally {
        setLoading(false);
      }
    },
    [props.phone_id]
  );

  // ---------------------------------------------
  // Effects
  // ---------------------------------------------
  // Transformations
  // ---------------------------------------------
  // API

  return {
    loading,
    loadingRegister,
    code,
    verify,
    register,
  };
}
