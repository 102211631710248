import { useCallback, useEffect, useState } from 'react';
import { initFacebookSDK } from '../sdk/initFacebookSDK';
import Animation from '../../../../components/common/animation';
import {
  FacebookAdsContainer,
  IframeContainer,
  ContainerTitle,
} from '../../components/style/fbiframe';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Button } from '@polichat/flamboyant';
import { useCustomerHasPluginInstalled } from './useCustomerHasPluginInstalled';

import './index.css';
export interface Customer {
  id: number;
  name: string;
  contact_email: string;
  phone: string;
  site: string;
  adress_street: string;
  adress_city: string;
  adress_state: string;
  adress_postal_code: string;
  photo: string;
  created_at: string;
}

export function AdsCreateFacebookPage() {
  const URL_SPA = window?.location?.origin ?? 'https://app-spa.poli.digital';
  const isPopupClose = window?.location?.search?.includes('close=popup');

  const FACEBOOK_DATA_DEFAULT = Object.freeze({
    business_config: {
      business: {
          name: ''
      },
      ig_cta: 'enabled',
    },
    setup: {
      external_business_id: '',
      timezone: 'America/Sao_Paulo',
      currency: 'BRL',
      business_vertical: 'MESSAGING',
    },
    repeat: false,
  });

  const [renderIframe, setRenderIframe] = useState(0);
  const [fbSDKinitiated, setFbSDKinitiated] = useState<boolean>();
  const [dataFb, setDataFb] = useState(FACEBOOK_DATA_DEFAULT);

  const customer_id: number = useSelector(
    // @ts-ignore
    (state) => state?.general?.current_customer_id
  );

  const customers: Customer[] = useSelector(
    // @ts-ignore
    (state) => state?.general?.user?.customers
  );

  const [isShowButton, setIsShowButton] = useState(true);

  const service = useCustomerHasPluginInstalled({ customer_id });
  const { uninstall } = service;

  const handleUninstallAccountMetaPlugin = useCallback(() => {
    if (
      !service.hasAccountMetaInstall ||
      !service.accountMetaId ||
      service.error.error
    ) {
      handleUninstallAccountMetaPluginError();
      return;
    }

    uninstall(
      service.accountMetaId,
      handleUninstallAccountMetaPluginSuccess,
      handleUninstallAccountMetaPluginError
    );
  }, [uninstall, service.accountMetaId, service.hasAccountMetaInstall]);

  function handleUninstallAccountMetaPluginSuccess() {
    toast.success('Plugin desinstalado com sucesso!');
    setIsShowButton(false);
  }
  function handleUninstallAccountMetaPluginError() {
    toast.error('Error ao remover, tente novamente!');
  }

  useEffect(() => {
    if (customers && Array.isArray(customers) && customer_id) {
      const _customer = customers.find((e) => e.id === customer_id);
      if (_customer?.id) {
        const _dataFbDefault = { ...FACEBOOK_DATA_DEFAULT };
        _dataFbDefault.business_config.business.name = _customer.name;
        _dataFbDefault.setup.external_business_id = _customer.id.toString();
        setDataFb({ ..._dataFbDefault });
      }
    }
  }, [customers, customer_id]);

  useEffect(() => {
    if (isPopupClose) {
      localStorage.setItem('callbackFBAdsCreate', 'true');
      toast.success('Carregando...');
      setTimeout(() => {
        window.close();
      }, 1000);
    }
  }, [isPopupClose]);

  useEffect(() => {
    initFacebookSDK().then(() => {
      setTimeout(() => {
        setFbSDKinitiated(true);
      }, 1500);
    });
  }, [renderIframe]);

  /**
   * Interval retorno callback conexão com FB
   * - Reiniciar o iframe apos login
   */
  useEffect(() => {
    const intervalId = setInterval(() => {
      const callbackFBAdsCreate = localStorage.getItem('callbackFBAdsCreate');
      if (callbackFBAdsCreate) {
        setFbSDKinitiated(false);
        setRenderIframe((prev) => prev + 1);
        localStorage.removeItem('callbackFBAdsCreate');
      }
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);
  /* end */

  return (
    <>
      <FacebookAdsContainer>
        <ContainerTitle>
          <h3>Cresça seu negócio com Facebook</h3>

          {service.hasAccountMetaInstall && isShowButton && (
            <Button
              id="tooltipUnistallButton"
              onClick={handleUninstallAccountMetaPlugin}
              disabled={service.loading}
            >
              Desinstalar
            </Button>
          )}
        </ContainerTitle>

        {!fbSDKinitiated && (
          <div className="alignLoading">
            <Animation
              icon="chat-loading"
              style={{
                maxWidth: '150px',
                height: '100%',
                maxHeight: '100px',
                border: '1px solid red',
              }}
            />
          </div>
        )}

        <IframeContainer>
          <div
            key={renderIframe}
            id="fbIframeCreate"
            className="fb-lwi-ads-creation"
            data-fbe-extras={JSON.stringify(dataFb)}
            data-fbe-scopes="manage_business_extension"
            data-fbe-redirect-uri={`${URL_SPA}/ads/facebook/create?close=popup`}
          ></div>
        </IframeContainer>
      </FacebookAdsContainer>
    </>
  );
}
