import styled from 'styled-components'

export const ContainerLabelButton = styled.div`
  display: flex;
  align-items: center;
  > img {
    margin-right: 8px;
  }

`

export const MarginDivs = styled.div`
  display: flex;
  & div {
    margin-left: 12px;
  }
`

export const ContainerStyle = styled.div`
  /* display: flex; */
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */

  .custom-select {
    background-size: 30px !important;
  }


  input, select {
  //   width: 9.34rem;
    height: 44px;
    border-radius: 0.375rem;
  }

  select:disabled {
    color: #747778;
  }

  .form-row {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .rowTitle {
    > span {
      font-family: 'Arial';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 18px;
      margin-left: 8px;
      color: var(--secondary-font-color);
      margin-top: 4px;
    }
  }

  .button-submit {
    width: 146px;
    height: 44px;
    margin-top: 1.875rem;
    background: var(--primary);
    border: none;
    border-radius: 0.375rem;
  }

  .button-download {
    border: 1px solid var(--primary);
    width: 70px;
    height: 44px;
    margin-top: 1.875rem;
    background-color: transparent;
    border-radius: 0.25rem;

    &:disable {
      background-color: transparent;
    }

    &:hover {
      background-color: var(--primary);
    }
  }


  .col-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const TitleExportsList = styled.div`
  display: flex;
  flex-direction: column;
  height: 10rem;
￼ justify-content: space-around;

  .title-exports {
    i {
      padding-left: 10px;
      padding-right: 10px;
    }
    h2 {
      margin: 0
    }
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .subtitle-exports {
    color: var(--Secondary-font-color-light, #909191);
    font-family: Arial;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 138.462% */
  }
`;

export const ButtonClearInputData = styled.div`
  width: 25px,
  height: 25px,
  position: relative,
  float: right,
  background: var(--backgroundInput),
  top: 39px,
  display: flex,
  marginRight: 10px,
  justifyContent: center,
  alignItems: center,
  cursor: pointer,
`;