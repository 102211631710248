import * as lang from './languages/languages';
export default function Text(text,values){
    let language_selected = localStorage.getItem('lg');
    if(!language_selected || !lang[language_selected]){
        localStorage.setItem('lg','ptBR');
        language_selected='ptBR';
    }
    const text_system = lang[language_selected]();
    
    let text_result = (text_system[text]||text)+'';
    if(values&&text_result){
        let reg = new RegExp('__#(.+)#__');
        let result = text_result.match(reg);
        if(result){
            result.map((key)=>{
                text_result = text_result.replace(`__#${key}#__`,values[key]||'');
            });
        }
    }
    return text_result;
}

