import { Button } from "reactstrap"
import { Icon } from '@polichat/flamboyant';
import request from '../../../../../services/tools/reports/export-reports'
import { ExportStatusIds, IconToActionsExports, ReportEntity } from "src/pages/report/shared/components/Export";

interface TypeButtonDownload {
    reports: ReportEntity
    loading: boolean
    onClick?: () => void
    disable: boolean
}

function ButtonDownloadAndReloadFile({ reports, loading, disable }: TypeButtonDownload) {
    
    return (
        <>
            {reports.link ?
                <a href={reports.link} target="_blank" download>
                    <ButtonClicked
                        reports={reports}
                        loading={loading}
                        disable={disable}
                    />
                </a> :
                <ButtonClicked
                    reports={reports}
                    loading={loading}
                    onClick={() => request.requestReloadReports({
                        reports_id: reports.id
                    })}
                    disable={disable}
                />
            }
        </>
    )
}

function ButtonClicked({ reports, loading, onClick, disable }: TypeButtonDownload) {
    function getStatusBackground(status: number): string {
        switch (status) {
            case ExportStatusIds.PENDING:
                return '';
            case ExportStatusIds.COMPLETED:
            case ExportStatusIds.FAILED:
            case ExportStatusIds.EXPIRED:
                return 'var(--primary)';
            default:
                return 'var(--primary)';
        }
    }

    function getIcon(link?: string): string {
        if(!link) return IconToActionsExports.reload

        return IconToActionsExports.download
    }


    return (
        <Button
            data-order-by={reports.id}
            style={{ textDecorationColor: 'none', border: 'none', color: 'var(--textDefault)' }}
            color="link"
            size='sm'
            disabled={disable}
            onClick={onClick}
        >
            <Icon
                icon={`poli-icon ${getIcon(reports.link)}`}
                size={20}
                position={'relative'}
                top={'-3px'}
                right={'4px'}
                background={getStatusBackground(reports.status)}
            />
        </Button>
    )
}

export { ButtonDownloadAndReloadFile }

export type { TypeButtonDownload }
