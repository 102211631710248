const initialState = {
  open: false,
}

const changelog = (state = initialState, action) => {
  switch (action.type) {
    case 'TOGGLE_CHANGELOG':
      return {
        ...state,
        open: !state.open
    }
    default:
      return state
  }
}

export default changelog