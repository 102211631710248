import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  useContext,
} from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ChatActions from '../../../../../store/modules/chat/actions';
import PayImage from './src/PayImage';
import { Link } from 'react-router-dom';

import {
  mountMessage,
  canSendMessage,
  findChannelId,
  findSelectedExternalId,
} from '../../../../../store/modules/chat/functions';

import { toast } from 'react-toastify';

import {
  Button,
  Form,
  FormGroup,
  Label,
  CustomInput,
  CustomTextArea,
  Icon,
} from '@polichat/flamboyant';

import Animation from '../../../../common/animation';
import Avatar from '../../../../common/profile/Avatar';
import ContactName from '../../../../common/profile/ContactName';

import ClickOutsideEvent from '../../../../../events/ClickOutsideEvent';

import { priceMask, priceUnmask } from '../../../../../utils/priceMask';

import { usePoliPayGatewaysService } from '../cart/hooks/usePoliPayGateways';
import { CustomerChannelBalanceContext } from '../../contexts/customerChannelBalance/context';

function PaymentChatModal({
  chat,
  userData,
  paymentChatEditPayment,
  closePaymentChatModal,
  paymentChatRequest,
  addMessage,
  sendMessage,
  sendOrder,
  paymentChatClearPayment,
  current_customer_id,
}) {
  const user_id = userData?.id;
  const customer_id = userData?.customer_id
    ? userData.customer_id
    : current_customer_id;
  const contact = chat.contact;
  const openedChat = chat;
  const isLoadingPayment = chat.payment?.loadingPayment;
  const isErrorPayment = chat.payment?.error;
  const isRequestSuccess = chat.payment?.success;
  const isEdit = chat.payment?.edit;
  const paymentSuccessData = chat.payment?.data;
  const isOpen = openedChat.payment_chat_modal_open;

  const { isLoadingGateways, PoliPayGateways, hasPoliPayGatewayActive } =
    usePoliPayGatewaysService();

  const [selectedGatewayId, setSelectedGatewayId] = useState();

  useEffect(() => {
    let gatewayDefault = PoliPayGateways.find(
      (gateway) => gateway.default === 1
    );
    if (gatewayDefault) {
      setSelectedGatewayId(gatewayDefault.integration_id);
    }
  }, [PoliPayGateways, isOpen]);

  useEffect(() => {
    if (isErrorPayment && !isLoadingPayment)
      toast.error('Erro ao gerar a cobrança', { closeButton: false });
  }, [isErrorPayment]);

  const wrapperRef = useRef(null);

  ClickOutsideEvent(wrapperRef, () => {
    handleClosePayment();
    closePaymentChatModal();
  });

  const [price, setPrice] = useState('');
  const [description, setDescription] = useState('');
  const [order_id, setOrderPaymentId] = useState(null);

  let paymentData = {
    customer_id,
    order_id, //order_id
    price,
    description,
    contact_id: contact.id,
    type: 'payment',
  };

  const handleChangePrice = useCallback((e) => {
    let dataPrice = e.currentTarget.value;
    dataPrice = priceUnmask(dataPrice);
    setPrice(dataPrice);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!price || !description || parseInt(price) === 0) {
      toast.error(
        <div>
          <strong>Erro ao Enviar!</strong>
          <br /> Preencha os campos obrigatórios:{' '}
          <strong>Valor e Descrição.</strong> <br />
        </div>,
        { closeButton: false, autoClose: 3000 }
      );
      return;
    } else {
      paymentChatRequest(paymentData);
    }
  };

  const onEditPayment = () => {
    setOrderPaymentId(paymentSuccessData.order_id);
    setPrice(paymentSuccessData.price);
    setDescription(paymentSuccessData.description);
    paymentChatEditPayment();
  };

  const handleSendMessage = () => {
    if (canSendMessage(openedChat)) {
      let text = `*${userData?.name} lhe enviou uma cobrança:*\nOlá, ${
        contact.name
      }, segue a solicitação de pagamento referente:\n${
        paymentSuccessData.description
      }\n*${priceMask(
        paymentSuccessData.price + ''
      )}*\n*Clique no link para pagar*\n${paymentSuccessData.payment_url}`;

      let message_body = {
        reference_type: 'debt',
        reference_id: paymentSuccessData.order_id,
        text,
        link: paymentSuccessData.payment_url,
        picture: `${process.env?.REACT_APP_CDN_HOST}/img/payment/solicitacao_pagamento.png`,
        description: paymentSuccessData.description,
        value: paymentSuccessData.price,
      };

      let message_data = mountMessage(
        openedChat,
        JSON.stringify(message_body),
        'payment_link'
      );

      let data_send = {
        chat_id: openedChat?.id,
        channel_id: findChannelId(openedChat),
        user_id,
        contact_id: contact?.id,
        external_id: findSelectedExternalId(openedChat),
        message_body,
        cuid: message_data.cuid,
        type: message_data.type,
      };

      sendOrder(
        openedChat.id,
        paymentSuccessData.order_id,
        'debt',
        {
          data_to_storage: message_data,
          data_to_send: data_send,
        },
        selectedGatewayId //send the integration_id on action sendOrder
      );

      handleClosePayment();
      paymentChatClearPayment(openedChat.id);
    } else {
      toast.warning('Não foi possível enviar a mensagem.', {
        closeButton: false,
      });
    }
  };

  const handleClosePayment = () => {
    setPrice('');
    setDescription('');
    setOrderPaymentId(null);
  };

  const handleChangeGateway = (e) => {
    setSelectedGatewayId(e);
  };

  const { isUserAbleToSendMessage, loading: isLoadingCustomerChannelContext } =
    useContext(CustomerChannelBalanceContext);

  if (isOpen) {
    if (
      (hasPoliPayGatewayActive ||
        isLoadingGateways ||
        isLoadingCustomerChannelContext) &&
      isUserAbleToSendMessage
    ) {
      return (
        <>
          <div
            className="payment-chat-modal"
            id="payment-chat-modal"
            ref={wrapperRef}
          >
            {/* preview do pagamento */}
            {isRequestSuccess && !isEdit ? (
              <>
                <div className="payment-chat-subsection-heading">
                  <span className="payment-chat-title">
                    Solicitação de pagamento:
                  </span>
                </div>
                <div className="payment-chat-preview">
                  <div className="sendedMessageBalloon ">
                    <div className="payment-chat-subsection">
                      <div className="icon-wrapper">
                        <PayImage />
                      </div>
                    </div>
                    <div className="adjustsize">
                      <p>
                        Olá, {contact.name}, segue a solicitação de pagamento
                        referente: {paymentSuccessData.description} <br />
                        <span>
                          <strong>
                            {priceMask(paymentSuccessData.price + '')}
                          </strong>
                        </span>
                        <br />
                        <span>
                          <strong>Clique no link para pagar</strong>:
                        </span>
                        <br />
                        <span>{paymentSuccessData.payment_url}</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="payment-chat-buttons-subsection">
                  <Button
                    color="secondary"
                    width="total"
                    className="payment-chat-button"
                    onClick={() => handleSendMessage()}
                    disable={!isUserAbleToSendMessage}
                  >
                    Enviar cobrança
                  </Button>
                  <Button
                    color="info"
                    width="total"
                    className="payment-chat-button"
                    onClick={() => onEditPayment()}
                  >
                    Editar
                  </Button>
                </div>
              </>
            ) : (
              // formulário de pagamento
              <>
                <div className="payment-chat-subsection payment-chat-preview">
                  <div className="icon-wrapper">
                    <PayImage height="100" />
                  </div>
                </div>
                {isLoadingGateways || isLoadingCustomerChannelContext ? (
                  <div className="payment-chat-subsection-heading">
                    <Animation size="full" icon="chat-loading" />
                  </div>
                ) : (
                  <>
                    <div className="payment-chat-subsection-heading">
                      <span className="payment-chat-title">
                        Solicitar pagamento:
                      </span>
                      <div className="contactInfo">
                        <Avatar
                          className="contact"
                          title={contact.name}
                          url={contact.picture}
                          size={36}
                        />
                        <div className="contactNameContainer">
                          <ContactName name={contact.name} />
                        </div>
                      </div>
                    </div>
                    <div className="payment-chat-subsection">
                      <Form onSubmit={handleSubmit}>
                        <FormGroup>
                          <Label check className="payment-chat-label">
                            Valor:
                          </Label>
                          <CustomInput
                            type="text"
                            id="payment_price"
                            name="payment_price"
                            required
                            value={priceMask(price + ' ')}
                            onChange={handleChangePrice}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label check className="payment-chat-label">
                            Método:
                          </Label>
                          <CustomInput
                            type="select"
                            id="payment_gateway"
                            name="payment_gateway"
                            //value="Método escolhido"
                            required
                            value={selectedGatewayId}
                            onChange={(e) =>
                              handleChangeGateway(e.target.value)
                            }
                          >
                            {PoliPayGateways.map((gateway) => (
                              <option
                                key={gateway.id}
                                value={gateway.integration_id}
                              >
                                {gateway.name}
                              </option>
                            ))}
                          </CustomInput>
                        </FormGroup>
                        <FormGroup>
                          <Label check className="payment-chat-label">
                            Descrição:
                          </Label>
                          <CustomTextArea
                            type="textarea"
                            id="description"
                            name="description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                          />
                        </FormGroup>
                        <FormGroup className="payment-chat-buttons-subsection tirarpadding">
                          {isLoadingPayment ? (
                            <div style={{ marginBottom: '10px' }}>
                              <Animation icon="loading" size={'tiny'} />
                            </div>
                          ) : (
                            <Button
                              color="secondary"
                              width="total"
                              className="payment-chat-button"
                            >
                              Gerar cobrança
                            </Button>
                          )}
                          <Button
                            color="danger"
                            width="total"
                            className="payment-chat-button"
                            onClick={() => {
                              handleClosePayment();
                              closePaymentChatModal();
                            }}
                          >
                            Cancelar
                          </Button>
                        </FormGroup>
                      </Form>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </>
      );
    } else if (!isUserAbleToSendMessage) {
      return (
        <div
          className="payment-chat-modal"
          id="payment-chat-modal"
          ref={wrapperRef}
        >
          <PayImage />
          <p>
            Seu acesso atingiu o limite de créditos contratados no período,
            é necessário adicionar créditos extras ou aguardar o próximo
            mês. Entre em contato com nossa área comercial.
          </p>
        </div>
      );
    } else {
      return (
        <div
          className="payment-chat-modal"
          id="payment-chat-modal"
          ref={wrapperRef}
        >
          <PayImage />

          <p>
            Não foi possível identificar nenhuma configuração de meio pagamento
            para esta empresa.
          </p>

          {userData.superRole === 'agent' ? (
            <p>
              Peça para algum gestor configurar, clicando no ícone do Poli
              Shopping no dashboard da plataforma.
            </p>
          ) : (
            <p>
              <Link to="/shopping/payment-settings">Clique aqui</Link> para
              ativar uma nova configuração e começar a usar o PoliPay.
            </p>
          )}
        </div>
      );
    }
  }

  return <></>;
}

const mapStateToProps = (state) => ({
  chat: state.chat?.chats?.find((chat) => chat.open === true),
  userData: state.chat?.config?.user,
  current_customer_id: state.general?.current_customer_id,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(ChatActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PaymentChatModal);
