import React, { useState,useEffect } from 'react';
import PageMainHeader from '../../../common/pages/PageMainHeader/PageMainHeader.component';
import PaymentCard from './PaymentCard';
import PageMainBody from '../../../common/pages/PageMainBody/PageMainBody.component';
import Animation from '../../../common/animation';
import useGatewayList from './hooks/useGatewayList';

function GatewayPayments() {
  const {isLoading, payments, onSetMainMethod } = useGatewayList()

;
  return (
    <>
      <PageMainHeader
        title="Integrações"
        icon="poli-icon pi-integrations-line"
      />

      <PageMainBody>
        {isLoading ?
         <div>
         <Animation size="large" icon="chat-loading" top={'50%'} />
         </div>
          : 
          payments.map((item, key) => {
            return (
              <>
              <PaymentCard
                key={key}
                id={item.id}
                img={item.img}
                title={item.title}
                paymentMethod={item.paymentMethod}
                mainMethod={item.mainMethod}
                status={item.status}
                link={item.link}
                onMainMethod={onSetMainMethod}
              />
              </>
            );
          })}      
           
      </PageMainBody>
    </>
  );
}

export default GatewayPayments;
