import React from 'react';
import Lottie from 'react-lottie';
import loadingDefault from '../../../lotties/loading-default.json';
import ackLoading from '../../../lotties/ack-loading.json';
import chatLoading from '../../../lotties/loading-chat.json';
import microphone from '../../../lotties/microphone.json';
import microphoneWl from '../../../assets/illustratorElement/lotties/microphone.json'
import omniChannel from '../../../lotties/animacao_main.json';
import miniLightLoading from '../../../assets/images/mini-loading-light.gif';
import miniDarkLoading from '../../../assets/images/mini-loading-dark.gif';
import { LoaderMain } from './loader';
import { useSelector } from 'react-redux';

function Animation(props) {
  const wlState = useSelector((state) => state?.whitelabel);
  const isWl = wlState.config.is_white_label;

  let style = {
    width: 40,
    height: 40,
  };

  if (props.style) {
    style = props.style;
  }

  const optionsAnimation = {
    loop: true,
    autoplay: true,
    animationData: '',
  };

  if (props.size) {
    if (typeof props.size === 'number') {
      style.width = props.size;
      style.height = props.size;
    } else {
      switch (props.size) {
        case 'searchTiny':
          style.width = '20px !important';
          style.textAlign = 'center';
          style.margin = '0.75rem auto';
          style.height = '20px !important';
          style.borderTopColor = props.color ? props.color : 'var(--green-polichat)';
          break;
        case 'minorLittleTiny':
          style.width = 23;
          style.textAlign = 'center';
          style.margin = '0 auto';
          style.height = 23;
          style.borderTopColor = props.color ? props.color : 'var(--green-polichat)';
          break;
        case 'tinyRem':
          style.width = '2rem';
          style.textAlign = 'center';
          style.margin = '0.75rem auto';
          style.height = '2rem';
          style.borderTopColor = props.color ? props.color : 'var(--green-polichat)';
          break;
        case 'tiny':
          style.width = 40;
          style.textAlign = 'center';
          style.margin = '0 auto';
          style.height = 40;
          style.borderTopColor = props.color ? props.color : 'var(--green-polichat)';
          break;

        case 'tiny-sended-media':
          style.minWidth = 40;
          style.textAlign = 'center';
          style.margin = '0 auto';
          style.minHeight = 40;
          style.borderTopColor = props.color ? props.color : 'var(--green-polichat)';
          break;
        case 'small':
          style.width = 150;
          style.textAlign = 'center';
          style.margin = '0 auto';
          style.height = 150;
          style.borderTopColor = props.color ? props.color : 'var(--green-polichat)';
          break;

        case 'large':
          style.width = 250;
          style.textAlign = 'center';
          style.margin = '0 auto';
          style.height = 250;
          style.borderTopColor = props.color ? props.color : 'var(--green-polichat)';
          break;
        case 'full':
          style.width = '100%';
          style.textAlign = 'center';
          style.margin = '0 auto';
          style.height = '100%';
          style.borderTopColor = props.color ? props.color : 'var(--green-polichat)';
          break;
        default:
          style.width = 40;
          style.textAlign = 'center';
          style.margin = '0 auto';
          style.height = 40;
          style.borderTopColor = props.color ? props.color : 'var(--green-polichat)';
          break;
      }
    }
  }

  let isLoader = false;

  if (props.icon) {
    switch (props.icon) {
      case 'microphone':
        optionsAnimation.animationData = isWl ? microphoneWl : microphone;
        break;
      case 'loading':
        return (
          <div
            className={
              props.extraClass ? `spinner ${props.extraClass}` : 'spinner'
            }
            style={style}
          ></div>
        );
      case 'ack-loading':
        optionsAnimation.animationData = ackLoading;
        break;
      case 'chat-loading':
        // optionsAnimation.animationData = chatLoading;
        isLoader = true;
        break;
      case 'omni-channel':
        optionsAnimation.animationData = omniChannel;
        break;

      default:
        break;
    }
  }

  return isLoader ? (
    <LoaderMain
      color={props?.color && props.color}
      size={(props?.size && typeof props.size === 'number') && props.size}
      top={props?.top && props.top}
    />
  ) : (
    <Lottie options={optionsAnimation} style={style} />
  );
}

export default Animation;
