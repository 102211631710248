import { useState } from 'react';
import {
  Row,
  Col,
  FormGroup,
  Label,
  CustomInput,
  Input,
} from '@polichat/flamboyant';

import MediaUpload from './MediaUpload/MediaUpload.component';
import AddVariable from '../../common/variable/AddVariable.component';
import {
  LabelWithSpan,
  OptionsContainer,
} from '../../FormMessagesBuilder.styles';

const headerTypes = [
  { id: 1, type: 'text', description: 'Texto', isActive: true },
  { id: 2, type: 'media', description: 'Mídia', isActive: true },
];

export interface CreateHeaderButtonsMessageProps {
  onUpdate(values: any): void;
  initialValue: HeaderDataType;
}

export interface TargetType {
  [key: string]: any;
}
export interface HeaderDataType {
  type: string;
  text: string;
  mediaUrl?: string | undefined;
  mediaFormat?: string | undefined;
  fileData?: {
    fileName: string;
    fileSize: number;
    fileExtension: string;
  };
}

export function CreateHeaderButtonsMessage(props: CreateHeaderButtonsMessageProps) {
  const [headerData, setHeaderData] = useState(props.initialValue);

  const handleInputsChange = ({ target }: TargetType) => {
    if (Array.isArray(target)) {
      let newState: TargetType = {};
      for (let item of target) {
        newState[item.name] = item.value;
      }
      setHeaderData({
        ...headerData,
        ...newState,
      });
      handleHeaderData({
        ...headerData,
        ...newState,
      });
    } else {
      let { name, value } = target;
      setHeaderData({
        ...headerData,
        [name]: value,
      });
      handleHeaderData({
        ...headerData,
        [name]: value,
      });
    }
  };

  const handleAddVariable = (data: any) => {
    const { variable } = data;
    const newText = headerData?.text + variable;

    setHeaderData({
      ...headerData,
      text: newText,
    });
    handleHeaderData({
      ...headerData,
      text: newText,
    });
  };

  const handleHeaderData = ({
    type,
    text,
    mediaUrl,
    mediaFormat,
    fileData,
  }: HeaderDataType) => {
    if (type === 'none') {
      text = '';
      setHeaderData({ type: '', text: '', mediaUrl, mediaFormat, fileData });
      //fazer a validação tipo e campos ao submeter template,
      // e delete do arquivo caso o tipo não seja media
    }
    if (type === 'text') {
      mediaFormat = 'TEXT';
      setHeaderData({ type, text, mediaUrl, mediaFormat, fileData });
    }
    props.onUpdate({ type, text, mediaUrl, mediaFormat, fileData });
  };

  return (
    <>
      <Row>
        <Col sm={12} md={6}>
          <FormGroup>
            <LabelWithSpan>
              <Label>Titulo:</Label>
              <span>
                Adicione um titulo ou escolha o tipo de midia que você usará
                para este cabeçalho
              </span>
            </LabelWithSpan>
            <CustomInput
              id="header-type"
              type="select"
              name="type"
              value={headerData?.type}
              onChange={handleInputsChange}
              disabled={false}
            >
              <option value="none">Nenhum</option>
              {headerTypes.map((headertype) => (
                <option
                  key={headertype.id}
                  value={headertype.type}
                  disabled={!headertype.isActive}
                >
                  {headertype.description}
                </option>
              ))}
            </CustomInput>
          </FormGroup>
        </Col>
      </Row>
      {/* Header type text */}
      {headerData?.type === 'text' && (
        <Row>
          <Col sm={12}>
            <Input
              id="header-title"
              type="text"
              name="text"
              placeholder="Cabeçalho da mensagem"
              value={headerData?.text}
              onChange={handleInputsChange}
              showCharCounter
              maxLength={60}
              disabled={false}
              required
            />
            <OptionsContainer>
              <AddVariable onUpdate={handleAddVariable} isEdit={false} />
            </OptionsContainer>
          </Col>
        </Row>
      )}
      {/* Header type media */}
      {headerData?.type === 'media' && (
        <Row>
          <Col sm={12}>
            <FormGroup>
              <MediaUpload
                initialValue={headerData}
                onUpdate={handleInputsChange}
              />
            </FormGroup>
          </Col>
        </Row>
      )}
    </>
  );
}
