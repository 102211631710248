import React, { ReactNode } from 'react';

import styles from './QuickItem.module.css';

interface QuickDescriptionProps {
  children: ReactNode;
  active?: boolean;
  handleClick: () => void;
}
export const QuickDescription = React.forwardRef<
  HTMLButtonElement,
  QuickDescriptionProps
>(({ children, handleClick, active = false }, ref) => {
  return (
    <button
      className={`${styles.text} ${active ? styles.text__active : ''}`}
      onClick={handleClick}
      ref={ref}
    >
      {children}
    </button>
  );
});
