export const actionNames = {
  REQUEST_WL_CONFIG: '@wl/REQUEST_WL_CONFIG',
  REQUEST_WL_CONFIG_SUCCESS: '@wl/REQUEST_WL_CONFIG_SUCCESS',
  REQUEST_WL_CONFIG_FAILURE: '@wl/REQUEST_WL_CONFIG_FAILURE',
};

export function requestWhiteLabelConfig() {
  return {
    type: actionNames.REQUEST_WL_CONFIG,
    payload: {},
  };
}

export function requestWhiteLabelConfigSuccess(access) {
  return {
    type: actionNames.REQUEST_WL_CONFIG_SUCCESS,
    payload: access,
  };
}

export function requestWhiteLabelConfigFailure(error) {
  return {
    type: actionNames.REQUEST_WL_CONFIG_FAILURE,
    payload: error,
  };
}
