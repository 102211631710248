import { captureMessage, User } from '@sentry/browser';
import { all, select, takeLatest } from 'redux-saga/effects';
import store from '../..';
import { actionNames, fetchChatAlertsFailure } from './actions';
import { CHAT_ALERT_SHOWN, getAlertLocalStorageName } from './functions';

function* fetchChatAlertsRequest(event: {
  payload: { user: User };
  type: string;
}): Generator<any, any, any> {
  try {
    const wsState = (state: any) => state.chat.socket;
    const websocket = yield select(wsState);
    yield websocket.sendMessage(
      JSON.stringify({
        subject: 'fetch_chat_alerts',
        data: {
          user_id: event.payload.user.id,
          customer_id:
            event.payload.user.customer_id ??
            event.payload.user.current_customer_id ??
            null,
        },
      })
    );
  } catch (error) {
    store.dispatch(fetchChatAlertsFailure());
    captureMessage('CHAT_ALERTS - Error when requesting chat alerts: ' + error);
  }
}

function* markChatAlertSeen(event: {
  payload: { alert_id: number };
  type: string;
}): Generator<any, any, any> {
  try {
    const websocket = yield select((state) => state.chat.socket);
    const user = yield select((state) => state.general.user);

    sessionStorage.setItem(CHAT_ALERT_SHOWN, 'true');
    localStorage.setItem(
      getAlertLocalStorageName(event.payload.alert_id),
      Date.now().toString()
    );

    yield websocket.sendMessage(
      JSON.stringify({
        subject: 'mark_alert_seen',
        data: {
          alert_id: event.payload.alert_id,
          user_id: user.id,
          customer_id: user.customer_id ?? user.current_customer_id ?? null,
        },
      })
    );
  } catch (error) {
    captureMessage('CHAT_ALERTS - Error when closing chat alert: ' + error);
  }
}

const alertsSagas = all([
  takeLatest(actionNames.FETCH_CHAT_ALERTS_REQUEST, fetchChatAlertsRequest),
  takeLatest(actionNames.CLOSE_CHAT_ALERT, markChatAlertSeen),
]);

export default alertsSagas;
