import { useState } from 'react';
import { toast } from 'react-toastify';

import { priceMask } from '../../../../../../utils/priceMask';
import { formatDate } from '../../../../../../utils/formatDate';
import { Credit } from '../../../../../../types/Credit';

import ModalConfirmation from '../ModalConfirmation/index.component';

import {
  Button,
  Icon,
  Table,
  Input,
  Badge,
  // @ts-ignore
} from '@polichat/flamboyant';

import { LabelStatus } from './CreditTable.styles';

/**
 * Requests
 */
import {
    postDisableRecurrent,
} from '../../../../../../services/credits/request';
/* */

/**
 * Types
 */

export type typeDataModalView = {
  type?: string;
  isRecurrent?: boolean;
  hasDiscount?: boolean;
};

interface ToggleModalViewCredit {
  (id: number, _data?: typeDataModalView): void;
}

interface FooProp {
  dataTable: Credit[];
  toggleModalViewCredit: ToggleModalViewCredit;
  screenAdmin: boolean;
}
/* end */

const CreditTable = (props: FooProp) => {
  const { dataTable, toggleModalViewCredit, screenAdmin } = props;

  /**
   * Status Payment
   */
  const getStatusLabel = (statusId?: number) => {
    switch (statusId) {
      case 1:
        return 'Pagamento Atrasado';
      case 2:
        return 'Pagamento pendente';
      case 3:
        return 'Pagamento aprovado';
      case 4:
        return 'Recorrente';
      case 5:
        return 'Liberação Manual';
      case 6:
        return 'Crédito Removido';
      default:
        return '';
    }
  };

  const getStatusLabelClass = (statusId?: number) => {
    switch (statusId) {
      case 1:
        return 'danger';
      case 2:
        return 'warning';
      case 3:
        return 'success';
      case 4:
        return 'dark';
      case 5:
        return 'primary';
      case 6:
        return 'danger';
      default:
        return '';
    }
  };
  /* end - Status de pagamento */

  /**
   * Desativar recorrencia
   */
  const [disableRecurrentId, setDisableRecurrentId] = useState<number>();
  const [loadingDisableRecurrent, setLoadingDisableRecurrent] = useState<boolean>();

  const dataModalDisableRecurrent = {
    title: 'Deseja cancelar a recorrência?',
    description:
      'Se cancelar, o processo não podera ser desfeito nessa compra de créditos.',
    icon: 'poli-icon pi-alert-fill',
    colorClass: 'text-warning',
  };

  const onConfirmedModal = async (confirmed: boolean) => {
    if (confirmed) {
      try {
        if (!disableRecurrentId) return;
        setLoadingDisableRecurrent(true);

        const result = await postDisableRecurrent(disableRecurrentId);
        if (result?.data?.success) {
          toast.success('Recorrência desativado com sucesso!');

          // Colocando status como desativado na tabela!
          const index = dataTable?.findIndex(
            (e) => e.id === disableRecurrentId
          );
          if (index >= 0) dataTable[index].status = 0;
          setDisableRecurrentId(0);
        } else {
          toast.error(result?.data?.msg ?? 'Erro ao desabilitar recorrência!');
        }
        setLoadingDisableRecurrent(false);
      } catch (error) {
        console.error('Error on Services Credit: postDisableRecurrent', error);
        toast.error(
          `${error} - Erro ao desabilitar recorrência! Tente novamente mais tarde ou entre em contato com o suporte!`
        );
        setLoadingDisableRecurrent(false);
      }
    } else {
      setDisableRecurrentId(0);
    }
  };
  /* end - desativar recorrencia */

  return (
    <>
      <Table responsive bordered striped hover>
        <thead>
          <tr>
            <th>
              <strong>Serviço</strong>
            </th>
            <th>
              <strong>Qtd</strong>
            </th>

            <th>
              <strong>Data Solicitação</strong>
            </th>

            {screenAdmin && (
            <th>
              <strong>Recorrência</strong>
            </th>
            )}

            <th>
              <strong>Valor Total</strong>
            </th>

            <th>
              <strong>Pagamento</strong>
            </th>

            <th>
              <strong>Ações</strong>
            </th>
          </tr>
        </thead>
        <tbody>
          {dataTable?.map((item, index) => {
            return (
              <tr key={index}>
                <td>
                  {item.service?.name
                    ? item.service.name
                    : `#${item.service_id}`}
                </td>
                <td>{item.package_quantity}</td>
                <td>{formatDate(item.created_at)}</td>
                {screenAdmin &&(
                <td>
                  <span className="custom-switch custom-control">
                    <Input
                      type="checkbox"
                      id={`checkListRecurrent${index}`}
                      checked={item.type === 'M' && item.status == 1}
                      className="custom-control-input"
                      onChange={() => {
                        if (item.type === 'M' && item.status == 1) {
                          setDisableRecurrentId(item?.id);
                        }
                      }}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor={`checkListRecurrent${index}`}
                    ></label>
                  </span>
                </td>
                )}
                <td>{priceMask((item.value ?? 0).toFixed(2))}</td>
                <td>
                  <LabelStatus>
                    <Badge
                      className="badge"
                      color={getStatusLabelClass(item.status_payment)}
                    >
                      {getStatusLabel(item.status_payment)}
                    </Badge>
                  </LabelStatus>
                </td>
                <td>
                  <Button
                    gradient="info"
                    className="btn-gradient-info btn btn-secondary btn-sm"
                    style={{ marginRight: '5px', paddingTop: '7px' }}
                    onClick={() =>
                      toggleModalViewCredit(item?.id, {
                        type: item?.type,
                        isRecurrent: item?.type === 'M' && item?.status == 1,
                        hasDiscount: item?.discount_value < 0,
                      })
                    }
                    disabled={!item.id}
                  >
                    <Icon icon="poli-icon pi-eye-fill" size={20} />
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>

      <ModalConfirmation
        isOpen={disableRecurrentId && disableRecurrentId > 0 ? true : false}
        onConfirmed={onConfirmedModal}
        data={dataModalDisableRecurrent}
        isLoading={loadingDisableRecurrent ?? false}
      />
    </>
  );
};

export default CreditTable;
