import React, { useState } from 'react';
import {
  Button,
  Icon,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
} from '@polichat/flamboyant';
import List from '../../../common/list';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ChatActions from '../../../../store/modules/chat/actions';
import Animation from '../../../common/animation';

import Avatar from '../../../common/profile/Avatar';
import { useFlags } from 'flagsmith/react';

function MergeContact({ chat, props, mergeContactSearch, mergeContacts }) {

  const crmNewChatDetails = useFlags(['crm_new_chat_details']).crm_new_chat_details?.enabled;
  //////////
  const currentContact = chat.contacts
    ? chat.contacts.find((contact) => props.contact.id === contact.id)
    : null;

  ///status da busca
  const searchingStatus =
    currentContact && currentContact.searchingContactsToMerge
      ? currentContact.searchingContactsToMerge.searchingStatus || 0
      : 0;

  const results =
    currentContact &&
      currentContact.searchingContactsToMerge &&
      currentContact.searchingContactsToMerge.results
      ? currentContact.searchingContactsToMerge.results
      : null;
  ////states
  const [show, setShow] = useState(false);
  const [contactToMerge, setContactToMerge] = useState(null);
  const [mergingStatus, setMergingStatus] = useState(
    currentContact?.searchingContactsToMerge?.mergingStatus || 0
  );
  const [tryMergingAgain, setTryMergingAgain] = useState(false); ///para forçar mostrar a lista mesmo após mostrar o erro
  if (
    currentContact &&
    typeof currentContact.searchingContactsToMerge?.mergingStatus !==
    'undefined' &&
    currentContact.searchingContactsToMerge.mergingStatus !== mergingStatus
  ) {
    setMergingStatus(currentContact.searchingContactsToMerge.mergingStatus);
  }
  const [searchingSuggestion, setSearchingSuggestion] = useState(true);
  if (!currentContact) return null;
  ///////////
  let new_term =
    currentContact.searchingContactsToMerge &&
      currentContact.searchingContactsToMerge.term
      ? currentContact.searchingContactsToMerge.term
      : null;

  let term = new_term;
  let time_last_term = null;
  let new_value = null;
  ////////////
  function setTerm(term) {
    if (!time_last_term) {
      recursive();
    }
    time_last_term = Date.now();
    new_term = term;
  }

  function searchContact(value) {
    new_value = value;
    if (new_value && new_value !== new_term && new_value != '') {
      setTerm(new_value);
    }
  }

  function onSearchContacts() {
    mergeContactSearch({ contact_id: currentContact.id, term });
  }

  function onMerge(contact) {
    setContactToMerge(contact);
  }
  function onConfirmMerge() {
    setTryMergingAgain(false);
    mergeContacts(contactToMerge.id, currentContact.id);
  }
  function sendTerm() {
    if (term !== new_term) {
      term = new_term;
      onSearchContacts();
    }
  }
  function recursive() {
    if (!show) {
      return;
    }
    let now = Date.now();
    if (
      time_last_term &&
      now - time_last_term >= 2000 &&
      term !== new_term &&
      searchingStatus !== 1
    ) {
      sendTerm();
    }
    setTimeout(
      () => {
        recursive();
      },
      searchingStatus === 1 ? 800 : 2000
    );
  }
  function toSearch() {
    sendTerm();
  }
  function onKeyDownInput(event) {
    event = event.nativeEvent;
    if (event.keyCode === 13) {
      sendTerm();
    }
  }
  ////verificando se o contactToMerge ainda existe
  if (contactToMerge) {
    if (results && results.length) {
      if (
        !show ||
        !results.find((contact) => contact.id === contactToMerge.id)
      ) {
        setContactToMerge(null);
      }
    } else {
      setContactToMerge(null);
    }
  }
  let modal = null;
  if (show) {
    /////buscando sugestões com base no nome do contato
    if (searchingSuggestion && !searchingStatus) {
      mergeContactSearch({
        contact_id: currentContact.id,
        term: currentContact.name,
        suggestion: true,
      });
    } else if (searchingSuggestion) {
      if (
        searchingStatus === -1 &&
        currentContact.searchingContactsToMerge.suggestion
      ) {
        setContactToMerge(null);
      }
      setSearchingSuggestion(false);
    }

    let modalBody = null;
    let modalFooter = null;
    if (contactToMerge) {
      if (mergingStatus === 1) {
        modalBody = <Animation icon="loading" size={'tiny'} />;
      } else if (mergingStatus === -1 && !tryMergingAgain) {
        modalBody = (
          <div className={(props.className || '') + ' errorResults'}>
            <Icon icon="poli-icon pi-alert-fill" /> Não foi possível realizar o
            vínculo. Tente novamente!{' '}
            <span
              onClick={() => {
                setContactToMerge(null);
                setTryMergingAgain(true);
              }}
            >
              Voltar
            </span>
          </div>
        );
      } else {
        modalBody = (
          <div className="mergingContactsBox">
            <strong>
              Você está prestes a transformar estes dois contatos em apenas um.
            </strong>
            <div className="currentContact">
              <span className="avatarContainer">
                <Avatar
                  className="contact"
                  title={currentContact.name}
                  url={currentContact.picture}
                  cursor="default"
                />
              </span>{' '}
              <span>
                {currentContact.name}
                <br />
                {currentContact.phone}
              </span>
            </div>
            <div className="spaceBeteweenContacts">
              <span className="before"></span>
              <Icon icon="poli-icon pi-link-fill" />
              <span className="after"></span>
            </div>
            <div className="contactToMerge">
              <span className="avatarContainer">
                <Avatar
                  className="contact"
                  title={currentContact.name}
                  url={contactToMerge.picture}
                  cursor="default"
                />
              </span>{' '}
              <span>
                {contactToMerge.name} <br /> {contactToMerge.phone}
              </span>
            </div>
          </div>
        );
        modalFooter = (
          <>
            <Button
              className="btn-primary"
              size="lg"
              onClick={() => {
                setMergingStatus(1); ///loading
                onConfirmMerge();
              }}
            >
              Vincular
            </Button>{' '}
            <Button
              className="btn-danger"
              size="lg"
              onClick={() => setContactToMerge(null)}
            >
              Cancelar
            </Button>
          </>
        );
      }
    } else {
      let results_to_list = results;
      let listResults = (
        <div className={(props.className || '') + ' emptyResults'}>
          Nenhum resultado encontrado!
        </div>
      );
      if (!new_value && (!results_to_list || !results_to_list.length)) {
        if (searchingStatus === -1) {
          if (currentContact.searchingContactsToMerge.suggestion) {
            new_term = ''; ///limpando o input
            listResults = (
              <div className={(props.className || '') + ' infoBox'}>
                <Icon icon="poli-icon pi-contacts-line" /> Digite acima alguma
                informação que você conheça sobre o contato a ser vinculado!
              </div>
            );
          } else {
            listResults = (
              <div className={(props.className || '') + ' errorResults'}>
                <Icon icon="poli-icon pi-alert-fill" /> Erro ao buscar contatos.
                Tente novamente!
              </div>
            );
          }
        } else if (searchingStatus === 1) {
          listResults = (
            <div className={(props.className || '') + ' searchingResults'}>
              {currentContact.searchingContactsToMerge.suggestion
                ? "Procurando sugestões para '" + currentContact.name + "'"
                : 'Pesquisando...'}
              <Animation icon="loading" size={'tiny'} />
            </div>
          );
        }
      } else if (!results_to_list || !results_to_list.length) {
        if (searchingStatus === -1) {
          listResults = (
            <div className={(props.className || '') + ' errorResults'}>
              <Icon icon="poli-icon pi-alert-fill" /> Erro ao buscar contatos.
              Tente novamente!
            </div>
          );
        }
      } else {
        listResults = (
          <>
            <h5>
              {currentContact.searchingContactsToMerge.suggestion
                ? 'Sugestões do sistema'
                : 'Lista de contatos'}
            </h5>
            <List
              list={results_to_list}
              keyAvatar="picture"
              keyTitle="name"
              keySubtitle="phone"
              keyDescription=""
              ordering="alphabet"
              contentAfter={(contact) => {
                return (
                  <span
                    className="buttonToMerge"
                    onClick={() => {
                      onMerge(contact);
                    }}
                  >
                    <Icon size={20} icon="poli-icon pi-link-fill" />
                    
                  </span>
                );
              }}
            />
          </>
        );
      }
      modalBody = (
        <>
          <FormGroup>
            <Label for="search">Buscar: </Label>
            <Input
              type="text"
              name="search"
              id="search"
              autoComplete="off"
              placeholder="Nome, CPF ou Telefone"
              defaultValue={new_term}
              onChange={(e) => {
                searchContact(e.target.value);
              }}
              onKeyDown={onKeyDownInput}
            />
            {searchingStatus === 1 ? (
              <Animation icon="loading" size={'tiny'} />
            ) : (
              <span onClick={toSearch}>
                <Icon
                  position="relative"
                  top="5px"
                  icon="poli-icon pi-search-line"
                />
              </span>
            )}
          </FormGroup>
          {listResults}
        </>
      );
    }
    modal = (
      <Modal
        isOpen={show}
        toggle={() => setShow(!show)}
        className={
          'modalChat mergeContacts ' +
          (localStorage.getItem('@polichat/darkmode') === 'true'
            ? 'darkMode'
            : '')
        }
      >
        <ModalHeader toggle={() => setShow(!show)}>
          <Icon icon="poli-icon pi-user-man-fill" size={50} />
          <span right="true">Vincular contatos</span>
          <span
            className="closeButton"
            onClick={() => {
              setShow(false);
            }}
          >
            <Icon icon="poli-icon pi-close-fill" size={50} />
          </span>
        </ModalHeader>
        <ModalBody>{modalBody}</ModalBody>
        {modalFooter ? <ModalFooter>{modalFooter}</ModalFooter> : ''}
      </Modal>
    );
  }
  if(crmNewChatDetails){
    return (
      <>
        {modal}
        <Button
          color="info"
          className="ContactButton"
          sx={6}
          onClick={() => {
            setShow(!show);
          }}
        >
          <Icon icon={'poli-icon pi-link-fill'} />{' '}
        </Button>
      </>
    );
  }else{
    return (
      <>
        {modal}
        <br/>
        <Button
          color="info"
          style={{
            alignItems: "center",
            borderRadius: "5px",
            display: "flex",
            justifyContent: "center",
            width: "100%",
            fontSize: "1.25rem",
            lineHeight: "1.5",
            fontWeight: "400",
            // padding: "0.5rem 1rem"
            margin: 0,
          }}
          className="ContactButton"
          sx={7}
          onClick={() => {
            setShow(!show);
          }}
        >
          <Icon icon={'poli-icon pi-link-fill'} />{' '}
          <span style = {{
          }}>Vincular contato</span>
        </Button>
      </>
    );
  }

}

const mapStateToProps = (state, ownProps) => ({
  chat: state.chat,
  props: ownProps,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(ChatActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MergeContact);
