import React, { useCallback, useEffect, useState } from 'react';

import {
  Button,
  CustomInput,
  CustomPhoneInput,
  FormGroup,
  Icon,
  Input,
  Label,
  ModalFooter,
  MultiSelector,
  Switch,
} from '@polichat/flamboyant';

import {
  ActionGroup,
  CheckBoxContainer,
  Container,
  ContrastBackgroundContainer,
  CustomModal,
  CustomModalHeader,
  CustomModalBody,
  InputItem,
  InputsContainer,
  MultiSelectorContainer,
  PhoneContainer,
  QrCodeContainer,
  Section,
  SwitchContainer,
} from './ChannelAddEditBroker.styles';

import { toast } from 'react-toastify';

import ChannelStatusAlert, {
  ChannelStatuses,
} from '../ChannelStatusAlert/ChannelStatusAlert.component';
import ChannelsService from '../../../../services/tools/channels/channels';
import ChannelDescriptionBanner from '../ChannelDescriptionBanner/ChannelDescriptionBanner.component';
import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import ConnectingIllustration from '../../../../assets/images/illustrations/broker-channel-connecting.svg';
import { ValidatePlanBeforeMigrateWaba } from './ValidatePlanBeforeMigrateWaba';

import { useFlags } from 'flagsmith/react';

import { laravelAxios } from '../../../../config/axios/laravelAxios';
import ButtonDeleteChannel from '../DeleteChannel/DeleteChannel.componet';
import { WhatsAppCreateEditService } from '../../../../services/tools/channels/whatsapp';
import { ZapiToBspModal } from './homologationZapiBspModal/index';

export default function ChannelAddEditBroker({
  data: dataFromDad,
  dataSet,
  userAdmPoliGetChannelFromCustomerId,
}) {
  const flags = useFlags(['button_zapi_to_bsp']);
  const WHATSAPP_CHANNEL_ID = 1; // identificador de canal tipo whatsapp
  const ZAPI_IDENTIFICATION = 4; // identificador da API do Z-API

  const { name, phone, id, config } = dataFromDad || {};
  let usersDad = dataFromDad?.users || [];

  const customer_id = useSelector((state) => {
    if (userAdmPoliGetChannelFromCustomerId) {
      return userAdmPoliGetChannelFromCustomerId;
    } else {
      return state.general.current_customer_id;
    }
  });
  const userLoggedInCustomerId = useSelector(
    (state) => state.general.current_customer_id
  );

  const [shouldShowMigrateToWabaButton] = useState(() => {
    //se é admin Poli: enviou ID da empresa que está editando o canal pela props
    if (userAdmPoliGetChannelFromCustomerId) {
      //está editando canal de uma empresa e está logado na empresa irá exibir botão waba
      return userAdmPoliGetChannelFromCustomerId === userLoggedInCustomerId;
    } else {
      //mostrar botão de migrar Waba
      return true;
    }
  });

  const [isDeleting, setIsDeleting] = useState(false); //valida se está deletando para que não altere status
  const [isConnecting, setIsConnecting] = useState(false); //valida se está conectando para não alterar o status

  const user_roles = useSelector((state) => state.general.user.roles); // busca roles do usuário logado
  const superRole = useSelector((state) => state.general.user.superRole);
  const user_is_adm = !!user_roles?.find((role) => role.name == 'adm'); // define se usuário é adm
  const need_new_channel = dataFromDad ? !dataFromDad.config : false; //caso não haja config devido a desconexão
  const channel_status = dataFromDad?.config?.status; // busca código de status do canal
  const status_for_restart = [200, 201, 202, 203, 204, 205]; // códigos de status do canal onde tem restart
  const restart_is_permiting = status_for_restart.includes(channel_status); // verifica se o status do canal permite restart
  const show_restart_button = user_is_adm && restart_is_permiting; // define se exibe o botão de restart

  // Estados locais para lidar com mudanças nos valores de um input
  const [channelName, setChannelName] = useState(name || '');
  const [channelPhone, setChannelPhone] = useState(phone || '');
  const [statusConnected, setStatusConnected] = useState();
  const [isPhoneValid, setPhoneValidation] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [verifyStatusChannel, setVerifyStatusChannel] = useState('');
  const [showChannelOnWidget, setShowChannelOnWidget] = useState(
    dataFromDad?.display_on_webchat == 1 ?? true
  );

  const [receiveInitData, setReceiveInitData] = useState(
    dataFromDad?.receive_init_data == 1 ?? true
  );
  // Guarda o status do canal de acordo com o backend
  const [channelStatus, setChannelStatus] = useState(
    dataFromDad?.config?.status
  );

  const [timeForOptimize, setTimeForOptimize] = useState(
    dataFromDad?.config?.time_for_optimize ?? 0
  );

  // Guarda a escolha do usuário sobre a ativação do canal
  const [activateChannel, setActivateChannel] = useState(
    dataFromDad?.status ?? true
  );
  const [usersOptions, setUsersOptions] = useState([]);
  const [sendButtonDisabled, setSendButtonDisabled] = useState(false);

  const [qrCode, setQrCode] = useState(dataFromDad?.config?.qr_code ?? null);
  const [showQrCodeModal, setShowQrCodeModal] = useState(false);

  const [modalContent, setModalContent] = useState({});

  const [isLoading, setIsLoading] = useState(false);

  const callSupport = () => {
    window.open('https://web.whatsapp.com/send?phone=556282361759');
  };

  const toggleModalQrCode = () => setShowQrCodeModal(false);

  const isDarkMode = JSON.parse(localStorage.getItem('@polichat/darkmode'));
  const [openModalZapiToBsp, setOpenModalZapiToBsp] = useState(false);

  // Popula a lista de usuários que podem ou não acessar o canal
  useEffect(() => {
    /**
     * verifica se a empresa passou pela analise do plano antes de criar canal
     * e não é edição
     */
    if (!historyState?.validated_can_add_channel && !dataFromDad) {
      history.push(`/tools/channels/add`);
      return;
    }

    async function fetchUsers() {
      try {
        const users_result = await ChannelsService.getAllUsersByCustomer(
          customer_id
        );
        const { data: users } = users_result;
        //Usado para criar o objeto options que irá alimentar o componente MultiSelector
        if (users && users.length) {
          const newUsersOptions = users.map((user) => {
            return {
              value: user.id,
              label: user.name,
            };
          });

          const usersSelected = newUsersOptions?.filter((user) =>
            usersDad?.includes(user.value)
          );
          setUsersOptions(newUsersOptions);
          setSelectedUsers(
            usersSelected.length ? usersSelected : newUsersOptions
          );
        }
        return;
      } catch (error) {
        console.log(
          'Erro no componente ChannelAddEditBroker na função fetchUsers => ',
          error
        );
        return;
      }
    }

    //channelStatus 201 >> aguardando leitura de QRCODE
    const toConnectChannel = channelStatus === 201;
    if (qrCode && toConnectChannel) {
      setShowQrCodeModal(true);
    }

    fetchUsers();
  }, []);

  const handleClickHomologationZApi = useCallback(async () => {
    try {
      const channelObject = {
        id: Number(dataFromDad.id),
        customer_id,
        channel_id: 6,
        status: activateChannel ? 1 : 0,
        api: 10,
      };

      const result = await WhatsAppCreateEditService.homogationZapiToBsp(
        channelObject
      );
      if (result?.success || result?.channel) {
        history.push({
          pathname: `/tools/channels/edit/${dataFromDad.id}`,
        });
        location.reload();
      }
    } catch (error) {
      console.log('Erro handleClickHomologationZApi: ', error);
      toast.error('Erro ao tentar homologar');
    }
  }, []);

  // Faz uma requisição periodicamente ao banco para buscar alterações no QR code e status do canal
  useEffect(() => {
    if (dataFromDad) {
      // Armazena o id do setInterval para podermos cancelá-lo quando a página for fechada
      const periodicUpdate = setInterval(async () => {
        if (isDeleting) {
          return;
        }
        const result =
          await WhatsAppCreateEditService.checkConnectionWhatsAppZAPIChannel({
            channel_customer_id: dataFromDad.id,
            customer_id,
          });
        if (result && Number(result.id) === Number(dataFromDad.id)) {
          const newQrCode = result?.config?.qr_code;
          const newStatus = result?.config?.status;
          setStatusConnected(newStatus);

          // Se houve alteração no QR code, atualize-o
          if (newQrCode !== qrCode) {
            setQrCode(newQrCode);
          }

          // Se houve alteração no status, atualize-o
          if (newStatus && newStatus !== channelStatus) {
            setChannelStatus(newStatus);

            if (isConnecting) {
              setIsConnecting(false);
            }
            if (newStatus == ChannelStatuses.CONECTADO.code && showQrCodeModal)
              setShowQrCodeModal(false);
          } else if (newStatus === undefined) {
            if (!isConnecting) {
              setChannelStatus(208);
            }
          }
        }
      }, 5e3);
      // Cancela o update ao sair da página
      return () => clearInterval(periodicUpdate);
    }
  }, [showQrCodeModal, channelStatus, isDeleting]);

  // Define qual tipo de modal deve ser mostrado baseado no status do canal
  useEffect(() => {
    if (dataFromDad) {
      const somethingWentWrongModalContent = {
        header: (
          <>
            <Icon
              icon="poli-icon pi-smartphone-fill"
              color="var(--danger)"
              size="40"
            />
            <span>Algo deu errado</span>
          </>
        ),
        body: (
          <div>
            Entre em contato com nosso suporte para obter ajuda
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                gap: '1rem',
                margin: '2rem',
              }}
            >
              <Button color="primary" size="lg" onClick={callSupport}>
                Falar com o suporte
              </Button>
              <Button
                color=""
                size="lg"
                style={{
                  borderColor: 'var(--gray)',
                  color: 'var(--primary-font-color',
                }}
                onClick={toggleModalQrCode}
              >
                Cancelar
              </Button>
            </div>
          </div>
        ),
        footer: (
          <ModalFooter
            style={{
              color: 'var(--danger)',
              fontSize: '0.8rem',
              padding: 0,
            }}
          >
            Se prosseguir com o canal desativado você não receberá as mensagens
            referentes a ele.
          </ModalFooter>
        ),
      };

      switch (parseInt(channelStatus)) {
        case ChannelStatuses.CONECTADO.code:
        case ChannelStatuses.CONECTANDO.code:
          setModalContent({
            header: (
              <>
                <Icon
                  icon="poli-icon pi-channels-conections-line"
                  color="var(--primary)"
                  size="40"
                />
                <span>Conectando</span>
              </>
            ),
            body: <img src={ConnectingIllustration} height="200px" />,
            footer: <></>,
          });
          break;

        case ChannelStatuses.AGUARDANDO_LEITURA.code:
          setModalContent(
            qrCode
              ? {
                header: (
                  <>
                    <Icon
                      icon="poli-icon pi-qr-code-fill"
                      color="var(--danger)"
                      size="40"
                    />
                    <span>Leia o QR Code</span>
                  </>
                ),
                body: (
                  <QrCodeContainer>
                    <img src={qrCode} height="300px" />
                  </QrCodeContainer>
                ),
                footer: (
                  <>
                    <Button
                      color="danger-link"
                      size="sm"
                      onClick={handleDisconnect}
                    >
                      Desconectar
                    </Button>
                  </>
                ),
              }
              : somethingWentWrongModalContent
          );
          setShowQrCodeModal(true);
          break;

        case ChannelStatuses.APARELHO_DESCONECTADO.code:
          setModalContent({
            header: (
              <>
                <Icon
                  icon="poli-icon pi-smartphone-fill"
                  color="var(--danger)"
                  size="40"
                />
                <span>Verifique o celular</span>
              </>
            ),
            body: (
              <div>
                Algo está errado com seu aparelho, verifique-o e tente novamente
                ativar seu canal, confira lista de possíveis problemas.
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    gap: '1rem',
                    margin: '2rem',
                  }}
                >
                  <Button color="primary" size="lg">
                    ATIVAR
                  </Button>
                  <Button
                    color=""
                    size="lg"
                    style={{
                      borderColor: 'var(--gray)',
                      color: 'var(--primary-font-color',
                    }}
                  >
                    Agora não
                  </Button>
                </div>
              </div>
            ),
            footer: (
              <ModalFooter
                style={{
                  color: 'var(--danger)',
                  fontSize: '0.8rem',
                  padding: 0,
                }}
              >
                Se prosseguir com o canal desativado você não receberá as
                mensagens referentes a ele.
              </ModalFooter>
            ),
          });
          break;

        default:
          setModalContent(somethingWentWrongModalContent);
          break;
      }
    }
  }, [channelStatus, qrCode]);

  // Para redirecionar o usuário após criação de um canal
  const history = useHistory();
  // Pegar state enviado pela URL
  const { state: historyState } = history.location;

  // Funções utilizadas pelos inputs quando os valores mudarem (OnChange)
  const handleNameChange = (e) => setChannelName(e.target.value);
  const handlePhoneChange = async (value) => setChannelPhone(value);
  const handlePhoneBlur = async () => {
    if (channelPhone) {
      const { data } = await laravelAxios.get(
        `/channels/verify_number/${customer_id}/${channelPhone}`
      );
      if (!data.available) {
        setSendButtonDisabled(true);
      } else {
        setSendButtonDisabled(false);
      }

      setPhoneValidation(data.available);
    }
  };
  const handleGetSelectorValues = (data) => setSelectedUsers(data);
  const handleShowOnWidgetChange = (e) =>
    setShowChannelOnWidget(e.target.checked);
  const handleActivateChannelChange = (e) =>
    setActivateChannel(e.target.checked);

  const handleReceiveInitData = (e) =>
    setReceiveInitData(e.target.checked);

  const handleConnect = async () => {
    try {
      setIsConnecting(true);
      setChannelStatus(202);
      setSendButtonDisabled(true);
      await WhatsAppCreateEditService.connectWhatsAppZAPIChannel({
        customer_id,
        channel_customer_id: dataFromDad.id,
      });
      setSendButtonDisabled(false);
    } catch (error) {
      setSendButtonDisabled(false);
    }
  };

  const handleDisconnect = async () => {
    try {
      setIsDeleting(true);
      setChannelStatus(208);
      setShowQrCodeModal(false);
      setSendButtonDisabled(true);
      await WhatsAppCreateEditService.disconnectWhatsAppZAPIChannel({
        customer_id,
        channel_customer_id: dataFromDad.id,
      });
      setSendButtonDisabled(false);
      dataSet({ ...dataFromDad, config: null });
    } catch (error) {
      setSendButtonDisabled(false);
    } finally {
      setIsDeleting(false);
    }
  };

  const handleTryAgain = async () => {
    try {
      setSendButtonDisabled(false);
      const { data: result } =
        await WhatsAppCreateEditService.connectWhatsAppZAPIChannel({
          customer_id,
          channel_customer_id: dataFromDad.id,
        });
      if (result && result.id) {
        result.config = JSON.parse(result.config);
        const newQrCode = result.config?.qr_code;
        const newStatus = result.config?.status;
        const newActivateChannel = result.status;

        if (newActivateChannel != activateChannel) {
          setActivateChannel(newActivateChannel);
        }

        // Se houve alteração no QR code, atualize-o
        if (newQrCode !== qrCode) {
          setQrCode(newQrCode);
        }

        // Se houve alteração no status, atualize-o
        if (newStatus && newStatus !== channelStatus) {
          setChannelStatus(newStatus);

          if (newStatus == ChannelStatuses.CONECTADO.code && showQrCodeModal) {
            setShowQrCodeModal(false);
          }
        }
      }
      setSendButtonDisabled(false);
    } catch (error) {
      console.error('Error handleTryAgain on ChannelAddEditBroker', error);
      toast.error(
        <p style={{ color: 'var(--danger)' }}>
          Erro ao {dataFromDad ? 'editar' : 'criar'} canal!
          <br />
          Se erro persistir entre em contato com o suporte.
        </p>
      );
      setSendButtonDisabled(false);
    }
  };

  const handleSendForm = async () => {
    if (!channelName) {
      toast.error('O campo nome é obrigatório');
      return;
    }

    if (!setChannelPhone || !channelPhone.length || channelPhone.length < 11) {
      toast.error('Telefone inválido');
      return;
    }

    if (!selectedUsers.length) {
      toast.error('Adicione pelo menos um usuário no canal!');
      return;
    }

    setSendButtonDisabled(true);

    try {
      setIsLoading(true);
      const channelObject = {
        customer_id,
        channel_id: WHATSAPP_CHANNEL_ID,
        name: channelName,
        phone: channelPhone,
        status: activateChannel ? 1 : 0,
        users: selectedUsers.map((user) => user.value),
        display_on_webchat: showChannelOnWidget ? 1 : 0,
        api: ZAPI_IDENTIFICATION,
        receive_init_data: receiveInitData ? 1 : 0,
      };

      if (!dataFromDad) {
        // Criar novo canal:
        // channelObject.config.status = 208;

        // alterando para rota de criar canal do LARAVEL
        const result =
          await WhatsAppCreateEditService.createWhatsAppZAPIChannel(
            channelObject
          );

        if (result.success) {
          toast.success('Canal criado com sucesso!');
          history.push(`/tools/channels/edit/${result.channel}`);
        } else {
          let error_message = '';
          if (result.msg.error_name)
            error_message += result.msg.error_name + ' ';
          if (result.msg.error_phone)
            error_message += result.msg.error_phone + ' ';
          if (result.msg.error_msg) error_message += result.msg.error_msg + ' ';
          toast.error(
            <div>
              <strong>Erro ao criar o canal!</strong> <br /> {error_message}
            </div>
          );
          setSendButtonDisabled(false);
        }
      } else {
        // Editar um canal existente
        channelObject.id = Number(dataFromDad.id);
        const result = await WhatsAppCreateEditService.editWhatsAppZAPIChannel(
          channelObject
        );
        if (result.success) {
          toast.success('Canal editado com sucesso!');
          setSendButtonDisabled(false);
          history.push(`/tools/channels/edit/${channelObject.id}`);
        }
        //  else if (result.status == ChannelCustomerStatusCodes.NOT_ACCEPTABLE) {
        //   console.warn('erro de parametro:', result.error);
        //   toast.warning(
        //     `${
        //       result.error.error.includes('time for optimize')
        //         ? 'Valor de otimização é inválido tente novamente '
        //         : 'Tente novamente'
        //     }`
        //   );
        //   setSendButtonDisabled(false);
        // } else {
        //   throw 'Requisição inválida';
        // }
      }
    } catch (error) {
      console.error(
        `Erro ao ${dataFromDad ? 'editar' : 'criar'} canal => `,
        error
      );
      toast.error(
        <p style={{ color: 'var(--danger)' }}>
          Erro ao {dataFromDad ? 'editar' : 'criar'} canal!
          <br />
          Se erro persistir entre em contato com o suporte.
        </p>
      );
      setSendButtonDisabled(false);
      return;
    } finally {
      setIsLoading(false);
    }
  };

  const handleChannelRestart = async () => {
    try {
      const { id, customer_id } = dataFromDad;

      const { success } =
        await WhatsAppCreateEditService.restartWhatsAppZAPIChannel({
          channel_customer_id: id,
          customer_id,
        });
      if (!success) {
        throw 'Requisição mal sucedida';
      }
    } catch (error) {
      console.error('Erro encontrado ao tentar restart do canal: ', error);
    }
  };

  async function VerifyChannel() {
    try {
      const verifyResult = await WhatsAppCreateEditService.verifyChannelStatus({
        customer_id,
        channel_customer_id: dataFromDad.id,
      });
      if (verifyResult.connected) {
        let conectado = 'Conectado ao provedor';
        return conectado;
      } else {
        let error = 'Desconectado do provedor';
        return error;
      }
    } catch (error) {
      let res = 'Canal não encontrado';
      if (error.response.data.error) {
        return (res = 'Canal cancelado');
      }
      return res;
    }
  }

  useEffect(() => {
    (async () => {
      if (
        dataFromDad?.config?.zapi &&
        (superRole == 'adm' || superRole == 'manager')
      ) {
        let verifyChannel = await VerifyChannel();
        setVerifyStatusChannel(verifyChannel);
      }
    })();
  }, [dataFromDad?.id, superRole, channelStatus, statusConnected]);

  return (
    <>
      <ZapiToBspModal
        isDarkMode={isDarkMode}
        isOpen={openModalZapiToBsp}
        toggleModal={() => setOpenModalZapiToBsp(!openModalZapiToBsp)}
        onSubmit={handleClickHomologationZApi}
      />
      <Container>
        <ChannelDescriptionBanner />
        <ChannelStatusAlert
          status={channelStatus ? channelStatus : need_new_channel ? 208 : null} //caso não exista status devido a exclusão do config pega status não conectado
          connectCallback={handleConnect}
          disconnectCallback={handleDisconnect}
          showQrCodeButtonCallback={() => setShowQrCodeModal(true)}
          tryAgainButtonCallBack={handleTryAgain}
          showDisconnectButtonCallback={handleDisconnect}
        />
        <Section>
          <CustomModal
            isOpen={showQrCodeModal}
            toggle={() => setShowQrCodeModal(!showQrCodeModal)}
          >
            <CustomModalHeader
              toggle={() => setShowQrCodeModal(!showQrCodeModal)}
            >
              {modalContent.header}
            </CustomModalHeader>
            <CustomModalBody>{modalContent.body}</CustomModalBody>
            <>{modalContent.footer}</>
          </CustomModal>

          {dataFromDad && (
            <ActionGroup
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                margin: '0 0 0.5em auto',
                gap: '0.5em',
              }}
            >
              {show_restart_button && (
                <Button
                  color="info"
                  size="md"
                  style={{
                    display: 'flex',
                    gap: '0.5rem',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingRight: '2rem',
                    paddingLeft: '2rem',
                  }}
                  onClick={handleChannelRestart}
                >
                  <Icon
                    icon="poli-icon pi-update-fill"
                    color="#FFFFFF"
                    size="20"
                  />
                  {'  '}Reiniciar
                </Button>
              )}

              {config?.migrationToWabaStatus !== 'started' ? (
                <ButtonDeleteChannel
                  channel={dataFromDad}
                  customer_id={customer_id}
                  size={'md'}
                />
              ) : (
                <></>
              )}
              {flags.button_zapi_to_bsp?.enabled &&
                //se usuário é ADMIN POLI e não está logado na empresa não irá exibir botão de homologar para BSP
                shouldShowMigrateToWabaButton ? (
                <Button
                  gradient="primary"
                  onClick={() => setOpenModalZapiToBsp(!openModalZapiToBsp)}
                >
                  Homologar canal para Poli BSP
                </Button>
              ) : (
                //se usuário é ADMIN POLI e não está logado na empresa não irá exibir botão de migrar para WABA
                shouldShowMigrateToWabaButton && (
                  <>
                    <ValidatePlanBeforeMigrateWaba
                      currentCustomer={customer_id}
                      migrationToWabaButtonData={{
                        id: id,
                        channelPhone: channelPhone,
                        channelName: channelName,
                        processStatus: config?.migrationToWabaStatus,
                      }}
                    />
                  </>
                )
              )}
            </ActionGroup>
          )}

          <InputsContainer>
            <InputItem>
              <Label id="channel-name-label" for="channel-name">
                <Icon
                  icon="poli-icon pi-channels-fill"
                  color="var(--green-polichat)"
                  size="24"
                />
                Nome do Canal
              </Label>

              <Input
                type="text"
                name="channel-name"
                id="channel-name"
                value={channelName}
                onChange={handleNameChange}
                style={{
                  borderColor: 'var(--borderDefault)',
                }}
                disabled={
                  config?.migrationToWabaStatus === 'started' ? true : false
                }
              />
            </InputItem>

            <InputItem>
              <PhoneContainer>
                <FormGroup>
                  <Label for="channelPhone">
                    <Icon
                      icon="poli-icon pi-smartphone-fill"
                      color="var(--green-polichat)"
                      size="24"
                    />{' '}
                    Telefone com DDD e código de país
                  </Label>
                  <CustomPhoneInput
                    country={'br'}
                    autoFormat={true}
                    value={channelPhone}
                    onChange={handlePhoneChange}
                    onBlur={handlePhoneBlur}
                    required
                    placeholder="+55 (62) 9999-9999"
                    disabled={
                      config?.migrationToWabaStatus === 'started' ? true : false
                    }
                  />
                  {isPhoneValid !== null &&
                    (isPhoneValid ? (
                      <p
                        style={{ color: 'var(--success)', fontSize: '0.7rem' }}
                      >
                        Número disponível
                      </p>
                    ) : (
                      <p style={{ color: 'var(--danger)', fontSize: '0.7rem' }}>
                        Número indisponível
                      </p>
                    ))}
                  {config?.migrationToWabaStatus === 'started' ? (
                    <>
                      <div
                        style={{ color: 'var(--danger)', fontSize: '0.7rem' }}
                      >
                        *Não é possível alterar o número de telefone de um canal
                        em migração para a WABA
                      </div>
                      <br />
                    </>
                  ) : (
                    <></>
                  )}
                </FormGroup>
              </PhoneContainer>
            </InputItem>

            <InputItem>
              <MultiSelectorContainer className="MultiSelectorContainer">
                <Label
                  id="select-users-channel-label"
                  for="select-users-channel"
                >
                  <Icon
                    icon="poli-icon pi-perfil-fill"
                    color={'var(--green-polichat)'}
                    size="24"
                  />
                  Usuários que poderão utilizar este canal
                </Label>
                <MultiSelector
                  className="multiSelector"
                  name="select-users-channel"
                  id="select-users-channel"
                  options={usersOptions}
                  values={selectedUsers}
                  selectedValues={handleGetSelectorValues}
                />
              </MultiSelectorContainer>

              {dataFromDad?.config?.zapi &&
                (superRole == 'adm' || 'manager') ? (
                <>
                  <Label style={{ marginTop: '1.5rem' }}>
                    <Icon
                      icon="poli-icon pi-cloud-fill"
                      color={
                        verifyStatusChannel == 'Conectado ao provedor'
                          ? '#00c48c'
                          : '#ff665d'
                      }
                      size="24"
                    />
                    Dados Provedor:
                  </Label>

                  <ContrastBackgroundContainer
                    style={{ flexWrap: 'wrap', flexDirection: 'column' }}
                  >
                    <div style={{ marginRight: '0.5rem' }}>
                      <span>{`ID: ${dataFromDad?.config?.zapi?.id}`}</span>
                    </div>

                    <div style={{ marginRight: '0.5rem' }}>
                      <br />
                      {verifyStatusChannel == 'Conectado ao provedor' ? (
                        <span className="badge badge-success badge-pill">
                          {verifyStatusChannel}
                        </span>
                      ) : (
                        <span className="badge badge-danger badge-pill">
                          {verifyStatusChannel}
                        </span>
                      )}
                    </div>
                  </ContrastBackgroundContainer>
                </>
              ) : null}
            </InputItem>

            <InputItem>
              <CheckBoxContainer>
                <CustomInput
                  type="checkbox"
                  id="show-widget-at-webchat"
                  label="Exibir canal no Widget do webchat."
                  checked={showChannelOnWidget}
                  onChange={handleShowOnWidgetChange}
                />
              </CheckBoxContainer>
              <InputItem style={{ marginTop: '1.5rem' }}>
                <Label id="channel-name-label" for="channel-name">
                  <Icon
                    icon="poli-icon pi-channels-fill"
                    color="var(--green-polichat)"
                  />
                  Status do Canal
                </Label>

                <SwitchContainer>
                  <Switch
                    color="primary"
                    isChecked={activateChannel}
                    onClick={handleActivateChannelChange}
                  />
                  <p>{activateChannel ? 'Ativado' : 'Desativado'}</p>
                </SwitchContainer>
              </InputItem>
            </InputItem>
          </InputsContainer>

          <ActionGroup>
            <Button
              color="primary"
              size={isMobile ? 'md' : 'lg'}
              disabled={sendButtonDisabled || isLoading}
              onClick={handleSendForm}
              style={{
                cursor: isLoading ? 'wait' : null,
              }}
            >
              {isLoading
                ? 'Carregando...'
                : dataFromDad
                  ? 'Salvar canal'
                  : 'Criar canal'}
            </Button>
            <Button
              color="danger"
              size={isMobile ? 'md' : 'lg'}
              style={{ width: '9rem' }}
              onClick={() => {
                history.push('/tools/channels');
              }}
            >
              Cancelar
            </Button>
          </ActionGroup>
        </Section>
      </Container>
    </>
  );
}
