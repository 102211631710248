export enum EnumMetricType {
  AD = 'ad',
  POST = 'post',
}
export enum EnumMetricFormat {
  IMAGE = 'image',
  VIDEO = 'video',
}
export interface DtoMetaAdsMetricData {
  // BASE INFO
  title: string;
  description: string;

  // METRIC INFO
  metric_id: string;
  origin_type: string;
  origin_id: string;
  messages: number;
  clicks?: null;

  // CREATIVE INFO
  creative_id: string;
  resources_url?: string;
  format?: string;
  headline?: string;
}
