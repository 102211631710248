import React, { useState, useEffect, useRef, useContext } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ChatActions from '../../../../../store/modules/chat/actions';

import Carousel from 'react-elastic-carousel';

import Animation from '../../../../common/animation';

import ProductImage from '../../../../../assets/images/open-box.png';
import { toast } from 'react-toastify';
import {
  Icon,
  Button,
  Form,
  FormGroup,
  CustomInput,
  Card,
  CardTitle,
  CardImg,
  CardBody,
  Alert,
  UncontrolledTooltip,
  CardSubtitle,
} from '@polichat/flamboyant';

import {
  canSendMessage,
  mountMessage,
  findChannelId,
  findSelectedExternalId,
  syncChatShoppingCartToServer,
} from '../../../../../store/modules/chat/functions';
import { priceMask } from '../../../../../utils/priceMask';

import { CustomerChannelBalanceContext } from '../../contexts/customerChannelBalance/context';
import { CustomerHasPaymentIntegratorContext } from '../../contexts/customerHasPaymentIntegrator/context';

function CatalogCarousel({
  chat,
  userData,
  categories,
  products,
  isLoading,
  closeCatalogCarousel,
  fetchCatalog,
  fetchCatalogCategories,
  fetchMoreProduct,
  addMessage,
  sendMessage,
  addItemToCart,
  createShopppingCart,
  currentPagination,
  lastPage,
}) {
  const isOpen = chat.catalog_carousel_show;

  const openedChat = chat;
  const contact = chat.contact;
  const user_id = userData?.id;

  const [category, setCategory] = useState(0);

  // Para controlar o comportamento do botão de enviar produto auto-desativar por 3s após clique
  const [sendProductButtonDisabled, setSendProductButtonDisabled] =
    useState(false);

  const { isUserAbleToSendMessage } = useContext(CustomerChannelBalanceContext);
  const { customerHasPaymentIntegrator } = useContext(
    CustomerHasPaymentIntegratorContext
  );

  const handleCloseCarousel = () => {
    if (document.querySelector('.textBoxContainer')) {
      document.querySelector('.textBoxContainer').classList.remove('hidden');
    }

    if (document.querySelector('.contactExternalContainer')) {
      document
        .querySelector('.contactExternalContainer')
        .classList.remove('hidden');
    }

    // Exibindo botões de outras ações.
    document.querySelector('.buttonUploadFiles').classList.remove('hidden');
    document
      .querySelector('#buttonHandleQuickMessages')
      .classList.remove('hidden');
    document.querySelector('.buttonHandleEmoji').classList.remove('hidden');
    document.querySelector('.buttonHandleAudio').classList.remove('hidden');
    document.querySelector('#sendMessageButton').classList.remove('hidden');
    document.querySelector('.groupActionsLeft').classList.remove('hidden');

    closeCatalogCarousel();
  };

  const handleAddProductCart = (product) => {
    if (!isUserAbleToSendMessage) {
      toast.error(
        'Seu acesso atingiu o limite de créditos contratados no período! Entre em contato com nossa área comercial.'
      );
      return;
    }

    if (openedChat.cart && openedChat.cart.id) {
      addItemToCart(openedChat.id, product.id);
      syncChatShoppingCartToServer(openedChat, openedChat.cart.id);
    } else {
      createShopppingCart({
        contact_id: openedChat.id,
        products: [
          {
            id: product.id,
            price: product.price,
            quantity: 1,
            name: product.name,
          },
        ],
        address: null,
      });
    }
  };

  const handleSendProductMessage = (product) => {
    if (!isUserAbleToSendMessage) {
      toast.error(
        'Seu acesso atingiu o limite de créditos contratados no período! Entre em contato com nossa área comercial.'
      );
      return;
    }

    if (canSendMessage(openedChat)) {
      setSendProductButtonDisabled(true);
      setTimeout(() => {
        setSendProductButtonDisabled(false);
      }, 3000);

      let text = `*${userData?.name} lhe enviou um produto:*\n*${
        product.name
      }*\n${product.price ? `*Por: ${priceMask(product.price + '')}*` : ''}\n${
        product.description ? product.description : ''
      }`;

      let message_body = {
        product_id: product.id,
        name: product.name,
        description: product.description,
        picture: product.image_url
          ? product.image_url
          : `${process.env?.REACT_APP_CDN_HOST}/img/polipay/open-box.png`,
        price: product.price,
        text,
      };

      let message_data = mountMessage(
        openedChat,
        JSON.stringify(message_body),
        'product'
      );

      let data_send = {
        chat_id: openedChat?.id,
        channel_id: findChannelId(openedChat),
        user_id,
        contact_id: contact?.id,
        external_id: findSelectedExternalId(openedChat),
        message_body,
        cuid: message_data.cuid,
        type: message_data.type,
      };

      addMessage(message_data);
      sendMessage(data_send);
    } else {
      toast.warning('Não foi possível enviar a mensagem.', {
        closeButton: false,
      });
    }
  };

  const handleTryAgain = () => fetchCatalog();

  const handleFetchCategories = () => fetchCatalogCategories();

  const [filteredProducts, setFilteredProducts] = useState(products);
  const [itemsToShow, setItemsToShow] = useState();

  const errorImgProduct = (id) => {
    if (id) {
      const _filteredProducts = [...filteredProducts];
      const index = _filteredProducts.findIndex((e) => e.id === id);

      if (index >= 0) {
        _filteredProducts[index].image_url = null;
        setFilteredProducts(_filteredProducts);
      }
    }
  };

  const onNextStart = (currentItem, nextItem) => {
    if (
      filteredProducts.length < nextItem.index + itemsToShow + 1 &&
      currentPagination < lastPage
    ) {
      fetchMoreProduct({
        nextPage: currentPagination + 1,
        category,
        status: 1,
      });
    }
  };

  const onResize = (currentBreakpoint) => {
    setItemsToShow(currentBreakpoint.itemsToShow);
  };

  const carousel = useRef(null);

  useEffect(() => {
    fetchMoreProduct({ nextPage: 1, category, status: 1 });
    carousel?.current?.goTo(0);
  }, [category]);

  useEffect(() => {
    setFilteredProducts(products);
  }, [products]);

  let content = null;

  if (isOpen) {
    if (filteredProducts) {
      content = (
        <>
          <div>
            <Alert
              color="warning"
              style={{ fontSize: '0.8rem', padding: '0.4rem' }}
            >
              É necessário ter um método de pagamento ativo para adicionar um
              produto ao carrinho. Fale com o seu gestor!
            </Alert>
          </div>

          <div className="carouselActions">
            <Form inline>
              <FormGroup>
                {categories ? (
                  categories.length ? (
                    <CustomInput
                      id="category"
                      type="select"
                      name="category"
                      value={category}
                      onChange={(e) => setCategory(e.target.value)}
                    >
                      <optgroup label="Categorias">
                        <option value={0}>Todas as categorias</option>
                        {categories.map((category) => (
                          <option key={category.id} value={category.id}>
                            {category.name}
                          </option>
                        ))}
                      </optgroup>
                    </CustomInput>
                  ) : (
                    <CustomInput
                      id="category"
                      type="select"
                      name="category"
                      disabled
                    >
                      <option> Não há categorias cadastradas </option>
                    </CustomInput>
                  )
                ) : (
                  <>
                    <CustomInput
                      id="category"
                      type="select"
                      name="category"
                      disabled
                    >
                      <option>Erro ao carregar</option>
                    </CustomInput>

                    {isLoading ? (
                      <div style={{ marginLeft: '8px' }}>
                        <Animation icon="loading" size={20} />
                      </div>
                    ) : (
                      <Button
                        color="primary"
                        size={'normal-icon'}
                        onClick={handleFetchCategories}
                      >
                        <Icon
                          icon="poli-icon pi-update-line"
                          size={20}
                          position={'relative'}
                          top={'-3px'}
                          right={'4px'}
                        />
                      </Button>
                    )}
                  </>
                )}
              </FormGroup>
            </Form>
          </div>
          <div className="carouselWrapper">
            {filteredProducts.length ? (
              <Carousel
                breakPoints={[
                  { width: 1, itemsToShow: 1 },
                  { width: 470, itemsToShow: 2, itemsToScroll: 2 },
                  { width: 710, itemsToShow: 3, itemsToScroll: 3 },
                  { width: 960, itemsToShow: 4, itemsToScroll: 4 },
                  { width: 1200, itemsToShow: 5, itemsToScroll: 5 },
                  { width: 1440, itemsToShow: 6, itemsToScroll: 5 },
                ]}
                onNextStart={onNextStart}
                onResize={onResize}
                ref={carousel}
              >
                {filteredProducts.map((product) => (
                  <Card
                    body
                    className="text-center carousel-card"
                    key={product.id}
                    id={'tooltipBlockPoliPay'}
                    style={{
                      cursor:
                        product.quantity <= 0 || !isUserAbleToSendMessage
                          ? 'not-allowed'
                          : 'pointer',
                    }}
                  >
                    {!isUserAbleToSendMessage && (
                      <UncontrolledTooltip
                        placement="top"
                        target="tooltipBlockPoliPay"
                      >
                        Seu acesso atingiu o limite de créditos contratados no
                        período
                      </UncontrolledTooltip>
                    )}
                    <div className="carousel-card-img">
                      {product.image_url ? (
                        <CardImg
                          src={product.image_url}
                          onError={() => errorImgProduct(product?.id)}
                          alt=""
                        />
                      ) : (
                        <img src={ProductImage} />
                      )}
                    </div>
                    <CardBody>
                      <div className="carousel-product-name">
                        <CardTitle>{product.name}</CardTitle>
                        <CardSubtitle className="carousel-product-price">
                          {priceMask(product.price + '')}
                        </CardSubtitle>
                      </div>
                      <hr />
                      <div className="carousel-bottoms">
                        <>
                          <Button
                            size={'normal-icon'}
                            color={'info'}
                            onClick={() => handleAddProductCart(product)}
                            disabled={
                              product.quantity <= 0 ||
                              !isUserAbleToSendMessage ||
                              !customerHasPaymentIntegrator
                            }
                            style={{
                              cursor:
                                product.quantity <= 0 ||
                                !isUserAbleToSendMessage ||
                                !customerHasPaymentIntegrator
                                  ? 'not-allowed'
                                  : 'pointer',
                            }}
                          >
                            <Icon
                              id={'tooltipAddCardButton'}
                              icon="poli-icon pi-add-circle-fill"
                              size={20}
                              position={'relative'}
                              top={'-3px'}
                              right={'4px'}
                            />
                          </Button>
                          <UncontrolledTooltip
                            placement="top"
                            target="tooltipAddCardButton"
                          >
                            {!customerHasPaymentIntegrator
                              ? 'Nenhum metódo de pagamento ativo!'
                              : 'Adicionar produto ao carrinho'}
                          </UncontrolledTooltip>
                        </>
                        <>
                          <Button
                            size={'normal-icon'}
                            onClick={() => handleSendProductMessage(product)}
                            disabled={
                              product.quantity <= 0 ||
                              sendProductButtonDisabled ||
                              !isUserAbleToSendMessage
                            }
                            style={{
                              cursor:
                                product.quantity <= 0 ||
                                sendProductButtonDisabled ||
                                !isUserAbleToSendMessage
                                  ? 'not-allowed'
                                  : 'pointer',
                            }}
                          >
                            <Icon
                              icon="poli-icon pi-set-fill"
                              size={20}
                              position={'relative'}
                              top={'-3px'}
                              right={'4px'}
                              id={'tooltipProductMessage'}
                            />
                          </Button>
                          <UncontrolledTooltip
                            placement="top"
                            target="tooltipProductMessage"
                          >
                            Enviar mensagem do produto
                          </UncontrolledTooltip>
                        </>
                      </div>
                    </CardBody>
                  </Card>
                ))}
              </Carousel>
            ) : (
              <Alert color="warning">
                Não há produtos cadastrados para essa categoria{' '}
                <Icon icon="poli-icon pi-sad-fill" size={20} />
              </Alert>
            )}
          </div>
        </>
      );
    } else {
      content = (
        <>
          {!customerHasPaymentIntegrator && (
            <div>
              <Alert
                color="warning"
                style={{ fontSize: '0.8rem', padding: '0.4rem' }}
              >
                É necessário ter um método de pagamento ativo para adicionar um
                produto ao carrinho. Fale com o seu gestor!
              </Alert>
            </div>
          )}
          <div className="carouselActions">
            <div></div>
            <Button
              border="border-none"
              color="link"
              onClick={handleCloseCarousel}
            >
              <Icon
                icon="poli-icon pi-close-fill"
                size={20}
                color={'var(--danger)'}
              />
            </Button>
          </div>
          <Alert color="warning">
            Não foi possível carregar os seus produtos
          </Alert>
          <Button color="primary" onClick={handleTryAgain}>
            {isLoading ? (
              <Animation icon="loading" size={20} />
            ) : (
              'Tentar novamente'
            )}
          </Button>
        </>
      );
    }
  }
  return <>{content}</>;
}

const mapStateToProps = (state) => ({
  chat: state.chat?.chats?.find((chat) => chat.open === true),
  userData: state.chat?.config?.user,
  categories: state.chat?.catalog?.categories,
  products: state.chat?.catalog?.products,
  isLoading: state.chat?.catalog?.loading,
  currentPagination: state.chat?.catalog?.currentPagination,
  lastPage: state.chat?.catalog?.lastPage,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(ChatActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CatalogCarousel);
