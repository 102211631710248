import React from 'react';
import { Button, Icon } from '@polichat/flamboyant';

function RoundedFloatingButton(props) {
  if (props.visible) {
    return (
      <a
        className="earlierMessagesButton"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '3rem',
          width: '3rem',
          background: 'var(--primary-background-color)',
          borderRadius: 100,
          position: 'absolute',
          bottom: '1rem',
          right: '1.3rem',
          cursor: 'pointer',
          zIndex: '1',
          textDecorationLine: 'none',
          color: 'var(--secondary-font-color)',
        }}
        onClick={props.onClick}
      >
        {props.newMessagesCounter !== 0 ? (
          <div className="newMessagesCounterLabel">
            {props.newMessagesCounter > 9 ? (
              <span>9+</span>
            ) :
            <span>{props.newMessagesCounter}</span>
        }
          </div>
        ) : (
          ''
        )}
        <Icon icon="poli-icon pi-simple-arrow-down-line" />
      </a>
    );
  } else {
    return <></>;
  }
}

export default RoundedFloatingButton;
