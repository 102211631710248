import { DtoPagination } from "../../../../../../../services/tools/channels/logsChannels/types/dtoPagination";
import { LocalFormValues } from "../../../FilterLogs/useLocalForm/types";
import { SearchParams } from "./types";


export const localFormInitialValues: LocalFormValues = {
  customer_id: undefined,
  channel_id: undefined,
  type_change: '',
  user_id: undefined,
  date_start: '',
  date_end: '',
};

export const paginationInitialValues: DtoPagination = {
  page: 1,
  per_page: 10,
  total: 100
}

export const initialSerachParams: SearchParams = {
  filters: localFormInitialValues,
  pagination: paginationInitialValues
}
