import styled from 'styled-components';

export const TemplateDocumentHeader = styled.div`
  background-color: #00000020;
  border-radius: 5px;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  color: var(--primary-font-color) !important;
`;

export const FileIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;

  & span {
    align-self: center !important;
  }
`;

export const FilenameContainer = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const IframeContainer = styled.div`
  width: 100%;
  height: 10rem;
  overflow: hidden;
`;

export const Iframe = styled.iframe`
  pointer-events: none;
  /* width: 100%; */
`;

export const FileDetailsContainer = styled.div`
  font-size: 0.7rem;
  opacity: 0.7;
  color: var(--textDefault);
`;
