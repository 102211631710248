import { useCallback, useState } from "react"
import { laravelAxios } from "src/config/axios/laravelAxios";
import { toast } from 'react-toastify';
import history from "src/services/history";

export function useRestoreContact() {
    const [showRestoreContactModal, setShowRestoreContactModal] = useState(false);
    const handleShowRestoreContactModal = useCallback(() => {
        setShowRestoreContactModal((prev) => !prev);
    }, [])

    const restoreContact = useCallback(async(customer_id: string, contact_id: string) => {
        const url = `contacts/${customer_id}/restore/${contact_id}`;

        try {
            await laravelAxios.post(url);
            toast.success(
                <div style={{ margin: 0 }}>
                  <span>Contato restaurado com sucesso!</span>
                </div>
            );
            setShowRestoreContactModal(false);
            history.push(`/chat#${contact_id}`);
        } catch (error) {
            toast.error(
                <div style={{ margin: 0 }}>
                    <span>Erro ao restaurar o contato!</span>
                    <br/>
                    <span>Favor tentar novamente.</span>
                </div>
            );
            setShowRestoreContactModal(false);
        }
    }, [])

    return {
        restoreContact,
        showRestoreContactModal,
        handleShowRestoreContactModal
    }
}