import { AxiosError } from 'axios';
import { useCallback, useState } from 'react';
import { MetaService } from '../../../../../services/meta-business/meta';
import { getDtoMetaMigrateFromStep1FormValues } from '../../helpers';
import { Step1FormValues } from '../useStep1Form/types';

export interface UseMetaServiceProps {
  customer_id: number;
}
export function useMetaService(props: UseMetaServiceProps) {
  const [loading, setLoading] = useState(false);
  // ---------------------------------------------
  // Functions
  const migrate = useCallback(
    async (
      values: Step1FormValues,
      onSuccess: (value: string) => void,
      onError: (value?: string) => void
    ) => {
      const dto = getDtoMetaMigrateFromStep1FormValues(
        props.customer_id,
        values
      );
      try {
        setLoading(true);
        const response = await MetaService.Migrate(dto);
        onSuccess(response.data.phone_id);
      } catch (error) {
        // @ts-ignore
        onError(error?.response?.data?.message ? error?.response?.data?.message : null );
      } finally {
        setLoading(false);
      }
    },
    [props.customer_id]
  );

  // ---------------------------------------------
  // Effects
  // ---------------------------------------------
  // Transformations
  // ---------------------------------------------
  // API

  return {
    loading,
    migrate,
  };
}
