import styled from 'styled-components';

export const FacebookAdsContainer = styled.div`
  background-color: var(--white);
  min-width: 950px;
  padding: 1.5rem;

  width: 100%;
  height: 100%;

  & h3 {
    color: var(--gray-75);
    font-weight: bold;
    font-size: 1.5rem;
    margin-left: 1rem;
  }
`;

export const IframeContainer = styled.div`
  div,
  span,
  iframe {
    width: 100% !important;
  }

  iframe {
    height: 100% !important;
  }
`;

export const ContainerTitle = styled.div`
  display: flex;
  justify-content: space-between;

  & button {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0px 35px;
    height: 36px;

    background-color: #1877f2;
    border-color: #1877f2;
    border-radius: 6px;
    color: #fff;

    span {
      font-weight: 600;
      font-size: 14px;
    }

    div {
      margin-left: 10px;
    }
  }
`;
