import { toast } from 'react-toastify';
import ObjectToQueryString from '../../../lib/ObjectToQueryString';
import poliApiGatewayRequest from '../../../utils/poliApiGatewayRequest';
import poliConfig from '../../../utils/poliConfig';

import requestLaravel from '../../laravel-web-app/request';

const getAllChannels = async (params) => {
  try {
    const query = params ? '?' + ObjectToQueryString(params) : '';
    const url = `${poliConfig.channelCustomerBaseURL}/${query}`;

    const result = await poliApiGatewayRequest.get(url);
    return result.data;
  } catch (error) {
    // console.log('error when getAllChannels => ', error);
    throw new Error(error);
  }
};

const createChannel = async ({
  customer_id,
  channel_id,
  name,
  phone,
  status,
  users,
  config,
  display_on_webchat,
  api,
}) => {
  try {
    const url = `${poliConfig.channelCustomerBaseURL}/?customer_id=${customer_id}`;

    const formmatedObj = {
      customer_id,
      channel_id,
      name,
      phone,
      status,
      users,
      config,
      display_on_webchat,
      api,
    };

    const result = await poliApiGatewayRequest.post(url, formmatedObj);

    return {
      status: result.status,
      data: result.data,
    };
  } catch (error) {
    if (error.response) {
      return {
        status: error.response.status,
        data: error.response.data,
      };
    }
  }
};

const updateStepHomologation = async (params) => {
  const { customer_id, channel_id, step } = params;
  try {
    const url = `${poliConfig.channelCustomerBaseURL}/${channel_id}/step?customer_id=${customer_id}`;

    const formmatedObj = {
      step,
    };
    const result = await poliApiGatewayRequest.post(url, formmatedObj);

    return {
      status: result.status,
      data: result.data,
    };
  } catch (error) {
    if (error.response) {
      return {
        status: error.response.status,
        data: error.response.data,
      };
    }
  }
};

//Migrate template: Migrate old approved templates to new BSP
const RecreateTemplate = async (channelID, customer_id) => {
  try {
    const url = `${poliConfig.channelCustomerBaseURL}/migrate-template?customer_id=${customer_id}`;

    const formmatedObj = {
      channelID,
    };
    const result = await poliApiGatewayRequest.post(url, formmatedObj);

    return result;

  } catch (error) {
    return error;

  }
};

const createAddressToken = async (params) => {
  const { customer_id, channel_id, address, token } = params;
  try {
    const url = `${poliConfig.channelCustomerBaseURL}/${channel_id}/address_token?customer_id=${customer_id}`;

    const formmatedObj = {
      address,
      token,
    };

    const result = await poliApiGatewayRequest.post(url, formmatedObj);

    return {
      status: result.status,
      data: result.data,
    };
  } catch (error) {
    if (error.response) {
      return {
        status: error.response.status,
        error: error.response.data.error[0],
      };
    }
  }
};

const getWabaVerticals = async ({ customer_id }) => {
  try {
    const url = `${poliConfig.apiGatewayBaseURL}/waba-vertical?customer_id=${customer_id}`;

    const result = await poliApiGatewayRequest.get(url);
    return result.data.data;
  } catch (error) {
    console.log('error when getWabaVerticals => ', error);
  }
};

const getChannelData = async ({ customer_id, id }) => {
  try {
    const url = `${poliConfig.channelCustomerBaseURL}/${id}?customer_id=${customer_id}`;

    const result = await poliApiGatewayRequest.get(url);
    return result.data;
  } catch (error) {
    console.log('error when getChannelData => ', error);
  }
};

const updateProfile = async (params) => {
  const { customer_id, channel_id, formData } = params;
  try {
    const url = `${poliConfig.channelCustomerBaseURL}/${channel_id}?customer_id=${customer_id}`;

    const result = await poliApiGatewayRequest.put(url, formData);

    if (result?.data?.customer_id) {
      return result;
    } else {
      toast.error('Erro ao Atualizar');
      return 'Erro ao Atualizar';
    }
  } catch (error) {
    console.log('Error in Channels when updateProfile => ', error);

    if (error?.response) {
      return {
        status: error.response?.status,
        error: error.response?.data,
      };
    } else {
      return {
        status: 500,
        error: true,
      };
    }
  }
};

const updateChannel = async ({
  id,
  customer_id,
  channel_id,
  name,
  phone,
  status,
  display_on_webchat,
  connection,
  users,
  config,
  api,
  uid,
}) => {
  try {
    const url = `${poliConfig.channelCustomerBaseURL}/${id}?customer_id=${customer_id}`;

    const formattedObj = {
      channel_id,
      customer_id,
      name,
      phone,
      status,
      display_on_webchat,
      connection,
      users,
      config: JSON.stringify(config),
      api,
      uid,
    };

    if (!Object.keys(formattedObj) || Object.keys(formattedObj).length <= 2) {
      return 'Nada para atualizar';
    }

    const result = await poliApiGatewayRequest.put(url, formattedObj);

    if (result.status === 200) {
      return result;
    } else {
      return 'Erro ao Atualizar';
    }
  } catch (error) {
    if (error.response) {
      return {
        status: error.response.status,
        error: error.response.data,
      };
    }
  }
};

const disconnectFB = async ({ id, customer_id }) => {
  try {
    const url = `${poliConfig.channelCustomerBaseURL}/${id}/fb/disconnect?customer_id=${customer_id}`;
    const result = await poliApiGatewayRequest.delete(url);

    return result.status == 204;
  } catch (e) {
    if (error.response) {
      return {
        status: error.response.status,
        error: error.response.data,
      };
    }
  }
};

const connectFB = async ({ id, customer_id }) => {
  try {
    const url = `${poliConfig.channelCustomerBaseURL}/${id}/fb/connect?customer_id=${customer_id}`;
    const result = await poliApiGatewayRequest.post(url);

    return result.status == 204;
  } catch (e) {
    if (error.response) {
      return {
        status: error.response.status,
        error: error.response.data,
      };
    }
  }
};

const deleteChannel = async ({ id, customer_id }) => {
  try {
    const url = `${poliConfig.channelCustomerBaseURL}/${id}?customer_id=${customer_id}`;

    const result = await poliApiGatewayRequest.delete(url);

    return result;
  } catch (error) {
    return error;
  }
};

const restartChannel = async ({ id, customer_id }) => {
  try {
    const url = `${poliConfig.channelCustomerBaseURL}/${id}/restart?customer_id=${customer_id}`;

    const result = await poliApiGatewayRequest.post(url);

    return result;
  } catch (error) {
    return error;
  }
};

const actionSend = async (action, { id, customer_id }) => {
  try {
    const url = `${poliConfig.channelCustomerBaseURL}/${id}/${action}?customer_id=${customer_id}`;

    const result = await poliApiGatewayRequest.post(url);

    return result;
  } catch (error) {
    return error;
  }
};

const postProfilePicture = async ({
  customer_id,
  id,
  formData,
  onUploadProgress,
}) => {
  try {
    const url = `${poliConfig.channelCustomerBaseURL}/${id}/photo?customer_id=${customer_id}`;

    const config = {
      onUploadProgress,
    };

    return await poliApiGatewayRequest.post(url, formData, config);
  } catch (error) {
    return error;
  }
};

const getAllUsersByCustomer = async (customer_id) => {
  try {
    const url = `${poliConfig.channelCustomerBaseURL}/users?customer_id=${customer_id}`;

    return await poliApiGatewayRequest.get(url);
  } catch (error) {
    return error;
  }
};

const getAllUsersChannel = async (customer_id, id) => {
  try {
    const url = `${poliConfig.channelCustomerBaseURL}/${id}/users?customer_id=${customer_id}`;
    return await poliApiGatewayRequest.get(url);
  } catch (error) {
    return error;
  }
};

const getLinkFacebook = async () => {
  try {
    return await requestLaravel({
      method: 'GET',
      url: '/fb/getlinklogin',
      withCredentials: true,
      maxRedirects: 0, //Evitar que o laravel redirecione para o spa e entrar em loop. Browsers will always follow HTTP redirects, need this to abort redirect.
      redirect: 'manual', //Evitar que o laravel redirecione para o spa e entrar em loop.
    });
  } catch (error) {
    return error;
  }
};

const getWabaMetaStatus = async (channel_id) => {
  try {
    return await requestLaravel({
      method: 'GET',
      url: `/meta/status/channel/${channel_id}`,
      withCredentials: true,
      maxRedirects: 0,
      redirect: 'manual',
    });
  } catch (error) {
    return error;
  }
};

const notifyLaravelSaveMeta = async (channel_id) => {
  try {
    return await requestLaravel({
      method: 'POST',
      url: `/meta/save/channel/${channel_id}`,
      withCredentials: true,
      maxRedirects: 0,
      redirect: 'manual',
    });
  } catch (error) {
    return error;
  }
};

export default {
  createAddressToken,
  createChannel,
  deleteChannel,
  restartChannel,
  getAllChannels,
  getAllUsersByCustomer,
  getAllUsersChannel,
  getChannelData,
  getWabaVerticals,
  updateChannel,
  updateProfile,
  updateStepHomologation,
  postProfilePicture,
  actionSend,
  getLinkFacebook,
  disconnectFB,
  connectFB,
  getWabaMetaStatus,
  notifyLaravelSaveMeta,
  RecreateTemplate,
};
