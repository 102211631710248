import ObjectToQueryString from '../../../lib/ObjectToQueryString';
import poliApiGatewayRequest from '../../../utils/poliApiGatewayRequest';
import poliConfig from '../../../utils/poliConfig';

const getWabaCategories = async (params) => {
  try {
    const url =
      `${poliConfig.apiGatewayBaseURL}/waba-category` +
      (params ? '?' + ObjectToQueryString(params) : '');

    const { data } = await poliApiGatewayRequest.get(url);
    if (data.data) {
      return data.data;
    } else {
      return false;
    }
  } catch (error) {
    console.log(
      'error when getWabaCategories => template-waba service ',
      error
    );
  }
};

const getWabaLanguages = async (params) => {
  try {
    const url =
      `${poliConfig.apiGatewayBaseURL}/waba-language` +
      (params ? '?' + ObjectToQueryString(params) : '');

    const { data } = await poliApiGatewayRequest.get(url);
    if (data.data) {
      return data.data;
    } else {
      return false;
    }
  } catch (error) {
    console.error(
      'error when getWabaCategories template-waba service => ',
      error
    );
  }
};

const getTemplatesWabaImport = async (params) => {
  try {
    const { customer_id } = params;
    const url = `${poliConfig.channelCustomerBaseURL}/${params.channel_id}/templates/import?customer_id=${customer_id}`;

    const { data } = await poliApiGatewayRequest.get(url);

    if (data.data) {
      return data;
    } else {
      return false;
    }
  } catch (error) {
    console.error(
      'error when getTemplateWabaImport template-waba service => ',
      error
    );
  }
};

const getTemplatesWaba = async (params) => {
  try {
    const url = `${poliConfig.apiGatewayBaseURL}/template${
      params ? '?' + ObjectToQueryString(params) : ''
    }`;

    const { data } = await poliApiGatewayRequest.get(url);

    if (data.data) {
      return data;
    } else {
      return false;
    }
  } catch (error) {
    console.error(
      'error when getTemplateWaba template-waba service => ',
      error
    );
  }
};

const syncTemplates = async (params) => {
  try {
    const url = `${poliConfig.apiGatewayBaseURL}/templates/sync${
      params ? '?' + ObjectToQueryString(params) : ''
    }`;

    const result = await poliApiGatewayRequest.get(url);

    if (result) {
      return result;
    } else {
      return false;
    }
  } catch (error) {
    console.error(
      'error when syncTemplates template-waba service => ',
      error
    );
  }
};

const getTemplateById = async (params) => {
  try {
    const { customer_id } = params;
    const url = `${poliConfig.apiGatewayBaseURL}/template/${params.id}?customer_id=${customer_id}`;
    const { data } = await poliApiGatewayRequest.get(url);

    if (data.template) {
      return data.template;
    } else {
      return false;
    }
  } catch (error) {
    console.error('Error when getTemplateById template-waba service =>', error);
  }
};

const createTemplateWaba = async (params) => {
  try {
    const { customer_id } = params;
    const url = `${poliConfig.channelCustomerBaseURL}/template?customer_id=${customer_id}`;

    const formattedObj = {
      ...params.formData,
    };

    const result = await poliApiGatewayRequest.post(url, formattedObj);
    const {
      status,
      data: { err, message, template },
    } = result;

    if (!err) {
      return { success: true, template };
    } else {
      return { error: err, message };
    }
  } catch (error) {
    console.error(
      'error when createTemplateWaba template-waba service => ',
      error
    );
    return { error };
  }
};

const createQuickMessage = async (params) => {
  try {
    const { customer_id } = params;
    const url = `${poliConfig.channelCustomerBaseURL}/${params.channel_id}/quickMessage?customer_id=${customer_id}`;

    const formattedObj = {
      ...params.formData,
    };

    const result = await poliApiGatewayRequest.post(url, formattedObj);
    const {
      status,
      data: { err, message, template },
    } = result;

    if (!err) {
      return { success: true, template };
    } else {
      return { error: err, message };
    }
  } catch (error) {
    console.error(
      'error when createTemplateWaba quick-message service => ',
      error
    );
    return { error };
  }
};

const editTemplateWaba = async (params) => {
  try {
    const { customer_id } = params;
    const url = `${poliConfig.apiGatewayBaseURL}/template/${params.template_id}?customer_id=${customer_id}`;

    const formattedObj = {
      ...params.formData,
    };

    const result = await poliApiGatewayRequest.put(url, formattedObj);
    const {
      status,
      data: { err, message, template },
    } = result;

    if (!err) {
      return { success: true, template };
    } else {
      return { error: err, message };
    }
  } catch (error) {
    console.error(
      'error when editTemplateWaba template-waba service => ',
      error
    );
  }
};

const deleteTemplateWaba = async (params) => {
  try {
    const { customer_id } = params;
    const url = `${poliConfig.apiGatewayBaseURL}/template/${params.template_id}?customer_id=${customer_id}`;

    const formattedObj = {
      ...params.formData,
    };

    const result = await poliApiGatewayRequest.delete(url, formattedObj);
    const {
      status,
      data: { err, message, template },
    } = result;

    if (!err) {
      return { success: true, template };
    } else {
      return { error: err, message };
    }
  } catch (error) {
    console.error(
      'error when deleteTemplateWaba template-waba service => ',
      error
    );
    return { error: true };
  }
};

const deleteImportTemplateWaba = async (params) => {
  try {
    const { customer_id } = params;
    const url = `${poliConfig.apiGatewayBaseURL}/template/${params.template_name}/${params.channel_id}?customer_id=${customer_id}`;

    const formattedObj = {
      ...params.formData,
    };

    const result = await poliApiGatewayRequest.delete(url, formattedObj);
    const {
      status,
      data: { err, message, template },
    } = result;

    if (!err) {
      return { success: true, template };
    } else {
      return { error: err, message };
    }
  } catch (err) {
    console.error(
      'error when deleteImportTemplateWaba template-waba service => ',
      err
    );
    return { error: true, message: 'Houve um erro inesperado!' };
  }
};

const getTemplateAwsUrlAssigned = async (params) => {
  try {
    const { customer_id } = params;
    const url = `${poliConfig.apiGatewayBaseURL}/waba-media-url?customer_id=${customer_id}`;
    const formattedObj = params;
    const result = await poliApiGatewayRequest.post(url, formattedObj);
    return result.data;
  } catch (error) {
    console.error(
      'error when getTemplateAwsUrlAssigned template-waba service => ',
      error
    );
    return { error };
  }
};

const deleteTemplateAwsUrlAssignedFile = async (params) => {
  try {
    const { customer_id } = params;
    const url = `${poliConfig.apiGatewayBaseURL}/waba-media-url/delete?customer_id=${customer_id}`;
    const formattedObj = {
      ...params,
    };
    const result = await poliApiGatewayRequest.post(url, formattedObj);
    return result.data;
  } catch (error) {
    console.error(
      'error when deleteTemplateAwsUrlAssignedFile template-waba service => ',
      error
    );
    return { error };
  }
};

export default {
  getTemplatesWabaImport,
  getWabaCategories,
  getWabaLanguages,
  createTemplateWaba,
  createQuickMessage,
  getTemplatesWaba,
  syncTemplates,
  getTemplateById,
  editTemplateWaba,
  deleteTemplateWaba,
  deleteImportTemplateWaba,
  getTemplateAwsUrlAssigned,
  deleteTemplateAwsUrlAssignedFile,
};
