import { Icon, Input, InputGroup, InputGroupText } from '@polichat/flamboyant';

import { Search } from '@poli-ink/react';

import styles from './QuickFields.module.css';
export interface QuickSearchTermProps {
  id: string;
  value: string;
  onChange: (value: string) => void;
}
export function QuickSearchTerm(props: Readonly<QuickSearchTermProps>) {
  const inLineStyle = {
    position: 'absolute',
    top: 3,
    right: 12,
    zIndex: '998',
    width: 44,
    height: 32,
    backgroundColor: 'transparent',
  };

  return (
    <div className={styles.search_container}>
      <InputGroup className={styles.search_input}>
        <Input
          color="primary"
          type="text"
          id={props.id}
          name="quick-search"
          placeholder="Buscar..."
          value={props.value}
          onChange={props.onChange}
          style={{
            borderRadius: '0.25rem',
            width: '100%',
            position: 'relative',
          }}
        />
        <InputGroupText style={inLineStyle}>
          {props.value ? (
            <Icon
              icon="poli-icon pi-close-line"
              size={20}
              cursor="pointer"
              color={'var(--primary)'}
              // limpar a busca
              onClick={() => props.onChange('')}
            />
          ) : (
            <Search color={'var(--primary)'} width="20" height="20" />
          )}
        </InputGroupText>
      </InputGroup>
    </div>
  );
}
