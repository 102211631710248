import { useState, useCallback } from 'react';

export default function useModal() {

  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState('');

  const onCloseRequest = useCallback(()=> {
    setIsOpen(false)
    setSelected('');
  }, [])

  const onOpenRequest = useCallback((value)=> {
    setIsOpen(true)
    setSelected(value);
  }, [])



  return {
    isOpen,
    selected,
    onCloseRequest,
    onOpenRequest
  };
}
