import axios from 'axios';
import env from '../env';
import { DebugToken } from '../types/DebugToken';
import poliApiGatewayRequest from 'src/utils/poliApiGatewayRequest';

const getWabasFromDebugToken = async (
  token: string,
  requestFromWabaService: boolean
): Promise<string[] | undefined> => {
  try {
    var response;
    if(requestFromWabaService){
      const url = `/waba/token/${token}`;
      response = await poliApiGatewayRequest.get(url);
    } else {
      response = await axios.get(
        `https://graph.facebook.com/v14.0/debug_token?input_token=${token}&access_token=${env.SYSTEM_USER_ACCESS_TOKEN}`
      );
    }

    const data: DebugToken | undefined = response?.data?.data;
    if (!data) return;

    const foundScopeWBM = data.granular_scopes.find((e) => {
      return e.scope === 'whatsapp_business_management';
    });

    if (foundScopeWBM) {
      return foundScopeWBM.target_ids;
    } else {
      return;
    }
  } catch (error) {
    console.error(error);
    return;
  }
};

export { getWabasFromDebugToken };
