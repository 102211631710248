import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import logoMercadoPago from '../../../assets/images/polipay/mercado-pago.svg';
import logoPagSeguro from '../../../assets/images/polipay/pag-seguro.svg';
import * as ShoppingOrdersList from '../../../store/modules/shopping/orders-list/actions';
import { CancelButton } from './styles';
import {
  Icon,
  Button,
  Col,
  Row,
  Form,
  FormGroup,
  Label,
  InputGroup,
  Input,
  InputGroupAddon,
  CustomInput,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledTooltip,
  LetterAvatar,
  Alert,
} from '@polichat/flamboyant';

//common shopping
import PageTitle from '../common/PageTitle';
import TablePagination from '../TablePagination';
import PaymentMethod from '../PaymentMethod';

//common spa
import Animation from '../../common/animation';
import ModalConfirmDelete from '../order/CancelButton';
import ModalStatus from '../../common/modals/ModalStatus';

import { priceMask } from '../../../utils/priceMask';
import { formatDate } from '../../../utils/formatDate';
import { toDate } from 'date-fns';
import checkAvatar from '../../../utils/checkAvatar';

import Avatar from '../../common/profile/Avatar';

const GatewaysNames = {
  pagseguro: 'PagSeguro',
  mercadopago: 'Mercado Pago',
};

import poliConfig from '../../../utils/poliConfig';

function OrderList({
  //STORE VALUES
  orders,
  pagination,
  isLoadingOrders,
  error,
  orderDetail,
  ordersStatusList,
  paymentMethodsList,
  paymentGateways,
  //status requests
  ordersRequestStatus,
  orderDetailRequestStatus,
  orderDeleteRequestStatus,

  //ACTIONS
  getOrders,
  getOrderDetail,
  deleteOrder,

  getOrdersStatus,
  getPaymentMethods,
  getPaymentGateways,
  //status requests

  resetOrderDetailRequestStatus,
  resetOrderDeleteRequestStatus,
}) {
  const per_page = 10,
    type = 'payment',
    showContact = true;

  useEffect(() => {
    getOrders({
      per_page,
      showContact,
      type,
    });

    getOrdersStatus();
    getPaymentMethods();
    getPaymentGateways();
  }, []);

  const onPageChange = (data) => {
    getOrders({
      per_page,
      showContact,
      type,
      page: data.currentPage,
      search,
      status: statusSearch,
      date: dateSearch,
      payment_method_id: paymentSearch,
      gateway_id: Number(gatewaySearch) === 0 ? '' : gatewaySearch,
    });
  };

  //
  const [search, setSearch] = useState('');
  const [statusSearch, setStatusSearch] = useState(0);
  const [dateSearch, setDateSearch] = useState('');
  const [paymentSearch, setPaymentSearch] = useState(0);
  const [gatewaySearch, setGatewaySearch] = useState(0);

  const [currentSearch, setCurrentSearch] = useState('');
  const [currentStatusSearch, setCurrentStatusSearch] = useState(0);
  const [currentDateSearch, setCurrentDateSearch] = useState('');
  const [currentPaymentSearch, setCurrentPaymentSearch] = useState(0);
  const [currentGatewaySearch, setCurrentGatewaySearch] = useState(0);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      !search &&
      !statusSearch &&
      !dateSearch &&
      !paymentSearch &&
      !gatewaySearch
    )
      return;

    if (
      search === currentSearch &&
      statusSearch === currentStatusSearch &&
      dateSearch === currentDateSearch &&
      paymentSearch === currentPaymentSearch &&
      gatewaySearch === currentGatewaySearch
    )
      return;

    setCurrentSearch(search);
    setCurrentStatusSearch(statusSearch);
    setCurrentDateSearch(dateSearch);
    setCurrentPaymentSearch(paymentSearch);
    setCurrentGatewaySearch(gatewaySearch);

    getOrders({
      per_page,
      showContact,
      status: statusSearch,
      search,
      type,
      date: dateSearch,
      payment_method_id: paymentSearch,
      gateway_id: Number(gatewaySearch) === 0 ? '' : gatewaySearch,
    });
  };

  const handleClearSubmit = (e) => {
    e.preventDefault();

    setCurrentSearch(search);
    setCurrentStatusSearch(statusSearch);
    setCurrentDateSearch(dateSearch);
    setCurrentPaymentSearch(paymentSearch);
    setCurrentGatewaySearch(gatewaySearch);

    getOrders({
      per_page,
      showContact,
      type,
    });
  };

  //Modal details order
  const [modalOrderDetails, setModalOrderDetails] = useState(false);
  const toggleModalOrderDetails = () =>
    setModalOrderDetails(!modalOrderDetails);

  //sideEffects ao sair do modal de detalhes do pagamento
  useEffect(() => {
    if (!modalOrderDetails) {
      resetOrderDetailRequestStatus();
    }
  }, [modalOrderDetails]);

  //side effects da requisição de detalhes do pagamento
  useEffect(() => {
    if (orderDetailRequestStatus && orderDetail) {
      toggleModalOrderDetails();
    } else if (orderDetailRequestStatus === false) {
    }
  }, [orderDetailRequestStatus, orderDetail]);

  //Modal confirm delete
  const [modalConfirmDeleteData, setModalConfirmDeleteData] = useState('');
  const [modalOrderDelete, setModalOrderDelete] = useState(false);
  const toggleModalOrderDelete = () => setModalOrderDelete(!modalOrderDelete);

  //Modal do status de resposta
  const [modalStatus, setModalStatus] = useState(true);
  const toggleModalStatus = () => setModalStatus(!modalStatus);
  //sideEffects do modal do status de resposta
  useEffect(() => {
    if (!modalStatus) {
      if (orderDeleteRequestStatus) {
        getOrders({
          per_page,
          showContact,
          type,
        });
      }

      if (orderDeleteRequestStatus !== null) resetOrderDeleteRequestStatus();

      if (orderDetailRequestStatus !== null) resetOrderDetailRequestStatus();

      setModalStatus(true);
    }
  }, [modalStatus]);
  console.log(getOrderDetail);
  const ordersTableTooltip = [
    { id: 'id', title: 'ID', text: 'Identificação do pagamento' },
    { id: 'costumer', title: 'Cliente', text: 'Identificação do cliente' },
    { id: 'status', title: 'Status', text: 'Status de andamento do pagamento' },
    { id: 'date', title: 'Data', text: 'Data de criação do pagamento' },
    {
      id: 'payment',
      title: 'Pagamento',
      text: 'Tipo de pagamento do pagamento',
    },
    { id: 'method', title: 'Método', text: 'Método escolhido do pagamento' },
    { id: 'price', title: 'Valor', text: 'Valor total do pagamento' },
    {
      id: 'options',
      title: 'Opções',
      text: 'Visualizar detalhes e cancelar pedido',
    },
  ];

  let statusTypeId;
  let addressDetail;

  return (
    <>
      <div className="shoppingTablePageContainer">
        {/* <PageTitle title="Pagamentos" icon="poli-icon pi-order-line" /> */}
        <div className="containerMobile">
          <div className="shoppingSearchTableContainer">
            <Form onSubmit={handleSubmit}>
              <Row form>
                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                  <FormGroup>
                    <InputGroup id="searchOrder">
                      <Input
                        id="search"
                        type="text"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        placeholder="Buscar"
                      />
                      <InputGroupAddon addonType="append">
                        <Button color="primary">
                          <Icon
                            position="relative"
                            top="2px"
                            icon="poli-icon pi-search-line"
                            size={18}
                            color={'white'}
                          />
                        </Button>
                      </InputGroupAddon>
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col xs={12} sm={6} md={6} lg={3} xl={3}>
                  <FormGroup>
                    <Input
                      id="statusOrder"
                      type="select"
                      name="statusOrder"
                      value={statusSearch}
                      onChange={(e) => setStatusSearch(e.target.value)}
                    >
                      <optgroup label="Status">
                        <option value={0}>Todos</option>
                        {ordersStatusList.length &&
                          ordersStatusList.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.description}
                            </option>
                          ))}
                      </optgroup>
                    </Input>
                  </FormGroup>
                </Col>
                <Col xs={12} sm={6} md={6} lg={3} xl={3}>
                  <FormGroup>
                    <Input
                      id="dateOrder"
                      type="date"
                      name="dateOrder"
                      value={dateSearch}
                      onChange={(e) => setDateSearch(e.target.value)}
                      placeholder="AAAA-MM-DD"
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} sm={6} md={6} lg={4} xl={3}>
                  <FormGroup>
                    <Input
                      id="paymentTypeOrder"
                      type="select"
                      name="paymentTypeOrder"
                      value={paymentSearch}
                      onChange={(e) => setPaymentSearch(e.target.value)}
                    >
                      <optgroup label="Pagamento">
                        <option value={0}>Todos</option>
                        {paymentMethodsList.length &&
                          paymentMethodsList.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.description}
                            </option>
                          ))}
                      </optgroup>
                    </Input>
                  </FormGroup>
                </Col>
                <Col xs={12} sm={6} md={6} lg={4} xl={3}>
                  <FormGroup>
                    <Input
                      id="paymentTypeOrder"
                      type="select"
                      name="paymentTypeOrder"
                      value={gatewaySearch}
                      onChange={(e) => setGatewaySearch(e.target.value)}
                    >
                      <optgroup label="Método">
                        <option value={0}>Todos</option>
                        {paymentGateways?.map((item) => (
                          <option key={item.id} value={item.id}>
                            {GatewaysNames[item.name]}
                          </option>
                        ))}
                      </optgroup>
                    </Input>
                  </FormGroup>
                </Col>
                <Col xs={12} sm={3} md={3} lg={2} xl={3}>
                  <FormGroup>
                    <Button width="total" outline color="primary">
                      <span>Filtrar</span>
                      <Icon
                        position="relative"
                        top="2px"
                        icon={'poli-icon pi-filter-line'}
                        size={18}
                      />
                    </Button>
                  </FormGroup>
                </Col>
                <Col xs={12} sm={3} md={3} lg={2} xl={3}>
                  <FormGroup>
                    <Button
                      id="cleanSearchInput"
                      outline
                      width="total"
                      color="danger"
                      onClick={(e) => {
                        setSearch('');
                        setStatusSearch(0);
                        setDateSearch('');
                        setPaymentSearch(0);
                        setGatewaySearch(0);
                        handleClearSubmit(e);
                      }}
                    >
                      <Icon
                        position="relative"
                        top="2px"
                        icon={'poli-icon pi-cleaning-fill'}
                        size={20}
                      />
                    </Button>
                    <UncontrolledTooltip
                      placement="bottom"
                      target="cleanSearchInput"
                    >
                      Limpar campo de pesquisa.
                    </UncontrolledTooltip>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </div>
          <div className="shoppingTableContainer">
            {isLoadingOrders ? (
              <Animation
                icon="chat-loading"
                top={'50%'}
              />
            ) : ordersRequestStatus === false && error ? (
              <p>
                <Button
                  color="primary"
                  onClick={() => getOrders({ per_page, showContact, type })}
                >
                  Tentar novamente
                </Button>
              </p>
            ) : ordersRequestStatus && orders && orders.length ? (
              <Table striped responsive hover className="shoppingPayments">
                <thead>
                  <tr>
                    {ordersTableTooltip.map((title) => (
                      <th key={title.id}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <span>{title.title}</span>
                          <div id={'tooltip' + title.id}>
                            <Icon
                              icon="poli-icon pi-question-fill"
                              size={20}
                              cursor="pointer"
                              color="var(--principal-icons-color)"
                              position={'relative'}
                              top={'3px'}
                              right={'-4px'}
                            />
                          </div>
                          <UncontrolledTooltip
                            placement="top"
                            target={'tooltip' + title.id}
                          >
                            {title.text}
                          </UncontrolledTooltip>
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {orders.map((order) => {
                    let payment = order.payment || '';
                    let contact = order.contact || '';
                    return (
                      <tr key={order.id}>
                        <td className="colWhitespaceNowrap">
                          {'# ' + order.id}
                        </td>
                        <td>
                          <div className="avatarName">
                            <Avatar
                              className="contact"
                              title={contact.name}
                              url={checkAvatar(contact.name, '')}
                              size={30}
                            />
                            <span
                              className="tableEllipsis"
                              style={{ maxWidth: '100px' }}
                            >
                              {contact.name}
                            </span>
                          </div>
                        </td>
                        <td className="tableEllipsis">
                          {ordersStatusList.length &&
                          (statusTypeId = ordersStatusList.find(
                            (status) => status.id === order.status
                          )) ? (
                            <span
                              style={{
                                color:
                                  statusTypeId.id === 1 || statusTypeId.id === 4
                                    ? 'var(--attention)'
                                    : statusTypeId.id === 2
                                    ? 'var(--info)'
                                    : statusTypeId.id === 3
                                    ? 'var(--secondary)'
                                    : statusTypeId.id === 5
                                    ? 'var(--success)'
                                    : statusTypeId.id === 7
                                    ? 'var(--warning)'
                                    : 'var(--danger)',
                              }}
                            >
                              {statusTypeId.description}
                            </span>
                          ) : (
                            ''
                          )}
                        </td>
                        <td>{formatDate(order.created_at)}</td>

                        <td>
                          {payment.installment_quantity ||
                          payment.method?.name ? (
                            <PaymentMethod
                              quantity={payment.installment_quantity}
                              method={payment.method?.name}
                            />
                          ) : (
                            ''
                          )}
                        </td>

                        <td>
                          <div>
                            {order.gateway === 'pagseguro' ? (
                              <>
                                <img
                                  id={`tooltip-order-gateway-${order.id}`}
                                  className="imgMercadoPago"
                                  src={logoPagSeguro}
                                  alt="PagSeguro"
                                  width={30}
                                  title={GatewaysNames[order.gateway]}
                                />
                                <UncontrolledTooltip
                                  placement="top"
                                  target={`tooltip-order-gateway-${order.id}`}
                                >
                                  {GatewaysNames[order.gateway]}
                                </UncontrolledTooltip>
                              </>
                            ) : order.gateway === 'mercadopago' ? (
                              <>
                                <img
                                  id={`tooltip-order-gateway-${order.id}`}
                                  className="imgMercadoPago"
                                  src={logoMercadoPago}
                                  alt="Mercado Pago"
                                  width={30}
                                  title={GatewaysNames[order.gateway]}
                                />
                                <UncontrolledTooltip
                                  placement="top"
                                  target={`tooltip-order-gateway-${order.id}`}
                                >
                                  {GatewaysNames[order.gateway]}
                                </UncontrolledTooltip>
                              </>
                            ) : (
                              ''
                            )}
                          </div>
                        </td>
                        <td>
                          {payment.installment_price ? (
                            <span className="colWhitespaceNowrap">
                              {priceMask(payment.installment_price + '')}
                            </span>
                          ) : (
                            <span className="colWhitespaceNowrap">
                              {priceMask(order.total + '')}
                            </span>
                          )}
                        </td>
                        <td>
                          <Button
                            size={'normal-icon'}
                            color="primary"
                            onClick={() => getOrderDetail(order.id)}
                          >
                            <Icon
                              icon={'poli-icon pi-eye-fill'}
                              color="white"
                              position={'relative'}
                              top={'-3px'}
                              right={'3px'}
                              size={20}
                            />
                          </Button>
                          <CancelButton
                            id={'cancel_order' + order.id}
                            style={{
                              cursor:
                                (order.gateway === 'pagseguro' &&
                                  order.status >= 5) ||
                                order.status > 4
                                  ? 'not-allowed'
                                  : 'pointer',
                              padding: '10px 0',
                            }}
                          >
                            {order.gateway === 'pagseguro' &&
                            order.status >= 4 &&
                            order.status < 9 ? (
                              <UncontrolledTooltip
                                placement="top"
                                target={'cancel_order' + order.id}
                              >
                                O pedido não pode ser cancelado após ser pago.
                              </UncontrolledTooltip>
                            ) : order.status === 9 ||
                              (order.gateway === 'pagseguro' &&
                                order.status === 9) ? (
                              <UncontrolledTooltip
                                placement="top"
                                target={'cancel_order' + order.id}
                              >
                                Pedido cancelado.
                              </UncontrolledTooltip>
                            ) : null}

                            <Button
                              size={'normal-icon'}
                              color="danger"
                              style={{
                                cursor:
                                  (order.gateway === 'pagseguro' &&
                                    order.status >= 5) ||
                                  order.status > 4
                                    ? 'not-allowed'
                                    : 'pointer',
                              }}
                              disabled={
                                (order.gateway === 'pagseguro' &&
                                  order.status >= 5) ||
                                order.status > 4
                                  ? true
                                  : false
                              }
                              onClick={() => {
                                toggleModalOrderDelete();
                                setModalConfirmDeleteData(order);
                              }}
                            >
                              <Icon
                                icon={'poli-icon pi-delete-fill'}
                                color="white"
                                position={'relative'}
                                top={'-3px'}
                                right={'3px'}
                                size={20}
                              />
                            </Button>
                          </CancelButton>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            ) : (
              <Alert
                color="attention"
                style={{ margin: 0, justifyContent: 'center' }}
              >
                Não há pagamentos registrados
                <Icon icon="poli-icon pi-sad-fill" size={20} />
              </Alert>
            )}
          </div>
        </div>
        <div className="shoppingPaginationContainer">
          {pagination && (
            <TablePagination
              totalItens={pagination.total_registers_server}
              limitItens={pagination.registers_per_page}
              pageSide={1}
              onPageChange={onPageChange}
            />
          )}
        </div>
      </div>

      {/* Modal para detalhes do pagamento */}
      <Modal isOpen={modalOrderDetails} toggle={toggleModalOrderDetails}>
        <ModalHeader toggle={toggleModalOrderDetails}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Icon
              icon="poli-icon pi-order-fill"
              size={40}
              color="var(--danger)"
            />
            <h5
              style={{
                fontSize: '32px',
                fontWeight: '700',
                color: `var(--danger)`,
                marginLeft: '10px',
              }}
            >
              Pedido #{orderDetail && orderDetail.id}
            </h5>
          </div>
        </ModalHeader>
        <ModalBody>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <p>{orderDetail && orderDetail.contact.name}</p>
            <p>{orderDetail && formatDate(orderDetail.created_at)}</p>
          </div>
          <div>
            <h5>
              {orderDetail &&
              ordersStatusList.length &&
              (statusTypeId = ordersStatusList.find(
                (status) => status.id === orderDetail.status
              )) ? (
                <span style={{ color: `var(${statusTypeId.color})` }}>
                  {statusTypeId.description}
                </span>
              ) : (
                ''
              )}
            </h5>
          </div>
          <Table hover size="sm">
            <thead>
              <tr>Detalhes:</tr>
            </thead>
            <tbody>
              <tr
                style={{
                  borderTop: '1px solid #F7F7F7',
                  borderBottom: '1px solid #F7F7F7',
                  padding: '.2rem 0',
                }}
              >
                {orderDetail?.description}
              </tr>
              <tr>
                <td colSpan="2"></td>
                <td>
                  Total: {orderDetail && priceMask(orderDetail.total + '')}
                </td>
              </tr>
            </tbody>
          </Table>
        </ModalBody>
      </Modal>
      {/* Modal de confirmação de exclusão */}
      <ModalConfirmDelete
        isOpen={modalOrderDelete}
        toggle={toggleModalOrderDelete}
        onClick={() => {
          deleteOrder(modalConfirmDeleteData.id);
          toggleModalOrderDelete();
        }}
        title={'Pagamento #' + modalConfirmDeleteData.id}
        subtitle="esse pagamento"
        warning="Pagamentos cancelados não podem ser recuperados"
      />
      {/* Modal de resposta da requisição*/}
      {orderDetailRequestStatus === false && (
        <ModalStatus
          type="danger"
          isOpen={modalStatus}
          toggle={toggleModalStatus}
          title="Algo deu errado!"
          subtitle="Infelizmente tivemos problemas para carregar esse pagamento"
        />
      )}
      {orderDeleteRequestStatus === false && (
        <ModalStatus
          type="danger"
          isOpen={modalStatus}
          toggle={toggleModalStatus}
          title="Algo deu errado!"
          subtitle="Infelizmente tivemos problemas para cancelar esse pagamento"
        />
      )}
      {orderDeleteRequestStatus && (
        <ModalStatus
          type="success"
          isOpen={modalStatus}
          toggle={toggleModalStatus}
          title="Pagamento cancelado"
          subtitle="O pagamento foi removido"
        />
      )}
    </>
  );
}

const mapStateToProps = (state, owProps) => ({
  orders: state.shoppingOrdersList?.orders?.list,
  pagination: state.shoppingOrdersList?.orders?.pagination,
  isLoadingOrders: state.shoppingOrdersList?.loadingGetOrders,
  isLoadingOrderDetail: state.shoppingOrdersList?.loadingGetOrderDetail,
  isLoadingDeleteOrder: state.shoppingOrdersList?.loadingDeleteOrder,
  error: state.shoppingOrdersList?.error,
  orderDetail: state.shoppingOrdersList?.order_detail,

  //order status list and payment method
  isLoadingOrdersStatus: state.shoppingOrdersList.loadingGetOrdersStatus,
  ordersStatusList: state.shoppingOrdersList.ordersStatus,
  isLoadingPaymentMethods: state.shoppingOrdersList.loadingGetPaymentMethods,
  paymentMethodsList: state.shoppingOrdersList.paymentMethods,

  //order request status
  ordersRequestStatus: state.shoppingOrdersList?.ordersRequestStatus,
  orderDetailRequestStatus: state.shoppingOrdersList?.orderDetailRequestStatus,
  orderDeleteRequestStatus: state.shoppingOrdersList?.orderDeleteRequestStatus,

  //remove mercadopago if environment is production
  //feature_polipay_mercadopago is false in production
  //in production enable mercadopago if customer is Poli
  paymentGateways:
    !!poliConfig.feature_polipay_mercadopago ||
    Number(state.general?.current_customer_id) ===
      Number(poliConfig.feature_enable_customer_tester_id)
      ? state.shoppingOrdersList.paymentGateways
      : state.shoppingOrdersList.paymentGateways?.filter(
          (gateway) => gateway.name !== 'mercadopago'
        ),

  props: owProps,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(ShoppingOrdersList, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(OrderList);
