import { ActionTypes } from './actions';

import { toast } from 'react-toastify';

export default (
  state = {
    departments: [],
    loadingGetDepartments: false,
    lastError: null,
    departmentsStatus: null,
  },
  action
) => {
  switch (action.type) {
    case ActionTypes.GET_DEPARTMENTS: {
      return {
        ...state,
        ...{
          loadingGetDepartments: true,
        },
      };
    }
    case ActionTypes.GET_DEPARTMENTS_SUCCESS: {
      return {
        ...state,
        ...{
          departments: action.data,
          loadingGetDepartments: false,
          departmentsStatus: true,
        },
      };
    }
    case ActionTypes.GET_DEPARTMENTS_FAILURE: {
      return {
        ...state,
        ...{
          departments: [],
          error: action.error,
          loadingGetDepartments: false,
          departmentsStatus: false,
        },
      };
    }

    // Usado para escutar mudanças no status do departamento
    case ActionTypes.UPDATE_STATUS_DEPARTMENT: {
      return {
        ...state,
        loadingGetDepartments: true,
      };
    }

    case ActionTypes.UPDATE_STATUS_DEPARTMENT_SUCCESS: {
      toast.success('Status do departamento atualizado com sucesso!');
      return {
        ...state,
      };
    }
    case ActionTypes.UPDATE_STATUS_DEPARTMENT_FAILURE: {
      toast.error('Erro ao atualizar status do departamento!');
      return {
        ...state,
      };
    }

    // Usado para escutar a criação de um departamento
    case ActionTypes.CREATE_DEPARTMENT: {
      return {
        ...state,
        loadingGetDepartments: true,
      };
    }

    case ActionTypes.CREATE_DEPARTMENT_SUCCESS: {
      toast.success('Departamento criado com sucesso!');
      return {
        ...state,
      };
    }
    case ActionTypes.CREATE_DEPARTMENT_FAILURE: {
      toast.error(
        'Erro ao criar departamento! Confira as informações e tente novamente.'
      );
      return {
        ...state,
      };
    }

    // Usado para escutar mudanças feitas em um departamento
    case ActionTypes.UPDATE_DEPARTMENT: {
      return {
        ...state,
        loadingGetDepartments: true,
      };
    }
    case ActionTypes.UPDATE_DEPARTMENT_SUCCESS: {
      toast.success('Departamento atualizado com sucesso!');
      return {
        ...state,
      };
    }
    case ActionTypes.UPDATE_DEPARTMENT_FAILURE: {
      toast.error(
        'Erro ao atualizar dados do departamento! Confira as informações e tente novamente.'
      );
      return {
        ...state,
      };
    }

    // Usado para escutar quando um usuário é excluído
    case ActionTypes.DELETE_DEPARTMENT: {
      return {
        ...state,
        loadingGetDepartments: true,
      };
    }
    case ActionTypes.DELETE_DEPARTMENT_SUCCESS: {
      toast.success('Departamento excluído com sucesso!');
      return {
        ...state,
      };
    }
    case ActionTypes.DELETE_DEPARTMENT_FAILURE: {
      toast.error('Erro ao excluir departamento!');

      return {
        ...state,
      };
    }

    // Usado para escutar entradas de usuários
    case ActionTypes.SEARCH_USERS_BY_CUSTOMER_ID_SUCCESS: {
      return {
        ...state,
        users: action.data.data,
      };
    }
    case ActionTypes.SEARCH_USERS_BY_CUSTOMER_ID_FAILURE: {
      return {
        ...state,
      };
    }

    case ActionTypes.OPEN_CHOOSE_ICON_MODAL: {
      return {
        ...state,
        isChooseIconModalOpen: true,
      };
    }
    case ActionTypes.CLOSE_CHOOSE_ICON_MODAL: {
      return {
        ...state,
        isChooseIconModalOpen: false,
      };
    }

    default: {
      return state;
    }
  }
};
