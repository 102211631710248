import Search from '../../../common/search';

import Badges from './Badges';

function Filter({ screen, onSearch }) {
  return (
    <div className="filtersContainer">
      <div className="searchGroupFilters">
        <Search
          placeholder="Pesquisar chat..."
          whoSendAction="chat"
          screen={screen}
          onSearch={onSearch}
        />
      </div>
      <Badges />
    </div>
  );
}

export default Filter;
