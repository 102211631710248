import React from 'react';
import { Icon } from '@polichat/flamboyant';
import Animation from '../../common/animation';
import { useFlags } from 'flagsmith/react';

function ChatReadStatus(props) {
  const flag = useFlags(['count_messages_no_reads']);
  const isChatRead = props.isChatRead;
  const lastMessage = props.lastMessage;

  // Se o chat já estiver lido
  if (isChatRead) {
    return <></>;
  }

  // Se o chat não foi lido e está com o contador de novas mensagens = 0
  // Acontece quando o chat é buscado no banco (Fetch Unique Chat ou Fetch More Chats)
  // Quando o usuario aperta F5 cairá aqui também

  // Lógica de aparecer a cartinha dps do F5
  if (
    !isChatRead &&
    props.newMessagesCounter === 0 &&
    props.countMessages === 0 &&
    lastMessage &&
    lastMessage.id &&
    !flag.count_messages_no_reads?.enabled
  ) {
    return (
      <div
        className="statusMessage notReadContainer"
        id="messageNotReadStart"
        title="Não lido"
      >
        <Icon icon="poli-icon pi-dont-read-fill" />
      </div>
    );
  }

  // Se o chat não foi lido e tem o contador > 0
  // antigo if (!isChatRead && props.newMessagesCounter > 0) || (!isChatRead && props.countMessages > 0)
  if (!isChatRead && (props.countMessages > 0 || props.newMessagesCounter)) {
    let countMessagesFromBack = props?.countMessages ?? 0; //se não exitir recebe zero
    let newMessagesCounterFromStore = props?.newMessagesCounter ?? 0;
    let count = countMessagesFromBack + newMessagesCounterFromStore;

    return (
      <div
        className="statusMessage notReadContainer"
        id="messageNotReadCounter"
        title="Não lido"
      >
        <div className="newMessagesCounterList">
          {count === 0 ? '' : <span>{count > 9 ? '9+' : count}</span>}
        </div>
      </div>
    );
  }

  return <></>;
}

export default ChatReadStatus;
