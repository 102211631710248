import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem;
  padding: 0 1rem;
`;

export const StatusSection = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  max-width: 600px;
`;

export const ItemAprroved = styled.div`
  display: flex;
  align-items: center;

  margin-bottom: 2rem;
  & span {
    margin-left: 0.75rem;
    font-size: 1.2rem;
  }
`;

export const IconAprroved = styled.div`
  border: 2px solid #20EFE5;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;

  & i {
    font-size: 2rem !important;
    font-weight: bold;
  }
`;

export const IconLoading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  & i {
    font-size: 2rem !important;
  }
`;

export const InfoTextContainer = styled.div``;

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;

  button {
    margin: 10px 0;
  }
`;
