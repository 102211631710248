import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { validationSchema } from './validation';
import { Step1FormValues } from './types';

export function useStep1Form() {
  const form = useForm<Step1FormValues>({
    defaultValues: {
      channel_id: '',
      business_manager_id: '',
    },
    resolver: yupResolver(validationSchema),
  });
  // ---------------------------------------------
  // Functions
  // const { watch } = form;
  // ---------------------------------------------
  // Effects
  // ---------------------------------------------
  // Transformations
  // ---------------------------------------------
  // API

  return {
    form,
  };
}
