import { Table as TableFlamboyant, Icon, UncontrolledTooltip } from '@polichat/flamboyant';
import { DEFAULT_ORDINATION_CONFIG, ORDERS_TABLE_TOOLTIP, TableProps } from './config';
import { Button } from 'reactstrap';
import { Loading } from 'src/pages/report/shared/components/Loading';
import { ContainerTale, TrResult } from 'src/pages/report/shared/components/Table/styles';
import { getNewOrdinationProps, getOrdinationIcon } from 'src/pages/report/shared/components/Pagination';

export function Table({ filters, records, resume, loading, ordinationProps, onOrdinationChange }: TableProps) {

  function changeOrdinationProps(event: any) {
    const orderBy = event.target.getAttribute('data-order-by');
    const newOrdinationProps = getNewOrdinationProps(orderBy, ordinationProps, DEFAULT_ORDINATION_CONFIG);
  
    onOrdinationChange(newOrdinationProps);
  }

  return (
    <ContainerTale>
      <TableFlamboyant striped responsive hover>
        <thead>
          <tr>
            {ORDERS_TABLE_TOOLTIP.map((title) => (
              <th key={title.id}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}>
                  {
                    (ordinationProps.order_by === title.id && ordinationProps.order !== 'none')
                    && <Icon
                        icon={`poli-icon ${getOrdinationIcon(ordinationProps.order)}`}
                        size={15}
                        cursor="pointer"
                        color="var(--principal-icons-color)"
                      />
                  } 
                  <Button
                    data-order-by={title.id}
                    style={{ textDecorationColor: 'none', border: 'none', color: 'var(--textDefault)' }}
                    color="link"
                    size='sm'
                    onClick={changeOrdinationProps}
                    disabled={!!loading || (records?.length ?? 0) < 1}
                  >
                    {title.title}
                  </Button>
                  {
                    title.text &&
                    <>
                      <div id={'tooltip' + title.id}>
                        <Icon
                          icon="poli-icon pi-question-fill"
                          size={20}
                          color="var(--principal-icons-color)"
                        />
                      </div>
                      <UncontrolledTooltip
                        placement="right"
                        target={'tooltip' + title.id}
                        dangerouslySetInnerHTML={{ __html: title.text }}
                        style={{ textAlign: 'left', fontSize: '14px' }}
                      />
                    </> 
                  }
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {
            loading
              ? <tr className='bg-transparent' style={{ pointerEvents: 'none' }}>
                  <td colSpan={7}>
                    <Loading></Loading>
                  </td>
                </tr>
              : records?.map((record) => (
                  <tr key={record?.operator_id}>
                    <td className='text-center'>{record?.operator_name}</td>
                    <td className='text-center'>{record?.interactions}</td>
                    <td className='text-center'>{record?.clients}</td>
                    <td className='text-center'>{record?.new_clients}</td>
                    <td className='text-center'>{record?.average_first_reply_time}</td>
                    <td className='text-center'>{record?.average_service_time}</td>
                    <td className='text-center'>{record?.number_of_ratings}</td>
                    <td className='text-center'>{record?.average_rating}</td>
                  </tr>
                ))
          }
          <TrResult key={ (resume?.total_records ?? 0) + 1 }>
            <td className='text-center color-gray'>Total</td>
            <td className='text-center color-gray'>
              {resume?.total_interactions ?? 0}
              <Icon
                id={'tooltip-total-interactions'}
                icon="poli-icon pi-question-fill"
                size={20}
                color="var(--principal-icons-color)"
                position={'relative'}
                top={'3px'}
                left={'4px'}
                cursor='pointer'
              />
              <UncontrolledTooltip
                placement="right"
                target={'tooltip-total-interactions'}
                style={{ textAlign: 'left', fontSize: '14px' }}
              >
                É a soma de interações que foram realizadas pelos usuários. Caso um cliente fale com mais de uma pessoa, ele será contabilizado mais de uma vez.
              </UncontrolledTooltip>
            </td>
            <td className='text-center color-gray'>
              {resume?.total_clients ?? 0}
              <Icon
                id={'tooltip-total-clients'}
                icon="poli-icon pi-question-fill"
                size={20}
                color="var(--principal-icons-color)"
                position={'relative'}
                top={'3px'}
                left={'4px'}
                cursor='pointer'
              />
              <UncontrolledTooltip
                placement="right"
                target={'tooltip-total-clients'}
                style={{ textAlign: 'left', fontSize: '14px' }}
              >
                Representa o total de clientes que a empresa conversou. É <strong>diferente da soma das linhas</strong>, pois se um cliente falar com mais de um operador, neste valor ele será contabilizado apenas uma vez.
              </UncontrolledTooltip>
            </td>
            <td className='text-center color-gray'>{resume?.total_new_clients ?? 0}</td>
            <td className='text-center color-gray'>{resume?.average_first_reply_time ?? 0}</td>
            <td className='text-center color-gray'>{resume?.average_service_time ?? 0}</td>
            <td className='text-center color-gray'>{resume?.number_of_ratings ?? 0}</td>
            <td className='text-center color-gray'>{resume?.average_rating ?? 0}</td>
          </TrResult>
        </tbody>
      </TableFlamboyant>
    </ContainerTale>
  )
}
