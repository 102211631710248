import { CustomInput, FormGroup } from 'reactstrap';
import { Filter } from '../../ListMessagesBuilder.styles';

interface FilterTypeProps {
  handleChangeTypeFilter(value: any): void;
  typeValue: string;
}

export function FilterType(props: FilterTypeProps) {
  return (
    <Filter>
      <FormGroup>
        <CustomInput
          type="select"
          id="filter-type"
          name="customSelectType"
          value={props.typeValue}
          onChange={props.handleChangeTypeFilter}
        >
          <optgroup label="Filtrar por tipo">
            <option value="all">Todos os Tipos</option>
            <option value="template">Templates</option>
            <option value="quick_message">Mensagens Rápidas</option>
            <option value="list">Mensages de Lista</option>
            <option value="buttons">Botões Interativos</option>
          </optgroup>
        </CustomInput>
      </FormGroup>
    </Filter>
  );
}
