import axios from 'axios';
import env from '../env';
import poliApiGatewayRequest from 'src/utils/poliApiGatewayRequest';
import { InfoAllByWabaId } from '../types/InfoAllByWabaId';

const getInfoAllByWabaId = async (
  waba_id: string,
  requestFromWabaService: boolean
): Promise<InfoAllByWabaId | undefined> => {
  try {
    var response;
    if(requestFromWabaService){
      const url = `/waba/info/${waba_id}`;
      response = await poliApiGatewayRequest.get(url);
    } else {
      response = await axios.get(
        `https://graph.facebook.com/v14.0/${waba_id}?fields=on_behalf_of_business_info,id,name,message_template_namespace,phone_numbers&access_token=${env.SYSTEM_USER_ACCESS_TOKEN}`
      );
    }

    console.log("response.data", response.data)
    const data: InfoAllByWabaId | undefined = response?.data;
    if (!data) return;
    return data;
  } catch (error) {
    console.error(error);
    return;
  }
};

export { getInfoAllByWabaId };
