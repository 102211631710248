import React from 'react';

import { Container, CardNumber, Content } from './InfoCard.styles';

const InfoCard = ({ number, height, children }) => (
  <Container height={height}>
    <CardNumber>{number}</CardNumber>
    <Content>{children}</Content>
  </Container>
);

export default InfoCard;
