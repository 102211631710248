import { useState } from 'react';

import {
  Button,
  Icon,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  // @ts-ignore
} from '@polichat/flamboyant';

import { useHistory } from 'react-router-dom';

import './styles.css';

interface MigrateButtonProps {
  id: String;
  processStatus?: undefined | 'started' | 'finished';
  channelPhone: String;
  channelName: String;
  validatedPlan: boolean;
}

const MigrateButton = ({
  id,
  processStatus,
  channelName,
  channelPhone,
  validatedPlan,

}: MigrateButtonProps) => {

  const [isModalConfirmationOpen, setOpenModalConfirmation] =
    useState<Boolean>(false);

  const history = useHistory();

  const buttonText = processStatus
    ? processStatus === 'started'
      ? 'Status da migração WABA'
      : ''
    : 'Migrar para Waba';

  const handleClick = () => {
    if (processStatus && processStatus === 'started') {
      return history.push({
        pathname: `/tools/channels/edit/${id}`,
        state: {
          data: {
            openCertificationScreen: true,
          },
        },
      });
    }
    setOpenModalConfirmation(true);
  };

  return (
    <>
      <Button
        gradient={
          processStatus && processStatus === 'started' ? 'attention' : 'primary'
        }
        className="migrate-button-container"
        onClick={handleClick}
      >
        <Icon
          icon="poli-icon pi-whatsapp-business-line"
          color="#fff"
          size="20"
        />
        {buttonText}
      </Button>

      <Modal
        isOpen={isModalConfirmationOpen}
        toggle={() => setOpenModalConfirmation(!isModalConfirmationOpen)}
      >
        <ModalHeader toggle={() => setOpenModalConfirmation(false)}>
          Migração para o Waba
        </ModalHeader>
        <ModalBody>Você tem certeza que deseja continuar?</ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              history.push({
                pathname: '/tools/channels/add/waba',
                state: {
                  validated_can_add_channel: validatedPlan,
                  data: {
                    id,
                    channelPhone,
                    channelName,
                    isMigrationToWaba: true,
                  },
                },
              });
            }}
          >
            Quero Prosseguir
          </Button>{' '}
          <Button
            color="secondary"
            onClick={() => setOpenModalConfirmation(false)}
          >
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default MigrateButton;
