import { Input } from '@polichat/flamboyant';
import { useFlags } from 'flagsmith/react';
import { FieldError } from 'react-hook-form';

interface DateInputInterface {
  onChange: (...event: any[]) => void
  value: string
  idInput: string
  error?: boolean
  style?: object
}


export function DateInputComponent({ idInput, onChange, value, error, style }: DateInputInterface) {
  const flags = useFlags(['reports_limit_search_per_mounth']);
  const flagValue = JSON.parse(flags.reports_limit_search_per_mounth.value as string);

  const limitMonth = flagValue?.limitMounth ? flagValue.limitMounth : 1;

  const today = new Date();
  
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);
  
  const lastMonth = new Date(today);
  lastMonth.setMonth(today.getMonth() - limitMonth);

  const formatDate = (date: Date): string => {
    const day = ("0" + date.getDate()).slice(-2);
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  }

  return (
    <Input
        type="date"
        id={idInput}
        style={{ marginTop: '0.375rem', ...style }}
        value={value}
        onChange={onChange}
        invalid={error}
        min={formatDate(lastMonth)}
        max={formatDate(yesterday)}
    />
  );
}
