export enum QuickTypes {
  QUICK = 'quick',
  TEMPLATE = 'template',
  LIST_OPTION = 'list',
  BUTTONS = 'buttons',
}

export enum QuickTypesText {
  quick = 'Mensagem rápida',
  template = 'Template',
  list = 'Lista de opção',
  buttons = 'Botão interativo',
}

// @url https://www.toptal.com/developers/keycode
export enum KeyboardCodes {
  ENTER = 'Enter',
  ESCAPE = 'Escape',
  SPACE = '',
  BACKSPACE = 'Backspace',
  DELETE = 'Delete',
  ARROW_UP = 'ArrowUp',
  ARROW_DOWN = 'ArrowDown',
  HASH_TAG = '#',
  CONTROL = 'Control',
}

export enum CSSHeightValue {
  QUICK_MESSAGE_ITEM_HEIGHT = 90,
  QUICK_MESSAGE_LIST_HEIGHT = 200,
}

export enum MessageVariables {
  CONTACT_NAME = '[contact_name]',
  CONTACT_FIRST_NAME = '[contact_first_name]',
  CONTACT_EMAIL = '[contact_email]',
  CONTACT_PHONE = '[contact_phone]',
  OPERATOR_NAME = '[operator_name]',
  DEPARTMENT_NAME = '[department_name]',
  DATE_CURRENT = '[date_current]',
  TIME_CURRENT = '[time_current]',
  PROTOCOL_NUMBER = '[protocol_number]',
  CUSTOM = '[custom]',
}
