import styled from 'styled-components';

import { Modal, ModalBody, ModalHeader } from '@polichat/flamboyant';
import { Input } from 'reactstrap';

/**
 * Modal Custom
 */
export const CustomModal = styled(Modal)`
  & .modal-content {
    padding: 0;
  }
`;

export const CustomModalClose = styled(ModalHeader)`
  padding: 0;
  .close {
    margin-top: -40px;
  }

  & button.close {
    top: 35px;
    left: -20px;
  }
`;

export const CustomModalHeader = styled(ModalBody)`
  margin: 1.5rem;
  margin-bottom: 0;
  padding: 0;

  & .modal-title {
    display: block;
    margin-bottom: 1rem;

    span {
      font-size: 24px;
    }
  }
`;

export const CustomModalBody = styled(ModalBody)`
  margin: 1.5rem;
  margin-top: 0;
  padding: 0;

  & .formBody {
    margin-top: 1rem;

    p {
      font-size: 16px;
      margin: 0;
      margin-top: 0.2rem;
    }
  }

  & .formBtn {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    margin-top: 0.2rem;

    button {
      flex: 1;
      margin: 0;
      position: relative;
    }

    .btn-block + .btn-block {
      margin: 0;
    }
  }
`;

/* END - Modal Custom */

/**
 * Formulario
 */
export const LabelForm = styled.label`
  font-size: 14px;
  margin: 0;

  color: var(--received-color);
`;
/* END - Formulario */

/**
 * Status do pagamento
 */
export const CustomModalStatus = styled(ModalBody)`
  margin: 0rem;
  padding: 0;
  background-color: var(--secondary-background-color);
`;

export const StatusView = styled.div`
  min-width: 30rem;
  margin: 0.5rem 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & .content {
    flex: 1;
    & h5 {
      margin: 0;
      margin-top: 0.5rem;
      font-size: 1rem;
      font-weight: 600;
    }
  }

  .box{
    display: block;
    width: 100%;
  }
  
  span {
    position: relative;
    padding-right: 2rem;
  }

  button {
    position: relative;
  }
`;
/* end - Status do pagamento */

/**
 * Tabs
 */
export const CustomModalTab = styled(ModalBody)`
  display: flex;
  margin: 0rem;
  padding: 0;
  align-items: center;

  & .iconeContainer {
    width: 1.5rem;
    cursor: pointer;
  }
`;
export const Tabs = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  max-height: 3em;
  border-bottom: 1px solid var(--border-color);
  justify-content: start;
  overflow-x: hidden;
`;

export const TabsContainer = styled.div`
  padding: 0.6rem 0;
  &.active {
    border-bottom: 3px solid var(--green-polichat);
    font-weight: bold;
    & .tabItem i {
      color: #0d9da7 !important;
    }
  }
`;

export const TabItem = styled.div`
  cursor: pointer;
  display: flex;
  flex: 1;
  justify-content: start;
  align-items: center;

  & span {
    font-size: 0.8em;
    line-height: 1.2em;
    text-align: center;
    text-transform: uppercase;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    color: var(--secondary-font-color);
  }
`;
/* end - Tabs */
