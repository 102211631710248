import { useReactMediaRecorder } from 'react-media-recorder';
import { useEffect, useState, useRef, useMemo } from 'react';
import { useFlags } from 'flagsmith/react';

import AudioRecorderMimeType from '../../../../../utils/audio/AudioRecorderMimeType';

export default function useRecorder(updateBlob) {
  const flags = useFlags([
    'audio_recorder_v1',
  ]);

  const supportedMimeType = useMemo(
    () => AudioRecorderMimeType.getMimeTypeSupportedByBrowser()
  );

  const AUDIO_TYPE_DEFAULT = supportedMimeType;

  const [typeAudio, setTypeAudio] = useState(AUDIO_TYPE_DEFAULT);

  useEffect(() => {
    if (
      !supportedMimeType &&
      flags.audio_recorder_v1?.enabled &&
      flags.audio_recorder_v1?.value
    ) {
      let audioV1RecorderValues = JSON.parse(flags.audio_recorder_v1?.value);
      let audioRecorderType = audioV1RecorderValues.audioType;

      if (audioRecorderType) {
        setTypeAudio(audioRecorderType);
      }
    }
  }, [flags]);

  const { startRecording, stopRecording } = useReactMediaRecorder({
    audio: true,
    video: false,
    stopStreamsOnStop: true,
    blobPropertyBag: {
      type: typeAudio,
    },
    mediaRecorderOptions: {
      mimeType: typeAudio,
    },
    onStop: (blobUrl, blob) => {
      updateBlob(blob);
    },
  });

  const timer = useRef(null);

  const [recordTime, setRecordTime] = useState(0);
  const [isRecording, setIsRecording] = useState(false);

  useEffect(() => {
    if (isRecording) {
      _startTimer();
    } else {
      _stopTimer();
    }
  }, [isRecording]);

  const start = async function () {
    try {
      startRecording();
      setIsRecording(true);
    } catch (error) {
      console.error('Error starting recorder');
      console.error(error);
      setIsRecording(false);
    }
  };

  const stop = async function () {
    stopRecording();
    setIsRecording(false);
  };

  const _startTimer = () => {
    timer.current = setInterval(() => {
      setRecordTime((time) => time + 1);
    }, 1000);
  };

  const _stopTimer = () => {
    clearInterval(timer.current);
    setRecordTime(0);
  };

  const duration = () => {
    let hour = 0;
    let minute = 0;
    let second = recordTime;

    if (second > 59) {
      minute = Math.floor(second / 60);
      second = second - minute * 60;
    }

    if (minute > 59) {
      hour = Math.floor(minute / 60);
      minute = minute - hour * 60;
    }

    let show_hour = false;
    if (hour > 0) {
      show_hour = true;
    }

    second = (second < 10 ? '0' : '') + second;
    minute = (minute < 10 ? '0' : '') + minute;
    hour = (hour < 10 ? '0' : '') + hour;
    return (show_hour ? hour + ':' : '') + minute + ':' + second;
  };

  const recorderOutput = {
    start,
    stop,
    duration,
  };

  return recorderOutput;
}
