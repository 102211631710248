import axios from 'axios';
import poliConfig from 'src/utils/poliConfig';

export const laravelAxios = axios.create({
  baseURL: poliConfig.poliAppLaravelUrl,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});
