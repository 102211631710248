import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
export interface CaptionFormValues {
  text: string;
}

const validationSchema = Yup.object({
  text: Yup.string().max(20, 'Máximo 20 caracteres').optional().nullable(),
});

export function useCaptionForm() {
  const form = useForm({
    defaultValues: {
      captions: [{caption: ''}],
    },
    // resolver: yupResolver(validationSchema),
  });

  return { form };
}
