import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  align-items: end;
  justify-items: center;

  & img {
    display: block;
    width: 30%;
  }

  & h2 {
    margin-top: 1.2rem;
  }

  & button {
    margin-top: 2rem;
    width: 20%;
  }

  @media (max-width: 800px) {
    margin-top: 1rem;
    & img {
      width: 100%;
    }
    & h2 {
      text-align: center;
    }
    & button {
      width: 100%;
    }
  }
`;
