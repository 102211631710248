import { useState, useEffect, useRef } from 'react';
import vmsg from 'vmsg';
import { Button, Icon } from '@polichat/flamboyant';

import ListenAudio from '../../ListenAudio';

import Animation from '../../../../../../common/animation';
import { toast } from 'react-toastify';

const recorder = new vmsg.Recorder({
  wasmURL: 'https://unpkg.com/vmsg@0.3.0/vmsg.wasm',
});

function RecorderMP3WhitVMSG({ recording, onChangeRecording, onSend }) {
  const [isRecording, setIsRecording] = useState(false);

  const [blob, setBlob] = useState(null);
  const [base64File, setBase64file] = useState(null);

  const timer = useRef(null);
  const [recordTime, setRecordTime] = useState(0);

  const _startTimer = () => {
    timer.current = setInterval(() => {
      setRecordTime((time) => time + 1);
    }, 1000);
  };

  const _stopTimer = () => {
    clearInterval(timer.current);
    setRecordTime(0);
  };

  const duration = () => {
    let hour = 0;
    let minute = 0;
    let second = recordTime;

    if (second > 59) {
      minute = Math.floor(second / 60);
      second = second - minute * 60;
    }

    if (minute > 59) {
      hour = Math.floor(minute / 60);
      minute = minute - hour * 60;
    }

    let show_hour = false;
    if (hour > 0) {
      show_hour = true;
    }

    second = (second < 10 ? '0' : '') + second;
    minute = (minute < 10 ? '0' : '') + minute;
    hour = (hour < 10 ? '0' : '') + hour;
    return (show_hour ? hour + ':' : '') + minute + ':' + second;
  };

  useEffect(() => {
    if (isRecording) {
      _startTimer();
    } else {
      _stopTimer();
    }
  }, [isRecording]);
  const getBase64 = async (url) => {
    const response = await fetch(url);
    const blob = await response.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  };

  async function startRecorder() {
    try {
      setIsRecording(true);
      await recorder.initAudio();
      await recorder.initWorker();
      recorder.startRecording();
    } catch (e) {
      console.error(e);
      setIsRecording(false);
      toast.error(
        'Estamos com problemas para abrir o gravador. Tente novamente ou entre em contato com nosso suporte!'
      );
      deleteRecorder();
    }
  }

  async function stopRecorder() {
    try {
      const blob = await recorder.stopRecording();
      setBlob(blob);
      // TODO: precisa dessa url ??
      const url = URL.createObjectURL(blob);
      const base64 = await getBase64(url);

      setBase64file(base64);
      setIsRecording(false);
    } catch (e) {
      console.error(e);
    }
  }

  async function deleteRecorder() {
    if (isRecording) {
      stopRecorder();
      setBase64file(null);
    }

    onChangeRecording(false);
    showHiddenActions();
  }

  function showHiddenActions() {
    // Mostra a section de ações que foi ocultada ao abrir o gravador de audio
    if (document.querySelector('.groupActions')) {
      document.querySelector('.groupActions').classList.remove('hidden');
    }
    // Mostrando o container de Emoji que foi ocultado ao clicar em gravar audio, caso eles estivesse abertos
    if (document.querySelector('.emojiContainer')) {
      document.querySelector('.emojiContainer').classList.remove('hidden');
    }

    // Mostrando o container de Mensagens Rápidas que foi ocultado ao clicar em gravar audio, caso elas estivesse abertos
    if (document.querySelector('.quickMessagesContainer')) {
      document
        .querySelector('.quickMessagesContainer')
        .classList.remove('hidden');
    }

    setBlob(null);
    setBase64file(null);
  }

  useEffect(() => {
    if (recording) {
      startRecorder();
    }
  }, [recording]);

  return (
    <>
      {recording && (
        <>
          <div className="recordAudioBox" id="recordAudioBox" tabIndex="0">
            {isRecording ? (
              <section className="groupAudioRecorder">
                <div className="groupAudioRecorderHeader">
                  <Icon icon="poli-icon pi-microphone-line" /> Gravação de áudio
                </div>
                <div className="groupAudioRecorderBody">
                  <>
                    <Animation icon="microphone" size="minorLittleTiny" />{' '}
                    Gravando...
                    <span className="countRecordAudioBox">
                      <Icon icon="poli-icon pi-timer-fill" />
                      <span id="countRecordAudio">{duration()}</span>
                    </span>
                  </>
                </div>
              </section>
            ) : (
              <section className="groupAudioRecorder">
                <ListenAudio blob={blob} />
              </section>
            )}

            <section className="groupActionsAudio">
              <div className="groupActionsRight">
                {/* Lixeira */}
                <Button
                  color="link"
                  onClick={() => {
                    deleteRecorder();
                  }}
                >
                  <Icon icon={'poli-icon pi-delete-fill'} size={20} />
                </Button>

                {/* Parar Gravação Audio / Enviar Áudio */}
                {isRecording ? (
                  <Button
                    color="link"
                    onClick={() => {
                      stopRecorder();
                      setIsRecording(false);
                    }}
                    className="buttonHandleAudio"
                  >
                    <Icon icon={'poli-icon pi-video-stop-fill'} size={20} />
                  </Button>
                ) : (
                  <Button
                    color="link"
                    id="sendAudioMessageButton"
                    onClick={() => {
                      onSend(base64File, blob);
                      onChangeRecording(false);
                      showHiddenActions();
                    }}
                    title="Enviar"
                  >
                    <Icon
                      icon={'poli-icon pi-set-fill'}
                      color="var(--icon-color-font)"
                      size={20}
                    />
                  </Button>
                )}
              </div>
            </section>
          </div>
          <div className="coverRecording"></div>
        </>
      )}
    </>
  );
}

export default RecorderMP3WhitVMSG;
