import React, { useState } from 'react';
import FilterModalTemplate from './FilterModalTemplate';
import Animation from '../../../../common/animation';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ChatActions from '../../../../../store/modules/chat/actions';
import { convertActiveTabInScreen } from '../../../../../store/modules/chat/functions';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from '@polichat/flamboyant';

function ChannelsModal({
  channels,
  isChannelMenuOpen,
  toggleFilterMenu,
  activeFilters,
  submitFilter,
  loading,
  clearFilter,
  currentTabNavigation
}) {
  const filter_type_name = 'channels';
  const filter_title = 'Filtrar por Canal';
  const filter_icon = 'poli-icon pi-folder-line';
  const currentScreen = convertActiveTabInScreen(currentTabNavigation);

  let content;
  let channels_select = [];
  let enabled = true;

  function verifyCurrentFilter () {
    if (!activeFilters.length) {
      setFilter('')
    }
  }


  const findCurrentChannelFilter = () => {
    let active_filter = '';

    if (activeFilters.length) {
      activeFilters.forEach((filter) => {
        if (filter.type === filter_type_name) {
          active_filter = filter.channel_id;
        }
      });
    }

    return active_filter;
  };

  const [filter, setFilter] = useState(findCurrentChannelFilter());
  const selectedChannelFilterName = channels?.map((channel) => {
    // tem que ser apenas dois iguais
    if (channel.id == filter) {
      return channel.name;
    }
  });

  const findName = (id) => {
    verifyCurrentFilter();
    let channel = channels.find((channel) => channel.id === id);

    if (channel) {
      if (channel.name) {
        return channel.name;
      } else {
        return '';
      }
    } else {
      return '';
    }
  };

  const onSubmitEvent = () => {
    let payload = Object.assign({}, activeFilters);

    if (filter) {
      let value = parseInt(filter);
      let name = findName(value);

      payload.channel = [
        {
          name: name,
          value: value,
        },
      ];

      submitFilter(payload, currentScreen);
    } else {
      payload.channel = [];
      clearFilter(payload);
    }

    toggleFilterMenu(filter_type_name);
  };

  const sortByName = (a, b) => {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  };

  if (channels && channels.length) {
    channels_select = channels.sort(sortByName).map((channel, index) => {
      return (
        <DropdownItem
          key={`${channel.id}+${index}`}
          value={channel.id}
          className="dropItem-filters"
          onClick={(e) => setFilter(e.target.value)}
        >
          {channel.name}
        </DropdownItem>
      );
    });

    content = (
      <UncontrolledDropdown className="dropDown-filters">
        <DropdownToggle
          color="neutral"
          caret
          id="selectchannels"
          name="selectchannels"
          className="total dropDownToggle-filters"
        >
          <span>
            {filter != '' && selectedChannelFilterName
              ? selectedChannelFilterName
              : 'Selecione um filtro'}
          </span>
        </DropdownToggle>
        <DropdownMenu className="dropDownMenu-filters">
          {channels_select}
        </DropdownMenu>
      </UncontrolledDropdown>
    );

    enabled = true;
  } else {
    enabled = false;
    content = <Animation icon="loading" size="tiny" />;
  }

  return (
    <FilterModalTemplate
      icon={filter_icon}
      title={filter_title}
      content={content}
      isOpen={isChannelMenuOpen}
      toggleEvent={() => toggleFilterMenu(filter_type_name)}
      onSubmit={onSubmitEvent}
      loading={loading}
      disabled={!enabled}
    />
  );
}

const mapStateToProps = (state) => ({
  channels: state.chat?.config?.user?.channels,
  isChannelMenuOpen: state.chat?.filters?.view?.isChannelMenuOpen,
  activeFilters: state.chat?.filters?.activeFilters,
  loading: state.chat?.filters?.loading,
  currentTabNavigation: state.chat?.config?.activeTabNavigation,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(ChatActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ChannelsModal);
