import { useCallback, useState } from 'react';

export function useSteps() {
  const [current, setCurrent] = useState(0);
  // ---------------------------------------------
  // Functions
  const next = useCallback(() => {
    setCurrent((prev) => Math.min(prev + 1, 5));
  }, []);

  const prev = useCallback(() => {
    setCurrent((prev) => Math.max(prev - 1, 0));
  }, []);
  // ---------------------------------------------
  // Effects
  // ---------------------------------------------
  // Transformations
  // ---------------------------------------------
  // API

  return {
    current,
    next,
    prev,
  };
}
