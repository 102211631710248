import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { CustomInput, Icon, Button } from '@polichat/flamboyant';

import YourBusinessImage from '../../../../../../assets/images/your-business-waba-card.jpg';
import Logo360 from '../../../../../../assets/images/360-dialog.png';

import history from '../../../../../../services/history';
import ChannelsService from '../../../../../../services/tools/channels/channels';
import ButtonDoubts from '../../common/ButtonDoubts/ButtonDoubts.component';
import InfoCard from '../../InfoCard/InfoCard.component';
import ActionGroupButtons from '../../ActionGroupButtons/ActionGroupButtons.component';

import {
  Container,
  TextInfos,
  FirstInfo,
  SecondInfo,
  ThirdInfo,
  CardsContainer,
  FourColumnsGrid,
  CheckBoxContainer,
} from './FillForm.styles';
import { handleExternalLink } from '../../../../../../utils/handleExternalLink';

const FillForm = ({ data, dataSet, currentStep }) => {
  const customer_id = useSelector((state) => state.general.current_customer_id);
  const [checked, setChecked] = useState(false);
  const [nextButton, nextButtonSet] = useState(false);

  const wlConfig = useSelector(
    // @ts-ignore
    (state) => state?.whitelabel?.config
    );

  const processClassic = data.config?.classic;

  // Actions to Actions Group Button component
  const goBack = () => history.push('/tools/channels');
  const handleNextAction = async () => {
    nextButtonSet(false);
    try {
      const step = parseInt(currentStep) + 1;
      await ChannelsService.updateStepHomologation({
        customer_id,
        channel_id: data.id,
        step,
      });
      dataSet({ ...data, homologation: step });
    } catch (error) {
      console.log(
        'Error in FillForm Component when uptadeStepHomologation. Error => ',
        error
      );
    }
  };
  const handleChangeCheckBox = ({ target: { checked } }) => {
    setChecked(checked);
    nextButtonSet(checked);
  };
  return (
    <>
      <Container>
      {!wlConfig.hide_doubts_button && <ButtonDoubts />}

        <TextInfos>
          <FirstInfo>
            <span>
              Agora o próximo passo é preencher o seguinte formulário:
            </span>
            <Button
              color="primary"
              size="sm"
              onClick={() =>
                handleExternalLink(
                  'https://hub.360dialog.com/lp/submit-waba/1QcGcbPA'
                )
              }
            >
              360 Dialog
            </Button>
          </FirstInfo>

          {processClassic ? (
            <>
              <SecondInfo>
                Este formulário irá pedir os dados de contato do responsável, o
                ID do Facebook Business do cliente e o telefone que será
                utilizado na WABA.
              </SecondInfo>
              <ThirdInfo>
                <span>
                  Para encontrar essas informações você precisa acessar a
                  páginda do:{' '}
                </span>
                <Button
                  color="primary"
                  size="sm"
                  onClick={() =>
                    handleExternalLink('https://business.facebook.com/select')
                  }
                >
                  Facebook Business
                </Button>
              </ThirdInfo>
            </>
          ) : (
            <>
              <SecondInfo>
                Este formulário irá iniciar o processo de solicitação da WABA e
                em seguida te permitirá fazer o login na sua conta do Facebook.
                Para isso, você irá ser direcionado para uma página onde irá
                criar um gerenciador de negócios (caso não tenha) e irá criar
                toda a estrutura do canal WhatsApp
              </SecondInfo>
            </>
          )}
        </TextInfos>

        <CardsContainer>
          <FourColumnsGrid columns={!processClassic && '1fr 1fr 1fr'}>
            <InfoCard number="1">
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <img src={YourBusinessImage} alt="Avatar da empresa" />
                <span style={{ fontWeight: 'bold' }}>Sua Empresa</span>
                <Icon icon="poli-icon pi-company-fill" size={30} />
              </div>
              <br />
              <span style={{ margin: '0.7rem 0' }}>
                Selecione a empresa correta para evitar transtornos.
              </span>

              <span>
                Caso não tenha uma conta de negócios do Facebook, será preciso
                fazer o registro e seguir para o próximo passo.
              </span>
            </InfoCard>

            {processClassic && (
              <InfoCard number="2">
                <span>
                  Clique na engrenagem{' '}
                  <Icon
                    icon="poli-icon pi-setting-fill"
                    size={25}
                    onClick={() =>
                      handleExternalLink(
                        'https://business.facebook.com/settings/info'
                      )
                    }
                  />{' '}
                  depois na opção informações da empresa{' '}
                  <Icon icon="poli-icon pi-company-fill" size={25} />
                </span>
                <br />
                <span>
                  Você irá encontrar seu número de identificação do Gerenciador
                  de Negócios: **************
                </span>
                <br />
                <span>Este é seu ID copie ele.</span>
              </InfoCard>
            )}

            <InfoCard number={processClassic ? '3' : '2'}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <span>
                  Preencha o formulário que você abriu com as informações.
                </span>
                <br />
                <img src={Logo360} alt="360 dialog" />
                <br />
                <span>
                  Após a submissão clique em{' '}
                  <strong>solicitações em aberto</strong> ou se preferir
                  <Button
                    size="sm"
                    color="primary"
                    style={{ marginLeft: '1rem' }}
                    onClick={() =>
                      handleExternalLink(
                        'https://hub.360dialog.com/lp/submit-waba/1QcGcbPA'
                      )
                    }
                  >
                    Clique Aqui
                  </Button>
                </span>
              </div>
            </InfoCard>

            {processClassic && (
              <InfoCard number="4">
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <span>Agora aprove a solicitação em aberto.</span>
                  <br />
                  <Button color="info">Aprovar</Button>
                  <br />
                  <span>
                    Após a submissão clique em{' '}
                    <strong>solicitações em aberto</strong> ou se preferir
                    <Button
                      size="sm"
                      color="primary"
                      style={{ marginLeft: '1rem' }}
                      onClick={() =>
                        handleExternalLink(
                          'https://business.facebook.com/settings/requests'
                        )
                      }
                    >
                      Clique Aqui
                    </Button>
                  </span>
                </div>
              </InfoCard>
            )}

            {!processClassic && (
              <InfoCard number="3">
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <span>
                    Agora clique no botão
                    <br />
                    "Generate API Key"
                  </span>
                  <br />
                  <Button
                    color="info"
                    onClick={() =>
                      handleExternalLink('https://hub.360dialog.com/')
                    }
                  >
                    Generate API Key
                  </Button>
                </div>
              </InfoCard>
            )}
          </FourColumnsGrid>
        </CardsContainer>

        <CheckBoxContainer>
          <CustomInput
            type="checkbox"
            id="exampleCustomCheckbox"
            label="Preenchi o formulário no 360 Dialog e aprovei a solicitação no Facebook Business"
            checked={checked}
            onChange={handleChangeCheckBox}
          />
        </CheckBoxContainer>

        <ActionGroupButtons
          onClickNext={handleNextAction}
          onClickBack={goBack}
          nextButtonActived={!nextButton}
        />
      </Container>
    </>
  );
};

export default FillForm;
