import { useCallback } from 'react';
import { Icon } from '@polichat/flamboyant';

import { QuickMessageSelectorOptions } from '../../types/types';
import { QuickTypes } from '../../types/constants';

import styles from './QuickItem.module.css';
export interface QuickIconProps {
  messageType: QuickMessageSelectorOptions;
}
export function QuickIcon({ messageType }: Readonly<QuickIconProps>) {
  const getQuickTypeIcon = useCallback(() => {
    switch (messageType) {
      case QuickTypes.QUICK:
        return (
          <span title="Mensagem rápida">
            <Icon icon="poli-icon pi-rocket-fill" color={'var(--primary)'} />
          </span>
        );
      case QuickTypes.TEMPLATE:
        return (
          <span title="Template">
            <Icon
              icon="poli-icon pi-whatsapp-api-fill"
              color="var(--whatsapp-color)"
            />
          </span>
        );

      case QuickTypes.LIST_OPTION:
        return (
          <span title="Lista de Opções">
            <Icon
              icon="poli-icon pi-barmenu-fill"
              color="var(--secondary-75)"
            />
          </span>
        );

      case QuickTypes.BUTTONS:
        return (
          <span title="Botões Interativos">
            <Icon
              icon="poli-icon pi-menu-mobile-fill"
              color="var(--secondary-75)"
            />
          </span>
        );

      default:
        return <></>;
    }
  }, []);
  return <span className={styles.text_name__type}>{getQuickTypeIcon()}</span>;
}
