import React from 'react';
import { useWhiteLabel } from 'src/hooks/useWhiteLabel';

function PayImage({ width = 174, height = 120 }) {
  let color1 = '#0000c8';
  let color2 = '#3e3eff';
  let color3 = '#1616ff';
  let color4 = '#0000e2';
  let color5 = '#b3b3ff';
  const whitelabel = useWhiteLabel();
  if (whitelabel.isWl) {
    if (whitelabel.cssPathWl.includes('chatshub')) {
      color1 = '#FF7038';
      color2 = '#fa8e64';
      color3 = '#ff6445';
      color4 = '#EE4B2B';
      color5 = '#ffd4c3';
    }
    if (whitelabel.cssPathWl.includes('auvo')) {
      color1 = '#6d26c2';
      color2 = '#7b3bc8';
      color3 = '#571e9b';
      color4 = '#41186a';
      color5 = '#d3bdec';
    }
    if (whitelabel.cssPathWl.includes('nectarcrm')) {
      color1 = '#ffd55b';
      color2 = '#ffe086';
      color3 = '#f3c641';
      color4 = '#fec10d';
      color5 = '#ffeaae';
    }
  }
  return (
    <svg
      width={width}
      height={height}
      className='img-fluid'
      viewBox="0 0 174 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.5"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M143.458 108C147.021 99.9765 149 91.0944 149 81.75C149 45.9896 120.01 17 84.25 17C48.4896 17 19.5 45.9896 19.5 81.75C19.5 91.0944 21.4794 99.9765 25.0419 108H143.458Z"
        fill={`${color5}`}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M83.75 32.0625C93.9098 32.0625 103.481 35.9843 110.666 43.099C117.852 50.2137 121.813 59.6903 121.813 69.75C121.813 79.8097 117.852 89.2863 110.666 96.401C103.481 103.516 93.9245 107.438 83.75 107.438C73.5902 107.438 64.0193 103.516 56.8338 96.401C49.6484 89.2863 45.6875 79.8097 45.6875 69.75C45.6875 59.6903 49.6484 50.2137 56.8338 43.099C64.0193 35.9843 73.5755 32.0625 83.75 32.0625ZM51.1355 69.75C51.1355 87.5513 65.7715 102.043 83.75 102.043C101.728 102.043 116.364 87.5513 116.364 69.75C116.364 51.9487 101.728 37.4568 83.75 37.4568C65.7715 37.4568 51.1355 51.9487 51.1355 69.75ZM89.2719 61.5873C89.2719 58.5694 86.7982 56.1201 83.7503 56.1201C80.7023 56.1201 78.2286 58.5694 78.2286 61.5873C78.2286 64.6052 80.7023 67.0546 83.7503 67.0546C89.802 67.0546 94.7199 71.924 94.7199 77.9161C94.7199 80.3946 93.8659 82.8148 92.2904 84.7393C90.8032 86.5617 88.7418 87.8592 86.4743 88.4424V90.7459C86.4743 92.233 85.2521 93.4431 83.7503 93.4431C82.2484 93.4431 81.0262 92.233 81.0262 90.7459V88.4424C78.744 87.8592 76.6973 86.5617 75.2101 84.7393C73.6493 82.8148 72.7806 80.3946 72.7806 77.9161C72.7806 76.4291 74.0027 75.219 75.5046 75.219C77.0065 75.219 78.2286 76.4291 78.2286 77.9161C78.2286 80.9341 80.7023 83.3834 83.7503 83.3834C86.7982 83.3834 89.2719 80.9341 89.2719 77.9161C89.2719 74.8982 86.7982 72.4489 83.7503 72.4489C77.6985 72.4489 72.7806 67.5794 72.7806 61.5873C72.7806 59.1088 73.6346 56.6887 75.2101 54.7642C76.6973 52.9418 78.7587 51.6442 81.0262 51.0611V48.7575C81.0262 47.2704 82.2484 46.0604 83.7503 46.0604C85.2521 46.0604 86.4743 47.2704 86.4743 48.7575V51.0611C88.7565 51.6442 90.8032 52.9418 92.2904 54.7642C93.8512 56.6887 94.7199 59.1088 94.7199 61.5873C94.7199 63.0744 93.4978 64.2845 91.9959 64.2845C90.494 64.2845 89.2719 63.0744 89.2719 61.5873Z"
        fill={`${color1}`}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.75 46.125C20.0031 46.125 23.0677 47.4128 25.3685 49.7489C27.6692 52.0851 28.9375 55.1968 28.9375 58.5C28.9375 61.8032 27.6692 64.9149 25.3685 67.2511C23.0677 69.5872 20.0079 70.875 16.75 70.875C13.4969 70.875 10.4323 69.5872 8.13153 67.2511C5.83075 64.9149 4.5625 61.8032 4.5625 58.5C4.5625 55.1968 5.83075 52.0851 8.13153 49.7489C10.4323 47.4128 13.4921 46.125 16.75 46.125ZM6.30694 58.5C6.30694 64.3452 10.9934 69.1037 16.75 69.1037C22.5066 69.1037 27.1931 64.3452 27.1931 58.5C27.1931 52.6548 22.5066 47.8963 16.75 47.8963C10.9934 47.8963 6.30694 52.6548 6.30694 58.5ZM18.5181 55.8197C18.5181 54.8288 17.726 54.0245 16.7501 54.0245C15.7741 54.0245 14.9821 54.8288 14.9821 55.8197C14.9821 56.8107 15.7741 57.6149 16.7501 57.6149C18.6878 57.6149 20.2625 59.2139 20.2625 61.1814C20.2625 61.9953 19.9891 62.7899 19.4846 63.4218C19.0084 64.0203 18.3484 64.4463 17.6223 64.6378V65.3942C17.6223 65.8825 17.231 66.2798 16.7501 66.2798C16.2692 66.2798 15.8779 65.8825 15.8779 65.3942V64.6378C15.1471 64.4463 14.4917 64.0203 14.0156 63.4218C13.5158 62.7899 13.2376 61.9953 13.2376 61.1814C13.2376 60.6931 13.629 60.2958 14.1099 60.2958C14.5907 60.2958 14.9821 60.6931 14.9821 61.1814C14.9821 62.1724 15.7741 62.9766 16.7501 62.9766C17.726 62.9766 18.5181 62.1724 18.5181 61.1814C18.5181 60.1905 17.726 59.3862 16.7501 59.3862C14.8123 59.3862 13.2376 57.7873 13.2376 55.8197C13.2376 55.0059 13.5111 54.2112 14.0156 53.5793C14.4917 52.9809 15.1518 52.5548 15.8779 52.3633V51.607C15.8779 51.1187 16.2692 50.7213 16.7501 50.7213C17.231 50.7213 17.6223 51.1187 17.6223 51.607V52.3633C18.3531 52.5548 19.0084 52.9809 19.4846 53.5793C19.9844 54.2112 20.2625 55.0059 20.2625 55.8197C20.2625 56.308 19.8712 56.7054 19.3903 56.7054C18.9094 56.7054 18.5181 56.308 18.5181 55.8197Z"
        fill={`${color5}`}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M132.5 70.4375C134.902 70.4375 137.165 71.3545 138.864 73.0182C140.563 74.6818 141.5 76.8977 141.5 79.25C141.5 81.6023 140.563 83.8182 138.864 85.4818C137.165 87.1455 134.906 88.0625 132.5 88.0625C130.098 88.0625 127.835 87.1455 126.136 85.4818C124.437 83.8182 123.5 81.6023 123.5 79.25C123.5 76.8977 124.437 74.6818 126.136 73.0182C127.835 71.3545 130.094 70.4375 132.5 70.4375ZM124.788 79.25C124.788 83.4125 128.249 86.8011 132.5 86.8011C136.751 86.8011 140.212 83.4125 140.212 79.25C140.212 75.0875 136.751 71.6989 132.5 71.6989C128.249 71.6989 124.788 75.0875 124.788 79.25ZM133.806 77.3413C133.806 76.6356 133.221 76.0629 132.5 76.0629C131.779 76.0629 131.194 76.6356 131.194 77.3413C131.194 78.047 131.779 78.6197 132.5 78.6197C133.931 78.6197 135.094 79.7584 135.094 81.1595C135.094 81.739 134.892 82.305 134.519 82.755C134.168 83.1811 133.68 83.4845 133.144 83.6209V84.1595C133.144 84.5072 132.855 84.7902 132.5 84.7902C132.145 84.7902 131.856 84.5072 131.856 84.1595V83.6209C131.316 83.4845 130.832 83.1811 130.481 82.755C130.112 82.305 129.906 81.739 129.906 81.1595C129.906 80.8118 130.195 80.5288 130.55 80.5288C130.905 80.5288 131.194 80.8118 131.194 81.1595C131.194 81.8652 131.779 82.4379 132.5 82.4379C133.221 82.4379 133.806 81.8652 133.806 81.1595C133.806 80.4538 133.221 79.8811 132.5 79.8811C131.069 79.8811 129.906 78.7425 129.906 77.3413C129.906 76.7618 130.108 76.1959 130.481 75.7459C130.832 75.3197 131.32 75.0163 131.856 74.88V74.3413C131.856 73.9936 132.145 73.7106 132.5 73.7106C132.855 73.7106 133.144 73.9936 133.144 74.3413V74.88C133.684 75.0163 134.168 75.3197 134.519 75.7459C134.888 76.1959 135.094 76.7618 135.094 77.3413C135.094 77.689 134.805 77.972 134.45 77.972C134.095 77.972 133.806 77.689 133.806 77.3413Z"
        fill={`${color5}`}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M165 77.75C166.702 77.75 168.305 78.4524 169.508 79.7267C170.712 81.001 171.375 82.6983 171.375 84.5C171.375 86.3017 170.712 87.999 169.508 89.2733C168.305 90.5476 166.704 91.25 165 91.25C163.298 91.25 161.695 90.5476 160.492 89.2733C159.288 87.999 158.625 86.3017 158.625 84.5C158.625 82.6983 159.288 81.001 160.492 79.7267C161.695 78.4524 163.296 77.75 165 77.75ZM159.537 84.5C159.537 87.6883 161.989 90.2838 165 90.2838C168.011 90.2838 170.463 87.6883 170.463 84.5C170.463 81.3117 168.011 78.7162 165 78.7162C161.989 78.7162 159.537 81.3117 159.537 84.5ZM165.925 83.038C165.925 82.4975 165.511 82.0588 165 82.0588C164.49 82.0588 164.075 82.4975 164.075 83.038C164.075 83.5786 164.49 84.0172 165 84.0172C166.014 84.0172 166.837 84.8894 166.837 85.9626C166.837 86.4065 166.694 86.84 166.43 87.1846C166.181 87.511 165.836 87.7434 165.456 87.8479V88.2605C165.456 88.5268 165.252 88.7435 165 88.7435C164.748 88.7435 164.544 88.5268 164.544 88.2605V87.8479C164.162 87.7434 163.819 87.511 163.57 87.1846C163.308 86.84 163.163 86.4065 163.163 85.9626C163.163 85.6962 163.367 85.4795 163.619 85.4795C163.871 85.4795 164.075 85.6962 164.075 85.9626C164.075 86.5031 164.49 86.9418 165 86.9418C165.511 86.9418 165.925 86.5031 165.925 85.9626C165.925 85.4221 165.511 84.9834 165 84.9834C163.986 84.9834 163.163 84.1112 163.163 83.038C163.163 82.5941 163.306 82.1607 163.57 81.816C163.819 81.4896 164.164 81.2572 164.544 81.1527V80.7402C164.544 80.4738 164.748 80.2571 165 80.2571C165.252 80.2571 165.456 80.4738 165.456 80.7402V81.1527C165.839 81.2572 166.181 81.4896 166.43 81.816C166.692 82.1607 166.837 82.5941 166.837 83.038C166.837 83.3044 166.633 83.5211 166.381 83.5211C166.13 83.5211 165.925 83.3044 165.925 83.038Z"
        fill={`${color1}`}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M129.75 13C132.703 13 135.485 14.2487 137.573 16.5141C139.661 18.7795 140.813 21.7969 140.813 25C140.813 28.2031 139.661 31.2205 137.573 33.4859C135.485 35.7513 132.707 37 129.75 37C126.797 37 124.015 35.7513 121.927 33.4859C119.839 31.2205 118.688 28.2031 118.688 25C118.688 21.7969 119.839 18.7795 121.927 16.5141C124.015 14.2487 126.793 13 129.75 13ZM120.271 25C120.271 30.6681 124.525 35.2824 129.75 35.2824C134.975 35.2824 139.229 30.6681 139.229 25C139.229 19.3319 134.975 14.7176 129.75 14.7176C124.525 14.7176 120.271 19.3319 120.271 25ZM131.355 22.4009C131.355 21.44 130.636 20.6601 129.75 20.6601C128.864 20.6601 128.145 21.44 128.145 22.4009C128.145 23.3619 128.864 24.1418 129.75 24.1418C131.509 24.1418 132.938 25.6922 132.938 27.6002C132.938 28.3893 132.69 29.1599 132.232 29.7727C131.8 30.353 131.201 30.7661 130.542 30.9518V31.6853C130.542 32.1588 130.187 32.5441 129.75 32.5441C129.314 32.5441 128.958 32.1588 128.958 31.6853V30.9518C128.295 30.7661 127.7 30.353 127.268 29.7727C126.814 29.1599 126.562 28.3893 126.562 27.6002C126.562 27.1267 126.917 26.7414 127.354 26.7414C127.79 26.7414 128.145 27.1267 128.145 27.6002C128.145 28.5611 128.864 29.341 129.75 29.341C130.636 29.341 131.355 28.5611 131.355 27.6002C131.355 26.6392 130.636 25.8594 129.75 25.8594C127.991 25.8594 126.562 24.3089 126.562 22.4009C126.562 21.6118 126.81 20.8412 127.268 20.2284C127.7 19.6481 128.299 19.235 128.958 19.0493V18.3158C128.958 17.8423 129.314 17.457 129.75 17.457C130.187 17.457 130.542 17.8423 130.542 18.3158V19.0493C131.205 19.235 131.8 19.6481 132.232 20.2284C132.686 20.8412 132.938 21.6118 132.938 22.4009C132.938 22.8744 132.583 23.2597 132.147 23.2597C131.71 23.2597 131.355 22.8744 131.355 22.4009Z"
        fill={`${color1}`}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M44.75 12C47.803 12 50.6789 13.2487 52.8381 15.5141C54.9973 17.7795 56.1875 20.7969 56.1875 24C56.1875 27.2031 54.9973 30.2205 52.8381 32.4859C50.6789 34.7513 47.8074 36 44.75 36C41.6971 36 38.8211 34.7513 36.6619 32.4859C34.5027 30.2205 33.3125 27.2031 33.3125 24C33.3125 20.7969 34.5027 17.7795 36.6619 15.5141C38.8211 13.2487 41.6926 12 44.75 12ZM34.9496 24C34.9496 29.6681 39.3476 34.2824 44.75 34.2824C50.1524 34.2824 54.5504 29.6681 54.5504 24C54.5504 18.3319 50.1524 13.7176 44.75 13.7176C39.3476 13.7176 34.9496 18.3319 34.9496 24ZM46.4093 21.4009C46.4093 20.44 45.666 19.6601 44.7501 19.6601C43.8342 19.6601 43.0909 20.44 43.0909 21.4009C43.0909 22.3619 43.8342 23.1418 44.7501 23.1418C46.5686 23.1418 48.0464 24.6922 48.0464 26.6002C48.0464 27.3893 47.7898 28.1599 47.3163 28.7727C46.8694 29.353 46.25 29.7661 45.5686 29.9518V30.6853C45.5686 31.1588 45.2014 31.5441 44.7501 31.5441C44.2988 31.5441 43.9315 31.1588 43.9315 30.6853V29.9518C43.2457 29.7661 42.6307 29.353 42.1838 28.7727C41.7148 28.1599 41.4538 27.3893 41.4538 26.6002C41.4538 26.1267 41.821 25.7414 42.2723 25.7414C42.7236 25.7414 43.0909 26.1267 43.0909 26.6002C43.0909 27.5611 43.8342 28.341 44.7501 28.341C45.666 28.341 46.4093 27.5611 46.4093 26.6002C46.4093 25.6392 45.666 24.8594 44.7501 24.8594C42.9316 24.8594 41.4538 23.3089 41.4538 21.4009C41.4538 20.6118 41.7104 19.8412 42.1838 19.2284C42.6307 18.6481 43.2502 18.235 43.9315 18.0493V17.3158C43.9315 16.8423 44.2988 16.457 44.7501 16.457C45.2014 16.457 45.5686 16.8423 45.5686 17.3158V18.0493C46.2544 18.235 46.8694 18.6481 47.3163 19.2284C47.7853 19.8412 48.0464 20.6118 48.0464 21.4009C48.0464 21.8744 47.6791 22.2597 47.2278 22.2597C46.7765 22.2597 46.4093 21.8744 46.4093 21.4009Z"
        fill={`${color1}`}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M81.75 2.625C83.802 2.625 85.735 3.44449 87.1863 4.93114C88.6375 6.4178 89.4375 8.39797 89.4375 10.5C89.4375 12.602 88.6375 14.5822 87.1863 16.0689C85.735 17.5555 83.805 18.375 81.75 18.375C79.698 18.375 77.765 17.5555 76.3137 16.0689C74.8625 14.5822 74.0625 12.602 74.0625 10.5C74.0625 8.39797 74.8625 6.4178 76.3137 4.93114C77.765 3.44449 79.695 2.625 81.75 2.625ZM75.1628 10.5C75.1628 14.2197 78.1189 17.2478 81.75 17.2478C85.3811 17.2478 88.3372 14.2197 88.3372 10.5C88.3372 6.78032 85.3811 3.75218 81.75 3.75218C78.1189 3.75218 75.1628 6.78032 75.1628 10.5ZM82.8653 8.79437C82.8653 8.16376 82.3656 7.65196 81.7501 7.65196C81.1345 7.65196 80.6348 8.16376 80.6348 8.79437C80.6348 9.42498 81.1345 9.93677 81.7501 9.93677C82.9723 9.93677 83.9656 10.9543 83.9656 12.2064C83.9656 12.7243 83.7931 13.23 83.4749 13.6321C83.1745 14.0129 82.7582 14.284 82.3002 14.4059V14.8872C82.3002 15.1979 82.0534 15.4508 81.7501 15.4508C81.4467 15.4508 81.1999 15.1979 81.1999 14.8872V14.4059C80.7389 14.284 80.3256 14.0129 80.0252 13.6321C79.71 13.23 79.5345 12.7243 79.5345 12.2064C79.5345 11.8956 79.7813 11.6428 80.0847 11.6428C80.388 11.6428 80.6348 11.8956 80.6348 12.2064C80.6348 12.837 81.1345 13.3488 81.7501 13.3488C82.3656 13.3488 82.8653 12.837 82.8653 12.2064C82.8653 11.5757 82.3656 11.064 81.7501 11.064C80.5278 11.064 79.5345 10.0464 79.5345 8.79437C79.5345 8.27647 79.707 7.77077 80.0252 7.36864C80.3256 6.98784 80.7419 6.71671 81.1999 6.59485V6.11352C81.1999 5.80278 81.4467 5.54993 81.7501 5.54993C82.0534 5.54993 82.3002 5.80278 82.3002 6.11352V6.59485C82.7612 6.71671 83.1745 6.98784 83.4749 7.36864C83.7901 7.77077 83.9656 8.27647 83.9656 8.79437C83.9656 9.1051 83.7188 9.35795 83.4154 9.35795C83.1121 9.35795 82.8653 9.1051 82.8653 8.79437Z"
        fill={`${color5}`}
      />
      <rect
        x="8.5"
        y="107"
        width="151.5"
        height="1.5"
        rx="0.75"
        fill={`${color1}`}
      />
      <rect
        width="24"
        height="1"
        rx="0.5"
        transform="matrix(1 0 0 -1 0 91.5)"
        fill={`${color1}`}
      />
      <rect
        width="12"
        height="0.5"
        rx="0.25"
        transform="matrix(1 0 0 -1 19.5 35)"
        fill={`${color1}`}
      />
      <rect
        width="24"
        height="1"
        rx="0.5"
        transform="matrix(1 0 0 -1 133.5 62.5)"
        fill={`${color1}`}
      />
      <rect
        width="14.5"
        height="1"
        rx="0.5"
        transform="matrix(1 0 0 -1 17 84.5)"
        fill={`${color1}`}
      />
      <rect
        width="14.5"
        height="1"
        rx="0.5"
        transform="matrix(1 0 0 -1 150.5 55.5)"
        fill={`${color1}`}
      />
    </svg>
  );
}

export default PayImage;
