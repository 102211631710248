import React, { useEffect, useState } from 'react';

interface Data {
  id: string;
  name: string;
}

interface Props {
  data: Data[];
  onSubmit: (value: string) => void;
}

import { SelectDropdownList } from './FillForm.styles';

const DropDownList = ({ data, onSubmit }: Props) => {
  const [idSelected, setIdSelected] = useState('');

  useEffect(() => {
    if (idSelected.length > 1) {
      onSubmit(idSelected);
    }
  }, [idSelected]);

  return (
    <>
      {data.length > 0 && (
        <div>
          <form>
            <SelectDropdownList
              value={idSelected}
              onChange={(event) => {
                setIdSelected(event.target.value);
              }}
            >
              <option key={-1} value={''} disabled>
                Selecione
              </option>

              {data.map((item, index) => {
                return (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                );
              })}
            </SelectDropdownList>
          </form>
        </div>
      )}
    </>
  );
};

export { DropDownList };
