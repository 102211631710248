import { connect } from "react-redux";
import poliConfig from "src/utils/poliConfig";
import ContactTags from "../../../chat/modals/tags/ContactTags";
import AvailableTags from "../../../chat/modals/tags/AvailableTags";
import { Accordion } from '@polichat/flamboyant';
import SubmitButton from "src/components/common/form/SubmitButton";
import { bindActionCreators } from "redux";
import * as ChatActions from '../../../../../../store/modules/chat/actions';
import { MenuTypes } from "src/store/modules/chat/reducer";

function TagInDetails({chat, hasTags, currentOrganizationId, insertTagToContactSubmit}) {
  const customer_id = currentOrganizationId;
  const tagMenu = chat.view?.menus?.find(
    (menu) => menu.type === MenuTypes.TAG_MENU
  );
  const isLoading = tagMenu?.isLoading;
  const contactTags = tagMenu?.contactTags;
  const canSubmit = tagMenu?.contactTags.length > 0;

  const onSubmit = () => {
    if (contactTags.length) {
      let data = {
        chat_id: chat.id,
        tags: contactTags,
      };
      insertTagToContactSubmit(data);
    }
  };

  return (
    <div>
      <ContactTags />
      <div className="header-menu-modal-subsection">
        <div className="existing-tags-div">
          <Accordion
            id="1"
            principalTitle="Adicionar Etiquetas"
            principalIcon="poli-icon pi-tag-line"
          >
            <div className="content-tags">
              <AvailableTags />
            </div>
          </Accordion>
        </div>
      </div>

      {hasTags ? <div className="header-menu-modal-button">
        <SubmitButton
          loading={isLoading}
          className="header-menu-modal-button-yes"
          onClick={() => {
            onSubmit();
          }}
          title="Adicionar"
          disabled={!canSubmit}
        />
      </div> :
      <div className="header-menu-modal-button">
        <SubmitButton
          loading={isLoading}
          className="header-menu-modal-button-add"
          onClick={() => {
            window.location.assign(`${poliConfig.poliAppLaravelUrl}/settings/${customer_id}/tags`)
          }}
          title="Criar nova Etiqueta"
        />
      </div>}
    </div>
  )
}
const mapStateToProps = (state) => ({
  chat: state.chat?.chats?.find((chat) => chat.open === true),
  tags: state.chat?.config?.general?.tags,
  hasTags: state.chat?.config?.general?.tags?.length > 0,
  currentOrganizationId: state.general?.current_customer_id,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(ChatActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TagInDetails);