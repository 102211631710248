import axios from 'axios';
import poliAuthTokenGet from './poliAuthTokenGet';
import poliConfig from './poliConfig';

const poliApiGatewayRequest = axios.create({
  baseURL: poliConfig.apiGatewayBaseURL,
});
poliApiGatewayRequest.interceptors.request.use(function (config) {
  config.headers.Authorization = `Bearer ${poliAuthTokenGet()}`;
  config.headers['Content-Type'] =
    config.headers['Content-Type'] || 'application/json';
  config.headers['Accept'] = 'application/json';
  return config;
});

export default poliApiGatewayRequest;
