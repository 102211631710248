import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Col, FormGroup, CustomInput } from '@polichat/flamboyant';

import SelectDepartments from './departments/SelectDepartments.component';
import SelectAttendants from './attendants/SelectAttendants.component';

import { DepartmentsService } from '../../../../../services/tools/messagebuilder/departments';

function TemplateOptionSpecificBonds({ onUpdate, initialValue, haveOffSet }) {
  const [hasSpecificBonds, setHasSpecificBonds] = useState({
    // multi seleção
    // link: (initialValue.departments.length || initialValue.attendants.length) ? true : false,
    // departments: initialValue.departments,
    // attendants: initialValue.attendants

    link: initialValue?.department_id || initialValue?.user_id ? true : false,
    department_id: initialValue?.department_id,
    user_id: initialValue?.user_id,
  });

  // get the current customer id from Redux on General
  const currentCustomerId = useSelector(
    (state) => state.general.current_customer_id
  );

  const [isLoading, setIsLoading] = useState(false);
  const [departments, setDepartmentList] = useState([]);

  const onGetDepartments = async () => {
    try {
      setIsLoading(true);

      let response = await DepartmentsService.getDepartments({
        customer_id: currentCustomerId,
      });

      if (response.error) throw Error(response.error);

      setDepartmentList(response);
    } catch (error) {
      setDepartmentList([
        { id: 'error', name: 'Erro ao carregar', disabled: true },
      ]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    onGetDepartments();
  }, []);

  const handleInputChange = ({ target: { name, value, checked } }) => {
    setHasSpecificBonds({
      ...hasSpecificBonds,
      [name]: checked,
    });
    handleHasSpecificBondsData({
      ...hasSpecificBonds,
      [name]: checked,
    });
  };

  const handleDepartmentData = (departmentData) => {
    // const { departments } = departmentData

    // multi seleção
    // setHasSpecificBonds({...hasSpecificBonds, departments})
    // handleHasSpecificBondsData({...hasSpecificBonds, departments})

    setHasSpecificBonds({ ...hasSpecificBonds, department_id: departmentData });
    handleHasSpecificBondsData({
      ...hasSpecificBonds,
      department_id: departmentData,
    });
  };

  const handleAttendantsData = (attendantsData) => {
    // const { attendants } = attendantsData

    // multi seleção
    // setHasSpecificBonds({...hasSpecificBonds, attendants})
    // handleHasSpecificBondsData({...hasSpecificBonds, attendants})]

    setHasSpecificBonds({ ...hasSpecificBonds, user_id: attendantsData });
    handleHasSpecificBondsData({
      ...hasSpecificBonds,
      user_id: attendantsData,
    });
  };

  // const handleHasSpecificBondsData = ({link, departments, attendants}) => {
  const handleHasSpecificBondsData = ({ link, department_id, user_id }) => {
    if (!link) {
      department_id = null;
      user_id = null;
    }

    // multi seleção
    // onUpdate({departments, attendants});
    onUpdate({ department_id, user_id });
  };

  return (
    <>
      <Col
        sm={12}
        md={{ size: 6, offset: haveOffSet ? 6 : 0 }}
        style={{ alignSelf: 'flex-end' }}
      >
        <FormGroup>
          <CustomInput
            type="checkbox"
            label="Vinculos especificos"
            id="template-link"
            name="link"
            checked={hasSpecificBonds.link}
            value={hasSpecificBonds.link}
            onChange={handleInputChange}
          />
        </FormGroup>
      </Col>
      <>
        {/* multi seleção */}
        {/* {hasSpecificBonds.link && <SelectDepartments onUpdate={handleDepartmentData} initialValues={hasSpecificBonds.departments}/> }
    {hasSpecificBonds.link && <SelectAttendants onUpdate={handleAttendantsData} initialValues={hasSpecificBonds.attendants}/> } */}

        {hasSpecificBonds.link && (
          <SelectDepartments
            onUpdate={handleDepartmentData}
            initialValues={hasSpecificBonds.department_id}
            departmentData={{ departments, isLoading }}
          />
        )}
        {hasSpecificBonds.link && (
          <SelectAttendants
            onUpdate={handleAttendantsData}
            initialValues={hasSpecificBonds.user_id}
          />
        )}
      </>
    </>
  );
}

export default TemplateOptionSpecificBonds;
