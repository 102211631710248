import React from 'react';
import { Button } from '@polichat/flamboyant';

import Illustration from '../../../../../../assets/images/illustrations/illustration-welcome-waba-channel.svg';
import { Container } from './Congratulations.styles';

interface Props {
  onUpdate: () => void;
}

const Congratulations = ({ onUpdate }: Props) => {
  return (

  <div data-tour={'HelpHero_Migrate_WhatsApp_Step05'}>
    <Container>
      <img
        src={Illustration}
        alt="Canal adicionado"
      />
      <h2>Parabéns, seu canal foi adicionado com sucesso.</h2>
      <Button
        gradient="primary"
        width="total"
        onClick={() => {
          onUpdate();
        }}
      >
        Concluído
      </Button>
    </Container>
    </div>
  );
};

export default Congratulations;
