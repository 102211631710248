import { ChannelBalance, DetachedCredits } from '../../types';

interface ChannelWabaBalance {
  total: number;
  used: number;
}

/**
 * Calculate the total credits percentage usage
 * @param channelBalance the object contains the selected channel credits used and total
 * @param detachedCredits the object contains the detached credits used and total
 * @returns the total usage of credits in percentage
 */
export function calculatePercent(
  channelBalance: ChannelBalance | ChannelWabaBalance,
  detachedCredits: DetachedCredits
) {
  let spent = 0;
  let total = 0;
  let extra = 0;

  //verifica quantos créditos avulsos foram consumidos pelo canal
  //checks how many single credits were consumed by the channel
  if (channelBalance.used >= channelBalance.total)
    // @ts-ignore
    extra -= channelBalance.total - channelBalance.used; //altera o sinal para deixar - na expressão final

  // @ts-ignore
  total = channelBalance.total + detachedCredits.total;
  // @ts-ignore
  spent = channelBalance.used + detachedCredits.used - extra;

  //equation calculate percentage
  return Number((100 * (spent / total)).toFixed(2));
}
