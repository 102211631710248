import { useEffect, useState } from 'react';
import { initFacebookSDK } from '../sdk/initFacebookSDK';
import Animation from '../../../../components/common/animation';
import {
  FacebookAdsContainer,
  IframeContainer,
} from '../../components/style/fbiframe';
import { useSelector } from 'react-redux';

export interface Customer {
  id: number;
  name: string;
  contact_email: string;
  phone: string;
  site: string;
  adress_street: string;
  adress_city: string;
  adress_state: string;
  adress_postal_code: string;
  photo: string;
  created_at: string;
}

export function AdsReportFacebookPage() {
  const [fbSDKinitiated, setFbSDKinitiated] = useState<boolean>();

  const urlSPA = window?.location?.origin ?? 'https://app-spa.poli.digital';

  /**
   * Data do iframe
   */
  const dataFbDefault = {
    business_config: { business: { name: '' } },
    setup: {
      external_business_id: '',
      timezone: 'America/Sao_Paulo',
      currency: 'BRL',
      business_vertical: 'ECOMMERCE',
    },
    repeat: false,
  };

  const [dataFb, setDataFb] = useState(dataFbDefault);
  /* end */

  /**
   * pegando id da empresa no state
   */
  const customer_id: number = useSelector(
    // @ts-ignore
    (state) => state?.general?.current_customer_id
  );
  /* end */

  /**
   * Todas as empresas que o usuario tem acesso
   */
  const customers: Customer[] = useSelector(
    // @ts-ignore
    (state) => state?.general?.user?.customers
  );
  /* end */

  /**
   * Pegando dados da empresa atual
   */
  useEffect(() => {
    if (customers && Array.isArray(customers) && customer_id) {
      const _customer = customers.find((e) => e.id === customer_id);
      if (_customer?.id) {
        const _dataFbDefault = { ...dataFbDefault };
        _dataFbDefault.business_config.business.name = _customer.name;
        _dataFbDefault.setup.external_business_id = _customer.id.toString();
        setDataFb({ ..._dataFbDefault });
      }
    }
  }, [customers, customer_id]);
  /* end */

  const LoadingComponent = (
    <Animation
      icon="chat-loading"
      style={{
        maxWidth: '150px',
        height: '100%',
        maxHeight: '100px',
      }}
    />
  );

  useEffect(() => {
    initFacebookSDK().then(() => {
      setTimeout(() => {
        setFbSDKinitiated(true);
      }, 1500);
    });
  }, []);

  return (
    <>
      <FacebookAdsContainer>
        <div>
          <h3>Relatório</h3>
        </div>

        {!fbSDKinitiated && LoadingComponent}

        <IframeContainer>
          <div
            className="fb-lwi-ads-insights"
            data-fbe-extras={JSON.stringify(dataFb)}
            data-fbe-scopes="manage_business_extension,ads_management"
            data-fbe-redirect-uri={`${urlSPA}/ads/facebook/create?close=popup`}
          ></div>
        </IframeContainer>
      </FacebookAdsContainer>
    </>
  );
}
