import { Icon }from '@polichat/flamboyant';
import OrganizationAddForm from '../organizationAddForm';
import { connect } from 'react-redux';


function OrganizationAdd({ chatConfig }) {
  let show;
  if (chatConfig) {
    if (chatConfig.openAddCompany) {
      show = true;
    } else {
      show = false;
    }
  }

  if (show) {
    return (
      <div className="contactContent">
        <div className="headerComponent">
          <div className="smallCircle">
            <Icon
              icon={'poli-icon pi-company-line'}
              size={30}
              color="var(--green-polichat)"
            />
          </div>

          <h4>Adicionar Empresa</h4>
        </div>
        <OrganizationAddForm icon="poli-icon pi-company-line" type="add" />
      </div>
    );
  } else {
    return <></>;
  }
}

const mapStateToProps = (state) => ({
  chatConfig: state.chat?.config,
});

export default connect(mapStateToProps)(OrganizationAdd);
