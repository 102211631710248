import React from 'react';
import { Link } from 'react-router-dom';
import {
  Switch,
  CustomInput,
  Card,
  CardBody,
  CardImg,
  Icon,
  CardTitle,
  Button,
  UncontrolledTooltip,
} from '@polichat/flamboyant';

import usePaymentCard from './hooks/usePaymentCard';

import { Cardpay } from './styles';

function PaymentCard(props) {
  const { status, mainMethod, onMainMethod, onStatus, checkStatus } =
    usePaymentCard(props);

  return (
    <>
      <Cardpay>
        <Card>
          <CardImg src={props.img} alt={props.title} />
          <CardBody className="card__content">
            <CustomInput
              id={props.paymentMethod}
              className="card__checkbox"
              type="checkbox"
              name="card__checkbox"
              checked={mainMethod}
              onChange={status ? onMainMethod : undefined}
            />
            {!status && (
              <UncontrolledTooltip
                placement="bottom"
                target={props.paymentMethod}
              >
                Para selecionar o Método Principal, por favor deixe o método
                como "Ativo"
              </UncontrolledTooltip>
            )}
            <label
              className="card__label"
              htmlFor={props.paymentMethod}
              id={`method-${props.paymentMethod}`}
            >
              Método Principal
            </label>

            <div className="card__info">
              <CardTitle>{props.title}</CardTitle>
              <div className="card__switch">
                <span className="card__switch--info">
                  {status ? 'Ativo' : 'Desativado'}
                </span>

                {props?.id && (
                  <>
                    <Switch
                      id={props.paymentMethod}
                      key={`${props.paymentMethod}-${status}`}
                      size={'sm'}
                      color="secondary"
                      isChecked={status}
                      onClick={onStatus}
                    />
                  </>
                )}
              </div>
              <Link to={`/shopping${props.link}`}>
                {' '}
                <Button size={'sm'} color="primary">
                  {' '}
                  <Icon icon="poli-icon pi-e-settings-line"></Icon>Configurações{' '}
                </Button>
              </Link>
            </div>
          </CardBody>
        </Card>
      </Cardpay>
    </>
  );
}

export default PaymentCard;
