import styled from 'styled-components';

export const WaveformContianer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 3.5em;
  width: 100%;
  background: transparent;
  gap: 2em;
`;

export const Wave = styled.div`
  width: 100%;
  height: 60px;
`;

export const PlayButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  background: #efefef;
  border-radius: 50%;
  border: none;
  outline: none;
  cursor: pointer;
  padding-bottom: 3px;
`;
