import './styles.css';
import { Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import createCompany from '../../tools/integrations/crmRequests/createCompany';
import {
  openChatTab,
  setAddOrganizationForm,
} from '../../../store/modules/chat/actions';
import { useLocalForm } from './useLocalForm';
import { toast } from 'react-toastify';
import React, { useState } from 'react';
import { formatCnpj } from './useLocalForm/validationSchema';

import {
  Col,
  Row,
  Button,
  Container,
  FormGroup,
  Input,
  FormFeedback,
  Label,
} from '@polichat/flamboyant';

import { isMobile } from 'react-device-detect';

export default function OrganizationAddForm(props) {
  const dispatch = useDispatch();
  const form = useLocalForm();

  const [cnpjValue, setCnpjValue] = useState('');

  const typeOptions = {
    '': '',
    active: 'Cliente Ativo',
    inactive: 'Cliente Inativo',
    provider: 'Fornecedor',
  };

  async function verifyCompanyAtBase(data) {
    try {
      const response = await createCompany(data);

      if (response) {
        toast.success(
          <div style={{ margin: 0 }}>
            <span>Cadastro realizado</span>
            {response && (
              <>
                <br />
              </>
            )}
          </div>
        );
        return true;
      }
    } catch (error) {
      if(error?.response?.data?.error === "Duplicated CNPJ"){
        toast.error(          
          "Este CNPJ já foi cadastrado anteriormente."
        );
      }else{
        toast.error(
          error?.response?.data?.error ||
            'Error ao salvar empresa, tente novamente!'
        );
      }
    }
    return false;
  }

  const onSubmit = async (values) => {
    try {
      let toast = await verifyCompanyAtBase(values);
      if (toast) {
        setIsLoading(true);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <Container className={'createForm'}>
        <Row form>
          <Col lg={12}>
            <FormGroup>
              <Label for="name">Nome:</Label>
              <Controller
                control={form.control}
                name="name"
                render={({ field, fieldState }) => (
                  <>
                    <Input
                      InputLabelProps={{ shrink: true }}
                      type="text"
                      label="name"
                      variant="outlined"
                      margin="normal"
                      {...field}
                      invalid={!!fieldState.error}
                    />
                    <FormFeedback>{fieldState.error?.message}</FormFeedback>
                  </>
                )}
              />
            </FormGroup>
          </Col>
          <Col lg={6}>
            <FormGroup>
            <Label for="cnpj">CNPJ:</Label>
            <Controller
              control={form.control}
              name="cnpj"
              render={({ field, fieldState }) => (
                <>
                  <Input
                    InputLabelProps={{ shrink: true }}
                    type="text"
                    label="cnpj"
                    variant="outlined"
                    margin="normal"
                    value={cnpjValue}
                    onChange={(e) => {
                      const formattedValue = formatCnpj(e.target.value);
                      setCnpjValue(formattedValue);
                      field.onChange(formattedValue);
                    }}
                    invalid={!!fieldState.error}
                  />
                    <FormFeedback>{fieldState.error?.message}</FormFeedback>
                  </>
                )}
              />
            </FormGroup>
          </Col>
          <Col lg={6}>
            <FormGroup>
              <Label for="companyName">Razão Social:</Label>
              <Controller
                control={form.control}
                name="companyName"
                render={({ field, fieldState }) => (
                  <>
                    <Input
                      InputLabelProps={{ shrink: true }}
                      type="text"
                      label="companyName"
                      variant="outlined"
                      margin="normal"
                      {...field}
                      invalid={!!fieldState.error}
                    />
                    <FormFeedback>{fieldState.error?.message}</FormFeedback>
                  </>
                )}
              />
            </FormGroup>
          </Col>
          <Col lg={6}>
            <FormGroup>
              <Label for="site">Site:</Label>
              <Controller
                control={form.control}
                name="site"
                render={({ field, fieldState }) => (
                  <>
                    <Input
                      InputLabelProps={{ shrink: true }}
                      type="text"
                      label="site"
                      variant="outlined"
                      margin="normal"
                      {...field}
                      invalid={!!fieldState.error}
                    />
                    <FormFeedback>{fieldState.error?.message}</FormFeedback>
                  </>
                )}
              />
            </FormGroup>
          </Col>
          <Col lg={6}>
            <FormGroup>
              <Label for="email">Email:</Label>
              <Controller
                control={form.control}
                name="email"
                render={({ field, fieldState }) => (
                  <>
                    <Input
                      InputLabelProps={{ shrink: true }}
                      type="text"
                      label="email"
                      variant="outlined"
                      margin="normal"
                      {...field}
                      invalid={!!fieldState.error}
                    />
                    <FormFeedback>{fieldState.error?.message}</FormFeedback>
                  </>
                )}
              />
            </FormGroup>
          </Col>
          <Col lg={6}>
            <FormGroup>
              <Label for="type">Tipo:</Label>
              <Controller
                name="type"
                control={form.control}
                defaultValue={typeOptions[0]}
                render={({ field }) => (
                  <>
                    <Input
                      InputLabelProps={{ shrink: true }}
                      type="select"
                      label="type"
                      variant="outlined"
                      margin="normal"
                      {...field}
                    >
                      {Object.values(typeOptions).map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </Input>
                  </>
                )}
              />
            </FormGroup>
          </Col>
          <Col lg={6}>
            <FormGroup>
              <Label for="address">Endereço:</Label>
              <Controller
                control={form.control}
                name="address"
                render={({ field, fieldState }) => (
                  <>
                    <Input
                      InputLabelProps={{ shrink: true }}
                      type="text"
                      label="address"
                      variant="outlined"
                      margin="normal"
                      {...field}
                      invalid={!!fieldState.error}
                    />
                    <FormFeedback>{fieldState.error?.message}</FormFeedback>
                  </>
                )}
              />
            </FormGroup>
          </Col>
          <Col lg={12}>
            <FormGroup>
              <Label for="observations">Observações:</Label>
              <Controller
                control={form.control}
                name="observations"
                render={({ field, fieldState }) => (
                  <>
                    <Input
                      InputLabelProps={{ shrink: false }}
                      type="text"
                      label="observations"
                      variant="outlined"
                      margin="normal"
                      {...field}
                      invalid={!!fieldState.error}
                      style={{ height: '100px' }}
                    />
                    <FormFeedback>{fieldState.error?.message}</FormFeedback>
                  </>
                )}
              />
            </FormGroup>
          </Col>
        </Row>
      </Container>
      <Container className="companyFormFooter">
        <Row className="buttonGroup center">
          <Col sm={12}>
            {
              <>
                <Button
                  size="lg"
                  color="danger"
                  onClick={() => {
                    if (props.type === 'add') {
                      dispatch(setAddOrganizationForm(false));

                      if (
                        isMobile &&
                        document
                          .querySelector('.chatPanelContainer')
                          .classList.contains('hidden')
                      ) {
                        document
                          .querySelector('.chatPanelContainer')
                          .classList.remove('hidden');
                      }
                      dispatch(openChatTab());
                    }
                  }}
                >
                  Cancelar
                </Button>{' '}
                <Button size="lg" color="primary">
                  Salvar
                </Button>
              </>
            }
          </Col>
        </Row>
      </Container>
    </form>
  );
}
