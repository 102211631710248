import { ReactNode } from 'react';
import styles from './QuickItem.module.css';

interface QuickDescriptionHeaderProps {
  name: string;
  shortcut?: string;

  children?: ReactNode;
}
export function QuickDescriptionHeader({
  name,
  shortcut,
  children,
}: Readonly<QuickDescriptionHeaderProps>) {
  return (
    <div className={styles.text_name}>
      {children}
      <span className={styles.text_name__title}>#{name}</span>

      {shortcut && (
        <span
          className={styles.text_name_shortcut}
        >{` - Atalho: ${shortcut} `}</span>
      )}
    </div>
  );
}
