import { InfoAllByWabaId } from '../types/InfoAllByWabaId';
import { getInfoAllByWabaId } from '../request/getInfoAllByWabaId';

const mountAndGetInfos = async (
  waba_ids: string[],
  requestFromWabaService: boolean
): Promise<InfoAllByWabaId[]> => {
  const infoAllData: InfoAllByWabaId[] = [];
  for (let i = 0; i < waba_ids.length; i++) {
    const waba_id = waba_ids[i];
    const res = await getInfoAllByWabaId(waba_id, requestFromWabaService);
    if (res) infoAllData.push(res);
  }

  return infoAllData;
};

export { mountAndGetInfos };
