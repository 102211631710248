import { useEffect, useState } from 'react';
import { Button, Icon, Row, Col, FormGroup, Label, CustomInput } from '@polichat/flamboyant';


import CreateBodyWaba from './body/CreateBodyWaba.component';
import CreateButtonWaba from './button/CreateButtonWaba.component';
import CreateFooterWaba from './footer/CreateFooterWaba.component';
import CreateHeaderWaba from './header/CreateHeaderWaba.component';
import SelectCategoryWaba from './categories/SelectCategoryWaba.component';
import SelectLanguageWaba from './language/SelectLanguageWaba.component';

import { LabelWithSpan } from '../FormMessagesBuilder.styles';

function FormTemplateWaba({ onUpdate , initialValue, isEdit}) {
  const { header, body, buttons, footer, options } = initialValue;

  const [messageButtons, setMessageButtons] = useState(buttons);
  const [messageHeader, setMessageHeader] = useState(header);
  const [messageBody, setMessageBody] = useState(body);
  const [messageFooter, setMessageFooter] = useState(footer);
  const [messageOptions, setMessageOptions] = useState(options);

  const buttonsType = [
    {id: 1, type: 'reply', description: 'Responder', limit: 3},
    {id: 2, type: 'action', description: 'Ação', limit: 2}
  ]
  const [isDisabledAddButton, setIsDisabledAddButton] = useState(false);
  const [buttonWabaType, setButtonWabaType] = useState(() => {
    const initialState =  messageButtons.length ? buttonsType.filter(btn => btn.type === messageButtons[0].type)[0] : buttonsType[0]
    return initialState
  });

    const updateHeaderData = (headerData) => {
      setMessageHeader(headerData);
    }

    const updateOptionsData = (optionsData) => {
      setMessageOptions({...messageOptions, ...optionsData});
    }

    const updateBodyData = (bodyData) => {
      setMessageBody(bodyData);
    }

    const updateFooterData = (footerData) => {
      setMessageFooter(footerData);
    }

    const handleAddButton = () => {
      if(isDisabledAddButton) return
      setMessageButtons([...messageButtons,{
          id: new Date().getTime(),
          type: buttonWabaType.type,
          text: "",
          actionType: "call",
          actionText: ""
        }
      ]);

      messageButtons.length + 1 >= buttonWabaType.limit && setIsDisabledAddButton(true)

    }

    const handleRemoveButton = (id) => {
      if(messageButtons.length <= 0 ) return
      setMessageButtons(messageButtons.filter(button => button.id != id))
      setIsDisabledAddButton(false);
    }

    const handleChangeButtonType = ({target: {name, value}}) => {
      setButtonWabaType(buttonsType.filter(btn => btn.type === value)[0])
      setMessageButtons([{
          id: new Date().getTime(),
          type: value,
          text: "",
          actionType: value === 'reply' ? '' :  "call",
          actionText: ""
        }
      ]);

      setIsDisabledAddButton(false);
    }

    const updateButtonData = (buttonData) => {
      const { id } = buttonData;
      let filteredButtons = messageButtons.filter(btn => btn.id !== id);
      filteredButtons.push(buttonData)
      filteredButtons = filteredButtons.sort((a, b) => {
        if (a.id < b.id) return -1;
        if (a.id > b.id) return 1;
        return 0;
      });
      setMessageButtons(filteredButtons);

    }

  useEffect(() => {
    onUpdate({
      header: messageHeader,
      body:  messageBody,
      footer:  messageFooter,
      buttons: messageButtons,
      options: messageOptions
    })
  }, [messageOptions, messageHeader, messageBody, messageFooter, messageButtons]);

  return (
   <>
    <Row>
      <SelectCategoryWaba onUpdate={updateOptionsData} initialValue={messageOptions.category} isEdit={isEdit}/>
      <SelectLanguageWaba onUpdate={updateOptionsData} initialValue={messageOptions.language} isEdit={isEdit}/>
    </Row>
    <CreateHeaderWaba onUpdate={updateHeaderData} initialValue={messageHeader} isEdit={isEdit}/>
    <CreateBodyWaba onUpdate={updateBodyData}  initialValue={messageBody} isEdit={isEdit}/>
    <CreateFooterWaba onUpdate={updateFooterData} initialValue={messageFooter} isEdit={isEdit}/>
    <Row>
      {messageButtons.length >= 1 &&
        <Col sm={12} md={6}>
          <FormGroup>
            <LabelWithSpan>
              <Label>Tipo de botão:</Label>
              <span>Você pode adicionar até {buttonWabaType.limit} botões</span>
            </LabelWithSpan>
            <CustomInput
              type="select"
              id="button-waba"
              name="type"
              value={buttonWabaType.type}
              onChange={handleChangeButtonType}
              disabled={isEdit}
              >
              {buttonsType.map(btType => (
                <option
                key={btType.id}
                value={btType.type}
                >
                  {btType.description}
                </option>))}
            </CustomInput>
          </FormGroup>
        </Col>
      }
    </Row>
    {messageButtons.map(button => (
      <Row key={button.id}>
        <CreateButtonWaba
          id={button.id}
          removeButton={handleRemoveButton}
          onUpdate={updateButtonData}
          initialValue={button}
          isEdit={isEdit}
          />
      </Row>
      ))}
      <Button color="secondary-link"
        onClick={handleAddButton}
        disabled={isEdit || isDisabledAddButton}>
        <Icon
        icon="poli-icon pi-add-circle-line"
        size={20}
        position={"relative"}
        top={"2px"}
        right={"2px"}/>
        <span>Adicionar botão</span>
      </Button>
  </>)
};

export default FormTemplateWaba;
