import React, { ReactNode } from 'react';

import styles from './QuickItem.module.css';

interface QuickRootProps extends React.HTMLProps<HTMLLIElement> {
  children: ReactNode;
}

export const QuickRoot = React.forwardRef<HTMLLIElement, QuickRootProps>(
  ({ children, ...rest }, ref) => {
    return (
      <li className={styles.container} {...rest} ref={ref}>
        {children}
      </li>
    );
  }
);
