import styled from 'styled-components';

export const Container = styled.div`
  height: ${(prop) => (prop.height ? prop.height : 'auto')};
  background: var(--secondary-background-color);
  border: 1px solid var(--border-color);
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
  position: relative;
`;

export const CardNumber = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 1rem;
  top: 1rem;

  width: 2rem;
  height: 2rem;
  border-radius: 2rem;
  font-weight: bold;

  background-color: #20EFE5;
  color: var(--primary-background-color);

  margin-top: -1.8rem;
  margin-right: -1.8rem;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: auto;
`;
