import { CustomInput, Label, Row, Col, Container } from '@polichat/flamboyant';
import { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { EnumMetricType } from '../../../../../services/meta-ads/dto/dtoMetaAdsMetricData';
import { useLocalForm } from './useLocalForm';

import { LocalFormValues } from './useLocalForm/types';

interface MetricsFiltersProps {
  onMetricTypeChange: (values: LocalFormValues) => void;
  disabled: boolean;
}
export function MetricsFilters(props: MetricsFiltersProps) {
  const form = useLocalForm();
  // ---------------------------------------------
  // Transformations
  function getMetricTypeFromSelectValue(value: string) {
    props.onMetricTypeChange({ type: value });
  }

  useEffect(() => {
    getMetricTypeFromSelectValue(form.watchedValueType);
  }, [form.watchedValueType]);
  // ---------------------------------------------
  // Render

  return (
    <div style={{ margin: '0 1rem' }}>
      <Controller
        control={form.control}
        name="type"
        render={({ field }) => (
          <>
            <Label for="select_ads_type">Tipo de Anúncios</Label>
            <CustomInput
              type="select"
              id="select_ads_type"
              value={field.value}
              onChange={field.onChange}
              disabled={props.disabled}
            >
              <option value={EnumMetricType.AD}>Anúncio</option>
              <option value={EnumMetricType.POST}>Postagem</option>
            </CustomInput>
          </>
        )}
      />
    </div>
  );
}
