import React, { useState } from 'react';
import { Alert, Button, Icon, Input } from '@polichat/flamboyant';

import { CustomModal, CustomModalHeader } from './styles';

interface Props {
  isOpen: boolean;
  toggle: () => void;
  channelData: any;
  handleDeleteChannel: () => void;
  isLoading: boolean;
}
export default function DeleteChannelModal({
  isOpen,
  toggle: toggleFromDad,
  channelData,
  handleDeleteChannel,
  isLoading,
}: Props) {
  const [confirmInput, setConfirmInput] = useState('');

  const modalToggle = () => {
    toggleFromDad();
    setConfirmInput('');
  };

  return (
    <>
      <CustomModal isOpen={isOpen} toggle={modalToggle} className="ModalMain">
        <CustomModalHeader toggle={modalToggle}>
          <Icon icon="poli-icon pi-delete-fill" color="var(--danger)" />
          Tem certeza?
        </CustomModalHeader>
        <Alert color="danger">
          Atenção: Deseja realmente EXCLUIR PERMANENTEMENTE este canal? Esta
          ação não pode ser desfeita!
        </Alert>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          Importante: A exclusão de um canal não gera redução no seu plano! Caso
          precise realizar o downgrade, contate o suporte ou financeiro para dar
          seguimento.
          <br />
          <br />
          <div style={{ marginBottom: '0.5rem' }}>
            Por favor, digite <b>{channelData.name}</b> abaixo para continuar:
          </div>
          <Input
            value={confirmInput}
            onChange={(e: any) => setConfirmInput(e.target.value)}
          />
          <br />
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              color="danger"
              disabled={confirmInput !== channelData.name || isLoading}
              onClick={handleDeleteChannel}
              style={{
                cursor: isLoading ? 'wait' : null,
              }}
            >
              {isLoading
                ? 'Solicitando exclusão...'
                : 'Entendi as consequências, quero excluir este canal'}
            </Button>
          </div>
        </div>
      </CustomModal>
    </>
  );
}
