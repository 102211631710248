import React from 'react';
import { connect } from 'react-redux';
import { MdCheckCircle, MdError /*MdLink*/ } from 'react-icons/md';
import {
  Container,
  FileInfo,
  PreviewIcon,
  PreviewImage,
  PreviewAudio,
  PreviewPdf,
  PreviewVideo,
  PreviewZip,
} from './styles';

import { bindActionCreators } from 'redux';
import * as ChatActions from '../../store/modules/chat/actions';

function FileList({ props, removeItemUploadList }) {
  let files = props.files;
  return (
    <>
      <Container>
        {files.map((uploadedFile, index) => (
          <li key={uploadedFile.id}>
            <FileInfo>
              {uploadedFile.typeFile === 'image' ? (
                <PreviewImage src={uploadedFile.preview} />
              ) : uploadedFile.typeFile === 'audio' ? (
                <PreviewAudio />
              ) : uploadedFile.typeFile === 'video' ? (
                <PreviewVideo />
              ) : uploadedFile.subTypeFile === 'pdf' ? (
                <PreviewPdf />
              ) : uploadedFile.subTypeFile === 'zip' ||
                uploadedFile.subTypeFile === 'rar' ? (
                <PreviewZip />
              ) : (
                <PreviewIcon />
              )}
              <div>
                <strong>{uploadedFile.name}</strong>
                <span>
                  <span>{uploadedFile.readableSize}</span>
                  {!uploadedFile.uploaded && (
                    <button
                      onClick={() => {
                        removeItemUploadList(props.chatSelected, index);
                      }}
                    >
                      <i className="poli-icon pi-delete-line" />
                    </button>
                  )}
                </span>
              </div>
            </FileInfo>
            <div>
              {uploadedFile.uploaded && (
                <MdCheckCircle size={24} color="var(--green-polichat)" />
              )}
              {uploadedFile.error && (
                <MdError size={24} color="var(--red-polichat)" />
              )}
            </div>
          </li>
        ))}
      </Container>
    </>
  );
}

const mapStateToProps = (state, ownProps) => ({
  chat: state.chat.chats,
  props: ownProps,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(ChatActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FileList);
