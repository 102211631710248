import React, { useState } from 'react';
import {
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  UncontrolledTooltip,
  Icon,
} from '@polichat/flamboyant';
import { DropdownList, DropdownListItem, ListItemContents } from './styles';

export default function ChannelsMonitor() {
  const [show, setShow] = useState(false);
  const toggle = () => setShow((prevState) => !prevState);

  return (
    <Dropdown
      isOpen={show}
      toggle={toggle}
      className="visitorsNotification"
      size={'normal-icon'}
      color={'neutral'}
    >
      <DropdownToggle
        color={'neutral'}
        border="border-none"
        id="tooltipNewsAndFeedback"
        size={'normal-icon'}
        caret
      >
        <UncontrolledTooltip placement="bottom" target="tooltipNewsAndFeedback">
          Novidades e Feedback
        </UncontrolledTooltip>
        <Icon
          size={24}
          icon="poli-icon pi-alerts-line"
          position={'relative'}
          top={'0px'}
          right={'0px'}
        />
      </DropdownToggle>
      <DropdownMenu className="plateBox">
        <DropdownList>
          <DropdownListItem>
            <a href="https://poli.digital/novidades/" target="_blank">
              <ListItemContents>
                <Icon
                  icon="poli-icon pi-loading-line"
                  size={20}
                  color="var(--primary)"
                />
                <p>Novidades e atualizações</p>
              </ListItemContents>
            </a>
          </DropdownListItem>
          <DropdownListItem>
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSfp32HoPS6AbzV1y-3IUGUdXVm_7T3E8EROUXlaFiPandTA5A/viewform"
              target="_blank"
            >
              <ListItemContents>
                <Icon
                  icon="poli-icon pi-message-line"
                  size={20}
                  color="var(--primary)"
                />
                <p>Feedbacks e sugestões</p>
              </ListItemContents>
            </a>
          </DropdownListItem>
        </DropdownList>
      </DropdownMenu>
    </Dropdown>
  );
}
