import React from 'react';
import Title from './Title';
import Actions from './Actions';
import BackToLink from '../../../mobile/back-to-actions/BackToLink.component';
import { isMobile } from 'react-device-detect';
import { useLocation } from 'react-router-dom';
import CustomBox from './CustomBox';

function Header({showCustomBoxWL}) {
  // Utilizado para acessar o path atual da rota
  const { pathname } = useLocation();

  return (
    <>
      {isMobile && pathname !== '/chat' && <BackToLink />}

      <Title />
      {showCustomBoxWL?<CustomBox local="head" />:<></>}
      <Actions />
    </>
  );
}

export default Header;
