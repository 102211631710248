import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useFieldArray, useWatch } from 'react-hook-form';
import { toast } from 'react-toastify';
import FilePreview from './FilePreview/index.js';
import { Button, Icon } from '@polichat/flamboyant';

// Store Redux - Actions and helper functions
import {
  addMessage,
  addUploadToList,
  clearReplyingMessage,
  updateChatDropzone,
  sendFilesMessage,
} from '../../../store/modules/chat/actions';

import {
  canSendMessage,
  mountMessage,
  treatObjectFile,
} from '../../../store/modules/chat/functions';

// Components
import UploadV2 from '../../upload/uploadV2.js';
import { useCaptionForm } from './useCaptionForm';
import { CaptionField } from './components/CaptionField';

// Module CSS
import styles from './Dropzone.module.css';
import { DtoFileImgPreview } from './FilePreview/dtoPreview';

interface SelectPreview {
  file: DtoFileImgPreview;
  index: number;
}

export function DropzoneUploader() {
  // STATE
  const [selectPreview, setSelectPreview] = useState<SelectPreview>(); //ARQUIVO ARMAZENADO
  const [selectedPreviewIndex, setSelectedPreviewIndex] = useState(0); // INDEX
  const [openFile, setOpenFile] = useState<boolean | undefined>(undefined); // INDEX
  const dispatch = useDispatch();

  const selectedChatId = useSelector(
    (state: any) => state?.chat?.chatSelectedId
  );
  const openedChat = useSelector((state: any) =>
    state?.chat?.chats?.find((chat: any) => chat?.id === selectedChatId)
  );

  const selectOrDefaultFile =
    selectPreview?.file ?? openedChat.uploadingFiles[0];

  const uploadingListHasFiles = openedChat.uploadingFiles?.length > 0;

  const { form } = useCaptionForm();
  const resetForm = form.reset;
  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: 'captions',
  });

  const watchFields = useWatch({
    control: form.control,
    name: 'captions',
  });


  // FUNCTIONS
  function handleAddCaption() {
    append({ caption: '' });
  }

  function handleRemoveCaption(index: number) {
    remove(index);
  }

  const handleSelectPreview = (select: SelectPreview) => {
    setSelectPreview({ file: select.file, index: select.index });
    setSelectedPreviewIndex(select.index);
  };

  const handleUploadFiles = useCallback(
    (files: any[]) => {
      // TODOL fix type any
      const uploadedFilesDrop = files.map((file: any) => treatObjectFile(file));
      dispatch(addUploadToList(selectedChatId, uploadedFilesDrop) as any);
    },
    [selectedChatId]
  );
  const handleRejectFiles = useCallback((files: any[]) => {
    const isPlural = files.length > 1;
    toast.warning(
      <div>
        <strong>
          {isPlural ? `Arquivos recusados!` : `Arquivo recusado!`}
        </strong>
        <br />
        <small>
          {isPlural
            ? `Não foi possível concluir o upload. Certifique-se de que os arquivos
            não ultrapassaram 30MB ou possuem um formato válido!`
            : ` Não foi possível concluir o upload. Certifique-se de que seu arquivo
            não ultrapassa 30MB ou possui um formato válido!`}
        </small>
      </div>
    );
  }, []);

  const sendFilesToMessage = useCallback(() => {
    const captions = form.getValues('captions');

    if (!canSendMessage(openedChat)) {
      return toast.error('Não foi possível enviar a mensagem!');
    }

    let dataFiles = { chat_id: selectedChatId, files: [] } as {
      chat_id: string;
      files: any[];
    };

    openedChat?.uploadingFiles?.forEach(async function (
      uploadedFile: any,
      index: number
    ) {
      dataFiles.files.push({ id: uploadedFile.id, name: uploadedFile.name });

      let message_data = mountMessage(openedChat, uploadedFile, 'media') as any;
      message_data.file_uploading_id = uploadedFile.id;

      if (openedChat.replying_message) {
        message_data.reply_message = openedChat.replying_message;
      }

      if (captions[index].caption) {
        message_data.caption = captions[index].caption;
      }

      dispatch(addMessage(message_data));
    });

    dispatch(clearReplyingMessage({ chat_id: openedChat.id }));

    dispatch(updateChatDropzone(selectedChatId, false, false));
    dispatch(sendFilesMessage(dataFiles));
  }, [openedChat, selectedChatId]);

  const handleCloseDropzoneAndClearChatFiles = useCallback(() => {
    resetForm();
    dispatch(updateChatDropzone(selectedChatId, false, true));
  }, [resetForm]);

  // EFFECTS
  useEffect(() => {
    if (openedChat.uploadingFiles.length + 1 >= watchFields.length) {
      const fileNumber = openedChat.uploadingFiles.length - watchFields.length;
      for (let i = 0; i < fileNumber; i++) {
        handleAddCaption();
      }
    }
  }, [openedChat.uploadingFiles.length]);

  // RENDER
  return (
    <div className={`dropzoneContainer`}>
      <div className={`containerDropUpload ${styles.content_flex}`}>
        {/* Upload Component */}
        <UploadV2
          onUpload={handleUploadFiles}
          onRejected={handleRejectFiles}
          selectPreview={selectOrDefaultFile}
          chatSelected={selectedChatId}
          openFile={openFile}
        />
        {/* Caption and Preview */}
        {uploadingListHasFiles && (
          <>
            <FilePreview
              files={openedChat.uploadingFiles}
              selectPreview={handleSelectPreview}
              chatSelected={selectedChatId}
              handleRemoveCaption={handleRemoveCaption}
              setSelectedPreviewIndex={setSelectedPreviewIndex}
            />
            {fields.length > 0 &&
              fields.map((field, index) => {
                if (index !== selectedPreviewIndex) return null;
                return (
                  <div key={index}>
                    <Controller
                      control={form.control}
                      name={`captions.${index}.caption`}
                      render={({ field, fieldState }) => (
                        <CaptionField
                          id={`caption-${index}`}
                          value={watchFields[index].caption}
                          onChange={field.onChange}
                          form={form}
                          setOpenFile={setOpenFile}
                          sendFilesToMessage={sendFilesToMessage}
                          uploadingListHasFiles={uploadingListHasFiles}
                        />
                      )}
                    />
                  </div>
                );
              })}
          </>
        )}

        {/* Cancel */}
        <Button
          className={styles.cancel_button}
          color="link"
          onClick={handleCloseDropzoneAndClearChatFiles}
        >
          <Icon
            icon={'poli-icon pi-close-fill'}
            color="var(--primary)"
            size={24}
          />
        </Button>
      </div>
    </div>
  );
}
