import styled from 'styled-components';

export const StatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin: 2rem 3rem;

  img {
    max-height: 75px;
  }

  h2,
  h3 {
    padding: 0 1rem;
    text-align: center;
    color: ${(props) =>
      props.color ? `var(${props.color})` : 'var(--textDefault)'};
  }

  @media (max-width: 600px) {
    margin: 0.5rem;
    gap: 0.2rem;

    h2,
    h3 {
      line-height: 1.5rem;
    }
  }
`;

export const LabelContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.3rem;

  label {
    font-weight: bold;
  }
`;

export const EmailsContainer = styled.ul`
  max-height: 250px;
  overflow-y: auto;
  /* border-top: 1px solid var(--border-color); */
  margin: 1rem 1rem;

  @media (max-width: 600px) {
    max-height: 150px;
  }
`;

export const EmailItem = styled.li`
  padding: 0.3rem 0.5rem;
  margin: 0 0.5rem;
  position: relative;
  line-height: 1.8rem;

  display: flex;
  justify-content: space-between;
  align-items: center;

  color: var(--textDefault);
  font-size: 1rem;

  transition: 0.2s;
  span > i {
    padding: 0.2rem 0.3rem;
    cursor: pointer;
    font-size: 1.5rem;
    position: absolute;
    right: 5px;
    top: 0;

    :hover {
      font-size: 1.75rem;
    }
  }
`;

export const ButtonsContainer = styled.div`
  padding: 0 15px;
  width: 100%;

  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  align-content: space-around;
  justify-content: space-evenly;
  align-items: center;

  @media (max-width: 600px) {
    flex-direction: column;
    gap: 0.3rem;
  }
`;
