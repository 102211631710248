import { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import integrationService from '../../../../services/tools/integrations';
import integrations from '../integrations-driver';
import { toast } from 'react-toastify';

export function useIntegrationService() {
  //information needed for the request
  // customer_id to integration service get route
  const customer_id = useSelector(
    (state) => state?.general?.current_customer_id
  );
  //token to validate apigateway request
  const tokenPoli = useSelector((state) => state?.auth?.access?.token);

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [integrationData, setIntegrationData] = useState({});
  const [authUrl, setAuthUrl] = useState(null);

  const validateDriver = (driverParam) =>
    Object.values(integrations).find((driver) => driver === driverParam);

  const getIntegrationData = useCallback(async (driver) => {
    if (validateDriver(driver)) {
      try {
        setIsLoading(true);
        const response = await integrationService.getIntegrationRequest({
          customer_id,
          tokenPoli,
          driver,
        });
        setIsSuccess(true);
        setIntegrationData(response);
      } catch (error) {
        setIsError(true);
        console.log('Error on hook useIntegrationService. error:', error);
      } finally {
        setIsLoading(false);
      }
    }
  }, []);

  const saveIntegrationData = useCallback(async (integrationData) => {
    if (Object.values(integrationData).length) {
      try {
        setIsLoading(true);
        const result = await integrationService.postIntegrationRequest({
          customer_id,
          tokenPoli,
          integrationData,
        });
        if (result.code == 412) {
          setIsError(true);
          setIsSuccess(false);
          toast.warning(result.message);
        } else {
          setIntegrationData(result);
          setIsSuccess(true);
          setIsError(false);
          toast.success('Integração registrada com sucesso.');
        }
      } catch (e) {
        setIsError(true);
        setIsSuccess(false);
        toast.error('Falha no servidor! Tente novamente mais tarde');

        console.error('Error on useIntegrationService hook. Error:', e);
      } finally {
        setIsLoading(false);
      }
    }
  }, []);

  const deleteIntegrationData = useCallback(async (driver) => {
    try {
      setIsLoading(true);
      await integrationService.deleteIntegrationRequest({
        customer_id,
        tokenPoli,
        driver,
      });
      //204
      setIsDeleted(true);
      toast.success('A sua integração foi removida');
    } catch (error) {
      //error not found (404) means customer doesn't have integration so delete is true
      if (error.response.status === 404) {
        setIsDeleted(true);
        toast.success('A sua integração foi removida');
      } else {
        toast.error('Falha no servidor! Tente novamente mais tarde');
        console.error('Error on useIntegrationService hook. Error:', error);
      }
    } finally {
      setIsLoading(false);
    }
  }, []);

  const genAuthUrl = useCallback(async (driver) => {
      try {
        const response = await integrationService.genAuthUrl({
          tokenPoli,
          driver
        });
        setAuthUrl(response.url);
      } catch (error) {
        console.log('Error on genAuthUrl. error:', error);
      }
    
  }, []);

  return {
    isLoading,
    isError,
    isSuccess,
    isDeleted,
    integrationData,
    getIntegrationData,
    saveIntegrationData,
    deleteIntegrationData,
    authUrl,
    genAuthUrl,
  };
}
