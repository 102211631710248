import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { validationSchema } from './validation';

export interface LocalFormProps {
  name: string;
}
export function useLocalForm(props: LocalFormProps) {
  // ---------------------------------------------
  // States
  const form = useForm({
    defaultValues: {
      name: '',
    },
    resolver: yupResolver(validationSchema(props.name)),
  });

  const name = form.watch('name');
  // ---------------------------------------------
  // Functions
  // ---------------------------------------------
  // Effects
  // ---------------------------------------------
  // Transformations
  // ---------------------------------------------
  // API

  return {
    ...form,
    watchedValue: {
      name,
    },
  };
}
