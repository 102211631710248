import React from 'react';
import { connect } from 'react-redux';
import filesize from 'filesize';

import { Button, Icon } from '@polichat/flamboyant';

import Upload from '../upload';
import FileList from '../fileList';

import { bindActionCreators } from 'redux';
import * as ChatActions from '../../store/modules/chat/actions';
import {
  mountMessage,
  treatObjectFile,
} from '../../store/modules/chat/functions';

import { toast } from 'react-toastify';

import { CustomerChannelBalanceContext } from '../chat/content/contexts/customerChannelBalance/context';

function Dropzone({
  chat,
  uploadedFiles,
  addUploadToList,
  chatSelectedId,
  updateChatDropzone,
  opennedChat,
  sendFilesMessage,
  addMessage,
  clearReplyingMessage,
}) {
  const { isUserAbleToSendMessage } = React.useContext(
    CustomerChannelBalanceContext
  );

  function handleUpload(files) {
    const uploadedFilesDrop = files.map((file) => treatObjectFile(file));

    addUploadToList(chatSelectedId, uploadedFilesDrop);
  }
  function handleRejected(rejectedFiles) {
    let qtde = rejectedFiles.length; ///para usar plural ou singular
    if (qtde > 1) {
      toast.warning(
        <div>
          <strong>Arquivos recusados!</strong>
          <br />
          <small>
            Não foi possível concluir o upload. Certifique-se de que os arquivos
            não ultrapassaram 30MB ou possuem um formato válido!
          </small>
        </div>
      );
    } else {
      toast.warning(
        <div>
          <strong>Arquivo recusado!</strong>
          <br />
          <small>
            Não foi possível concluir o upload. Certifique-se de que seu arquivo
            não ultrapassa 30MB ou possui um formato válido!
          </small>
        </div>
      );
    }
  }
  function sendFilesToMessage() {
    if (!canSendMessage())
      return toast.warning('Não foi possível enviar a mensagem!');

    let dataFiles = { chat_id: chatSelectedId, files: [] };

    uploadedFiles.forEach(async function (uploadedFile) {
      dataFiles.files.push({ id: uploadedFile.id, name: uploadedFile.name });
      let message_data = mountMessage(opennedChat, uploadedFile, 'media');
      message_data.file_uploading_id = uploadedFile.id;

      if (opennedChat.replying_message) {
        message_data.reply_message = opennedChat.replying_message;
      }

      addMessage(message_data);
    });

    clearReplyingMessage({ chat_id: opennedChat.id });

    updateChatDropzone(chatSelectedId, false, false);
    sendFilesMessage(dataFiles);
  }

  function canSendMessage() {
    if (
      opennedChat &&
      opennedChat.chat &&
      opennedChat.view &&
      opennedChat.view.selectedChannel &&
      opennedChat.contact &&
      opennedChat.contact.externals &&
      opennedChat.contact.externals.length
    ) {
      return true;
    }

    return false;
  }

  return (
    <div className="dropzoneContainer">
      {!isUserAbleToSendMessage ? (
        <div className="dropzoneContent">
          <div style={{ padding: '3rem', display: 'inline-flex' }}>
            <div
              style={{
                borderRadius: '50%',
                height: 'fit-content',
                width: 'fit-content',
                background: 'white',
              }}
            >
              <Icon
                icon="poli-icon pi-poly-sad-fill"
                color="var(--gray)"
                size={100}
              />
            </div>
            <h6 style={{ size: '1.5rem', marginLeft: '1rem' }}>
              Seu acesso atingiu o limite de créditos contratados no
              período, é necessário adicionar créditos extras ou aguardar o
              próximo mês. Entre em contato com nossa área comercial.
            </h6>
          </div>
          <Button
            color="link"
            className="closeUpload"
            onClick={() => {
              updateChatDropzone(chatSelectedId, false, true);
            }}
          >
            <Icon
              icon={'poli-icon pi-close-circle-fill'}
              color="var(--red-polichat)"
              size={50}
            />
          </Button>
        </div>
      ) : (
        <>
          <div className="dropzoneContent">
            <Upload onUpload={handleUpload} onRejected={handleRejected} />

            {uploadedFiles && uploadedFiles.length > 0 && (
              <FileList files={uploadedFiles} chatSelected={chatSelectedId} />
            )}
          </div>
          <section className="actionsContainer">
            <div className="groupActionsLeft"></div>
            <div className="groupActionsRight">
              <Button
                color="link"
                className="closeUpload"
                onClick={() => {
                  updateChatDropzone(chatSelectedId, false, true);
                }}
              >
                <Icon
                  icon={'poli-icon pi-close-circle-fill'}
                  color="var(--red-polichat)"
                  size={20}
                />
              </Button>

              <Button
                color="link"
                className="sendUpload"
                onClick={sendFilesToMessage}
                disabled={!isUserAbleToSendMessage}
              >
                <Icon
                  icon={'poli-icon pi-set-fill'}
                  size={20}
                />
              </Button>
            </div>
          </section>
        </>
      )}
    </div>
  );
}

const mapStateToProps = (state, ownProps) => ({
  chat: state.chat,
  chatSelectedId: state.chat.chatSelectedId,
  chatDropzone: state.chat.chats?.find(
    (chat) => chat.id === state.chat.chatSelectedId
  )?.chatDropzone,
  uploadedFiles: state.chat.chats?.find(
    (chat) => chat.id === state.chat.chatSelectedId
  ).uploadingFiles,
  // Retorna as informações do chat que está aberto
  opennedChat:
    state.chat?.chats?.filter((chat) => {
      return chat.open === true;
    })[0] || [],
  props: ownProps,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(ChatActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Dropzone);
