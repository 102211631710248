import styled from 'styled-components';

export const StylizedAlertMessage = styled.div`
  h1 {
    font-size: 1.75rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
    line-height: 1.5;
    text-align: center;
  }
`;

export const ModalContainer = styled.div`
  max-height: 70vh;
  overflow-y: auto;

  img {
    display: block;
    margin: 0 auto;
    padding-top: 0.5rem;
    padding-bottom: 1rem;
  }
`;
