import {
  Alert,
  DropdownButton,
  DropdownItem,
  Icon,
  TableMobile,
  UncontrolledTooltip,
} from '@polichat/flamboyant';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import WabaService from '../../../../../services/tools/template-waba/template-waba';

import Animation from '../../../../common/animation';
import {
  Container,
  OptionsButtonContainer,
  Td,
  TemplateBadge,
} from './TableMessagesBuilder.styles';

const TableMessagesBuilder = ({ cols, rows, isLoading, onOptionsClick }) => {
  const customer_id = useSelector((state) => state.general.current_customer_id);
  const [categoriesWabaList, setCategoriesWabaList] = useState([]);

  const statusAlertColor = {
    Aprovado: 'success',
    Aguardando: 'attention',
    Reprovado: 'danger',
  }

  useEffect(() => {
    async function fetchCategoriesWaba() {
      const result = await WabaService.getWabaCategories({ customer_id });
      result && setCategoriesWabaList(result);
    }
    fetchCategoriesWaba();
  }, []);

   function getCategoryWabaText(id) {
    const foundedCategory = categoriesWabaList.find(
      (category) => category.id === id
    );
    if (foundedCategory) {
      return foundedCategory.title;
    } else {
      return '';
    }
  }

  function handleEdit(data){
    onOptionsClick({
      id: data.id,
      option: 'edit',
      template_name: data.template_name,
      tag: data.tag,
    });
  }


  function handleDelete(data){
    onOptionsClick({
      id: data.id,
      option: 'delete',
      template_name: data.template_name,
      tag: data.tag,
    });
  }

  return (
    <Container>
      {isLoading ? (
        <Animation
          icon="chat-loading"
          style={{
            maxWidth: '150px',
            height: '100%',
            maxHeight: '100px',
          }}
          background="rgba(0, 0, 0, 0)"
        />
      ) : (
        <TableMobile striped hover cols={cols}>
          {rows.map((row) => (
            <tr key={row.id}>
              <td>
                <div className='tagMessagBuilder' id={`row-tag-${row.id}`}>
                  {row.tag}

                  {/* if it is template WABA, it has a template_name */}
                  {row.template_name && (
                    <UncontrolledTooltip
                      placement="bottom"
                      target={`row-tag-${row.id}`}
                    >
                      {row.template_name}
                    </UncontrolledTooltip>
                  )}
                </div>
              </td>
              <td>
                <TemplateBadge>
                  {row.template_waba && (
                    <>
                      <Icon
                        icon="poli-icon pi-whatsapp-business-line"
                        size={22}
                        color={'var(--success)'}
                      />
                      <span>Template</span>
                    </>
                  )}
                  {!row.template_waba && !row.template_name && (
                    <>
                      <Icon
                        icon="poli-icon pi-rocket-fill"
                        size={20}
                        color={'var(--color-primary)'}
                      />
                      <span>M. Rápida</span>
                    </>
                  )}
                  {!row.template_waba &&
                    row.template_name &&
                    row.template_name.indexOf('list') > -1 && (
                      <>
                        <Icon
                          icon="poli-icon pi-request-line"
                          size={20}
                          color={'var(--color-primary)'}
                        />
                        <span>Lista </span>
                      </>
                    )}
                </TemplateBadge>
              </td>
              <td>{row.category && getCategoryWabaText(row.category)}</td>
              <Td title={row.text}>{row.text}</Td>
              <td>
                {row.keyboard_shortcut && (
                  <Alert fade={false} color="light">
                    {row.keyboard_shortcut}
                  </Alert>
                )}
              </td>
              <td>
                {row.template_status && (
                  <Alert color={statusAlertColor[row.template_status]}>
                    {row.template_status}
                  </Alert>
                )}
              </td>
              <td>
                <DropdownButton
                  color="neutral"
                  icon="poli-icon pi-bullet-fill"
                  iconSize={22}
                  direction="left"
                >
                  <OptionsButtonContainer>
                    <DropdownItem
                      className="OptionEdit"
                      onClick={() => handleEdit(row)}
                    >
                      <Icon
                        icon="poli-icon pi-pencil-line"
                        size={22}
                        position={'relative'}
                        right={'10px'}
                      />
                      <span>Editar</span>
                    </DropdownItem>
                    <DropdownItem
                      className="OptionDelete"
                      onClick={() => handleDelete(row)}
                    >
                      <Icon
                        icon="poli-icon pi-delete-line"
                        size={22}
                        color={'var(--danger)'}
                        position={'relative'}
                        right={'10px'}
                      />
                      <span>Excluir</span>
                    </DropdownItem>
                  </OptionsButtonContainer>
                </DropdownButton>
              </td>
            </tr>
          ))}
        </TableMobile>
      )}
    </Container>
  );
};

export default TableMessagesBuilder;
