import { IllustrationContainer } from './styles';

export interface IllustrationProps {
  imgName: string;
  alt: string;
}
//O nome que vem por props.imgName deve ser estilizado no css global App.css ou no css do revendedor whitelabel
export function Illustration(props: IllustrationProps) {
  return (
    <IllustrationContainer>
      <i className={props.imgName} />
    </IllustrationContainer>
  );
}
