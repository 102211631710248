import DocumentHeaderPreview from '../../../../../../../tools/messageBuilder/preview/DocumentHeaderPreview.component';
import { useQuickMessage } from '../../hooks/useQuickMessage';
import { QuickMessageTemplate } from '../../types/types';

import styles from '../Modal/ModalMessagePreview.module.css';

export interface PreviewHeaderProps {
  header: QuickMessageTemplate['header'];
}
export function PreviewHeader({ header }: Readonly<PreviewHeaderProps>) {
  const { getMessageTextUI } = useQuickMessage();
  if (header.type === 'text')
    return (
      <div>
        <span className={styles.header_text}>
          {getMessageTextUI(header.text, 'quick')}
        </span>
      </div>
    );
  // mediaUrl is necessary from here onwards, so if it's invalid, render nothing
  if (!header?.mediaUrl.length) return <></>;
  // Header of type media
  switch (header.mediaFormat) {
    case 'IMAGE':
      return (
        <div className={styles.header_media}>
          <img src={header.mediaUrl} alt="" loading="lazy" />
        </div>
      );
    case 'VIDEO':
      return (
        <div className={styles.header_media}>
          <video loop autoPlay={true} muted src={header.mediaUrl} />{' '}
        </div>
      );
    case 'DOCUMENT':
      return (
        <div className={styles.header_media}>
          <DocumentHeaderPreview header={header} />
        </div>
      );
    default:
      return (
        <div>
          <span className={styles.header_text}>Mídia inválida</span>
        </div>
      );
  }
}
