import { getServicesImportSLInLaravel, getServicesInLaravel } from '../../../services/admin/services/services';

function modelServices(services) {

  if(services && services instanceof Array){
    return services;
  }

  return [];
}

function modelServicesSl(servicesSl) {

  let tempServicesSl = [];

  servicesSl.forEach((serviceSl) => {
    tempServicesSl.push({
        name:serviceSl?.st_descricao_prd,
        description: serviceSl?.st_descricao_prd,
        value:serviceSl?.vl_unitario_prd ? serviceSl?.vl_unitario_prd : 0,
        status: 1,
        idSuperlogica: Number(serviceSl?.id_produto_prd),
      });
  });

  return tempServicesSl;
}

async function getServices() {
  const response = await getServicesInLaravel();
  const services = response?.data;
  return modelServices(services);
}

async function getService(id) { // TODO criar um controller no backend para fazer este serviço
  const response = await getServicesInLaravel();
  const services = response?.data;
  return modelServices(services).find(service => String(service.id) === String(id));
}

async function getServicesImportSL() {
  const response = await getServicesImportSLInLaravel();
  const servicesSl = response?.data;
  return modelServicesSl(servicesSl);
}

export {
  getServices,
  getService,
  getServicesImportSL,
  modelServicesSl
};
