import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Icon, Alert } from '@polichat/flamboyant';
import Animation from '../../common/animation';

import history from '../../../services/history';
import integrations from './integrations-driver';

import PoliNectarNewPoli from '../../../assets/images/newIdentity/Poli_Nectar.svg';
import PoliRDSNewPoli from '../../../assets/images/newIdentity/Poli_RDS.svg';
import PoliZAPINewPoli from '../../../assets/images/newIdentity/Poli_ZAPI.svg';
import PoliZAPIWNewPoli from '../../../assets/images/newIdentity/Poli_ZAPI_white.svg';
import AppsIntegrationNewPoliImg from '../../chat/content/chat/modals/src/AppsIntegration';

import PageMainHeader from '../../common/pages/PageMainHeader/PageMainHeader.component';
import PageMainBody from '../../common/pages/PageMainBody/PageMainBody.component';

import { ModalHireAdditionalChannel } from '../channels/AddChannel/ModalHireAdditionalChannel';

import { useCustomerVerifyChannelPlan } from '../channels/AddChannel/useCustomerVerifyChannelPlan';

import {
  Container,
  ListContainer,
  ListItem,
  IntegrationAnimation,
} from './IntegrationsList.styles';
import ZapiModal from './zapiModal';
import { useWhiteLabel } from 'src/hooks/useWhiteLabel';
import { IconPlusIcon } from 'src/assets/illustratorElement/iconPlusIcon/iconPlusIcon';

function IntegrationsList() {
  const [isZapiModalOpen, setIsZapiModalOpen] = useState(false);
  const [isFreeTrial, setIsFreeTrial] = useState(false);

  const customerId = useSelector((state) => state.general.current_customer_id);

  const matchCustomer = useSelector((state) => {
    const getCustomers = state.general.user.customers
    return getCustomers.find(element => element.id == customerId);
  });

  const pushIntegratorConfig = (integrator) => {
    history.push({
      pathname: `/tools/integrations/${integrator}`,
    });
  };

  const ZAPI_ADD_CHANEL_SPA_URL = `/tools/channels/add/broker`;

  const pushZapiIntegration = () => {
    history.push({
      pathname: ZAPI_ADD_CHANEL_SPA_URL,
      state: {
        validated_can_add_channel: true,
      },
    });
    // window.location.replace(
    //   `${poliConfig.poliAppLaravelUrl}/channels/${customerId}/whatsapp/create`
    // );
  };

  /**
   * additional identifier param
   * DOCS: https://poli-digital.atlassian.net/browse/PS-1697
   */
  const ZAPI_ADDITIONAL_CHANNEL_ID = 2;

  const currentCustomer = useSelector(
    (state) => state?.general?.current_customer_id
  );

  const [
    isModalHireChannelAdditionalOpen,
    setIsModalHireChannelAdditionalOpen,
  ] = useState(false);

  const {
    loading: loadingCustomerChannelPlanInfo,
    error: errorVerifyCustomerChannelPlanInfo,
    channelPlanInfo,
    blocked,
    verifyCustomerCanAddChannel,
  } = useCustomerVerifyChannelPlan();

  async function handleVerifyAdditionalWhatsappChannelIntegration() {
    const {
      should_buy: customerShouldBuyPlan,
      error,
      blocked: customerAreBlockedByOverDue,
    } = await verifyCustomerCanAddChannel(
      currentCustomer,
      ZAPI_ADDITIONAL_CHANNEL_ID
    );

    //customer inadimplente
    if (customerAreBlockedByOverDue) {
      setIsModalHireChannelAdditionalOpen(true);
      return;
    }

    //verificar se precisar comprar adicional ao plano antes de criar canal
    if (customerShouldBuyPlan) {
      setIsModalHireChannelAdditionalOpen(true);
    } else if (!error) {
      handleOpenZapiModal();
      // pushZapiIntegration();
    }
  }
  const handleOpenZapiModal = () => {
    setIsZapiModalOpen(true);
    const freeTrial = matchCustomer.status === 3;
    setIsFreeTrial(freeTrial);
  };

  const handleCloseZapiModal = () => {
    setIsZapiModalOpen(false);
  };

  const closeHireModalAndOpenZapiModal = () => {
    handleOpenZapiModal();
  };

  const [isDarkMode, setDarkMode] = useState(() => {
    // getting stored value
    const saved = JSON.parse(localStorage.getItem('@polichat/darkmode'));
    const initialValue = saved;
    return initialValue || false;
  });

  const isWhiteLabel = useWhiteLabel();

  const integrationLogoNectarWl = useSelector((state) => state?.whitelabel?.config?.logo_integration_Nectar);
  const integrationLogoRDSWl = useSelector((state) => state?.whitelabel?.config?.logo_integration_RDS);
  const integrationLogoZAPIWl = useSelector((state) => state?.whitelabel?.config?.logo_integration_ZAPI);

  const darkModePoliZAPI = PoliZAPIWNewPoli;
  const lightModePoliZAPI = PoliZAPINewPoli;
  return (
    <>
      <PageMainHeader
        title="Integrações"
        icon="poli-icon pi-integrations-line"
      />
      <PageMainBody>
        <Container>
          {loadingCustomerChannelPlanInfo && (
            <div style={{ margin: '1rem' }}>
              <Alert color="info" style={{ padding: '0.5rem' }}>
                <Animation icon="loading" size="searchTiny" />
                <span
                  style={{ flexGrow: 2, margin: 'auto', marginLeft: '1rem' }}
                >
                  Carregando informações sobre o seu plano!
                </span>
              </Alert>
            </div>
          )}
          {!loadingCustomerChannelPlanInfo &&
            errorVerifyCustomerChannelPlanInfo && (
              <div style={{ margin: '1rem' }}>
                <Alert color="danger" style={{ padding: '0.5rem' }}>
                  <Icon icon="poli-icon pi-sad-line" size={40} />
                  <span
                    style={{ flexGrow: 2, margin: 'auto', marginLeft: '1rem' }}
                  >
                    Erro ao buscar informações sobre o seu plano, tente
                    novamente!
                  </span>
                </Alert>
              </div>
            )}
          <ZapiModal
            isDarkMode={isDarkMode}
            isOpen={isZapiModalOpen}
            isFreeTrial={isFreeTrial}
            onClose={handleCloseZapiModal}
            onSubmit={pushZapiIntegration}
          />

          <ListContainer>
            <ListItem>
              <IconPlusIcon
                firstIcon="onlyLogo"
                secondIcon="nectarIcon"
                secondColor="var(--success)"
              />
              {/* <img src={integrationLogoNectarWl ? integrationLogoNectarWl : PoliNectarNewPoli} /> */}
              <span>
                Gerencie seus contatos, oportunidades diretamente no CRM Nectar.
              </span>
              <Button
                gradient="primary"
                onClick={() => pushIntegratorConfig(integrations.NECTAR)}
              >
                <Icon icon="poli-icon pi-e-settings-line" size={22} />{' '}
                Configurações
              </Button>
            </ListItem>
            <ListItem>
              <IconPlusIcon
                firstIcon="onlyLogo"
                secondIcon="rdStationIcon"
                secondColor="var(--success)"
              />
              {/* <img src={integrationLogoRDSWl ? integrationLogoRDSWl : PoliRDSNewPoli} /> */}
              <span>
                Mantenha a comunicação com seus Contatos do RD Station
              </span>
              <Button
                gradient="primary"
                onClick={() => pushIntegratorConfig(integrations.RDSTATION)}
              >
                <Icon icon="poli-icon pi-e-settings-line" size={22} />{' '}
                Configurações
              </Button>
            </ListItem>
            {isWhiteLabel.spaTitle !== 'NectarCRM' && (
              <ListItem>
                <IconPlusIcon
                firstIcon="onlyLogo"
                secondIcon="zApiIcon"
                secondColor="var(--success)"
              />
                {/* <img src={integrationLogoZAPIWl ? integrationLogoZAPIWl : (isDarkMode ? darkModePoliZAPI : lightModePoliZAPI)} /> */}
                <span>Integre seu número com a Z-API.</span>

                <Button
                  gradient="primary"
                  onClick={handleVerifyAdditionalWhatsappChannelIntegration}
                >
                  <Icon icon="poli-icon pi-e-settings-line" size={22} /> Integrar
                  Canal
                </Button>
              </ListItem>
            )}
          </ListContainer>
          <IntegrationAnimation>
            <AppsIntegrationNewPoliImg />
            <h2>Em breve mais integrações para você aqui {!isWhiteLabel.isWl && 'na Poli'}</h2>
          </IntegrationAnimation>
        </Container>
      </PageMainBody>

      {/* MODAL TO HIRE ADDITIONAL CHANNEL */}
      {isModalHireChannelAdditionalOpen && (
        <ModalHireAdditionalChannel
          modalIsOpen={isModalHireChannelAdditionalOpen}
          toggleModal={() =>
            setIsModalHireChannelAdditionalOpen((prev) => !prev)
          }
          channelPlanInfo={channelPlanInfo}
          closeModalAction={closeHireModalAndOpenZapiModal}
          customerId={currentCustomer}
          blocked={blocked}
        />
      )}
    </>
  );
}

export default IntegrationsList;
