import { useState, useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';

import isEmailValid from '../../../../../../utils/isEmailValid';

import paymentConfig from '../../../../../../services/shopping/payment/paymentConfig';

const MESSAGE = {
  NOTFOUND: 'Houve um erro ao buscar os seus dados de pagamento. Caso o erro persista, entre em contato com o suporte.',
  NOTSAVED: 'Houve um erro ao salvar as configurações de pagamento. Tente novamente. Caso o erro persista, entre em contato com o nosso suporte.'
}

export default function usePagSeguroConfig(){
  const [email, setEmail] = useState('');
  const [token, setToken] = useState('');
  const [picture, setPicture] = useState('');
  const [customer_name, setCustomerName] = useState('');
  const [maxInstallmentNoInterest, setMaxInstallmentNoInterest] = useState(0);
  const [includeInstallment, setIncludeInstallment] = useState(true);
  const [isRequestLoading, setRequestLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(true);

  const handleEmail = useCallback((email) => {
    setEmail(email);
  },[]);
  const handleToken = useCallback((token) => {
    setToken(token)
  },[]);
  const handlePicture = useCallback((picture) => {
    setPicture(picture)
  },[]);
  const handleCustomerName = useCallback((customerName)=>{
    setCustomerName(customerName)
  },[]);
  const handleIncludeInstallment = useCallback((includeInstallment) => {
    setIncludeInstallment(includeInstallment)
  },[]);
  const handleMaxInstallmentsNoInterest = useCallback((installments) => {
    setMaxInstallmentNoInterest(installments)
  },[]);

  const setFailureAlert = (text) => {
    toast.error(`${text}`);
  };

  const handleSubmit = useCallback(async ()=>{
    //Validate email
    if(!isEmailValid(email)){
      toast.warning('Insira um e-mail válido')
      return
    }
    //Validate token
    if(!token.length){
      toast.warning('Insira o token')
      return
    }
    //Validate customer name
    if(!customer_name){
      toast.warning('Insira o nome que irá aparecer na página de checkout')
      return
    }

    //Validate picture url
    if(picture && !picture.substr(-4).match(/(\.jpg|\.png|jpeg)(\?.+)?/gi)){
      toast.warning(
        <p>
          <span><strong>Link inválido. 😢</strong></span>
          <br/>
          Verifique se o seu link esta com
          <strong>.jpg</strong> ou <strong>.png</strong> no final, como no
          exemplo: <br />
          <strong style={{ color: 'var(--warning)' }}>
            https://img.techpowerup.org/200506/mesa-centro.jpg
          </strong>
        </p>,
        { autoClose: 6000 }
      )
      return
    }

    try {
      setRequestLoading(true);
      let paramsGeneral = {
        email,
        token,
        picture,
        customer_name,
        includeInstallment,
      };
      let paramsInstallments = {
        max_installments_no_interest: maxInstallmentNoInterest,
      }
      let result = await paymentConfig.storeConfig(paramsGeneral);
      if(result.data?.success && Number(maxInstallmentNoInterest) !== 0){
        try{
          await paymentConfig.storeConfig(paramsInstallments);
          toast.success('Configurações de pagamento salvas com sucesso!');
        }catch(e){
          throw Error('fail installmensts', e)
        }

    }
  }catch (error) {
      setFailureAlert(MESSAGE.NOTSAVED);
      console.error('Error on save PagSeguro configs: ', error)
    }finally{
      setRequestLoading(false);
    }

  },[email, token, customer_name, picture, maxInstallmentNoInterest, includeInstallment]);

  useEffect(() => {
    setIsPageLoading(true);
    paymentConfig
      .fetchConfig()
      .then(function (result) {
        if (result && result.data && result.data.email && result.data.token) {
          setEmail(result.data.email);
          setToken(result.data.token);
          setCustomerName(result.data.customer_name);
          if (result.data.picture) setPicture(result.data.picture);
          if (result.data.max_installments_no_interest) setMaxInstallmentNoInterest(result.data.max_installments_no_interest)
          result.data.include_installment === 0 ? setIncludeInstallment(false) : setIncludeInstallment(true);
        }
      })
      .catch(function (err) {
        setFailureAlert(MESSAGE.NOTFOUND);
      })
      .finally(function () {
        setIsPageLoading(false);
      });
  }, []);

  return {
    isRequestLoading,
    isPageLoading,
    email,
    token,
    picture,
    customer_name,
    includeInstallment,
    maxInstallmentNoInterest,
    handleEmail,
    handleToken,
    handlePicture,
    handleCustomerName,
    handleIncludeInstallment,
    handleMaxInstallmentsNoInterest,
    handleSubmit,
  }
}
