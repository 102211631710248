import { useState, useCallback, useEffect } from 'react';

import {
  initialError,
  initialSearchParams,
  paginationInfoInitialValues,
} from './initialValues';
import {
  PoliAdsMetricItem,
  SearchParams,
  PaginationInfo,
  ValidationError,
} from './types';

import { AdService } from '../../../../../services/meta-ads/ad';
import {
  getPaginationInfoFromServiceResponse,
  getPaginationServiceFormatFromSearchParams,
  getPoliAdsMetricFromDtoMetaAdsMetricData,
} from '../../helpers';
import { LocalFormValues } from '../metricsFilters/useLocalForm/types';
import { EnumMetricType } from '../../../../../services/meta-ads/dto/dtoMetaAdsMetricData';
import { PostService } from '../../../../../services/meta-ads/post';

export interface UseMetaAdMetricServiceProps {
  organization_id?: string;
}

export interface AdsMetricsResults {
  items: PoliAdsMetricItem[];
  paginationInfo: PaginationInfo;
  filters: LocalFormValues;
}

export function useMetaAdMetricService(props: UseMetaAdMetricServiceProps) {
  const [data, setData] = useState<AdsMetricsResults>();
  const [error, setError] = useState<ValidationError>(initialError);
  const [loading, setLoading] = useState(true);
  const [params, setParams] = useState<SearchParams>(initialSearchParams);

  // ---------------------------------------------
  // Functions
  const mountResultData = useCallback((response: any) => {
    const _poliAdMetric = getPoliAdsMetricFromDtoMetaAdsMetricData(
      response.data
    );

    const _pagination = getPaginationInfoFromServiceResponse(response);

    const _filters = { type: params.type };

    setData({
      items: _poliAdMetric,
      paginationInfo: _pagination,
      filters: _filters,
    });

    clearErrors();
  }, []);

  const handleErrorResult = useCallback((response: any) => {
    const axiosError: any = response;

    if (axiosError.response?.status === 404) {
      setError({
        hasError: true,
        message: 'Você não possui PoliAds',
      });
    }

    if (
      axiosError.response?.status === 400 ||
      axiosError.response?.status === 500
    ) {
      setError({
        hasError: true,
        message:
          'Falha no servidor! Tente novamente em instantes ou contacte o suporte.',
      });
    }

    setData(undefined);
  }, []);

  const clearErrors = useCallback(() => {
    setError(initialError);
  }, []);

  const search = useCallback(
    async (organization_id: string, params: SearchParams) => {
      try {
        const { paginationInfo, type } = params;
        if (!type) return;

        setLoading(true);

        const _params =
          getPaginationServiceFormatFromSearchParams(paginationInfo);

        let response;
        if (params.type === EnumMetricType.AD) {
          response = await AdService.ListMessageMetric(
            organization_id,
            _params
          );
          mountResultData(response);
        }

        if (params.type === EnumMetricType.POST) {
          response = await PostService.ListPostMetrics(
            organization_id,
            _params
          );
          mountResultData(response);
        }

        setLoading(false);
      } catch (error) {
        handleErrorResult(error);
      }
    },
    []
  );

  const setMetricFilter = useCallback((filters: LocalFormValues) => {
    setParams({
      type: filters.type,
      paginationInfo: paginationInfoInitialValues,
    });
  }, []);

  const setPage = useCallback((page: number) => {
    setParams((prev) => ({
      ...prev,
      paginationInfo: {
        ...prev.paginationInfo,
        page,
      },
    }));
  }, []);

  // ---------------------------------------------
  // Effects

  useEffect(() => {
    if (!props?.organization_id && !params) return;
    search(props.organization_id!, params);
  }, [props?.organization_id, search, params]);

  // ---------------------------------------------
  // Transformations
  // ---------------------------------------------
  // API

  return {
    loading,
    data,
    error,
    setMetricFilter,
    setPage,
  };
}
