import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Icon,
} from '@polichat/flamboyant';

function DeleteTemplateModal({
  isOpen,
  onCloseRequest,
  data,
  onDeleteRequest,
}) {
  const { id, template_name, tag } = data;
  function handleDeleteClick() {
    onDeleteRequest(id ? id : template_name);
    onCloseRequest();
  }

  const styles = {
    title: {
      fontSize: '3rem',
      margin: 'auto',
      fontWeight: '700',
      overflow: 'hidden',
    },
    attention: {
      fontSize: '1.75rem',
      fontWeight: '700',
      marginLeft: '10px',
      textAlign: 'center',
    },
    warning: {
      fontSize: '0.8rem',
      fontWeight: '700',
      color: 'var(--danger)',
    },
  }

  return (
    <Modal isOpen={isOpen} toggle={onCloseRequest}>
      <div>
        <h5 style={styles.attention}>Atenção!</h5>
        <ModalHeader toggle={onCloseRequest}>
          <Icon
            icon="poli-icon pi-delete-fill"
            size={40}
            color={`var(--danger)`}
          />
          <span style={styles.title}>{tag}</span>
        </ModalHeader>
      </div>
      <ModalBody>
        <span>Exclusão permanente</span>
        <p>Tem certeza de que deseja prosseguir com a exclusão?</p>
        <p style={styles.warning}>Essa ação não pode ser desfeita.</p>
      </ModalBody>
      <ModalFooter>
        <Button outline onClick={onCloseRequest} size="lg">
          Voltar
        </Button>
        <Button color="danger" size="lg" onClick={handleDeleteClick}>
          Deletar
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default DeleteTemplateModal;
