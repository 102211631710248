import List from './List';
import Filter from './filter/Filter';
import AvailableUser from './available-user';
import { Loading } from '../loading';
import { connect } from 'react-redux';
import { useRef, useState } from 'react';
import NewTabs from './NewTabs';
import { CustomerContactDayAlert } from '../content/chat/customerContactDayAlert';
import { CustomerChannelBalanceProvider } from '../content/contexts/customerChannelBalance/provider';
import { CustomerHasPaymentIntegratorProvider } from '../content/contexts/customerHasPaymentIntegrator/provider';
import { useWhiteLabel } from '../../../hooks/useWhiteLabel';

function UnReadsPanel({ isLoading, opennedChat, chat, settings }) {
  const [searching, setSearching] = useState(false);
  const containerRef = useRef(null);

  const isWL = useWhiteLabel().isWl;

  return (
    <>
      <CustomerChannelBalanceProvider
        contactId={chat?.id}
        channelCustomerId={chat?.view?.selectedChannel?.id}
      >
        <CustomerHasPaymentIntegratorProvider
          customer_id={settings.customer_id}
        >
          <div className="chatPanelContainer" id="chatPanelContainer">
            <div className="chatPanelContainerHeader">
              {!isWL && (
                <CustomerContactDayAlert
                  channelId={Number(opennedChat?.view?.selectedChannel?.id)}
                />
              )}
              <NewTabs />
            </div>
            <Filter screen={'noread'} onSearch={setSearching} />
            <AvailableUser />
            <div className="panel-divider"></div>
            <div
              className="chatListContainerMaster"
              id="chatListContainerMaster"
              ref={containerRef}
            >
              {isLoading || searching ? (
                <div style={{ height: '150px' }}>
                  <Loading text="Carregando conversas..." />
                </div>
              ) : (
                <List whoGetList="unreads" container={containerRef} />
              )}
            </div>
          </div>
        </CustomerHasPaymentIntegratorProvider>
      </CustomerChannelBalanceProvider>
    </>
  );
}

const mapStateToProps = (state) => ({
  opennedChat:
    state.chat?.chats?.filter((chat) => {
      return chat.open === true;
    })[0] || [],
  chat: state.chat?.chats?.find((chat) => chat.open === true),
  settings: state.chat?.config?.general?.settings,
});

export default connect(mapStateToProps)(UnReadsPanel);
