import { isMobile } from 'react-device-detect';
import styled from 'styled-components';

export const Content = styled.div`
  display: inline-block;
  border-radius: 5px;
  border: 1px solid var(--borderDefault);
  color: var(--textDefault);
  background-color: var(--primary-background-color);
  width: ${isMobile ? '100%' : '350px'};

  & .dropdown {
    display: flex;

    & button#select_custumers {
      display: flex;
      align-items: center;
      justify-content: space-between;
      overflow: hidden;
      white-space: nowrap;
      flex: 1;
      border-color: var(--border-color-dark);
    }
  }

  & .dropdown-menu {
    width: 100%;
    & .list-containner {
      margin: 5px 10px 15px;
    }
  }

  .select-customer-dropdown {
    max-height: 15rem;
    overflow-y: auto;
    width: 100%;
  }

  .select-customer-dropdown span.departmentDropdown,
  .dropDownMenu-filters span {
    line-height: 1.3em;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 0.8em;
    max-width: 135px;
    white-space: nowrap;
  }
`;
