import { SignJWT } from 'jose';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Animation from 'src/components/common/animation';

export function CompanySummary() {
  const [urlIframe, setUrlIframe] = useState('');
  const darkmode = localStorage.getItem('@polichat/darkmode') === 'true';
  const customer_id = useSelector(
    (state: any) => state.general?.current_customer_id
  );
  const user = useSelector((state: any) => state.general?.user);
  const departments = useSelector(
    (state: any) => state.general?.user?.departments
  );
  const userDepartments = departments?.map((dep: { id: number }) => {
    return dep.id;
  });
  const metabaseSiteUrl = process.env?.REACT_APP_METABASE_SITE_URL;
  const metabaseSecretKey = process.env?.REACT_APP_METABASE_SECRET_KEY;

  const date = new Date();
  date.setSeconds(date.getSeconds() + 600);
  // token expira em 10 minutos
  const time = Math.floor(date.getTime() / 1000);

  // Gerar url com token
  const generateUrltoIframe = async () => {
    const secretKey = new TextEncoder().encode(metabaseSecretKey);
    const alg = 'HS256';
    const wichDashboard = 9;
    const tokenExpiresIn = '10m';
    const payload = {
      resource: { dashboard: wichDashboard },
      params: {
        customer_id: customer_id,
        user_ids: user?.superRole === 'agent' ? [user?.id.toString()] : null,
        department_ids:
          user?.superRole === 'supervisor' ? userDepartments : null,
      },
      exp: time,
      dashboard: {
        '9': {
          exporting: true,
          csv: true,
          pdf: true,
        },
      },
    };
    const token = await new SignJWT(payload)
      .setProtectedHeader({ alg })
      .setIssuedAt()
      .setExpirationTime(tokenExpiresIn)
      .sign(secretKey);

    const iframeUrl = `${metabaseSiteUrl}/embed/dashboard/${token}${
      darkmode ? '#theme=night&bordered=true&titled=true' : ''
    }`;
    setUrlIframe(iframeUrl);
  };

  // Quando carregar as envs e os detalhes do usuario, será gerado a url
  useEffect(() => {
    if (
      metabaseSiteUrl &&
      metabaseSecretKey &&
      user &&
      userDepartments &&
      customer_id
    ) {
      generateUrltoIframe();
    }
  }, [metabaseSiteUrl, metabaseSecretKey, user, userDepartments, customer_id]);

  return (
    <>
      {urlIframe === '' ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
          }}
        >
          <Animation
            icon="chat-loading"
            style={{
              border: '6px solid transparent',
              borderColor: 'var(--background-spinner)',
              borderTopColor: 'var(--green-polichat)',
            }}
          />
        </div>
      ) : (
        <iframe
          src={urlIframe}
          width="100%"
          height="100%"
          allowTransparency
          title="Metabase Dashboard"
        />
      )}
    </>
  );
}
