import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify'

import history from '../../../services/history';

import { useIntegrationService } from './useIntegrationService';

import Animation from '../../common/animation';
import NectarIntegration from './nectar/NectarIntegration.component';
import RDStationIntegration from './rdstation/RDStationIntegration.component';
import { useEffect } from 'react';

function IntegrationSelector() {
  const { integrator, action } = useParams();
  const { isLoading, isError, integrationData, getIntegrationData } = useIntegrationService();

  useEffect(()=>{
    getIntegrationData(integrator);
  },[]);

  useEffect(()=>{
    if(isError){
      toast.error('Erro ao conectar, tente novamente mais tarde');
      history.push('/tools/integrations');
    }
  }, [isError])

  if(isLoading){
    return <Animation icon="loading" />
  }

  switch(integrator)
  {
    case "nectarcrm":
      return <NectarIntegration data={integrationData} />
    case "rdstation":
      return <RDStationIntegration data={integrationData} action={action}/>
  }
  
}

export default IntegrationSelector;
