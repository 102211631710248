import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import SelectCustomer from '../../../admin/plans/Credit/components/SelectCustomer/SelectCustomer.component';
import PageMainBody from '../../../common/pages/PageMainBody/PageMainBody.component';
import PageMainHeader from '../../../common/pages/PageMainHeader/PageMainHeader.component';
import { FilterLogs } from './FilterLogs';
import { ResultLogs } from './ResultLogs';
import { useAllChannels } from './ResultLogs/search/useAllChannels';
import { useAllUsers } from './ResultLogs/search/useAllUsers';
import { useLogsChannelsService } from './ResultLogs/search/useLogsChannelsService';
import { Container } from '@polichat/flamboyant';
import { getCustomer } from './hooks/getCustomer';

export function LogsChannel() {
  const customer_id = useSelector(
    (state: any) => state.general.current_customer_id
  );
  const isUserAdmin = useSelector(
    (state: any) => state.general.user.superRole === 'adm'
  );
  const [customerFilter, setCustomerFilter] = useState(customer_id);
  const [customers, setCustomers] = useState();

  const service = useLogsChannelsService({
    customerFilter,
  });

  useEffect(() => {
    if (isUserAdmin) {
      getCustomer({ setCustomers });
    }
  }, []);

  const serviceAllChannels = useAllChannels({
    customerFilter,
  });

  const serviceAllUsers = useAllUsers({
    customerFilter,
  });

  return (
    <>
      <PageMainHeader
        title={'Logs do canal'}
        icon="poli-icon pi-channels-line"
        iconSize={undefined}
      >
        {isUserAdmin && (
          <SelectCustomer
            customers={customers ?? []}
            value={customerFilter ?? []}
            onChange={setCustomerFilter}
          />
        )}
      </PageMainHeader>

      <PageMainBody>
        <Container fluid>
          <FilterLogs
            onSubmit={(values) => service.setFilters(values)}
            channelList={serviceAllChannels}
            customerFilter={customerFilter}
            userList={serviceAllUsers}
          />
          <ResultLogs
            onPageChange={service.setPage}
            items={service.data}
            loading={service.loading}
          />
        </Container>
      </PageMainBody>
    </>
  );
}
