import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import api from '../../../../../services/api';
import { ChangeUserStatus } from '../../../../../store/modules/general/actions';

import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from '@polichat/flamboyant';
import { useFlags } from 'flagsmith/react';

interface UserStatusProps {
  miniStatusUser: (status?: number) => void
}

const enumStatus = [
  {
    id: 1,
    name: 'Disponível',
    description: 'Na fila para receber novos contatos',
    color: 'var(--color-success)',
    show: true,
  },
  {
    id: 0,
    name: 'Desconectado',
    description: '',
    color: 'var(--danger)',
    show: false,
  },
  {
    id: -1,
    name: 'Indisponível',
    description: 'Você não receberá novos contatos',
    color: 'var(--color-warning)',
    show: true,
  },
];

const UserStatus = (props?: UserStatusProps) => {
  // @ts-ignore
  const user = useSelector((state) => state.general.user);
  // @ts-ignore
  const customer_id = useSelector((state) => state.general.current_customer_id);

  const [status, setStatus] = useState<number>();

  const dispatch = useDispatch();

  const onChangeUserStatus = async (_status: number) => {
    try {
      if (_status && status != _status) {
        const params = {
          status: _status,
          customer_id,
          user: user.id
        };

        await api.changeUserStatusLogged(params);
      }
      setStatus(_status);
      dispatch(ChangeUserStatus(_status));
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (user?.status >= -1) {
      setStatus(user.status);
    }
  }, [user?.status]);

  if (props?.miniStatusUser) {
    props?.miniStatusUser(status);
  }

  const getEnumStatusById = (id: number) => {
    return enumStatus.find((e) => e.id === id);
  };

  return (
    <div className="userStatusTopNav">
      <UncontrolledDropdown className="change-users-status">
        <DropdownToggle
          color="neutral"
          caret
          id="userStatus"
          name="userStatus"
          value={status}
        >
          {Number.isInteger(status) && status !== undefined ? (
            <>
              <div
                className="statusUserIconNav"
                style={{ backgroundColor: getEnumStatusById(status)?.color }}
              ></div>
              <span className="nameusersstatus">
                {getEnumStatusById(status)?.name}
              </span>
            </>
          ) : (
            <span className="nameusersstatus" style={{ fontStyle: 'italic' }}>
              Carregando...
            </span>
          )}
        </DropdownToggle>

        <DropdownMenu className="userStatusContainer">
          {enumStatus
            .filter((e) => e.show)
            .map((item, key) => {
              return (
                <div style={{ width: '150px' }} key={key}>
                  <DropdownItem
                    key={key}
                    className="userStatusOptions"
                    value={item.id}
                    onClick={() => {
                      onChangeUserStatus(item.id);
                    }}
                  >
                    <div>
                      <div
                        className="statusUserIconNav"
                        style={{ backgroundColor: item.color }}
                      ></div>
                    </div>

                    <div>
                      <span className="userStatusName">{item.name}</span>
                      <p className="userStatusDescription">
                        {item.description}
                      </p>
                    </div>
                  </DropdownItem>
                </div>
              );
            })}
        </DropdownMenu>
      </UncontrolledDropdown>
    </div>
  );
};

export { UserStatus };
