import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as ShoppingProductsList from '../../../../store/modules/shopping/products-list/actions';
import * as ShoppingProductsManage from '../../../../store/modules/shopping/products-manage/actions';

import history from '../../../../services/history';
import { priceMask } from '../../../../utils/priceMask';

import Animation from '../../../common/animation';
import CancelButton from '../../common/CancelButton';
import DeleteButton from '../../common/DeleteButton';
import PageTitle from '../../common/PageTitle';

import ProductImage from '../../../../assets/images/open-box.png';

import TablePagination from '../../TablePagination';

import ModalStatus from '../../../common/modals/ModalStatus';
import ModalConfirmDelete from '../../../common/modals/ModalConfirmDelete';

import {
  Button,
  Icon,
  Col,
  Row,
  Input,
  CustomInput,
  InputGroup,
  InputGroupAddon,
  Label,
  Form,
  FormGroup,
  Table,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
  Card,
  CardBody,
  CardImg,
  CardTitle,
  CardText,
  CardSubtitle,
  Alert,
} from '@polichat/flamboyant';

function ProductList({
  //store values
  //products list
  products,
  pagination,
  isLoadingProducts,
  error,
  productsRequestStatus,
  //product manage
  productRequestStatus,
  categories,
  categoriesRequestStatus,
  //ACTIONS
  //products list
  getProducts,
  resetProductsStatus,
  //product manage
  updateProduct,
  resetProductStatus,
  getCategories,
  resetCategoriesStatus,
}) {
  //constantes para parâmetros de requisição
  const per_page = 10;
  const status = 1;

  useEffect(() => {
    getProducts({
      per_page,
      status,
    });

    getCategories({
      status,
    });
  }, []);

  //request pagination
  const onPageChange = (data) => {
    getProducts({
      per_page,
      status,
      page: data.currentPage,
      category_id: categorySearch,
      search: search,
    });
  };

  //Modal details product
  const [productDetails, setProductDetails] = useState('');
  const [modalProductDetails, setModalProductDetails] = useState(false);
  const toggleModalProductDetails = () =>
    setModalProductDetails(!modalProductDetails);

  //Modal delete confirm
  const [modalConfirmData, setModalConfirmData] = useState('');
  const [modalConfirmDelete, setModalConfirmDelete] = useState(false);
  const toggleConfirmDelete = () => setModalConfirmDelete(!modalConfirmDelete);

  //Estado para armazenar o toggle do ModalStatus de resposta
  const [modalStatus, setModalStatus] = useState(true);
  const toggleModalStatus = () => setModalStatus(!modalStatus);

  const editProduct = (product) =>
    history.push({
      pathname: 'product',
      state: {
        formProductType: 'edit',
        product: product,
      },
    });

  //sideEffects do ModalStatus de resposta
  useEffect(() => {
    if (!modalStatus) {
      if (productRequestStatus) {
        getProducts({
          per_page,
          status,
          page: pagination.current_page,
        });
        getCategories({
          status,
        });
      }
      resetProductStatus();
      setModalStatus(true);
    }
  }, [modalStatus]);

  const productTableTooltip = [
    { id: 'code', title: 'Código', text: 'Código do produto' },
    { id: 'product', title: 'Produto', text: 'Produto' },
    { id: 'price', title: 'Preço', text: 'Preço' },
    { id: 'quantity', title: 'Quantidade', text: 'Quantidade' },
    { id: 'category', title: 'Categoria', text: 'Categoria' },
    { id: 'options', title: 'Opções', text: 'Opções' },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!categorySearch && !search) return;

    if (search === currentSearch && categorySearch === currentCategorySearch)
      return;

    setCurrentSearch(search);
    setCurrentCategorySearch(categorySearch);

    getProducts({
      per_page,
      status,
      category_id: categorySearch,
      search: search,
    });
  };

  const handleClearSubmit = (e) => {
    e.preventDefault();

    setCurrentSearch(search);
    setCurrentCategorySearch(categorySearch);

    getProducts({
      per_page,
      status,
    });
  };

  //armazena a categoria filtrada
  let categoryId;

  const [search, setSearch] = useState('');
  const [categorySearch, setCategorySearch] = useState(0);

  const [currentSearch, setCurrentSearch] = useState();
  const [currentCategorySearch, setCurrentCategorySearch] = useState();

  return (
    <>
      <div className="shoppingTablePageContainer">
        {/* <PageTitle icon="poli-icon pi-products-line" title="Produtos" /> */}

        <div className="containerMobile">
          <div className="shoppingSearchTableContainer">
            <Form onSubmit={handleSubmit}>
              {/* xl≥1200px - lg≥992px - md≥768px - sm≥576px - xs<576px */}
              <Row form>
                <Col xs={12} sm={12} md={6} xl={4}>
                  <FormGroup>
                    <InputGroup id="searchCatalog">
                      <Input
                        id="search"
                        type="text"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        placeholder="Buscar"
                      />
                      <InputGroupAddon addonType="append">
                        <Button color="primary">
                          <Icon
                            position="relative"
                            top="2px"
                            icon="poli-icon pi-search-line"
                            size={18}
                            color={'white'}
                          />
                        </Button>
                      </InputGroupAddon>
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col xs={12} sm={12} md={6} xl={3}>
                  <FormGroup>
                    {categoriesRequestStatus && categories.length ? (
                      <Input
                        id="category"
                        type="select"
                        name="category"
                        value={categorySearch}
                        onChange={(e) => setCategorySearch(e.target.value)}
                      >
                        <optgroup label="Categorias">
                          <option value={0}>Todas as categorias</option>
                          {categories.map((category) => (
                            <option key={category.id} value={category.id}>
                              {category.name}
                            </option>
                          ))}
                        </optgroup>
                      </Input>
                    ) : (
                      <Input
                        id="category"
                        type="select"
                        name="category"
                        disabled
                      >
                        <option>Erro ao carregar</option>
                      </Input>
                    )}
                  </FormGroup>
                </Col>
                <Col xs={12} sm={6} md={5} xl={2}>
                  <FormGroup>
                    <Button width="total" outline color="primary">
                      <span>Filtrar </span>
                      <Icon
                        position={'relative'}
                        top={'2px'}
                        icon={'poli-icon pi-filter-line'}
                        size={18}
                      />
                    </Button>
                  </FormGroup>
                </Col>
                <Col xs={12} sm={6} md={2} xl={1}>
                  <FormGroup>
                    <Button
                      id="cleanSearchInput"
                      outline
                      width="total"
                      color="danger"
                      onClick={(e) => {
                        setSearch('');
                        setCategorySearch(0);
                        handleClearSubmit(e);
                      }}
                    >
                      <Icon
                        position="relative"
                        top="2px"
                        icon={'poli-icon pi-cleaning-fill'}
                        size={20}
                      />
                    </Button>
                    <UncontrolledTooltip
                      placement="bottom"
                      target="cleanSearchInput"
                    >
                      Limpar campo de pesquisa.
                    </UncontrolledTooltip>
                  </FormGroup>
                </Col>
                <Col xs={12} sm={12} md={5} xl={2}>
                  <FormGroup>
                    <Button
                      width="total"
                      color="primary"
                      onClick={() => {
                        history.push({
                          pathname: 'product',
                          state: {
                            formProductType: 'add',
                          },
                        });
                      }}
                    >
                      <Icon
                        icon={'poli-icon pi-add-circle-line'}
                        color={'white'}
                        size={18}
                        position={'relative'}
                        top={3}
                        left={2}
                      />
                      <span style={{ marginLeft: '0.4rem' }}>produtos</span>
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </div>
          <div className="shoppingTableContainer">
            {productsRequestStatus === false && error && !isLoadingProducts ? (
              <p>
                <Button
                  onClick={() => {
                    getProducts({
                      per_page,
                      status,
                    });
                    getCategories({
                      status,
                    });
                  }}
                >
                  Tentar novamente
                </Button>
              </p>
            ) : isLoadingProducts ? (
              <Animation
                icon="chat-loading"
                top={'50%'}
                background="rgba(0, 0, 0, 0)"
              />
            ) : productsRequestStatus && products.length ? (
              <Table striped hover responsive className="shoppingProducts">
                <thead>
                  <tr>
                    {productTableTooltip.map((title) => (
                      <th key={title.id}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <span>{title.title}</span>
                          <div id={'tooltip' + title.id}>
                            <Icon
                              icon="poli-icon pi-question-fill"
                              size={20}
                              cursor="pointer"
                              color="var(--principal-icons-color)"
                              position={'relative'}
                              top={'3px'}
                              right={'-4px'}
                            />
                          </div>
                          <UncontrolledTooltip
                            placement="top"
                            target={'tooltip' + title.id}
                          >
                            {title.text}
                          </UncontrolledTooltip>
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {products.map((product) => (
                    <tr key={product.id}>
                      <td>{product.code}</td>
                      <td className="tableEllipsis">{product.name}</td>
                      <td>{priceMask(product.price + '')}</td>
                      <td>
                        {product.quantity} unidade
                        {product.quantity === 1 ? '' : 's'}
                      </td>
                      <td>
                        {categories &&
                          (categoryId = categories.find(
                            (category) => category.id == product.category_id
                          )) &&
                          categoryId.name}
                      </td>
                      <td>
                        <Button
                          size={'normal-icon'}
                          color="primary"
                          onClick={() => {
                            toggleModalProductDetails();
                            setProductDetails(product);
                          }}
                        >
                          <Icon
                            position={'relative'}
                            top={'-3px'}
                            right={'3px'}
                            icon={'poli-icon pi-eye-fill'}
                            color={'white'}
                            size={20}
                          />
                        </Button>
                        <Button
                          size={'normal-icon'}
                          color="info"
                          onClick={() => editProduct(product)}
                        >
                          <Icon
                            icon={'poli-icon pi-pencil-fill'}
                            position={'relative'}
                            top={'-3px'}
                            right={'3px'}
                            size={20}
                          />
                        </Button>
                        <Button
                          size={'normal-icon'}
                          color="danger"
                          onClick={() => {
                            toggleConfirmDelete();
                            setModalConfirmData(product);
                          }}
                        >
                          <Icon
                            icon={'poli-icon pi-delete-fill'}
                            position={'relative'}
                            top={'-3px'}
                            right={'3px'}
                            size={20}
                          />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <Alert
                color="attention"
                style={{ margin: 0, justifyContent: 'center' }}
              >
                Não há produtos cadastrados
                <Icon icon="poli-icon pi-sad-fill" size={20} />
              </Alert>
            )}
          </div>
        </div>

        <div className="shoppingPaginationContainer">
          {pagination && (
            <TablePagination
              totalItens={pagination.total_registers_server}
              limitItens={pagination.registers_per_page}
              pageSide={1}
              onPageChange={onPageChange}
            />
          )}
        </div>
      </div>

      <Modal isOpen={modalProductDetails} toggle={toggleModalProductDetails}>
        <ModalHeader toggle={toggleModalProductDetails}>
          <span>{productDetails.name}</span>
        </ModalHeader>
        <ModalBody>
          <Card className="cardProductContainer">
            <CardImg
              className="cardProductImage"
              top
              src={productDetails.image_url || ProductImage}
              alt={`Imagem do produto: ${productDetails.name}`}
            />
            <CardBody>
              <CardTitle className="cardProductTitle">
                {productDetails.name}
              </CardTitle>
              <CardText className="cardProductDescription">
                {productDetails.description}
              </CardText>
              <CardSubtitle>
                Por {priceMask(productDetails.price + '')}
              </CardSubtitle>
            </CardBody>
          </Card>
        </ModalBody>
        <ModalFooter>
          <Button
            outline
            onClick={toggleModalProductDetails}
            className="buttonActionModal"
          >
            Voltar
          </Button>
          <Button
            color="info"
            onClick={() => editProduct(productDetails)}
            className="buttonActionModal"
          >
            Editar
          </Button>
        </ModalFooter>
      </Modal>

      {/* Modal confirmação de exclusão */}
      <ModalConfirmDelete
        isOpen={modalConfirmDelete}
        toggle={toggleConfirmDelete}
        title={'Produto ' + modalConfirmData.name}
        subtitle={'esse produto'}
        warning="Produtos excluídos não podem ser recuperados"
        onClick={() => {
          updateProduct(modalConfirmData.id, {
            ...modalConfirmData,
            status: 0,
          });
          toggleConfirmDelete();
        }}
      />

      {productRequestStatus === false && (
        <ModalStatus
          type="danger"
          isOpen={modalStatus}
          toggle={toggleModalStatus}
          title="Algo deu errado!"
          subtitle="Infelizmente tivemos problemas para deletar este produto."
        />
      )}

      {productRequestStatus && (
        <ModalStatus
          type="success"
          isOpen={modalStatus}
          toggle={toggleModalStatus}
          title="Produto deletado"
          subtitle="O produto foi removido do catálogo."
        />
      )}
    </>
  );
}

const mapStateToProps = (state, ownProps) => ({
  //products list store
  products: state.shoppingProductsList?.products?.list,
  pagination: state.shoppingProductsList?.products?.pagination,
  isLoadingProducts: state.shoppingProductsList?.loadingGetProducts,
  error: state.shoppingProductsList?.error,
  productsRequestStatus: state.shoppingProductsList?.productsStatus,
  //product manage store
  categories: state.shoppingProductsManage?.categories,
  categoriesRequestStatus: state.shoppingProductsManage?.categoriesStatus,
  productRequestStatus: state.shoppingProductsManage?.productStatus,

  props: ownProps,
});

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    ...bindActionCreators(ShoppingProductsList, dispatch),
    ...bindActionCreators(ShoppingProductsManage, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductList);
