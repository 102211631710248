import React, { useEffect, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import gatewayListConfig from '../../../../../services/shopping/payment/gatewayListConfig';
import { toast } from 'react-toastify';

import poliConfig from '../../../../../utils/poliConfig';

// Importação da logo dos gateways
import PagseguroImg from '../../../../../assets/images/polipay/pag-seguro.svg';
import MercadoPagoImg from '../../../../../assets/images/polipay/mercado-pago.svg';



const useGatewayList = () => {
  /**
   * Lista de gateways de pagamentos
   */
  let paymentsDefault = [
    {
      id: null,
      img: MercadoPagoImg,
      title: 'Mercado Pago',
      paymentMethod: 'mercadopago',
      mainMethod: false,
      status: false,
      link: '/mercadopago',
    },
    {
      id: null,
      img: PagseguroImg,
      title: 'PagSeguro',
      paymentMethod: 'pagseguro',
      mainMethod: false,
      status: false,
      link: '/pagseguro',
    },
  ];

  //remove mercadopago if environment is production
  //feature_polipay_mercadopago is false in production
  //in production enable mercadopago if customer is Poli
  const state_current_customer_id = useSelector(
    (state) => state.general?.current_customer_id
  );

  if (
    !poliConfig.feature_polipay_mercadopago &&
    !(
      Number(state_current_customer_id) ===
      Number(poliConfig.feature_enable_customer_tester_id)
    )
  ) {
    paymentsDefault = paymentsDefault.filter(
      (gateway) => gateway.paymentMethod !== 'mercadopago'
    );
  }

  const [isLoading, setIsLoading] = useState(false)
  /**
   * Estado da lista de gateways de pagamento
   */
  const [payments, setPayments] = useState(paymentsDefault);

  /**
   * Função de requisição da lista
   *  @function requestList
   */
  const requestList = useCallback(async () => {
    try {
      setIsLoading(true)
      const result = await gatewayListConfig.fetchGateways();
      if (result && result.data) {
        return result.data;
      }
    } catch (err) {
      console.error('err:', err);
      toast.error(
        'Houve um erro ao buscar os seus dados de pagamento. Caso o erro persista, entre em contato com o suporte.'
      );
      
    }
    finally {
      setIsLoading(false);
    }

    return null;
  });
  /* End */

  /**
   * Busca lista de gateways
   */
  useEffect(() => {
    const getDataList = async () => {
      const result = await requestList();

      if (result) {
        const _payments = [...payments];

        result.map((res) => {
          const foundIndex = _payments.findIndex(
            (e) => e.paymentMethod === res.name
          );

          if (foundIndex >= 0) {
            _payments[foundIndex].id = res.integration_id;
            _payments[foundIndex].status = res.active == 1;
            _payments[foundIndex].mainMethod = res.default == 1;
          }
        });

        setPayments(_payments);
      }
    };

    getDataList();
  }, []);
  /* End */

  /**
   * Mudar qual metodo principal
   * Mudar no estado dos metodos de pagamento, colocando um como principal e removendo os outros
   * Enviando para back-end o novo metodo principal
   */
  const onSetMainMethod = useCallback(
    (paymentMethod, disabledMethod = false) => {
      const _payments = [...payments];
      if (disabledMethod) {
        const find = _payments.findIndex(
          (e) => e.paymentMethod === paymentMethod
        );
        if (find > -1) {
          _payments[find].mainMethod = false;
          const disableDefault = true;
          storeGatewayDefault(_payments[find].id, disableDefault);
        }
      } else {
        _payments.map((e) => {
          if (e.paymentMethod === paymentMethod) {
            e.mainMethod = true;

            storeGatewayDefault(e.id);
          } else {
            e.mainMethod = false;
          }
        });
      }
      setPayments(_payments);
    },
    []
  );
  /* End */

  /**
   * Enviar para o back-end qual metodo padrao de pagamento
   *  @function storeGatewayDefault
   *  @param {number} id id do gateway de pagamento
   */
  const storeGatewayDefault = useCallback(async (id, disableParam = false) => {
    try {
      const params = disableParam ? { default: 0 } : { default: 1 };

      const result = await gatewayListConfig.storeDefault(id, params);
      if (result && result.data) {
        return result.data;
      }
    } catch (err) {
      console.error('err:', err);
      toast.error(
        'Houve um erro ao salvar. Caso o erro persista, entre em contato com o suporte.'
      );
    }

    return null;
  });
  /* End */

  return {
    payments,
    onSetMainMethod,
    isLoading
  };
};

export default useGatewayList;
