import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Icon,
  TableMobile,
  DropdownButton,
  Alert,
  DropdownItem,
} from '@polichat/flamboyant';

import WabaService from '../../../../../services/tools/template-waba/template-waba';

import Animation from '../../../../common/animation';
import {
  Container,
  OptionsButtonContainer,
} from './TableMessagesBuilder.styles';

const TableMessagesBuilderImport = ({
  cols,
  rows,
  isLoading,
  onOptionsClick,
}) => {
  const customer_id = useSelector((state) => state.general.current_customer_id);
  const [categoriesWabaList, setCategoriesWabaList] = useState([]);

  const statusAlertColor = {
    approved: 'success',
    pending: 'attention',
    rejected: 'danger',
  };
  const statusText = {
    approved: 'Aprovado',
    pending: 'Aguardando',
    rejected: 'Reprovado',
  };
  const titleStatus = ['approved', 'pending', 'rejected'];

  useEffect(() => {
    async function fetchCategoriesWaba() {
      const result = await WabaService.getWabaCategories({ customer_id });
      result && setCategoriesWabaList(result);
    }
    fetchCategoriesWaba();
  }, []);

  function getCategoryWabaText(fui) {
    const foundedCategory = categoriesWabaList.find(
      (category) => category.fui === fui
    );
    if (foundedCategory) {
      return foundedCategory.title;
    } else {
      return '';
    }
  }

  const goToImportTemplate = () => {
    //Link da pagina para preencher dados do template a ser importado.
  };

  function handleDelete(data) {
    onOptionsClick({
      option: 'delete',
      template_name: data.template_name,
      tag: data.tag,
    });
  }

  return (
    <Container>
      {isLoading ? (
        <Animation
          icon="chat-loading"
          style={{
            maxWidth: '150px',
            height: '100%',
            maxHeight: '100px',
          }}
          background="rgba(0, 0, 0, 0)"
        />
      ) : (
        <TableMobile striped hover cols={cols}>
          {rows.map((row) => (
            <tr key={row.id}>
              <td>{row.name}</td>
              {/*
              Type Col
              <td>
                <TemplateBadge>
                  <>
                    <Icon
                      icon="poli-icon pi-whatsapp-business-line"
                      size={22}
                      color={'var(--success)'}
                    />
                    <span>Template</span>
                  </>
                </TemplateBadge>
              </td> */}
              <td>{row.category && getCategoryWabaText(row.category)}</td>
              <td>{row.components[0].text}</td>
              <td>
                {row.status ? (
                  <Alert
                    color={
                      statusAlertColor[
                        titleStatus.includes(row.status)
                          ? row.status
                          : 'pending'
                      ]
                    }
                  >
                    {
                      statusText[
                        titleStatus.includes(row.status)
                          ? row.status
                          : 'pending'
                      ]
                    }
                  </Alert>
                ) : null}
              </td>
              <td>
                <DropdownButton
                  color="neutral"
                  icon="poli-icon pi-bullet-fill"
                  iconSize={22}
                  direction="left"
                >
                  <OptionsButtonContainer>
                    {row.status === 'approved' ? (
                      <DropdownItem
                        className="OptionEdit"
                        onClick={goToImportTemplate()}
                      >
                        <Icon
                          icon="poli-icon pi-download-line"
                          size={22}
                          position={'relative'}
                          right={'10px'}
                        />
                        <span>Importar</span>
                      </DropdownItem>
                    ) : null}

                    <DropdownItem
                      className="OptionDelete"
                      onClick={() => handleDelete(row)}
                    >
                      <Icon
                        icon="poli-icon pi-delete-line"
                        size={22}
                        color={'var(--danger)'}
                        position={'relative'}
                        right={'10px'}
                      />
                      <span>Excluir</span>
                    </DropdownItem>
                  </OptionsButtonContainer>
                </DropdownButton>
              </td>
            </tr>
          ))}
        </TableMobile>
      )}
    </Container>
  );
};

export default TableMessagesBuilderImport;
