import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { validationSchema } from './validation';

export interface LocalFormValues {
  name: string;
  phone: string;
}

export function useLocalForm() {
  // ---------------------------------------------
  // States
  const form = useForm<LocalFormValues>({
    defaultValues: {
      name: '',
      phone: '',
    },
    resolver: yupResolver(validationSchema),
  });
  // ---------------------------------------------
  // Functions
  // ---------------------------------------------
  // Effects
  // ---------------------------------------------
  // Transformations
  // ---------------------------------------------
  // API

  return { ...form };
}
