import React from 'react';

import { BannerAlert } from './ChannelStatusAlert.styles';

import { Button } from '@polichat/flamboyant';

export const ChannelStatuses = Object.freeze({
  SEM_CONEXÃO: { code: 101, txt: 'Sem conexão com o servidor', type: 'danger' },
  SEM_ABA: { code: 102, txt: 'Sem aba aberta', type: 'attention' },
  CONEXÃO_NÃO_CONFIGURADA: {
    code: 103,
    txt: 'Conexão não configurada',
    type: 'attention',
  },
  CONEXÃO_LIVRE: {
    code: 104,
    txt: 'Conexão livre, Buscando Canal',
    type: 'attention',
  },
  SEM_SERVIDOR: {
    code: 105,
    txt: 'Sem Servidor disponível para Hospedagem',
    type: 'warning',
  },
  ERRO_INICIAR_EXTENSÃO: {
    code: 106,
    txt: 'Erro ao iniciar Extensão',
    type: 'danger',
  },
  ERRO_EXTENSÃO_107: { code: 107, txt: 'Erro na Extensão', type: 'danger' },
  ERRO_EXTENSÃO_108: { code: 108, txt: 'Erro na Extensão', type: 'danger' },
  ERRO_EXTENSÃO_109: { code: 109, txt: 'Erro na Extensão', type: 'danger' },
  ERRO_EXTENSÃO_ABA_TRAVADA: {
    code: 110,
    txt: 'Erro na Extensão - Aba travada',
    type: 'danger',
  },
  ERRO_EXTENSÃO_111: { code: 111, txt: 'Erro na Extensão', type: 'danger' },
  INICIANDO_CONEXÃO: {
    code: 200,
    txt: 'Iniciando conexão da API com o whatsapp',
    type: 'attention',
  },
  AGUARDANDO_LEITURA: {
    code: 201,
    txt: 'Canal aguardando leitura do QR Code',
    type: 'info',
    show_qr: true,
  },
  CONECTANDO: { code: 202, txt: 'Conectando', type: 'success', button_disconnect: true },
  CONECTADO: {
    code: 203,
    txt: 'Canal conectado com o WhatsApp',
    type: 'success',
    show_disconnect: true,
  },
  APARELHO_DESCONECTADO: {
    code: 204,
    txt: 'Aparelho Desconectado',
    type: 'danger',
  },
  BATERIA_FRACA: {
    code: 205,
    txt: 'Aparelho com Bateria Fraca',
    type: 'warning',
  },
  AGUARDANDO_ATIVAÇÃO: {
    code: 206,
    txt: 'Aguardando ativação',
    type: 'warning',
  },
  USANDO_API: {
    code: 207,
    txt: 'WhatsApp usando api.whatsapp.com',
    type: 'warning',
  },
  CANAL_DESCONECTADO: {
    code: 208,
    txt: 'Canal desconectado',
    type: 'warning',
    show_connect: true,
  },
  REINICIANDO_CANAL: { code: 209, txt: 'Reiniciando Canal', type: 'warning' },
  ERRO_INICIALIZAR: {
    code: 301,
    txt: 'Erro ao inicializar WhatsApp',
    type: 'danger',
  },
  CONTA_DIVERGENTE: {
    code: 401,
    txt: 'Conta Divergente da configurada',
    type: 'warning',
    show_connect: true,
  },
});

const getChannelDataByStatusCode = (code) =>
  ChannelStatuses[
    Object.keys(ChannelStatuses).find(
      (channelStatus) => ChannelStatuses[channelStatus].code == code
    )
  ];

export default function ChannelStatusAlert({
  status,
  connectCallback,
  disconnectCallback,
  showQrCodeButtonCallback,
  tryAgainButtonCallBack,
  showDisconnectButtonCallback,
}) {
  if (!status) return <></>;

  return (
    <BannerAlert color={getChannelDataByStatusCode(status).type}>
      <div>{getChannelDataByStatusCode(status).txt}</div>
      {getChannelDataByStatusCode(status).show_connect && (
        <Button color="success" size="sm" onClick={connectCallback}>
          Conectar
        </Button>
      )}
      {getChannelDataByStatusCode(status).show_disconnect && (
        <Button color="info" size="sm" onClick={disconnectCallback}>
          Desconectar
        </Button>
      )}
      {getChannelDataByStatusCode(status).type == 'danger' && (
        <Button color="danger" size="sm" onClick={tryAgainButtonCallBack}>
          Tentar novamente
        </Button>
      )}
      {getChannelDataByStatusCode(status).show_qr && (
        <Button
          color={getChannelDataByStatusCode(status).type}
          size="sm"
          onClick={showQrCodeButtonCallback}
        >
          Ver QR Code
        </Button>
      )}
      {getChannelDataByStatusCode(status).button_disconnect && (
        <Button
          color="danger"
          size="sm"
          onClick={showDisconnectButtonCallback}
        >
          Desconectar
        </Button>
      )}
    </BannerAlert>
  );
}
