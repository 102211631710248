import { laravelAxios } from '../../../../config/axios/laravelAxios';

async function getOpportunitiesAndTickets(contact) {
  const getOpportunitiesAndTickets = await laravelAxios.get(
    `crm/getOpportunitiesAndTickets/${contact.contact}/${contact.all}`
  );

  return getOpportunitiesAndTickets;
}
export default getOpportunitiesAndTickets;
