import * as React from 'react';
import { PaymentIntegratorService } from '../service';
import { DtoGateway } from '../service/types';
import { getPaymentInfoFromGatewayData } from './helper';

export interface UsePaymentIntegratorServiceProps {
  customer_id?: number;
}

export function usePaymentIntegratorService(
  props: UsePaymentIntegratorServiceProps
) {
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState<DtoGateway[]>();
  const [error, setError] = React.useState<Error>();

  // ---------------------------------------------
  // Functions

  const refresh = React.useCallback(async () => {
    try {
      setLoading(true);
      const response = await PaymentIntegratorService.list();
      setData(response);
    } catch (error: any) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, []);

  // ---------------------------------------------
  // Effects

  React.useEffect(() => {
    if (props.customer_id) refresh();
  }, [props.customer_id]);

  // ---------------------------------------------
  // Transformations
  const customerHasPaymentIntegrator = getPaymentInfoFromGatewayData(data);
  // ---------------------------------------------
  // API

  return {
    loading,
    error,
    customerHasPaymentIntegrator,
    refresh,
  };
}
