import {
  Button,
  Icon,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from '@polichat/flamboyant';
import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import Animation from 'src/components/common/animation';
import { useRegisterMetaService } from 'src/components/tools/channels/hooks/useRegisterMetaService';
import history from 'src/services/history';
import { EnumBSPPoliCodeVerificationStatus } from './enum';
export interface RestartChannelProps {
  phone_id: string;
  customer_channel_id: string;
  channel_status: string;
}
export function RestartChannelUsingRegister(props: RestartChannelProps) {
  const phone_id = parseInt(props.phone_id);

  const registerService = useRegisterMetaService({ phone_id });

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleToggle = useCallback(() => {
    setIsModalOpen((prev) => !prev);
  }, []);

  const handleClickRegister = useCallback(() => {
    if (
      !props.channel_status &&
      !props.phone_id &&
      !props.customer_channel_id
    ) {
      toast.error(
        'Erro ao registrar o canal, tente novamente. Em caso de dúvida entre em contato com o suporte'
      );
      handleToggle();
      return;
    }

    if (props.channel_status === EnumBSPPoliCodeVerificationStatus.VERIFIED) {
      registerService.register(
        handleRegisterChannelReturnSuccess,
        handleRegisterChannelReturnError
      );
      return;
    }

    if (
      props.channel_status === EnumBSPPoliCodeVerificationStatus.NOT_VERIFIED
    ) {
      history.push({
        pathname: `/tools/channels/register/${props.customer_channel_id}`,
        search: `phone_id=${props.phone_id}`,
      });
    }
  }, []);

  const handleRegisterChannelReturnError = useCallback(() => {
    toast.error(
      'Erro ao registrar o canal, tente novamente. Em caso de dúvida entre em contato com o suporte'
    );
  }, []);

  const handleRegisterChannelReturnSuccess = useCallback(() => {
    toast.success('Canal registrado com sucesso!');
    handleToggle();
  }, []);

  return (
    <>
      <Button color="warning" onClick={handleToggle}>
        Registrar canal
      </Button>

      {/* Modal */}
      <Modal
        isOpen={isModalOpen}
        toggle={handleToggle}
        size="lg"
        className="modal-dialog-scrollable"
      >
        <ModalHeader toggle={handleToggle}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around',
              width: '100%',
            }}
          >
            <Icon icon="poli-icon pi-question-line" />
            <span>Você tem certeza?</span>
          </div>
        </ModalHeader>
        <ModalBody>
          <p>
            Realizar esta ação irá refazer o <strong> registro </strong> do seu
            canal pode gerar um pequeno período de
            <strong> indisponibilidade</strong>. Além disso, será gerado uma
            nova chave de criptografia em seu canal.
          </p>
        </ModalBody>
        {registerService.loading && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-around',
            }}
          >
            <span>Registrando canal</span>
            <Icon icon="poli-icon pi-polichat-logo-line" size={20} />
            <Icon icon="poli-icon pi-facebook-line" size={20} />
            <Animation icon="chat-loading" size="tiny" />
          </div>
        )}
        <ModalFooter>
          <Button outline onClick={handleToggle}>
            Cancelar
          </Button>
          <Button
            color="warning"
            onClick={handleClickRegister}
            disabled={registerService.loading}
          >
            Confirmar registro
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
