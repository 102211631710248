import React, { useState } from 'react';
import Tag from './Tag';
import {
  Icon,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
} from '@polichat/flamboyant';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ChatActions from '../../../../../../store/modules/chat/actions';

function AvailableTags({ chat, insertTagToContact }) {
  const tagsMenu = chat?.view?.menus?.find((menu) => menu.type === 'tag');
  let availableTagsComponent = <></>;
  let contactTags, tagsIncluded, resultOfFilterSearchTerm;
  let allAvailableTags = tagsMenu.availableTags;

  const [searchTerm, setSearchTerm] = useState('');
  const [availableTags, setAvailableTags] = useState(allAvailableTags);
  const [contador, setContador] = useState(0)


  const [lastTimeout, setLastTimeout] = useState(0);
  const [loading, setLoading] = useState(false);

  function handleInsertTag(tag) {

    // Ação de inserir tag no contato
    insertTagToContact(tag);

    // Resentando value do input
    setSearchTerm('')
    // Resetando tags disponíveis
    setAvailableTags(allAvailableTags)

  }

  const onUpdateSearchValue = (value) => {
    setLoading(true);

    if (lastTimeout) {
      clearTimeout(lastTimeout);
    }

    setSearchTerm(value);

    setLastTimeout(
      setTimeout(() => {
        setLoading(false);

        if (searchTerm.length != 1) {
          resultOfFilterSearchTerm = allAvailableTags.filter((tag) => tag.name.toLowerCase().includes(value.toLocaleLowerCase()))
          setAvailableTags(resultOfFilterSearchTerm);
        } else{
            setAvailableTags(allAvailableTags)
          }
      }, 1000)
    );
  };

  if (chat && tagsMenu) {
    contactTags = tagsMenu.contactTags;
    tagsIncluded = chat.tags;

    // Caso não existam tags cadastradas
    if (
      contactTags.length === 0 &&
      allAvailableTags.length === 0 &&
      tagsIncluded.length === 0
    ) {
      return (
        <>
            <span className="noTags">Não existem etiquetas ativas.</span>
        </>
      );
    }

    // Se tiver etiquetas disponíveis
    else if (availableTags) {
      return (
        <>
          <InputGroup>
            <Input
              type="text"
              placeholder="Buscar etiquetas"
              value={searchTerm}
              onChange={(e) => onUpdateSearchValue(e.target.value)}
              onKeyDown={() => setContador((contador) + 1)}
            />
            <InputGroupAddon addonType="prepend">
              <InputGroupText className="input-tags">
                <Icon icon="poli-icon pi-search-line" size={20} />
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>

          <div className="avaliable-tags-container">
            {
              availableTagsComponent = availableTags.length > 0 ? availableTags.map((tag, index) => {
                  return (
                    <Tag
                      key={index}
                      name={tag.name}
                      color={tag.color}
                      onClick={() => {
                        handleInsertTag(tag);
                      }}
                    />
                  );
              })
              : <span className="noTagsFoundedInSearch">Nenhuma etiqueta foi encontrada.</span>
            }
          </div>
        </>
      );
    }

    // Se o usuário já utilizou todas as etiquetas no contato
    else {
      availableTagsComponent = (
        <span>Você utilizou todas as etiquetas disponíveis.</span>
      );
    }
  }

  return <>{availableTagsComponent}</>;
}


const mapStateToProps = (state) => ({
  chat: state.chat?.chats?.find((chat) => chat.open === true),
  allTags: state.chat?.config?.general?.tags,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(ChatActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AvailableTags);
