import React, { useState } from 'react';
import { Button, Icon } from '@polichat/flamboyant';
import DeleteChannelModal from './modal';
import ChannelsService from '../../../../../services/tools/channels/channels';

import { toast } from 'react-toastify';
import history from '../../../../../services/history';
import { ButtonContainer } from './styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ChatActions from '../../../../../store/modules/chat/actions';
import { useFlags } from 'flagsmith/react';

interface Props {
  channel: any;
  size: string;
  currentUser: any
}

const DeleteChannelBsp = ({ channel, size, currentUser }: Props) => {
  const [showDeleteChannel, setShowDeleteChannel] = useState(false);
  const [isLoadingDeleteRequest, setIsLoadingDeleteRequest] = useState(false);

  const showDeleteButton = currentUser.superRole === 'adm' ||  currentUser.superRole === 'manager';

  const handleDeleteChannel = async () => {
    try {
      setIsLoadingDeleteRequest(true);
      const { id, customer_id } = channel;
      const result: any = await ChannelsService.deleteChannel({
        id,
        customer_id,
      });

      if (result.status == 200) {
        toast.success('Canal excluido!');
        history.push('/tools/channels/list');
      } else throw 'Requisição mal sucedida';
    } catch (error) {
      toast.error(
        <div>
          Erro ao excluir canal, tente novamente!
          <br />
          Se erro persistir entre em contato com o suporte.
        </div>
      );
      console.error('Erro encontrado ao tentar deletar canal: ', error);
    } finally {
      setIsLoadingDeleteRequest(false);
    }
  };
  return (
    <>
      <ButtonContainer style={{alignSelf: 'center'}}>
        {showDeleteButton && (
          <Button
            size={size}
            color="danger"
            onClick={() => setShowDeleteChannel(!showDeleteChannel)}
          >
            <Icon icon="poli-icon pi-delete-fill" color="#FFFFFF" size="25" />
            <p style={{ marginBottom: '-2px', color: '#fff' }}>Excluir canal</p>
          </Button>
        )}
      </ButtonContainer>

      <DeleteChannelModal
        isOpen={showDeleteChannel}
        toggle={() => setShowDeleteChannel(!showDeleteChannel)}
        channelData={channel}
        handleDeleteChannel={handleDeleteChannel}
        isLoading={isLoadingDeleteRequest}
      />
    </>
  );
};

const mapStateToProps = (state: any) => ({
  currentUser: state?.general?.user,
});
export default connect(mapStateToProps)(DeleteChannelBsp);
