import * as Yup from 'yup';

function validateCNPJ(cnpj) {
  if (cnpj === '') return true;
  
  cnpj = cnpj.replace(/[^\d]+/g, '');

  if (cnpj.length !== 14) return false;

  // Verifica se todos os dígitos são iguais
  if (/^(\d)\1+$/.test(cnpj)) return false;

  // Calcula os dígitos verificadores
  let tamanho = cnpj.length - 2;
  let numeros = cnpj.substring(0, tamanho);
  const digitos = cnpj.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado != digitos.charAt(0)) return false;

  tamanho = tamanho + 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado != digitos.charAt(1)) return false;

  return true;
}

export const validationSchema = Yup.object({
  name: Yup.string().required('campo obrigatório'),
  cnpj: Yup.string()
    .optional()
    .test('is-cnpj-valid', 'CNPJ inválido', (value) => {
      if (!value) return true;

      return validateCNPJ(value);
    }),
  companyName: Yup.string().optional(),
  site: Yup.string().optional(),
  email: Yup.string().optional().email('Email inválido!'),
  type: Yup.string(),
  address: Yup.string().optional(),
  observations: Yup.string().optional(),
});

// Função para formatar CNPJ
export const formatCnpj = (value) => {
  // Remove caracteres não numéricos
  const cleanedValue = value.replace(/\D/g, '');

  // Limita a quantidade de caracteres a um CNPJ válido
  const limitedValue = cleanedValue.substring(0, 14);

  // Aplica a máscara de acordo com os caracteres digitados
  let formattedValue = '';
  if (limitedValue.length <= 2) {
    formattedValue = limitedValue;
  } else if (limitedValue.length <= 5) {
    formattedValue = `${limitedValue.substring(
      0,
      2
    )}.${limitedValue.substring(2)}`;
  } else if (limitedValue.length <= 8) {
    formattedValue = `${limitedValue.substring(
      0,
      2
    )}.${limitedValue.substring(2, 5)}.${limitedValue.substring(5)}`;
  } else if (limitedValue.length <= 12) {
    formattedValue = `${limitedValue.substring(
      0,
      2
    )}.${limitedValue.substring(2, 5)}.${limitedValue.substring(
      5,
      8
    )}/${limitedValue.substring(8)}`;
  } else {
    formattedValue = `${limitedValue.substring(
      0,
      2
    )}.${limitedValue.substring(2, 5)}.${limitedValue.substring(
      5,
      8
    )}/${limitedValue.substring(8, 12)}-${limitedValue.substring(12)}`;
  }

  return formattedValue;
};