import {useState} from 'react';
import {Col, FormGroup, Label, Input} from '@polichat/flamboyant';

function TemplateNameInput({onUpdate, initialValue, isEdit}) {
  const [templateName, setTemplateName] = useState({
    templateName: initialValue
  });

  const handleInputChange = ({target: {name, value}}) => {
    setTemplateName({
      ...templateName,
      [name]: value
    });
    handleTemplateNameData({
      ...templateName,
      [name]: value
    })
  };

  const handleTemplateNameData = (data) => {
    onUpdate(data)
  }

  return (
  <Col sm={12} md={6} >
    <FormGroup>
      <Label>Nome do template:</Label>
      <Input
        type="text"
        id="template-name"
        name= "templateName"
        placeholder="Digite um nome"
        value={templateName.templateName}
        onChange={handleInputChange}
        required
        disabled={isEdit}
        />
    </FormGroup>
  </Col>
)}

export default TemplateNameInput;
