import { ReactNode } from 'react';

import styles from '../Modal/ModalMessagePreview.module.css';

interface PreviewRoot {
  children: ReactNode;
}
export function PreviewRoot({ children }: Readonly<PreviewRoot>) {
  return (
    <div className={styles.ballon}>
      <div className="d-flex flex-column">{children}</div>
    </div>
  );
}
