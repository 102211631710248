import styled from 'styled-components'


export const ContainerTale = styled.div`
  table {
    // max-width: 1300px;
    font-size: 15px
  }

  thead {
    th {
      max-width: 50px;
      word-wrap: break-word;
      display: table-cell;
      vertical-align: middle;
      line-height: normal;
    }
  }

  tbody {

    td {
      max-width: 50px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    tr:nth-child(odd) {
      background-color: #EBEBEB;
    }

  }

  .bg-transparent {
    background-color: transparent !important;
  }

  tbody tr:nth-child(2n+1), tbody tr:nth-child(2n+1):hover {
    background-color: var(--bg-line-2n_1);
  }
`

export const TrResult = styled.tr`
  .color-gray {
    color: var(--textDefault);
    font-weight: bold;
    background-color: var(--bg-tr-result);
  }
`

export const DivStatusExport = styled.div`
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 4px 10px;
  justify-content: center;
  color: var(--textDefault);
  text-wrap: balance;
  text-align: center;
`
