import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Lottie from 'react-lottie';
import AuthLayout from '../../components/layouts/authLayout';
import {
  valid_token,
  password_recover_update,
} from '../../services/auth/password';
import errorImage from '../../assets/images/error.svg';
import sucessImage from '../../assets/images/success.svg';
import loadingMain from '../../lotties/loading-main.json';

import { Button, Icon, CustomPassword, Form } from '@polichat/flamboyant';

import {
  TextNewPassword,
  TextValidNewPassword,
  IconPassword,
  ButtonNewPassword,
  ContainerError,
  ContainerSuccess,
} from './stylesNewPassword';
import Animation from '../../components/common/animation';

function NewPassword() {
  const [state, setState] = useState({
    loading: true,
    userTokenData: null,
    success: false,
    loadingSendPassword: false,
  });
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('tk');
  const inputPassword = useRef(null);
  let history = useHistory();

  const optionsAnimation = {
    loop: true,
    autoplay: true,
    animationData: loadingMain,
  };

  useEffect(() => {
    if (state.loading) {
      valid_token(token)
        .then((dataToken) => {
          setState({ ...state, loading: false, userTokenData: dataToken });
        })
        .catch((e) => {
          setState({
            ...state,
            loading: false,
            userTokenData: { error: true },
          });
        });
    } else if (state.loadingSendPassword) {
      password_recover_update(state.userTokenData.email, state.password, token)
        .then(() => {
          setState({ ...state, success: true, loadingSendPassword: false });
        })
        .catch((e) => {
          setState({
            ...state,
            loadingSendPassword: false,
            errorSendPassword: true,
          });
        });
    }
  }, [state]);

  const handleValidatePassword = (e) => {
    e.preventDefault();
    let value = e?.nativeEvent?.target?.value;
    let rolesPassword = {
      min: true,
      number: true,
      lowerAndUpper: true,
      specialCharacter: true,
      rolesValidSuccess: true,
    };

    if (value.length < 8) {
      rolesPassword.min = false;
      rolesPassword.rolesValidSuccess = false;
    }
    if (!/[0-9]/.test(value)) {
      rolesPassword.number = false;
      rolesPassword.rolesValidSuccess = false;
    }
    if (!/[^a-zA-Z0-9]/.test(value)) {
      rolesPassword.specialCharacter = false;
      rolesPassword.rolesValidSuccess = false;
    }
    if (!/([a-z]{1,}.*[A-Z]{1,})|([A-Z]{1,}.*[a-z]{1,})/.test(value)) {
      rolesPassword.lowerAndUpper = false;
      rolesPassword.rolesValidSuccess = false;
    }
    setState({ ...state, rolesPassword });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!state?.rolesPassword?.rolesValidSuccess) {
      return;
    }
    if (!state.loadingSendPassword) {
      setState({
        ...state,
        loadingSendPassword: true,
        password: inputPassword.current.value,
      });
    }
  };

  const handleClickSuccess = () => {
    history.push('/login');
  };

  const handleClickInvalidToken = () => {
    history.push('/recover-password');
  };

  const handleClickError = () => {
    setState({ ...state, loading: false, userTokenData: dataToken });
  };

  const screenSuccess = () => {
    return (
      <AuthLayout title="Senha alterada com sucesso!" titleColor="#00C48C">
        <ContainerSuccess>
          <img src={sucessImage}></img>
          <Button onClick={handleClickSuccess} width="total">
            {' '}
            Fazer login na plataforma{' '}
          </Button>
        </ContainerSuccess>
      </AuthLayout>
    );
  };

  // Tela informando que o token é invalido
  const screenInvalidToken = () => {
    return (
      <AuthLayout title="Sua sessão expirou!" titleColor="#ff665d">
        <ContainerError>
          <img src={errorImage}></img>
          <Button onClick={handleClickInvalidToken} width="total">
            {' '}
            Tentar Novamente{' '}
          </Button>
        </ContainerError>
      </AuthLayout>
    );
  };
  // Tela de loading
  const screenLoading = () => {
    return (
      <AuthLayout>
        <Animation
          icon="loading"
          size={50}
          style={{
            border: '6px solid transparent',
            borderColor: 'var(--background-spinner)',
            borderTopColor: 'var(--green-polichat)',
          }}
        />
        <div style={{ marginTop: 40 }}></div>
      </AuthLayout>
    );
  };

  // Tela que informar que houve erro
  const screenError = () => {
    return (
      <AuthLayout title="Algo deu errado!" titleColor="#ff665d">
        <ContainerError>
          <img src={errorImage}></img>
          <Button onClick={handleClickError} width="total">
            {' '}
            Tentar Novamente{' '}
          </Button>
        </ContainerError>
      </AuthLayout>
    );
  };
  const screenForm = () => {
    return (
      <AuthLayout title="Nova Senha">
        <Form onSubmit={handleSubmit}>
          <TextNewPassword>
            Define uma nosa senha de acesso a Poli para o usuário{' '}
            {state.userTokenData.email}
          </TextNewPassword>
          <IconPassword>
            <div>
              <Icon size={25} icon={'poli-icon pi-key-line'}></Icon>
            </div>
            <span>Senha:</span>
          </IconPassword>

          <CustomPassword
            innerRef={inputPassword}
            onChange={handleValidatePassword}
            type="password"
            width="half"
          />

          <TextValidNewPassword>
            <p
              className={
                state.rolesPassword && state.rolesPassword.min !== null
                  ? state.rolesPassword.min
                    ? 'valid'
                    : 'error'
                  : null
              }
            >
              <Icon
                size={18}
                position={'relative'}
                top={'3px'}
                right={'1px'}
                icon={'poli-icon pi-finish-line'}
              ></Icon>
              Ter no mínimo 8 dígitos
            </p>
            <p
              className={
                state.rolesPassword &&
                state.rolesPassword.specialCharacter !== null
                  ? state.rolesPassword.specialCharacter
                    ? 'valid'
                    : 'error'
                  : null
              }
            >
              <Icon
                size={18}
                position={'relative'}
                top={'3px'}
                right={'1px'}
                icon={'poli-icon pi-finish-line'}
              ></Icon>
              Ao menos 1 caracter especial
            </p>
            <p
              className={
                state.rolesPassword &&
                state.rolesPassword.lowerAndUpper !== null
                  ? state.rolesPassword.lowerAndUpper
                    ? 'valid'
                    : 'error'
                  : null
              }
            >
              <Icon
                size={18}
                position={'relative'}
                top={'3px'}
                right={'1px'}
                icon={'poli-icon pi-finish-line'}
              ></Icon>
              Possuir letras maiúsculas e minúsculas
            </p>
            <p
              className={
                state.rolesPassword && state.rolesPassword.number !== null
                  ? state.rolesPassword.number
                    ? 'valid'
                    : 'error'
                  : null
              }
            >
              <Icon
                size={18}
                position={'relative'}
                top={'3px'}
                right={'1px'}
                icon={'poli-icon pi-finish-line'}
              ></Icon>
              Ao menos 1 número
            </p>
          </TextValidNewPassword>

          <ButtonNewPassword>
            <Button
              outline
              color="primary"
              width="half"
              className="buttonBack"
              onClick={handleClickSuccess}
            >
              {' '}
              Voltar{' '}
            </Button>
            <Button
              type="submit"
              width="half"
              className={
                !state?.rolesPassword?.rolesValidSuccess ? 'disabled' : ''
              }
            >
              {' '}
              Criar nova senha{' '}
            </Button>
          </ButtonNewPassword>
        </Form>
      </AuthLayout>
    );
  };
  if (state.success) {
    return screenSuccess();
  } else if (state.loading || state.loadingSendPassword) {
    return screenLoading();
  } else if (state?.userTokenData?.error) {
    return screenInvalidToken();
  } else if (state.errorSendPassword) {
    return screenError();
  } else {
    return screenForm();
  }
}

export default NewPassword;
