import styled from 'styled-components';

export const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  /*height: calc(100vmin - 24rem);*/

  overflow-y: auto;

  margin: 0.7rem auto;
  padding: 0 1.5rem;

  @media (max-width: 800px) {
    height: auto;
  }
`;

export const HeadingTextContainer = styled.div`
  margin: 1rem 0;
`;

export const GridInformationContainer = styled.div`
  margin: 1rem 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    gap: 0.7rem;
  }
`;

export const DocumentList = styled.div`
  border-right: 1px solid var(--border-color);
  padding: 0 3rem 0 0;

  @media (max-width: 800px) {
    border-right: none;
    padding: 0;
  }
`;

export const Item = styled.div`
  display: flex;
  margin-bottom: 1rem;

  & span {
    margin-left: 0.5rem;
  }
`;

export const ExtraInformation = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  & img {
    width: 13rem;
  }

  & span {
    margin-top: 1.3rem;
  }
`;

export const InputGroupContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem;

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    gap: 0.75rem;
  }

  & label {
    display: flex;
    align-items: center;

    & i {
      margin-right: 0.5rem;
    }
  }
`;

export const NameContainer = styled.div``;

export const PhoneContainer = styled.div``;
