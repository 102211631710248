import { DropdownItem } from '@polichat/flamboyant';
import { QuickMessage } from '../../types/types';
export interface QuickControlEditProps {
  handleEdit: (message: QuickMessage) => void;
}
export function QuickControlEdit({
  handleEdit,
}: Readonly<QuickControlEditProps>) {
  return <DropdownItem onClick={handleEdit}>Editar</DropdownItem>;
}
