import React, { useRef } from 'react';
import { Button, Icon } from '@polichat/flamboyant';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ChatActions from '../../../../../store/modules/chat/actions';
import { useFlags } from 'flagsmith/react';

import Animation from '../../../../common/animation';

import ClickOutsideEvent from '../../../../../events/ClickOutsideEvent';

function FinishChatModal({ chat, finishChatRequest, closeFinishChatModal }) {
  const wrapperRef = useRef(null);
  const selectedChannel = chat.view.selectedChannel;
  const flagFinishChatByChannel = useFlags(['finish_chat_by_channel']).finish_chat_by_channel;
  
  ClickOutsideEvent(wrapperRef, closeFinishChatModal);

  const confirmButton = chat.finishing ? (
    <Animation icon="loading" size={'tiny'} />
  ) : (
    <Button
      finish-chat-buttons-subsection
      width="total"
      color="primary"
      className="finish-chat-button-yes"
      onClick={() => {
        finishChatRequest(chat.id, selectedChannel.id);
      }}
    >
      Sim
    </Button>
  );

  return (
    <div className="finish-chat-modal" id="finish-chat-modal" ref={wrapperRef}>
      <div className="finish-chat-subsection">
        <Icon icon="pi-talks-line" className="finish-chat-icon" size={80} />
      </div>

      <div className="finish-chat-subsection" style={{ marginTop: '0.8rem' }}>
        <span className="finish-chat-title">Encerrar conversa</span>
      </div>

      {
        flagFinishChatByChannel?.enabled
        ? <div className="finish-chat-subsection" style={{ marginTop: '1rem' }}>
            <span className="finish-chat-description">
              Tem certeza que deseja encerrar as conversas com este contato no canal
            </span>
            {'\n'}
            <div className='finish-chat-channel-info'>
              <i
                className={selectedChannel.icon} style={{ fontSize: '20px' }}
                ref={(node) => node && node.style.setProperty('color', selectedChannel.icon_color, 'important')}
              />
              {'  '}
              <span className="finish-chat-channel-info">
                {selectedChannel.channel_name ?? selectedChannel.name}
                {selectedChannel.channel_phone && ` (${selectedChannel.channel_phone})`}
              </span>
              <span style={{ fontSize: '0.8rem' }}>
                ?
              </span>
            </div>
          </div>
        : <div className="finish-chat-subsection" style={{ marginTop: '1rem' }}>
            <span className="finish-chat-description">
              Tem certeza que deseja encerrar a conversa com este contato?
            </span>
          </div>
      }

      <div className="finish-chat-buttons-subsection">
        <Button
          color="danger"
          width="total"
          className="finish-chat-button-no"
          onClick={() => {
            closeFinishChatModal();
          }}
        >
          Não
        </Button>
        {confirmButton}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  chat: state.chat?.chats?.find((chat) => chat.open === true),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(ChatActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FinishChatModal);
