class globalVarsObj{
    constructor(){
        window.Poli = window.Poli||{}
    }
    setUser(user){
        window.Poli.user = {
            email: user.email,
            id: user.id,
            name: user.name,
            token:user?.token,
            customers:(user.customers||[]).map((customer)=>{
                if(typeof customer === 'number'){
                    return {id:customer}
                }
                return {
                    id:customer.id
                }
            })
        };
    }
    setContact(contact){
        window.Poli.contact = {
            id : contact.id,
            name : contact.name,
            channels : (contact?.externals||[]).map(
            (external)=>{ 
                return {
                    id:external.id, 
                    uid:external.uid,
                    name:external.name,
                    type:external?.type,
                    selected:external?.selected||false
                }
            })
        }
    }
    setChannel(channel){
        window.Poli.channel = channel;
    }
}
const globalVars = new globalVarsObj;
export default globalVars