import { getOperatorName } from '../../../store/modules/chat/functions';
import { Users } from '@poli-ink/react';

function ChatOperator(props) {
  let users = props.users;
  let operator_id = props.operator;

  let operatorName = getOperatorName(users, operator_id);
  const classNoAttendant =
    operatorName === 'Sem atendente' ? 'noAttendant' : '';

  return (
    <span className="chatOperatorContainer small">
      <Users className="iconAttendant" />
      <span
        className={`chat-operator-container-text ${classNoAttendant}`}
        title={operatorName}
      >
        {operatorName}
      </span>
    </span>
  );
}

export default ChatOperator;
