import { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';

import isEmailValid from '../../../../../utils/isEmailValid';

const status = {
  OFF: {
    status: 'off',
    statusColor: '--gray-75',
    message: [
      'Sua conta ainda não está conectada ao Nectar',
      'Siga os passos abaixo para conectar ao Nectar e ter todas as opções disponiveis em sua conta',
    ],
  },
  ON: {
    status: 'on',
    statusColor: '--success',
    message: [
      'Sua conta está conectada',
      'Em caso de alteração no Token ou qualquer outra mudança insira novamente as informações',
    ],
  },
  ERROR: {
    status: 'error',
    statusColor: '--danger',
    message: [
      'Sua conta não está conectada',
      'Suspeitamos que o Token ou E-mail inserido é invalido, revise-o e volte a tentar se conectar a Poli',
    ],
  },
};

function useNectar({ nectarData }) {
  const [nectarStatus, setNectarStatus] = useState(status.OFF);
  const [nectarToken, setNectarToken] = useState('');
  const [nectarEmailInput, setNectarEmailInput] = useState('');
  const [nectarEmails, setNectarEmails] = useState([]);
  const [useAttendantEmail, setUseAttendantEmail] = useState(nectarData.config?.useAttendantEmail);

  const handleToken = useCallback((tkn) => setNectarToken(tkn), [nectarToken]);
  const handleToggleUseAttendantEmail = useCallback(() => setUseAttendantEmail(!useAttendantEmail), [useAttendantEmail]);

  const handleAddEmail = (addEmail) => {
    if (isEmailValid(addEmail)) {
      if (nectarEmails.find((item) => item === addEmail)) {
        toast.warning(`O e-mail ${addEmail} já foi adicionado.`);
      } else {
        setNectarEmails([...nectarEmails, addEmail]);
      }
      setNectarEmailInput('');
    } else {
      toast.error('Por favor, inserir um e-mail válido.');
    }
  };

  const handleRemoveEmail = (removeEmail) => {
    setNectarEmails(nectarEmails.filter((email) => email !== removeEmail));
  };

  useEffect(() => {
    //post request error
    if (nectarData?.error) {
      setNectarStatus(status.ERROR);
    } else if (nectarData?.success) {
      //post request success
      setNectarStatus(status.ON);
      //get request with integration
    } else if (Object.values(nectarData).length) {
      setNectarToken(nectarData.token);
      setNectarEmails(nectarData?.config?.emails);
      setNectarStatus(status.ON);
    }
  }, [nectarData]);

  return {
    nectarStatus,
    nectarToken,
    handleToken,
    nectarEmails,
    nectarEmailInput,
    setNectarEmailInput,
    handleAddEmail,
    handleRemoveEmail,
    handleToggleUseAttendantEmail,
    useAttendantEmail
  };
}

export default useNectar;
