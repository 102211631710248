function NewTicket() {
 
  return (
    <div>
      <p>
        Use tickets para acompanhar todas as perguntas e pedidos de ajuda de
        seus clientes.
      </p>
    </div>
  );
}
export default NewTicket;
