import { CustomInput, Label } from '@polichat/flamboyant';
import { EnumCodeMethod } from '../../../../../services/meta-business/meta/types';

export interface SelectCodeMethodProps {
  value: string;

  disabled?: boolean;
  onChange?: React.ChangeEventHandler<HTMLSelectElement>;
}
export function SelectCodeMethod(props: SelectCodeMethodProps) {
  // ---------------------------------------------
  // Transformations
  // ---------------------------------------------
  // Render

  return (
    <>
      <Label for="select_code_method">
        Selecione como você deseja receber o código de confirmação
      </Label>
      <CustomInput
        type="select"
        id="select_code_method"
        value={props.value}
        onChange={props.onChange}
        disabled={props.disabled}
      >
        <option value={''}>Escolha uma opção</option>
        <option value={EnumCodeMethod.SMS}>SMS</option>
        <option value={EnumCodeMethod.VOICE}>LIGAÇÃO</option>
      </CustomInput>
    </>
  );
}
