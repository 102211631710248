import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useContext,
} from 'react';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ChatActions from '../../../../store/modules/chat/actions';
import {
  treatMessageType,
  syncChatShoppingCartToServer,
  hasProductInStock,
} from '../../../../store/modules/chat/functions';
import TimeMessage from './TimeMessage';
import Ack from './Ack';
// import { DelayedMessageStatus } from './delayedMessageStcarouselContaineratus';
import checkAvatar from '../../../../utils/checkAvatar';
import {
  Icon,
  Button,
  UncontrolledTooltip,
  DropdownIcon,
} from '@polichat/flamboyant';
import { ReactTinyLink } from 'react-tiny-link';

import setBgColorMessages from '../../../../utils/backgroundColorMessages';
import formatText from '../../../../utils/formatText';
import { priceMask } from '../../../../utils/priceMask';

import locationImage from '../../../../assets/images/location-polichat-icon.svg';
import Lightbox from '../../../common/lightbox';
import Avatar from '../../../common/profile/Avatar';

import PayImage from './modals/src/PayImage';
import CartImage from './modals/src/CartImage';
import Audio from '../../message-types/audio/Audio.component';
import { parseMessageTemplate } from '../../../../utils/chat/parseMessageTemplate';
import { WabaTemplateMessage } from '../../message-types/WabaTemplate';

import poliConfig from '../../../../utils/poliConfig';
import { getMessageBuilderTemplateText } from './quickMessages/functions/getMessageBuilderTemplateText';

import { CustomerHasPaymentIntegratorContext } from '../contexts/customerHasPaymentIntegrator/context';
import { toast } from 'react-toastify';
import { useWhiteLabel } from 'src/hooks/useWhiteLabel';

function MessageBalloon({
  props,
  setOpenEditContact,
  setOpenAddContact,
  opennedChat,
  setAddCardContact,
  replyingMessage,
  channelsList,
  currentUser,
  users,
  createShopppingCart,
  addItemToCart,
  //hasPaymentService,
  catalogProducts,
  fetchMoreMessagesRequest,
}) {
  if (!props.messageType) {
    props = treatMessageType(props);
  }

  const [urlPreview, setUrlPreview] = useState();
  const isWl = useWhiteLabel().isWl;
  const wlLogo = useSelector((state) => state.whitelabel.config.logo);

  const linkify = useCallback((text) => {
    try {
      let urlRegex = /(https?:\/\/[^\s]+)/g;
      let urlWithProtocol = undefined;

      if (text?.includes('.')) {
        text
          .replace(urlRegex, function (url) {
            url = url.trim();
            urlWithProtocol = /(http|https|Http|Https|rtsp|Rtsp)/.test(url)
              ? url
              : 'http://' + url;
          })
          .trim();
      }

      const isUrl = new URL(urlWithProtocol);
      if (isUrl) {
        return urlWithProtocol;
      } else {
        return undefined;
      }
    } catch (error) {
      return undefined;
    }
  }, []);

  const { customerHasPaymentIntegrator } = useContext(
    CustomerHasPaymentIntegratorContext
  );

  useEffect(() => {
    if (props?.text?.length > 2) {
      const _url = linkify(props.text);
      setUrlPreview(_url);
    } else {
      setUrlPreview(undefined);
    }
  }, [props?.text]);

  const contentLinkPreview = useMemo(() => {
    if (
      !parseMessageTemplate(props.text).isFromMessageBuilder &&
      urlPreview?.length > 1
    ) {
      return (
        <div className="BalloonLinkPreview">
          <ReactTinyLink
            cardSize="small"
            showGraphic={true}
            maxLine={1}
            minLine={1}
            defaultMedia={
              isWl
                ? wlLogo
                : 'https://app-spa.poli.digital/assets/images/newIdentity/pwa-icon.png'
            }
            url={urlPreview}
            proxyUrl={`${process.env?.REACT_APP_API_GATEWAY_HOST}/proxy`}
          />
        </div>
      );
    }

    return <></>;
  }, [urlPreview]);

  /**
   * Função de ir ate a mensagem que esta respondendo
   */
  const goReplyMessage = (replyMessageUid) => {
    const elem = document.getElementById(replyMessageUid);
    if (elem) {
      elem.scrollIntoView({ behavior: 'smooth' });
    }

    // Essa mensagem não foi encontrada, então vou buscar no banco de dados e tentar ir ate a mensagem novamente
    else {
      // Ordendando as mensagens
      let currentMessages = opennedChat?.messages?.sort(function (a, b) {
        if (a.dtm === b.dtm) {
          if (a.id > b.id) return 1;
          else if (a.id < b.id) return -1;
          else return 0;
        }

        if (a.dtm > b.dtm) return 1;
        else if (a.dtm < b.dtm) return -1;
        else return 0;
      });

      // Apos ordenar as mensagens irei buscar mais no banco de dados.
      if (currentMessages && !opennedChat.finishingNewMessages) {
        const lastMessageDtm = currentMessages[0]?.dtm;
        if (lastMessageDtm) {
          fetchMoreMessagesRequest(opennedChat.id, lastMessageDtm);

          // dando meio segundo para deixar SPA respirar.
          setTimeout(() => {
            goReplyMessage(replyMessageUid);
          }, 500);
        }
      }
    }
  };
  /* end - Função de ir ate a mensagem que esta respondendo */

  // Detectando Usuário que enviou a mensagem
  let userSendedMessagePicture, userSendedMessage, userSendedMessageName;
  if (users && props.authorMessageId) {
    userSendedMessage = users.find((user) => user.id === props.authorMessageId);
    // UR significa que o contato foi direcionado pela URA
    userSendedMessagePicture = userSendedMessage
      ? checkAvatar(userSendedMessage.name, userSendedMessage.picture)
      : 'BOT';
    userSendedMessageName = userSendedMessage?.name || 'Mensagem do Sistema';
  }
  const messageDirection = props.direction;
  const messageMimeType = props.mimeType;
  const messageDocumentType = props.documentType;
  const messageFormat = formatText(props.text, 'whatsapp');
  var iframeRegex = /(<iframe.*>.*< *\/ *iframe *>)/g;

  const time = props.time;

  const message = messageFormat.match(iframeRegex) ? (
    <p id="messageMain">{messageFormat}</p>
  ) : parseMessageTemplate(props.text).isFromMessageBuilder ? (
    <>
      <WabaTemplateMessage message={props.text} />
    </>
  ) : (
    <p
      id="messageMain"
      dangerouslySetInnerHTML={{
        __html: messageFormat,
      }}
    ></p>
  );

  const deletedMessage = (
    <div>
      <p className="message-info-baloon">
        Essa mensagem foi deletada!
        <Icon
          size={22}
          color={'var(--danger)'}
          icon={'poli-icon pi-delete-line'}
        />
      </p>
    </div>
  );

  const [isMouseEnter, setIsMouseEnter] = useState(false);

  // const [openMessageOptionsModal, setOpenMessageOptionsModal] = useState(false);
  // const toggle = () => setOpenMessageOptionsModal((prevState) => !prevState);

  const isAdReply = props.messageType === 'ad_reply';
  const adBody = isAdReply ? JSON.parse(props.text) : null;

  const urlMedia = isAdReply ? adBody.body.url : props.url;
  const ack = props.ack;

  let previewMedia;

  let isLoading = false;

  let isMedia = isAdReply ? !!adBody.body.mimetype : props.isMedia;
  let vCard = props.vCard;

  let Product = props.product;
  let Payment = props.payment;
  /**
   * Manipulação da thumb
   */
  if (!props.thumb) {
    previewMedia = urlMedia;
  } else if (
    props.thumb.substr(0, 4) === 'data' ||
    props.thumb.substr(0, 4) === 'http' ||
    props.thumb.substr(0, 4) === 'blob'
  ) {
    previewMedia = props.thumb;
  } else {
    previewMedia = 'data:image/png;base64,' + props.thumb;
  }

  isLoading = isMedia && props.direction === 'sent' && ack === '';

  /**
   * Enviar contato para formulário de adição de contato
   * @param {} contact
   */
  function handleAddContact(contact) {
    setOpenEditContact(false);
    setOpenAddContact(true);
    setAddCardContact(contact);
  }

  function reply_message() {
    let message_data = { chat_id: opennedChat.id, message_uid: props.uid };
    replyingMessage(message_data);
  }

  function addMessageProductToCart() {
    if (!customerHasPaymentIntegrator) {
      toast.warning('Não foi encontrado nenhum método de pagamento ativo!');
      return;
    }

    if (!Product) return;

    if (opennedChat.cart && opennedChat.cart.id) {
      addItemToCart(opennedChat.id, Product.product_id);
      syncChatShoppingCartToServer(opennedChat, opennedChat.cart.id);
    } else {
      let hasInStock = hasProductInStock(
        catalogProducts,
        Product.product_id,
        0
      );

      if (hasInStock)
        createShopppingCart({
          contact_id: opennedChat.id,
          products: [
            {
              id: Product.product_id,
              price: Product.price,
              quantity: 1,
              name: Product.name,
            },
          ],
          address: null,
        });
    }
  }

  /**
   * Criação dos contents de acordo com o tipo da mensagem
   */
  const contentError = (type) => {
    console.log('contentError', type);
    return (
      <div>
        <div
          className="mx-auto text-center mb-1"
          style={{
            borderRadius: '50%',
            background: 'white',
            width: 'fit-content',
            marginTop: '5px',
            marginLeft: '5px',
          }}
        >
          <Icon
            size={45}
            color={'var(--activated-icons-color)'}
            icon={'poli-icon pi-poly-crazy-fill	'}
          />
        </div>
        <p>Ops, algo deu errado, {type}</p>
      </div>
    );
  };

  const image = (
    <div className="mediaMessage image">
      <div
        rel="noopener noreferrer"
        className="mobileSize"
        id={`media-${props.messageId}`}
      >
        {/* TEMPORARY FIX by Matheus Costa */}
        {/* {isLoading ? (
          <div>
            <img
              src={previewMedia}
              width="150"
              alt="media"
              type={messageMimeType || 'image'}
            />
            <Animation icon="loading" size={'tiny'} />
          </div>
        ) : */}
        {props.errorMessage ? (
          contentError('com sua image.')
        ) : (
          <Lightbox
            authorContent={
              messageDirection === 'received' ? (
                <div className="contactInfo">
                  <Avatar
                    className="contact"
                    url={opennedChat.contact.picture}
                    title={opennedChat.contact.name}
                    alt={opennedChat.contact.name}
                    size={36}
                  />
                </div>
              ) : (
                // sent
                <div className="contactInfo">
                  <Avatar
                    className="operator"
                    url={
                      userSendedMessagePicture ? userSendedMessagePicture : ''
                    }
                    size={36}
                    title={userSendedMessageName ? userSendedMessageName : ''}
                    alt={userSendedMessageName ? userSendedMessageName : ''}
                  />
                </div>
              )
            }
            id={`image-${props.messageId}`}
            src={urlMedia}
            thumb={previewMedia}
            width="150"
            alt="media"
            type={'image'}
            messageId={props.messageId}
            isLoading={isLoading}
          />
        )}
      </div>
      {props.caption ? <p>{props.caption}</p> : ''}
    </div>
  );

  const application = (
    <>
      <div className="mediaContainer aplication">
        <a
          href={urlMedia}
          rel="noopener noreferrer"
          target="_blank"
          className="mediaContainerFlex"
        >
          {/* TEMPORARY FIX by Matheus Costa */}
          {/* {isLoading ? (
            <Animation icon="loading" size={'tiny-sended-media'} />
          ) :  */}
          {props.errorMessage ? (
            contentError('com seu arquivo.')
          ) : messageDocumentType && messageDocumentType === 'compacted' ? (
            <Icon icon="poli-icon pi-zip-fill" color="var(--white)" size={46} />
          ) : messageDocumentType === 'textEditor' ? (
            <Icon
              icon="poli-icon pi-word-file-fill"
              size={46}
              color="var(--primary)"
            />
          ) : messageDocumentType === 'textSheet' ? (
            <Icon
              icon="poli-icon pi-excel-file-fill"
              size={46}
              color="var(--success)"
            />
          ) : messageDocumentType === 'apresentation' ? (
            <Icon
              icon="poli-icon pi-apresentation-file-fill"
              size={46}
              color="var(--warning)"
            />
          ) : messageDocumentType === 'pdf' ? (
            <Icon
              icon="poli-icon pi-pdf-file-fill"
              size={46}
              color="var(--danger)"
            />
          ) : (
            <Icon
              icon="poli-icon pi-file-fill"
              size={46}
              color={'var(--white)'}
            />
          )}
          <p>{props.fileName}</p>
        </a>
      </div>
      <p>{props.caption !== props.fileName ? props.caption : ''}</p>
    </>
  );

  const unsupportedType = (
    <>
      <p style={{ fontStyle: 'italic' }}>
        Ops, algo deu errado, mensagem não suportada
      </p>
    </>
  );

  const videoPlayer = (
    <div className="mediaMessage videoPlayer">
      <div rel="noopener noreferrer" id={`media-${props.messageId}`}>
        {/* TEMPORARY FIX by Matheus Costa */}
        {/* {isLoading ? (
          <div>
            <Animation icon="loading" size={'tiny'} />
          </div>
        ) : */}
        {props.errorMessage ? (
          contentError('com seu video.')
        ) : (
          <Lightbox
            authorContent={
              messageDirection === 'received' ? (
                <div className="contactInfo">
                  <Avatar
                    className="contact"
                    url={opennedChat.contact.picture}
                    size={36}
                    title={opennedChat.contact.name}
                  />
                </div>
              ) : (
                // sent
                <div className="contactInfo">
                  <Avatar
                    className="operator"
                    url={currentUser.picture}
                    size={36}
                    title={opennedChat.contact.name}
                  />
                </div>
              )
            }
            src={urlMedia}
            thumb={previewMedia}
            width="150"
            alt="media"
            type={'video'}
            messageId={props.messageId}
            isLoading={isLoading}
          />
        )}
      </div>
      {props.caption ? <p>{props.caption}</p> : ''}
    </div>
  );

  const location = (
    <div className="mediaContainer mediaContainerGrid">
      <img
        width="60"
        height="60"
        src={locationImage}
        alt="imagem de localização"
      />
      <p>
        <strong>Localização de {props.location.name}</strong>
      </p>
      <Button
        color="success"
        size="lg"
        onClick={() =>
          window.open(
            `https://maps.google.com/maps?q=${props.location.latitude},${props.location.longitude}&amp;hl=pt-BR`,
            '_blank'
          )
        }
      >
        Abrir Localização
      </Button>
    </div>
  );

  const vcard = (
    <div className="mediaContainer mediaContainerGrid">
      <div className="vCardIcon">
        <Icon
          icon="poli-icon pi-perfil-line"
          size={36}
          color="var(--activated-icons-color)"
        />
      </div>
      <p className="vCardText">
        {vCard
          ? vCard.name && (
              <>
                <strong>Nome: </strong>
                {vCard.name}
              </>
            )
          : ''}
        <br />
        {vCard
          ? vCard.phone && (
              <>
                <strong>Número: </strong>
                {vCard.phone}
              </>
            )
          : ''}
        <br />
        {vCard
          ? vCard.email && (
              <>
                <strong>Email: </strong>
                {vCard.email}
              </>
            )
          : ''}
        <br />
      </p>
      <Button
        color="primary btn-vcard"
        ClassName="btn-vcard"
        size="lg"
        onClick={() => handleAddContact(vCard)}
      >
        Adicionar este contato
      </Button>
    </div>
  );

  const multiVcard = (
    <>
      {Array.isArray(vCard) &&
        vCard.map((item, key) => {
          return (
            <div className="mediaContainer mediaContainerGrid" key={key}>
              <div className="vCardIcon">
                <Icon
                  icon="poli-icon pi-perfil-line"
                  size={36}
                  color="var(--activated-icons-color)"
                />
              </div>
              <p className="vCardText">
                {item?.name && (
                  <>
                    <strong>Nome: </strong>
                    {item.name}
                  </>
                )}

                <br />
                {item?.phone && (
                  <>
                    <strong>Número: </strong>
                    {item.phone}
                  </>
                )}
                <br />
                {item?.email && (
                  <>
                    <strong>Email: </strong>
                    {item.email}
                  </>
                )}
                <br />
              </p>
              <Button
                color="primary btn-vcard"
                ClassName="btn-vcard"
                size="lg"
                onClick={() => handleAddContact(item)}
              >
                Adicionar este contato
              </Button>
            </div>
          );
        })}
    </>
  );

  const link = (
    <>
      <p
        dangerouslySetInnerHTML={{
          __html: messageFormat,
        }}
      ></p>

      {props.errorMessage ? (
        <div className="mediaContainer">
          <div className="mediaContainerFlex">
            <Icon icon="poli-icon pi-link-fill" size={46} color="#000" />
            <span style={{ color: '#000' }}>Link Indisponível</span>
          </div>
        </div>
      ) : (
        <div className="mediaContainer">
          <a
            className="mediaContainerFlex"
            href={urlMedia}
            rel="noopener noreferrer"
            target="_blank"
          >
            {previewMedia !== urlMedia ? (
              // mensagem url com thumb
              <>
                <img src={previewMedia} width="60" height="60" alt="media" />
                <p>
                  {props.caption}
                  <br />
                  <span>{urlMedia}</span>
                </p>
              </>
            ) : (
              <>
                <Icon icon="poli-icon pi-link-fill" size={46} color="#fff" />
                <span style={{ color: '#ccc' }}>{urlMedia}</span>
              </>
            )}
          </a>
        </div>
      )}
    </>
  );

  const product = (
    <div className="mediaMessage image">
      <img src={Product?.picture} alt={Product?.name} width="90" />
      <div className="productBallon">
        <span>{Product?.name}</span>
        <br />
        <span>
          {Product?.price ? `Por: ${priceMask(Product?.price + '')}` : ''}
        </span>
        <p>{Product?.description}</p>

        <div className="productButtonContainer">
          <Button
            className="addToCartProductButton"
            onClick={addMessageProductToCart}
          >
            <Icon icon="poli-icon pi-ecommerce-line" size={24} />
            Adicionar ao carrinho
          </Button>
        </div>
      </div>
    </div>
  );
  const payment = (
    <div className="mediaMessage image">
      <div className="paymentImage">
        {Payment?.reference_type === 'debt' ? <PayImage /> : <CartImage />}
      </div>
      <div className="productBallon">
        {Payment?.reference_type === 'debt' ? (
          <p>
            Olá, {opennedChat.contact?.name}, segue a solicitação de pagamento
            referente: {Payment?.description} <br />
            <span>
              <strong>Valor: {priceMask(Payment?.value + '')}</strong>
            </span>
            <br />
            <span>
              <strong>Clique no link para pagar</strong>:
            </span>
            <br />
            <a href={Payment?.link} target="blank">
              {Payment?.link}
            </a>
          </p>
        ) : (
          <p>
            Olá {opennedChat.contact?.name}, o seu pedido{' '}
            <strong>{Payment?.reference_id}</strong> foi criado.
            <br />
            <span>
              <strong>
                {Payment?.value
                  ? `Valor: ${priceMask(Payment?.value + '')}`
                  : ''}
              </strong>
            </span>
            <br />
            <span>
              <strong>Clique no link para pagar</strong>:
            </span>
            <br />
            <a href={Payment?.link} target="blank">
              {Payment?.link}
            </a>
          </p>
        )}
      </div>
    </div>
  );

  let storyObj = null;

  // the json story in the body message attribute are received at pros text
  if (
    props.messageType == 'story_mention' ||
    props.messageType == 'story_reply'
  ) {
    storyObj = JSON.parse(props.text);

    let dayInMilliseconds = 24 * 60 * 60 * 1000;
    let timeNowInMilliseconds = new Date().getTime();
    let messageTimeInMilliseconds = props.time * 1000; //at DataBase DTM is saved divided by 1000

    if (
      storyObj &&
      timeNowInMilliseconds - messageTimeInMilliseconds >= dayInMilliseconds
    ) {
      storyObj.unavailable = true;
    }
  }

  const callMissedVoice = (
    <div>
      <div className="callMissed">
        <Icon
          icon="poli-icon pi-phone-line"
          color={'var(--danger)'}
          size={22}
        />
        <span>Chamada de voz perdida!</span>
      </div>
    </div>
  );

  const callMissedVideo = (
    <div>
      <div className="callMissed">
        <Icon
          icon="poli-icon pi-camera-video-fill"
          color={'var(--danger)'}
          size={22}
        />
        <span>Chamada de vídeo perdida!</span>
      </div>
    </div>
  );

  const callVoice = (
    <div>
      <div className="callReceived">
        <Icon
          icon="poli-icon pi-phone-line"
          color={'var(--success)'}
          size={22}
        />
        <span>Recebendo chamada de voz!</span>
      </div>
    </div>
  );

  const waitingMessage = (
    <div>
      <div className="waitingMessage">
        <Icon
          icon="poli-icon pi-clock-line"
          color={'var(--info-75)'}
          size={22}
        />
        <span>Aguardando essa mensagem. Isso pode demorar um pouco.</span>
      </div>
    </div>
  );

  const callVideo = (
    <div>
      <div className="callReceived">
        <Icon
          icon="poli-icon pi-camera-video-fill"
          color={'var(--success)'}
          size={22}
        />
        <span>Recebendo chamada de vídeo!</span>
      </div>
    </div>
  );

  const story = (
    <>
      <div className="replied_message">
        <div
          className="replyMessageText"
          style={{
            borderLeft: `5px solid ${setBgColorMessages(
              opennedChat.contact.picture,
              'replyInBalloon'
            )}`,
          }}
        >
          <p
            className="text"
            style={{ paddingBottom: '5px', fontStyle: 'italic' }}
          >
            {storyObj?.text
              ? 'Respondeu você no story'
              : ' Mencionou você no story'}
          </p>
        </div>
      </div>
      {storyObj?.unavailable || props?.errorMessage ? (
        <p
          className="text"
          style={{ paddingBottom: '5px', fontStyle: 'italic' }}
        >
          Story não está mais disponível
        </p>
      ) : (
        <div className="mediaMessage image">
          <div rel="noopener noreferrer" id={`media-${props.messageId}`}>
            {/* TEMPORARY FIX by Matheus Costa */}
            {/* {isLoading ? (
              <div>
                <Animation icon="loading" size={'tiny'} />
              </div>
            ) : ( */}
            <Lightbox
              authorContent={
                messageDirection === 'received' ? (
                  <div className="contactInfo">
                    <Avatar
                      className="contact"
                      url={opennedChat.contact.picture}
                      title={opennedChat.contact.name}
                      alt={opennedChat.contact.name}
                      size={36}
                    />
                  </div>
                ) : (
                  // sent
                  <div className="contactInfo">
                    <Avatar
                      className="operator"
                      url={
                        userSendedMessagePicture ? userSendedMessagePicture : ''
                      }
                      size={36}
                      title={userSendedMessageName ? userSendedMessageName : ''}
                      alt={userSendedMessageName ? userSendedMessageName : ''}
                    />
                  </div>
                )
              }
              detail_message_type="instagram_story"
              src={storyObj?.story?.url}
              thumb={previewMedia}
              width="150"
              alt="media"
              type={storyObj?.story?.type ? storyObj?.story?.type : 'image'}
              messageId={props.messageId}
              isLoading={isLoading}
            />
            {/* )} */}
          </div>
          {storyObj?.text ? <p>{storyObj?.text}</p> : ''}
        </div>
      )}
    </>
  );

  function renderMessageOptions() {
    let showReplyOption = props.uid ? true : false;

    let showDropDownOptions = false;

    if (showReplyOption) showDropDownOptions = true;

    if (!showDropDownOptions) return null;

    if (showDropDownOptions && isMouseEnter) {
      return (
        // <div className="message_actions">
        //   <Dropdown
        //     direction="down"
        //     isOpen={openMessageOptionsModal}
        //     toggle={toggle}
        //   >
        //     <DropdownToggle border="border-none" color="link">
        //       <Icon
        //         icon="poli-icon pi-simple-arrow-down-line"
        //         color={'var(--tertiary-font-color)'}
        //         size={22}
        //       />
        //     </DropdownToggle>
        //     <DropdownMenu right>
        //       {showReplyOption ? (
        //         <DropdownItem onClick={reply_message} key={0}>
        //           <p className="alternative">Responder</p>
        //         </DropdownItem>
        //       ) : null}
        //     </DropdownMenu>
        //   </Dropdown>
        // </div>
        <>
          <DropdownIcon>
            {!poliConfig.channelsCantReplyMessage.includes(
              opennedChat?.view?.selectedChannel?.type
            ) ? (
              <Button
                onClick={reply_message}
                key={0}
                color="reply-collapse-itens"
              >
                <Icon size={14} icon={'poli-icon pi-reply-arrow-fill'} />
                Responder
              </Button>
            ) : null}
          </DropdownIcon>
        </>
      );
    } else {
      return null;
    }
  }

  function renderReplyMessage() {
    if (props.reply_message && props.reply_message.uid) {
      let replyMessage = Object.assign(
        {},
        treatMessageType(props.reply_message)
      );

      let content = null;
      let miniature = null;

      if (replyMessage.documentType) {
        switch (replyMessage.documentType) {
          case 'image':
          case 'sticker':
            content = (
              <span>
                <Icon icon="poli-icon pi-photo-fill" size={12} /> Foto
              </span>
            );
            miniature =
              replyMessage.thumb || replyMessage.url ? (
                <img
                  src={
                    replyMessage?.thumb &&
                    replyMessage.thumb !== replyMessage.url
                      ? `data:${replyMessage?.mimeType};base64,${replyMessage?.thumb}`
                      : replyMessage?.url
                  }
                  width={60}
                />
              ) : (
                <Icon
                  icon="poli-icon pi-video-fill"
                  color="var(--white)"
                  size={50}
                />
              );
            break;
          case 'audio':
            content = (
              <span>
                <Icon
                  icon="poli-icon pi-microphone-fill"
                  size={12}
                  color={'var(--icon-color-font)'}
                />{' '}
                Áudio
              </span>
            );
            miniature = (
              <Icon
                icon="poli-icon pi-microphone-fill"
                color={'var(--icon-color-font)'}
                size={50}
                position={'relative'}
                top={10}
                right={5}
              />
            );
            break;
          case 'video':
            content = (
              <span>
                <Icon icon="poli-icon pi-camera-video-fill" size={12} /> Video
              </span>
            );
            miniature =
              replyMessage.thumb && replyMessage.mimeType ? (
                <img
                  src={`data:${replyMessage.mimeType};base64,${replyMessage.thumb}`}
                  width={60}
                />
              ) : (
                <Icon
                  icon="poli-icon pi-video-line"
                  color="var(--white)"
                  size={50}
                  position={'relative'}
                  top={10}
                  right={5}
                />
              );
            break;
          case 'compacted':
            content = (
              <span>
                <Icon icon="poli-icon pi-zip-line" size={12} /> Documento
                compactado
              </span>
            );
            miniature = (
              <Icon
                icon="poli-icon pi-zip-line"
                color="var(--white)"
                size={50}
                position={'relative'}
                top={10}
                right={5}
              />
            );
            break;
          case 'textEditor':
            content = (
              <span>
                <Icon icon="poli-icon pi-word-file-fill" size={12} /> Documento
                de texto
              </span>
            );
            miniature = (
              <Icon
                icon="poli-icon pi-word-file-fill"
                color="var(--white)"
                size={50}
                position={'relative'}
                top={10}
                right={5}
              />
            );
            break;
          case 'textSheet':
            content = (
              <span>
                <Icon icon="poli-icon pi-excel-file-fill" size={12} /> Planilha
              </span>
            );
            miniature = (
              <Icon
                icon="poli-icon pi-excel-file-fill"
                color="var(--white)"
                size={50}
                position={'relative'}
                top={10}
                right={5}
              />
            );
            break;
          case 'pdf':
            content = (
              <span>
                <Icon icon="poli-icon pi-pdf-file-fill" size={12} /> PDF
              </span>
            );
            miniature = (
              <Icon
                icon="poli-icon pi-pdf-file-line"
                color="var(--white)"
                size={50}
                position={'relative'}
                top={10}
                right={5}
              />
            );
            break;
          case 'apresentation':
            content = (
              <span>
                <Icon icon="poli-icon pi-apresentation-file-line" size={12} />{' '}
                Apresentação
              </span>
            );
            miniature = (
              <Icon
                icon="poli-icon pi-apresentation-file-line"
                color="var(--white)"
                size={50}
                position={'relative'}
                top={10}
                right={5}
              />
            );
            break;
          case 'file':
            content = (
              <span>
                <Icon icon="poli-icon pi-file-line" size={12} /> Documento
              </span>
            );
            miniature = (
              <Icon
                icon="poli-icon pi-file-line"
                color="var(--white)"
                size={50}
                position={'relative'}
                top={10}
                right={5}
              />
            );
            break;
          case 'url':
          case 'link':
            content = (
              <span>
                <Icon icon="poli-icon pi-link-line" size={12} /> Link
              </span>
            );
            miniature = (
              <Icon
                icon="poli-icon pi-link-line"
                color="var(--white)"
                size={50}
                position={'relative'}
                top={10}
                right={5}
              />
            );
            break;
          case 'vcard':
            content = (
              <span>
                <Icon icon="poli-icon pi-contacts-line" size={12} /> Contato
              </span>
            );
            miniature = (
              <Icon
                icon="poli-icon pi-contacts-line"
                color="var(--white)"
                size={50}
                position={'relative'}
                top={10}
                right={5}
              />
            );
            break;
          case 'multi_vcard':
            content = (
              <span>
                <Icon icon="poli-icon pi-contacts-line" size={12} /> Contato
              </span>
            );
            miniature = (
              <Icon
                icon="poli-icon pi-contacts-line"
                color="var(--white)"
                size={50}
                position={'relative'}
                top={10}
                right={5}
              />
            );
            break;
          case 'location':
            content = (
              <span>
                <Icon icon="poli-icon pi-location-line" size={12} /> Localização
              </span>
            );
            miniature = (
              <img
                width="50"
                height="50"
                src={locationImage}
                alt="imagem de localização"
              />
            );
            break;
          case 'product':
            content = (
              <span>
                {replyMessage.product?.name
                  ? replyMessage.product?.name
                  : 'Catalogo'}
              </span>
            );
            miniature = (
              <img
                src={
                  replyMessage.product &&
                  replyMessage.product?.picture &&
                  replyMessage.product?.picture
                }
                width={50}
                height={50}
                alt={
                  replyMessage.product?.name
                    ? replyMessage.product?.name
                    : 'Catalogo'
                }
              />
            );
            break;
          case 'payment_link':
            content = <span>Pagamento</span>;
            miniature = (
              <div className="paymentImage">
                {replyMessage.payment?.reference_type === 'debt' ? (
                  <PayImage width="90" height="90" />
                ) : (
                  <CartImage width="90" height="90" />
                )}
              </div>
            );
            break;
          case 'chat':
          default:
            content = (
              <span>{getMessageBuilderTemplateText(replyMessage.text)}</span>
            );
            break;
        }
      }

      return (
        <div
          className="replied_message"
          onClick={() => goReplyMessage(props.reply_message.uid)}
        >
          <div
            className="replyMessageText"
            style={{
              borderLeft: `5px solid ${setBgColorMessages(
                opennedChat.contact.picture,
                'replyInBalloon'
              )}`,
            }}
          >
            <div>
              <p
                className="authorName"
                style={{
                  color: setBgColorMessages(
                    opennedChat.contact.picture,
                    'replyInBalloon'
                  ),
                }}
              >
                {props.reply_message.author_name
                  ? props.reply_message.author_name
                  : props.reply_message.dir === 'o'
                  ? 'Você'
                  : opennedChat.contact?.name}
              </p>
              <p className="text">
                {content}
                {/* <br />
                {props.reply_message.file_name
                  ? props.reply_message.file_name
                  : props.reply_message.text} */}
              </p>
            </div>
            {miniature}
          </div>
        </div>
      );
    } else {
      return null;
    }
  }

  function renderAuthorMessageWhenGroup() {
    if (props.chatType && props.author_name && props.chatType === 'group') {
      return <div className="authorMessageWhenGroup">{props.author_name}</div>;
    } else {
      return null;
    }
  }

  function renderMessageChannel() {
    if (props.channelId) {
      let canal = channelsList.find(
        (channel) => channel.id === props.channelId
      );

      if (!canal) {
        canal = {
          name: 'Canal não identificado',
          icon: 'poli-icon pi-alert-fill',
        };
      }
      return (
        <>
          <div className="messageChannel" id={'canal' + canal.id}>
            <Icon icon={canal.icon} color={canal.icon_color} size={14} />
          </div>
          <UncontrolledTooltip placement="bottom" target={'canal' + canal.id}>
            {canal.name}
          </UncontrolledTooltip>
        </>
      );
    }
  }

  const messageRealType = isAdReply ? adBody.type : props.messageType;

  return (
    <div
      className={['messageBalloon', props.messageType].join(' ')}
      id={props.uid}
      onMouseEnter={() => {
        setIsMouseEnter(true);
      }}
      onMouseLeave={() => {
        setIsMouseEnter(false);
      }}
    >
      {(() => {
        let content = props.deletedAt ? (
          deletedMessage
        ) : messageRealType === 'audio' || messageRealType === 'ptt' ? (
          <>
            {isAdReply ? message : ''}
            <div className="audioMessage">
              <Audio
                urlBase64={props.urlBase64}
                errorMessage={props.errorMessage}
                messageId={props.messageId}
                isLoading={isLoading}
                urlMedia={urlMedia}
                mimeType={props.mimeType}
                authorName={props.authorMessage}
                messageTime={props.time}
                messageDirection={props.direction}
                reply_message={reply_message}
              />
            </div>
          </>
        ) : messageRealType === 'video' ? (
          <>
            {isAdReply ? message : ''}
            {videoPlayer}
          </>
        ) : messageRealType === 'image' ? (
          <>
            {isAdReply ? message : ''}
            {image}
          </>
        ) : messageRealType === 'sticker' ? (
          <>
            {isAdReply ? message : ''}
            {image}
          </>
        ) : messageRealType === 'application' ? (
          application
        ) : messageRealType === 'unsupported_type' ? (
          unsupportedType
        ) : messageRealType === 'url' ? (
          link
        ) : messageRealType === 'link' ? (
          link
        ) : messageRealType === 'document' ? (
          application
        ) : messageRealType === 'compacted' ? (
          application
        ) : messageRealType === 'location' ? (
          location
        ) : messageRealType === 'vcard' ? (
          vcard
        ) : messageRealType === 'multi_vcard' ? (
          multiVcard
        ) : messageRealType === 'product' ? (
          product
        ) : messageRealType === 'payment_link' ? (
          payment
        ) : messageRealType === 'story_mention' ? (
          story
        ) : messageRealType === 'story_reply' ? (
          story
        ) : messageRealType === 'call_missed_voice' ? (
          callMissedVoice
        ) : messageRealType === 'call_missed_video' ? (
          callMissedVideo
        ) : messageRealType === 'call_voice' ? (
          callVoice
        ) : messageRealType === 'call_video' ? (
          callVideo
        ) : messageRealType === 'waiting_message' ? (
          waitingMessage
        ) : (
          message
        );

        switch (messageDirection) {
          case 'received':
            return (
              <div className="receivedMessageBalloon">
                {renderMessageChannel()}
                <span className="receivedInfo">{renderMessageOptions()}</span>
                {renderReplyMessage()}
                {renderAuthorMessageWhenGroup()}
                {contentLinkPreview}
                {content}
                <span className="messageInfo">
                  <TimeMessage time={time} />
                </span>
              </div>
            );
          case 'sent':
            return (
              <div className="sendedMessageBalloon">
                {renderMessageChannel()}
                <span className="sendedInfo">{renderMessageOptions()}</span>
                {renderReplyMessage()}
                {contentLinkPreview}
                {content}
                <span className="messageInfo">
                  <TimeMessage time={time} send />
                  <Ack ack={ack} />
                </span>
                {/* <DelayedMessageStatus ack={ack} /> */}
              </div>
            );
          default:
            return <></>;
        }
      })()}
    </div>
  );
}
const mapStateToProps = (state, ownProps) => ({
  props: ownProps,
  opennedChat:
    state.chat?.chats?.filter((chat) => {
      return chat.open === true;
    })[0] || [],
  channelsList: state.chat?.config?.general?.channels,
  users: state.chat?.config?.general.users,
  currentUser: state.chat?.config?.user,
  //hasPaymentService: state.chat?.config?.general?.settings?.payment_service,
  catalogProducts: state.chat?.catalog?.products,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(ChatActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MessageBalloon);
