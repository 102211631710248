import {
  Button,
  Col,
  Form,
  FormGroup,
  Icon,
  Input,
  InputGroup,
  InputGroupAddon,
  Row,
} from '@polichat/flamboyant';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import * as ChatActions from '../../../../../store/modules/chat/actions';
import Animation from '../../../../common/animation';
import { getMessageBuilderTemplateText } from './functions/getMessageBuilderTemplateText';
import { QuickMessages } from './QuickMessages';
import { useCallback } from 'react';
import { useFlags } from 'flagsmith/react';
var textInit;

function QuickMessageSelector({
  chat,
  quickMessages,
  opennedChat,
  fetchQuickMessages,
  createQuickMessage,
  updateQuickMessage,
  toggleQuickMessagesSelector,
  editQuickMessage,
  cancelEditQuickMessage,
  cancelDeleteQuickMessage,
  alertDeleteQuickMessage,
  deleteQuickMessage,
  props,
}) {
  const flags = useFlags(['enable_send_interactive_msg']);

  const flagInteractiveV2 = flags?.enable_send_interactive_msg || {}
  const flagInteractiveV2Enabled = flagInteractiveV2?.enabled || false;
  const flagInteractiveV2Channels = flagInteractiveV2Enabled
    ? (() => {
        try {
          return JSON.parse(flagInteractiveV2?.value)?.channels || [];
        } catch (e) {
          return []; // Prevents break SPA on the JSON parse.
        }
      })()
    : [];

  props.onUpdateMessage = props.onUpdateMessage || function () {};
  props.onlyTemplate = props.onlyTemplate || false;
  let content = null;
  let user = chat.config.user;
  function handleKeyboardShortcutKeyPressEvent(event) {
    if (event.nativeEvent.keyCode > 32 && event.nativeEvent.keyCode < 127) {
      let text = event.nativeEvent.key;
      if (
        !QuickMessages.searchFromKeyboardShortcut(
          'Ctrl+' + event.nativeEvent.key,
          chat
        )
      ) {
        document.querySelector('#textKeyboardShortcut').value = text;
        //document.querySelector('#infoKeyboardShortcut').innerHTML = '';
      } else {
        let message = QuickMessages.getMessage();
        let warning = 'Ctrl+' + text + ' já está sendo usado';
        if (message) {
          warning += ' em #' + message.tag;
        }
        toast.warning(warning);
        //document.querySelector('#infoKeyboardShortcut').innerHTML = warning;
      }
      event.nativeEvent.stopPropagation();
      event.nativeEvent.preventDefault();
      return false;
    }
  }
  const setHasButtonsMessage = useCallback((data) => {
    return data.template_name?.indexOf('buttons') > -1;
  }, []);
  function saveQuickMessage(message) {
    let data = {};
    if (message) {
      data = message;
    } else {
      data.tag = quickMessages.term;
    }
    data.text = document.querySelector('#textAreaQuickMessage').value.trimEnd();
    data.keyboard_shortcut = document
      .querySelector('#textKeyboardShortcut')
      .value.trimEnd();
    if (message) {
      updateQuickMessage(data);
    } else {
      createQuickMessage(data);
    }
  }

  if (quickMessages && quickMessages.isOpen) {
    if (quickMessages.loadingForm) {
      content = (
        <div className="loadingFormQuickMessagesContainer">
          <Animation icon="loading" size="tiny" />
          <small>{'Salvando mensagem'}</small>
        </div>
      );
    } else if (quickMessages.loading) {
      content = (
        <div className="loadingQuickMessagesContainer">
          <Animation icon="loading" size="tiny" />
          <small>{'Buscando mensagens'}</small>
        </div>
      );
    } else if (quickMessages.delete) {
      content = (
        <div className="errorAlertContainer">
          <Icon icon={'poli-icon pi-alert-fill'} size={20} />
          <small>
            <strong>Atenção!</strong> Deseja realmente excluir essa mensagem?
          </small>
          <Button
            color="danger"
            className="buttonReloadQuickMessages"
            onClick={() => deleteQuickMessage(quickMessages.delete)}
          >
            Sim
          </Button>
          <Button
            color="primary"
            className="buttonReloadQuickMessages"
            onClick={() => cancelDeleteQuickMessage()}
          >
            Não
          </Button>
        </div>
      );
    } else if (quickMessages.failure) {
      content = (
        <div className="errorAlertContainer">
          <Icon icon={'poli-icon pi-sad-fill'} size={20} />
          <small>
            <strong>Erro</strong> ao carregar mensagens
          </small>
          <Button
            color="danger"
            className="buttonReloadQuickMessages"
            onClick={() => fetchQuickMessages()}
          >
            Tentar novamente
          </Button>
        </div>
      );
    } else if (quickMessages.messages) {
      QuickMessages.setState(chat);
      let list = quickMessages.messages;
      if (quickMessages.search && quickMessages.term) {
        list = quickMessages.search;
      }

      if (
        (quickMessages.term && !list.length) ||
        quickMessages.createErrorData ||
        (quickMessages.edit && quickMessages.edit.id)
      ) {
        let term = quickMessages.term;
        let keyboard_shortcut = '';
        let text = '';
        let message = null;
        let linkVoltar = null;
        let is_template = false;
        let is_templateList = false;
        let is_buttonsMessage = false;

        if (quickMessages.createErrorData) {
          term = quickMessages.createErrorData.tag;
          keyboard_shortcut = quickMessages.createErrorData.keyboard_shortcut;
          text = quickMessages.createErrorData.text;
          toast.error('Erro ao tentar salvar!');
        } else if (quickMessages.edit && quickMessages.edit.id) {
          if (quickMessages.editError) {
            toast.error('Erro ao tentar salvar!');
          }
          is_template =
            quickMessages.edit.template && quickMessages.edit.template > 0;
          is_templateList =
            quickMessages.edit.template_name?.indexOf('list') > -1;
          is_buttonsMessage = setHasButtonsMessage(quickMessages);
          message = quickMessages.edit;
          term = quickMessages.edit.tag;
          keyboard_shortcut = (
            quickMessages.edit.keyboard_shortcut || ''
          ).replace('Ctrl+', '');
          text = quickMessages.edit.text;
          linkVoltar = (
            <Button
              outline
              className="backQuickMessages"
              color="primary"
              size="sm"
              onClick={() => {
                cancelEditQuickMessage();
              }}
            >
              <Icon
                position="relative"
                top="2px"
                icon="poli-icon pi-simple-arrow-left-line"
              />
              <span>Voltar</span>
            </Button>
          );
        }
        content = (
          <>
            <Form inline className="quickMessagesNewMessage">
              <div className="infoVarsSystem">
                <Button
                  color="link"
                  onClick={() => {
                    window.open(
                      'https://polichat.com.br/como-utilizar-variaveis-nas-mensagens-rapidas-do-polichat/'
                    );
                    return;
                    let styleValue = 'display:block;position:absolute';
                    let style =
                      document.getElementById('textInfoVarsSystem').attributes
                        .style;
                    style.value = styleValue;
                    style.nodeValue = styleValue;
                    style.textContent = styleValue;
                  }}
                >
                  Conheça as variáveis do sistema{' '}
                  <Icon icon="poli-icon pi-simple-arrow-right-line" />
                </Button>
                <div
                  className="textInfoVarsSystem"
                  id="textInfoVarsSystem"
                  style={{ display: 'none', position: 'absolute' }}
                >
                  <span
                    onClick={() => {
                      let styleValue = 'display:none;position:absolute';
                      let style =
                        document.getElementById('textInfoVarsSystem').attributes
                          .style;
                      style.value = styleValue;
                      style.nodeValue = styleValue;
                      style.textContent = styleValue;
                    }}
                  >
                    [X]
                  </span>
                  Com as variáveis do sistema é possível personalizar o conteúdo
                  de sua mensagem rápida inserindo no meio do texto dados
                  relacionados a conversa, como por exemplo o nome do contato,
                  nome do operador...
                  <br />
                  As variáveis do sistema existentes são:
                  <br />
                  [contact_name], [contact_first_name], [contact_phone],
                  [contact_email], [operator_name], [department_name],
                  [date_current], [time_current] e [protocol_number].
                </div>
              </div>
              <Row form className="MinhaClasse">
                <Col md={9} className="quickMessagesName">
                  <FormGroup>
                    <div className="quickMessagesNameLabel">
                      <Icon
                        icon="poli-icon pi-text-fast-fill"
                        size={20}
                        color={'var(--color-primary)'}
                      />
                      <span>
                        Nova mensagem rápida <strong>#{term}</strong>
                      </span>
                    </div>

                    <Input
                      disabled={
                        is_template || is_templateList || is_buttonsMessage
                      }
                      type="textarea"
                      name=""
                      defaultValue={text}
                      id="textAreaQuickMessage"
                      className="textAreaQuickMessage"
                      placeholder="Digite a Mensagem"
                      onChange={() => {}}
                    />
                  </FormGroup>
                </Col>

                <Col md={3} className="quickMessagesShortcut">
                  <FormGroup>
                    <div className="quickMessagesShorcutLabel">
                      <Icon
                        icon="poli-icon pi-shortcuts-fill"
                        size={20}
                        color="var(--green-polichat)"
                      />
                      <span>
                        Atalho para <strong>#{term}</strong>
                      </span>
                    </div>

                    <InputGroup>
                      <span className="iconCtrl">Ctrl+</span>
                      <Input
                        label="Tecla de atalho"
                        type="text"
                        name=""
                        id="textKeyboardShortcut"
                        className="textKeyboardShortcut"
                        autoComplete="off"
                        defaultValue={keyboard_shortcut}
                        onChange={() => {}}
                        onKeyPress={(event) =>
                          handleKeyboardShortcutKeyPressEvent(event)
                        }
                        placeholder="__"
                      />
                      <InputGroupAddon
                        addonType="prepend"
                        className="input-group-saveQuickMessage"
                      >
                        <Button
                          color="info"
                          onClick={() => saveQuickMessage(message)}
                        >
                          <Icon icon="poli-icon pi-add-circle-fill" size={20} />
                        </Button>
                      </InputGroupAddon>
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
              {linkVoltar}
            </Form>
          </>
        );
      } else if (quickMessages.messages && !quickMessages.messages.length) {
        props.onFocusInputText();
        //não há nenhuma mensagem cadastrada
        content = (
          <div
            className="infoForNewQuickMessageContainer"
            onClick={() => {
              props.onFocusInputText();
            }}
          >
            <div>
              <span
                style={{
                  background: '#fff',
                  paddingTop: '5px',
                  paddingLeft: '5px',
                  position: 'relative',
                  left: '-7px',
                  top: '-6px',
                  paddingRight: '5px',
                  borderRadius: '80px',
                }}
              >
                <Icon
                  color="var(--primary)"
                  size="35"
                  icon="poli-icon pi-poly-love-fill"
                  position="relative"
                  top="-3px"
                />
              </span>{' '}
              Não há mensagem rápida cadastrada!
            </div>
            <p>
              Para cadastrar uma mensagem rápida digite no campo de texto abaixo
              uma <i>hashtag</i> para a nova mensagem, por exemplo{' '}
              <i>#minha_nova_mensagem</i>.<br />
              Em seguida será mostrado os campos para a criação da sua mensagem
              rápida.
            </p>
          </div>
        );
      } else {
        let auto_scroll = () => {
          textInit = props.text;
          if (!document.getElementById('listQuickMessagesContainer')) {
            setTimeout(() => {
              auto_scroll();
            }, 1000);
          } else {
            const listContainer = document.getElementById(
              'listQuickMessagesContainer'
            );
            const selectedMessage = document.querySelector('.selected');

            if (selectedMessage) {
              selectedMessage.scrollIntoView({
                behavior: 'auto',
                block: 'center',
              });
            } else {
              listContainer.scrollTop = 0;
            }
            let heightPerItem =
              document.getElementById('listQuickMessagesContainer')
                .scrollHeight / list.length;
            document.getElementById('listQuickMessagesContainer').scroll({
              top: heightPerItem * (quickMessages.nav || 0),
              behavior: 'smooth',
            });
          }
        };
        if (textInit != props?.text) {
          auto_scroll();
        }

        let qtde = 0;

        /**
         * For each item (template/quick_message)
         */
        let listItems = list?.map((list, id) => {
          const isQuickMessage = list.template === null;

          const isValidTemplate =
            list.validated === 1 && // is a template BUT not validated
            opennedChat?.view?.selectedChannel?.id === list.template;

          if (
            list.template_name?.indexOf('list') > -1 && // verificando se a mensagem é lista e o canal é diferente de waba
            ![33, 6, 10, 34].includes(
              opennedChat?.view?.selectedChannel?.api
            ) &&
            !flagInteractiveV2Channels?.find(
              (flag_channel) =>
                flag_channel?.api == opennedChat?.view?.selectedChannel?.api &&
                flag_channel?.canSendList
            )
          )
            return null;
          if (
            setHasButtonsMessage(list) && // verificando se a mensagem de botões interativo e o canal é diferente de waba
            ![33, 6, 10, 34].includes(
              opennedChat?.view?.selectedChannel?.api
            ) &&
            !flagInteractiveV2Channels?.find(
              (channel) =>
                channel?.api == opennedChat?.view?.selectedChannel?.api &&
                channel?.canSendButtons
            )
          )
            return null;
          // If it is a template but not valid, return null
          if (list.template && !isValidTemplate) return null;

          // If it is for templates only (24h passed) don't show quick_messages
          if (props.onlyTemplate && isQuickMessage) return null;

          QuickMessages.setMessage(list);
          let text = QuickMessages.getText(opennedChat);

          let buttonsActions = null;
          if (
            user.id === list.user_id ||
            user.superRole === 'adm' ||
            user.superRole === 'manager'
          ) {
            buttonsActions = (
              <span className="itemListActionsGroup">
                {(list.template && list.validated === 1) ||
                list.template_name?.indexOf('list') > -1 ||
                setHasButtonsMessage(list) ? (
                  list.template == opennedChat.chat.channel_id ||
                  list.template_name?.indexOf('list') > -1 ||
                  setHasButtonsMessage(list) ? (
                    <>
                      {list.template_name?.indexOf('list') > -1 ? (
                        <i title="Lista de Opções">
                          <Icon
                            icon="poli-icon pi-barmenu-fill"
                            color="var(--secondary-75)"
                          />
                        </i>
                      ) : setHasButtonsMessage(list) ? (
                        <i title="Botões Interativos">
                          <Icon
                            icon="poli-icon pi-menu-mobile-fill"
                            color="var(--secondary-75)"
                          />
                        </i>
                      ) : (
                        <i title="Template para whatsapp">
                          <Icon
                            icon="poli-icon pi-whatsapp-api-fill"
                            color="var(--whatsapp-color)"
                          />
                        </i>
                      )}
                    </>
                  ) : (
                    <i title="Canal do Template não encontrado, você só pode usar este Template depois de abrir a janela de 24hrs">
                      <Icon
                        icon="poli-icon pi-whatsapp-api-fill"
                        color="var(--success-25)"
                      />
                    </i>
                  )
                ) : null}
                <Button
                  color="info"
                  className="btnEdit"
                  onClick={() => {
                    editQuickMessage(list);
                  }}
                  disabled={
                    list.template ||
                    list.template_name?.indexOf('list') > -1 ||
                    setHasButtonsMessage(list)
                  }
                >
                  <Icon icon="poli-icon pi-pencil-fill" />
                </Button>
                <Button
                  color="danger"
                  className="btnDelete"
                  onClick={() => {
                    alertDeleteQuickMessage(list);
                  }}
                >
                  <Icon icon="poli-icon pi-delete-fill" />
                </Button>
              </span>
            );
          } else if (
            (list.template &&
              list.validated === 1 &&
              list.template == opennedChat.chat.channel_id) || // only show templates for current channel
            list.template_name?.indexOf('list') > -1 || // mostrar nas mensagens do tipo lista
            setHasButtonsMessage(list) // mostrar nas mensagens do tipo botões
          ) {
            buttonsActions = (
              <span className="itemListActionsGroup">
                {list.template_name?.indexOf('list') > -1 ? (
                  <i title="Lista de Opções">
                    <Icon
                      icon="poli-icon pi-request-line"
                      color="var(--secondary-75)"
                    />
                  </i>
                ) : setHasButtonsMessage(list) ? (
                  <i title="Template para whatsapp">
                    <Icon
                      icon="poli-icon pi-whatsapp-api-fill"
                      color="var(--whatsapp-color)"
                    />
                  </i>
                ) : (
                  <i title="Template para whatsapp">
                    <Icon
                      icon="poli-icon pi-whatsapp-api-fill"
                      color="var(--whatsapp-color)"
                    />
                  </i>
                )}
              </span>
            );
          } else {
            buttonsActions = (
              <span className="itemListActionsGroup">
                <i title="Template não disponível para o canal selecionado.">
                  <Icon
                    icon="poli-icon pi-whatsapp-api-fill"
                    color="var(--success-25)"
                  />
                </i>
              </span>
            );
          }
          qtde++;
          return (
            <>
              <li
                key={id}
                className={
                  list.validated == 1 && list.template
                    ? !list.template ||
                      list.template == opennedChat.chat.channel_id
                      ? 'template ' +
                        (id === (quickMessages.nav || 0) ? 'selected' : '')
                      : 'templateNoChannel'
                    : 'quickMessage ' +
                      (id === (quickMessages.nav || 0) ? 'selected' : '')
                }
              >
                <label
                  className="itemListTitle"
                  onClick={() => {
                    if (!QuickMessages.selectTable(list, opennedChat)) {
                      return;
                    }
                    QuickMessages.setMessage(list);
                    let textMessage = QuickMessages.updateText(
                      props.text,
                      opennedChat,
                      true
                    );
                    props.onUpdateMessage(
                      textMessage,
                      list.id,
                      list.template && list.validated == 1,
                      list.template_name?.includes('list'),
                      list.template_name?.includes('buttons')
                    );
                    props.onFocusInputText();
                    toggleQuickMessagesSelector();
                  }}
                >
                  <span>
                    <strong
                      title={
                        '#' +
                        list.tag +
                        (list.keyboard_shortcut
                          ? ' (' + list.keyboard_shortcut + ')'
                          : '')
                      }
                    >
                      #{list.tag}
                    </strong>
                  </span>
                  <span>
                    {list.template ||
                    list.template_name?.indexOf('list') > -1 ||
                    setHasButtonsMessage(list) ? (
                      <span title={getMessageBuilderTemplateText(text)}>
                        {' '}
                        - {getMessageBuilderTemplateText(text)}
                      </span>
                    ) : (
                      <span title={text}> - {text}</span>
                    )}
                  </span>
                </label>
                {buttonsActions}
              </li>
            </>
          );
        });
        if (!qtde) {
          content = (
            <div className="infoForNewQuickMessageContainer" onClick={() => {}}>
              <div>
                <Icon icon="poli-icon pi-alert-fill" /> Não há template
                cadastrado e aprovado!
              </div>
              <p>
                Para iniciar uma conversa ativa por esse canal é necessário ter
                um template vinculado ao canal e aprovado pela equipe do
                Facebook.
                <br />
                {user.superRole === 'adm' || user.superRole === 'manager' ? (
                  <>
                    Crie um template em{' '}
                    <i>
                      {'Página inicial -> Configurações -> Mensagens rápidas'}
                    </i>
                  </>
                ) : (
                  'Solicite ao administrador/gestor a criação de um template.'
                )}
              </p>
            </div>
          );
        } else {
          content = (
            <div
              className="listQuickMessagesContainer"
              id="listQuickMessagesContainer"
              onScroll={() => {}}
            >
              <small>#{quickMessages.term || ''}</small>
              <ul className="listaQuickMessages">{listItems || null}</ul>
            </div>
          );
        }
      }
    } else {
      fetchQuickMessages();
    }
  }
  if (!content) return null;
  return (
    <div className="quickMessagesContainer">
      <div className="quickMessagesContainerHeader">
        <Icon
          icon="poli-icon pi-rocket-fill"
          size={20}
          color={'var(--primary)'}
        />
        <span>Mensagens rápidas</span>
      </div>
      <div className="quickMessagesContainerBody">{content}</div>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => ({
  chat: state.chat,
  quickMessages: state.chat?.quickMessages || null,
  opennedChat:
    state.chat?.chats?.filter((chat) => {
      return chat.open === true;
    })[0] || [],
  props: ownProps,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(ChatActions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuickMessageSelector);
