import store from '../../../store';

/**
 * Essa função verifica se existe esse visitante na lista
 * contatos e chats do state.chats
 * @param { session } visitor
 * @returns boolean
 */

export function testContactChat(visitor) {
  let currentStore;

  try {
    currentStore = store.getState();
  } catch (e) {
    console.log('Error ao acessar a store @Visitor/functions', e);
    return { error: 'error ao acessar a store', sessionError: visitor };
  }

  let currentChats = currentStore.chat?.chats;
  // let currentContacts = currentStore.chat?.contacts;

  let hasContactInChatStore = currentChats.some(
    (chat) => chat.id === visitor.id
  );
  // let hasContactInContactStore = currentContacts.some(
  //   (contact) => contact.id === visitor.id
  // );

  return { hasContactInChatStore };
}
