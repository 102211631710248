import { isMobile } from 'react-device-detect';
import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;

  box-sizing: border-box;
  background-color: var(--primary-background-color);
  border: 1px solid var(--border-color);
  margin-top: 1rem;
  padding: 0.8rem 0;
  border-radius: 5px;
  overflow: ${isMobile ? 'scroll' : 'auto'};
  ${isMobile && 'margin-bottom: 50px'};

  position: relative;
`;
