import * as Yup from 'yup';

const MAX_DAYS = 15;
// 86400000 is the number of milliseconds in one day
const MAX_DAYS_INTERVAL_IN_MILLISECONDS = 86400000 * MAX_DAYS;

type dateInput = string | null | undefined;
type dateOutput = number | null;

function convertStringDateToMilliseconds(date: dateInput): dateOutput {
  if (!date) return null;
  return new Date(date).getTime();
}

export const validationSchema = Yup.object({
  date_start: Yup.string()
    .nullable(true)
  .test({
      name: 'max',
      message: 'A data deve ser de hoje ou anterior!',
      test: (value) => {
        const dateNow = new Date();
        if (value && new Date(Date.parse(value)) >= dateNow) {
          return false;
        }
        return true;
      },
    }),
  date_end: Yup.string()
    .nullable(true)
    .test({
      name: 'min',
      message: 'Data final não pode ser menor que a data inicial',
      test: (value, context) => {
        let start = new Date(context.parent.date_start);
        if (value && new Date(Date.parse(value)) < start) {
          return false;
        }
        return true;
      },
    })
    .test({
      name: 'max_interval',
      message: 'Intervalo máximo de 15 dias',
      test: (value, context) => {
        const startDate = convertStringDateToMilliseconds(
          context.parent.date_start
        );
        const endDate = convertStringDateToMilliseconds(value);

        const interval = startDate && endDate ? endDate - startDate : null;

        if (!interval) return true;

        if (interval <= MAX_DAYS_INTERVAL_IN_MILLISECONDS) {
          return true;
        } else {
          return false;
        }
      },
    })
    .test({
      name: "one_date",
      message: "Você deve inserir as duas datas!",
      test: (value, context) => {
        if(context.parent.date_start && value === ""){
          return false;
        }
        return true;
      },
    })
    .test({
      name: 'max',
      message: 'A data deve ser de hoje ou anterior!',
      test: (value) => {
        const dateNow = new Date();
        if (value && new Date(Date.parse(value)) >= dateNow) {
          return false;
        }
        return true;
      },
    }),
});
