import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Icon,
} from '@polichat/flamboyant';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ChatActions from '../../../../store/modules/chat/actions';

import Text from '../../../../utils/text';
import FilterModals from './modals/FilterModals';

import { Crm } from '@poli-ink/react';

function FilterDropdown({ toggleFilterMenu, isMainMenuOpen, superRole }) {
  const hasSupervisorAccess =
    superRole === 'manager' || superRole === 'adm' || superRole === 'supervisor'
      ? true
      : false;

  return (
    <div className="filter-content">
      <Dropdown
        isOpen={isMainMenuOpen}
        toggle={() => {
          toggleFilterMenu('main');
        }}
      >
        <DropdownToggle
          className="btnFilterDropdown"
          boder="border-none"
          color="light"
        >
          {isMainMenuOpen ? (
            <Crm width={16} height={16} />
          ) : (
            <Crm width={16} height={16} />
          )}
        </DropdownToggle>

        <DropdownMenu className="menuDropdownShow filterDropdown">
          {hasSupervisorAccess && (
            <DropdownItem
              className="itemDropdownMenu"
              onClick={() => toggleFilterMenu('attendants')}
            >
              <Icon
                icon={'poli-icon pi-operator-line'}
                color={'var(--green-poli)'}
                size={20}
              />
              <p className="alternative">{Text('Atendentes')}</p>
              <Icon
                icon={'poli-icon pi-simple-arrow-right-line'}
                color={'var(--green-polichat)'}
                size={20}
              />
            </DropdownItem>
          )}

          {hasSupervisorAccess && (
            <DropdownItem
              className="itemDropdownMenu"
              onClick={() => toggleFilterMenu('departments')}
            >
              <Icon
                icon={'poli-icon pi-folder-line'}
                color={'var(--green-poli)'}
                size={20}
              />
              <p className="alternative">{Text('Departamentos')}</p>
              <Icon
                icon={'poli-icon pi-simple-arrow-right-line'}
                color={'var(--green-polichat)'}
                size={20}
              />
            </DropdownItem>
          )}
          <DropdownItem
            className="itemDropdownMenu"
            onClick={() => toggleFilterMenu('tags')}
          >
            <Icon
              icon={'poli-icon pi-tag-line'}
              color={'var(--green-poli)'}
              size={20}
            />
            <p className="alternative">{Text('Etiquetas')}</p>
            <Icon
              icon={'poli-icon pi-simple-arrow-right-line'}
              color={'var(--green-polichat)'}
              size={20}
            />
          </DropdownItem>

          <DropdownItem
            className="itemDropdownMenu"
            onClick={() => toggleFilterMenu('date')}
          >
            <Icon
              icon={'poli-icon pi-calendar-line'}
              color={'var(--green-poli)'}
              size={20}
            />
            <p className="alternative">{Text('Data')}</p>
            <Icon
              icon={'poli-icon pi-simple-arrow-right-line'}
              color={'var(--green-polichat)'}
              size={20}
            />
          </DropdownItem>

          <DropdownItem
            className="itemDropdownMenu"
            onClick={() => toggleFilterMenu('chats')}
          >
            <Icon
              icon={'poli-icon pi-talks-line'}
              color={'var(--green-poli)'}
              size={20}
            />
            <p className="alternative">{Text('Status dos Chats')}</p>
            <Icon
              icon={'poli-icon pi-simple-arrow-right-line'}
              color={'var(--green-polichat)'}
              size={20}
            />
          </DropdownItem>

          <DropdownItem
            className="itemDropdownMenu border-none"
            onClick={() => toggleFilterMenu('channels')}
          >
            <Icon
              icon={'poli-icon pi-channels-line'}
              color={'var(--green-poli)'}
              size={20}
            />
            <p className="alternative">{Text('Canais')}</p>
            <Icon
              icon={'poli-icon pi-simple-arrow-right-line'}
              color={'var(--green-polichat)'}
              size={20}
            />
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>

      <FilterModals />
    </div>
  );
}

const mapStateToProps = (state) => ({
  chat: state.chat,
  isMainMenuOpen: state.chat?.filters?.view?.isMainMenuOpen,
  superRole: state.chat?.config?.user?.superRole,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(ChatActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FilterDropdown);
