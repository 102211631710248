import { IFlagsmithFeature } from "flagsmith/types";
import { useSelector } from "react-redux";

interface State {
  general: {
    current_customer_id?: number;
  };
}

export function customUseFlags(flag: IFlagsmithFeature) {
  const customer_id = useSelector(
    (state: State) => state.general?.current_customer_id
  ) as number;
  const value = flag.value?.toString();
  function isFlagEnabled(): boolean {
    if (!flag.enabled) return false;

    try {
      // Transforma string em array e mapea os ids para o tipo number
      const customerIds = value ? JSON.parse(value) as number[] : undefined;
      // Verifica se há o id da empresa atual no array de ids da flag ou se não há nenhum id
      return (
        customerIds ? (customerIds.includes(customer_id) ||
          customerIds.length === 0) : false
      );
    } catch (error) {
      // Caso o parse dê erro, e a flag for null ou '' será habilitada a flag
      return flag.value === null || flag.value === '';
    }
  }
  return isFlagEnabled();
}