import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import * as GeneralActions from '../../../store/modules/general/actions';
import { connect } from 'react-redux';
import PageMainHeader from '../../common/pages/PageMainHeader/PageMainHeader.component';
import { Button, Icon, Table } from '@polichat/flamboyant';
import history from '../../../services/history';
import PageMainBody from '../../common/pages/PageMainBody/PageMainBody.component';
import { getServicesImportSL } from '../../../utils/admin/services/services';
import Animation from '../../common/animation';

function ServiceImport() {

  const [servicesSl, setServicesSl] = useState([]); // SL = Superlógica
  const [isLoading, setIsLoading] = useState(true);

  async function fetchData() {
    const tempServicesSl = await getServicesImportSL();
    setServicesSl(tempServicesSl);
    setIsLoading(false);
  }

  useEffect(()=> {
    fetchData();

    return () => {
      setServicesSl([]);
    }
  }, []);

  return (
    <>
      <PageMainHeader title='Importar Serviços' icon='poli-icon pi-catalogo-line' />
      <PageMainBody>
        {isLoading ?
          <Animation icon='chat-loading' top={'50%'} />
          :
        <Table responsive bordered striped hover>
          <thead>
          <tr>
            <th className='text-center'>Id</th>
            <th className='text-center'>Descrição</th>
            <th className='text-center'>Valor Unitário</th>
            <th className='text-center' />
          </tr>
          </thead>
          <tbody>
          {
            servicesSl?.map((serviceSl, index) => {
              return <tr key={index}>
                <td className="text-center">{serviceSl?.idSuperlogica}</td>
                <td className="text-center">{serviceSl?.description}</td>
                <td className="text-center">{(serviceSl?.value)?.toLocaleString()}</td>
                <td className="text-center">
                  <Button color='secondary' className='btn-sm' style={{ marginLeft: '5px' }} onClick={() => {
                    history.push({
                      pathname: '/admin/services/new',
                      state: {origin: 'import', data: serviceSl}
                    });
                  }}><Icon icon='poli-icon pi-arrow-down-line' /> Importar </Button>
                </td>
              </tr>
            })
          }
          </tbody>
        </Table>}
      </PageMainBody>
    </>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(GeneralActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ServiceImport);
