import React from 'react';
import { Button }from '@polichat/flamboyant';
import Animation from '../animation';

function SubmitButton(props) {
  if (props.loading === true) {
    return (
      <div className="loadingContainer">
        <Animation icon="loading" size="tiny" />
      </div>
    );
  } else {
    return <Button {...props}>{props.title}</Button>;
  }
}

export default SubmitButton;
