import React, { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import PageMainHeader from '../../../common/pages/PageMainHeader/PageMainHeader.component';
import PageMainBody from '../../../common/pages/PageMainBody/PageMainBody.component';
import Animation from '../../../common/animation';

import CreditIndex from './components/CreditIndex/CreditIndex.component';
import CreditTable, {
  typeDataModalView,
} from './components/CreditTable/CreditTable.component';
import CreditModalAdd from './components/CreditModalAdd/CreditModalAdd.component';
import CreditModalView from './components/CreditModalView/CreditModalView.component';

import SelectCustomer from './components/SelectCustomer/SelectCustomer.component';

// @ts-ignore
import { Button, Icon } from '@polichat/flamboyant';

/**
 * Requests
 */
import {
  getServicesCredit,
  postHireContactsDay,
  getListContactsDay,
  getListCustomer,
  getCheckPlanAnnual,
  getFinanceUser,
} from '../../../../services/credits/request';
/* */

/**
 * Types
 */
import { Credit, SendAddCredit } from '../../../../types/Credit';

type Service = {
  id: number;
  name: string;
  description?: string;
  status?: number;
  type?: number;
  value: string;
};

type Customer = {
  id: number;
  name: string;
};

type CustomerCred = {
  id: number;
  type: string;
  isRecurrent: boolean;
  hasDiscount?: boolean;
};

export type ErrorCustomerPlan = {
  error: boolean;
  message: string;
};
/* types */

const CreditList = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<Credit[]>();
  const [servicesData, setServicesData] = useState<Service[]>();
  const [customers, setCustomers] = useState<Customer[]>();

  const [errorCustomerPlan, setErrorCustomerPlan] = useState<ErrorCustomerPlan>(
    {
      error: false,
      message: '',
    }
  );

  // loading to sync annual plan customer info
  const [isLoadingAnnualPlanInfo, setIsLoadingAnnualPlanInfo] = useState(false);

  // Para contratar pacote anual precisa desta informação
  const [dueDatePlan, setDueDatePlan] = useState<string>();

  /**
   * Se true => esta na tela de admin
   */
  const screenAdmin = location.pathname.split('/').includes('admin');

  /**
   * Buscar a empresa que o usuario esta logado no state
   */
  const [customerId, setCustomerId] = useState<number>();
  const state_current_customer_id = useSelector(
    // @ts-ignore
    (state) => state.general?.current_customer_id
  );

  useEffect(() => {
    if (state_current_customer_id) {
      setCustomerId(parseInt(state_current_customer_id));
    }
  }, [state_current_customer_id]);
  /* end */

  /**
   * Modal Add
   */
  const [isOpenModalAdd, setIsOpenModalAdd] = useState(false);
  const toggleModalAddCredit = useCallback(() => {
    setIsOpenModalAdd((prev) => !prev);
  }, []);
  /* */

  const [isUserFinance, setIsUserFinance] = useState<boolean>();

  const user_id = useSelector(
    // @ts-ignore
    (state) => state.general?.user?.id
  );

  const handleUserFinance = async () => {
    const result = await getFinanceUser(user_id);

    if (result?.data.isUserFinance) {
      setIsUserFinance(true);
    } else {
      setIsUserFinance(false);
    }
  };
  useEffect(() => {
    handleUserFinance();
  }, []);

  /**
   * Modal View
   */
  const [isOpenModalView, setIsOpenModalView] = useState(false);
  const [customerCredSelected, setCustomerCredSelected] =
    useState<CustomerCred>();
  const toggleModalViewCredit = useCallback(
    (customerCredId: number, _data?: typeDataModalView) => {
      const _selected = {
        id: customerCredId ?? 0,
        type: _data?.type ?? '',
        isRecurrent: _data?.isRecurrent ?? false,
        hasDiscount: _data?.hasDiscount ?? false,
      };

      setCustomerCredSelected(_selected);
      setIsOpenModalView((prev) => !prev);
    },
    []
  );
  /* end - Modal View */

  /**
   * Get Data
   */
  const getData = async () => {
    setIsLoading(true);
    setIsLoadingAnnualPlanInfo(true);

    if (!customerId) return;

    try {
      const result = await getListContactsDay(customerId);
      if (result?.data.success && Array.isArray(result?.data?.data)) {
        setData(result?.data?.data);
      }
    } catch (error) {
      console.error('Error on Services Credit: getListContactsDay', error);
    } finally {
      setIsLoading(false);
    }

    // Se o cliente tem plano anual
    try {
      setDueDatePlan('');
      const res = await getCheckPlanAnnual(customerId);
      if (res?.data?.success && res?.data?.due_date_plan) {
        setDueDatePlan(res?.data?.due_date_plan ?? '');
      }
    } catch (error) {
      console.error('Error on Services Credit: getCheckPlanAnnual', error);
    } finally {
      setIsLoadingAnnualPlanInfo(false);
    }
  };

  const updateDataSecondPlan = async () => {
    const result = await getListContactsDay(customerId);
    if (result?.data.success && Array.isArray(result?.data?.data)) {
      setData(result?.data?.data);
    }
  };

  const setHasDiscount = (hasDiscount: boolean): void => {
    let newCustomer: CustomerCred | undefined = customerCredSelected;

    if (newCustomer) {
      newCustomer.hasDiscount = hasDiscount;
    }

    setCustomerCredSelected(newCustomer);
  };

  const updateValue = (value: number) => {
    if (!data?.length || !customerCredSelected) {
      return;
    }

    const index = data?.findIndex((e) => e.id === customerCredSelected?.id);

    if (index >= 0) {
      let old_data = data;
      old_data[index].value = value;
      setData(old_data);
    }
  };
  /* End - Get Data */

  /**
   * Get Services
   */
  const getServices = async () => {
    try {
      const result = await getServicesCredit({ customer_id: customerId });
      if (Array.isArray(result?.data)) {
        setServicesData(result.data);
      }
    } catch (error: any) {
      if (error.response.status === 403) {
        setErrorCustomerPlan({
          error: true,
          message: `${error.response.data}, entre em contato com suporte`,
        });
      }
      console.error('Error on Services Credit: getServicesCredit', error);
    }
  };
  /* End - Get Services */

  /**
   * Get Customer
   */
  const getCustomer = async () => {
    try {
      const result = await getListCustomer();
      if (result?.data.success && Array.isArray(result?.data?.data)) {
        const _resData = result.data.data;
        // Order Name
        _resData.sort((a: Customer, b: Customer) => {
          const _aName = a.name?.toString().length > 0 ? a.name : 'Sem Nome';
          const _bName = b.name?.toString().length > 0 ? b.name : 'Sem Nome';
          return _aName.localeCompare(_bName);
        });

        setCustomers(_resData);
      }
    } catch (error) {
      console.error('Error on Services Credit: getListCustomer', error);
    }
  };
  /* End - Get Customer */

  /**
   * Mounted
   */
  useEffect(() => {
    if (customerId) getData();
    if (!servicesData && customerId) getServices();
    if (!customers) getCustomer();
  }, [customerId]);
  /* end */

  /**
   * Save Credit
   */
  const onAddCredit = async (dataSend: SendAddCredit) => {
    toast.info('Salvando...');
    setIsLoading(true);
    toggleModalAddCredit();

    try {
      const send = {
        customer_id: dataSend.customer_id,
        service_id: dataSend.service_id,
        package_quantity: dataSend.package_quantity,
        type: dataSend.type,
        due_date_plan:
          dueDatePlan && dueDatePlan?.toString()?.length > 0
            ? dueDatePlan
            : null,
        recurrent: dataSend.recurrent,
        installment: dataSend.installment,
      };

      const result = await postHireContactsDay(send);

      const newData: Credit =
        result.data.success && result.data.data ? result.data.data : null;

      if (newData) {
        const _data = [...(data ?? [])];

        // Nome do serviço
        const service = servicesData?.find((e) => e.id === newData.service_id);
        const service_name = service?.name ?? `#${newData.service_id}`;

        newData.service = {
          id: newData.service_id,
          name: service_name,
        };
        /* */

        // Ao contratar o status é Pendente
        newData.status_payment = 2;

        _data.unshift(newData);
        setData(_data);
        toast.success('Contratação de crédito realizado com sucesso!');
      } else {
        toast.error(
          'Error ao salvar! Tente novamente mais tarde ou entre em contato com o suporte!'
        );
      }

      setIsLoading(false);
    } catch (error) {
      console.error('Error on Services Credit: postHireContactsDay', error);
      setIsLoading(false);
      toast.error(
        `${error} - Error ao salvar! Tente novamente mais tarde ou entre em contato com o suporte!`
      );
    }
  };

  /* */

  const handleChangeCustomerId = (customerId: number) => {
    setServicesData(undefined);
    setCustomerId(customerId);
  };

  /**
   * Return render JSX
   */
  return (
    <>
      {/* @ts-ignore */}
      <PageMainHeader title="Adicionar crédito" icon="poli-icon pi-debit-line">
        {/* (SOMENTE ADMIN) Pesquisar a empresa que deseja filtrar */}
        {screenAdmin && (
          <SelectCustomer
            customers={customers ?? []}
            value={customerId ?? 0}
            onChange={handleChangeCustomerId}
          />
        )}

        {/* Botão do topo de Add Credito */}
        {(screenAdmin || (data && data?.length > 0)) && (
          <Button
            gradient="primary"
            onClick={toggleModalAddCredit}
            style={{
              paddingTop: '0.5rem',
              paddingBottom: '0.5rem',
            }}
          >
            Adicionar crédito
            <Icon
              icon="poli-icon pi-add-circle-line"
              size={24}
              position={'relative'}
              top={3}
              left={5}
            />
          </Button>
        )}
      </PageMainHeader>

      <PageMainBody>
        {isLoading ? (
          <Animation
            icon="chat-loading"
            top={'50%'}
          />
        ) : (
          <>
            {Array.isArray(data) && data?.length > 0 ? (
              <CreditTable
                dataTable={data ?? []}
                toggleModalViewCredit={toggleModalViewCredit}
                screenAdmin={screenAdmin}
              />
            ) : (
              <CreditIndex
                toggleModalAddCredit={toggleModalAddCredit}
                screenAdmin={screenAdmin}
              />
            )}
          </>
        )}
      </PageMainBody>

      {/* Modal Add Credito*/}
      <CreditModalAdd
        toggleModalAddCredit={toggleModalAddCredit}
        isOpenModalAdd={isOpenModalAdd}
        // @ts-ignore
        servicesData={servicesData ?? []}
        onAddCredit={onAddCredit}
        customerId={customerId ?? 0}
        dueDatePlan={dueDatePlan ?? ''}
        loadingAnnualPlanInfo={isLoadingAnnualPlanInfo}
        errorGetCustomerServiceData={errorCustomerPlan}
      />
      {/* */}

      {/* Modal Ver Credito*/}
      <CreditModalView
        toggleModalViewCredit={toggleModalViewCredit}
        isOpenModalView={isOpenModalView}
        customerCredSelected={customerCredSelected ?? {}}
        screenAdmin={screenAdmin}
        isUserFinance={isUserFinance}
        updateValue={updateValue}
        updateDatalist={() => {
          updateDataSecondPlan();
        }}
        setHasDiscount={setHasDiscount}
      />
      {/* */}
    </>
  );
};

export default CreditList;
