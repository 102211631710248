import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Routers } from '../../../../routes';
import { Icon }from '@polichat/flamboyant';
import { isMobile } from 'react-device-detect';

function Title() {
  const routes = Routers();

  return (
    <Switch>
      {routes.map((route, index) => {
        return (
          <Route key={index} exact={route.exact} path={route.path}>
            {!isMobile && (
              <div className="titleTopNav">
                <Icon icon={route.icon} size={25} color="var(--green-polichat)" />
                <span className="titleText">{route.title}</span>
              </div>
            )}
          </Route>
        );
      })}
    </Switch>
  );
}
export default Title;
