import React from 'react';
import { Button, Badge, Icon }from '@polichat/flamboyant';

function Tag(props) {
  const hasCloseButton = props.close ? true : false;

  let style = styles.tag;
  let name = props.name ? props.name : 'Não identificada';

  let onClickEvent = props.onClick ? props.onClick : () => {};
  let onCloseEvent = props.onRemove ? props.onRemove : () => {};

  if (props.color) {
    style = { ...style, backgroundColor: props.color };
  }

  return (
    <>
      <Badge
        title={name}
        onClick={onClickEvent}
        style={style}
        className="tag-container"
      >
        <span className="short">{name}</span>
        {hasCloseButton && (
          <Button color="link" onClick={onCloseEvent}>
            <Icon icon="poli-icon pi-close-line" size={20} />
          </Button>
        )}
      </Badge>
    </>
  );
}

const styles = {};
export default Tag;
