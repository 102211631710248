import axios from 'axios';

const instance = axios.create({
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    },
});

async function requestWhiteLabel(axiosConfig){
    let data = await instance.request(axiosConfig);
    return data;
    //analisar retorno de requiçoes não autorizadas para redirecionar para a tela de login
}

export default requestWhiteLabel;
