import styled from 'styled-components';

export const PreviewContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const ImagePreview = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: solid 1px #eee;
  cursor: not-allowed;
`;

export const VideoPreview = styled.video`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: solid 1px #eee;
  border: 1px solid greenyellow;
`;

export const DocumentPreview = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: solid 1px #eee;
  cursor: not-allowed;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Main = styled.div`
  position: relative;
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
  }
`;

export const UploadingAlert = styled.div`
  position: absolute;
  bottom: 0;
  background-color: var(--danger);
  color: white;
  font-size: 0.75rem;
  padding: 1px 2px;
`;

export const MediaSelector = styled.div`
  border: 1px solid var(--gray-75);
  border-radius: 0.5rem;
  padding: 1rem 2rem;
  cursor: pointer;

  display: flex;
  flex-direction: column;
  align-items: center;

  i {
    color: var(--gray-75);
  }

  span {
    font-size: 1rem;
    font-weight: bold;
    color: var(--gray-75);
  }
`;
