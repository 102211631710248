import axios from 'axios';
import ObjectToQueryString from '../lib/ObjectToQueryString';
import poliConfig from '../utils/poliConfig';

const defaultHeaders = {
  'Accept': 'application/json',
  'Content-Type': 'application/json',
};

const defaultConfig = {
  headers: defaultHeaders,
  withCredentials: true,
};

const getSuperRoleSpa = (params = null) => {
  return axios.get(
    poliConfig.poliAppLaravelUrl +
      `/spa/getSuperRoleSpa` +
      (params ? '?' + ObjectToQueryString(params) : ''),
    defaultConfig
  );
};

const keepAlive = (params = null) => {
  return axios.post(
    poliConfig.poliAppLaravelUrl +`/alive`,
    null,
    defaultConfig
  );
};


const changeUserStatusLogged = (params) => {
  return axios.post(
    process.env?.REACT_APP_LARAVEL_URL + `/changeUserStatusLogged`,
    params,
    defaultConfig
  );
};

const api = axios.create({
  baseURL: "http://localhost:3088"
})

export default {
  getSuperRoleSpa,
  keepAlive,
  changeUserStatusLogged,
  api,
};
