import React, { useState } from 'react';
import FilterModalTemplate from './FilterModalTemplate';
import Animation from '../../../../common/animation';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ChatActions from '../../../../../store/modules/chat/actions';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from '@polichat/flamboyant';

function ChatsModal({
  isChatMenuOpen,
  toggleFilterMenu,
  activeFilters,
  submitFilter,
  loading,
  clearFilter,
}) {
  const filter_type_name = 'chats';
  const filter_title = 'Filtrar por Status';
  const filter_icon = 'poli-icon pi-talks-line';

  let content;
  let chats_select = [];
  let enabled = true;

  const options = [
    // {
    //   value: 0,
    //   name: 'Em atendimento',
    // },
    {
      value: 1,
      name: 'Chats Finalizados',
      screen: 'closed'
    },
    // {
    //   value: 2,
    //   name: 'Não lidos',
    // },
    {
      value: 3,
      name: 'Chats lidos',
      screen: 'read',
    },
  ];

  function verifyCurrentFilter () {
    if (!activeFilters.length) {
      setFilter('')
    }
  }

  const findCurrentChatFilter = () => {
    let active_filter = '';

    if (activeFilters.length) {
      activeFilters.forEach((filter) => {
        if (filter.type === filter_type_name) {
          active_filter = filter.chat_id;
        }
      });
    }

    return active_filter;
  };

  const [filter, setFilter] = useState(findCurrentChatFilter());
  const selectedChatFilterName = options.map((option) => {
    // tem que ser apenas dois iguais
    if (option.value == filter) {
      return option.name;
    }
  });

  const onSubmitEvent = () => {
    let payload = Object.assign({}, activeFilters);

    if (filter) {
      let value = parseInt(filter);
      let name = findName(value);
      let screen = findScreen(value);

      payload.chat = [
        {
          name: name,
          value: value,

        },
      ];

      submitFilter(payload, screen);
    } else {
      payload.chat = [];
      clearFilter(payload);
    }

    toggleFilterMenu(filter_type_name);
  };

  const sortByValue = (a, b) => {
    if (a.value < b.value) return -1;
    if (a.value > b.value) return 1;
    return 0;
  };

  const findName = (id) => {
    verifyCurrentFilter();
    let option = options.find((option) => option.value === id);

    if (option) {
      return option.name;
    } else {
      return '';
    }
  };


  const findScreen = (id) => {
    verifyCurrentFilter();
    let option = options.find((option) => option.value === id);

    if (option) {
      return option.screen;
    } else {
      return '';
    }
  };

  if (options && options.length) {
    chats_select = options.sort(sortByValue).map((option, index) => {
      return (
        <DropdownItem
          key={`${option.value}+${index}`}
          value={option.value}
          className="dropItem-filters"
          onClick={(e) => setFilter(e.target.value)}
        >
          {option.name}
        </DropdownItem>
      );
    });

    content = (
      <UncontrolledDropdown className="dropDown-filters">
        <DropdownToggle
          color="neutral"
          caret
          id="chatsFilter"
          name="chatsFilter"
          className="total dropDownToggle-filters"
        >
          <span>
            {filter != '' && selectedChatFilterName
              ? selectedChatFilterName
              : 'Selecione um filtro'}
          </span>
        </DropdownToggle>
        <DropdownMenu className="dropDownMenu-filters">
          {chats_select}
        </DropdownMenu>
      </UncontrolledDropdown>
    );

    enabled = true;
  } else {
    enabled = false;
    content = <Animation icon="loading" size="tiny" />;
  }

  return (
    <FilterModalTemplate
      icon={filter_icon}
      title={filter_title}
      content={content}
      isOpen={isChatMenuOpen}
      toggleEvent={() => toggleFilterMenu(filter_type_name)}
      onSubmit={onSubmitEvent}
      loading={loading}
      disabled={!enabled}
    />
  );
}

const mapStateToProps = (state) => ({
  isChatMenuOpen: state.chat?.filters?.view?.isChatMenuOpen,
  activeFilters: state.chat?.filters?.activeFilters,
  loading: state.chat?.filters?.loading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(ChatActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ChatsModal);
