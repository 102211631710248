import { isMobile } from 'react-device-detect';
import styled from 'styled-components';

export const ActionGroup = styled.div`
  ${!isMobile && 'position: absolute'};
  ${!isMobile && 'bottom: 1.5rem'};
  ${!isMobile && 'width: calc(100% - 3rem)'};
  ${isMobile && 'margin: 10px 0px'};
`;

export const ActionGroupContent = styled.div`
  display: flex;
  justify-content: space-between;
`;
