import React from 'react';
import { Icon } from '@polichat/flamboyant';

export default function paymentMethod({method, quantity}){
  let content = '';
  let icon = '';
  let colorBackground = '';


  if(method == 'billet'){
    content = 'x via boleto';
    icon = 'poli-icon pi-bol-fill';
    colorBackground = 'var(--info)';
  } else if(method == 'creditCard'){
    content = 'x cartão de crédito';
    icon = 'poli-icon pi-credit-card-fill';
    colorBackground = 'var(--success)';
  } else if(method == 'debit'){
    content = 'x cartão de débito';
    icon = 'poli-icon pi-debit-fill';
    colorBackground = 'var(--attention)';
  } else if(method == 'pix') {
    content = 'x via PIX';
    icon = 'poli-icon pi-pig-fill';
    colorBackground = 'var(--attention)';
  } else {
    content = method;
  }

  const style = {
    box: {
      display: 'grid',
      gridTemplateAreas: 'icon content',
      gridTemplateColumns: '3rem 1fr',
      gridTemplateRows: 'none',
      alignItems: 'center',
      justifyContent: 'center',
    },
    boxIcon: {
      backgroundColor: colorBackground,
      borderRadius: '3rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '2.6rem',
      height: '2.6rem'
    }
  }


  return(
    <div style={style.box}>
      <div style={style.boxIcon}>
        <Icon icon={icon} size={25} color="white"/>
      </div>
      <span style={{alingSelf:"center"}}>
        {quantity}{content}
      </span>
    </div>
  );
}
