import React, { useRef, useState, useEffect } from 'react';
import {
  EditButton,
  ProfileWabaPhotoMain,
  PhotoImage,
  ProfilePictureContainer,
} from './ProfileWabaPhoto.styles';
import Animation from '../../../../../common/animation';
import { Icon } from '@polichat/flamboyant';
import { toast } from 'react-toastify';
import poliConfig from '../../../../../../utils/poliConfig';
import poliApiGatewayRequest from '../../../../../../utils/poliApiGatewayRequest';

const ProfileWabaPhoto = ({ channelData }) => {
  const { id, profile, customer_id } = channelData || {};
  let { photo } = profile || {};

  const [uploading, uploadingSet] = useState(false);
  const [photoLocal, photoLocalSet] = useState(photo);
  useEffect(() => {
    photoLocalSet(photo);
  }, [photo]);

  const inputFile = useRef(null);

  const fileUpload = async ({ blob }) => {
    const url = `${poliConfig.channelCustomerBaseURL}/${id}/photo?customer_id=${customer_id}`;
    const formData = new FormData();
    formData.append('photo', blob);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    return poliApiGatewayRequest.post(url, formData, config);
  };

  const cropResizeImage = async ({ file }) => {
    return new Promise((resolve) => {
      var reader = new FileReader();
      reader.onload = function (e) {
        var image = document.createElement('img');
        image.onload = function () {
          const sizeMax = 640;

          const canvas = document.createElement('canvas');
          canvas.mozImageSmoothingEnabled = false;
          canvas.webkitImageSmoothingEnabled = false;
          canvas.msImageSmoothingEnabled = false;
          canvas.imageSmoothingEnabled = false;
          canvas.width = sizeMax;
          canvas.height = sizeMax;

          let width = image.width;
          let height = image.height;

          let sx = 0;
          let sy = 0;
          let sw = width;
          let sh = height;
          if (width > height) {
            sx = width / 2 - height / 2;
            sw = height;
          } else {
            sy = height / 2 - width / 2;
            sh = width;
          }

          canvas
            .getContext('2d')
            .drawImage(image, sx, sy, sw, sh, 0, 0, sizeMax, sizeMax);

          function dataURItoBlob(dataURI) {
            var byteString;
            if (dataURI.split(',')[0].indexOf('base64') >= 0)
              byteString = atob(dataURI.split(',')[1]);
            else byteString = unescape(dataURI.split(',')[1]);
            var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
            var ia = new Uint8Array(byteString.length);
            for (var i = 0; i < byteString.length; i++) {
              ia[i] = byteString.charCodeAt(i);
            }
            return new Blob([ia], { type: mimeString });
          }

          const dataURL = canvas.toDataURL('image/jpeg', 0.8);
          const blob = dataURItoBlob(dataURL);

          const preview = document.getElementById('preview');
          if (preview) {
            document.getElementById('preview').src = dataURL;
          }

          resolve({ blob });
        };
        image.src = e.target.result;
      };
      reader.readAsDataURL(file);
    });
  };

  const handleFileChange = async (e) => {
    if (e.target.value) {
      try {
        const file = e.target.files[0]; // Pega a imagem que foi selecionada

        uploadingSet(true); // Início processo de upload

        const { blob } = await cropResizeImage({ file });

        const {
          data: { url: photoNew },
        } = await fileUpload({ blob }); // Envia a imagem para ser tratada e armazenada no AWS

        uploadingSet(false); // Requisição terminou

        if (photoNew) {
          // Requisição bem sucedida
          toast.success('Foto do canal alterada com sucesso!');
          photoLocalSet(photoNew);
        } else {
          // Requisição mal sucedida
          throw 'Problema ao atualizar foto do canal';
        }
      } catch (error) {
        toast.error('Foto do canal não pôde ser atualizada!');
        uploadingSet(false);
      }
    }
  };

  const handleImageClick = () => {
    inputFile.current.click();
  };

  return (
    <ProfileWabaPhotoMain className="profileWabaPhotoMain">
      <ProfilePictureContainer>
        {uploading ? (
          <Animation icon="loading" size={'lg'} />
        ) : photoLocal ? (
          <PhotoImage id="preview" src={photoLocal} />
        ) : (
          <Icon icon="poli-icon pi-company-fill" size="150" />
        )}
      </ProfilePictureContainer>
      <EditButton
        title="Clique para atualizar a foto"
        alt="Clique para atualizar a foto"
        onClick={handleImageClick}
      >
        <Icon icon="poli-icon pi-pencil-fill" size="30" />
      </EditButton>
      <input
        type="file"
        onChange={handleFileChange}
        ref={inputFile}
        style={{ display: 'none' }}
        accept="image/jpeg, image/pjpeg, image/png, image/gif"
      />
    </ProfileWabaPhotoMain>
  );
};

export default ProfileWabaPhoto;
