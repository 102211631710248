import styled from 'styled-components';

export const Td = styled.td`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 15vw;

  @media screen and (max-width: 768px) {
    box-sizing: content-box;
    max-width: 40vw;
  };
`

export const Container = styled.div`
  & .alert {
    justify-content: center;
  }

  & .alert-light {
    background: none;
  }

  thead {
    position: sticky;
    top: 0;
    background-color: var(--primary-background-color);
    z-index: 2;
  }
`;

export const TemplateBadge = styled.div`
  display: inline-flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: space-between;
`;

export const OptionsButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  padding: 0.5rem;

  & .OptionEdit {
    border: 1px solid var(--light);
    border-radius: 0.4rem;
    justify-content: center;
    color: white;

    :hover {
      color: var(--secondary-font-color) !important;
      button {
        background-color: blue;
      }
    }
  }

  & .OptionDelete {
    border: 1px solid var(--danger-75);
    border-radius: 0.4rem;
    justify-content: center;
    color: var(--danger-75) !important;

    :hover {
      color: var(--danger) !important;
      background-color: var(--danger-25) !important;
    }
  }
`;
