import { forwardRef, useImperativeHandle } from 'react';
import { Controller } from 'react-hook-form';
import {
  CustomPhoneInput,
  Input,
  Label,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
} from '@polichat/flamboyant';
import { FormFeedback } from 'reactstrap';
import { toast } from 'react-toastify';

import Illustration from 'src/assets/images/illustrations/documentation-step-channel.svg';
import Animation from 'src/components/common/animation';

import { LocalFormValues, useLocalForm } from './useForm';
import { useChannelCustomerService } from '../useChannelCustomerService';
import { DtoDialog360Channel } from '../../service/DtoDialog360Channel';
export interface Step1Ref {
  submit: () => void;
}

export interface Step1Props {
  customer_id: number;
  onSuccess: (channel: DtoDialog360Channel) => void;
}
export const Step1 = forwardRef<Step1Ref, Step1Props>((props, ref) => {
  const { customer_id } = props;
  const form = useLocalForm();
  const service = useChannelCustomerService({ customer_id });

  function handleFormSubmit(data: LocalFormValues) {
    service.create(data, handleCreateSuccess, handleCreateError);
  }

  function handleCreateSuccess(data: any) {
    props.onSuccess(data);
  }

  function handleCreateError(message?: string) {
    toast.error(`Erro ao cadastrar número!`);
    form.setError('phone', {
      message: message ? message : 'Número inválido',
    });
  }

  useImperativeHandle(ref, () => ({
    submit: () => {
      form.handleSubmit(handleFormSubmit)();
    },
  }));

  return (
    <>
      <Row>
        <Col>
          <p>
            Para iniciarmos o processo de homologação com o Facebook, precisamos
            de alguns documentos que comprovem o nome legal, endereço,
            representantes e telefone. Estas são algumas sugestões. Colete o
            máximo de documentação que conseguir, esse processo poderá ser feito
            posteriormente ao cadastro do seu canal Waba.
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <Row>
            <Col md={12}>
              <FormGroup>
                <Controller
                  control={form.control}
                  name={'name'}
                  render={({ field, fieldState }) => (
                    <>
                      <Label for="channel_name">Nome do canal</Label>
                      <Input
                        id="channel_name"
                        type="text"
                        value={field.value}
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                        invalid={!!fieldState.error}
                      />
                      <FormFeedback>{fieldState.error?.message}</FormFeedback>
                    </>
                  )}
                />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <FormGroup>
                <Controller
                  control={form.control}
                  name={'phone'}
                  render={({ field, fieldState }) => (
                    <>
                      <Label for="channel_phone">Telefone</Label>
                      <CustomPhoneInput
                        id="channel_phone"
                        value={field.value}
                        onChange={field.onChange}
                        country={'br'}
                        autoFormat={true}
                        required
                        placeholder="+55 (62) 9999-9999"
                      />
                      {!!fieldState.error && (
                        <span
                          style={{
                            width: '100%',
                            marginTop: '0.25rem',
                            fontSize: '80%',
                            color: 'var(--danger)',
                          }}
                        >
                          {fieldState.error?.message}
                        </span>
                      )}
                    </>
                  )}
                />
              </FormGroup>
            </Col>
          </Row>
        </Col>
        <Col auto>
          <img src={Illustration} />
        </Col>
      </Row>

      <Row>
        <Col>
          {service.loading && <Animation size={'tiny'} icon={'loading'} />}
        </Col>
      </Row>
    </>
  );
});
