import React, { useState } from 'react';

import FilterModalTemplate from './FilterModalTemplate';

import { Input, Label, FormGroup } from '@polichat/flamboyant';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ChatActions from '../../../../../store/modules/chat/actions';
import { convertActiveTabInScreen } from '../../../../../store/modules/chat/functions';

function DateModal({
  isDateMenuOpen,
  toggleFilterMenu,
  activeFilters,
  submitFilter,
  loading,
  clearFilter,
  currentTabNavigation
}) {
  // dados gerais
  const filter_type_name = 'date';
  const filter_title = 'Filtrar por Data';
  const filter_icon = 'poli-icon pi-calendar-line';
  const currentScreen = convertActiveTabInScreen(currentTabNavigation);

  let content;
  let enabled = true;

  const findCurrentDateFilter = () => {
    let active_filter = {
      start_date: null,
      end_date: null,
    };

    if (activeFilters.length) {
      activeFilters.forEach((filter) => {
        if (filter.type === filter_type_name) {
          active_filter.start_date = filter.start_date;
          active_filter.finish_date = filter.finish_date;
        }
      });
    } else {
      active_filter.start_date = formatDate(new Date());
      active_filter.finish_date = formatDate(new Date());
    }

    return active_filter;
  };

  const formatDate = (date) => {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  };

  const [startDate, setStartDate] = useState(
    findCurrentDateFilter().start_date
  );
  const [finishDate, setFinishDate] = useState(
    findCurrentDateFilter().finish_date
  );

  const formatDateDMY = (date) => {
    let split = date.split('-');

    if (split[0] && split[1] && split[2]) {
      return split[2] + '/' + split[1];
    }
  };

  const onSubmitEvent = () => {
    if (new Date(finishDate) < new Date(startDate)) {
      return;
    }

    let payload = Object.assign({}, activeFilters);

    if (startDate) {
      let value = { start_date: startDate, finish_date: finishDate };
      let name = formatDateDMY(startDate) + ' a ' + formatDateDMY(finishDate);

      payload.date = [
        {
          name: name,
          value: value,
        },
      ];

      submitFilter(payload, currentScreen);
    } else {
      payload.date = [];
      clearFilter(payload);
    }

    toggleFilterMenu(filter_type_name);
  };

  const onChangeStartDate = (value) => {
    setStartDate(value);

    let start_date = new Date(value);
    let finish_date = new Date(finishDate);

    if (start_date > finish_date) {
      setFinishDate(value);
    }
  };

  const onChangeFinishDate = (value) => {
    setFinishDate(value);
  };

  content = (
    <div className="filter-date">
      <FormGroup>
        <Label for="exampleDate">De:</Label>
        <Input
          onChange={(e) => onChangeStartDate(e.target.value)}
          type="date"
          name="date"
          id="exampleDate"
          placeholder="date placeholder"
          value={startDate}
        />
      </FormGroup>

      <FormGroup>
        <Label for="exampleDate">Até:</Label>
        <Input
          onChange={(e) => onChangeFinishDate(e.target.value)}
          type="date"
          name="date"
          id="exampleDate"
          placeholder="date placeholder"
          min={startDate}
          value={finishDate}
        />
      </FormGroup>
    </div>
  );

  enabled = true;

  return (
    <FilterModalTemplate
      id="date_chat_modal"
      icon={filter_icon}
      title={filter_title}
      content={content}
      isOpen={isDateMenuOpen}
      toggleEvent={() => toggleFilterMenu(filter_type_name)}
      onSubmit={onSubmitEvent}
      loading={loading}
      disabled={!enabled}
    />
  );
}

const mapStateToProps = (state) => ({
  date: state.chat?.config?.general?.date,
  isDateMenuOpen: state.chat?.filters?.view?.isDateMenuOpen,
  activeFilters: state.chat?.filters?.activeFilters,
  loading: state.chat?.filters?.loading,
  currentTabNavigation: state.chat?.config?.activeTabNavigation,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(ChatActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DateModal);
