export const formatDate = (value) => {
  //  2020-09-30 12:43:56 -> dd/mm/aaaa
  if (value) {
    value = value.split(' ');
    value = value[0];
    value = value.split('-').reverse().join('/');
  } else {
    value = '';
  }

  return value;
};

export const formatDateAbbreviation = (value) => {
  //  2022-01 -> mm/aaaa
  if (value) {
    const _date = value?.split('-');

    // Ano abreviado
    _date[0] = _date[0]?.toString()?.substr(_date[0]?.length - 2);

    // Mes abreviado descritivo
    switch (_date[1]) {
      case '01':
        _date[1] = 'Jan';
        break;
      case '02':
        _date[1] = 'Fev';
        break;
      case '03':
        _date[1] = 'Mar';
        break;
      case '04':
        _date[1] = 'Abr';
        break;
      case '05':
        _date[1] = 'Mai';
        break;
      case '06':
        _date[1] = 'Jun';
        break;
      case '07':
        _date[1] = 'Jul';
        break;
      case '08':
        _date[1] = 'Ago';
        break;
      case '09':
        _date[1] = 'Set';
        break;
      case '10':
        _date[1] = 'Out';
        break;
      case '11':
        _date[1] = 'Nov';
        break;
      case '12':
        _date[1] = 'Dez';
        break;
    }

    return _date.reverse().join('/');
  } else {
    return '';
  }
};
