import Animation from '../../../components/common/animation';
import PageMainBody from '../../../components/common/pages/PageMainBody/PageMainBody.component';
import PageMainHeader from '../../../components/common/pages/PageMainHeader/PageMainHeader.component';

import { MetricsResults } from './metrics/metricsResults';

import { useMetaBusinessManagerService } from './useMetaBusinessManagerService';
import { useMetaAdMetricService } from './metrics/useMetaAdMetricService';
import { NotPermissionPage } from '../components/common/notPermission';
import { MetricsFilters } from './metrics/metricsFilters';

export interface PoliAdsPageProps {
  customer_id: number;
}
export function PoliAdsPage(props: PoliAdsPageProps) {
  const customer_id = props.customer_id;

  const businessManager = useMetaBusinessManagerService({
    customer_id,
  });

  const metaAd = useMetaAdMetricService({
    organization_id: businessManager.organizationId,
  });
  // ---------------------------------------------
  // Transformations
  // ---------------------------------------------
  // Render

  return (
    <>
      <PageMainHeader
        icon={'poli-icon pi-alerts-fill'}
        iconSize={'22'}
        title="Métricas"
        key="poli_ads"
      >
        {/* Filters */}
        <MetricsFilters
          disabled={!(businessManager.customerHasOrganizationId === true)}
          onMetricTypeChange={(values) => {
            metaAd.setMetricFilter(values);
          }}
        />
      </PageMainHeader>
      <PageMainBody>
        <>
          {businessManager.loading && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Animation icon="chat-loading" size={'large'} top={'15%'} />
              <span>Verificando dados</span>
            </div>
          )}

          {!businessManager.loading &&
            businessManager.customerHasOrganizationId === false && (
              <NotPermissionPage helperText="Você ainda não possui acesso ao PoliAds" />
            )}

          {!businessManager.loading &&
            businessManager.customerHasOrganizationId === true && (
              <>
                {/* Results */}
                {!metaAd.error.hasError && (
                  <MetricsResults
                    loading={metaAd.loading}
                    items={metaAd.data?.items}
                    pagination={metaAd.data?.paginationInfo}
                    onPageChange={(value) => metaAd.setPage(value)}
                  />
                )}

                {/* User error message */}
                {metaAd.error.hasError && (
                  <NotPermissionPage
                    helperText={
                      metaAd.error.message ||
                      'Falha no servidor! Tente novamente'
                    }
                  />
                )}
              </>
            )}
        </>
      </PageMainBody>
    </>
  );
}
