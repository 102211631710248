import { ORDINATION_TYPES, OrdinationProps } from "src/pages/report/shared/types/Ordination"
import { PerformanceRecordProps, PerformanceResumeProps } from "../../types/OperatorsPerformanceResponse"
import { TypeFiltersProps } from "src/pages/report/shared/types/Filters";

export interface TableProps {
  filters?: TypeFiltersProps;
  records?: PerformanceRecordProps[];
  resume?: PerformanceResumeProps;
  ordinationProps: OrdinationProps;
  onOrdinationChange: (event: any) => void;
  loading: boolean;
}

export interface OrderTable {
  id: string;
  title: string
  text?: string
}

export const DEFAULT_ORDINATION_CONFIG: OrdinationProps = {
  order_by: 'operator_name',
  order: ORDINATION_TYPES.ASCENDING
};

export const ORDERS_TABLE_TOOLTIP: OrderTable[] = [
  { id: 'operator_name', title: 'Nome do Operador' },
  { id: 'interactions', title: 'Interações', text: 'Número de vezes que o operador falou com os contatos no período filtrado. <br></br>Se um operador falar com o mesmo contato em 2 dias diferentes, serão contabilizados 2 interações.' },
  { id: 'clients', title: 'Clientes', text: 'Número de pessoas diferentes que cada operador interagiu. <br></br>Caso o operador fale com o mesmo contato 4 vezes, ele será considerado 1 vez por operador.' },
  { id: 'new_clients', title: 'Clientes Novos', text: 'Quantidade de clientes que houve uma troca de mensagem e que tiveram seus contatos criados no período filtrado.' },
  { id: 'number_of_opportunities', title: 'Oportunidades', text: 'Número de carrinhos ou cobranças que foram enviadas para os contatos.' },
  { id: 'number_of_sales', title: 'Vendas', text: 'Número de carrinhos ou cobranças que foram pagos no período filtrado.' },
  { id: 'value_sold', title: 'Valor Vendido', text: 'Total recebido das cobranças e carrinhos.' },
];
