import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';

import Header from './Header';
import Messages from './Messages';
import TextBox from './TextBox';
import MessageLoading from './MessageLoading';
import Dropzone from '../../../dropzone';
import globalVars from '../../../../utils/globalVars';
import { DispatchEvent } from '../../../../utils/dispatchEvent';
import { useWhiteLabel } from '../../../../hooks/useWhiteLabel';

import { bindActionCreators } from 'redux';
import * as ChatActions from '../../../../store/modules/chat/actions';
import { CustomerChannelBalanceProvider } from '../contexts/customerChannelBalance/provider';
import { CustomerHasPaymentIntegratorProvider } from '../contexts/customerHasPaymentIntegrator/provider';

import { useDropzoneLegendImageFlag } from '../../../dropzone/v2/useDropzoneFlag';
import { DropzoneUploader } from '../../../dropzone/v2';

var content;
var messagesRender;

function Chat({
  chat,
  dateConnectedSocket,
  messagesLoaded,
  isLoadingMessagesOneChat,
  settings,
  chatDropzone,
  fetchMessagesRequest,
  oneChatLoaded,
  idSelectedChat,
  fetchShoppingCartDetail,
}) {
  const whitelabel = useWhiteLabel();

  const { showDropzoneWithLegendV2 } = useDropzoneLegendImageFlag();

  useEffect(() => {
    if (chat.contact.shopping_cart_current) {
      fetchShoppingCartDetail(chat.contact.shopping_cart_current.id);
    }
  }, [chat.contact]);

  /**
   * Quando fica offline e a plataforma volta a ficar online devemos buscar novamente as mensagens
   */
  const [offline, setOffline] = useState(false);

  window.addEventListener('online', () => {
    if (offline) setOffline(false);
  });

  window.addEventListener('offline', () => {
    if (!offline) setOffline(true);
  });

  useEffect(() => {
    if (!offline && chat.loadMessages) {
      fetchMessagesRequest(chat.id, true);
    }
  }, [offline, dateConnectedSocket]);
  /* end */

  useEffect(() => {
    if (whitelabel.scriptInjection !== '') {
      //criação de variável global para ser usada em scripts injetados
      if (chat.view?.selectedChannel) {
        globalVars.setChannel({
          id: chat.view?.selectedChannel?.id,
          name: chat.view?.selectedChannel?.channel_name,
          uid: chat.view?.selectedChannel?.channel_uid,
          type: chat.view?.selectedChannel?.type,
        });
      } else {
        globalVars.setChannel({ id: chat.chat.channel_id });
      }
      DispatchEvent('change_channel', window.Poli?.channel);
    }
  }, [chat.chat.channel_id, whitelabel.scriptInjection]);
  /**
   * Se não foi carregada as mensagens ainda
   */
  useEffect(() => {
    if (!chat.loadMessages) {
      const offLoading = true;
      fetchMessagesRequest(chat.id, offLoading);
    } else {
      oneChatLoaded();
      idSelectedChat(chat.id);
    }
  }, [chat.loadMessages, chat.id]);

  useEffect(() => {
    if (chat.contact && whitelabel.scriptInjection !== '') {
      //criação de variável global para ser usada em scripts injetados
      globalVars.setContact(chat.contact);
      DispatchEvent('change_contact', window.Poli?.contact);
    }
  }, [chat.id]);

  /**
   * Hook renderizar componente mensagem
   */
  useMemo(() => {
    messagesRender = <MessageLoading />;
    if (messagesLoaded && !isLoadingMessagesOneChat) {
      messagesRender = <Messages />;
    }
  }, [messagesLoaded && !isLoadingMessagesOneChat]);

  /**
   * Hook montar chat completo
   */
  useMemo(() => {
    let isChatDropzone = chatDropzone;
    if (isChatDropzone) {
      isChatDropzone = settings.can_send_file;
    }

    content = (
      <div className="realChatContainer">
        <Header />
        {isChatDropzone &&
          (showDropzoneWithLegendV2 ? <DropzoneUploader /> : <Dropzone />)}
        {!isChatDropzone && messagesRender}
        {/* {!isChatDropzone && <TextBox />}; */}
        {
          <div
            style={{
              display: !isChatDropzone ? 'block' : 'none',
              position: 'relative',
              backgroundColor: 'var(--background-messages)',
            }}
          >
            <TextBox />
          </div>
        }
      </div>
    );
  }, [chatDropzone, settings.can_send_file, messagesRender]);

  return (
    <CustomerChannelBalanceProvider
      contactId={chat.id}
      channelCustomerId={chat?.view?.selectedChannel?.id}
    >
      <CustomerHasPaymentIntegratorProvider
        customer_id={settings.customer_id}
      >
        {content}
      </CustomerHasPaymentIntegratorProvider>
    </CustomerChannelBalanceProvider>
  );
}
const mapStateToProps = (state) => ({
  dateConnectedSocket: state?.chat?.socket?.dateConnected,
  chat: state.chat?.chats?.find((chat) => chat.open === true),
  isLoadingMessagesOneChat: state.chat.loadingMessagesOneChat,
  messagesLoaded: state.chat.messagesLoaded,
  settings: state.chat?.config?.general?.settings,
  chatDropzone: state.chat.chats?.find(
    (chat) => chat.id === state.chat.chatSelectedId
  )?.chatDropzone,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(ChatActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Chat);
