import { Modal, ModalHeader } from '@polichat/flamboyant';
import styled from 'styled-components';

export const CustomModal = styled(Modal)`
  & .modal-content {
    padding: 2.5rem;
  }
`;

export const CustomModalHeader = styled(ModalHeader)`
  & h5 {
    justify-content: flex-start !important;
  }
  & i {
    padding: 1rem;
    margin-right: 1rem;
  }
  & button {
    margin-right: -0.5rem !important;
    margin-top: -2rem !important;
  }
`;
