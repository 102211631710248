import React, {
  useState,
  useRef,
  useEffect,
  ReactNode,
  ChangeEvent,
} from 'react';
import { Picker } from 'emoji-mart';
import {
  ButtonGroup,
  Button,
  Icon,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  UncontrolledTooltip,
} from '@polichat/flamboyant';
import {
  Container,
  StyledDropdownMenu,
  TextArea,
  Toolbar,
} from './CustomTextAreaWithEditor.styles';
import {
  FormattingStyle,
  removeWhitespaceFromSelectionEnd,
  removeWhitespaceFromSelectionStart,
  applyStyle,
} from './utils';

interface CustomTextAreaWithEditorProps {
  id: string;
  value: string;
  onChange: (value: string) => void;
  maxLength: number;
  showCharCounter: boolean;
  required: boolean;
  disabled: boolean;
  placeholder: string;
  children: ReactNode;
}

function CustomTextAreaWithEditor({
  id,
  value,
  onChange: setValue,
  maxLength,
  showCharCounter,
  required,
  disabled,
  placeholder,
  children,
}: CustomTextAreaWithEditorProps) {
  const [isEmojiPickerOpen, setIsEmojiPickerOpen] = useState(false);
  let emojiPicker;

  const [charCounter, setCharCounter] = useState(0);
  const inputRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    setCharCounter(value?.length);
  }, [value]);

  let emojiTheme: 'light' | 'dark' = 'light';
  if (localStorage.getItem('@polichat/darkmode') === 'true') {
    emojiTheme = 'dark';
  }

  if (isEmojiPickerOpen) {
    emojiPicker = (
      // @ts-ignore
      <Picker
        set="apple"
        theme={emojiTheme}
        emoji="point_up"
        onSelect={(emoji: any) => setValue(value + emoji.native)}
        style={{
          width: '100%',
        }}
        i18n={{
          search: 'Pesquisar emoji',
          notfound: 'Emoji não encontrado',
          categories: {
            search: 'Emojis Econtrados',
            recent: 'Recentes',
            people: 'Emojis & Pessoas',
            nature: 'Animais & Natureza',
            foods: 'Comida & Bebida',
            activity: 'Atividade',
            places: 'Viagens & Locais',
            objects: 'Objetos',
            symbols: 'Símbolos',
            flags: 'Bandeiras',
            custom: 'Personalizado',
          },
        }}
      />
    );
  }

  function handleText(event: ChangeEvent<HTMLTextAreaElement>) {
    const {
      target: { value },
    } = event;
    setValue(value);
  }

  const handleStyleButtonClick = (style: FormattingStyle) => {
    if (!inputRef.current) return;

    const selectionStart = removeWhitespaceFromSelectionStart(
      value,
      inputRef.current.selectionStart,
      inputRef.current.selectionEnd
    );

    const selectionEnd = removeWhitespaceFromSelectionEnd(
      value,
      inputRef.current.selectionStart,
      inputRef.current.selectionEnd
    );

    const newValue =
      value?.substring(0, selectionStart) +
      applyStyle(value?.substring(selectionStart, selectionEnd), style) +
      value?.substring(selectionEnd);

    setValue(newValue);
  };

  return (
    <>
      <TextArea
        wrap="soft"
        id={id}
        value={value}
        maxLength={maxLength}
        required={required}
        placeholder={placeholder}
        disabled={disabled}
        ref={inputRef}
        onChange={(e) => {
          handleText(e);
        }}
      />
      <Container>
        <span>
          {showCharCounter && (
            <span
              style={{
                fontStyle: 'italic',
                color: charCounter === maxLength ? 'var(--danger)' : '',
              }}
            >
              Caracteres: {`${charCounter}${maxLength ? '/' + maxLength : ''}`}
            </span>
          )}
        </span>
        {!disabled && (
          <Toolbar>
            <ButtonGroup>
              <UncontrolledTooltip delay={250} target="toolbar-btn-bold">
                Negrito
              </UncontrolledTooltip>
              <Button
                id="toolbar-btn-bold"
                color="neutral"
                onClick={() => handleStyleButtonClick('bold')}
              >
                <Icon icon={'poli-icon pi-bold-fill'} size={20} />
                <span style={{ fontWeight: 'bold' }}></span>
              </Button>

              <UncontrolledTooltip delay={250} target="toolbar-btn-italic">
                Itálico
              </UncontrolledTooltip>
              <Button
                id="toolbar-btn-italic"
                color="neutral"
                onClick={() => handleStyleButtonClick('italic')}
              >
                <Icon icon={'poli-icon pi-italic-fill'} size={20} />
                <span style={{ fontStyle: 'italic' }}></span>
              </Button>

              <UncontrolledTooltip
                delay={250}
                target="toolbar-btn-strikethrough"
              >
                Riscado
              </UncontrolledTooltip>
              <Button
                id="toolbar-btn-strikethrough"
                color="neutral"
                onClick={() => handleStyleButtonClick('strikethrough')}
              >
                <Icon icon={'poli-icon pi-strike-fill'} size={20} />
                <span style={{ textDecoration: 'line-through' }}></span>
              </Button>

              <UncontrolledTooltip delay={250} target="toolbar-btn-code">
                Código
              </UncontrolledTooltip>
              <Button
                id="toolbar-btn-code"
                color="neutral"
                onClick={() => handleStyleButtonClick('code')}
              >
                <Icon icon={'poli-icon pi-type-code-fill'} size={20} />
                <span></span>
              </Button>
            </ButtonGroup>
            <UncontrolledTooltip delay={250} target="toolbar-btn-emoji">
              Emoji
            </UncontrolledTooltip>
            <Dropdown
              isOpen={isEmojiPickerOpen}
              toggle={() => setIsEmojiPickerOpen((s) => !s)}
            >
              <DropdownToggle
                id="toolbar-btn-emoji"
                color="neutral"
                className={isEmojiPickerOpen ? 'active' : ''}
              >
                <Icon icon={'poli-icon pi-smille-fill'} size={20} />
              </DropdownToggle>

              <StyledDropdownMenu>{emojiPicker}</StyledDropdownMenu>
            </Dropdown>
            {children}
          </Toolbar>
        )}
      </Container>
    </>
  );
}
export default CustomTextAreaWithEditor;
