import React from 'react';
import { Icon } from '@polichat/flamboyant';

function ChatFinished(props) {
  let style = styles.chatFinished;
  let content = (
    <span
      style={style}
      title={props.body_preview || props.body}
    >
      <Icon
        icon="poli-icon pi-finish-fill"
        size={16}
        color="var(--red-polichat)"
      />
      <span style={styles.text}>
        Conversa Finalizada
      </span>
    </span>
  );

  return content;
}

const styles = {
  chatFinished: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontWeight: 'bold',
  },
  text: {
    marginLeft: '5px',
    fontSize: '0.8rem',
    color: 'var(--primary-font-color)',
  }
};

export default ChatFinished;
