import { AlertRepeat, ChatAlert } from '../../../types/alerts';

export const CHAT_ALERT_SHOWN = 'chat_alert_shown';

/**
 * Checks if the given object contains the necessary properties to be considered
 * a valid ChatAlert.
 *
 * @export
 * @param {*} alert
 * @return {*}  {alert is ChatAlert}
 */
export function isValidAlert(alert: unknown): boolean {
  if (!alert || typeof alert !== 'object') return false;

  return (
    alert.hasOwnProperty('id') && // obrigatório
    alert.hasOwnProperty('priority') && // obrigatório
    alert.hasOwnProperty('repeat') &&
    // as propriedades abaixo são opcionais, mas ao menos uma delas deve existir
    (alert.hasOwnProperty('message') || alert.hasOwnProperty('image'))
  );
}

export function isToday(someDate: Date): boolean {
  const today = new Date();
  return (
    someDate.getDate() == today.getDate() &&
    someDate.getMonth() == today.getMonth() &&
    someDate.getFullYear() == today.getFullYear()
  );
}

export const getAlertLocalStorageName = (id: number) => `chat_alert_${id}`;

const getAlertLastSeen = (alert: ChatAlert) =>
  Number(localStorage.getItem(getAlertLocalStorageName(alert.id)));

const higherPriority = (a: ChatAlert, b: ChatAlert) => b.priority - a.priority;

// Define how to check if alert should repeat by it's AlertRepeat property
const alertRepeatHandlers = new Map<AlertRepeat, (alert: ChatAlert) => boolean>(
  [
    ['visit', () => true],
    [
      'halfhour',
      (alert) => Date.now() - getAlertLastSeen(alert) > 30 * 60 * 1000,
    ],
    [
      'daily',
      (alert) => {
        const alertLastSeen = new Date(getAlertLastSeen(alert));
        return !isToday(alertLastSeen);
      },
    ],
  ]
);

// Returns whether or not an alert should be shown by looking at it's "repeat" property
function shouldRepeat(alert: ChatAlert) {
  // filter alerts based on their 'repeat' property
  const handler = alertRepeatHandlers.get(alert.repeat);
  return !!handler && handler(alert);
}

/**
 * Given an array possibly containing multiple ChatAlerts, returns the object of type {@link ChatAlert} with
 * the highest priority that should be seen or null if no valid alert is found in the array
 *
 * @export
 * @param {any[]} alerts
 * @returns {ChatAlert | null}
 */
export function selectAlert(alerts: unknown): ChatAlert | null {
  if (sessionStorage.getItem(CHAT_ALERT_SHOWN)) return null;

  if (!alerts || !Array.isArray(alerts) || alerts.length === 0) return null;

  return alerts
    .filter(isValidAlert)
    .filter(shouldRepeat)
    .sort(higherPriority)[0];
}
