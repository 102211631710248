import React, { useState, useEffect } from 'react';
import { useFlagsmith } from 'flagsmith/react';
import { useDispatch } from 'react-redux';
import { toggleChangelog } from '../../../store/modules/changelog/actions';
import packageJson from '../../../../package.json';

function Changelog() {
  /* Preencha o array com as informações do seu card */
  let cardsArray = [
    { id: 'PS-2799', flag: 'sem flag' },
    { id: 'PS-3099', flag: 'sem_flag_wl' },
    { id: 'PS-2881', flag: 'enable_send_interactive_msg' },
    { id: 'PS-3306', flag: 'create_company_dropdown'},
    { id: 'PS-3306', flag: 'crm_new_chat_details'},
    { id: 'DEV-861', flag: 'image_scaling'},
  ];

  const dispatch = useDispatch();
  const flagsmith = useFlagsmith();
  const [cards, setCards] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (cards.length === 0) {
      let availableFlags = flagsmith.getAllFlags();

      cardsArray.forEach((element) => {
        let newCard = {
          id: element.id,
          flag: element.flag,
          status: availableFlags[element.flag]?.enabled,
        };
        setCards((oldArray) => [...oldArray, newCard]);
      });

      setLoading(false);
    }
  }, [flagsmith]);

  function closeModal() {
    dispatch(toggleChangelog());
  }

  return (
    !loading && (
      <div className="changelog-modal">
        <div className="changelog-modal-close" onClick={() => closeModal()}>
          x
        </div>
        <div className="changelog-modal-title">
          🚀 Changelog da versão {packageJson?.version} 🚀
        </div>
        <div className="changelog-modal-content">
          <div className="changelog-header">
            <div className="changelog-card-column">card</div>
            <div className="changelog-card-column">flag</div>
            <div className="changelog-card-column">status</div>
          </div>
          {cards.map((card) => (
            <div className="changelog-card">
              <div className="changelog-card-column">{card.id}</div>
              <div className="changelog-card-column">{card.flag}</div>
              <div className="changelog-card-column">
                {card.status ? 'Ativada' : 'Desativada'}
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  );
}

export default Changelog;
