import React, { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Icon,
  FormGroup,
  Label,
  CustomText,
  CustomPhoneInput,
  Button,
  Row,
  Col,
} from '@polichat/flamboyant';
import { toast } from 'react-toastify';
import history from '../../../../../../services/history';
import ChannelsService from '../../../../../../services/tools/channels/channels';

import ButtonDoubts from '../../common/ButtonDoubts/ButtonDoubts.component';
import ActionGroupButtons from '../../ActionGroupButtons/ActionGroupButtons.component';

import ChannelAddWabaDocuments from '../../ChannelAddWaba/ChannelAddWabaDocuments';

import {
  Container,
  HeadingTextContainer,
  GridInformationContainer,
  DocumentList,
  Item,
  ExtraInformation,
  InputGroupContainer,
  NameContainer,
  PhoneContainer,
} from './GatherDocuments.styles';
import { Illustration } from 'src/assets/illustratorElement/illustrators';

const GatherDocuments = ({ classic }) => {
  const customer_id = useSelector((state) => state.general.current_customer_id);
  const wlConfig = useSelector((state) => state?.whitelabel?.config);
  const [processDocuments, processDocumentsSet] = useState(false);

  const [id, setId] = useState('');
  const [channelName, setChannelName] = useState('');
  const [channelPhone, setChannelPhone] = useState('');
  const [isMigrationToWaba, setMigrationToWaba] = useState(false);
  const [disableInputs, setDisableInputs] = useState(false);

  // This variables are used to receive state of migrate from polizat to waba
  useEffect(() => {
    if (history && history?.location?.state?.data) {
      setId(history?.location?.state?.data?.id);
      setChannelName(history?.location?.state?.data?.channelName);
      setChannelPhone(history?.location?.state?.data?.channelPhone);
      setMigrationToWaba(history?.location?.state?.data?.isMigrationToWaba);

      setDisableInputs(true);
      return;
    }
  }, [onload]);

  const handleDocumentsSelect = () => {
    processDocumentsSet(true);
  };

  const handleProccessDocumentsNext = () => {
    processDocumentsSet(true);
    processSelectedSet(true);
  };

  const handleProccessNewNext = () => {
    processDocumentsSet(false);
    processSelectedSet(true);
  };

  const handleCloseModal = () => {
    processDocumentsSet(false);
  };

  // Actions to Actions Group Button component
  const goBack = () => history.goBack();
  const handleNextAction = async () => {
    if (!channelName || !channelName.length) {
      toast.error('O campo Nome do Canal é obrigatório');
      return;
    }

    if (!channelPhone || !channelPhone.length || channelPhone.length < 11) {
      toast.error('Telefone inválido!');
      return;
    }

    try {
      let result;

      /* migrationToWabaStatus can be receive 3 values #grt23

      - undefined: undefined
      - started: String
      - finished: String

      */

      const channelConfigs = isMigrationToWaba
        ? {
            id,
            customer_id,
            name: channelName,
            config: {
              classic,
              homologation: 2,
              migrationToWabaStatus: 'started',
            },
          }
        : {
            customer_id,
            channel_id: 6, // Waba
            name: channelName,
            phone: channelPhone,
            config: { classic },
          };

      if (isMigrationToWaba) {
        result = await ChannelsService.updateChannel(channelConfigs);
      } else {
        result = await ChannelsService.createChannel(channelConfigs);
      }

      if (result?.status === 201 || result?.status === 200) {
        if (isMigrationToWaba) {
          history.push({
            pathname: `/tools/channels/edit/${result.data.id}`,
            state: {
              ...result.data,
              data: {
                openCertificationScreen: true,
              },
            },
          });
        } else {
          history.push({
            pathname: `/tools/channels/edit/${result.data.id}`,
            state: result.data,
          });
        }
      } else {
        if (result?.status === 400) {
          toast.error(result.data.error[0].msg);
        } else if (result?.status === 422 || result?.status === 403) {
          toast.error(result.data.message);
        } else {
          toast.error(result.data.error.message);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  if (!classic)
    return (
      <Container>
        {!wlConfig.hide_doubts_button && <ButtonDoubts />}

        <HeadingTextContainer>
          Para iniciarmos o processo de homologação com o Facebook, precisamos
          de alguns documentos que comprovem o nome legal, endereço,
          representantes e telefone.{' '}
          <strong>
            Estas são algumas sugestões. Colete o máximo de documentação que
            conseguir, esse processo poderá ser feito posteriormente ao cadastro
            do seu canal Waba.{' '}
          </strong>
          {!wlConfig.hide_channel_approval_documents_button && (<Button
            onClick={handleDocumentsSelect}
            className="py-0 px-2"
            size="sm"
            color="primary"
          >
            Documentos que você vai precisar
          </Button>)}
          <ChannelAddWabaDocuments
            isOpen={processDocuments}
            handleCloseModal={handleCloseModal}
            handleProccessClassicNext={handleProccessDocumentsNext}
            handleProccessNewNext={handleProccessNewNext}
          />
        </HeadingTextContainer>

        <Row>
          <Col md="6">
            <FormGroup className="mt-3">
              <Label for="channelName">
                <Icon
                  icon="poli-icon pi-channels-fill"
                  color="var(--green-polichat)"
                  size={20}
                />{' '}
                Nome do Canal
              </Label>
              <CustomText
                type="text"
                name="text"
                id="channelName"
                placeholder="Ex. Ativo 1"
                value={channelName}
                onChange={(e) => setChannelName(e?.target?.value)}
                required
                maxLength={50}
                disabled={disableInputs}
              />
            </FormGroup>

            <FormGroup className="mt-5">
              <Label for="channelPhone">
                <Icon
                  icon="poli-icon pi-smartphone-fill"
                  color="var(--green-polichat)"
                  size={20}
                />{' '}
                Telefone com DDD e código de país
              </Label>
              <CustomPhoneInput
                country={'br'}
                autoFormat={true}
                value={channelPhone}
                onChange={(value) => setChannelPhone(value)}
                placeholder="+55 (62) 9999-9999"
                required
                disabled={disableInputs}
              />
            </FormGroup>
          </Col>
          <Col md="6" className="text-center">
            <img
              src={Illustration}
              alt="Reúna documentos para a homologação do WhatsApp Oficial"
            />
          </Col>
        </Row>

        <ActionGroupButtons
          onClickNext={handleNextAction}
          onClickBack={goBack}
        />
      </Container>
    );

  return (
    <>
      <Container>
        {!wlConfig.hide_doubts_button && <ButtonDoubts />}

        <HeadingTextContainer>
          <span>
            Para iniciarmos o processo de homologação com o Facebook, precisamos
            de alguns documentos que comprovem o nome legal, endereço,
            representantes e telefone.{' '}
            <strong>
              Estas são algumas sugestões. Colete o máximo de documentação que
              conseguir:
            </strong>
          </span>
        </HeadingTextContainer>

        <GridInformationContainer>
          <DocumentList>
            <Item>
              <Icon icon="poli-icon pi-finish-fill" color="#20EFE5" size={24} />
              <span>Cartão CNPJ</span>
            </Item>

            <Item>
              <Icon icon="poli-icon pi-finish-fill" color="#20EFE5" size={24} />
              <span>Contrato Social</span>
            </Item>

            <Item>
              <Icon icon="poli-icon pi-finish-fill" color="#20EFE5" size={24} />
              <span>Alvará</span>
            </Item>

            <Item>
              <Icon icon="poli-icon pi-finish-fill" color="#20EFE5" size={24} />
              <span>
                Licença da empresa e autorizações do governo local, estadual
                e/ou federal
              </span>
            </Item>

            <Item>
              <Icon icon="poli-icon pi-finish-fill" color="#20EFE5" size={24} />
              <span>
                Relatório de crédito da empresa de uma das agências credenciadas
              </span>
            </Item>

            <Item>
              <Icon icon="poli-icon pi-finish-fill" color="#20EFE5" size={24} />
              <span>Imposto Comercial</span>
            </Item>

            <Item>
              <Icon icon="poli-icon pi-finish-fill" color="#20EFE5" size={24} />
              <span>Conta de telefonia com o número oficial da empresa</span>
            </Item>

            <Item>
              <Icon icon="poli-icon pi-finish-fill" color="#20EFE5" size={24} />
              <span>Conta de água ou energia</span>
            </Item>
          </DocumentList>

          <ExtraInformation>
            <Illustration imgName="documentation-step-channel" />
            <span>
              Uma informação muito importante é que para eles a coerência dos
              dados é fundamental. Os endereços tem que ser os mesmos, assim
              como os nomes lá inclusos.
              <br />
              <br />
              Caso não sejam, é preciso ter algo que relacione-os. Por exemplo,
              se o número estiver em um CPF, é preciso estar no CPF do
              representante legal, e aí enviar um documento comprovante este
              vínculo
            </span>
          </ExtraInformation>
        </GridInformationContainer>

        <InputGroupContainer>
          <NameContainer>
            <FormGroup>
              <Label for="channelName">
                <Icon
                  icon="poli-icon pi-channels-fill"
                  color="var(--green-polichat)"
                  size={20}
                />{' '}
                Nome do Canal
              </Label>
              <CustomText
                type="text"
                name="text"
                id="channelName"
                placeholder="Ex. Ativo 1"
                value={channelName}
                onChange={(e) => setChannelName(e?.target?.value)}
                required
                maxLength={50}
              />
            </FormGroup>
          </NameContainer>

          <PhoneContainer>
            <FormGroup>
              <Label for="channelPhone">
                <Icon
                  icon="poli-icon pi-smartphone-fill"
                  color="var(--green-polichat)"
                  size={20}
                />{' '}
                Telefone com DDD e código de país
              </Label>
              <CustomPhoneInput
                country={'br'}
                autoFormat={true}
                value={channelPhone}
                onChange={(value) => setChannelPhone(value)}
                placeholder="+55 (62) 9999-9999"
                disabled={disableInputs}
              />
            </FormGroup>
          </PhoneContainer>
        </InputGroupContainer>

        <ActionGroupButtons
          onClickNext={handleNextAction}
          onClickBack={goBack}
        />
      </Container>
    </>
  );
};

export default GatherDocuments;
