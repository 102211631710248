import { laravelAxios } from '../../../../config/axios/laravelAxios'; 

async function scheduleMessage(data) {

    const response = await laravelAxios.post('schedule-message', data);
    return response; 

}

export default scheduleMessage;
