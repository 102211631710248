import styled from 'styled-components';

export const Container = styled.div`
  display: inline-block;
  cursor: pointer;
  margin-left: auto;

  div {
    display: inline-flex;
    align-items: center;
    color: var(--primary);
    gap: 0.1rem;
  }
`;
