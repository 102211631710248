import { CustomInput, Label } from '@polichat/flamboyant';
import { useChannelsService } from './useChannelsService';

export interface SelectChannelProps {
  customer_id?: number;
  value: string;
  onChange?: React.ChangeEventHandler<HTMLSelectElement>;
}
export function SelectChannel(props: SelectChannelProps) {
  const { customer_id } = props;

  const service = useChannelsService({
    customer_id,
  });

  // @ts-ignore
  const selectItem = service.channels?.find((item) => item?.id === props.value);

  // ---------------------------------------------
  // Transformations
  // ---------------------------------------------
  // Render

  return (
    <>
      <Label for="select_channel_id">Canal para migração</Label>
      <CustomInput
        type="select"
        id="select_channel_id"
        value={selectItem}
        disabled={service?.loading}
        onChange={props.onChange}
      >
        {!service.loading && !service.error && (
          <option value={''}>Selecione um canal</option>
        )}

        {service.loading && (
          <option value={''} key={'loading'} style={{ fontStyle: 'italic' }}>
            Carregando...
          </option>
        )}

        {service.error && (
          <option value={''} key={'error'} style={{ fontStyle: 'italic' }}>
            Não foi possivel carregar canais.
          </option>
        )}

        {!service.loading &&
          !service.error &&
          service?.channels?.map((item) => (
            // @ts-ignore
            <option key={item?.id} value={item?.id}>
              {/* @ts-ignore */}
              {item?.name}
            </option>
          ))}
      </CustomInput>
    </>
  );
}
