import { useState } from 'react';
import { Icon } from '@polichat/flamboyant';
import VariableModal from './VariableModal.component';

import { Container } from './AddVariable.styles';

import VariablesList from './variablesList'

function AddVariable({ onUpdate , isEdit}) {
  const [isNewVariableModalOpen, setIsNewVariableModalOpen] = useState(false);

  function handleOpenNewVariableModal(){
    setIsNewVariableModalOpen(true);
  }

  function handleCloseNewVariableModal(){
    setIsNewVariableModalOpen(false);
  }

  function handleSelectVariable(selectData){
    onUpdate({variable: selectData.label});
  }

  return (
    <Container>
      <div onClick={handleOpenNewVariableModal}>
        <Icon icon="poli-icon pi-add-circle-line" size={20} color={"var(--primary)"}/>
        <span>Variável</span>
      </div>
      <VariableModal
        isOpen={isNewVariableModalOpen}
        onCloseRequest={handleCloseNewVariableModal}
        variables={VariablesList}
        onUpdate={handleSelectVariable}
      />
    </Container>
  );
}

export default AddVariable;
