import styled from 'styled-components';

export const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  height: 100%;
  padding: 1rem;

  flex-direction: column;
  align-items: stretch;

  @media (max-width: 600px) {
    margin: 0;
    overflow: auto;
  }
`;

export const Filter = styled.div`
  display: grid;
  grid-template-columns: 3fr 3fr 3fr;
  grid-template-rows: 1fr;
  gap: 3rem;

  & .input-group-search .form-group {
    width: 90%;
  }

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
    gap: 0;

    & .input-group-search .form-group {
      width: 85%;
    }
  }
`;

export const ChannelsContainer = styled.div`
  display: grid;
  grid-template-columns: 6fr 6fr;
  column-gap: 3rem;
  row-gap: 2rem;
  //margin-top: 0.5rem;
  overflow: auto;

  @media (max-width: 600px) {
    margin: 1rem 0;
    grid-template-columns: 1fr;
    row-gap: 1rem;
  }
`;

export const ChannelCard = styled.div`
  border: 1px solid var(--border-color);
  border-radius: 5px;
  background: var(--primary-background-color);
  /* box-shadow: 0px 2px 10px rgba(255, 255, 255, 0.15); */
  padding: 5px;
  display: grid;
  grid-template-columns: 4fr 8fr;
  /* column-gap: 3rem; */

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
    gap: 0;
  }

  .poli {
    position: absolute;
    bottom: 5px;
    left: 5px;
    color: gray;
    opacity: 0.05;
  }

  .poli:hover {
    opacity: 1;
  }
`;

export const IconContainer = styled.div`
  margin: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (max-width: 600px) {
    display: none;
  }
`;

export const ChannelInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  border-left: 1px solid var(--secondary-background-color);

  @media (max-width: 600px) {
    border-left: none;
  }
`;

export const ChannelStatus = styled.div`
  align-self: flex-start;
  display: flex;
  align-items: center;
  color: #00c28b;
  font-size: 1.2rem;
  font-weight: bold;
  margin-left: 0.85rem;

  & span {
    font-size: 1rem;
    margin-left: 0.3rem;
  }

  @media (max-width: 600px) {
    font-size: 2rem;
    margin-left: 0;

    & span {
      font-size: 1rem;
    }
  }
`;

export const ChannelDetails = styled.div`
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 0.3rem;
`;

export const ChannelType = styled.div`
  display: flex;
  align-items: center;

  & span {
    font-size: 1.3rem;
    font-weight: bold;
    color: var(--primary-font-color);
    margin-left: 0.4rem;
  }
`;

export const ChannelName = styled.div`
  font-size: 1rem;
  color: var(--secondary-font-color);
  font-weight: bold;
  margin-top: 0.3rem;
`;

export const ChannelUuid = styled.div`
  font-size: 0.85rem;
  color: var(--secondary-font-color);
`;

export const ActionGroup = styled.div`
  align-self: flex-end;
  display: flex;
  width: 100%;
  margin: 0.85rem auto 0 auto;

  & button:first-child {
    margin: 0 0.5rem;
  }
`;

export const ChannelNotFound = styled.div`
  margin: 5rem 0;
  display: flex;
  align-items: center;
  justify-content: center;

  & span {
    font-size: 1.5rem;
    text-align: center;
  }
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;

  & * {
    margin: auto;
  }

  @media (max-width: 600px) {
    margin-top: 0;
  }
`;
