import { Table as TableFlamboyant } from '@polichat/flamboyant';
import { ORDERS_TABLE_TOOLTIP, TableProps } from './config';
import { ContainerTale, DivStatusExport } from '../../../shared/components/Table/styles';
import { ButtonDownloadAndReloadFile } from '../ButtonDownloadAndReloadFile';
import { userRoleValidator } from 'src/pages/report/shared/utils/userRoleValidator';
import { useSelector } from 'react-redux';
import { ExportStatusIds, ReportEntity } from 'src/pages/report/shared/components/Export';

export function Table({ records, loading = false }: TableProps) {

  const statusBackground: { [key in number]: string } = {
    [ExportStatusIds.PENDING]: 'var(--warning, #FFCF5C)',
    [ExportStatusIds.COMPLETED]: 'var(--success, #00C48C)',
    [ExportStatusIds.FAILED]: 'var(--red-polichat, #FF647C)',
    [ExportStatusIds.EXPIRED]: 'transparent'
  }

  const getUserRole = useSelector((state: any) => state.chat?.config?.user?.superRole)
  const isManager = userRoleValidator(getUserRole)

  function returnExpired(timestamp?: string): string {
    if (!timestamp) return ''
    
    const data = new Date(timestamp)

    data.setDate(data.getDate() + 30)
    
    return formatDate(data.toString())
  }

  function returnStatus(record: ReportEntity): number {
    const reportDate = new Date(record.updated_at) as any;
    
    const currentDate = new Date() as any;

    const diff = (currentDate - reportDate)/(1000 * 60 * 60 * 24)
    
    if(diff > 30) {
      return ExportStatusIds.EXPIRED
    }
    
    return record.status
  }


  function formatDate(timestamp?: string): string {
    if (!timestamp) return ''
  
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
  
    return `${day}/${month}/${year}`;
  }

  function formatDateAndHours(timestamp?: string): string {
    if (!timestamp) return ''
  
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const time = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
  
    return `${day}/${month}/${year} às ${time}`;
  }

  return (
    <ContainerTale>
      <TableFlamboyant striped responsive hover>
        <thead>
          <tr>
            {ORDERS_TABLE_TOOLTIP.map((title) => (
              <th key={title.id}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', textAlign: 'center' }}>
                  {title.title}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {records?.map((record: ReportEntity) => (
              <tr key={record?.id}>
                <td className='text-center'>{record.id ? record.id : '-'}</td>
                <td className='text-center'>{record.report_name ? record.report_name : '-'}</td>
                <td className='text-center'>{record.user_name ? record.user_name : '-'}</td>
                <td className='text-center'>{record.created_at ? formatDateAndHours(record.created_at) : '-'}</td>
                <td className='text-center'>{record.updated_at ? returnExpired(record.updated_at) : '-'}</td>
                <td className='text-center'>{record.size ? `${record.size} kb` : '-'}</td>
                <td>
                    <DivStatusExport style={{
                      background: statusBackground[returnStatus(record)]
                    }}>
                      {ExportStatusIds[returnStatus(record)]}
                    </DivStatusExport>
                  </td>
                  <td className='text-center'>
                    <ButtonDownloadAndReloadFile
                      reports={record}
                      loading={loading}
                      disable={!isManager}
                    />
                  </td>
              </tr>
            ))}
        </tbody>
      </TableFlamboyant>
    </ContainerTale>
  )
}
