import styled, { keyframes } from 'styled-components';

// padrão usado em telas anteriores
const fadeIn = keyframes`
  from { opacity : 0 }
  to { opacity: 1 }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  max-width: 80%;
  height: 100%;
  margin: auto;
  padding: 1rem 0;

  & img {
    animation: ${fadeIn} 2s;

    width: 100%;
    height: auto;
    max-width: 300px;
  }

  & h2 {
    font-weight: bold;
    font-size: 1.5rem;
  }

  & p {
    text-align: center;
    font-size: 1rem;

    max-width: 80%;
    margin-bottom: 0;
  }

  /* Tablets */
  @media (max-width: 768px) {
    max-width: 90%;
    gap: 0.5rem;

    & p {
      max-width: 90%;
    }
  }

  /* Mobile */
  @media (max-width: 600px) {
    max-width: 100%;
    padding: 1rem 0;

    justify-content: flex-start;
    gap: 0;

    overflow-y: auto;
    overflow-x: hidden;

    & img {
      max-width: 200px;
      max-height: 200px;
    }

    & p {
      max-width: 100%;
      margin-bottom: 0.5rem;

      font-size: 0.8rem;
    }

    & p:first-of-type {
      font-size: 1rem;
      margin-bottom: 0;
    }
  }
`;
