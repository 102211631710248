import { useState, useRef, useEffect } from 'react';
import {
  Row,
  Col,
  FormGroup,
  Label,
  CustomInput,
  CustomTextArea,
  Input,
} from '@polichat/flamboyant';

import MediaUpload from './MediaUpload/MediaUpload.component';
import AddVariable from '../../common/variable/AddVariable.component';
import {
  LabelWithSpan,
  OptionsContainer,
} from '../../FormMessagesBuilder.styles';

const headerTypes = [
  { id: 1, type: 'text', description: 'Texto', isActive: true },
  { id: 2, type: 'media', description: 'Mídia', isActive: true },
];

function CreateHeaderWaba({ onUpdate, initialValue, isEdit }) {
  const [headerData, setHeaderData] = useState(initialValue);
  const headerInputRef = useRef(null);

  const handleInputsChange = ({ target }) => {
    if (Array.isArray(target)) {
      let newState = {};
      for (let item of target) {
        newState[item.name] = item.value;
      }
      setHeaderData({
        ...headerData,
        ...newState,
      });
      handleHeaderData({
        ...headerData,
        ...newState,
      });
    } else {
      let { name, value } = target;
      setHeaderData({
        ...headerData,
        [name]: value,
      });
      handleHeaderData({
        ...headerData,
        [name]: value,
      });
    }
  };

  const handleAddVariable = (data) => {
    const { variable } = data;
    const newText = headerData.text + variable;

    setHeaderData({
      ...headerData,
      text: newText,
    });
    handleHeaderData({
      ...headerData,
      text: newText,
    });
  };

  // This will force the character counter to update
  useEffect(() => {
    const e = new Event('input', { bubbles: 'true' });
    if (headerInputRef.current) headerInputRef.current.dispatchEvent(e);
  }, [headerData.text]);

  const handleHeaderData = ({
    type,
    text,
    mediaUrl,
    mediaFormat,
    fileData,
  }) => {
    if (type === 'none') {
      text = '';
      setHeaderData({ type: '', text: '', mediaUrl, mediaFormat, fileData });
      //fazer a validação tipo e campos ao submeter template,
      // e delete do arquivo caso o tipo não seja media
    }
    if (type === 'text') {
      mediaFormat = 'TEXT';
      setHeaderData({ type, text, mediaUrl, mediaFormat, fileData });
    }
    onUpdate({ type, text, mediaUrl, mediaFormat, fileData });
  };

  return (
    <>
      <Row>
        <Col sm={12} md={6}>
          <FormGroup>
            <LabelWithSpan>
              <Label>Titulo:</Label>
              <span>
                Adicione um titulo ou escolha o tipo de midia que você usará
                para este cabeçalho
              </span>
            </LabelWithSpan>
            <CustomInput
              id="header-type"
              type="select"
              name="type"
              value={headerData.type}
              onChange={handleInputsChange}
              disabled={isEdit}
            >
              <option value="none">Nenhum</option>
              {headerTypes.map((headertype) => (
                <option
                  key={headertype.id}
                  value={headertype.type}
                  disabled={!headertype.isActive}
                >
                  {headertype.description}
                </option>
              ))}
            </CustomInput>
          </FormGroup>
        </Col>
      </Row>
      {/* Header type text */}
      {headerData.type === 'text' && (
        <Row>
          <Col sm={12}>
            <Input
              id="header-title"
              type="text"
              name="text"
              placeholder="Cabeçalho da mensagem"
              value={headerData.text}
              onChange={handleInputsChange}
              showCharCounter
              maxLength={60}
              disabled={isEdit}
              innerRef={headerInputRef}
              required
            />
            <OptionsContainer>
              <AddVariable onUpdate={handleAddVariable} isEdit={isEdit} />
            </OptionsContainer>
          </Col>
        </Row>
      )}
      {/* Header type media */}
      {headerData.type === 'media' && (
        <Row>
          <Col sm={12}>
            <FormGroup>
              <MediaUpload
                initialValue={headerData}
                onUpdate={handleInputsChange}
                isEdit={isEdit}
              />
            </FormGroup>
          </Col>
        </Row>
      )}
    </>
  );
}

export default CreateHeaderWaba;
