import axios from 'axios';
import ObjectToQueryString from '../../../lib/ObjectToQueryString';
import poliConfig from 'src/utils/poliConfig';

const defaultHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

const defaultConfig = {
  headers: defaultHeaders,
  withCredentials: true,
};

const fetchGateways = () => {
  return axios.get(
    poliConfig.poliAppLaravelUrl + `/shopping/gateway`,
    defaultConfig
  );
};

const storeStatus = (id, params = null) => {
  return axios.post(
    poliConfig.poliAppLaravelUrl +
      `/shopping/gateway/${id}/status` +
      (params ? '?' + ObjectToQueryString(params) : ''),
    {},
    defaultConfig
  );
};

const storeDefault = (id, params = null) => {
  return axios.post(
    poliConfig.poliAppLaravelUrl +
      `/shopping/gateway/${id}/default` +
      (params ? '?' + ObjectToQueryString(params) : ''),
    {},
    defaultConfig
  );
};

export default { fetchGateways, storeStatus, storeDefault };
