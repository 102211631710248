import { laravelAxios } from '../../../../config/axios/laravelAxios';

import { GetContactParams } from './types';

export class ContactsService {
  static async getContactInfo(params: GetContactParams) {
    const url = `/contacts/${params.customer_id}/uid/${params.contact_number}`;
    const request = await laravelAxios.get(url);
    return request.data;
  }
}
