import { CustomerContactCreditContext } from './context';
import { useCustomerContactCredit } from './useCustomerContactCredit';

export interface CustomerContactCreditProvider {
  children?: any;
}

export function CustomerContactCreditProvider(
  props: CustomerContactCreditProvider
) {
  const hook = useCustomerContactCredit();

  // ---------------------------------------------
  // Transformations
  // ---------------------------------------------
  // Render

  return (
    <CustomerContactCreditContext.Provider value={hook}>
      {props.children}
    </CustomerContactCreditContext.Provider>
  );
}
