import styled from 'styled-components';

export const Container = styled.div`
  padding: 0.3rem 0.4rem;
  background: var(--secondary-background-color);

  ul {
    margin-bottom: 0; //remove margin-bottom de 1rem do @flamboyant-mim-css
    max-height: 20em; //limite tamanho
    overflow: auto;
  }

  li {
    margin: 0 0.5rem;
    padding: 0.2rem 0.3rem;
    border-bottom: 1px solid var(--border-color);

    display: grid;
    grid-template-columns: 60% 40%;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: space-around;

    background: var(--dateChat-background);
    border: 0.5px solid;
    border-radius: 5px;
    height: 2rem;
    width: 100%;

    font-size: x-small;
    padding: 5px;
  }
`;

export const BoxDescription = styled.div`
  display: flex;
  flex-direction: column;

  span:first-child {
    color: var(--primary-font-color);
    font-size: 1rem;
    max-width: 15ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  div {
    display: inline-flex;
    align-items: center;
    color: var(--secondary-font-color);
    font-size: 0.6rem;
    span {
      padding-left: 0.1rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`;
