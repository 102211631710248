import poliApiGatewayRequest from 'src/utils/poliApiGatewayRequest';

import {
  DtoDialog360Channel,
  DtoDialog360GenerateApiKeyInfo,
} from './DtoDialog360Channel';
import { channel } from 'redux-saga';

export class Channel360DialogCustomerService {
  static async create(
    dto: Omit<
      DtoDialog360Channel,
      'id' | 'config' | 'status' | 'display_on_webchat' | 'users'
    >,
    customer_id: number
  ) {
    const url = `/channel-customer/?customer_id=${customer_id}`;
    const request = await poliApiGatewayRequest.post(url, dto);
    return request;
  }
  static async edit(
    dto: Pick<DtoDialog360Channel, 'display_on_webchat' | 'status' | 'users'>,

    customer_id: number,
    channel_id: number
  ) {
    const url = `/channel-customer/${channel_id}?customer_id=${customer_id}`;
    const request = await poliApiGatewayRequest.put(url, dto);
    return request;
  }

  static async delete(
    channel_id: number,

    customer_id: number
  ) {
    const url = `/channel-customer/${channel_id}/?customer_id=${customer_id}`;
    const request = await poliApiGatewayRequest.delete(url);
    return request;
  }
  static async get(channel_id: number, customer_id: number) {
    const url = `/channel-customer/${channel_id}?customer_id=${customer_id}`;
    const request = await poliApiGatewayRequest.get<DtoDialog360Channel>(url);
    return request;
  }
  static async createApiKey(
    dto: DtoDialog360GenerateApiKeyInfo,
    customer_id: number
  ) {
    const url = `/channel-customer/${customer_id}/create_apikey?customer_id=${customer_id}`;
    const request = await poliApiGatewayRequest.post(url, dto);
    return request;
  }

  static async getUsers(customer_id: number) {
    const url = `/channel-customer/users?customer_id=${customer_id}`;
    const request = await poliApiGatewayRequest.get(url);
    return request;
  }
}
