import { DropdownItem } from '@polichat/flamboyant';
import { QuickMessage } from '../../types/types';

import styles from './QuickItem.module.css';
export interface QuickControlRemoveProps {
  handleRemove: (message: QuickMessage) => void;
}
export function QuickControlRemove({
  handleRemove,
}: Readonly<QuickControlRemoveProps>) {
  return (
    <DropdownItem onClick={handleRemove}>
      <span className={styles.control_remove}>Excluir</span>
    </DropdownItem>
  );
}
