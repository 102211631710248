import React from 'react';
import Body from './body';
import { MenuSide } from '@polichat/flamboyant';
export default (props) => {
  return (
    <div className="bodyContentContainer">
      {/* {<MenuSide
        iconOne="poli-icon pi-whatsapp-fill"
        textOne="  Suporte"
        textTooOne="Entre em contato com o suporte do Polichat"
        onClickOne={() => {
          window.open('https://web.whatsapp.com/send?phone=556282361759');
        }}
        iconTwo="poli-icon pi-talks-fill"
        textTwo="  Feedback"
        textTooTwo="Envie seu feedback sobre nossa plataforma"
        onClickTwo={() => {
          window.open('https://forms.gle/BuWnxve6UsiZUz2QA');
        }}
      /> } */}
      <Body />
    </div>
  );
};
