import { useState, useCallback } from 'react';
import { set } from 'react-hook-form';
import ChannelsService from 'src/services/tools/channels/channels';

interface dtoErrorMessage {
  message: string;
}
export interface dtoRecreateTemplate {
  migratedQuantity: string;
  areAlreadyTemplatesMigrated: boolean;
  message: string;
}
interface useRecreateTemplateServiceProps {
  channelId: string;
  custommerId: string;
}
export function useRecreateTemplateService(
  props: useRecreateTemplateServiceProps
) {
  // ---------------------------------------------
  // States
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<dtoRecreateTemplate | null>();
  const [error, setError] = useState<dtoErrorMessage | null>();
  // ---------------------------------------------
  // Functions
  const onRecreateTemplate = useCallback(async () => {
    try {
      setLoading(true);
      const res: any = await ChannelsService.RecreateTemplate(
        props.channelId,
        props.custommerId
      );
      const data: dtoRecreateTemplate = res?.data ?? null;
      setData(data);
    } catch (error: any) {
      if (error.response.data.message) {
        setError({ message: error.response.data.message });
      } else {
        setError({
          message:
            'Erro ao realizar procedimento. Caso o erro persista, entre em contato com o suporte para obter ajuda',
        });
      }
    } finally {
      setLoading(false);
    }
  }, []);
  // ---------------------------------------------
  // Effects
  // ---------------------------------------------
  // Transformations
  // ---------------------------------------------
  // API

  return {
    loading,
    error,
    data,
    onRecreateTemplate,
  };
}
