import { Alert, Icon, Row } from '@polichat/flamboyant';
import { ContainerStyle } from "../shared/styles";
import { ReportsFiltersInterface } from "../shared/types/ReportsFiltersTypes";
import { useEffect, useState } from 'react';
import { EmptyData } from '../shared/components/EmptyData';
import { Table } from './components/Table';
import { SubMenuWelcome } from './components/SubMenuWelcome';
import exportsAPI from "src/services/tools/reports/export-reports";
import { Loading } from '../shared/components/Loading';
import TablePagination from 'src/components/shopping/TablePagination';
import { PaginationProps } from '../shared/types/Pagination';
import { DEFAULT_PAGINATION_CONFIG } from '../shared/components/Pagination/config';
import { ReportEntity } from '../shared/components/Export';
import { useFlags } from 'flagsmith/react';

export function ExportReport({ error, onError: setError }: ReportsFiltersInterface) {

  const [loading, setLoading] = useState(false);
  const [exportsToList, setExportsToList] = useState<ReportEntity[] | undefined>(undefined);
  const [pagination, setPagination] = useState<PaginationProps | undefined>(DEFAULT_PAGINATION_CONFIG)
  const flags = useFlags(['simulate_exports_error']);

  async function simulateExportsError() {
    setError(false);
    setLoading(true);

    setTimeout(function() {
        setError(true);
        setLoading(false);
    }, 3000)
  }

  
  async function  fetchExports(page?: PaginationProps): Promise<void>
  {
    setError(false);
    setLoading(true);

    try {
      const {
        data,
        current_page,
        records_per_page,
        total_pages,
        total_records,
      } = await exportsAPI.allListReports(page);

      setExportsToList(data);
      setPagination({ current_page, total_pages, total_records, records_per_page });
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    flags?.simulate_exports_error?.enabled ? simulateExportsError() : fetchExports()
  }, [])


  return (
    <ContainerStyle>
      {exportsToList === undefined && loading && (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', maxHeight: '100'}}>
          <SubMenuWelcome />
          <Loading />
        </div>
      )}

      {exportsToList !== undefined && exportsToList?.length == 0  && !loading && <EmptyData />}

      { exportsToList === undefined && error 
        && 
        <>
          <SubMenuWelcome />
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Alert color='danger' style={{ display: 'inline' }}>Houve um erro ao consultar as informações. Recarregue a página e tente novamente. Caso o problema persista contate o administrador do sistema.</Alert>
          </div>
        </>
      }

      {
        exportsToList !== undefined && exportsToList?.length > 0 
        && <>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start'}}>
              <Row className="rowTitle" style={{ margin: 0, marginTop: '1rem', boxShadow: 'none' }}>
                <Icon
                  icon={`poli-icon pi-download-line`}
                  size={25}
                  color="var(--principal-icons-color)"
                  position={'relative'}
                />
                <span>Exportações de relatórios</span>
              </Row>
                <span style={{ marginTop: '1.5rem', marginBottom: '2.5rem' }}>Exporte relatórios e consulte o status das suas exportações.</span>
            </div>
            { error 
              && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '2rem'  }}>
                <Alert color='danger' style={{ display: 'inline' }}>Houve um erro ao consultar as informações. Recarregue a página e tente novamente. Caso o problema persista contate o administrador do sistema.</Alert>
              </div>
            }
            <>
              {!loading ?
                <Table
                  records={ exportsToList }
                /> : <Loading />}
              <div>
              {pagination && (
                <TablePagination
                  totalItens={ pagination.total_records }
                  onPageChange={flags?.simulate_exports_error?.enabled ? simulateExportsError : fetchExports}
                />
              )}
            </div>
            </>
          </>
      }      
    </ContainerStyle >
  )
}