import { EnumMetricType } from '../../../../../services/meta-ads/dto/dtoMetaAdsMetricData';
import { PaginationInfo, SearchParams, ValidationError } from './types';

export const paginationInfoInitialValues: PaginationInfo = {
  page: 1,
  per_page: 15,
  total_pages: 100,
};

export const initialSearchParams: SearchParams = {
  type: '',
  paginationInfo: paginationInfoInitialValues,
};

export const initialError: ValidationError = {
  hasError: false,
  message: '',
};
