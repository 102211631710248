import { Icon, Alert } from '@polichat/flamboyant';
import { useCustomerContactDayAlert } from './useCustomerContactDayAlert';
import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';

export interface CustomerContactDayAlertProps {
  channelId: number;
}

export function CustomerContactDayAlert(
  props: CustomerContactDayAlertProps
): JSX.Element | null {
  const {
    isOpenAlert,
    closeAlert,
    percentage,
    isUserAbleToSendMessage,
  } = useCustomerContactDayAlert({ channelId: props.channelId });
  const isWhiteLabel = useSelector(
    (state: any) => state?.whitelabel?.config?.is_white_label
  );
  const poliWhatsappNumber = process.env.REACT_APP_POLI_WHATSAPP_NUMBER;
  const linkPoliWhats = `https://wa.me/${poliWhatsappNumber}`;

  const wL80percent = percentage >= 80 && isWhiteLabel;
  const wL100percent = percentage >= 100 && isWhiteLabel;

  const getConsumedMessage = () => {
    if (percentage >= 100 && !isWhiteLabel) {
      return `100% dos seus créditos foram consumidos, sua empresa consumirá créditos avulsos. `;
    }
    if (wL80percent) {
      return `Seu consumo já atingiu ${percentage}% dos créditos contratados no período. `;
    }
    return `Sua empresa atingiu 80% dos créditos contratados. `;
  };

  const getColor = () => {
    if (wL100percent) {
      return 'danger';
    }
    if (wL80percent) {
      return 'warning';
    }
    return null;
  };

  const styles = {
    container: {
      padding: '0.5rem 1rem',
      marginBottom: '0',
      borderRadius: '0',
      display: 'flex',
      flexDirection: isWhiteLabel ? 'column' : 'row',
      alignItems: isWhiteLabel ? 'flex-start' : 'center',
      fontSize: '0.8rem',
      zIndex: 2,
      ...(isWhiteLabel
        ? {
          backgroundColor: '#fff8f4'
          }
        : {
            display: 'flex',
            gap: '8px',
            backgroundColor: '#FAE0AA'
          }),
    },

    closeIcon: {
      // fontSize: '1.3rem',
      position: 'absolute',
      top: ' 0.5rem',
      right: '0.5rem',
      cursor: 'pointer',
    },

    title: {
      color: isWhiteLabel ? 'inherit' : '#232C3D',
      display: 'flex',
      alignItems: 'flex-end',
    },

    text: {
      color: isWhiteLabel ? 'inherit' : '#232C3D',
      margin: '0',
    },
  };
  // ---------------------------------------------
  // Render
  if (isOpenAlert) {
    return (
      <>
        {wL100percent && (
          <Alert style={styles.container} color={getColor()}>
            <h3 style={styles.title}>
              <Icon color="danger" size={24} icon="poli-icon pi-alert-fill" />
              <b>Atenção!</b>
            </h3>
            <p style={styles.text}>
              {isUserAbleToSendMessage && (
                <>
                  {' '}
                  <span>Está conversa já é de crédito!</span>
                  <br />{' '}
                </>
              )}
              Seu acesso atingiu o limite de créditos contratados no período.
              Seus créditos serão restabelecidos a partir do dia 1º dia do
              próximo mês. Caso precise antecipar o desbloqueio, efetue a compra
              de novos créditos e evite o bloqueio do canal. Entre em contato
              com nosso comercial.
            </p>
          </Alert>
        )}
        {!wL100percent && (
          <Alert style={styles.container} color={getColor()}>
            {/* @ts-ignore */}
            <div style={styles.closeIcon} onClick={closeAlert}>
              <Icon
                style={styles.closeIcon}
                icon="poli-icon pi-close-line"
                color="#ffa26b"
                size={20}
              />
            </div>
            {(!isMobile && !isWhiteLabel) &&
              <h3 style={styles.title}>
                <Icon color="#FFCA5D" size={24} icon="poli-icon pi-alert-line" />
              </h3>
            }
            <div>
              <h3 style={styles.title}>Créditos</h3>
              <p style={styles.text}>
                {getConsumedMessage()}
                {!isWhiteLabel && (
                  <>
                    <a href={linkPoliWhats} target="_blank">
                      Fale com nossa equipe
                    </a>{' '}
                    e evite pagar um valor maior.
                  </>
                )}
                {wL80percent && (
                  <>
                    <br />
                    Efetue a compra de novos créditos e evite o bloqueio do canal.
                    Entre em contato com nosso comercial.
                  </>
                )}
              </p>
            </div>
          </Alert>
        )}
      </>
    )
  }
  return null;
  }
