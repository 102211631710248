import styled, { css } from 'styled-components';

export const TextNewPassword = styled.div`
  color: #606161;
  margin-top: -20px;
  margin-bottom: 20px;
`;

export const TextValidNewPassword = styled.div`
  font-family: Helvetica;
  font-size: 14px;
  line-height: 24px;

  p {
    margin: 0;
  }
  .valid {
    color: var(--success);
  }
  .error {
    color: var(--danger);
  }
`;

export const IconPassword = styled.div`
  display: flex;
  align-items: center;
  padding-top: 1rem;
  div {
    color: var(--page-title);
    font-size: 0.8rem;
    line-height: 1rem;
    text-align: right;
  }
  span {
    margin-bottom: -0.4rem;
    font-weight: bold;
    flex-flow: row wrap;
    color: #606161;
    font-size: 1rem;
    line-height: 1.25rem;
    padding-left: 0.5rem;
  }
`;

export const ButtonNewPassword = styled.div`
  button {
    background: linear-gradient(225deg, #20efe5 3.26%, #0184bb 99.54%);
    border-radius: 5px;
    margin-top: 25px;
    margin-bottom: -40px;
  }

  .buttonBack {
    background: #ffffff;
    border-radius: 5px;
    margin-right: 10%;
  }

  .disabled {
    background: #8b8989;
    border-color: #8b8989;
  }
`;

export const ContainerSuccess = styled.div`
  img {
    display: flex;
    text-align: center;
    margin: auto;
  }
  button {
    background: linear-gradient(225deg, #20efe5 3.26%, #0184bb 99.54%);
    border-radius: 5px;
    margin-top: 20px;
    margin-bottom: -20px;
  }
`;

export const ContainerError = styled.div`
  img {
    display: flex;
    text-align: center;
    margin: auto;
  }
  button {
    background: linear-gradient(
      225deg,
      rgba(255, 100, 124, 0.9) 3.44%,
      rgba(253, 175, 187, 0.9) 100%
    );
    border-radius: 5px;
    border: none;
    margin-top: 20px;
    margin-bottom: -20px;
  }
`;
