import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: stretch;
  align-content: stretch;
  gap: 0.5rem;

  width: 100%;
  height: 100%;
  padding: 0.4rem;

  @media (max-width: 768px) {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    flex-direction: column;
  }
`;

export const ContrastBackgroundContainer = styled.div`
  background-color: var(--secondary-background-color);
  border-radius: 6px !important;
  padding: 3% 5%;
  display: block;
  justify-content: space-between;
`;

export const FormContainer = styled.div`
  flex: 2;

  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  border-right: 1px solid var(--border-color);

  @media (max-width: 768px) {
    border: 0;
    overflow: unset;
    height: fit-content;
  }
`;

export const LabelWithSpan = styled.div`
  display: inline-flex;
  align-items: baseline;
  gap: 0.2rem;

  span {
    font-size: 0.8rem;
    font-style: italic;
  }
`;

export const OptionsContainer = styled.div`
  width: 100%;
  padding: 0 0.5rem;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
`;
