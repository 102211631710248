// Função pesquisar chats
export const applySearch = function (chats, search) {

  const isLetter = (str) => {
    return (/[a-zA-Z]/).test(str)
  }

  const searchAll = search.length
    ? search
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
    : search;

  const searchPhone = search?.replace(/([^\d])+/gim, '');

  const filter = () => {
    const filterName = chats.filter(
      (chat) =>
        //filtro por nome
        searchAll &&
        searchAll.length &&
        chat.contact &&
        chat.contact.name &&
        chat.contact.name.length &&
        chat.contact.name
          .toLowerCase()
          .toString()
          .trim()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .includes(searchAll.toString().trim())
    );

    const filterPhone = chats.filter(
      (chat) =>
        //filtro por telefone
        !isLetter(searchAll) &&
        searchPhone &&
        searchPhone.length &&
        chat.contact &&
        chat.contact.phone &&
        chat.contact.phone.length &&
        chat.contact.phone.includes(searchPhone)
    );

    const filterEmail = chats.filter(
      (chat) =>
        //filtro por email
        searchAll &&
        searchAll.length &&
        chat.contact &&
        chat.contact.email &&
        chat.contact.email.length &&
        chat.contact.email.toLowerCase().includes(searchAll)
    );

    // Juntando os resultados.
    const result = [...filterPhone, ...filterName, ...filterEmail];

    // evitando retonar elementos repetidos do array
    return result.filter((e, i) => result.indexOf(e) === i);
  };

  if (!searchAll.length) {
    return chats;
  }

  chats = filter();

  return chats;
};
