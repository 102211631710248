import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import {
  Link,
  Route,
  useHistory,
  Switch,
  useRouteMatch,
} from 'react-router-dom';
import {
  Button,
  Icon,
  CollapseMenu,
  Navbar,
  NavbarToggler,
  Collapse,
  Accordion,
} from '@polichat/flamboyant';
import poliConfig from '../../utils/poliConfig';

import {
  PageContentContainer,
  PageMainContainer,
  PageMenuContainer,
} from '../../components/common/pages/PageContainer/PageContainer.styles';
import Animation from '../../components/common/animation';
import PageMainBody from '../../components/common/pages/PageMainBody/PageMainBody.component';

import { AdsCreateFacebookPage } from './Facebook/adsCreateFacebookPage';
import { AdsReportFacebookPage } from './Facebook/adsReportFacebookPage';
import { WelcomeFacebookAdsPage } from './Facebook/adsWelcomeFacebookPage';

import { NotPermissionPage } from './components/common/notPermission';
import { useUserRole } from './hooks/useUserRole';
import { PoliAdsPage } from './Poli';
import { isMobile } from 'react-device-detect';

export function PageAds() {
  let { path, url } = useRouteMatch();
  let history = useHistory();
  const pathname = window?.location?.pathname;
  const { userPermission, isLoading } = useUserRole();

  const [collapsed, setCollapsed] = useState(true);
  const toggleNavbar = () => setCollapsed(!collapsed);

  /**
   * pegando id da empresa no state
   */
  const customer_id: number = useSelector(
    // @ts-ignore
    (state) => state?.general?.current_customer_id
  );
  /* end */

  const enableAds =
    poliConfig?.ads_enable ||
    poliConfig?.ads_enable_custumer?.includes(Number(customer_id));

  const enablePoliAds = true;

  useEffect(() => {
    if (customer_id && !enableAds) {
      history.push('/chat');
    }
  }, [customer_id]);

  /**
   * Menu ativo
   */
  const [menuActive, setMenuActive] = useState<string>();
  useEffect(() => {
    const _menuAtive = path;
    setMenuActive('');
    setMenuActive(_menuAtive);
  }, [pathname, path, url]);
  /* end - Menu ativo */

  const LoadingComponent = (
    <Animation
      icon="chat-loading"
      style={{
        maxWidth: '150px',
        height: '100%',
        maxHeight: '100px',
      }}
      top={'50%'}
    />
  );

  const styleBody = { padding: 0, backgroundColor: 'white' };

  return (
    <>
      <PageMainContainer>
        <PageMenuContainer>
          <Navbar color="faded" light expand="sm">
            <NavbarToggler
              color="primary"
              onClick={toggleNavbar}
              className="mr-2"
            >
              <Icon size={30} icon="poli-icon pi-menu-mobile-fill" />
            </NavbarToggler>

            <Collapse isOpen={!collapsed} navbar>
              <Accordion defaultActiveKey={menuActive} key={menuActive}>
                {/* FACEBOOK ADS PLUGIN */}
                <CollapseMenu
                  id="/ads"
                  principalTitle="Anúncios Facebook"
                  principalIcon="poli-icon pi-alerts-line"
                >
                  {/* Criar Anuncio */}
                  <Link
                    className="btn-primary-collapse-itens"
                    // onClick={isMobile && toggleNavbar}
                    to={{ pathname: '/ads/facebook/create' }}
                  >
                    <Button
                      color="primary-collapse-itens"
                      className={
                        pathname == `/ads/facebook/create` ? 'active' : ''
                      }
                    >
                      <Icon size={18} icon={'poli-icon pi-add-line'} />
                      Criar Anúncio
                    </Button>
                  </Link>

                  {/* Relatório */}
                  <Link
                    className="btn-primary-collapse-itens"
                    to={{ pathname: `${url}/facebook/report` }}
                    // onClick={isMobile && toggleNavbar}
                  >
                    <Button
                      color="primary-collapse-itens"
                      className={
                        pathname == `${url}/facebook/report` ? 'active' : ''
                      }
                    >
                      <Icon size={18} icon={'poli-icon pi-speedometer-line'} />
                      Relatório
                    </Button>
                  </Link>
                </CollapseMenu>
                {/* POLI ADS */}
                <CollapseMenu
                  id="poli_ads"
                  principalTitle="Poli Ads"
                  principalIcon="poli-icon pi-alerts-line"
                >
                  {/* Metricas */}
                  <Link
                    className="btn-primary-collapse-itens"
                    to={{ pathname: '/ads/poli/metrics' }}
                    // onClick={isMobile && toggleNavbar}
                  >
                    <Button
                      color="primary-collapse-itens"
                      className={
                        pathname == `/ads/poli/metrics` ? 'active' : ''
                      }
                    >
                      <Icon size={18} icon={'poli-icon pi-golden-line'} />
                      Métricas
                    </Button>
                  </Link>
                </CollapseMenu>
              </Accordion>
            </Collapse>
          </Navbar>
        </PageMenuContainer>

        {/* content */}
        <PageContentContainer>
          <Switch>
            <Route exact path="/ads">
              <WelcomeFacebookAdsPage />
            </Route>
            {/* FACEBOOK POP-UP */}
            <Route exact path="/ads/facebook/create">
              <PageMainBody style={styleBody}>
                {isLoading ? (
                  LoadingComponent
                ) : userPermission ? (
                  <AdsCreateFacebookPage />
                ) : (
                  <NotPermissionPage />
                )}
              </PageMainBody>
            </Route>

            <Route exact path="/ads/facebook/report">
              <PageMainBody style={styleBody}>
                {isLoading ? (
                  LoadingComponent
                ) : userPermission ? (
                  <AdsReportFacebookPage />
                ) : (
                  <NotPermissionPage />
                )}
              </PageMainBody>
            </Route>
            {enablePoliAds && (
              <>
                {/* POLI ADS */}
                <Route exact path="/ads/poli/metrics">
                  {isLoading ? (
                    LoadingComponent
                  ) : userPermission ? (
                    <PoliAdsPage customer_id={customer_id} />
                  ) : (
                    <PageMainBody>
                      <NotPermissionPage />
                    </PageMainBody>
                  )}
                </Route>
              </>
            )}
          </Switch>
        </PageContentContainer>
      </PageMainContainer>
    </>
  );
}
