import React from 'react';
import PageMainHeader from '../../common/pages/PageMainHeader/PageMainHeader.component';
import PageMainBody from '../../common/pages/PageMainBody/PageMainBody.component';
import { bindActionCreators } from 'redux';
import * as GeneralActions from '../../../store/modules/general/actions';
import { connect } from 'react-redux';
import imageNewPoli from '../../../assets/images/newIdentity/hire-channels.svg';

function WelcomeService() {
  return (
    <>
      <PageMainHeader
        title="Serviços"
        icon="poli-icon pi-list-customers-line"
      />
      <PageMainBody>
        <div className='shoppingScreenImage AnimationImage01'>
          <img className='img-fluid imagePay' src={imageNewPoli} alt='Caixa de planos' />
          <h2 style={{ marginTop: '45px', lineHeight: '35px' }}>
            Explore seus serviços
          </h2>
        </div>
      </PageMainBody>
    </>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(GeneralActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(WelcomeService);
