import styled from 'styled-components';
import { Button } from '@polichat/flamboyant';

export const ProfileWabaPhotoMain = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin: 1rem 0;
`;

export const ProfilePictureContainer = styled.div`
  width: 18rem;
  height: 18rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--secondary-background-color);
  border-radius: 50%;
`;

export const PhotoImage = styled.img`
  width: 18rem;
  height: 18rem;
  border-radius: 50%;
`;

export const UploadingAlert = styled.div`
  position: absolute;
  bottom: -1rem;
  background-color: var(--danger);
  color: white;
  font-size: 0.75rem;
  padding: 0.5em;
  border-radius: 1rem;
`;

export const EditButton = styled(Button)`
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: absolute;
  margin-right: -12rem;
  margin-bottom: 1rem;
`;
