import { laravelAxios } from '../../../config/axios/laravelAxios';

interface ChannelParams {
    customer_id: number,
    channel_customer_id: number
}

interface DtoDeleted {
    success: string;
}

export class ChannelsDeleteService {
 
  static async deleteChannel(params: ChannelParams) {
    const url = `channels/${params.customer_id}/${params.channel_customer_id}/destroy`;
    const request = await laravelAxios.get<DtoDeleted>(url);
    return request.data;
  }
 
}
