import styled from 'styled-components';

export const ButtonContainer = styled.div`
  align-self: flex-end;

  & button {
    display: flex;
    align-items: center;
    justify-content: space-around;

    & i {
      margin-left: 0.7rem;
    }
  }
`;
