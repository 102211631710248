export const priceMask = (value) => {
  // 1.234,56
  value = parseInt(value.replace(/\D/g, '')); //remove letters and simbols
  if (isNaN(value) || !value) {
    value = '0.00';
  } else {
    value = parseFloat(value/100).toFixed(2);
  }
  return (
    'R$ ' +
    value
      .replace(/\D/g, '')
      .replace(/(\d)(\d{2})$/, '$1,$2') // input ,
      .replace(/(?=(\d{3})+(\D))\B/g, '.')
  ); // input .
};

export const priceUnmask = (value) => {
  return value.replace(/\D/g, '');
};
