import axios from 'axios';
import poliConfig from 'src/utils/poliConfig';

const defaultHeaders = {
  'Accept': 'application/json',
  'Content-Type': 'application/json',
};

const defaultConfig = {
  headers: defaultHeaders,
  withCredentials: true,
};

const postContractInLaravel = (data) => {
  return axios.post(poliConfig.poliAppLaravelUrl + `/admin/contracts`, data, defaultConfig);
};

export {
  postContractInLaravel,
}
