import { Button, Icon } from '@polichat/flamboyant'
import { useSelector } from 'react-redux';

interface VoxProps {
    contact: any
    user: any
}

export function Vox(props: VoxProps) {
    const customer_id = useSelector((state: any) => state.general?.current_customer_id);
    const user_id = useSelector((state: any) => state.general?.user.id);

    function handleClickVox() {
        window.open(`${process.env?.REACT_APP_VOX_URL}?contactID=${props?.contact.id}&customerID=${customer_id}&userID=${user_id}&contactName=${props?.contact.name}&contactPicture=${props?.contact.picture}`, 'popup', 'width=700, height=500')
    }
    return (
        <Button onClick={handleClickVox} className="voxButton">
            <Icon icon="poli-icon pi-phone-fill" size={25} />
        </Button>
    )
}
