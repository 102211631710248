import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import history from '../../../../services/history';
import WabaService from '../../../../services/tools/template-waba/template-waba';

import Animation from '../../../common/animation';
import FormMessageBuilder from './FormMessagesBuilder.component';

function FormMessageEdit() {
  const { id } = useParams();
  const customer_id = useSelector((state) => state.general.current_customer_id);
  const [isLoading, setIsLoading] = useState(true);
  const [templateData, setTemplateData] = useState({});

  useEffect(() => {
    async function fetchTemplate() {
      const result = await WabaService.getTemplateById({ id, customer_id });
      if (result) {
        setTemplateData(result);
        setIsLoading(false);
      } else {
        history.goBack();
      }
    }
    fetchTemplate();
  }, []);

  if (isLoading) {
    return <Animation icon="loading" />;
  }

  return <FormMessageBuilder data={templateData} />;
}

export default FormMessageEdit;
