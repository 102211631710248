import styled from "styled-components";

export const IllustrationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  & i {
    max-width: 100%;
    margin-bottom: 1rem;

    @media (max-width: 600px) {
      width: auto;
    }
  }
`;
