import React, { useCallback, useState } from 'react';
import { Button, Icon, CustomInput } from '@polichat/flamboyant';

import {
  OpacityActive,
  Title,
  Container,
  IconLabelContainer,
  HorizontalRule,
  RadioGroup,
  ContainerDirConectionFace,
} from './ChannelDescriptionBanner.styles';
import { IconPlusIcon } from 'src/assets/illustratorElement/iconPlusIcon/iconPlusIcon';

export default function ChannelBaseboardMessenger({
  step,
  title,
  description,
  activeStep,
  linkFacebook,
  data,
  onPageFBSelected,
}) {
  const [pageSelected, setPageSelected] = useState(null);

  /**
   * onPageSelected
   * @param {*} e
   */
  const onPageSelected = (e) => {
    const value = e.target.value;
    setPageSelected(value);
    onPageFBSelected(value);
  };
  /* end */

  /**
   * Label do botao de ação de acordo com cada passo
   */
  const labelActionButton = useCallback(() => {
    switch (step) {
      case 'connection':
        return `${!activeStep ? 'Conectado' : 'Conectar agora'}`;
      case 'app_allowed':
        return 'Tentar novamente';
      case 'error':
        return 'Tentar novamente';
      default:
        return '';
    }
  }, [activeStep, step]);
  /* end */

  /**
   * Cada passo tem uma ação
   */
  const handleActionButton = () => {
    if (['connection', 'app_allowed', 'error'].includes(step)) {
      window.open(
        linkFacebook,
        'MyWindow',
        'toolbar=no,location=no,directories=no,status=no, menubar=no,scrollbars=no,resizable=no,width=900,height=900'
      );
    }
  };
  /* end */

  return (
    <OpacityActive activeStep={activeStep}>
      <Title step={step}>{title}</Title>

      <HorizontalRule />

      <Container>
        <IconPlusIcon
          firstIcon="onlyLogo"
          secondIcon="poli-icon pi-facebook-fill"
          secondColor="var(--messenger-color)"
        />
        <ContainerDirConectionFace>
          <div className="text">
            {description}

            {/* Seleção de paginas */}
            {['selectPageFB'].includes(step) && (
              <RadioGroup>
                {data?.length > 0 &&
                  data.map((e, i) => {
                    return (
                      <CustomInput
                        key={i}
                        id={`select-page-key${i}`}
                        type="radio"
                        label={e.name}
                        value={e.id}
                        checked={pageSelected === e.id}
                        onChange={onPageSelected}
                      />
                    );
                  })}
              </RadioGroup>
            )}

            {/* Permissões necessarias */}
            {['app_allowed'].includes(step) && (
              <div style={{ display: 'flex' }}>
                <ul style={{ fontSize: '0.75em', listStyle: 'circle' }}>
                  {data?.length > 0 &&
                    data.map((e, i) => {
                      let perm = e.replace('É necessário a permissão de ', '');
                      return (
                        <li key={i}>
                          {perm.charAt(0).toUpperCase() + perm.slice(1)}
                        </li>
                      );
                    })}
                </ul>
              </div>
            )}
          </div>

          {['connection', 'app_allowed', 'error'].includes(step) && (
            <Button
              color="primary"
              onClick={handleActionButton}
              className="buttonMessenger"
              disabled={!activeStep}
            >
              <span>{labelActionButton()}</span>
            </Button>
          )}
        </ContainerDirConectionFace>
      </Container>

      <HorizontalRule />
    </OpacityActive>
  );
}
