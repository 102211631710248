import { ActionTypes } from "./actions";

export default (state = {
  categories: [],
  loadingGetCategories: false,
  lastError: null,
  categoriesStatus: null,
}, action) => {

  switch (action.type)
  {
    case ActionTypes.GET_CATEGORIES: {
      return {
        ...state,
        ...{
          loadingGetCategories: true
        }
      };
    }
    case ActionTypes.GET_CATEGORIES_SUCCESS: {
      return {
        ...state,
        ...{
          categories: action.data,
          loadingGetCategories: false,
          categoriesStatus: true,
        }
      };
    }
    case ActionTypes.GET_CATEGORIES_FAILURE: {
      return {
        ...state,
        ...{
          error: action.error,
          loadingGetCategories: false,
          categoriesStatus: false,
        }
      };
    }
    case ActionTypes.RESET_CATEGORIES_STATUS : {
      return {
        ...state,
        ...{
          categoriesStatus: null,
        }
      }
    }
    default: {
      return state;
    }
  }

}
