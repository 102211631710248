import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Admin from '../pages/admin';
import Chat from '../pages/chat/index';
import { Report } from '../pages/report';
import Shopping from '../pages/shopping';
import Tools from '../pages/tools';

import {
  Bot,
  Chat as ChatIcon,
  Company,
  Configs,
  Crm,
  Home,
  Pay,
  Report as ReportIcon,
} from '@poli-ink/react';

const Routers = () => {
  // @ts-ignore
  const wlConfig = useSelector((state) => state?.whitelabel?.config);
  // @ts-ignore

  const routesDefault: any = [
    {
      path: '/',
      title: 'Página Inicial',
      exact: true,
      sidebarName: 'Home',
      icon: <Home />,
      content: '',
      showPwa: true,
      tag: 'home',
    },
    {
      path: '/chat',
      title: 'Chat',
      sidebarName: 'Chat',
      icon: <ChatIcon />,
      content: Chat,
      showPwa: true,
      tag: 'chat',
    },
    {
      path: '/automation',
      title: 'Automações',
      sidebarName: ' Automações',
      icon: <Bot />,
      content: '',
      tag: 'automation',
      showPwa: true,
    },
    {
      path: '/crm',
      title: 'CRM',
      exact: true,
      sidebarName: 'CRM',
      icon: <Crm />,
      content: '',
      showIsNew: true,
      tag: 'crm',
      isNew: true,
    },
    {
      path: '/shopping',
      title: 'Pay',
      sidebarName: 'Pay',
      subitens: true,
      icon: <Pay />,
      content: Shopping,
      showPwa: true,
      tag: 'pay',
    },
    {
      path: '/v2-report',
      title: 'Relatório',
      sidebarName: 'Report',
      subitens: true,
      icon: <ReportIcon />,
      content: Report,
      showPwa: true,
      tag: 'report',
    },
    {
      path: '/tools',
      title: 'Configurações',
      sidebarName: 'Configurações',
      subitens: true,
      icon: <Configs />,
      content: Tools,
      tag: 'tools',
    },
    {
      path: '/admin',
      title: 'Administração',
      sidebarName: 'Administração',
      subitens: true,
      icon: <Company />,
      content: Admin,
      showPwa: true,
      needAdmin: true,
      tag: 'admin',
    },
  ];

  const [routers, setRouters] = useState(routesDefault);

  useEffect(() => {
    if (wlConfig?.is_white_label) {
      const _routers: any = [];
      /**
       * Logica WhiteLabel
       */
      const navWl = Array.isArray(wlConfig?.buttons) ? wlConfig?.buttons : [];

      routesDefault.map((route: { tag: any; title: any; sidebarName: any }) => {
        const foundItemWL = navWl.find((e: any) => e.id === route.tag);
        if (foundItemWL) {
          route.title = foundItemWL.name;
          route.sidebarName = foundItemWL.name;
          _routers.push(route);
        }
      });

      /* end */
      setRouters(_routers);
    }
  }, [wlConfig]);

  return routers;
};

export { Routers };
