import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { validationSchema } from './validationSchema';

export function useLocalForm(){
    const form = useForm({
        defaultValues:{
            name: '',
            cnpj: '',
            companyName: '',
            site: '',
            email: '',
            type: '',
            address: '',
            externalId: '', 
            observations: '',
        },
        resolver:yupResolver(validationSchema)
    });
    return form
}