import { CustomerHasPaymentIntegratorContext } from './context';
import { usePaymentIntegratorService } from './usePaymentIntegratorService';

export interface CustomerHasPaymentIntegratorProviderProps {
  customer_id?: number;
  children?: any;
}

export function CustomerHasPaymentIntegratorProvider(
  props: CustomerHasPaymentIntegratorProviderProps
) {
  const hook = usePaymentIntegratorService({
    customer_id: props.customer_id,
  });

  // ---------------------------------------------
  // Transformations
  // ---------------------------------------------
  // Render

  return (
    <CustomerHasPaymentIntegratorContext.Provider value={hook}>
      {props.children}
    </CustomerHasPaymentIntegratorContext.Provider>
  );
}
