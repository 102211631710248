import React, { useState, useEffect, useCallback } from 'react';

import {
  Icon,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  UncontrolledTooltip,
} from '@polichat/flamboyant';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as VisitorActions from '../../../store/modules/visitors/actions';
import * as ChatActions from '../../../store/modules/chat/actions';

import Animation from '../../common/animation';
import SessionsWebchat from '../SessionsWebchat/SessionsWebchat';

function VisitorsNotifications({
  //data from visitor store
  sessions,
  sessionsLength,
  loading,
  error,
  //data from chat store
  userHasChats,
  userHasUsersList,
  userHasWebChatChannel,
  //actions from visitor
  startVisitorsService,
  storeFromVisitor,
  removeReadToOpenChatVisitor,
  tryOpenVisitorChatError,
  // actions from chat
  openChat,
  openChatTab,
  fetchUniqueChatRequest,
}) {
  const [isVisitorsDropDownOpen, setIsVisitorsDropDownOpen] = useState(false);
  // side-effect to dispatch START_VISITORS_SERVICE
  useEffect(() => {
    if (userHasWebChatChannel && userHasChats && userHasUsersList)
      onStartVisitorsService();
  }, [userHasWebChatChannel, userHasChats, userHasUsersList]);

  const onStartVisitorsService = useCallback(() => {
    startVisitorsService();
  }, []);

  if (!userHasWebChatChannel) return null;

  let content = null;
  let iconStatus;
  let textStatus;

  if (error || loading) {
    //defining CONTENT to LOADING or ERROR cases
    if (error && !loading) {
      iconStatus = (
        <Icon
          icon="poli-icon pi-alert-fill"
          size={14}
          color="var(--red-polichat)"
        />
      );
      textStatus = 'Erro ao conectar, clique para tentar novamente!';
    }

    if (loading) {
      iconStatus = <Animation icon="loading" size={14} />;
      textStatus = 'Carregando os visitantes do seu webchat...';
    }

    content = (
      <div style={{ position: 'relative' }} onClick={onStartVisitorsService}>
        <Button id="tooltipVisitors" size={'normal-icon'} color="neutral">
          <Icon
            size={24}
            position={'relative'}
            top={'-5px'}
            right={'6px'}
            icon="poli-icon pi-earth-line"
          />
        </Button>
        <div
          style={{
            position: 'absolute',
            top: '3px',
            right: '7px',
          }}
        >
          {iconStatus}
        </div>
        <UncontrolledTooltip placement="bottom" target="tooltipVisitors">
          {textStatus}
        </UncontrolledTooltip>
      </div>
    );
  } else {
    //Visitors Dropdown
    content = (
      <Dropdown
        isOpen={isVisitorsDropDownOpen}
        toggle={() => setIsVisitorsDropDownOpen((prevState) => !prevState)}
        className="visitorsNotification"
        size={'normal-icon'}
        color={'neutral'}
      >
        <DropdownToggle
          color={'neutral'}
          border="border-none"
          id="tooltipVisitors"
          size={'normal-icon'}
          caret
        >
          <UncontrolledTooltip placement="bottom" target="tooltipVisitors">
            Lista de visitantes em seu website.{' '}
          </UncontrolledTooltip>
          <Icon
            size={24}
            icon="poli-icon pi-earth-line"
            position={'relative'}
            top={'0px'}
            right={'0px'}
          />
          <span
            style={{
              position: 'absolute',
              top: '2px',
              right: '-5px',
              background: 'var(--red-polichat)',
              borderRadius: '100%',
              fontSize: '0.6rem',
              fontWeight: 'bold',
              color: 'var(--primary-background-color)',
              alignItems: 'center',
              textAlign: 'center',
              padding: '3px 0px 0px 0px',
              height: '20px',
              width: '20px',
              display: 'inline',
            }}
            title={sessionsLength}
          >
            {sessionsLength > 999 ? '999+' : sessionsLength}
          </span>
        </DropdownToggle>
        <DropdownMenu>
          <SessionsWebchat
            isVisitorsDropDownOpen={isVisitorsDropDownOpen}
            handleCloseVisitorsDropDown={() => setIsVisitorsDropDownOpen(false)}
            //sessions
            sessions={sessions}
            //visitors actions as props
            storeFromVisitor={storeFromVisitor}
            removeReadToOpenChatVisitor={removeReadToOpenChatVisitor}
            tryOpenVisitorChatError={tryOpenVisitorChatError}
            //chat actions as props
            openChat={(id) => openChat(id)}
            openChatTab={() => openChatTab()}
            fetchUniqueChatRequest={(id) => fetchUniqueChatRequest(id)}
          />
        </DropdownMenu>
      </Dropdown>
    );
  }

  return content;
}

const mapStateToProps = (state) => ({
  //state.visitors
  loading: state.visitors?.hasAsyncRequest || false,
  error: state.visitors?.error || false,
  sessionsLength: state.visitors?.sessions?.length || 0,
  sessions:
    state.visitors?.sessions?.length > 2
      ? state.visitors.sessions.sort((a, b) =>
          a.created_at > b.created_at ? -1 : b.created_at > a.created_at ? 1 : 0
        )
      : state.visitors?.sessions,

  //state.chats
  userHasChats: state.chat?.chats ? true : false,
  userHasUsersList: state.chat?.config?.general?.users ? true : false,
  userHasWebChatChannel:
    state.chat?.config?.user?.channels?.reduce(
      (result, channel) => (channel.type == 'webchat' ? (result += 1) : result),
      0
    ) || 0,
});
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    ...bindActionCreators(VisitorActions, dispatch),
    ...bindActionCreators(ChatActions, dispatch),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VisitorsNotifications);
