import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import gatewayListConfig from '../../../../../../../services/shopping/payment/gatewayListConfig';

import poliConfig from '../../../../../../../utils/poliConfig';

const GatewaysNames = {
  pagseguro: 'PagSeguro',
  mercadopago: 'Mercado Pago',
};

export function usePoliPayGatewaysService() {
  const [isLoadingGateways, setLoading] = useState(false);
  const [hasPoliPayGatewayActive, setHasPoliPayGatewayActive] = useState(false);
  const [PoliPayGateways, setPoliPayGateways] = useState([]);

  //code to remove when remover feature flag: feature_polipay_mercadopago
  const state_current_customer_id = useSelector(
    (state) => state.general?.current_customer_id
  );

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        let { data } = await gatewayListConfig.fetchGateways();
        if (data.length > 0) {
          let aux = data.filter((item) => item.active == 1);
          if (aux.length > 0) {
            setHasPoliPayGatewayActive(true);
            aux = aux.map((gateway) => {
              return { ...gateway, name: GatewaysNames[gateway.name] };
            });
            //remove mercadopago if environment is production
            //feature_polipay_mercadopago is false in production
            //in production enable mercadopago if customer is Poli
            if (
              !poliConfig.feature_polipay_mercadopago &&
              !(
                Number(state_current_customer_id) ===
                Number(poliConfig.feature_enable_customer_tester_id)
              )
            ) {
              aux = aux.filter(
                (gateway) => gateway.name !== GatewaysNames.mercadopago
              );
              aux = aux.filter((item) => item.active == 1);
              !(aux.length > 0) && setHasPoliPayGatewayActive(false);
            }
            setPoliPayGateways(aux);
          }
        }
      } catch (error) {
        console.error('Error on usePolipayGatewaysService: ', error);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return { isLoadingGateways, PoliPayGateways, hasPoliPayGatewayActive };
}
