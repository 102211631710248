export default function formatPhone(phone) {
  let countryCode;
  let dddCode;
  let first4Digits;
  let last4Digits;
  let formattedPhone;
  let nineDigit;

  if (phone === '' || phone === null) {
    return 'Telefone não cadastrado';
  }

  if (phone.substr(0, 2) !== '55') {
    return phone;
  }

  const phoneLength = phone.length;
  const currentPhone = parseInt(phone);

  // Nao tem nono dígito
  if (phoneLength === 12) {
    countryCode = parseInt(currentPhone / Math.pow(10, 10));
    dddCode = parseInt((currentPhone % Math.pow(10, 10)) / Math.pow(10, 8));
    first4Digits = parseInt(
      ((currentPhone % Math.pow(10, 10)) % Math.pow(10, 8)) / Math.pow(10, 4)
    );
    last4Digits = parseInt(
      ((currentPhone % Math.pow(10, 10)) % Math.pow(10, 8)) % Math.pow(10, 4)
    );

    if (String(last4Digits).length === 3) {
      last4Digits = `0${last4Digits}`;
    }

    if (String(last4Digits).length === 2) {
      last4Digits = `00${last4Digits}`;
    }

    if (String(last4Digits).length === 1) {
      last4Digits = `000${last4Digits}`;
    }

    formattedPhone = `+${countryCode} (${dddCode}) ${first4Digits}-${last4Digits}`;
  }
  // Tem nono dígito
  else if (phoneLength === 13) {
    countryCode = parseInt(currentPhone / Math.pow(10, 11));
    dddCode = parseInt((currentPhone % Math.pow(10, 11)) / Math.pow(10, 9));
    nineDigit = parseInt(
      ((currentPhone % Math.pow(10, 11)) % Math.pow(10, 9)) / Math.pow(10, 8)
    );
    first4Digits = parseInt(
      (((currentPhone % Math.pow(10, 11)) % Math.pow(10, 9)) %
        Math.pow(10, 8)) /
      Math.pow(10, 4)
    );
    last4Digits = parseInt(
      ((currentPhone % Math.pow(10, 11)) % Math.pow(10, 9)) % Math.pow(10, 4)
    );

    if (String(last4Digits).length === 3) {
      last4Digits = `0${last4Digits}`;
    }

    if (String(last4Digits).length === 2) {
      last4Digits = `00${last4Digits}`;
    }

    if (String(last4Digits).length === 1) {
      last4Digits = `000${last4Digits}`;
    }

    formattedPhone = `+${countryCode} (${dddCode}) ${nineDigit} ${first4Digits}-${last4Digits}`;
  }
  else if (phoneLength === 14) {
    phone = String(phone);
    countryCode = phone.substring(0, 2);
    dddCode = phone.substring(2, 4);
    nineDigit = phone.substring(4, 5);
    let first5Digits = phone.substring(5, 10);
    last4Digits = phone.substring(10);

    return `+${countryCode} (${dddCode}) ${nineDigit} ${first5Digits}-${last4Digits}`;
  } else {
    formattedPhone = phone;
  }

  return formattedPhone;
}
