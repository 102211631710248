import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import * as GeneralActions from '../../../store/modules/general/actions';
import { connect } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import PageMainHeader from '../../common/pages/PageMainHeader/PageMainHeader.component';
import { Button, Input } from '@polichat/flamboyant';
import PageMainBody from '../../common/pages/PageMainBody/PageMainBody.component';
import { getService } from '../../../utils/admin/services/services';
import { postAServiceInLaravel, putAServiceInLaravel } from '../../../services/admin/services/services';
import history from '../../../services/history';
import Animation from '../../common/animation';

function ServiceForm() {

  let { id } = useParams();
  const { state } = useLocation();
  const [service, setService] = useState(getCleanService());
  const [erros, setErros] = useState(getCleanErros());
  const [canSave, setCanSave] = useState(true);

  const [isLoading, setIsLoading] = useState(true);

  async function fetchData() {
    if(id){
      const service = await getService(id);
      setService(service);
    }
    setIsLoading(false);
  }

  useEffect(()=> {

    /**
     * Caso o origin exista significa que veio da tela de importação de serviços do superlógica
     * No origin vai conter a Sring "import", e no campo data vai conter um serviço.
     * caso contrário o fluxo deve seguir normalmente conforme foi desenhado
     */
    if(state && state.origin){
      const service = state.data;
      setService(service)
      setIsLoading(false);
    }else{
      fetchData();
    }

    return ()=> {
      setService(getCleanService());
    }
  }, []);

  useEffect(() => {
    /**
     * Deve ser criado um serviço em branco para os seguintes casos
     * Caso não tenha o id que foi obtido na URL
     * Caso não tenha o estado de import
     */
    if(!id && !(state?.origin === 'import')){
      setService(getCleanService());
    }
  }, [id]);

  return (
    <>
      <PageMainHeader title={id ? 'Editar serviço' : 'Novo serviço'} icon='poli-icon pi-catalogo-line' />
      <PageMainBody>

        {isLoading ?
          <Animation icon='chat-loading' top={'50%'} />
          :
          <>
            <p>Nome</p>
            <Input type='text' value={service?.name} className={erros.inputName.error ? 'is-invalid' : ''} onChange={(event) => {
                setService({...service, name:event.target.value});
            }} />
            {erros.inputName.error ? <span className='small is-invalid'>{erros.inputName.description}<br /></span> : null}
            <br />
            <p>Preço</p>
            <Input type="number" value={service?.value} className={`custom-number ${erros.inputValue.error ? 'is-invalid' : ''}`} onChange={(event) => {
                setService({...service, value:event.target.value});
            }} />
            {erros.inputValue.error ? <span className='small is-invalid'>{erros.inputValue.description}<br /></span> : null}
            <br />
            <p>Descrição</p>
            <Input type='textarea' value={service?.description} className={erros.inputDescription.error ? 'is-invalid' : ''} onChange={(event) => {
                setService({...service, description:event.target.value});
            }} />
            {erros.inputDescription.error ? <span className='small is-invalid'>{erros.inputDescription.description}<br /></span> : null}
            <br />
            <span className='custom-switch custom-control'>
              <Input type='checkbox' id='checkIsStatus' checked={!service?.status} className='custom-control-input'  onChange={() => {
                  const newStatus = !(service?.status) === true ? 1 : 0;
                  setService({...service, status: newStatus});
              }} />
              <label className='custom-control-label' htmlFor='checkIsStatus'>Pausado</label>
            </span>
            <br/>
            <span className='custom-switch custom-control'>
              <Input type='checkbox' id='checkIsType' checked={service?.type == 10} className='custom-control-input'  onChange={() => {
                  const newType = !(service?.type) === true ? 10 : 0;
                  setService({...service, type: newType});
              }} />
              <label className='custom-control-label' htmlFor='checkIsType'>Créditos</label>
            </span>
            <span className='custom-switch custom-control'>
              <Input type='checkbox' id='checkTypeDiscount' checked={service?.type == 11} className='custom-control-input'  onChange={() => {
                  const newType = !(service?.type) === true ? 11 : 0;
                  setService({...service, type: newType});
              }} />
              <label className='custom-control-label' htmlFor='checkTypeDiscount'>Desconto</label>
            </span>

            {(service?.type == 10 && id) && (
                <>
                  <br/>
                  <p>Quatidade do pacote</p>
                  <Input type="number" value={service?.quantity_contact_day} className={`custom-number ${erros.inputValue.error ? 'is-invalid' : ''}`} onChange={(event) => {
                      setService({...service, quantity_contact_day: event.target.value});
                  }} />
                </>
              )
            }

            <br/>
            <Button gradient='success' className='btn-gradient-success btn btn-secondary' style={{ float: 'right' }} disabled={!canSave} onClick={() => {

              const errosChecked = getValidatedErros(service);
              setErros(errosChecked);

              if(errosChecked.existErros){
                return;
              }

              setCanSave(false);

              if(id){ // Atualizar o serviço (PUT)
                putService(service);
              } else { // Criar um serviço (POST)
                postService(service);
              }

            }}>
              Salvar
            </Button>
          </>}
      </PageMainBody>
    </>
  );
}

async function postService(service) {
  try{
    const response = await postAServiceInLaravel(service);
    if(response.status === 200){
      history.push({ pathname: `/admin/services` });
    }
  }catch (error) {
    console.error(error.message, 'message:', error.response?.data?.message);
    alert('Ocorreu um erro ao cadastrar este serviço. Verifique todos os campos. Caso o problema persista, contate o nosso suporte.');
  }
}

async function putService(service) {
  try{
    const response = await putAServiceInLaravel(service);
    if(response.status === 200){
      history.push({ pathname: `/admin/services` });
    }
  }catch (error) {
    console.error(error.message, 'message:', error.response?.data?.message);
    alert('Ocorreu um erro ao atualizar este serviço. Verifique todos os campos. Caso o problema persista, contate o nosso suporte.');
  }
}

function getValidatedErros(service) {

  let errosChecked = getCleanErros();

  if(!(service.name) || service.name === '' || service.name?.trim() === '') {
    errosChecked = {...errosChecked, inputName : {error: true, description: '* Campo obrigatório'}, existErros: true};
  }

  if(!(service.value) || service.value === '' || service.value?.trim() === ''){
    errosChecked = {...errosChecked, inputValue : {error: true, description: '* Campo obrigatório'}, existErros: true}
  }

  if(!(service?.description) || service?.description === '' || service?.description?.trim() === ''){
    errosChecked = {...errosChecked, inputDescription : {error: true, description: '* Campo obrigatório'}, existErros: true}
  }

  return errosChecked;
}

function getCleanService() {
  return {name:'', description:'', value:'0', status: 1};
}

function getCleanErros() {
  return {
    existErros: false,
    inputName : {error: false, description: ''},
    inputValue : {error: false, description: ''},
    inputDescription : {error: false, description: ''}
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(GeneralActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ServiceForm);
