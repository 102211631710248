import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ListContainer = styled.ul`
  margin: 0.5rem;
`;

export const LogoInfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 0 5px;

  & span {
    color: var(--primary-font-color);
    font-size: 1.2rem;
    font-weight: bold;
    margin-left: 10px;
  }
`;

export const ListItem = styled.li`
  padding: 0.2rem 0.5rem;
  margin: 0.5rem 0 0.3rem;
  border-bottom: 1px solid var(--gray-25);

  display: flex;
  justify-content: flex-start;
  gap: 1rem;
  align-items: center;

  img {
    max-height: 50px;
  }

  & span {
    font-size: 1rem;
    color: var(--gray-75);
  }

  //button position in layout
  button {
    margin-left: auto;
  }

  //fix text and icon inside button
  button {
    display: flex;
    align-items: center;
    gap: 0.3rem;
    justify-content: space-between;
  }

  //mobile
  @media (max-width: 600px) {
    padding: 0 0.5rem 0.5rem;
    flex-direction: column;
    align-items: center;

    span {
      text-align: center;
    }

    button {
      margin: 0;
    }
  }
`;

export const IntegrationAnimation = styled.div`
  margin-top: 200px;

  display: flex;
  flex-direction: column;
  align-items: center;
  //justify-content: baseline;

  img {
    border-radius: 1rem;
  }

  h2 {
    margin-top: 2rem;
    color: var(--gray-75);
    font-size: 1.5rem;
    line-height: 1.2rem;
    font-weight: bold;
  }

  //mobile
  @media (max-width: 600px) {
    margin-top: 50px;
    text-align: center;

    h2 {
      font-size: 1rem;
      margin-bottom: 20px;
    }
  }
`;
