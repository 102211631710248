import { useCallback, useEffect, useState } from 'react';
import { BusinessManagerService } from '../../../../services/meta-business/businessManager';
import { getOrganizationIdFromDtoDeprecatedCustomer } from '../helpers';

export interface UseMetaBusinessManagerServiceProps {
  customer_id: number;
}
export function useMetaBusinessManagerService(
  props: UseMetaBusinessManagerServiceProps
) {
  const [loading, setLoading] = useState(false);
  const [organizationId, setOrganizationId] = useState<string | undefined>('');
  const [customerHasOrganizationId, setCustomerHasOrganizationId] = useState<
    boolean | null
  >(null);

  // ---------------------------------------------
  // Functions
  const load = useCallback(async (id: number) => {
    try {
      setLoading(true);
      const result = await BusinessManagerService.DeprecatedCustomerId({
        customer_id: id,
      });

      let _organizationId = getOrganizationIdFromDtoDeprecatedCustomer(result);

      setOrganizationId(_organizationId);
      setCustomerHasOrganizationId(true);
    } catch (error) {
      setCustomerHasOrganizationId(false);
    } finally {
      setLoading(false);
    }
  }, []);
  // ---------------------------------------------
  // Effects
  useEffect(() => {
    if (props.customer_id) {
      load(props.customer_id);
    }
  }, [props.customer_id]);
  // ---------------------------------------------
  // Transformations
  // ---------------------------------------------
  // API

  return {
    loading,
    organizationId,
    customerHasOrganizationId,
  };
}
