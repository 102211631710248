import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Icon } from '@polichat/flamboyant';
import { toast } from 'react-toastify';

import history from '../../../../../../services/history';
import ChannelsService from '../../../../../../services/tools/channels/channels';
import ButtonDoubts from '../../common/ButtonDoubts/ButtonDoubts.component';
import InfoCard from '../../InfoCard/InfoCard.component';
import ActionGroupButtons from '../../ActionGroupButtons/ActionGroupButtons.component';

import { initFacebookSDK } from '../../MetaSDK/initFacebookSDK';
import { EmbeddedSignupFlow } from '../../MetaSDK/EmbeddedSignupFlow';
import { ReturnResponse } from '../../MetaSDK/EmbeddedSignupFlow';
import { DropDownList } from './DropDownList';

import {
  Container,
  TextInfos,
  CardsContainer,
  FourColumnsGrid,
  ErrorFbContainer,
  SuccessFbContainer,
  ContainerSuccessFB,
  IconCenterContainer,
  ContentInfo,
} from './FillForm.styles';
import DeleteChannelBsp from '../../DeleteChannelBsp';

interface Props {
  data: any;
  dataSet: Function;
  currentStep: number;
}

interface PhoneNumbersInput {
  id: string;
  name: string;
  verified_name: string;
  waba_id: string;
  waba_name: string;
}

interface ConfigSend {
  business_id: string;
  business_name: string;

  waba_id: string;
  waba_name: string;

  phone_id: string;
  phone_name: string;
  phone_number: string;
}

interface User {
  id: number;
  name: string;
}

export interface Customer {
  id: string;
  name: string;
  contact_email: string;
  phone: string;
  site: string;
  adress_street: string;
  adress_city: string;
  adress_state: string;
  adress_postal_code: string;
  photo: string;
  created_at: string;

  timezone?: string;
}

const FillForm = ({ data, dataSet, currentStep }: Props) => {
  const [customerCurrent, setCustomerCurrent] = useState<Customer>();
  const [isLoading, setIsLoading] = useState<boolean>();
  const wlConfig = useSelector(
    // @ts-ignore
    (state) => state?.whitelabel?.config
  );
  /**
   * pegando id da empresa no state
   */
  const customer_id: number = useSelector(
    // @ts-ignore
    (state) => state?.general?.current_customer_id
  );
  /* end */

  /**
   * Todas as empresas que o usuario tem acesso
   */
  const customers: number = useSelector(
    // @ts-ignore
    (state) => state?.general?.user?.customers
  );
  /* end */

  /**
   * Todas as empresas que o usuario tem acesso
   */
  const timezone: number = useSelector(
    // @ts-ignore
    (state) => state?.general?.user?.settings?.timezone[0]?.offset
  );
  /* end */

  /**
   * Pegando dados da empresa atual
   */
  useEffect(() => {
    if (customers && Array.isArray(customers) && customer_id) {
      const found = customers.find((e) => e.id === customer_id);
      if (found) {
        found.timezone = timezone;
        setCustomerCurrent(found);
      }
    }

    if (customer_id) {
      getUsers(customer_id);
    }
  }, [customers, customer_id, timezone]);
  /* end */

  /**
   * Get Users
   */
  const [usersSelected, usersSelectedSet] = useState([]);
  const getUsers = async (customer_id: number) => {
    try {
      // @ts-ignore
      const { data: users } = await ChannelsService.getAllUsersByCustomer(
        customer_id
      );

      if (users && users.length) {
        const usersOptions = users.map((user: User) => {
          return {
            value: user.id,
            label: user.name,
          };
        });
        usersSelectedSet(usersOptions);
      }
      return;
    } catch (error) {
      console.error('Error in FillForm component when getUsers => ', error);
      return;
    }
  };
  /* end */

  const [disabledBtnNext, setDisabledBtnNext] = useState(true);
  const [errorSdkFb, setErrorSdkFb] = useState(false);

  // Action back page to Actions Group Button component
  const goBack = () => history.push('/tools/channels');

  /**
   * Return SDK
   * - DATA WABA ACCOUNTS WITH NUMBERS
   */
  const [callbackSdkData, setCallbackSdkData] = useState<ReturnResponse[]>();

  // Ir para proximo passo quando popup do facebook retornar sucesso
  useEffect(() => {
    if (callbackSdkData) {
      dataSet({ ...data, homologation: 3 });
    }
  }, [callbackSdkData]);
  /* end */

  /**
   * Conta de negocios selecionado, entao deve filtrar o input de contas waba
   * @param businessId id da conta de negocios
   */
  const [bussinessSelected, setBussinessSelected] = useState<ReturnResponse>();

  const onSeletedBusiness = (businessId: string) => {
    if (callbackSdkData) {
      const business = callbackSdkData.find((e) => e.id === businessId);
      setBussinessSelected(business);
    }
  };
  /* end */

  /**
   * Conta Waba selecionado, entao deve filtrar o input de numeros
   * @param wabaId id da conta do whatsapp
   */
  const [phoneNumbers, setPhoneNumbers] = useState<PhoneNumbersInput[]>();

  const onSeletedWabaAccount = (wabaId: string) => {
    if (Array.isArray(bussinessSelected?.waba_accounts)) {
      const acc = bussinessSelected?.waba_accounts.find((e) => e.id === wabaId);

      if (acc && Array.isArray(acc.phone_numbers)) {
        const _phoneNumbers = [];
        for (let i = 0; i < acc.phone_numbers.length; i++) {
          const item = acc.phone_numbers[i];
          const add = {
            id: item.id,
            name: item.display_phone_number,
            verified_name: item.verified_name,
            waba_id: acc.id,
            waba_name: acc.name,
          };
          _phoneNumbers.push(add);
        }
        setPhoneNumbers(_phoneNumbers);
      }
    }
  };
  /* end */

  /**
   * Send Number selected for backend meta
   */
  const [numberIdSelected, setNumberIdSelected] = useState<string>();
  const sendPhoneNumberToBackend = async () => {
    if (
      bussinessSelected &&
      phoneNumbers &&
      Array.isArray(phoneNumbers) &&
      numberIdSelected
    ) {
      const _phoneData = phoneNumbers.find((e) => e.id === numberIdSelected);

      if (_phoneData) {
        const _dataConfig: ConfigSend = {
          business_id: bussinessSelected.id,
          business_name: bussinessSelected.name,

          waba_id: _phoneData.waba_id,
          waba_name: _phoneData.waba_name,

          phone_id: _phoneData.id,
          phone_name: _phoneData.verified_name,
          phone_number: _phoneData.name,
        };

        onSaveChannelSDK(_dataConfig);
      }
    }
  };
  /* end */

  /**
   * Salvar o retorno final da SDK com o numero selecionado
   * @param {*} _dataConfig
   */
  const onSaveChannelSDK = async (_dataConfig: ConfigSend) => {
    try {
      setIsLoading(true);

      const send = {
        id: data.id,
        users: usersSelected.map((user: any) => user.value),
        customer_id,
        config: { ..._dataConfig, homologation: 'finished' },
      };

      // @ts-ignore
      const res = await ChannelsService.updateChannel(send);
      // @ts-ignore
      if (res.status !== 200) {
        toast.error('Erro ao salvar. Tente novamente mais tarde!');
        return;
      }

      const resLaravel = await ChannelsService.notifyLaravelSaveMeta(data.id);
      // @ts-ignore
      if (resLaravel.status !== 200) {
        toast.error('Erro ao salvar. Tente novamente mais tarde!');
        return;
      }

      dataSet({ ...data, homologation: parseInt(currentStep.toString()) + 1 });
    } catch (error) {
      toast.error('Erro ao salvar. Tente novamente mais tarde!');

      console.error(
        'Error in FillForm Component when onSaveChannelSDK. Error => ',
        error
      );
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Init FBSDK
   */
  const [fbSDKinitiated, setFbSDKinitiated] = useState(false);
  useEffect(() => {
    initFacebookSDK().then(() => {
      setFbSDKinitiated(true);
    });
  }, []);
  /* end */

  return (
    <>
      <Container>
        {!wlConfig.hide_doubts_button && <ButtonDoubts />}

        {/* Passos para se o popup ainda nao foi aberto */}
        {!errorSdkFb && !callbackSdkData && (
          <>
            <TextInfos>
              <span>
                Agora, você entrará no ambiente do Facebook por um popup.
                <br />
                Estas são as etapas que você irá passar:
              </span>
            </TextInfos>

            <CardsContainer>
              <FourColumnsGrid columns={'1fr 1fr 1fr 1fr'}>
                <InfoCard number="1">
                  <>
                    <div>
                      <span style={{ fontWeight: 'bold' }}>Login</span>
                    </div>

                    <span className="mt-3">
                      Ao clicar em <strong>Entrar com o Facebook</strong>, irá
                      abrir um popup. Nele, faça login ou se certifique de estar
                      logado no Facebook com sua conta correta.
                    </span>
                  </>
                </InfoCard>

                <InfoCard number="2">
                  <>
                    <div>
                      <span style={{ fontWeight: 'bold' }}>
                        Escolher conta Business
                      </span>
                    </div>

                    <span className="mt-3">
                      Em seguida, caso já possua uma conta no gerenciador de
                      negócios do Facebook, escolha a opção correta, caso
                      contrário, crie uma nova conta de negócio do Facebook.
                      Obs.: É importante que esta conta seja a mesma que você
                      utiliza para anúncios do Facebook e no Instagram Ads.
                    </span>
                  </>
                </InfoCard>

                <InfoCard number="3">
                  <>
                    <div>
                      <span style={{ fontWeight: 'bold' }}>
                        Conta de gestão WABA
                      </span>
                    </div>

                    <span className="mt-3">
                      A próxima etapa, será criar uma conta do WhatsApp Business
                      API.
                    </span>
                  </>
                </InfoCard>

                <InfoCard number="4">
                  <>
                    <div>
                      <span style={{ fontWeight: 'bold' }}>
                        Número do WhatsApp
                      </span>
                    </div>

                    <span className="mt-3">
                      Por fim, você irá colocar o telefone oficial da sua
                      empresa, que será utilizado no canal WhatsApp dentro da
                      Poli. Para isso funcionar, este número não pode estar
                      vinculado a nenhum aplicativo do WhatsApp (Caso esteja,
                      exclua a sua conta e aguarde alguns minutos).
                    </span>
                  </>
                </InfoCard>
              </FourColumnsGrid>
            </CardsContainer>
          </>
        )}

        {/* Deu Error no Facebook, ou seja o usuario fechou o popup sem concluir as etapas */}
        {errorSdkFb && !callbackSdkData && (
          <ErrorFbContainer>
            <h2>
              Ops! Aparentemente não foi feita <br />
              todas as etapas no Facebook, <br />
              tente novamente!
            </h2>

            <p>Tente novamente</p>
          </ErrorFbContainer>
        )}

        {/* Quando deu tudo certo com facebook e foi todas as etapas */}
        {callbackSdkData && (
          <>
            <SuccessFbContainer>
              <h2>
                Conexão com o Facebook realizada com sucesso! <br />
                Confira as informações
              </h2>
            </SuccessFbContainer>

            <CardsContainer>
              <ContainerSuccessFB>
                {/* Conta de negócios Meta */}
                <InfoCard>
                  <>
                    <div>
                      <span style={{ fontWeight: 'bold' }}>
                        Conta de negócios Meta
                      </span>
                    </div>

                    <ContentInfo>
                      <DropDownList
                        data={callbackSdkData}
                        onSubmit={(value) => {
                          onSeletedBusiness(value);
                        }}
                      />

                      <p>
                        Tenha certeza que esta é a conta utilizada para fazer
                        anúncios no Facebook e Instagram
                      </p>
                    </ContentInfo>
                  </>
                </InfoCard>

                {/* ICON */}
                <IconCenterContainer>
                  <Icon
                    icon={'poli-icon pi-simple-arrow-right-line'}
                    size={45}
                  />
                </IconCenterContainer>

                {/* Conta WhatsApp */}
                <InfoCard>
                  <>
                    <div>
                      <span style={{ fontWeight: 'bold' }}>Conta WhatsApp</span>
                    </div>

                    <ContentInfo>
                      {bussinessSelected &&
                        bussinessSelected?.waba_accounts?.length > 0 && (
                          <DropDownList
                            data={bussinessSelected?.waba_accounts}
                            onSubmit={(value) => {
                              onSeletedWabaAccount(value);
                              // Mudou de conta waba resetar o numero
                              setNumberIdSelected(undefined);
                              setDisabledBtnNext(true);
                            }}
                          />
                        )}

                      {!bussinessSelected && (
                        <i>Selecione a conta de negócios Primeiro!</i>
                      )}

                      {bussinessSelected &&
                        bussinessSelected?.waba_accounts?.length === 0 && (
                          <i>
                            Não há contas de WhatsApp cadastrado para essa conta
                            de negócios
                          </i>
                        )}
                    </ContentInfo>
                  </>
                </InfoCard>

                {/* ICON */}
                <IconCenterContainer>
                  <Icon
                    icon={'poli-icon pi-simple-arrow-right-line'}
                    size={45}
                  />
                </IconCenterContainer>

                {/* Telefone WhatsApp  */}
                <InfoCard>
                  <>
                    <div>
                      <span style={{ fontWeight: 'bold' }}>
                        Telefone WhatsApp
                      </span>
                    </div>

                    <ContentInfo>
                      {phoneNumbers && phoneNumbers?.length > 0 && (
                        <DropDownList
                          data={phoneNumbers}
                          onSubmit={(value) => {
                            setNumberIdSelected(value);
                            setDisabledBtnNext(false);
                          }}
                        />
                      )}

                      {!phoneNumbers && (
                        <i>Selecione a conta do WhatsApp Primeiro!</i>
                      )}

                      {phoneNumbers?.length === 0 && (
                        <i>
                          Não há número cadastrado para essa conta do WhatsApp
                        </i>
                      )}
                    </ContentInfo>
                  </>
                </InfoCard>
              </ContainerSuccessFB>
            </CardsContainer>
          </>
        )}

        {/* Button Facebook SDK */}

        <div
         data-tour={'HelpHero_Migrate_WhatsApp_Step04'}
         style={{display: 'flex', justifyContent: 'space-evenly'}}
        >
          {fbSDKinitiated && !callbackSdkData && (
            <EmbeddedSignupFlow
              setErrorSdkFb={setErrorSdkFb}
              onCallbackSDK={setCallbackSdkData}
              customer={customerCurrent}
            />
          )}
          <DeleteChannelBsp channel={data} size={'lg'} />
        </div>

        <ActionGroupButtons
          onClickNext={() => {
            sendPhoneNumberToBackend();
          }}
          onClickBack={goBack}
          btnBackShow={true}
          btnCancelShow={false}
          btnNextShow={callbackSdkData && !isLoading ? true : false}
          isLoading={isLoading}
          disabledBtnBack={callbackSdkData ? true : false}
          nextButtonActived={disabledBtnNext}
        />
      </Container>
    </>
  );
};

export default FillForm;
