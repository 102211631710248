import React from 'react';
import styled from 'styled-components';

import {
  Container,
  HeadingTextContainer,
  GridInformationContainer,
  DocumentList,
  Item,
  ExtraInformation,
  InputGroupContainer,
  NameContainer,
  PhoneContainer,
} from '../steps/GatherDocuments/GatherDocuments.styles';

import {
  Icon,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from '@polichat/flamboyant';

import channelAddWabaClassicModalImage from './channelAddWabaClassicModalImage.svg';
import Illustration from '../../../../../assets/images/illustrations/documentation-step-channel.svg';

const ModalStyled = styled(Modal)`
  .modal-content {
    overflow-y: auto;
    padding: 0;
    border-radius: 1rem !important;
    max-width: 628px;
  }
  .modal-header {
    padding: 0;
    background-color: #fafafa;
    border-radius: 1rem !important;
  }
  .modal-body {
    margin-bottom: 0;
    font-size: 15px;
  }
  .modal-footer {
    margin-top: -38px;
    justify-content: space-evenly !important;
    font-size: 13px;
  }
  button {
    max-width: 200px;
  }
`;

const ExternalLink = styled.a`
  text-decoration: none;
  color: #20EFE5;
`;

const ChannelAddWabaClassicModal = ({
  isOpen,
  handleCloseModal,
  handleProccessClassicNext,
  handleProccessNewNext,
}) => {
  return (
    <ModalStyled isOpen={isOpen} toggle={handleCloseModal}>
      <ModalHeader>
        <img src={channelAddWabaClassicModalImage} />
      </ModalHeader>
      <ModalBody>
        <p>
          Para a análise do negócio no Facebook, vamos precisar de alguns
          documentos que comprovem o nome legal, endereço, representantes e
          telefone.{' '}
          <strong>
            Estas são algumas sugestões. Colete o máximo de documentação que
            conseguir:
          </strong>
        </p>

        <DocumentList>
          <p>
            <Item>
              <Icon icon="poli-icon pi-finish-fill" color="#20EFE5" size={24} />
              <span>Cartão CNPJ</span>
            </Item>
            <Item>
              <Icon icon="poli-icon pi-finish-fill" color="#20EFE5" size={24} />
              <span>Contrato Social</span>
            </Item>
            <Item>
              <Icon icon="poli-icon pi-finish-fill" color="#20EFE5" size={24} />
              <span>Alvará</span>
            </Item>
            <Item>
              <Icon icon="poli-icon pi-finish-fill" color="#20EFE5" size={24} />
              <span>
                Licença da empresa e autorizações do governo local, estadual
                e/ou federal
              </span>
            </Item>
            <Item>
              <Icon icon="poli-icon pi-finish-fill" color="#20EFE5" size={24} />
              <span>
                Relatório de crédito da empresa de uma das agências credenciadas
              </span>
            </Item>
            <Item>
              <Icon icon="poli-icon pi-finish-fill" color="#20EFE5" size={24} />
              <span>Imposto Comercial</span>
            </Item>
            <Item>
              <Icon icon="poli-icon pi-finish-fill" color="#20EFE5" size={24} />
              <span>Conta de telefonia com o número oficial da empresa</span>
            </Item>
            <Item>
              <Icon icon="poli-icon pi-finish-fill" color="#20EFE5" size={24} />
              <span>Conta de água ou energia</span>
            </Item>{' '}
          </p>
        </DocumentList>
      </ModalBody>
      <ModalFooter>
        <p>
          Uma informação muito importante é que para eles a coerência dos dados
          é fundamental. Os endereços tem que ser os mesmos, assim como os nomes
          lá inclusos. Caso não sejam, é preciso ter algo que relacione-os. Por
          exemplo, se o número estiver em um CPF, é preciso estar no CPF do
          representante legal, e aí enviar um documento comprovante este
          vínculo.
        </p>
      </ModalFooter>
    </ModalStyled>
  );
};
export default ChannelAddWabaClassicModal;
