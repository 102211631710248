import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { Controller } from 'react-hook-form';
import {
  Input,
  Label,
  FormGroup,
  Row,
  Col,
  CustomInput,
} from '@polichat/flamboyant';

import { SelectChannel } from '../components/selectChannel';
import { useStep1Form } from './useStep1Form';
import { Step1FormValues } from './useStep1Form/types';
import { useMetaService } from './useMetaService';
import { useChannelService } from './useChannelService';
import Animation from '../../../common/animation';

export interface Step1Ref {
  submit: () => void;
  onClearForm: () => void;
}
export interface Step1Props {
  customer_id: number;
  channel_id?: number;
  onSuccess: (phone_id: string, channel_id: number) => void;
  onError: () => void;
}
export const Step1 = forwardRef<Step1Ref, Step1Props>((props, ref) => {
  const { customer_id, channel_id } = props;

  const { form } = useStep1Form();

  const service = useMetaService({
    customer_id,
  });

  const channelService = useChannelService({
    customer_id,
    channel_id,
  });

  function handleSuccessSubmit(phone_id: string) {
    let _channelID = channel_id ?? parseFloat(form.getValues('channel_id'));
    props.onSuccess(phone_id, _channelID);
  }

  function handleSubmit(data: Step1FormValues) {
    service.migrate(data, handleSuccessSubmit, handleError);
  }

  function handleError(message?: string) {
    props.onError();
    form.setError('business_manager_id', {
      type: 'custom',
      message: message
        ? message
        : 'Oops, não encontramos sua empresa. Confirme o número ou refaça o processo de compartilhar sua empresa conosco na Meta',
    });
  }

  useImperativeHandle(ref, () => ({
    submit: () => {
      form.handleSubmit(handleSubmit)();
    },
    onClearForm: () => {
      form.clearErrors();
      form.resetField('business_manager_id');
    },
  }));

  useEffect(() => {
    if (channelService.channel) {
      form.reset({
        channel_id: channelService.channel.id,
      });
    }
  }, [channelService.channel]);
  // ---------------------------------------------
  // Transformations
  // ---------------------------------------------
  // Render

  return (
    <div style={{ margin: '1rem' }}>
      <h3 style={{ fontWeight: 'bold' }}>
        Conectando ao gerenciador de negócios do Facebook
      </h3>
      <>
        <Row>
          <Col md={6}>
            <FormGroup>
              {props.channel_id ? (
                <Controller
                  control={form.control}
                  name="channel_id"
                  render={({ field, fieldState }) => (
                    <>
                      <Label for="select_channel">Canal para migração</Label>
                      <CustomInput
                        id="select_channel"
                        type="select"
                        disabled={true}
                        value={field.value}
                        onChange={field.onChange}
                      >
                        {channelService.channel && (
                          <option value={channelService.channel.id}>
                            {channelService.channel.name}:{' '}
                            {channelService.channel.phone}
                          </option>
                        )}
                      </CustomInput>
                      <span style={{ color: 'red' }}>
                        {fieldState?.error && fieldState?.error?.message}
                      </span>
                    </>
                  )}
                />
              ) : (
                <Controller
                  control={form.control}
                  name="channel_id"
                  render={({ field, fieldState }) => (
                    <>
                      <SelectChannel
                        customer_id={props.customer_id}
                        onChange={field.onChange}
                        value={field.value}
                      />
                      <span style={{ color: 'red' }}>
                        {fieldState?.error && fieldState?.error?.message}
                      </span>
                    </>
                  )}
                />
              )}
            </FormGroup>
          </Col>

          <Col md={8}>
            <FormGroup>
              <Controller
                control={form.control}
                name="business_manager_id"
                render={({ field, fieldState }) => (
                  <>
                    <Label for="business_manager_id">
                      Número de identificação do Gerenciador de Négocios
                    </Label>
                    <br />
                    <span style={{ fontSize: '0.8rem' }}>
                      Encontre este número aqui:{' '}
                      <a
                        target={'_blank'}
                        href={' https://business.facebook.com/settings/info'}
                      >
                        https://business.facebook.com/settings/info
                      </a>
                    </span>
                    <Input
                      id="business_manager_id"
                      type="number"
                      onChange={field.onChange}
                      value={field.value}
                      error={fieldState?.error}
                    />
                    <div style={{ color: 'red', marginTop: '0.5rem' }}>
                      <span>
                        {fieldState?.error && fieldState?.error?.message}
                      </span>
                    </div>
                  </>
                )}
              />
            </FormGroup>
          </Col>
        </Row>
        <br />
        <Row>
          <Col></Col>
          <Col xs="auto">
            {service.loading && (
              <>
                <Animation icon="loading" size="tinyRem" />
                <span>Verificando o seu Gerenciador de Négocios</span>
              </>
            )}
          </Col>
          <Col></Col>
        </Row>
      </>
    </div>
  );
});
