import React, { useEffect, useState } from 'react';
import { launchWhatsAppSignup } from './launchWhatsAppSignup';
import { sendToken } from './service';
import { Icon, Button } from '@polichat/flamboyant';
import { ButtonOpenFB } from './EmbeddedSignupFlow.styles';
import { Customer } from '../steps/FillForm/FillForm.component';
import { useFlags } from 'flagsmith/react';

export interface ReturnResponse {
  name: string;
  id: string;
  status: string;
  waba_accounts: WabaAccounts[];
}

interface WabaAccounts {
  id: string;
  name: string;
  phone_numbers: PhoneNumbers[];
}

interface PhoneNumbers {
  verified_name: string;
  code_verification_status: string;
  display_phone_number: string;
  quality_rating: string;
  id: string;
}

interface Props {
  setErrorSdkFb: (value: boolean) => void;
  onCallbackSDK: (value: ReturnResponse[]) => void;
  customer?: Customer;
}



const EmbeddedSignupFlow = ({
  setErrorSdkFb,
  onCallbackSDK,
  customer,
}: Props) => {
  const [debugToken, setDebugToken] = useState<string>();

  const requestFromWabaService = !!useFlags(['request_graph_api_from_waba_service'])?.request_graph_api_from_waba_service?.enabled;

  useEffect(() => {
    if (debugToken === 'ERROR') {
      setErrorSdkFb(true);
    } else {
      setErrorSdkFb(false);
    }

    if (debugToken && debugToken !== 'ERROR') {
      sendTokenToBackend(debugToken);
    }
  }, [debugToken]);
  const sendTokenToBackend = async (debugToken: string) => {
    const data = await sendToken(debugToken, requestFromWabaService);
    if (!data) return;

    if (Array.isArray(data)) {
      onCallbackSDK(data);
    }
  };

  return (
    <div>
      <ButtonOpenFB>
        <Button
          size="sm"
          color="info"
          onClick={() => launchWhatsAppSignup(setDebugToken, customer)}
        >
          <Icon icon={'poli-icon pi-facebook-fill'} color="white" size={45} />
          <span>Entrar com Facebook</span>
        </Button>
      </ButtonOpenFB>
    </div>
  );
};

export { EmbeddedSignupFlow };
