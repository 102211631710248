import React, { useRef /*useState*/ } from 'react';
import SubmitButton from '../../../../../common/form/SubmitButton';
import { Icon, Accordion, CollapseMenu } from '@polichat/flamboyant';
import ClickOutsideEvent from '../../../../../../events/ClickOutsideEvent';

import AvailableTags from './AvailableTags';
import ContactTags from './ContactTags';

import { MedalIcon } from '../src/Medal.js'

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ChatActions from '../../../../../../store/modules/chat/actions';

import { MenuTypes } from '../../../../../../store/modules/chat/reducer';
import poliConfig from 'src/utils/poliConfig';

function AddTagModal({ chat, closeTagChatModal, insertTagToContactSubmit, hasTags,currentOrganizationId }) {
  const CUSTOMER = currentOrganizationId
  const wrapperRef = useRef(null);
  ClickOutsideEvent(wrapperRef, closeTagChatModal);

  const tagMenu = chat.view?.menus?.find(
    (menu) => menu.type === MenuTypes.TAG_MENU
  );
  const isLoading = tagMenu?.isLoading;
  const isVisible = tagMenu?.isOpen;
  const contactTags = tagMenu?.contactTags;
  const canSubmit = tagMenu?.contactTags.length > 0;

  // Ao submeter o form
  const onSubmit = () => {
    if (contactTags.length) {
      let data = {
        chat_id: chat.id,
        tags: contactTags,
      };
      insertTagToContactSubmit(data);
    }
  };

  if (isVisible) {
    return (
      <div className="header-menu-modal" id="new-tag-modal" ref={wrapperRef}>
        <div className="header-menu-modal-heading">
          <MedalIcon />
        </div>
        
        <ContactTags />

        <div className="header-menu-modal-subsection">
          <div className="existing-tags-div">
            <Accordion
              id="1"
              principalTitle="Adicionar Etiquetas"
              principalIcon="poli-icon pi-tag-line"
            >
              <div className="content-tags">
                <AvailableTags />
              </div>
            </Accordion>

          </div>
        </div>

        {hasTags ? <div className="header-menu-modal-button">
          <SubmitButton
            loading={isLoading}
            className="header-menu-modal-button-yes"
            onClick={() => {
              onSubmit();
            }}
            title="Adicionar"
            disabled={!canSubmit}
          />
        </div> :
        <div className="header-menu-modal-button">
          <SubmitButton
            loading={isLoading}
            className="header-menu-modal-button-add"
            onClick={() => {
              window.location.assign(`${poliConfig.poliAppLaravelUrl}/settings/${CUSTOMER}/tags`)
            }}
            title="Criar nova Etiqueta"
          />
        </div>}
      </div>
    );
  }

  return <></>;
}

const mapStateToProps = (state) => ({
  chat: state.chat?.chats?.find((chat) => chat.open === true),
  tags: state.chat?.config?.general?.tags,
  hasTags: state.chat?.config?.general?.tags?.length > 0,
  currentOrganizationId: state.general?.current_customer_id,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(ChatActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AddTagModal);
