import styled, { css } from 'styled-components';

const dragActive = css`
  border-color: #78e5d5;
`;

const dragReject = css`
  border-color: #e57878;
`;

export const DropContainer = styled.div.attrs({
  className: 'dropzone',
})`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  border: 2px dashed #b9b9b9;
  border-radius: 5px;
  cursor: pointer;
  height: 100%;

  transition: height 0.2s ease;

  ${(props) => props.isDragActive && dragActive};
  ${(props) => props.isDragReject && dragReject};
`;

const messageColors = {
  default: 'var(--secondary-font-color)',
  error: 'var(--red-polichat)',
  success: 'var(--green-polichat)',
};

export const UploadMessage = styled.p`
  display: flex;
  color: ${(props) => messageColors[props.type || 'default']};
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
  flex-direction: column;

  & > i {
    font-size: 30px !important;
    color: var(--primary) !important;
  }
  & > div {
    color: var(--quartet-font-color) !important;
    font-weight: 500;
    line-height: 120%;
  }
  & > div > span.noDrags {
    color: var(--primary);
    font-size: 16px !important;
    text-decoration: underline;
    line-height: 175%;
    letter-spacing: 0.15px;
  }
  & > div > span.drags {
    color: var(--primary);
    font-size: 16px !important;
  }
`;
