import {
  Accordion,
  Button,
  Collapse,
  CollapseMenu,
  Icon,
  Navbar,
  NavbarToggler
} from '@polichat/flamboyant';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Link,
  Redirect,
  Route,
  Switch,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import history from '../../services/history';

/**
 * Componentes do Shopping/E-commerce
 *  Catalogo: Produtos e Categorias
 */
import CategoryForm from '../../components/shopping/catalog/category/CategoryForm';
import CategoryList from '../../components/shopping/catalog/category/CategoryList';
import ProductForm from '../../components/shopping/catalog/product/ProductForm';
import ProductList from '../../components/shopping/catalog/product/ProductList';
import OrderList from '../../components/shopping/order/OrderList';
import PaymentList from '../../components/shopping/payment/PaymentList';

import PaymentMercadoPago from '../../components/shopping/paymentgateway/gateways/MercadoPago';
import PaymentPagSeguro from '../../components/shopping/paymentgateway/gateways/PagSeguro';
import GatewayPayments from '../../components/shopping/paymentgateway/gatewayslist';

import imageCatalog from '../../assets/images/catalogo.svg';
import imageSettings from '../../assets/images/configuracoes.svg';
import imagePayment from '../../assets/images/pagamentos.svg';
import imageOrder from '../../assets/images/pedidos.svg';
import imagePromotions from '../../assets/images/promocoes.svg';
import imageReports from '../../assets/images/relatorios.svg';

import imageCatalogNewPoli from '../../assets/images/newIdentity/catalogo.svg';
import imageSettingsNewPoli from '../../assets/images/newIdentity/configuracoes.svg';
import imagePaymentNewPoli from '../../assets/images/newIdentity/pagamentos.svg';
import imageOrderNewPoli from '../../assets/images/newIdentity/pedidos.svg';

import Animation from '../../components/common/animation';

import {
  PageContentContainer,
  PageMainContainer,
  PageMenuContainer,
} from '../../components/common/pages/PageContainer/PageContainer.styles';
import PageMainBody from '../../components/common/pages/PageMainBody/PageMainBody.component';
import PageMainHeader from '../../components/common/pages/PageMainHeader/PageMainHeader.component';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as GeneralActions from '../../store/modules/general/actions';

import { useFlags } from 'flagsmith/react';
import { Illustration } from 'src/assets/illustratorElement/illustrators';
import poliConfig from '../../utils/poliConfig';

function Shopping({ currentUser, currentPayStatus, loading }) {
  let { path, url } = useRouteMatch();

  const pathname = window?.location?.pathname;

  const [modalPoliPay, setModalPoliPay] = useState(true);
  const toggleModalPoliPay = () => setModalPoliPay(!modalPoliPay);

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const [collapsed, setCollapsed] = useState(true);
  const toggleNavbar = () => setCollapsed(!collapsed);
  const superRole = currentUser?.superRole;
  const [errorLoadPermissions, setErrorLoadPermissions] = useState(false);
  const [menuActive, setMenuActive] = useState();

  useEffect(() => {
    let _menuAtive = pathname;

    // Shopping
    if (pathname == 'shopping') {
      _menuAtive = '/shopping';
    }

    /**
     * O _menuAtive representa o "id" que esta setado no
     * CollapseMenu. Neste caso os ids são numeros, mas
     * no geral são os pathname da url.
     */

    // Catalog
    if (
      pathname.includes('products') ||
      pathname.includes('categories') ||
      pathname.includes('catalog')
    ) {
      _menuAtive = '0';
    }
    // Order
    if (pathname.includes('orders') || pathname.includes('order')) {
      _menuAtive = '1';
    }
    // Payment
    if (pathname.includes('payments') || pathname.includes('payment')) {
      _menuAtive = '2';
    }
    // Settings
    if (
      pathname.includes('payment-settings') ||
      pathname.includes('settings')
    ) {
      _menuAtive = '3';
    }

    setMenuActive('');
    setMenuActive(_menuAtive);
  }, [pathname, path, url]);

  if (loading) {
    return <Animation size="full" />;
  }
  if (currentUser) {
    return (
      <>
        <PageMainContainer>
          {superRole === 'adm' || superRole === 'manager' ? (
            <>
              <PageMenuContainer>
                <Navbar color="faded" light expand="sm" className="new-sidebar">
                  <NavbarToggler
                    color="primary"
                    onClick={toggleNavbar}
                    className="mr-2"
                  >
                    <Icon size={30} icon="poli-icon pi-menu-mobile-fill" />
                  </NavbarToggler>
                  <Collapse navbar isOpen={!collapsed}>
                    <Accordion defaultActiveKey={menuActive} key={menuActive}>
                      <CollapseMenu
                        id="0"
                        principalTitle="Catálogo"
                        principalIcon="poli-icon pi-catalogo-line"
                        doSomething={() =>
                          history.push({
                            pathname: `${url}/products`,
                          })
                        }
                      >
                        <Link //products
                          className="btn-primary-collapse-itens"
                          // onClick={isMobile && toggleNavbar}
                          to={{
                            pathname: `${url}/products`,
                          }}
                        >
                          <Button
                            color="primary-collapse-itens"
                            className={
                              pathname == `${url}/products` ? 'active' : ''
                            }
                          >
                            <Icon
                              size={18}
                              icon={'poli-icon pi-products-line'}
                            />
                            Produtos
                          </Button>
                        </Link>
                        <Link //categories
                          className="btn-primary-collapse-itens"
                          // onClick={isMobile && toggleNavbar}
                          to={{
                            pathname: `${url}/categories`,
                          }}
                        >
                          <Button
                            color="primary-collapse-itens"
                            className={
                              pathname === `${url}/categories` ? 'active' : ''
                            }
                          >
                            <Icon
                              size={18}
                              icon={'poli-icon pi-category-line'}
                            />
                            Categorias
                          </Button>
                        </Link>
                      </CollapseMenu>

                      <CollapseMenu
                        id="1"
                        principalTitle="Pedidos"
                        principalIcon="poli-icon pi-order-line"
                        doSomething={() =>
                          history.push({
                            pathname: `${url}/orders`,
                          })
                        }
                      >
                        <Link //orders
                          className="btn-primary-collapse-itens"
                          // onClick={isMobile && toggleNavbar}
                          to={{
                            pathname: `${url}/orders`,
                          }}
                        >
                          <Button
                            color="primary-collapse-itens"
                            className={
                              pathname === `${url}/orders` ? 'active' : ''
                            }
                          >
                            <Icon size={18} icon={'poli-icon pi-finish-line'} />
                            Meus Pedidos
                          </Button>
                        </Link>
                      </CollapseMenu>

                      <CollapseMenu
                        id="2"
                        principalTitle="Pagamentos"
                        principalIcon="poli-icon pi-pay-line"
                        doSomething={() =>
                          history.push({
                            pathname: `${url}/payments`,
                          })
                        }
                      >
                        <Link //payments
                          className="btn-primary-collapse-itens"
                          // onClick={isMobile && toggleNavbar}
                          to={{
                            pathname: `${url}/payments`,
                          }}
                        >
                          <Button
                            color="primary-collapse-itens"
                            className={
                              pathname === `${url}/payments` ? 'active' : ''
                            }
                          >
                            <Icon size={18} icon={'poli-icon pi-wallet-line'} />
                            Carteira
                          </Button>
                        </Link>
                      </CollapseMenu>

                      <CollapseMenu
                        id="3"
                        principalTitle="Configurações"
                        principalIcon="poli-icon pi-e-settings-line"
                        doSomething={() =>
                          history.push({
                            pathname: `${url}/payment-settings`,
                          })
                        }
                      >
                        <Link
                          className="btn-primary-collapse-itens"
                          // onClick={isMobile && toggleNavbar}
                          to={{
                            pathname: `${url}/payment-settings`,
                          }}
                        >
                          <Button
                            color="primary-collapse-itens"
                            className={
                              pathname === `${url}/payment-settings`
                                ? 'active'
                                : ''
                            }
                          >
                            <Icon size={18} icon={'poli-icon pi-pay-line'} />
                            Integrações
                          </Button>
                        </Link>
                      </CollapseMenu>
                    </Accordion>
                  </Collapse>
                </Navbar>
              </PageMenuContainer>
              <PageContentContainer>
                <Switch>
                  <Route exact path={path}>
                    <PageMainBody>
                      <WelcomeShopping />
                    </PageMainBody>
                  </Route>
                  <Route path={`${path}/:contentId`}>
                    <Content />
                  </Route>
                </Switch>
              </PageContentContainer>
            </>
          ) : errorLoadPermissions ? (
            <div className="shoppingContentContainer disabledMenu">
              <div className="erroPay">
                <Icon
                  icon={'poli-icon pi-sad-fill'}
                  color="var(--danger)"
                  size={86}
                />
                <p>
                  Ocorreu um erro ao tentar carregar alguns dados. Acesse a
                  página novamente.
                  <br />
                  Caso o erro persista, contate o nosso suporte.
                </p>
              </div>
            </div>
          ) : (
            <div className="shoppingContentContainer disabledMenu">
              <div className="erroPay">
                <Icon
                  icon={'poli-icon pi-sad-fill'}
                  color="var(--danger)"
                  size={86}
                />
                <p>Você não possui permissão para acessar esta página.</p>
              </div>
            </div>
          )}
        </PageMainContainer>
      </>
    );
  }
}

function Content() {
  let { contentId } = useParams();
  let returnComponent = <></>;

  //code to remove when remover feature flag: feature_polipay_mercadopago
  const state_current_customer_id = useSelector(
    (state) => state.general?.current_customer_id
  );

  // eslint-disable-next-line default-case
  switch (contentId) {
    case 'products':
      returnComponent = (
        <>
          <PageMainHeader title="Produtos" icon="poli-icon pi-products-line" />
          <PageMainBody>
            <ProductList />
          </PageMainBody>
        </>
      );
      break;
    case 'product':
      returnComponent = (
        <>
          <PageMainHeader title="Produtos" icon="poli-icon pi-products-line" />
          <PageMainBody>
            <ProductForm />
          </PageMainBody>
        </>
      );
      break;
    case 'categories':
      returnComponent = (
        <>
          <PageMainHeader
            title="Categorias"
            icon="poli-icon pi-products-line"
          />
          <PageMainBody>
            <CategoryList />
          </PageMainBody>
        </>
      );
      break;
    case 'category':
      returnComponent = (
        <>
          <PageMainHeader
            title="Categorias"
            icon="poli-icon pi-products-line"
          />
          <PageMainBody>
            <CategoryForm />
          </PageMainBody>
        </>
      );
      break;
    case 'orders':
      returnComponent = (
        <>
          <PageMainHeader title="Pedidos" icon="poli-icon pi-order-line" />
          <PageMainBody>
            <OrderList />
          </PageMainBody>
        </>
      );

      break;
    case 'payments':
      returnComponent = (
        <>
          <PageMainHeader title="Pagamentos" icon="poli-icon pi-order-line" />
          <PageMainBody>
            <PaymentList />
          </PageMainBody>
        </>
      );

      break;
    case 'catalog':
      returnComponent = (
        <PageMainBody>
          <WelcomeShopping title="catalog" />
        </PageMainBody>
      );
      break;
    case 'order':
      returnComponent = (
        <PageMainBody>
          <WelcomeShopping title="order" />
        </PageMainBody>
      );
      break;
    case 'payment':
      returnComponent = (
        <PageMainBody>
          <WelcomeShopping title="payment" />
        </PageMainBody>
      );
      break;
    case 'promotions':
      returnComponent = (
        <PageMainBody>
          <WelcomeShopping title="promotions" />
        </PageMainBody>
      );
      break;
    case 'reports':
      returnComponent = (
        <PageMainBody>
          <WelcomeShopping title="reports" />
        </PageMainBody>
      );
      break;
    case 'settings':
      returnComponent = (
        <PageMainBody>
          <WelcomeShopping title="settings" />
        </PageMainBody>
      );
      break;
    case 'payment-settings':
      returnComponent = <GatewayPayments />;
      break;
    case 'mercadopago':
      //remove mercadopago if environment is production
      //feature_polipay_mercadopago is false in production
      //in production enable mercadopago if customer is Poli
      returnComponent =
        !poliConfig.feature_polipay_mercadopago &&
        !(
          state_current_customer_id ===
          Number(poliConfig.feature_enable_customer_tester_id)
        ) ? (
          <Redirect to="/shopping" />
        ) : (
          <PaymentMercadoPago />
        );
      break;
    case 'pagseguro':
      returnComponent = <PaymentPagSeguro />;
      break;
    default:
      returnComponent = <Redirect to="/shopping" />;
  }

  return returnComponent;
}

function WelcomeShopping(props) {
  let returnImage = <></>;
  switch (props.title) {
    case 'catalog':
      returnImage = (
        <div className="shoppingScreenImage AnimationImage01">
          <img
            className="img-fluid imagePay"
            src={imageCatalogNewPoli}
          />
          <h2 style={{ marginTop: '45px', lineHeight: '35px' }}>
            {' '}
            Monte seu catálogo e venda seus produtos nas principais plataformas
            <br />
            de comunicação do mundo.
          </h2>{' '}
        </div>
      );
      break;
    case 'order':
      returnImage = (
        <div className="shoppingScreenImage AnimationImage02">
          <img
            className="img-fluid imagePay"
            src={imageOrder}
          />
          <h2 style={{ marginTop: '45px', lineHeight: '35px' }}>
            {' '}
            Veja todos os pedidos realizados em sua conta da Poli.
          </h2>{' '}
        </div>
      );
      break;
    case 'payment':
      returnImage = (
        <div className="shoppingScreenImage AnimationImage03">
          <img
            className="img-fluid imagePay"
            src={imagePayment}
          />
          <h2 style={{ marginTop: '45px', lineHeight: '35px' }}>
            {' '}
            Gerencie os pagamentos recebidos de suas vendas.
          </h2>{' '}
        </div>
      );
      break;
    case 'promotions':
      returnImage = (
        <div className="shoppingScreenImage AnimationImage04">
          <img className="img-fluid imagePay" src={imagePromotions} />
          <h2 style={{ marginTop: '45px', lineHeight: '35px' }}>
            {' '}
            Crie promoções e as gerencie !
          </h2>{' '}
        </div>
      );
      break;
    case 'reports':
      returnImage = (
        <div className="shoppingScreenImage AnimationImage05">
          <img className="img-fluid imagePay" src={imageReports} />
          <h2 style={{ marginTop: '45px', lineHeight: '35px' }}>
            {' '}
            Relatórios da sua loja.
          </h2>{' '}
        </div>
      );
      break;
    case 'settings':
      returnImage = (
        <div className="shoppingScreenImage AnimationImage06">
          <img
            className="img-fluid imagePay"
            src={imageSettingsNewPoli}
          />
          <h2 style={{ marginTop: '45px', lineHeight: '35px' }}>
            {' '}
            Configurações da sua conta.
          </h2>{' '}
        </div>
      );
      break;
    default:
      returnImage = (
        <div className="shoppingScreenImage AnimationImage07">
          <Illustration imgName="documentation-step-channel" />
          <h2 style={{ marginTop: '45px', lineHeight: '35px' }}>
            {' '}
            Bem vindo ao Poli Pay{' '}
          </h2>{' '}
        </div>
      );
  }
  return returnImage;
}

const mapStateToProps = (state) => ({
  currentUser: state?.general?.user,
  loading: state?.general?.user ? false : true,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(GeneralActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Shopping);
