import { useState } from 'react';

import Player from './Player.component';
import { PlayerAudio } from './PlayerAudio/index';
import './Audio.css';
import { dtmToFormatString } from '../../../../utils/dtmToFormatString';
import poliConfig from 'src/utils/poliConfig';
import { useWhiteLabel } from 'src/hooks/useWhiteLabel';

function AudioComponent({
  isLoading,
  urlMedia,
  messageId,
  errorMessage,
  mimeType,
  authorName,
  messageTime, // unix timestamp da mensagem
  urlBase64,
  messageDirection,
  reply_message,
}) {
  const [loading, setLoading] = useState(isLoading);
  const [urlAudio, setUrlAudio] = useState(urlBase64 ? urlBase64 : urlMedia);
  const [typeAudio, setTypeAudio] = useState(mimeType);

  const whitelabel = useWhiteLabel();

  let downloadURL =
    poliConfig.poliAppLaravelUrl + '/downloader?url=' + urlMedia;
  if (authorName || messageTime)
    downloadURL +=
      '&message_body_caption=' +
      (authorName ? authorName + ' ' : '') +
      (messageTime ? dtmToFormatString(messageTime) : '');

  return (
    <div className="container__audio-player" id={messageId} key={loading}>
      {!whitelabel.isWl ? (
        <PlayerAudio
          urlAudio={urlAudio}
          messageId={messageId}
          messageDirection={messageDirection}
          downloadURL={downloadURL}
          reply_message={reply_message}
        />
      ) : (
        <Player
          mimeType={typeAudio}
          soundURL={urlAudio}
          errorMessage={errorMessage}
          isLoading={loading}
          downloadURL={downloadURL}
          hasBase64Url={urlBase64 ? true : false}
        />
      )}
    </div>
  );
}

export default AudioComponent;
