import React from 'react';
import { useState, useEffect } from 'react';
import { Button, Icon, UncontrolledTooltip } from '@polichat/flamboyant';

function ButtonRecordAudio(props) {
  const [hasPermission, setHasPermission] = useState(false);

  useEffect(() => {
    if (!hasPermission) {
      navigator.mediaDevices
        .getUserMedia({ audio: true, video: false })
        .then(() => {
          setHasPermission(true);
        })
        .catch(() => {
          setHasPermission(false);
        });
    }
  }, [hasPermission]);

  if (!props.recording) {
    return (
      /** TODO: remover error button inside button
       * Warning: validateDOMNesting(...):
       * <button> cannot appear as a descendant of <button>
       * */
      <Button
        style={{
          cursor: !hasPermission ? 'not-allowed' : 'pointer',
          background: 'none',
          padding: '0rem',
          border: 'none',
        }}
        id="buttonHandleAudio"
        classname="buttonHandleAudio btn btn-link"
      >
        <Button
          color="link"
          disabled={!hasPermission}
          onClick={() => {
            // Esconder a section de ações
            if (document.querySelector('.groupActions')) {
              document.querySelector('.groupActions').classList.add('hidden');
            }
            // Esconde o container de emojis caso esteja ativo
            if (props.emojiActivated) {
              document.querySelector('.emojiContainer').classList.add('hidden');
            }

            // Esconde o container de mensagens rápidas caso esteja ativo
            if (props.quickMessagesActivated) {
              document
                .querySelector('.quickMessagesContainer')
                .classList.add('hidden');
            }

            props.onChangeRecording(true);
          }}
          className="buttonHandleAudio"
        >
          {!hasPermission && (
            <UncontrolledTooltip placement="top" target={'buttonHandleAudio'}>
              Você precisa dar permissão para gravar áudios.
            </UncontrolledTooltip>
          )}

          <Icon icon={'poli-icon pi-microphone-fill'} size={20} />
        </Button>
      </Button>
    );
  } else {
    return (
      <Button color="link" className="buttonHandleAudio">
        <Icon icon={'poli-icon pi-close-fill'} size={20} />
      </Button>
    );
  }
}

export default ButtonRecordAudio;
