import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Input,
  CustomPhoneInput,
  Icon,
  CustomInput,
} from '@polichat/flamboyant';

import PageMainBody from 'src/components/common/pages/PageMainBody/PageMainBody.component';

import { useChannelCustomerService } from './useChannelCustomerService';
import { Controller } from 'react-hook-form';
import { useLocalForm } from './useLocalForm';
import Animation from 'src/components/common/animation';
import { getLocalFormValuesFromDtoDialog360Channel } from './helpers';
import { toast } from 'react-toastify';

import './index.css';
import MainHeader from 'src/components/common/pages/PageMainHeader/PageMainHeader.component';
import { DeleteChannelModal } from './components/form/deleteChannelModal';
import { UsersAtChannelField } from './components/form/usersAtChannelField';
import { LocalFormValues } from './useLocalForm/types';
import { DtoDialog360Channel } from '../service/DtoDialog360Channel';
import { addChannelToLocalStorage } from '../common/helpers';

export function ChannelDialog360Page() {
  const ONBOARD_STATUS_COMPLETED = 2;
  //@ts-ignore
  const { id } = useParams();
  const history = useHistory();
  const userAdmPoliGetChannelFromCustomerId =
    //@ts-ignore
    history?.location?.state?.customer_id_to_search;

  const customer_id = userAdmPoliGetChannelFromCustomerId
    ? userAdmPoliGetChannelFromCustomerId
    : useSelector(
        //@ts-ignore
        (state) => state.general?.current_customer_id
      );

  const channel_id = id ? id : undefined;

  const service = useChannelCustomerService({ customer_id, channel_id });

  const form = useLocalForm();
  const { reset: formReset } = form;

  const hasChannelOnboardCompleted = useCallback(
    (channel: DtoDialog360Channel) => {
      if (Number(channel?.config?.onboarding360) === ONBOARD_STATUS_COMPLETED) {
        return true;
      }
      return false;
    },
    []
  );

  const handleContinueOnboard = useCallback((channel: DtoDialog360Channel) => {
    addChannelToLocalStorage(channel);
    history.push('/tools/channel/360Dialog/onboard?channel_continue_onboard');
  }, []);

  useEffect(() => {
    if (service.data) {
      if (hasChannelOnboardCompleted(service.data)) {
        formReset(getLocalFormValuesFromDtoDialog360Channel(service.data));
      } else {
        handleContinueOnboard(service.data);
      }
    }
  }, [service.data]);

  useEffect(() => {
    if (service.error.error) {
      toast.error(service.error.msg ?? 'Tente novamente');
    }
  }, [service.error]);

  function handleSuccess() {
    toast.success('Canal editado com sucesso');
    history.push('/tools/channels/list');
  }

  function handleError() {
    toast.error('Error ao editar canal, tente novamente');
  }

  function handleSubmit(data: LocalFormValues) {
    service.edit(data, handleSuccess, handleError);
  }

  return (
    <>
      <MainHeader
        title={'Editar canal'}
        icon="poli-icon pi-channels-line"
        children={null}
        iconSize={20}
      />
      <PageMainBody>
        <div className="container360EditPage">
          {service.loading ? (
            <div className="loadingAnimation">
              <Animation size={100} icon="chat-loading" />
              <span>Buscando informações do seu canal!</span>
            </div>
          ) : (
            <>
              <Row>
                <Col>
                  <form
                    id="edit_channel"
                    onSubmit={form.handleSubmit(handleSubmit)}
                  >
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <Controller
                            control={form.control}
                            name={'name'}
                            render={({ field, fieldState }) => (
                              <>
                                <div className="inputLabelIcon">
                                  <Icon
                                    icon="poli-icon pi-text-fast-fill"
                                    color={'var(--secondary)'}
                                    size="24"
                                  />
                                  <Label for="channel_name">
                                    Nome do canal
                                  </Label>
                                </div>
                                <Input
                                  id="channel_name"
                                  type="text"
                                  value={field.value}
                                  onChange={field.onChange}
                                  onBlur={field.onBlur}
                                  invalid={!!fieldState.error}
                                  disabled
                                />
                              </>
                            )}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Controller
                            control={form.control}
                            name={'phone'}
                            render={({ field, fieldState }) => (
                              <>
                                <div className="inputLabelIcon">
                                  <Icon
                                    icon="poli-icon pi-whatsapp-business-line"
                                    color={'var(--secondary)'}
                                    size="24"
                                  />
                                  <Label for="channel_phone">Telefone</Label>
                                </div>
                                <CustomPhoneInput
                                  id="channel_phone"
                                  value={field.value}
                                  onChange={field.onChange}
                                  country={'br'}
                                  autoFormat={true}
                                  required
                                  placeholder="+55 (62) 9999-9999"
                                  disabled
                                />
                                <span className="inputErrorFeedBack">
                                  * Não é possível alterar o número de telefone
                                  após criado
                                </span>
                              </>
                            )}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <Controller
                            control={form.control}
                            name={'status'}
                            render={({ field }) => (
                              <>
                                <div className="inputLabelIcon">
                                  <Icon
                                    icon="poli-icon pi-channels-fill"
                                    color={'var(--secondary)'}
                                    size="24"
                                  />
                                  <Label for="channel_status">
                                    Status do canal
                                  </Label>
                                </div>
                                <div className="inputSwitchText">
                                  <span>Desativado</span>
                                  <CustomInput
                                    type="switch"
                                    id="channel_status"
                                    color="primary"
                                    checked={field.value}
                                    onClick={field.onChange}
                                  />
                                  <span>Ativado</span>
                                </div>
                              </>
                            )}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Controller
                            control={form.control}
                            name={'display_on_webchat'}
                            render={({ field }) => (
                              <>
                                <div className="inputLabelIcon">
                                  <Icon
                                    icon="poli-icon pi-webchat-fill"
                                    color={'var(--secondary)'}
                                    size="24"
                                  />
                                  <Label for="channel_widget">
                                    Exibir canal no Widget do webchat
                                  </Label>
                                </div>

                                <div className="inputSwitchText">
                                  <span>Não</span>
                                  <CustomInput
                                    type="switch"
                                    id="channel_widget"
                                    color="primary"
                                    checked={field.value}
                                    onClick={field.onChange}
                                  />
                                  <span>Sim</span>
                                </div>
                              </>
                            )}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Controller
                            control={form.control}
                            name={'users'}
                            render={({ field }) => (
                              <>
                                <UsersAtChannelField
                                  customer_id={customer_id}
                                  value={field.value}
                                  onChange={field.onChange}
                                />
                              </>
                            )}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </form>
                </Col>
              </Row>
              <br />
              <Row>
                <Col
                  sm={{
                    offset: 2,
                    size: 4,
                  }}
                >
                  <DeleteChannelModal
                    channel_id={service.data?.id!}
                    customer_id={service.data?.customer_id!}
                    channel_name={service.data?.name!}
                  />
                </Col>
                <Col
                  sm={{
                    offset: 2,
                    size: 4,
                  }}
                >
                  <Button
                    type="submit"
                    form="edit_channel"
                    primary
                    width="half"
                    disabled={service.error.error || service.saveLoading}
                  >
                    {!service.saveLoading ? 'Salvar' : 'Salvando...'}
                  </Button>
                </Col>
              </Row>
            </>
          )}
        </div>
      </PageMainBody>
    </>
  );
}
