import { toast } from 'react-toastify';
import {
  put,
  all,
  takeLatest,
  select,
  call,
  takeEvery,
} from 'redux-saga/effects';

import store from '../../../store';

import {
  actionNames,
  startVisitorsServiceFailure,
  storeFromVisitorFailure,
} from './actions';

function* startVisitorsService() {
  try {
    const wsState = (state) => state.chat.socket;
    const websocket = yield select(wsState);
    yield websocket.sendMessage(
      JSON.stringify({
        subject: 'start_visitors_service',
        data: {},
      })
    );
  } catch (_) {
    store.dispatch(startVisitorsServiceFailure());
    // console.error(e);
  }
}

function* storeFromVisitor(event) {
  // setTimeout(() => {
  //   store.dispatch(storeFromVisitorFailure(event.payload));
  // }, 3000);
  // return null;
  try {
    const wsState = (state) => state.chat.socket;
    const websocket = yield select(wsState);
    yield websocket.sendMessage(
      JSON.stringify({
        subject: 'store_from_visitor',
        data: event.payload,
      })
    );
  } catch (_) {
    store.dispatch(storeFromVisitorFailure(event.payload));
    // console.error(e);
  }
}

export default all([
  // visitors
  takeEvery(actionNames.START_VISITORS_SERVICE, startVisitorsService),
  takeLatest(actionNames.STORE_FROM_VISITOR, storeFromVisitor),
]);
