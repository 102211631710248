import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Switch from 'react-switch';

import { Day, Night } from '@poli-ink/react';

function DarkMode() {
  const [dark, setDark] = useState(false);
  let checked = dark;

  // Avoid forced dark mode if is White Label.
  const isWhiteLabel = useSelector(
    (state) => state?.whitelabel?.config?.is_white_label
  );

  if (!isWhiteLabel) {
    if (!localStorage.getItem('@polichat/darkmode')) {
      if (
        window.matchMedia &&
        window.matchMedia('(prefers-color-scheme: dark)').matches
      ) {
        localStorage.setItem('@polichat/darkmode', true);
      }
    }

    if (localStorage.getItem('@polichat/darkmode') === 'true') {
      checked = true;
      document.body.classList.add('darkMode');

      // Se o usuário tiver o modo escuro ativado no browser ou SO.
      if (
        window.matchMedia &&
        window.matchMedia('(prefers-color-scheme: dark)').matches
      ) {
        checked = true;
      }
    }
  }

  return (
    <label htmlFor="material-switch" className="darkModeTopNav">
      <span>
        <Day width="16" height="16" />
      </span>
      <Switch
        onChange={() => {
          const rootElement = document.body;
          if (!dark) {
            localStorage.setItem('@polichat/darkmode', true);
          } else {
            localStorage.setItem('@polichat/darkmode', false);
            rootElement.classList.remove('darkMode');
          }

          setDark(!dark);
        }}
        checked={checked}
        onColor={'#242b3d'}
        onHandleColor={'#6666DE'}
        handleDiameter={20}
        uncheckedIcon={false}
        checkedIcon={false}
        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
        height={20}
        width={35}
        className="react-switch"
        id="material-switch"
      />
      <span>
        <Night width="16" height="16" />
      </span>
    </label>
  );
}

export default DarkMode;
