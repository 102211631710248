import { Row, Icon, Alert } from '@polichat/flamboyant';
import { useState } from 'react';
import { useLocalForm } from '../shared/components/FiltersForm/useLocalForm';
import { Table } from './components/Table';
import { laravelAxios } from 'src/config/axios/laravelAxios';
import { OrdinationProps } from '../shared/types/Ordination';
import { PaginationProps } from '../shared/types/Pagination';
import { DEFAULT_ORDINATION_CONFIG } from './components/Table/config';
import { DEFAULT_PAGINATION_CONFIG } from '../shared/components/Pagination/config';
import { FiltersForm } from '../shared/components/FiltersForm';
import { OperatorsEfficiencyResponse } from './types/OperatorsEfficiencyResponse';
import { ContainerStyle } from '../shared/styles';
import TablePagination from '../shared/components/Pagination';
import { EmptyData } from '../shared/components/EmptyData';
import { SubMenuWelcome } from '../shared/components/SubMenuWelcome';
import { ReportsFiltersInterface } from '../shared/types/ReportsFiltersTypes';
import { useSelector } from 'react-redux';
import { toast } from "react-toastify";
import exportsAPI from "src/services/tools/reports/export-reports";
import { TypeFiltersProps } from '../shared/types/Filters';
import { userRoleValidator } from '../shared/utils/userRoleValidator';

export function OperatorsEfficiency({ filters, error, onError: setError }: ReportsFiltersInterface): JSX.Element {
  const localForm = useLocalForm();

  const [successExports, setSuccessExports] = useState(false);
  const [errorExports, setErrorExports] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingExports, setLoadingExports] = useState(false);
  const [pagination, setPagination] = useState<PaginationProps | undefined>(DEFAULT_PAGINATION_CONFIG)
  const [operatorsEfficiency, setOperatorsEfficiency] = useState<OperatorsEfficiencyResponse | undefined>(undefined);
  const [ordination, setOrdination] = useState<OrdinationProps>(DEFAULT_ORDINATION_CONFIG);

  function formatFormValues(data: any) {
    return {
      ...data,
      department_id: data.department.value,
      channel_id: data.channel.value,
      operator_id: data.operator.value,
      ad_id: data.ad.value
    };
  }

  async function onSubmit(data?: any) {
    setPagination(undefined);
    setOperatorsEfficiency(undefined);
    
    await fetchOperatorsEfficiency({
      ...formatFormValues(data),
      ...ordination
    });
  }

  async function onPageChange(page: any) {
    await fetchOperatorsEfficiency({ 
      ...formatFormValues(localForm.getValues()),
      page: page.currentPage,
      per_page: page.limitItens,
      order_by: ordination.order_by,
      order: ordination.order
    });
  }

  async function onChangeOrdination(ordinationProps: OrdinationProps) {
    setOrdination(ordinationProps);

    await fetchOperatorsEfficiency({ 
      ...formatFormValues(localForm.getValues()),
      page: pagination?.current_page,
      per_page: pagination?.records_per_page,
      order_by: ordinationProps.order_by,
      order: ordinationProps.order
    });
  }

  async function  fetchOperatorsEfficiency(params: any)
  {
    setError(false);
    setLoading(true);
    setErrorExports(false);
    setSuccessExports(false);

    try {
      const { data: operatorsEfficiency } = await laravelAxios.get('/report-v2/operators/efficiency', { params });
      const { current_page, records_per_page, total_pages, total_records } = operatorsEfficiency;

      setOperatorsEfficiency(operatorsEfficiency);
      setPagination({ current_page, total_pages, total_records, records_per_page });
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  }

  const getUserRole = useSelector((state: any) => state.chat?.config?.user?.superRole)
  const isManager = userRoleValidator(getUserRole)

  async function requestExport(filters: TypeFiltersProps) {
    setErrorExports(false);
    setSuccessExports(false);
    setError(false);
    setLoadingExports(true);


    try {
      await exportsAPI.requestExport(filters)

      setSuccessExports(true);
    } catch (error) {
      setErrorExports(true);
    } finally {
      setLoadingExports(false);
    }
  }

  return (
    <ContainerStyle>
      <FiltersForm
        form={localForm}
        onSubmit={onSubmit}
        filters={filters}
        requestExport={requestExport}
        disableSubmit={loading}
        loadingExports={ loadingExports }
        disableExport={!isManager}
      />

      { ((operatorsEfficiency?.total_records ?? 0) !== 0 || loading)
        && <Row className="rowTitle" style={{ margin: 0, marginBottom: '1.6rem', marginTop: '4.5rem', boxShadow: 'none' }}>
            <Icon
              icon={`poli-icon pi-operator-line`}
              size={25}
              color="var(--principal-icons-color)"
              position={'relative'}
            />
            <span>Eficiência dos Operadores</span>
          </Row>
      }

      { successExports &&
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Alert color='success' style={{ display: 'inline' }}>Exportação de relatório foi solicitada com êxito!</Alert>
        </div>
      }

      {errorExports &&
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Alert color='danger' style={{ display: 'inline' }}>Houve um erro ao solicitar a exportação. <br/>Recarregue a página e tente novamente. Caso o problema persista contate o administrador do sistema.</Alert>
        </div> 
      }

      { error 
        && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Alert color='danger' style={{ display: 'inline' }}>Houve um erro ao consultar as informações. Recarregue a página e tente novamente. Caso o problema persista contate o administrador do sistema.</Alert>
        </div>
      }

      { operatorsEfficiency === undefined && !loading && <SubMenuWelcome /> }

      { operatorsEfficiency?.total_records === 0  && !loading && <EmptyData /> }

      {
        (operatorsEfficiency !== undefined || loading)
        && operatorsEfficiency?.total_records !== 0 
        && <>
            <Table
              filters={filters}
              records={ operatorsEfficiency?.data }
              resume={ operatorsEfficiency?.resume }
              loading={ loading }
              ordinationProps={ ordination }
              onOrdinationChange={ onChangeOrdination }
            />
            <div>
              {pagination && (
                <TablePagination
                  totalItens={ pagination.total_records }
                  onPageChange={onPageChange}
                />
              )}
            </div>
          </>
      }      
    </ContainerStyle >
  )
}
