import React, { useEffect, useState } from 'react';
import FilterModalTemplate from './FilterModalTemplate';
import Animation from '../../../../common/animation';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ChatActions from '../../../../../store/modules/chat/actions';
import { convertActiveTabInScreen } from '../../../../../store/modules/chat/functions';

import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from '@polichat/flamboyant';

function DepartmentsModal({
  departments,
  isDepartmentsMenuOpen,
  toggleFilterMenu,
  activeFilters,
  submitFilter,
  loading,
  clearFilter,
  allDepartments,
  currentUser,
  currentTabNavigation
}) {
  const filter_type_name = 'departments';
  const filter_title = 'Filtrar por Departamento';
  const filter_icon = 'poli-icon pi-folder-line';
  const currentScreen = convertActiveTabInScreen(currentTabNavigation);

  /**
   * Administrador Geral e Gestor pode ver todos os departamentos
   * Supervisor poderá ver apenas o departamento que atua.
   * o roleCurrentUser verifica qual é o nível do usuário.
   */
  const roleCurrentUser = () => {
    if (currentUser && currentUser.superRole && allDepartments && departments) {
      if (currentUser.superRole === 'adm' || currentUser.superRole === 'manager') {
        return allDepartments;
      }
      else {
        return departments;
      }

    } else {
      return [];
    }

  }

  const arrayDepartments = roleCurrentUser();

  let content;
  let departments_select = [];
  let enabled = true;



  function verifyCurrentFilter () {
    if (!activeFilters.length) {
      setFilter('')
    }
  }

  const findCurrentDepartmentFilter = () => {
    let active_filter = '';

    if (activeFilters.length) {
      activeFilters.forEach((filter) => {
        if (filter.type === filter_type_name) {
          active_filter = filter.department_id;
        }
      });
    }

    return active_filter;
  };

  const [filter, setFilter] = useState(findCurrentDepartmentFilter());
  const selectedDepartmentName = arrayDepartments.map((depto) => {
    // tem que ser apenas dois iguais
    if (depto.id == filter) {
      return depto.name;
    }
  });

  const findName = (id) => {
    verifyCurrentFilter()
    let department = arrayDepartments.find((department) => department.id === id);

    if (department) {
      if (department.name) {
        return department.name;
      } else {
        return '';
      }
    } else {
      return '';
    }
  };

  const onSubmitEvent = () => {
    let payload = Object.assign({}, activeFilters);

    if (filter) {
      let value = parseInt(filter);
      let name = findName(value);

      payload.department = [
        {
          name: name,
          value: value,
        },
      ];

      submitFilter(payload, currentScreen);
    } else {
      payload.department = [];
      clearFilter(payload);
    }

    toggleFilterMenu(filter_type_name);
  };

  const sortByName = (a, b) => {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  };

  if (arrayDepartments && arrayDepartments.length) {
    departments_select = arrayDepartments
      .sort(sortByName)
      .map((department, index) => {
        return (
          <DropdownItem
            key={`${department.id}+${index}`}
            value={department.id}
            className="dropItem-filters"
            onClick={(e) => setFilter(e.target.value)}
          >
            {department.name}
          </DropdownItem>
        );
      });
    const notDepartment = (
     <React.Fragment key={-1}>
      <DropdownItem
        key={-1}
        value={-1}
        className="dropItem-filters"
        onClick={(e) => setFilter(e.target.value)}
      >
        Sem Departamento
      </DropdownItem>
        <hr></hr>
      </React.Fragment>
    );
    departments_select.unshift(notDepartment);
    content = (
      <UncontrolledDropdown className="dropDown-filters">
        <DropdownToggle
          color="neutral"
          caret
          id="departmentsFilter"
          name="departmentsFilter"
          className="total dropDownToggle-filters"
        >
          <span>
            {filter != '' && filter != -1 && selectedDepartmentName
              ? selectedDepartmentName
              : filter == -1
              ? 'Sem Departamento'
              : 'Selecione um Departamento'}
          </span>
        </DropdownToggle>
        <DropdownMenu className="dropDownMenu-filters">
          {departments_select}
        </DropdownMenu>
      </UncontrolledDropdown>
    );

    enabled = true;
  } else {
    enabled = false;
    content = <Animation icon="loading" size="tiny" />;
    if (allDepartments && allDepartments.length === 0) {
      content = <span className="noDepartments">Não existem departamentos cadastrados.</span>
    }

  }

  return (
    <FilterModalTemplate
      icon={filter_icon}
      title={filter_title}
      content={content}
      isOpen={isDepartmentsMenuOpen}
      toggleEvent={() => toggleFilterMenu(filter_type_name)}
      onSubmit={onSubmitEvent}
      loading={loading}
      disabled={!enabled}
    />
  );
}

const mapStateToProps = (state) => ({
  currentUser: state.chat?.config?.user,
  departments: state.chat?.config?.user?.departments,
  allDepartments: state.chat?.config?.general?.departments,
  isDepartmentsMenuOpen: state.chat?.filters?.view?.isDepartmentsMenuOpen,
  activeFilters: state.chat?.filters?.activeFilters,
  loading: state.chat?.filters?.loading,
  currentTabNavigation: state.chat?.config?.activeTabNavigation,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(ChatActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DepartmentsModal);
