import { laravelAxios } from '../../../../config/axios/laravelAxios';
import {
  GetCustomerChannelLimitParams,
  PostBuyAdditionalChannelsParams,
} from './types';

import { DtoVerifyChannelList, DtoHireAdditionalChannel } from './dto';

export class additionalPlans {
  static async getCustomerHasChannelLimit(
    params: GetCustomerChannelLimitParams
  ) {
    const url = `verifyLimitSpa/${params.customer_id}/${params.additional_id}`;
    const request = await laravelAxios.get<DtoVerifyChannelList>(url);
    return request.data;
  }

  static async postBuyAdditionalChannel(
    params: PostBuyAdditionalChannelsParams
  ) {
    const url = `buyAdditionalSpa`;
    const request = await laravelAxios.post<DtoHireAdditionalChannel>(
      url,
      params
    );
    return request.data;
  }
}
