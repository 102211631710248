import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import history from '../../../../services/history';
import PageMainHeader from '../../../common/pages/PageMainHeader/PageMainHeader.component';
import PageMainBody from '../../../common/pages/PageMainBody/PageMainBody.component';

import ProgressBar from './ProgressBar/ProgressBar.component';

import FillForm from './steps/FillForm/FillForm.component';
import SendDocs from './steps/SendDocs/SendDocs.component';
import FacebookAnalysis from './steps/FacebookAnalysis/FacebookAnalysis.component';
import Migration from './steps/Migration/Migration.component';
import Token from './steps/Token/Token.component';
import Profile from './steps/Profile/Profile.component';

import { Container } from './Certification.styles';

const Certification = ({ currentStep, data, dataSet }) => {
  let content = <></>;

  const processClassic = data.config?.classic;

  switch (currentStep) {
    case 2:
      content = (
        <FillForm data={data} dataSet={dataSet} currentStep={currentStep} />
      );
      break;

    case 3:
      content = processClassic ? (
        <SendDocs data={data} dataSet={dataSet} currentStep={currentStep} />
      ) : (
        <Token data={data} dataSet={dataSet} currentStep={currentStep} />
      );
      break;

    case 4:
      content = processClassic ? (
        <FacebookAnalysis
          data={data}
          dataSet={dataSet}
          currentStep={currentStep}
        />
      ) : (
        <Profile data={data} dataSet={dataSet} currentStep={currentStep} />
      );
      break;

    case 5:
      content = processClassic ? (
        <Migration data={data} dataSet={dataSet} currentStep={currentStep} />
      ) : (
        <Profile data={data} dataSet={dataSet} currentStep={currentStep} />
      );
      break;

    case 6:
      content = (
        <Token data={data} dataSet={dataSet} currentStep={currentStep} />
      );
      break;

    case 7:
      content = (
        <Profile data={data} dataSet={dataSet} currentStep={currentStep} />
      );
      break;

    default:
      break;
  }

  return (
    <>
      <PageMainHeader
        title="Adicionando Canal"
        icon="poli-icon pi-channels-line"
      />

      <PageMainBody>
        <Container>
          <ProgressBar
            classic={data?.config?.classic}
            currentStep={currentStep}
          />
          {content}
        </Container>
      </PageMainBody>
    </>
  );
};

export default Certification;
