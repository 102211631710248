import React, { useState, useCallback, useEffect } from 'react';
import gatewayListConfig from '../../../../../services/shopping/payment/gatewayListConfig';
import { toast } from 'react-toastify';

const usePaymentCard = (props) => {
  const [status, setStatus] = useState(props.status ?? false);
  const mainMethod = props.mainMethod ?? false;

  /**
   * Quando o pai mudar o status
   */
  useEffect(() => {
    if (props.status != status) {
      setStatus(props.status);
    }
  }, [props.status]);
  /* */

  /**
   * Mudando estado do metodo padrao
   * permitir mudar status se o mesmo ja tiver integração configurado.
   */
  const onMainMethod = useCallback(
    (e) => {
      if (props.id) {
        const newValue = e.target.checked;
        /**
         * Valor true so vai ser definido ao selecionar como principal, não pode remover o unico principal
         * (somente o pai que pode colocar como false)
         * Avisar para o pai o qual metodo de pagamento principal
         */
        if (newValue) {
          props.onMainMethod(props.paymentMethod);
        } else {
          props.onMainMethod(props.paymentMethod, true); //remover o principal
        }
      }
    },
    [props.id]
  );
  /* end */

  /**
   * Enviar para o back-end o status do gateway de pagamento
   *  @function storeGatewayDefault
   *  @param {number} id id do gateway de pagamento
   */

  const storeGatewayStatus = useCallback(async (id, status) => {
    try {
      const params = { status: status ? 1 : 0 };

      const result = await gatewayListConfig.storeStatus(id, params);
      if (result && result.data) {
        return result.data;
      }
    } catch (err) {
      console.error('err:', err);
      toast.error(
        'Houve um erro ao salvar. Caso o erro persista, entre em contato com o suporte.'
      );
    }

    return null;
  });
  /* End */

  /**
   * Mudando estado do Status
   * permitir mudar status se o mesmo ja tiver integração configurado.
   */
  const onStatus = useCallback(
    (e) => {
      if (props.id) {
        const newValue = e.target.checked;
        setStatus(newValue);

        storeGatewayStatus(props.id, newValue);

        if (newValue === false) {
          props.onMainMethod(props.paymentMethod, true); //remover o principal
        }
      }
    },
    [props.id]
  );
  /* end */

  return {
    status,
    mainMethod,
    onMainMethod,
    onStatus,
  };
};

export default usePaymentCard;
