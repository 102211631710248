import { Col, FormGroup, Input, Label, Row } from '@polichat/flamboyant';
import { useState } from 'react';
import {
  LabelWithSpan,
  OptionsContainer,
} from '../../FormMessagesBuilder.styles';

function CreateButtonList({ onUpdate, initialValue, isEdit }) {
  const [buttonData, setButtonData] = useState(initialValue);

  const handleInputChange = ({ target: { name, value } }) => {
    setButtonData({
      ...buttonData,
      [name]: value,
    });
    handleButtonData({
      ...buttonData,
      [name]: value,
    });
  };

  const handleButtonData = (data) => {
    onUpdate(data);
  };

  return (
    <Row>
      <Col sm={12}>
        <FormGroup>
          <LabelWithSpan>
            <Label>
              Botão:{'  '}
              <span>
                Adicione um título para o seu botão, exemplo : "Toque Aqui"{' '}
              </span>
            </Label>
          </LabelWithSpan>
          <Input
            type="text"
            id="button-message"
            name="text"
            placeholder="Texto do botão"
            value={buttonData.text}
            onChange={handleInputChange}
            showCharCounter
            maxLength={20}
            disabled={isEdit}
            required
          />
          <OptionsContainer></OptionsContainer>
        </FormGroup>
      </Col>
    </Row>
  );
}

export default CreateButtonList;
