import { Company } from '@poli-ink/react';
import { Button, Icon } from '@polichat/flamboyant';
import { useContext, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalContext } from 'src/context/globalContext';
import checkAvatar from 'src/utils/checkAvatar';
import { getCompanyOfContact } from '../../../../utils/crm/index';
import {
  showBlockTwo,
  showDetailsComponent,
  openTagInDetails,
} from '../../../../store/modules/chat/actions';
import Avatar from '../../../common/profile/Avatar';

import listCompanies from '../../../tools/integrations/crmRequests/listCompanies';
import listCompanyByContact from '../../../tools/integrations/crmRequests/listCompanyByContact';

function ContactProfile(props) {
  const contactId = props.id;
  // Context
  const { pictureGlobal } = useContext(GlobalContext);
  // const [picture, setPicture] = useState();
  // useEffect(() => {
  //   if (pictureGlobal && pictureGlobal !== 'null') {
  //     setPicture(pictureGlobal);
  //   } else {
  //     setPicture(props.avatarURL);
  //   }
  // }, [pictureGlobal, props.avatarURL]);
  const dispatch = useDispatch();

  const [contactOrganization, setContactOrganization] = useState('');

  const customerId = useSelector(
    (state) => state.general?.current_customer_id,
  );

  const fetchCompaniesList = async () => {
    try {
      if (customerId) {
        const response = await listCompanyByContact(contactId);

        if (response?.data) {
          const company = response.data || [];
          if (company?.length) {
            if (company[0]?.name) {
              setContactOrganization(company[0].name);
            }
          }
        }
      }
    } catch (error) {}
  };

  fetchCompaniesList();

  return (
    <div className="contactProfile">
      <div className="closeDetails">
        <Button
          color="link"
          onClick={() => {
            isMobile && dispatch(showBlockTwo());
            dispatch(showDetailsComponent(false));
            dispatch(openTagInDetails(false));
          }}
        >
          <Icon icon="poli-icon pi-close-fill" />
        </Button>
      </div>
      <div style={{
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center',
          flexDirection: 'row',
        gap: '1rem'
      }}>
        <div className="centerAvatar">
          <Avatar
            className="contact"
            title={props.name}
          url={pictureGlobal
              ? pictureGlobal
              : checkAvatar(props.avatarURL)}
            size={'100%'}
            onClick={props.onClick}
          />
        </div>
        <div className="grouped">
          <span id="contactProfilePhone">
          <strong>{props.name} | {contactOrganization}</strong>
            <br />
            {props.phone}
          <br/>
        <Company/> {contactOrganization}
          </span>

        </div>

      </div>
    </div>
  );
}

export default ContactProfile;

// className="contactProfilePhone"
