import React, { useState } from 'react';
import { Button, Icon } from '@polichat/flamboyant';
import DeleteChannelModal from '../../channels/DeleteChannelModal/DeleteChannelModal.component';
import { ChannelsDeleteService } from '../../../../services/tools/channels/index';
import { toast } from 'react-toastify';
import history from '../../../../../src/services/history';
import { ButtonContainer } from './DeleteChannel.styles';

export default function ButtonDeleteChannel({channel,customer_id,size}) {

const [showDeleteChannel, setShowDeleteChannel] = useState(false);
const [isLoadingDeleteRequest, setIsLoadingDeleteRequest] = useState(false);

const handleDeleteChannel = async () => {
    // setShowDeleteChannel(!showDeleteChannel);
    try {
      setIsLoadingDeleteRequest(true);
      const channel_id = channel.id;
      const result = await ChannelsDeleteService.deleteChannel({
        channel_customer_id: channel_id,
        customer_id
      });
      console.log('result deltes::', result);
      if (result.success) {
        toast.success('Canal excluido!');
        history.push('/tools/channels/list');
      } else throw 'Requisição mal sucedida';
    } catch (error) {
      toast.error(
        <div>
          Erro ao excluir canal, tente novamente!
          <br />
          Se erro persistir entre em contato com o suporte.
        </div>
      );
      console.error('Erro encontrado ao tentar deletar canal: ', error);
    } finally {
      setIsLoadingDeleteRequest(false);
    }
  };
return (
    <>
<ButtonContainer>
    <Button
        size={size}
        color="danger"
        onClick={() => setShowDeleteChannel(!showDeleteChannel)}>
    <Icon
      icon="poli-icon pi-delete-fill"
      color="#FFFFFF"
      size="15"
    />
    {'  '}Excluir canal
    </Button>
    </ButtonContainer>
  
    <DeleteChannelModal
        isOpen={showDeleteChannel}
        toggle={() => setShowDeleteChannel(!showDeleteChannel)}
        channelData={channel}
        handleDeleteChannel={handleDeleteChannel}
        isLoading={isLoadingDeleteRequest}
    />
    </>)
}
  
  