import React from 'react';
import './styles.css';
import { Icon } from '@polichat/flamboyant';
import { connect, useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';

function ContactsStartContent({ chatConfig }) {
  let show;
  const mobile = isMobile && useSelector((state) => state.chat.mobile);

  // Verifica que o conteúdo de Adicionar contato ou Importar Contato estão abertos
  // Se um deles estiver com estado de aberto o Start Content não aparecerá
  if (chatConfig) {
    if (
      chatConfig.openAddContact ||
      chatConfig.openImportContact ||
      chatConfig.openEditContact
    ) {
      show = false;
    } else {
      show = true;
    }
  }
  if (show) {
    return (
      <div className={`startContent ${isMobile && (mobile?.blockTwo ? '' : 'hidden')}`}>
        <div className="itemStartContent">
          <figure className="item itemStartContent">
            <div className="circulo">
              <Icon
                icon={'poli-icon pi-perfil-line'}
                size={250}
                color="var(--green-polichat"
              />
            </div>
            <h4>
              Aqui você pode adicionar ou importar contatos para sua conta da Poli.
            </h4>
          </figure>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
}

const mapStateToProps = (state) => ({
  chatConfig: state.chat?.config,
  openAddContact: state.chat?.config?.openAddContact,
  openImportContact: state.chat?.config?.openImportContact,
  contacts: state.chat.contacts,
});

export default connect(mapStateToProps)(ContactsStartContent);
