import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { QuickMessage } from '../../types/types';
import { useQuickMessage } from '../useQuickMessage';
export function useGetQuickMessageFromShortcut() {
  const quickMessages = useSelector(
    (state: any) => state?.chat?.quickMessages?.messages
  ) as QuickMessage[];

  const filteredQuickMessagesWithShortcuts = useMemo(() => {
    return quickMessages?.filter(
      (messages: QuickMessage) => !!messages.keyboard_shortcut
    );
  }, [quickMessages]);

  const { getMessageTreated } = useQuickMessage();

  const findQuickMessageWithShortcut = useCallback(
    (shortcut: string) => {
      let foundedMessage = filteredQuickMessagesWithShortcuts?.find(
        (message) =>
          message.keyboard_shortcut.toLowerCase() === shortcut.toLowerCase() ||
          message.keyboard_shortcut.replace(/^Ctrl\+/i, '') ===
            shortcut.toLowerCase()
      );

      if (foundedMessage) {
        const result = getMessageTreated(foundedMessage);
        return result;
      }

      return null;
    },
    [filteredQuickMessagesWithShortcuts]
  );

  return {
    findQuickMessageWithShortcut,
  };
}
