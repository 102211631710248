import React from 'react';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Icon
} from '@polichat/flamboyant';

import Avatar from '../../../profile/Avatar';
import checkAvatar from '../../../../../utils/checkAvatar';

import { connect } from 'react-redux';
import poliConfig from '../../../../../utils/poliConfig';

function Organizations({ user, currentOrganizationId }) {
  if (user && currentOrganizationId) {
    const customers = user.customers;
    const currentOrganization = customers.find(
      (customer) => customer.id === currentOrganizationId
    );

    return (
      <div className="organizationsTopNav">
        <UncontrolledDropdown className="change-organizations">
          <DropdownToggle
            color="neutral"
            caret
            id="organizations"
            name="organizations"
            value={currentOrganizationId}
          >
            <Icon icon={'poli-icon pi-company-line'} size={20} />
            <span className="nameorganization">
              {currentOrganization
                ? currentOrganization?.name
                : 'Selecione uma empresa'}
            </span>
          </DropdownToggle>

          <DropdownMenu className="organizationsContainer">
            {customers.map((customer, index) => {
              const handleChangeOrganization = () => {
                return (window.location.href = `${poliConfig.poliAppLaravelUrl}/spa/select-company/${customer.id}?url=${window.location.origin}${window.location.pathname}`);
              };
              return (
                <div
                  className="organizationDiv"
                  style={{ width: '274px' }}
                  key={index}
                >
                  <DropdownItem
                    key={index}
                    className="organizationsOptions"
                    value={customer.id}
                    onClick={handleChangeOrganization}
                  >
                    <Avatar
                      className="operator"
                      title={customer.name}
                      url={checkAvatar(customer.name, customer.photo)}
                      size={30}
                    />
                    <span className="organizationsName">{customer.name}</span>
                  </DropdownItem>
                </div>
              );
            })}
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
    );
  }

  return <></>;
}

const mapStateToProps = (state, ownProps) => ({
  user: state.chat?.config?.user,
  currentOrganizationId: state.general?.current_customer_id,
  props: ownProps,
});

export default connect(mapStateToProps)(Organizations);
