import { Alert, Icon } from '@polichat/flamboyant';
import { ConnectButton } from '360dialog-connect-button';
import './index.css';

import Dialog360Logo from 'src/assets/images/illustrations/Dialog360.svg';
import PoliLogo from 'src/assets/images/polichat-icon.svg';

import InfoCard from '../../../../channels/CertificationWabaByPoli/InfoCard/InfoCard.component';
import {
  Container,
  FourColumnsGrid,
  CardsContainer,
  TextInfos,
} from '../../../../channels/CertificationWabaByPoli/steps/FillForm/FillForm.styles';
import Animation from 'src/components/common/animation';
export interface Step2Props {
  phone_number: string;
  channel_id: number;
  loading?: boolean;
}
//https://docs.360dialog.com/partner/integration/integrated-onboarding-setup
export function Step2(props: Step2Props) {
  const PARTNER_ID = process.env?.REACT_APP_PARTNER_360_ID ?? null;
  const SPA_DOMAIN =
    process.env?.REACT_APP_SPA_DOMAIN ?? 'https://app-spa.domain.local';

  function removeTheFirstNineNumber(phone_number: string): string | undefined {
    if (!phone_number) return undefined;

    if (phone_number.length !== 13) return phone_number;

    return phone_number.slice(0, 4) + phone_number.slice(5);
  }

  const handleCallback = (callbackObject: any) => {
    /* The callback function returns the client ID as well as all channel IDs, for which you're enabled to fetch the API key via the Partner API */
    console.log('client ID: ' + callbackObject.client);
    console.log('channel IDs: ' + callbackObject.channels);
    if (callbackObject.revokedChannels) {
      console.log('Revoked Channel IDs: ' + callbackObject.revokedChannels);
    }
    // ?client=oaY9LLfUCL&channels=[y9MiLoCH]
  };

  return (
    <div className="container360OnboardPage">
      <div>
        <p>Você já está quase lá!</p>
      </div>
      <CardsContainer style={{ margin: '0.5rem 0' }}>
        <FourColumnsGrid columns={'1fr 1fr 1fr'}>
          <InfoCard number="1">
            <>
              <span className="infoTitle">Acesso ao canal</span>
              <p>
                Ao clicar em <strong>Finalizar Cadastro</strong>, será aberto um
                pop up. Faça login com a conta criada na etapa anterior e avance
                para a etapa seguinte
              </p>
            </>
          </InfoCard>
          <InfoCard number="2">
            <>
              <span className="infoTitle">
                Permita o Gerenciamento da Conta
              </span>
              <p>
                O próximo passo é permitir que a Poli gerenciar a sua conta de
                WhatsApp. Isso possibilitará a conexão com a nossa plataforma.
                Fique tranquilo, pois a sua conta continuará sob o seu controle,
                caso necessário será possível alterar apermissão
              </p>
            </>
          </InfoCard>
          <InfoCard number="3">
            <>
              <span className="infoTitle">Canal Ativo</span>
              <p>
                Ao final do processo de autorização a sua conta já estará
                disponível para troca de mensagens
              </p>
            </>
          </InfoCard>
        </FourColumnsGrid>
      </CardsContainer>

      {PARTNER_ID ? (
        <div className="containerButton360Partner">
          <ConnectButton
            partnerId={PARTNER_ID}
            callback={handleCallback}
            label="Conecte sua conta de Whatsapp Business"
            className="Button360Partner"
            queryParameters={{
              redirect_url: `${SPA_DOMAIN}/tools/channel/360Dialog/onboard`,
            }}
            // requestedNumber={removeTheFirstNineNumber(props.phone_number)}
            disabled={props.loading ?? false}
          />
        </div>
      ) : (
        <Alert color="danger">
          <div className="Button360PartnerError">
            <Icon icon="poli-icon pi-sad-fill" size={22} />
            <span>Erro ao carregar botão do parceiro, tente novamente!</span>
          </div>
        </Alert>
      )}

      {props.loading && (
        <div className="containerLoading360Partner">
          <div className="loading360Partner">
            <img src={PoliLogo} alt="Poli" width={50} height={50} />
            <div>
              <Animation
                icon={'chat-loading'}
                size={40}
                style={{ margin: '1px' }}
              />
            </div>
            <img src={Dialog360Logo} alt="Dialog360" width={50} height={50} />
          </div>
          <span>Conectando o seu novo canal!</span>
        </div>
      )}
    </div>
  );
}
