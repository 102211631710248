export default function returnNameMonth(
  date = null,
  language = 'pt-br',
  size = 'long'
) {
  if (!date) {
    date = new Date();
  }

  let month = date.toLocaleString(language, { month: size });
  const _month = month.charAt(0).toUpperCase() + month.slice(1);

  return _month;
}
