import { ImageContent } from '../../../shared/components/ImageContent';
import ExportReport from '../../../img/ExportReport';

export function SubMenuWelcome() {
  return (
    <ImageContent
      ImgElement={ExportReport}
      title='Faça download dos seus relatórios.'
      subTitle='Exporte relatórios e consulte aqui os status das suas exportações.'
    />
  );
}