export const poliGetUserIDFromLocalStorage = () => {
  const { user } = JSON.parse(window.localStorage.getItem('poli_access'));
  return user.id;
};

const poliAuthTokenGet = () => {
  const { token } = JSON.parse(window.localStorage.getItem('poli_access'));
  return token;
};

export default poliAuthTokenGet;
