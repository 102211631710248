import React from 'react';
import { Icon, Button } from '@polichat/flamboyant';
import history from '../../../services/history';

function BackToLink() {
  return (
    <Button
      id="backToMenuMobile"
      color="link"
      className="backToMenuMobile"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        history.goBack();
      }}
    >
      <Icon
        icon="poli-icon pi-arrow-left-fill"
        color="var(--green-polichat)"
        size={30}
      />
    </Button>
  );
}
export default BackToLink;
