import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import PageMainHeader from '../../../../common/pages/PageMainHeader/PageMainHeader.component';
import PageMainBody from '../../../../common/pages/PageMainBody/PageMainBody.component';

import ProgressBar from '../ProgressBar/ProgressBar.component';
import GatherDocuments from '../steps/GatherDocuments/GatherDocuments.component';

const ChannelAddWaba = () => {
  const history = useHistory();
  // Pegar state enviado pela URL
  const { state: historyState } = history.location;

  useEffect(() => {
    /**
     * verifica se a empresa passou pela analise do plano antes de criar canal
     */
    if (!historyState?.validated_can_add_channel) {
      history.push(`/tools/channels/add`);
      return;
    }
  }, []);

  return (
    <>
      <PageMainHeader />
      <PageMainBody>
        <ProgressBar currentStep={1} classic={false} />
        <GatherDocuments />
      </PageMainBody>
    </>
  );
};

export default ChannelAddWaba;
