import React from 'react';
import { handleExternalLink } from '../../../../../../utils/handleExternalLink';

import { Button, Icon } from '@polichat/flamboyant';
import { ButtonContainer } from './ButtonDoubts.styles';

const ButtonDoubts = () => (
  <ButtonContainer>
    <Button color="primary" size="sm" onClick={() => handleExternalLink('https://poli.digital/whatsapp-business/')} >
      <span>Dúvidas?</span>
      <Icon icon="poli-icon pi-camera-video-fill" size={15} />
    </Button>
  </ButtonContainer>
);

export default ButtonDoubts;
