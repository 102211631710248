import { laravelAxios } from '../../../../config/axios/laravelAxios';

import {
  PostWhatsAppChannelParams,
  PutWhatsAppChannelParams,
  GetWhatsAppChannelParams,
  updatePictureParams,
} from './types';
import {
  DtoConnect,
  DtoConnectionWhatsAppChannel,
  DtoCreateEditWhatsAppChannel,
  DtoSuccessVerifyChannelStatus,
  DtoSuccessUpdatePicture,
  DtoDeleted,
  DtoDisconnect,
} from './dto';

export class WhatsAppCreateEditService {
  static async createWhatsAppZAPIChannel(params: PostWhatsAppChannelParams) {
    const url = `channels/spa/${params.customer_id}/whatsapp/store`;
    const request = await laravelAxios.post<DtoCreateEditWhatsAppChannel>(
      url,
      params
    );
    return request.data;
  }

  static async verifyChannelStatus(params: GetWhatsAppChannelParams) {
    const url = `/channels/${params.customer_id}/${params.channel_customer_id}/verify-channel-status`;
    const request = await laravelAxios.get<DtoSuccessVerifyChannelStatus>(url);
    return request.data;
  }

  static async updatePicture(params: updatePictureParams){
    const url = `/contacts/${params.customerId}/${params.contactId}/channel/${params.channelId}/uid/${params.uid}/profile`;
    const request = await laravelAxios.get<DtoSuccessUpdatePicture>(url);
    return request.data;
  }

  static async editWhatsAppZAPIChannel(params: PutWhatsAppChannelParams) {
    const url = `channels/spa/${params.customer_id}/whatsapp/store/${params.id}`;
    const request = await laravelAxios.post<DtoCreateEditWhatsAppChannel>(
      url,
      params
    );
    return request.data;
  }

  static async homogationZapiToBsp(params: PutWhatsAppChannelParams) {
    const url = `channels/spa/${params.customer_id}/zapi/bsp/${params.id}`;
    const request = await laravelAxios.post<DtoCreateEditWhatsAppChannel>(
      url,
      params
    );
    return request.data;
  }

  static async checkConnectionWhatsAppZAPIChannel(
    params: GetWhatsAppChannelParams
  ) {
    const url = `channels/checkconnection/${params.customer_id}/${params.channel_customer_id}`;
    const request = await laravelAxios.get<DtoConnectionWhatsAppChannel>(url);
    return request.data;
  }

  static async connectWhatsAppZAPIChannel(params: GetWhatsAppChannelParams) {
    const url = `channels/spa/${params.customer_id}/${params.channel_customer_id}/connect`;
    const request = await laravelAxios.get<DtoConnect>(url);
    return request.data;
  }

  static async disconnectWhatsAppZAPIChannel(params: GetWhatsAppChannelParams) {
    const url = `channels/spa/${params.customer_id}/${params.channel_customer_id}/disconnect`;
    const request = await laravelAxios.get<DtoDisconnect>(url);
    return request.data;
  }

  static async deleteWhatsAppZAPIChannel(params: GetWhatsAppChannelParams) {
    const url = `channels/${params.customer_id}/${params.channel_customer_id}/destroy`;
    const request = await laravelAxios.get<DtoDeleted>(url);
    return request.data;
  }
  static async restartWhatsAppZAPIChannel(params: GetWhatsAppChannelParams) {
    const url = `channels/spa/${params.customer_id}/${params.channel_customer_id}/restart`;
    const request = await laravelAxios.get<DtoConnect>(url);
    return request.data;
  }
}
