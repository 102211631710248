import { Button, Col, Form, FormGroup, Row } from '@polichat/flamboyant';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import history from '../../../../services/history';
import ChannelsService from '../../../../services/tools/channels/channels';
import WabaService from '../../../../services/tools/template-waba/template-waba';

import PageMainBody from '../../../common/pages/PageMainBody/PageMainBody.component';
import PageMainHeader from '../../../common/pages/PageMainHeader/PageMainHeader.component';

import MessagePreview from '../preview/MessagePreview.component';
import {
  TemplateOptionSpecificBonds,
  TemplateSelectBuilderType,
  TemplateShortcutInput,
  TemplateTagInput,
} from './common';
import SelectChannelWaba from './waba/channel/SelectChannelWaba.component';
import FormTemplateWaba from './waba/FormTemplateWaba.component';

import Animation from '../../../common/animation';
import allSectionsHasRows from './allSectionsHasRows';
import CreateBody from './body/CreateBody.component';
import { Container, FormContainer } from './FormMessagesBuilder.styles';
import FormTemplateList from './list/FormTemplateList.component';
import { FormButtonsMessage } from './buttonsMessage/index.tsx';
import { useWhiteLabel } from 'src/hooks/useWhiteLabel';

const FormMessagesBuilder = ({ data }) => {
  const languageId_ptbr = 46;
  const isEdit = data ? true : false;
  const whitelabel = useWhiteLabel();

  const WABA = {
    channel_id: 6,
    api_version: 33,
    api_meta: 10,
  };

  const customerID = useSelector((state) => state.general?.current_customer_id);
  const defaultUserID = useSelector((state) => state.general?.user?.id);
  const [isLoading, setIsLoading] = useState(true); // Início da variável loading para pré-carregamento dos canais
  const [formTemplateData, setFormTemplateData] = useState({
    templateId: data?.id || '',
    templateChannelCustomer: data?.channel_customer_id || '',

    templateType: data?.template_waba
      ? 'waba'
      : data?.type == 'list'
      ? 'list'
      : data?.type == 'buttonsMessage'
      ? 'buttonsMessage'
      : 'quick',
    templateName: data?.template_name || '',
    templateTag: data?.tag || '',
    templateShortcut: data?.keyboard_shortcut || '',

    user_id: data?.user_id,
    created_by_user_id: data?.created_by_user_id || defaultUserID,
    department_id: data?.department_id,
    buttons: data?.buttons || [],
    wabaValues: {
      header: data?.header || { type: 'none', text: '', mediaUrl: '' },
      body: {
        text: data?.body || typeof data?.text === 'string' ? data.text : '',
      },
      footer: { text: data?.footer || '' },
      buttons: data?.buttons || [],
      options: {
        language: data?.language || languageId_ptbr,
        category: data?.category || 'none',
      },
    },
    listValues: {
      header: data?.header || { type: 'none', text: '' },
      body: {
        text: data?.body || typeof data?.text === 'string' ? data.text : '',
      },
      footer: { text: data?.footer || '' },
      button: { text: data?.button || '' },
      section: data?.section || [],
      option: data?.option || [],
    },
    buttonsMessageValues: {
      header: data?.header || { type: 'none', text: '', mediaUrl: '' },
      body: {
        text: data?.body || typeof data?.body === 'string' ? data.body : '',
      },
      footer: { text: data?.footer || '' },
      buttons: data?.buttons || [],
    },
  });

  const quickMessageTypesToSelect = [
    { id: 1, type: 'waba', description: 'Template', active: true },
    { id: 2, type: 'quick', description: 'Mensagens Rápidas', active: true },
    { id: 3, type: 'list', description: 'Lista de opções', active: true },
    {
      id: 4,
      type: 'buttonsMessage',
      description: 'Botões Interativos',
      active: true,
    },
  ];

  const [templatesType, setTemplatesType] = useState(
    quickMessageTypesToSelect.filter((item) => item)
  );

  const [wabaChannelsList, setWabaChannelsList] = useState([]);
  const [channelsInitialValue, setChannelsInitialValue] = useState({});
  const [showSelectChannelsWaba, setShowSelectChannelsWaba] = useState(false);
  const [hasWabaChannels, setHasWabaChannels] = useState(false);

  const [isProcessing, setIsProcessing] = useState(false);

  const disableWabaTemplate = () => {
    const WABA_TEMPLATE_ID = 1;
    const LIST_TEMPLATE_ID = 3;

    setHasWabaChannels(false);
    setTemplatesType(
      templatesType.map((template) => {
        if (template.id === WABA_TEMPLATE_ID || LIST_TEMPLATE_ID) {
          template.active = false;
        }
        return template;
      })
    );
  };

  useEffect(() => {
    async function fetchWabaChannels() {
      try {
        // Search only active WABA channels
        const result = await ChannelsService.getAllChannels({
          customer_id: customerID,
          status: 1, // status 1 = active
          api: 33, // api 33 = WABA
          noHomologation: true,
          size: 100, // set registers range
        });

        const { data: channels } = result;

        // If there is no channels, disable the template
        if (channels.length < 1) {
          disableWabaTemplate();
        } else {
          setHasWabaChannels(true);

          const firstActiveChannel = channels.find((channel) => {
            return (
              channel.api == WABA.api_version ||
              channel.api == WABA.api_meta ||
              channel.channel_id == WABA.channel_id
            );
          });

          setChannelsInitialValue({
            templateChannelCustomer: firstActiveChannel.id,
          });

          setFormTemplateData({
            ...formTemplateData,
            templateChannelCustomer: firstActiveChannel.id,
          });

          setWabaChannelsList(
            channels.filter((channel) => !channel.homologation)
          );
        }
      } catch (error) {
        console.error(
          'Error in FormMessageBuilder component when getAllChannels from channel service',
          error
        );
      } finally {
        setIsLoading(false);
      } // No final do try catch o load fica como false (pós-processamento)
    }

    if (!isEdit) {
      fetchWabaChannels();
    } else setIsLoading(false); // Se edição, não é feito o loading (evitar loop infinito)
  }, [customerID]);

  useEffect(() => {
    setShowSelectChannelsWaba(
      channelsInitialValue?.templateChannelCustomer && wabaChannelsList
    );
  }, [channelsInitialValue?.templateChannelCustomer, wabaChannelsList]);

  const handleFormBodyQuickMessageData = (bodyText) => {
    if (formTemplateData.wabaValues) {
      const wabaValues = formTemplateData.wabaValues;
      setFormTemplateData({
        ...formTemplateData,
        wabaValues: {
          ...wabaValues,
          body: {
            text: bodyText,
          },
        },
        body: {
          text: bodyText,
        },
      });
    } else if (formTemplateData.buttonsMessageValues) {
      const buttonsMessageValues = formTemplateData.buttonsMessageValues;
      setFormTemplateData({
        ...formTemplateData,
        buttonsMessageValues: {
          ...buttonsMessageValues,
          body: {
            text: bodyText,
          },
        },
        body: {
          text: bodyText,
        },
      });
    }
  };

  const handleFormTemplateData = (inputsData) => {
    setFormTemplateData({
      ...formTemplateData,
      ...inputsData,
    });
  };

  const handleFormWabaData = (formWabaData) => {
    setFormTemplateData({
      ...formTemplateData,
      ...formWabaData,
      wabaValues: {
        ...formWabaData,
      },
    });
  };

  const handleFormButtonsMessageData = (formButtonsMessageData) => {
    setFormTemplateData({
      ...formTemplateData,
      ...formButtonsMessageData,
      buttonsMessageValues: {
        ...formButtonsMessageData,
      },
    });
  };

  const handleFormListData = (formListData) => {
    setFormTemplateData({
      ...formTemplateData,
      ...formListData,
      listValues: {
        ...formListData,
      },
    });
  };
  // template = channel_id
  // text = obj contendo body, footer e header

  const submitTemplateWaba = async () => {
    const onToastClose = () => {
      setIsProcessing(false);
      history.push('/tools/messages-builder');
    };

    let params = {};
    if (
      formTemplateData.wabaValues?.header.type === 'media' &&
      formTemplateData.wabaValues?.header.mediaUrl === ''
    ) {
      toast.warning('Escolha um arquivo para a template');
      return;
    }
    if (formTemplateData.wabaValues?.header.type !== 'media') {
      if (formTemplateData.wabaValues?.header.mediaUrl !== '') {
        //remover a midia da AWS
        WabaService.deleteTemplateAwsUrlAssignedFile({
          customer_id: customerID,
          access_url_delete: formTemplateData.wabaValues?.header.mediaUrl,
        });
      }
    }
    if (
      formTemplateData.wabaValues?.buttons.length > 0 &&
      formTemplateData.wabaValues.buttons.some(
        (button) =>
          button.actionType === 'call' && button.actionText.length < 12
      )
    ) {
      toast.warning('O telefone do botão ligar deve ser preenchido e válido!');
      return;
    }

    params = {
      customer_id: customerID,
      formData: {
        // general template data
        type: formTemplateData.templateType,
        channel_id: formTemplateData.templateChannelCustomer,
        tag: formTemplateData.templateTag,
        name: formTemplateData.templateName,
        shortcut: formTemplateData.templateShortcut,
        // multi seleção
        // departments: formTemplateData.departments,
        // attendants: formTemplateData.attendants,

        department_id: formTemplateData.department_id,
        user_id: formTemplateData.user_id,
        created_by_user_id: formTemplateData.created_by_user_id,
        //waba specific data
        header: formTemplateData.wabaValues?.header,
        body: formTemplateData.wabaValues?.body?.text,
        footer: formTemplateData.wabaValues?.footer?.text,
        buttons: formTemplateData.wabaValues?.buttons,
        category: formTemplateData.wabaValues?.options?.category,
        language: formTemplateData.wabaValues?.options?.language,
      },
    };
    if (isEdit) {
      const { formData, channel_id } = params;
      delete params.channel_id;
      params = {
        customer_id: customerID,
        template_id: formTemplateData.templateId,
        formData: { ...formData, channel_id },
      };

      const result = await WabaService.editTemplateWaba(params);

      if (result?.error || !result) {
        toast.error('Erro ao editar template!', {
          onClose: () => setIsProcessing(false),
        });
      }
      if (result?.success) {
        toast.success('Template editado!', { onClose: onToastClose });
      }

      return;
    }

    const result = await WabaService.createTemplateWaba(params);
    if (result?.error) {
      toast.error(
        result?.message ||
          result?.error?.response?.data?.message ||
          'Erro ao cadastrar template!',
        {
          onClose: () => setIsProcessing(false),
        }
      );
    }
    if (result?.success) {
      toast.success('Template enviada para análise!', {
        onClose: onToastClose,
      });
    }
  };
  const submitButtonsMessageWaba = async () => {
    const onToastClose = () => {
      setIsProcessing(false);
      history.push('/tools/messages-builder');
    };

    let params = {};
    if (formTemplateData.buttonsMessageValues.buttons.length === 0) {
      toast.warning('Você precisa adicionar pelo menos um botão interativo!', {
        onClose: () => setIsProcessing(false),
      });
      return;
    }
    if (
      formTemplateData.buttonsMessageValues?.header.type === 'media' &&
      formTemplateData.buttonsMessageValues?.header.mediaUrl === ''
    ) {
      toast.warning('Escolha um arquivo para a Mensagem interativa', {
        onClose: () => setIsProcessing(false),
      });
      return;
    }
    if (formTemplateData.buttonsMessageValues?.header.type !== 'media') {
      if (formTemplateData.buttonsMessageValues?.header.mediaUrl !== '') {
        //remover a midia da AWS
        WabaService.deleteTemplateAwsUrlAssignedFile({
          customer_id: customerID,
          access_url_delete:
            formTemplateData.buttonsMessageValues?.header.mediaUrl,
        });
      }
    }
    if (
      formTemplateData.buttonsMessageValues?.buttons.length > 0 &&
      formTemplateData.buttonsMessageValues.buttons.some(
        (button) =>
          button.actionType === 'call' && button.actionText.length < 12
      )
    ) {
      toast.warning('O telefone do botão ligar deve ser preenchido e válido!', {
        onClose: () => setIsProcessing(false),
      });
      return;
    }

    params = {
      customer_id: customerID,
      formData: {
        // general template data
        type: formTemplateData.templateType,
        channel_id: formTemplateData.templateChannelCustomer,
        tag: formTemplateData.templateTag,
        name: formTemplateData.templateName,
        shortcut: formTemplateData.templateShortcut,
        // multi seleção
        // departments: formTemplateData.departments,
        // attendants: formTemplateData.attendants,

        department_id: formTemplateData.department_id,
        user_id: formTemplateData.user_id,
        created_by_user_id: formTemplateData.created_by_user_id,
        //waba specific data
        header: formTemplateData.buttonsMessageValues?.header,
        body: formTemplateData.buttonsMessageValues?.body?.text,
        footer: formTemplateData.buttonsMessageValues?.footer?.text,
        buttons: formTemplateData.buttonsMessageValues?.buttons,
      },
    };
    if (isEdit) {
      const { formData, channel_id } = params;
      delete params.channel_id;
      params = {
        customer_id: customerID,
        template_id: formTemplateData.templateId,
        formData: { ...formData, channel_id },
      };

      const result = await WabaService.editTemplateWaba(params);
      if (result?.error || !result) {
        toast.error('Erro ao editar Botões interativos!', {
          onClose: () => setIsProcessing(false),
        });
      }
      if (result?.success) {
        toast.success('Botões interativos editado!', { onClose: onToastClose });
      }

      return;
    }
    // criar mensagem interativa
    const result = await WabaService.createTemplateWaba(params);
    if (result?.error) {
      toast.error(
        result?.message ||
          result?.error?.response?.data?.message ||
          'Erro ao criar Botões interativos!',
        {
          onClose: () => setIsProcessing(false),
        }
      );
    }
    if (result?.success) {
      toast.success('Botões interativos criado com sucesso!', {
        onClose: onToastClose,
      });
    }
  };

  const submitList = async () => {
    const onToastClose = () => history.push('/tools/messages-builder');
    let params = {
      customer_id: customerID,
      formData: {
        type: formTemplateData.templateType,
        channel_id: null,
        name: formTemplateData.templateName,
        tag: formTemplateData.templateTag,
        shortcut: formTemplateData.templateShortcut,
        // departments: formTemplateData.departments,
        // attendants: formTemplateData.attendants,

        department_id: formTemplateData.department_id,
        user_id: formTemplateData.user_id,
        created_by_user_id: formTemplateData.created_by_user_id,

        header: formTemplateData.listValues?.header,
        body: formTemplateData.listValues?.body?.text,
        footer: formTemplateData.listValues?.footer?.text,
        button: formTemplateData.listValues?.button.text,
        section: formTemplateData.listValues?.section,
      },
    };
    let emptyTitleSection = false;
    let invalidatorOption = false;

    formTemplateData.listValues?.section.map((component) => {
      if (component.text === '') {
        emptyTitleSection = true;
      }
      component.rows.map((componentTwo) => {
        if (
          componentTwo.messageOption.length === 0 ||
          componentTwo.messageOption?.title === undefined ||
          componentTwo.messageOption?.description === undefined
        ) {
          invalidatorOption = true;
        }
      });
    });

    if (
      (formTemplateData.listValues?.header.type === 'text' &&
        formTemplateData.listValues?.header.text === '') ||
      formTemplateData.listValues?.body.text === '' ||
      formTemplateData.listValues?.button.text === '' ||
      formTemplateData.listValues?.section.length === 0 ||
      emptyTitleSection === true ||
      invalidatorOption === true
    ) {
      toast.warning('Preencha os campos obrigatórios!', {
        onClose: () => setIsProcessing(false),
      });
      return;
    }

    if (allSectionsHasRows(formTemplateData?.listValues?.section)) {
      toast.warning('Toda lista precisa ter pelo menos uma opção!', {
        onClose: () => setIsProcessing(false),
      });
      return;
    }

    if (isEdit) {
      const { formData, channel_id } = params;
      delete params.channel_id;
      params = {
        customer_id: customerID,
        template_id: formTemplateData.templateId,
        formData: { ...formData, channel_id },
      };

      const result = await WabaService.editTemplateWaba(params);
      if (result?.error || !result) {
        toast.error('Erro ao editar lista!', {
          onClose: () => setIsProcessing(false),
        });
      }
      if (result?.success) {
        toast.success('Lista editada!', { onClose: onToastClose });
      }

      return;
    }

    const result = await WabaService.createTemplateWaba(params);

    if (result.error) {
      toast.error(result.message || 'Erro ao cadastrar lista!', {
        onClose: () => setIsProcessing(false),
      });
    }
    if (result.success) {
      toast.success('Lista criada com sucesso!', {
        onClose: onToastClose,
      });
    }

    return;
  };

  const submitTemplateQuick = async () => {
    const onToastClose = () => history.push('/tools/messages-builder');

    let params = {
      customer_id: customerID,
      formData: {
        type: formTemplateData.templateType,
        channel_id: null,
        name: formTemplateData.templateName,
        tag: formTemplateData.templateTag,
        shortcut: formTemplateData.templateShortcut,
        body: formTemplateData.wabaValues?.body?.text,
        // departments: formTemplateData.departments,
        // attendants: formTemplateData.attendants,

        department_id: formTemplateData.department_id,
        user_id: formTemplateData.user_id,
        created_by_user_id: formTemplateData.created_by_user_id,
      },
    };

    if (isEdit) {
      const { formData, channel_id } = params;
      delete params.channel_id;
      params = {
        customer_id: customerID,
        template_id: formTemplateData.templateId,
        formData: { ...formData, channel_id },
      };

      const result = await WabaService.editTemplateWaba(params);
      if (result?.error || !result) {
        toast.error('Erro ao editar mensagem rápida!', {
          onClose: () => setIsProcessing(false),
        });
      }
      if (result?.success) {
        toast.success('Mensagem rápida editada!', { onClose: onToastClose });
      }

      return;
    }

    const result = await WabaService.createTemplateWaba(params);

    if (result.error) {
      toast.error(result.message || 'Erro ao cadastrar mensagem rápida!', {
        onClose: () => setIsProcessing(false),
      });
    }
    if (result.success) {
      toast.success('Mensagem rápida criada com sucesso!', {
        onClose: onToastClose,
      });
    }

    return;
  };

  const handleSubmitTemplate = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsProcessing(true);

    const TEMPLATE_FUNCTIONS = {
      waba: submitTemplateWaba,
      quick: submitTemplateQuick,
      list: submitList,
      buttonsMessage: submitButtonsMessageWaba,
    };

    TEMPLATE_FUNCTIONS[formTemplateData.templateType]();
  };

  return (
    <>
      <PageMainHeader
        title={`Construtor de ${
          whitelabel.isWl ? 'Mensagem rápida' : 'Template'
        }`}
        icon="poli-icon pi-message-builder-fill"
      />
      <PageMainBody>
        <Container>
          <FormContainer>
            <p>
              Comece a construir seu modelo de mensagem aqui, depois você poderá
              utiliza-lo como mensagem rápida, template ou então automatizá-lo
              com o Bot.
            </p>
            {isLoading ? (
              <Animation icon="chat-loading" size="large" top={'50%'} />
            ) : (
              !isLoading && (
                <Form onSubmit={handleSubmitTemplate}>
                  <FormGroup>
                    <Row>
                      <TemplateSelectBuilderType
                        onUpdate={handleFormTemplateData}
                        initialValue={{
                          initialTemplateType: formTemplateData.templateType,
                          templateTypeList: templatesType,
                        }}
                        isEdit={isEdit}
                      />

                      {formTemplateData?.templateType === 'waba' && (
                        <SelectChannelWaba
                          onUpdate={handleFormTemplateData}
                          initialValue={{
                            initialChannelCustomer:
                              formTemplateData?.templateChannelCustomer ||
                              channelsInitialValue?.templateChannelCustomer,
                            channelsWabaList: wabaChannelsList,
                          }}
                          isEdit={isEdit}
                        />
                      )}
                      <TemplateOptionSpecificBonds
                        haveOffSet={
                          formTemplateData.templateType === 'waba' &&
                          showSelectChannelsWaba
                            ? true
                            : false
                        }
                        onUpdate={handleFormTemplateData}
                        initialValue={{
                          // multi seleção
                          // departments: formTemplateData.departments,
                          // attendants: formTemplateData.attendants,

                          department_id: formTemplateData.department_id,
                          user_id: formTemplateData.user_id,
                        }}
                      />
                    </Row>
                    <Row>
                      <TemplateTagInput
                        onUpdate={handleFormTemplateData}
                        initialValue={formTemplateData.templateTag}
                      />
                      <TemplateShortcutInput
                        onUpdate={handleFormTemplateData}
                        initialValue={formTemplateData.templateShortcut}
                      />
                    </Row>
                  </FormGroup>
                  <FormGroup>
                    {formTemplateData.templateType === 'waba' ? (
                      <FormTemplateWaba
                        onUpdate={handleFormWabaData}
                        initialValue={formTemplateData.wabaValues}
                        isEdit={isEdit}
                      />
                    ) : formTemplateData.templateType === 'list' ? (
                      <FormTemplateList
                        onUpdate={handleFormListData}
                        initialValue={formTemplateData.listValues}
                        isEdit={isEdit}
                      />
                    ) : formTemplateData.templateType === 'buttonsMessage' ? (
                      <FormButtonsMessage
                        onUpdate={handleFormButtonsMessageData}
                        initialValue={formTemplateData.buttonsMessageValues}
                        isEdit={isEdit}
                      />
                    ) : (
                      <CreateBody
                        onUpdate={handleFormBodyQuickMessageData}
                        initialValue={formTemplateData.wabaValues.body.text}
                        isEdit={isEdit}
                      />
                    )}
                  </FormGroup>
                  {/* Confirm submit */}
                  <Row>
                    <Col sm={12} md={{ size: 6, offset: 3 }}>
                      <FormGroup>
                        <Button gradient="primary" disabled={isProcessing}>
                          {formTemplateData.templateType === 'waba'
                            ? 'Salvar e enviar para aprovação'
                            : 'Salvar'}
                        </Button>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              )
            )}
          </FormContainer>
          <MessagePreview data={formTemplateData} />
        </Container>
      </PageMainBody>
    </>
  );
};

export default FormMessagesBuilder;
