import React from 'react';
import Tag from './Tag';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ChatActions from '../../../../../../store/modules/chat/actions';
import { MenuTypes } from '../../../../../../store/modules/chat/reducer';

function ContactTags({ chat, removeTagFromContact }) {
  let contactTagsComponent = <></>;

  let tagsMenu = chat?.view?.menus?.find(
    (menu) => menu.type === MenuTypes.TAG_MENU
  );

  if (tagsMenu) {
    // Tags relacionadas ao contato
    let contactTags = tagsMenu.contactTags;

    if (contactTags && contactTags.length) {
      return (
        <div className="header-menu-modal-subsection">
          <div className="inserted-tags-div">
            {
            contactTagsComponent = contactTags.map((tag, index) => {
              let tagObj = tag;
              if (tagsMenu.closeBadge) {
                removeTagFromContact(tag);
              }

              if (tagObj) {
                return (
                  <Tag
                    key={index}
                    close
                    name={tag.name}
                    color={tag.color}
                    onRemove={() => removeTagFromContact(tag)}
                  />
                );
              } else {
                return <></>;
              }
            })}
          </div>
        </div>
      )

    } else {
      return <></>
    }
  }

  return <>{contactTagsComponent}</>;
}

const mapStateToProps = (state) => ({
  chat: state.chat?.chats?.find((chat) => chat.open === true),
  allTags: state.chat?.config?.general?.tags,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(ChatActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ContactTags);
