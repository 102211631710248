import { 
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
} from '@polichat/flamboyant';

interface ModalMessageProps {
    isOpen: boolean;
    toggle: () => void;
    handleSubmit: () => void;
    title: string;
    subtitle: string;
    buttonTitle: string;
}

function ModalMessage({
    isOpen,
    toggle,
    handleSubmit,
    title,
    subtitle,
    buttonTitle,
  }: ModalMessageProps) {
    const styles = {
        title: {
          fontSize: '1.75rem',
          fontWeight: '700',
          marginLeft: '10px',
        }
    };
    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>
                <span style={styles.title}>{title}</span>
            </ModalHeader>
            <ModalBody>
                <p>{subtitle}</p>
            </ModalBody>
            <ModalFooter>
                <Button color="info" size="lg" onClick={handleSubmit}>
                    <span style={{ marginLeft: 5 }}>{buttonTitle}</span>
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default ModalMessage;