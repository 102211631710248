import React, { Component } from 'react';

import Dropzone from 'react-dropzone';
import { Icon } from '@polichat/flamboyant';
import { isMobile } from 'react-device-detect';

import { DropContainer, UploadMessage } from './stylesV2';
import ShowPreview from '../dropzone/v2/FilePreview/ShowPreview';

export default class UploadV2 extends Component {
  renderDragMessage = (isDragActive, isDragReject) => {
    if (!isDragActive) {
      return (
        <UploadMessage>
          <Icon icon="poli-icon pi-upload-fill" />
          {isMobile ? (
            <div>
              <span className="noDrags">Clique para carregar</span>
            </div>
          ) : (
            <div>
              <span className="noDrags">Clique para carregar</span> ou arraste
              aqui...
            </div>
          )}
        </UploadMessage>
      );
    }

    if (isDragReject) {
      return (
        <UploadMessage type="error">
          <Icon icon="poli-icon pi-upload-fill" />
          <div>
            Arquivos <span className="drags">não suportados</span>
          </div>
        </UploadMessage>
      );
    }
    return (
      <UploadMessage type="success">
        <Icon icon="poli-icon pi-upload-fill" />
        <div>
          Solte os <span className="drags">arquivos aqui</span>
        </div>
      </UploadMessage>
    );
  };
  render() {
    const { onUpload, onRejected, selectPreview, chatSelected, openFile } =
      this.props;
    return (
      <Dropzone
        accept="image/*, video/*, audio/*, application/*, message/*, model/*, text/*, windows/metafile, www/mime, xgl/movie, xgl/drawing, x-conference/x-cooltalk, x-music/x-midi, x-world/*"
        onDropAccepted={onUpload}
        onDropRejected={onRejected}
        maxSize={30000000} ///20MB
        noClick={selectPreview ? true : false}
      >
        {({
          getRootProps,
          getInputProps,
          open,
          isDragActive,
          isDragReject,
        }) => (
          <DropContainer
            {...getRootProps()}
            isDragActive={isDragActive}
            isDragReject={isDragReject}
            className={`dropUpload ${selectPreview ? 'border-0' : ''}`}
            style={
              selectPreview && {
                background: 'var(--secondary-background-color)',
              }
            }
          >
            <input {...getInputProps()} />
            {selectPreview?.id ? (
              <div className="wrapperShowPreview">
                <ShowPreview
                  open={open}
                  openFile={openFile}
                  selectPreview={selectPreview}
                  chatSelected={chatSelected}
                />
              </div>
            ) : (
              this.renderDragMessage(isDragActive, isDragReject)
            )}
          </DropContainer>
        )}
      </Dropzone>
    );
  }
}
