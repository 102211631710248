import React, { useEffect, useState } from 'react';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Icon,
  UncontrolledDropdown,
} from '@polichat/flamboyant';
import InputSearch from '../../../common/form/InputSearch';
import { removeAccentString } from '../../../../store/modules/chat/functions';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ChatActions from '../../../../store/modules/chat/actions';
import { isJson } from '../../../../utils/isJson';

function ChannelSelector({ chat, opennedChat, selectChannel }) {
  const [channelSwitch, setChannelSwitch] = useState(false);
  const [channels, setChannels] = useState();
  const toggle = () => setChannelSwitch((prevState) => !prevState);

  const selectedChannel = opennedChat?.view?.selectedChannel;

  const renderChannelText = (channel) => {
    let text = channel.channel_name;

    let textNumber = '';
    if (channel.channel_id == 1 || channel.channel_id == 6) {
      textNumber = ` (${channel.channel_phone})`;
    }

    if (selectedChannel && selectedChannel.id === channel.id)
      return (
        <>
          <small className="selected-channel">
            {text}
            {textNumber}
          </small>
        </>
      );

    return (
      <small>
        {text}
        {textNumber}
      </small>
    );
  };

  const orderNameChannel = (a, b) => {
    return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
  };

  const orderTypeChannel = (a, b) => {
    const aType =
      a.type === 'waba' ? '1' : a.type === 'whatsapp' ? '2' : a.type;
    const bType =
      b.type === 'waba' ? '1' : b.type === 'whatsapp' ? '2' : b.type;
    return aType < bType ? -1 : aType > bType ? 1 : 0;
  };

  useEffect(() => {
    const _channels = chat?.config?.user?.channels;
    if (Array.isArray(_channels)) {
      _channels.sort(orderNameChannel);
      _channels.sort(orderTypeChannel);
      setChannels(_channels);
    }
  }, [chat?.config?.user?.channels]);

  /**
   * Campo de pesquisa e toda a logica de pesquisa do canal
   */
  const [currentValueSearch, setCurrentValueSearch] = useState('');
  const [lastTimeout, setLastTimeout] = useState(0);
  const [loadingSearch, setLoadingSearch] = useState(false);

  const onUpdateSearchValue = (value) => {
    setLoadingSearch(true);

    if (lastTimeout) {
      clearTimeout(lastTimeout);
    }

    setCurrentValueSearch(value);

    setLastTimeout(
      setTimeout(() => {
        setLoadingSearch(false);
        searchList(value);

        setChannelSwitch(false);
        setChannelSwitch(true);
      }, 1000)
    );
  };

  const searchList = (value = '') => {
    const _channels = chat?.config?.user?.channels;
    if (_channels && _channels.length > 0) {
      const result = _channels.filter((item) => {
        return removeAccentString(item.name.toLowerCase()).includes(
          removeAccentString(value?.toLowerCase())
        );
      });

      result.sort(orderNameChannel);
      result.sort(orderTypeChannel);
      setChannels(result);
    }
  };
  /* end */

  // relaciona contato externo com canais da empresa (whatsapp com whatsapp, fb com fb ...)
  const availableChannels = () => {
    let available_channels = [];
    // let channels = chat?.config?.user?.channels;

    // Os externals são usados para definir quais canais serão exibidos
    if (opennedChat?.contact?.externals && channels && channels.length > 0) {
      let contactExt__chanId = [];
      opennedChat.contact.externals.forEach((contExt) => {
        if (
          !contactExt__chanId.find(
            (chanId__contEx) => chanId__contEx === contExt.channel_id
          )
        ) {
          contactExt__chanId.push(contExt.channel_id);
          // se for poliAPI tbm tem que adicionar WABA
          if (contExt.channel_id == 1) {
            contactExt__chanId.push(6);
          } else if (contExt.channel_id == 6) {
            /// se for WABA tbm tem que adicionar poliAPI
            contactExt__chanId.push(1);
          }
        }
      });

      if (contactExt__chanId.length) {
        channels.forEach((chan) => {
          // Se colocar chan.connection === 1 no if abaixo ele não mostrará canais desconectados
          // if (types.find((type) => type === chan.channel_id && chan.connection === 1)) {
          if (
            contactExt__chanId.find(
              (chanId__contEx) =>
                chanId__contEx === chan.channel_id ||
                ((chanId__contEx == 1 || chanId__contEx == 6) &&
                  (chan.api === 33 || chan.api === 34 ) &&
                  chan.status == 1 &&
                  chan.connection == 1)
            )
          ) {
            available_channels.push({
              id: chan.id,
              icon: chan.icon,
              icon_color: chan.icon_color,
              api: chan.api,
              channel_id: chan.channel_id,
              channel_uid: chan.uid,
              type: chan.type,
              channel_phone: chan.phone,
              channel_name: chan.name,
              connection: chan.connection,
              status: chan.status,
              config: chan.config ?? '{}',
            });
          }
        });
      }
    }

    let items = <></>;
    if (available_channels?.length) {
      items = available_channels.map((channel, index) => {
        if (!channel.type) return null;
        let channelActive = channel.status == 1;

        /**
         * Z-API valide status
         */
        if (channel.channel_id === 1 && channel.api === 4) {
          /**
           * Pegando json do config do channel
           */
          let _config = { status: 0 };
          if (isJson(channel.config)) {
            _config = JSON.parse(channel.config);
          }
          /* end */

          if (channel.status === 1 && _config?.status === 203) {
            channelActive = true;
          } else {
            channelActive = false;
          }
        }
        /* end - Z-API valide status */

        return (
          <DropdownItem
            className={`${
              selectedChannel && selectedChannel.id === channel.id
                ? 'dropactive'
                : ' '
            } ${channelActive ? '' : 'channelProblem'}`}
            onClick={() => selectChannel(channel)}
            key={index}
          >
            <Icon
              icon={channel.icon}
              color={
                selectedChannel && selectedChannel.id === channel.id
                  ? 'var(--primary)'
                  : channel.icon_color
              }
              size={20}
            />
            {renderChannelText(channel)}
          </DropdownItem>
        );
      });
    }

    const showCurrentChannelIcon = () => {
      let type =
        selectedChannel && selectedChannel.type ? selectedChannel.type : '';
      let icon =
        selectedChannel && selectedChannel.icon
          ? selectedChannel.icon
          : 'poli-icon pi-whatsapp-fill';
      let icon_color =
        selectedChannel && selectedChannel.icon_color
          ? selectedChannel.icon_color
          : 'var(--whatsapp-color)';
      return <Icon icon={icon} color={icon_color} size={20} />;
    };

    return (
      <>
        <Dropdown direction="up" isOpen={channelSwitch} toggle={toggle}>
          <DropdownToggle color="">{showCurrentChannelIcon()}</DropdownToggle>
          <DropdownMenu className="channelDropdownContainer">
            <div className="channel-selector-search">
              <InputSearch
                onChange={(e) => onUpdateSearchValue(e.target.value)}
                value={currentValueSearch}
                loading={loadingSearch}
                placeholder=""
                clearSearch={onUpdateSearchValue}
              />
            </div>

            {items}
          </DropdownMenu>
        </Dropdown>
      </>
    );
  };

  return <div className="channelDropdown">{availableChannels()}</div>;
}

const mapStateToProps = (state) => ({
  chat: state.chat,
  opennedChat:
    state.chat?.chats?.filter((chat) => {
      return chat.open === true;
    })[0] || [],
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(ChatActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ChannelSelector);
