import React from 'react';

import Profile from '../Certification/steps/Profile/Profile.component';
import ProfileWabaByPoli from '../CertificationWabaByPoli/steps/Profile/Profile.component';
import ChannelAddEditBroker from '../AddChannel/ChannelAddEditBroker.component';
import ChannelAddEditInstagram from '../AddChannel/ChannelAddEditInstagram.component';
import ChannelAddEditWebchat from '../AddChannel/ChannelAddEditWebchat.component';
import ChannelAddEditMessenger from '../AddChannel/ChannelAddEditMessenger.component';

import {
  getChannelExtra,
  channelTypeNames,
} from '../../../../utils/channels/getChannelExtra';

const ChannelFormSelector = ({
  data,
  dataSet,
  userAdmPoliGetChannelFromCustomerId,
}) => {
  const { typeName } = getChannelExtra(data);

  switch (typeName) {
    case channelTypeNames.WABA:
      return (
        <>
          {/* Poli <> Meta */}
          {data.api == 10 ? (
            <ProfileWabaByPoli data={data} />
          ) : (
            <Profile data={data} dataset={dataSet} />
          )}
        </>
      );

    case channelTypeNames.WHATSAPP:
      return (
        <ChannelAddEditBroker
          data={data}
          dataset={dataSet}
          userAdmPoliGetChannelFromCustomerId={
            userAdmPoliGetChannelFromCustomerId
          }
        />
      );

    case channelTypeNames.INSTAGRAM:
      return (
        <ChannelAddEditInstagram
          data={data}
          userAdmPoliGetChannelFromCustomerId={
            userAdmPoliGetChannelFromCustomerId
          }
        />
      );

    case channelTypeNames.WEBCHAT:
      return (
        <ChannelAddEditWebchat
          data={data}
          userAdmPoliGetChannelFromCustomerId={
            userAdmPoliGetChannelFromCustomerId
          }
        />
      );

    case channelTypeNames.MESSENGER:
      return (
        <ChannelAddEditMessenger
          data={data}
          userAdmPoliGetChannelFromCustomerId={
            userAdmPoliGetChannelFromCustomerId
          }
        />
      );

    default:
      return <div>Tipo de canal Inválido!</div>;
  }
};

export default ChannelFormSelector;
