import React from 'react';
import { Button, Icon } from '@polichat/flamboyant';
import Animation from '../../common/animation';

function SaveButton({ title, onClick, isLoading = false }) {
  if (isLoading) {
    return (
      <Animation
        icon="chat-loading"
        style={{
          maxWidth: '150px',
          height: '100%',
          maxHeight: '100px',
        }}
        background="rgba(0, 0, 0, 0)"
      />
    );
  }
  return (
    <Button border="border-none" color="primary"  onClick={onClick}>
      <Icon position={"relative"} top={"2px"} icon={'poli-icon pi-add-circle-fill'} color={'white'} size={20} />
      <span style={{ marginLeft: 5 }}>{title}</span>
    </Button>
  );
}

export default SaveButton;
