import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  Button,
  Col,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
} from '@polichat/flamboyant';

import { useQuickMessageService } from '../../hooks/useQuickMessageService';
import { ModalOptions } from './types';

export function ModalRemoveQuickMessage(props: Readonly<ModalOptions>) {
  const isLoading = useSelector(
    (state: any) => state?.chat?.quickMessages?.loadingDelete
  ) as boolean | undefined;

  const successRemove = useSelector(
    (state: any) => state?.chat?.quickMessages?.removeSuccess
  ) as boolean | undefined;

  const { remove } = useQuickMessageService();

  const handleCloseModal = useCallback(() => {
    props.handleClose();
  }, []);

  const handleRemove = useCallback(() => {
    if (props.message) remove(props.message);
  }, [props.message]);

  useEffect(() => {
    if (successRemove) {
      toast.success(`Mensagem rápida removida com sucesso!`);
      handleCloseModal();
    }
  }, [successRemove]);

  return (
    <Modal isOpen={props.isOpen} toggle={handleCloseModal}>
      <ModalHeader toggle={handleCloseModal}>
        Excluir mensagem rápida
      </ModalHeader>
      <ModalBody>
        <div>
          <p>Tem certeza que deseja remover esta mensagem rápida?</p>
          <Row>
            <Col>
              <FormGroup style={{ textAlign: 'center' }}>
                <Button onClick={handleRemove} color="danger">
                  {!isLoading ? 'Excluir' : 'Removendo...'}
                </Button>
              </FormGroup>
            </Col>
          </Row>
        </div>
      </ModalBody>
    </Modal>
  );
}
