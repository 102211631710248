import * as React from 'react'

export interface PreviewThumbnailProps {
    media: string
    type: string
    body: string
    header: string
    footer: string
    click(): React.MouseEventHandler<HTMLImageElement> | undefined
}

function PreviewThumbnail(props: PreviewThumbnailProps) {
    return (
        <div className="previewThumbnail">
            <div className="previewMedia">
                {props.type === "IMAGE" ? (
                    <img
                        src={props.media}
                        style={{cursor: "pointer", minWidth: "10rem", maxWidth: "10rem"}}
                        onClick={props.click()}
                    ></img>
                ) : props.type === "VIDEO" && (
                    <video controls style={{cursor: "pointer", minWidth: "10rem", maxWidth: "10rem"}} >
                        <source src={props.media} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                )}
                <div className='previewText'>
                    {props.header && (
                    <>
                        <span className='previewSpan' style={{fontWeight: "bold"}}>
                            {props.header}
                        </span>
                    </>)}
                    <span className='previewSpan' style={{opacity: "90%"}}>
                        {props.body}
                    </span>
                    <span className='previewSpan' style={{opacity: "50%"}}>
                        {props.footer}
                    </span>
                </div>
            </div>
        </div>
    )
}

export default PreviewThumbnail;