export default function checkAvatar(name, avatar) {
  // Se o avatar for nulo ou vazio
  if (
    (avatar === '' || avatar === null || avatar === undefined) &&
    name !== null && name !== undefined
  ) {
    //Validação do Name para não aceitar números

    // Iniciais que aparecem quando o usuário não tem avatar
    let initials = name.split(/[\s]/i);
    let doubleInitials = '';
    let size;

    if (initials.length >= 2) {
      size = 2;
    }

    if (initials.length === 1) {
      doubleInitials = initials[0].charAt(0);
    } else {
      for (let i = 0; i < size; i++) {
        doubleInitials = doubleInitials + initials[i].charAt(0);
      }
    }
    return doubleInitials;
  }
  // Se o avatar tiver uma URL então retorna a própria URL
  else {
    return avatar;
  }
}
