import React, { useEffect, useState, useRef } from 'react';

import {
  Label,
  CustomPassword,
  CustomTextArea,
  Icon,
  CustomInput,
  Switch,
  Input,
  MultiSelector,
  Button,
} from '@polichat/flamboyant';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import formatPhone from '../../../../../../utils/formatPhone';
import ChannelsService from '../../../../../../services/tools/channels/channels';
import UsersService from '../../../../../../services/tools/users';
import Congratulations from '../Congratulations/Congratulations.component';
import FacebookStatus from '../FacebookStatus/FacebookStatus.component';
import ProfileWabaPhoto from './ProfileWabaPhoto.component';
import ButtonDeleteChannel from '../../../DeleteChannel/DeleteChannel.componet';
import poliConfig from '../../../../../../utils/poliConfig';
import DeleteChannelModal from '../../../DeleteChannelModal/DeleteChannelModal.component';
import { ChannelsDeleteService } from '../../../../../../services/tools/channels/index';
import { replaceUrlWebsite } from '../utils/replaceUrlWebsiteForHttp';

import {
  Container,
  Section,
  SectionName,
  SectionContent,
  InputsContainer,
  InputItem,
  MultSelectorItem,
  SwitchContainer,
  ButtonContainer,
  PhoneInputContainer,
} from './Profile.styles';
import { useHistory } from 'react-router-dom';

const Profile = ({ data: dataFromDad, dataSet, currentStep }) => {
  const {
    id,
    name,
    phone,
    status,
    homologation,
    users: usersDad,
    waba: wabaFromDad,
    profile: profileDad,
    display_on_webchat: WidgetDad,
  } = dataFromDad || {};

  //code to remove when remover feature flag: bugfix_PS_354
  const state_current_customer_id = useSelector(
    (state) => state.general?.current_customer_id
  );

  const processClassic = dataFromDad.config?.classic;

  const [facebookStatusHide, facebookStatusHideSet] = useState(false);
  const [uploadStatus, setUploadStatus] = useState(0.0); // Upload da imagem de perfil, vai de 0 a 100

  // Estados de requisição
  const [congratulations, congratulationsSet] = useState(false);
  const [sendButtonDisabled, sendButtonDisabledSet] = useState(false);

  const [, setUsers] = useState([]);
  const [usersOptions, usersOptionsSet] = useState([]);
  const [usersSelected, usersSelectedSet] = useState([]);
  // const [multiSelectorValues, setMultiSelectorValues] = useState([]);

  const [verticalList, verticalListSet] = useState([]);

  // Estados locais para lidar com mudanças nos valores de um input
  const [channelName, channelNameSet] = useState(name || '');
  const [channelPhone] = useState(phone || '');
  const [channelWidget, channelWidgetSet] = useState(WidgetDad === '1');
  const [channelStatus, setChannelStatus] = useState(!!status);

  const [addressToken, setAddressToken] = useState(wabaFromDad?.address || '');
  const [token, setToken] = useState(wabaFromDad?.token || '');

  const { about, description, address, email, websites, vertical } =
    profileDad || {};
  const [profileAbout, profileAboutSet] = useState(about || '');
  const [profileDescription, profileDescriptionSet] = useState(
    description || ''
  );
  const [profileAddress, profileAddressSet] = useState(address || '');
  const [profileEmail, profileEmailSet] = useState(email || '');
  const [profileWebsite, profileWebsiteSet] = useState(
    websites ? websites[0] : ''
  );
  const [profileVertical, profileVerticalSet] = useState(vertical || 0);

  const phoneInputRef = useRef(null);
  const history = useHistory();

  useEffect(() => {
    async function fetchUsers() {
      try {
        const { data: users } = await ChannelsService.getAllUsersByCustomer(
          dataFromDad.customer_id
        );
        setUsers(users);

        // buscar todos os usuarios que tem acesso a esse canal
        const { data: usersChannel } = await ChannelsService.getAllUsersChannel(
          dataFromDad.customer_id,
          dataFromDad.id
        );

        //Usado para criar o objeto options que irá alimentar o componente MultiSelector
        if (users && users.length) {
          const _userSelected = [];

          const usersOptions = users.map((user) => {
            const add = {
              value: user.id,
              label: user.name,
            };

            if (Array.isArray(usersChannel)) {
              const _i = usersChannel.findIndex((e) => e.user_id == user.id);
              if (_i >= 0) {
                _userSelected.push(add);
              }
            }

            return add;
          });
          usersOptionsSet(usersOptions);
          usersSelectedSet(_userSelected);
        }
        return;
      } catch (error) {
        console.error(
          'Error in Profile component when getAllUsersByCustomer => ',
          error
        );
        return;
      }
    }

    async function fetchVerticalsWaba() {
      try {
        const result = await ChannelsService.getWabaVerticals({
          customer_id: dataFromDad.customer_id,
        });
        verticalListSet(result);
        return;
      } catch (error) {
        console.log(
          'Error in Profile component when getWabaVerticals => ',
          error
        );
        return;
      }
    }

    fetchUsers();
    fetchVerticalsWaba();
  }, []);

  // Funções utilizadas pelos inputs quando os valores mudarem (OnChange)
  const handleVerticalOnChange = (e) => profileVerticalSet(e.target.value);

  const handleNameChange = (e) => channelNameSet(e.target.value);

  const handleEmailChange = (e) => profileEmailSet(e.target.value);

  const handleAddressTokenChange = (e) => setAddressToken(e.target.value);

  const handleTokenChange = (e) => setToken(e.target.value);

  const handleBusinessAboutChange = (e) => profileAboutSet(e.target.value);

  const handleBusinessDescriptionChange = (e) =>
    profileDescriptionSet(e.target.value);

  const handleBusinessAddressChange = (e) => profileAddressSet(e.target.value);

  const handleWebsiteChange = (e) => profileWebsiteSet(e.target.value);

  const handleShowOnWidgetChange = (e) => channelWidgetSet(e.target.checked);

  const handleChannelStatusChange = (e) => setChannelStatus(e.target.checked);

  const handleGetSelectorValues = (data) => usersSelectedSet(data);

  const handleSendForm = async () => {
    sendButtonDisabledSet(true);
    try {
      let profileObject = null;

      if (poliConfig.bugfix_PS_354) {
        const replacedUrlWebsite = replaceUrlWebsite(profileWebsite);
        profileWebsiteSet(replacedUrlWebsite);

        profileObject = {
          about: profileAbout,
          description: profileDescription,
          address: profileAddress,
          email: profileEmail,
          vertical: profileVertical,
          website1: replacedUrlWebsite,
        };
      } else {
        profileObject = {
          about: profileAbout,
          description: profileDescription,
          address: profileAddress,
          email: profileEmail,
          vertical: profileVertical,
          website1: profileWebsite,
        };
      }

      let wabaObject = {
        token,
        address: addressToken,
      };

      const finalSettingsObject = {
        name: channelName,
        status: channelStatus ? '1' : '0',
        display_on_webchat: channelWidget ? '1' : '0',
        profile: profileObject,
        waba: wabaObject,
        users: usersSelected.map((user) => user.value),
      };

      const { data, error } = await ChannelsService.updateProfile({
        customer_id: dataFromDad.customer_id,
        channel_id: dataFromDad.id,
        formData: finalSettingsObject,
      });

      if (data) {
        if (homologation) {
          const step = processClassic ? 0 : 5;
          await ChannelsService.updateStepHomologation({
            customer_id: dataFromDad.customer_id,
            channel_id: data.id,
            step,
          });
          if (processClassic) {
            congratulationsSet(true);
          } else {
            facebookStatusHideSet(false);
            sendButtonDisabledSet(false);
            dataSet({ ...dataFromDad, homologation: step });
          }
          return;
        }

        toast.success('Canal editado com sucesso');
        sendButtonDisabledSet(false);
      } else {
        if (homologation) {
          toast.error(
            error?.message ||
              'Houve um erro ao continuar com sua homologação. Caso o erro persista, entre em contato com o suporte.'
          );
          sendButtonDisabledSet(false);
          return;
        }

        toast.error(error?.message || 'Erro ao salvar os dados');
        sendButtonDisabledSet(false);
      }

      return;
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          'Houve um erro ao continuar com sua homologação. Caso o erro persista, entre em contato com o suporte.'
      );
      console.log(
        'Error in Profile Component when uptadeStepHomologation. Error => ',
        error
      );
      sendButtonDisabledSet(false);
      return;
    }
  };

  if (congratulations) {
    return <Congratulations />;
  }

  if (!facebookStatusHide && !processClassic && homologation == 5) {
    return (
      <FacebookStatus
        data={{
          ...dataFromDad,
          name: channelName,
          status: channelStatus,
        }}
        dataSet={dataSet}
        showProfile={() => facebookStatusHideSet(true)}
      />
    );
  }

  return (
    <>
      <Container>
        <Section>
          <div className='buttonAndText'>
            <SectionName>Configurações</SectionName>
              <Button
                gradient="primary"
                onClick={() =>
                  history.push({
                    pathname: `/tools/channels/migrate/${dataFromDad.id}`,
                  })
                }
              >
                Migrar canal para Poli BSP
              </Button>
          </div>

          <SectionContent>
            <InputsContainer>
              <InputItem>
                <Label id="channel-name-label" for="channel-name">
                  <Icon
                    icon="poli-icon pi-channels-fill"
                    color="var(--green-polichat)"
                    size="20"
                  />
                  Nome do Canal
                </Label>
                <Input
                  type="text"
                  name="channel-name"
                  id="channel-name"
                  value={channelName}
                  onChange={handleNameChange}
                  maxLength={50}
                />
                <br />
                <Label id="phone-number-label" for="phone-number">
                  <Icon
                    icon="poli-icon pi-smartphone-fill"
                    color="var(--green-polichat)"
                    size="20"
                  />
                  Telefone com DDD e código de país*
                </Label>
                <PhoneInputContainer>
                  <Input
                    type="text"
                    value={formatPhone(channelPhone)}
                    disabled="true"
                    style={{ borderColor: 'var(--secondary-font-color)' }}
                  />
                  <Button
                    onClick={(e) => {
                      navigator.clipboard.writeText(phone);
                      toast.success('Telefone copiado com sucesso!');
                    }}
                  >
                    <Icon icon="poli-icon pi-copy-fill" size="30" />
                    Copiar
                  </Button>
                </PhoneInputContainer>
                <div style={{ color: 'var(--danger)', fontSize: '0.7rem' }}>
                  *Não é possível alterar o número de telefone de um canal WABA
                  depois de criado
                </div>
                <br />
                <MultSelectorItem>
                  <Label
                    id="select-users-channel-label"
                    for="select-users-channel"
                  >
                    <Icon
                      icon="poli-icon pi-perfil-fill"
                      color={'var(--green-polichat)'}
                      size="20"
                    />
                    Usuários que poderão utilizar este canal
                  </Label>
                  <MultiSelector
                    name="select-users-channel"
                    id="select-users-channel"
                    options={usersOptions}
                    values={usersSelected}
                    selectedValues={handleGetSelectorValues}
                  />
                </MultSelectorItem>
              </InputItem>

              <InputItem>
                <ProfileWabaPhoto channelData={dataFromDad} />
              </InputItem>
            </InputsContainer>

            <InputsContainer>
              {poliConfig.bugfix_PS_354 ? (
                <>
                  <InputItem>
                    <Label id="address-api-label" for="address-api">
                      <Icon
                        icon="poli-icon pi-link-fill"
                        color="var(--green-polichat)"
                        size="20"
                      />
                      ADDRESS
                    </Label>
                    <CustomPassword
                      type="password"
                      name="address-api"
                      autoComplete="new-password"
                      id="address-api"
                      value={addressToken}
                      onChange={handleAddressTokenChange}
                    />
                  </InputItem>

                  <InputItem>
                    <Label id="api-key-label" for="api-key">
                      <Icon
                        icon="poli-icon pi-key-fill"
                        color="var(--green-polichat)"
                        size="20"
                      />
                      API Key
                    </Label>
                    <CustomPassword
                      type="password"
                      name="api-key"
                      autoComplete="new-password"
                      id="api-key"
                      value={token}
                      onChange={handleTokenChange}
                    />
                  </InputItem>
                </>
              ) : (
                <>
                  <InputItem>
                    <Label id="address-api-label" for="address-api">
                      <Icon
                        icon="poli-icon pi-link-fill"
                        color="var(--green-polichat)"
                        size="20"
                      />
                      ADDRESS
                    </Label>
                    <CustomPassword
                      type="password"
                      name="address-api"
                      id="address-api"
                      value={addressToken}
                      onChange={handleAddressTokenChange}
                    />
                  </InputItem>

                  <InputItem>
                    <Label id="api-key-label" for="api-key">
                      <Icon
                        icon="poli-icon pi-key-fill"
                        color="var(--green-polichat)"
                        size="20"
                      />
                      API Key
                    </Label>
                    <CustomPassword
                      type="password"
                      name="api-key"
                      id="api-key"
                      value={token}
                      onChange={handleTokenChange}
                    />
                  </InputItem>
                </>
              )}
            </InputsContainer>
          </SectionContent>
        </Section>
        <Section>
          <SectionName>Profile About</SectionName>

          <SectionContent>
            <InputItem>
              <Label id="address-api-label" for="address-api">
                <Icon
                  icon="poli-icon pi-text-left-fill"
                  color="var(--green-polichat)"
                  size="20"
                />
                Escreva sobre seu negócio:
              </Label>
              <Input
                type="text"
                name="business-about"
                id="business-about"
                value={profileAbout}
                onChange={handleBusinessAboutChange}
                maxLength={139}
              />
            </InputItem>
          </SectionContent>
        </Section>

        <Section>
          <SectionName>Business Profile</SectionName>

          <SectionContent>
            <InputItem>
              <Label id="address-api-label" for="address-api">
                <Icon
                  icon="poli-icon pi-text-left-fill"
                  color="var(--green-polichat)"
                  size="20"
                />
                Descrição sobre seu negócio:
              </Label>
              <CustomTextArea
                type="textarea"
                name="business-description"
                id="business-description"
                value={profileDescription}
                onChange={handleBusinessDescriptionChange}
                maxLength={256}
              />
            </InputItem>
            <InputsContainer>
              <InputItem>
                <Label id="business-address-label" for="business-address">
                  <Icon
                    icon="poli-icon pi-send-fill"
                    color="var(--green-polichat)"
                    size="20"
                  />
                  Endereço
                </Label>
                <Input
                  type="text"
                  name="business-address"
                  id="business-address"
                  value={profileAddress}
                  onChange={handleBusinessAddressChange}
                  maxLength={256}
                />
              </InputItem>

              <InputItem>
                <Label id="business-email" for="business-email">
                  <Icon
                    icon="poli-icon pi-mail-fill"
                    color="var(--green-polichat)"
                    size="20"
                  />
                  E-mail
                </Label>
                <Input
                  type="email"
                  name="business-email"
                  id="business-email"
                  value={profileEmail}
                  onChange={handleEmailChange}
                  maxLength={128}
                />
              </InputItem>
            </InputsContainer>

            <InputsContainer>
              <InputItem>
                <Label id="business-niche-label" for="business-niche">
                  <Icon
                    icon="poli-icon pi-shop-fill"
                    color="var(--green-polichat)"
                    size="20"
                  />
                  Vertical de negócio:
                </Label>

                <CustomInput
                  type="select"
                  id="business-niche"
                  name="business-niche"
                  onChange={handleVerticalOnChange}
                  value={profileVertical}
                >
                  <option value={0}>Selecione a Vertical de Negócio...</option>
                  {verticalList?.map(({ id, title }) => {
                    return (
                      <option value={id} key={id}>
                        {title}
                      </option>
                    );
                  })}
                </CustomInput>
              </InputItem>

              <InputItem>
                <Label id="business-website" for="business-website">
                  <Icon
                    icon="poli-icon pi-link-fill"
                    color="var(--green-polichat)"
                    size="20"
                  />
                  Website
                </Label>
                <Input
                  type="url"
                  name="business-website"
                  id="business-website"
                  onChange={handleWebsiteChange}
                  value={profileWebsite}
                  maxLength={256}
                />
              </InputItem>
            </InputsContainer>

            <InputsContainer>
              <InputItem className="py-3">
                <CustomInput
                  type="checkbox"
                  id="show-widget-at-webchat"
                  label="Exibir canal no Widget do webchat."
                  checked={channelWidget}
                  onChange={handleShowOnWidgetChange}
                />
              </InputItem>
              <InputItem>
                <Label id="channel-status" for="channel-status">
                  <Icon
                    icon="poli-icon pi-channels-fill"
                    color="var(--green-polichat)"
                    size="20"
                  />
                  Status do Canal
                </Label>
                <SwitchContainer>
                  <p>Inativo</p>
                  <Switch
                    color="primary"
                    isChecked={channelStatus}
                    onClick={handleChannelStatusChange}
                  />
                  <p>Ativo {!!channelStatus}</p>
                </SwitchContainer>
              </InputItem>
            </InputsContainer>
          </SectionContent>

          <ButtonContainer>
            <Button
              gradient="primary"
              size="lg"
              onClick={handleSendForm}
              disabled={sendButtonDisabled}
            >
              {homologation ? 'Solicitar Confirmação' : 'Salvar Dados'}
            </Button>
            <Button
              color="danger"
              size="lg"
              style={{ width: '9rem' }}
              onClick={() => {
                history.push('/tools/channels');
              }}
            >
              Cancelar
            </Button>
          </ButtonContainer>
        </Section>
      </Container>
    </>
  );
};

export default Profile;
