import axios from 'axios';
import ObjectToQueryString from '../../lib/ObjectToQueryString';

const defaultHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

const defaultConfig = {
  headers: defaultHeaders,
};

const getAllDepartments = async (params) => {
  try {
    // Tenta buscar a página que foi passada via params
    const url =
      process.env?.REACT_APP_HTTP_DEPARTMENT_SERVICE +
      `/departments` +
      (params ? '?' + ObjectToQueryString(params) : '');

    const result = await axios.get(url, defaultConfig);
    // console.log(result)
    return result;
  } catch (errorOne) {
    // Caso dê erro, então tenta buscar a página 1
    try {
      params.page = 1;
      const url =
        process.env?.REACT_APP_HTTP_DEPARTMENT_SERVICE +
        `/departments` +
        (params ? '?' + ObjectToQueryString(params) : '');

      const result = await axios.get(url, defaultConfig);
      return result;
    } catch (errorTwo) {
      return errorTwo;
    }
  }
};

const updateStatusDepartment = (data) => {
  const url =
    process.env?.REACT_APP_HTTP_DEPARTMENT_SERVICE +
    `/departments/${data.departmentID}`;

  const formmatedObj = { customer_id: data.customerID, status: data.status };
  const result = axios.patch(url, formmatedObj, defaultConfig);
  return result;
};

const createDepartment = (data) => {
  const url = process.env?.REACT_APP_HTTP_DEPARTMENT_SERVICE + `/departments`;

  const result = axios.post(url, data, defaultConfig);
  return result;
};

const updateDepartment = (event) => {
  const departmentID = event.id;
  const {bodyData} = event;
  const url =
    process.env?.REACT_APP_HTTP_DEPARTMENT_SERVICE +
    `/departments/${departmentID}`;

  const result = axios.patch(url, bodyData, defaultConfig);
  return result;
};

const deleteDepartment = (data) => {
  const url =
    process.env?.REACT_APP_HTTP_DEPARTMENT_SERVICE +
    `/departments/${data.departmentID}`;
  const result = axios.delete(url, defaultConfig);
  return result;
};

export default {
  getAllDepartments,
  updateStatusDepartment,
  createDepartment,
  updateDepartment,
  deleteDepartment,
};
