import { useCallback, useEffect, useState } from 'react';
import { initFacebookSDK } from '../../../../../pages/ads/Facebook/sdk/initFacebookSDK';
import { ReturnResponse } from '../../../channels/CertificationWabaByPoli/MetaSDK/EmbeddedSignupFlow';

export function useFacebookSdkService() {
  const [started, setStarted] = useState(false);
  const [hasError, setError] = useState(false);
  const [data, setData] = useState<ReturnResponse[]>();
  const [loading, setLoading] = useState(true);

  // ---------------------------------------------s
  // Functions
  const handleError = useCallback((error: boolean) => {
    setError(error);
  }, []);

  const handleData = useCallback((data: ReturnResponse[]) => {
    setData(data);
  }, []);
  // ---------------------------------------------
  // Effects
  useEffect(() => {
    initFacebookSDK().then(() => {
      setTimeout(() => {
        setStarted(true);
        setLoading(false);
      }, 1500);
    });
  }, []);
  // ---------------------------------------------
  // Transformations
  // ---------------------------------------------
  // API

  return {
    hasError,
    handleError,
    started,
    data,
    handleData,
    loading,
  };
}
