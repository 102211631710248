import styled from 'styled-components';

export const Container = styled.div`
  background-color: var(--secondary-background-color);
  max-height: 90%;

  div {
    padding: 0.5rem;
    border-bottom: 1px solid var(--border-color);

    & .form-group {
      padding: 0;
      border-bottom: 0;
    }
  }

  div + div {
    border-bottom: 0;

    max-height: 300px;
    overflow-y: auto;
  }

  @media (max-width: 600px) {
    padding: 0.5rem;
  }
`;

export const VariableItem = styled.li`
  border-bottom: 1px solid var(--border-color);
  display: flex;
  flex-direction: column;
  padding: 0.3rem 0.5rem;
  margin: 0 0.5rem;
  cursor: pointer;
  transition: 0ms.2s;

  :hover {
    background-color: var(--secondary-background-color-hover);
  }

  span {
    color: var(--primary);
  }

  span + span {
    color: var(--textDefault);
  }
`;
