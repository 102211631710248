interface ImageContentProps {
  image?: string;
  title: string;
  subTitle: string;
  ImgElement?: React.ElementType;
}

export function ImageContent({
  image,
  title,
  subTitle,
  ImgElement,
}: ImageContentProps) {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        textAlign: 'center',
        marginTop: '2.5625rem',
      }}
    >
      {ImgElement ? <ImgElement /> : (
        <img src={image} alt="" height={392} width={392}></img>
      )}
      <div
        style={{
          maxWidth: '738px',
          marginTop: '2.4rem',
          marginBottom: '20px',
          color: 'var(--secondary-font-color)',
          fontStyle: 'normal',
          lineHeight: '2.1875rem',
        }}
      >
        <span style={{ fontSize: '1.5rem', fontWeight: '700' }}>{title}</span>
        <br></br>
        <span style={{ fontSize: '1rem', fontWeight: '400' }}>{subTitle}</span>
      </div>
    </div>
  );
}
