import { useForm } from 'react-hook-form';

export function useLocalForm() {
  const form = useForm({
    defaultValues: {
      channel_credit_id: '',
    },
  });

  const credit_id = form.watch('channel_credit_id');

  return {
    ...form,
    watchedValue: {
      credit_id,
    },
  };
}
