export function timeHasPassed(createdAt) {
  let time = new Date(createdAt).getTime();

  if ((time + '').length === 13) {
    time = Math.floor(time / 1000);
  }
  if (time) {
    let now = Math.floor(Date.now() / 1000);
    let diff = now - time;

    if (diff > 0) {
      let mins = Math.floor(diff / 60);

      if (mins <= 60) {
        if (mins <= 1) {
          return `há 1 minuto`;
        } else {
          return `há ${mins} minutos`;
        }
      } else {
        let hours = Math.floor(mins / 60);
        if (hours <= 24) {
          if (hours > 1) return `há ${hours} horas`;
          else return 'há 1 hora';
        } else {
          let days = Math.floor(hours / 24);
          if (days <= 6) {
            if (days > 1) return `há ${days} dias`;
            else return 'há 1 dia';
          } else {
            if (days === 7) {
              return `há 1 semana`;
            } else {
              let months = Math.floor(days / 30);
              if (months <= 1) {
                return `há 1 mês`;
              } else {
                if (months < 12) {
                  return `há ${months} meses`;
                } else {
                  if (months === 12) {
                    return `há 1 ano`;
                  } else {
                    return `há mais de 1 ano`;
                  }
                }
              }
            }
          }
        }
      }
    } else if (diff === 0) {
      return `Agora`;
    } else {
      return '';
    }
  } else {
    return '';
  }
}

export function transformValueToMonetary(value) {
  const formattedValue = value.toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  return formattedValue;
}
