import { DtoDialog360Channel } from '../service/DtoDialog360Channel';
import { LocalFormValues } from './useLocalForm/types';
export function getLocalFormValuesFromDtoDialog360Channel(
  data: DtoDialog360Channel
): LocalFormValues {
  return {
    name: data.name,
    phone: data.phone,
    display_on_webchat: Boolean(Number(data.display_on_webchat)),
    status: Boolean(data.status),
    users: data.users,
  };
}

export function getDtoDialog360ChannelFromLocalFormValues(
  changeData: LocalFormValues
): Pick<DtoDialog360Channel, 'display_on_webchat' | 'status' | 'users'> {
  return {
    users: changeData.users,
    display_on_webchat: String(Number(changeData.display_on_webchat)),
    status: Number(changeData.status),
  };
}
