import { useEffect, useState } from 'react';

import { Button, Icon, ModalFooter } from '@polichat/flamboyant';

import { CustomModal } from './MessagePreview.styles';

function CreateModalPreview({ list, open, close }) {
  //Modal details section
  const [modalSectionDetails, setModalSectionDetails] = useState(false);

  useEffect(() => {
    if (open) {
      setModalSectionDetails(true);
    }
  }, [open]);

  const toggleModalSectionDetails = () => {
    setModalSectionDetails(false);
    close();
  };

  return (
    <>
      <CustomModal
        isOpen={modalSectionDetails}
        toggle={toggleModalSectionDetails}
      >
        <header>
          <div className="click">
            <Icon
              outline
              icon="poli-ícone pi-close-fill"
              color={'var(--gray-25)'}
              size={28}
              onClick={toggleModalSectionDetails}
            />
          </div>
          <h2>
            <b>{list.button?.text}</b>
          </h2>
        </header>
        {list.section?.map((sec) => (
          <div key={sec.id}>
            <h3>
              <b>{sec.text}</b>
            </h3>
            {sec.rows?.map((r) => (
              <div key={r.id} className="options">
                <div>
                  <h4>{r.messageOption.title}</h4>
                  <p>{r.messageOption.description}</p>
                </div>
                <Icon icon="poli-icon pi-point-line" size={28} />
              </div>
            ))}
          </div>
        ))}
        <ModalFooter>
          <Button
            outline
            onClick={toggleModalSectionDetails}
            className="buttonActionModal"
          >
            Voltar
          </Button>
        </ModalFooter>
      </CustomModal>
    </>
  );
}
export default CreateModalPreview;
