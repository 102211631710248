import React from 'react';
import styled from 'styled-components';

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from '@polichat/flamboyant';

import channelAddWabaClassicModalImage from './channelAddWabaClassicModalImage.svg';

const ModalStyled = styled(Modal)`
  .modal-content {
    padding: 0;
    border-radius: 1rem !important;
    max-width: 628px;
  }
  .modal-header {
    padding: 0;
    background-color: #fafafa;
    border-radius: 1rem !important;
    cursor: pointer;
  }
  .modal-body {
    margin-bottom: 0;
  }
  .modal-footer {
    justify-content: space-evenly !important;
  }
  button {
    max-width: 200px;
  }
`;

const ExternalLink = styled.a`
  text-decoration: none;
  color: #20EFE5;
`

const ChannelAddWabaClassicModal = ({
  isOpen,
  handleCloseModal,
  handleProccessClassicNext,
  handleProccessNewNext,
}) => {
  return (
    <ModalStyled isOpen={isOpen} toggle={handleCloseModal}>
      <ModalHeader>
        <img src={channelAddWabaClassicModalImage} onClick={handleCloseModal}/>
      </ModalHeader>
      <ModalBody>
        <p>Tem certeza que deseja fazer o processo de homologação antigo?</p>

        <p>
          Este processo pode demorar cerca de 30 dias e só após a homologação
          você terá a sua conta disponível na WABA.{' '}
        </p>

        <p>
          Por outro lado, o processo moderno te permite utilizar a WABA
          imediatamente (com algumas ressalvas). <ExternalLink target='_blank' rel="noopener noreferrer" href="https://wa.me/556282361759"> Procure nosso suporte em caso
          de dúvidas </ExternalLink>
        </p>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={handleProccessClassicNext}>
          Prosseguir no formato clássico
        </Button>
        <Button color="primary" onClick={handleProccessNewNext}>
          Ir para o novo processo de homologação
        </Button>{' '}
      </ModalFooter>
    </ModalStyled>
  );
};
export default ChannelAddWabaClassicModal;
