import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// types
import { Customer } from '../../../channels/CertificationWabaByPoli/steps/FillForm/FillForm.component';

export function useCustomer() {
  const [current, setCurrent] = useState<Customer>();

  const customers = useSelector(
    // @ts-ignore
    (state) => state?.general?.user?.customers
  );

  const customer_id: number = useSelector(
    // @ts-ignore
    (state) => state?.general?.current_customer_id
  );

  const timezone: number = useSelector(
    // @ts-ignore
    (state) => state?.general?.user?.settings?.timezone[0]?.offset
  );

  // ---------------------------------------------
  // Functions
  // ---------------------------------------------
  // Effects
  useEffect(() => {
    if (customers && Array.isArray(customers) && customer_id) {
      const found = customers.find((e) => e.id === customer_id);
      if (found) {
        found.timezone = timezone;
        setCurrent(found);
      }
    }
  }, [customers, customer_id, timezone]);
  // ---------------------------------------------
  // Transformations
  // ---------------------------------------------
  // API

  return {
    current,
  };
}
