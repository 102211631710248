export const ActionTypes = {
  GET_DEPARTMENTS: '@TOOLS/DEPARTMENTS/GET_DEPARTMENTS',
  GET_DEPARTMENTS_SUCCESS: '@TOOLS/DEPARTMENTS/GET_DEPARTMENTS_SUCCESS',
  GET_DEPARTMENTS_FAILURE: '@TOOLS/DEPARTMENTS/GET_DEPARTMENTS_FAILURE',

  UPDATE_STATUS_DEPARTMENT: '@TOOLS/DEPARTMENTS/UPDATE_STATUS_DEPARTMENT',
  UPDATE_STATUS_DEPARTMENT_SUCCESS:
    '@TOOLS/DEPARTMENTS/UPDATE_STATUS_DEPARTMENT_SUCCESS',
  UPDATE_STATUS_DEPARTMENT_FAILURE:
    '@TOOLS/DEPARTMENTS/UPDATE_STATUS_DEPARTMENT_FAILURE',

  CREATE_DEPARTMENT: '@TOOLS/DEPARTMENTS/CREATE_DEPARTMENT',
  CREATE_DEPARTMENT_SUCCESS: '@TOOLS/DEPARTMENTS/CREATE_DEPARTMENT_SUCCESS',
  CREATE_DEPARTMENT_FAILURE: '@TOOLS/DEPARTMENTS/CREATE_DEPARTMENT_FAILURE',

  UPDATE_DEPARTMENT: '@TOOLS/DEPARTMENTS/UPDATE_DEPARTMENT',
  UPDATE_DEPARTMENT_SUCCESS: '@TOOLS/DEPARTMENTS/UPDATE_DEPARTMENT_SUCCESS',
  UPDATE_DEPARTMENT_FAILURE: '@TOOLS/DEPARTMENTS/UPDATE_DEPARTMENT_FAILURE',

  DELETE_DEPARTMENT: '@TOOLS/DEPARTMENTS/DELETE_DEPARTMENT',
  DELETE_DEPARTMENT_SUCCESS: '@TOOLS/DEPARTMENTS/DELETE_DEPARTMENT_SUCCESS',
  DELETE_DEPARTMENT_FAILURE: '@TOOLS/DEPARTMENTS/DELETE_DEPARTMENT_FAILURE',

  SEARCH_USERS_BY_CUSTOMER_ID: '@TOOLS/DEPARTMENTS/SEARCH_USERS_BY_CUSTOMER_ID',
  SEARCH_USERS_BY_CUSTOMER_ID_SUCCESS:
    '@TOOLS/DEPARTMENTS/SEARCH_USERS_BY_CUSTOMER_ID_SUCCESS',
  SEARCH_USERS_BY_CUSTOMER_ID_FAILURE:
    '@TOOLS/DEPARTMENTS/SEARCH_USERS_BY_CUSTOMER_ID_FAILURE',

  OPEN_CHOOSE_ICON_MODAL: '@TOOLS/DEPARTMENTS/OPEN_CHOOSE_ICON_MODAL',
  CLOSE_CHOOSE_ICON_MODAL: '@TOOLS/DEPARTMENTS/CLOSE_CHOOSE_ICON_MODAL',
};

// Actions used to list all or list one department.
export const getDepartments = (params = null) => ({
  type: ActionTypes.GET_DEPARTMENTS,
  params,
});

export const getDepartmentsSuccess = (data) => ({
  type: ActionTypes.GET_DEPARTMENTS_SUCCESS,
  data,
});

export const getDepartmentsFailure = (error) => ({
  type: ActionTypes.GET_DEPARTMENTS_FAILURE,
  error,
});

// Actions used when update status department -> 1 => active 0 => deactive
export const updateStatusDepartment = (data = null) => ({
  type: ActionTypes.UPDATE_STATUS_DEPARTMENT,
  data,
});

export const updateStatusDepartmentSuccess = (data, requestData) => ({
  type: ActionTypes.UPDATE_STATUS_DEPARTMENT_SUCCESS,
  data,
  requestData,
});

export const updateStatusDepartmentFailure = (error) => ({
  type: ActionTypes.UPDATE_STATUS_DEPARTMENT_FAILURE,
  error,
});

// Actions used when create new department
export const createDepartment = (data) => ({
  type: ActionTypes.CREATE_DEPARTMENT,
  data,
});

export const createDepartmentSuccess = (data) => ({
  type: ActionTypes.CREATE_DEPARTMENT_SUCCESS,
  data,
});

export const createDepartmentFailure = (error) => ({
  type: ActionTypes.CREATE_DEPARTMENT_FAILURE,
  error,
});

// Actions used when update  an existent department
export const updateDepartment = (id, bodyData) => ({
  type: ActionTypes.UPDATE_DEPARTMENT,
  id,
  bodyData,
});

export const updateDepartmentSuccess = (data) => ({
  type: ActionTypes.UPDATE_DEPARTMENT_SUCCESS,
  data,
});

export const updateDepartmentFailure = (error) => ({
  type: ActionTypes.UPDATE_DEPARTMENT_FAILURE,
  error,
});

// Actions used when DELETE an existent department
export const deleteDepartment = (data) => ({
  type: ActionTypes.DELETE_DEPARTMENT,
  data,
});

export const deleteDepartmentSuccess = (data) => ({
  type: ActionTypes.DELETE_DEPARTMENT_SUCCESS,
  data,
});

export const deleteDepartmentFailure = (error) => ({
  type: ActionTypes.DELETE_DEPARTMENT_FAILURE,
  error,
});

// Actions used to search users by customer_id and put on global redux state
export const searchUsersByCustomerID = (params = null) => ({
  type: ActionTypes.SEARCH_USERS_BY_CUSTOMER_ID,
  params,
});

export const searchUsersByCustomerIDSuccess = (data) => ({
  type: ActionTypes.SEARCH_USERS_BY_CUSTOMER_ID_SUCCESS,
  data,
});

export const searchUsersByCustomerIDFailure = (error) => ({
  type: ActionTypes.SEARCH_USERS_BY_CUSTOMER_ID_FAILURE,
  error,
});

export function openChooseIconModal() {
  return {
    type: ActionTypes.OPEN_CHOOSE_ICON_MODAL,
  };
}

export function closeChooseIconModal() {
  return {
    type: ActionTypes.CLOSE_CHOOSE_ICON_MODAL,
  };
}
