import { put, all, call, takeLatest } from 'redux-saga/effects';
import {
  ActionTypes,
  createCategorySuccess,
  createCategoryFailure,
  updateCategorySuccess,
  updateCategoryFailure
} from './actions';

import ProductService from '../../../../services/shopping/product';

function* createCategoryRequest(event) {
  try {
    yield call(ProductService.createCategory, event.data);

    yield put(createCategorySuccess());
  } catch (e) {
    yield put(createCategoryFailure(e));
  }
}

function* updateCategoryRequest(event) {
  try {
    yield call(ProductService.updateCategory, event.id, event.data);

    yield put(updateCategorySuccess());
  } catch (e) {
    yield put(updateCategoryFailure(e));
  }
}

export default all([
  takeLatest(ActionTypes.CREATE_CATEGORY, createCategoryRequest),
  takeLatest(ActionTypes.UPDATE_CATEGORY, updateCategoryRequest),
]);
