import styled from 'styled-components';

export const ActionGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 50%;
  gap: 3rem;
  margin: 0.85rem auto 0 auto;

  & button:first-child {
    margin: 0 1rem;
  }

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
    gap: 1rem;
    & button:first-child {
      margin: 0;
    }
  }
`;
