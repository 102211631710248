const env = process.env ?? null;

const apiGatewayBaseURL = new URL(env?.REACT_APP_API_GATEWAY_HOST).origin;
const IN_PRODUCTION = env?.REACT_APP_ENV === 'production';

const poliConfig = {
  apiGatewayBaseURL,
  channelCustomerBaseURL: `${apiGatewayBaseURL}/channel-customer`,
  nanoAudioBaseURL: `${apiGatewayBaseURL}/nanoaudio`,
  cdnBaseURL: env?.REACT_APP_CDN_BASE_URL,

  // Feature flags
  inProduction: IN_PRODUCTION, // false to production
  featureOnboardingWaba: true,
  feature_polipay_mercadopago: true, //false to production
  feature_polichat_canais: true, //false to production
  feature_polichat_message_builder: !IN_PRODUCTION, //false to production
  bugfix_PS_385: !IN_PRODUCTION,
  feature_enable_customer_tester_id: env?.REACT_APP_CUSTOMER_TEST_ID,
  bugfix_PS_384: !IN_PRODUCTION,
  bugfix_PS_354: !IN_PRODUCTION,
  bugfix_PS_662: !IN_PRODUCTION,
  waba_homologation_classic: true,
  bsp_enable: env?.REACT_APP_BSP_ENABLE,
  bsp_enable_custumer: [1],
  ads_enable: env?.REACT_APP_ADS_ENABLE,
  ads_enable_custumer: [1, 36],

  customers_test_instagram: [
    10548, 24075, 27397, 2715, 37391, 36838, 25627, 3026, 12787, 7926, 41851,
    9594, 5675, 43559, 21276, 36421, 38263, 32181, 27437, 26907, 41801, 1,
    31604, 28325, 44953, 44522, 44710, 23182, 25229, 29243, 45158, 1463,
  ], //customers_id Poli on PROD
  customers_enable_homologation_classic_waba: [31604], //customers_id Poli on PROD
  customers_enable_new_channels: [31604, 1], //customers_id Poli on PROD

  poliAppLaravelUrl:
    localStorage.getItem('current_laravel_url') ?? env?.REACT_APP_LARAVEL_URL,

  poliAppNanoAudioUrl: env?.REACT_APP_NANO_AUDIO_URL,

  channelsCantReplyMessage: env?.REACT_APP_CHANNELS_CANT_REPLY_MESSAGE
    ? env?.REACT_APP_CHANNELS_CANT_REPLY_MESSAGE.split(',')
    : [],
  channelsCantSendAudioMessage: env?.REACT_APP_CHANNELS_CANT_SEND_AUDIO_MESSAGE
    ? env?.REACT_APP_CHANNELS_CANT_SEND_AUDIO_MESSAGE.split(',')
    : [],
  displayDiscountButton: env?.REACT_APP_DISPLAY_DISCOUNT_BUTTON || false,
  canShowProviderOptionOnWhatsappForm:
    env?.REACT_APP_PS_1434_SHOW_CHANNEL_WHASTAPP_PROVIDER == 'true' || false,
};

export default poliConfig;
