import { put, all, call, takeLatest, takeEvery } from 'redux-saga/effects';
import {
  ActionTypes,
  getOrdersSuccess,
  getOrdersFailure,
  getOrderDetailSuccess,
  getOrderDetailFailure,
  deleteOrderSuccess,
  deleteOrderFailure,
  getOrdersStatusSuccess,
  getOrdersStatusFailure,
  getPaymentMethodsSuccess,
  getPaymentMethodsFailure,
  getPaymentGatewaysSuccess,
  getPaymentGatewaysFailure,
} from './actions';

import OrderService from '../../../../services/shopping/order';

function* getOrdersRequest(event) {
  try {
    let result = yield call(OrderService.getOrders, event.params);

    if (result.data && result.data.per_page) {
      let data = {
        list: result.data.data,
        pagination: {
          total_registers_server: result.data.total,
          registers_per_page: result.data.per_page,
          current_page: result.data.current_page,
          last_page: result.data.last_page,
        },
      };
      yield put(getOrdersSuccess(data));
    } else {
      yield put(getOrdersFailure({}));
    }
  } catch (e) {
    yield put(getOrdersFailure(e));
  }
}

function* getOrderDetailRequest(event) {
  try {
    let result = yield call(OrderService.getOrderDetail, event.id);

    let data = result.data;

    yield put(getOrderDetailSuccess(data));
  } catch (e) {
    yield put(getOrderDetailFailure(e));
  }
}

function* deleteOrderRequest(event) {
  try {
    yield call(OrderService.deleteOrder, event.id);

    yield put(deleteOrderSuccess(event.id));
  } catch (e) {
    yield put(deleteOrderFailure(e));
  }
}

function* getOrdersStatusRequest() {
  try {
    let result = yield call(OrderService.getOrderStatus);
    if (result.data) {
      yield put(getOrdersStatusSuccess(result.data));
    } else {
      yield put(getOrdersStatusFailure({}));
    }
  } catch (e) {
    yield put(getOrdersStatusFailure(e));
  }
}

function* getPaymentMethodsRequest() {
  try {
    let result = yield call(OrderService.getPaymentMethods);
    if (result.data) {
      yield put(getPaymentMethodsSuccess(result.data));
    } else {
      yield put(getPaymentMethodsFailure({}));
    }
  } catch (e) {
    yield put(getPaymentMethodsFailure(e));
  }
}

function* getPaymentGatewaysRequest() {
  try {
    let result = yield call(OrderService.getPaymentGateways);
    if (result.data) {
      yield put(getPaymentGatewaysSuccess(result.data));
    } else {
      yield put(getPaymentGatewaysFailure({}));
    }
  } catch (e) {
    yield put(getPaymentGatewaysFailure(e));
  }
}

export default all([
  takeLatest(ActionTypes.GET_ORDERS, getOrdersRequest),
  takeLatest(ActionTypes.GET_ORDER_DETAIL, getOrderDetailRequest),
  takeEvery(ActionTypes.DELETE_ORDER, deleteOrderRequest),
  takeLatest(ActionTypes.GET_ORDERS_STATUS, getOrdersStatusRequest),
  takeLatest(ActionTypes.GET_PAYMENT_METHODS, getPaymentMethodsRequest),
  takeLatest(ActionTypes.GET_PAYMENT_GATEWAYS, getPaymentGatewaysRequest),
]);
