import { ReportEntity } from "src/pages/report/shared/components/Export";
import { ORDINATION_TYPES, OrdinationProps } from "../../../shared/types/Ordination"

export interface TableProps {
  records?: ReportEntity[];
  loading?: boolean;
}

export interface OrderTable {
  id: string;
  title: string
  text?: string
}

export const DEFAULT_ORDINATION_CONFIG: OrdinationProps = {
  order_by: 'request_date',
  order: ORDINATION_TYPES.DESCENDING
};

export const ORDERS_TABLE_TOOLTIP: OrderTable[] = [
  { id: 'id_report', title: 'Identificador' },
  { id: 'title', title: 'Título do Relatório' },
  { id: 'user_name', title: 'Requerente', text: 'Usuário que solicitou a exportação.'},
  { id: 'created_at', title: 'Data da solicitação' },
  { id: 'expiration', title: 'Data de expiração', text: 'Data limite para downlaod do relatório solicitado.' },
  { id: 'size_export', title: 'Tamanho', text: 'Tamanho do arquivo.' },
  { id: 'status_export', title: 'Status' },
  { id: 'actions_export', title: 'Ações' },
];
