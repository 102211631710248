import React, { useEffect, useState } from 'react';
import InputSearch from '../form/InputSearch';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as ChatActions from '../../../store/modules/chat/actions';

function Search({
  search,
  props,
  chatCurrentValueSearched,
  contactCurrentValueSearched,
}) {
  let searchCurrentValue;

  if (props.whoSendAction === 'chat') {
    searchCurrentValue = chatCurrentValueSearched;
  } else if (props.whoSendAction === 'contact') {
    searchCurrentValue = contactCurrentValueSearched;
  }

  const [currentValue, setCurrentValue] = useState(
    searchCurrentValue ? searchCurrentValue : ''
  );
  const [lastTimeout, setLastTimeout] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props.onSearch) {
      props.onSearch(loading);
    }
  }, [loading]);

  const onUpdateSearchValue = (value) => {
    setLoading(true);

    if (lastTimeout) {
      clearTimeout(lastTimeout);
    }

    setCurrentValue(value);

    setLastTimeout(
      setTimeout(() => {
        // Ação que dispara um saga
        // whoSendAction é quem enviou a mensagem - página de chat ou contato
        search(value, props.whoSendAction, props.screen);
        setTimeout(() => {
          setLoading(false);

        }, 1000)
      }, 1000)
    );
  };

  return (
    <InputSearch
      onChange={(e) => onUpdateSearchValue(e.target.value)}
      value={currentValue}
      placeholder={props.placeholder}
      whoSendAction={props.whoSendAction}
      clearSearch={onUpdateSearchValue}
    />
  );
}

const mapStateToProps = (state, ownProps) => ({
  props: ownProps,
  chatCurrentValueSearched: state.chat?.search?.current,
  contactCurrentValueSearched: state.chat?.searchContact?.current,
  searchValue: state.chat?.search?.current,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(ChatActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Search);
