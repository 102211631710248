import React from 'react';
import { Icon } from '@polichat/flamboyant';

const IconMessenger = ({ size }) => {
  return (
    <Icon
      icon='poli-icon pi-messenger-fill'
      color='#0084F4'
      background="-webkit-linear-gradient(39.22deg, #1989FF -21.69%, #8246FF 27.71%, #FF5E73 84.88%)"
      size={size}
    />
  );
};

export default IconMessenger;
