import { Icon } from '@polichat/flamboyant';

interface NotPermissionPageProps {
  helperText?: string;
}

export function NotPermissionPage(props: NotPermissionPageProps) {
  const PERMISSION_TEXT_DEFAULT =
    'Você não possui permissão para acessar esta página.';
  // ---------------------------------------------
  // Transformations
  // ---------------------------------------------
  // Render

  return (
    <div className="disabledMenu">
      <div className="erroPay">
        <Icon icon={'poli-icon pi-sad-fill'} color="var(--danger)" size={86} />
        <p style={{ color: 'red' }}>
          {props.helperText ? props.helperText : PERMISSION_TEXT_DEFAULT}
        </p>
      </div>
    </div>
  );
}
