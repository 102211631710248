import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem;
  padding: 0 1rem;
`;

export const TextInfos = styled.div`
  display: flex;
  flex-direction: column;

  & button {
    margin: 0 0.3rem;
  }

  @media (max-width: 600px) {
    span {
      font-size: 0.9rem;
    }

    & button {
      width: 100%;
      margin: 0.5rem 0;
    }
  }
`;

export const FirstInfo = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  & button {
    display: inline-flex;
    align-items: center;

    & i {
      margin-right: 0.3rem;
    }
  }

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

export const SecondInfo = styled.div`
  margin-top: 0.5rem;
`;

export const ThirdInfo = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 1.1rem;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

export const CardsContainer = styled.div`
  margin: 2rem 0;
  padding: 1rem 0;

  @media (max-width: 600px) {
    margin-top: 1rem;
    padding: 0;
  }
`;

export const FourColumnsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 2rem;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
`;

export const PasswordContainer = styled.div`
  background-color: var(--border-color);
  padding: 3px 1rem;
  border-radius: 5px;
  & span {
    font-weight: bold;
    letter-spacing: 0.2rem;
    font-size: 1.2rem;
  }
`;

export const CheckBoxContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 600px) {
    flex-direction: column;
    margin-top: 2rem;

    & label {
      font-size: 0.9rem;
    }

    & button {
      margin-top: 1rem;
    }
  }
`;
