import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem;
  padding: 0 1rem;
`;

export const StatusContainer = styled.div`
  display: flex;
  margin-top: 20px;
  gap: 5rem;
`;

export const ContentContainer = styled.div`
  margin-top: 5rem;
  display: flex;

  .content {
    flex: 1;
  }
`;

export const ItemAprroved = styled.div`
  display: flex;
  align-items: center;

  margin-bottom: 2rem;
  & span {
    margin-left: 0.75rem;
    font-size: 1.2rem;
  }
`;

export const IconLoading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  & i {
    font-size: 2rem !important;
  }
`;

export const ButtonOpenFB = styled.div`
  & button {
    display: flex;
    align-items: center;
    justify-content: space-around;

    & i {
      margin-right: 0.3rem;
    }
  }
`;

export const CardContainer = styled.div`
  height: auto;
  width: 80%;
  background: var(--secondary-background-color);
  border: 1px solid var(--border-color);
  border-radius: 0.5rem;
  padding: 1rem;
  position: relative;

  h2 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 1rem;
  }

  p {
    font-size: 14px;
    margin: 0;
  }
`;

export const BtnBackContainer = styled.div`
  margin-top: 1rem;
`;
