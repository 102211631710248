import { useEffect, useState } from 'react';

import { Icon } from '@polichat/flamboyant';
import formatText from '../../../../utils/formatText';
import Modal from './CreateModalPreview.component';
import DocumentHeaderPreview from './DocumentHeaderPreview.component';

import {
  BallonContainer,
  Container,
  PreviewBody,
  PreviewButtonsCall,
  PreviewButtonsReply,
  PreviewContainer,
  PreviewFooter,
  PreviewHeader
} from './MessagePreview.styles';
import { IconLogo } from './iconLogo';

function MessagePreview({ data }) {
  const {
    header,
    footer,
    buttons,
    wabaValues,
    listValues,
    buttonsMessageValues,
  } = data;
  const [isWaba, setIsWaba] = useState(false);
  const [isList, setIsList] = useState(false);
  const [isButtonsMessage, setIsButtonsMessage] = useState(false);
  const [enableModal, setEnableModal] = useState(false);

  const [body, setBody] = useState();

  const desactivateModal = () => {
    setEnableModal(false);
  };
  useEffect(() => {
    if (wabaValues?.body) {
      setBody(wabaValues.body);
    }
  }, [wabaValues?.body]);

  useEffect(() => {
    if (buttonsMessageValues?.body) {
      setBody(buttonsMessageValues.body);
    }
  }, [buttonsMessageValues?.body]);

  useEffect(() => {
    if (listValues?.body) {
      setBody(listValues.body);
    }
  }, [listValues?.body]);

  useEffect(() => {
    setIsWaba(data.templateType === 'waba');
    setIsList(data.templateType === 'list');
    setIsButtonsMessage(data.templateType === 'buttonsMessage');
  }, [data.templateType]);

  /*{
    type: 'none'|| 'text|| 'media' ,
    text: string,
    mediaUrl: string,
    mediaFormat: 'IMAGE', 'VIDEO', 'DOCUMENT' >icone file-fill
  }*/

  const renderPreviewHeaderContents = () => {
    if (isWaba || isList || isButtonsMessage) {
      if (!header || !header.type?.length || header.type === 'none') return '';

      // Header of type text
      if (header.type === 'text') return <h1>{header.text}</h1>;

      // mediaUrl is neccessary from here onwards, so if it's invalid, render nothing
      if (
        header.type !== 'media' ||
        !header.mediaUrl ||
        !header.mediaUrl.length
      )
        return '';

      // Header of type media
      switch (header.mediaFormat) {
        case 'IMAGE':
          return <img src={header.mediaUrl} />;

        case 'VIDEO':
          return <video loop autoplay="true" muted src={header.mediaUrl} />;

        case 'DOCUMENT':
          return <DocumentHeaderPreview header={header} />;

        default:
          <>Mídia inválida</>;
          break;
      }
    }
  };

  const renderPreviewFooterContents = () => {
    if (isWaba || isList || isButtonsMessage) {
      return footer?.text;
    }
  };

  return (
    <Container>
      <h1>
        Essa é uma <strong>pré-visualização</strong> da sua mensagem
      </h1>
      <PreviewContainer>
        <header>
          <div>
            {/* <Icon
              icon="poli-icon pi-polichat-logo-fill"
              color={'var(--secondary-75)'}
              size={28}
            /> */}
            <IconLogo />
          </div>
          <span></span>
        </header>
        <div className="body">
          <BallonContainer>
            <PreviewHeader>{renderPreviewHeaderContents()}</PreviewHeader>
            {isList && (
              <PreviewBody
                dangerouslySetInnerHTML={{
                  __html: formatText(listValues.body?.text),
                }}
              ></PreviewBody>
            )}
            {isWaba && (
              <PreviewBody
                dangerouslySetInnerHTML={{
                  __html: formatText(wabaValues.body?.text),
                }}
              ></PreviewBody>
            )}
            {isButtonsMessage && (
              <PreviewBody
                dangerouslySetInnerHTML={{
                  __html: formatText(buttonsMessageValues.body?.text),
                }}
              ></PreviewBody>
            )}
            {!isWaba && !isList && !isButtonsMessage && (
              <PreviewBody
                dangerouslySetInnerHTML={{
                  __html: formatText(body?.text),
                }}
              ></PreviewBody>
            )}
            <PreviewFooter>{renderPreviewFooterContents()}</PreviewFooter>
            <span>Agora</span>
          </BallonContainer>
          {buttons[0]?.type === 'reply' && (
            <PreviewButtonsReply>
              {buttons?.map((button) => (
                <div key={button.id}>
                  <span>{button.text || ''}</span>
                </div>
              ))}
            </PreviewButtonsReply>
          )}
          <Modal
            list={data.listValues}
            open={enableModal}
            close={desactivateModal}
          ></Modal>
          {isList && (
            <PreviewButtonsCall>
              <div
                onClick={() => {
                  setEnableModal(true);
                }}
              >
                <Icon icon={`poli-ícone pi-barmenu-fill`} size={18} />
                <span>{listValues.button?.text}</span>
              </div>
            </PreviewButtonsCall>
          )}
          {buttons[0]?.type === 'action' && (
            <PreviewButtonsCall>
              {buttons?.map((button) => (
                <div key={button.id}>
                  <Icon
                    icon={`poli-icon pi-${
                      button.actionType === 'link' ? 'external-link' : 'phone'
                    }-line`}
                    size={18}
                  />
                  <span>{button.text || ''}</span>
                </div>
              ))}
            </PreviewButtonsCall>
          )}
        </div>
        <footer>
          <div>
            <Icon
              icon="poli-icon pi-smille-line"
              size={22}
              color={'var(--secondary-font-color)'}
            />
            <Icon
              icon="poli-icon pi-attach-line"
              size={22}
              color={'var(--secondary-font-color)'}
            />
            <Icon
              icon="poli-icon pi-photo-fill"
              size={22}
              color={'var(--secondary-font-color)'}
            />
          </div>
          <div>
            <Icon
              icon="poli-icon pi-microphone-fill"
              size={24}
              color={'var(--white)'}
            />
          </div>
        </footer>
      </PreviewContainer>
    </Container>
  );
}

export default MessagePreview;
