const ChannelCustomerStatusCodes = {
  OK: 200,
  BAD_REQUEST: 400,
  CREATED: 201,
  NOT_FOUND: 404,
  MISSING_PARAMS: 422,
  NOT_ACCEPTABLE: 406,
};

export default ChannelCustomerStatusCodes;
