import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Link, useRouteMatch } from 'react-router-dom';
import { Icon, Button, Alert } from '@polichat/flamboyant';

import PageMainHeader from '../../../common/pages/PageMainHeader/PageMainHeader.component';
import PageMainBody from '../../../common/pages/PageMainBody/PageMainBody.component';

import history from '../../../../services/history';
import poliConfig from '../../../../utils/poliConfig';
import Animation from '../../../common/animation';
import {
  Container,
  ChannelListContainer,
  IllustrationContainer,
  ChannelItemContainer,
  ChannelInfosContainer,
  LogoInfoContainer,
  TextInfoContainer,
  ActionButtonContainer,
} from './AddChannel.styles';
import IconMessenger from '../../common/IconMessenger.component';
import { useCustomerVerifyChannelPlan } from './useCustomerVerifyChannelPlan';
import { ModalHireAdditionalChannel } from './ModalHireAdditionalChannel';
import { Illustration } from 'src/assets/illustratorElement/illustrators';

const AddChannel = () => {
  const currentCustomer = useSelector(
    (state) => state?.general?.current_customer_id
  );

  const whiteLabelConfig = useSelector((state) => state?.whitelabel?.config);

  const {
    loading: loadingCustomerChannelPlanInfo,
    error: errorVerifyCustomerChannelPlanInfo,
    channelPlanInfo,
    blocked,
    verifyCustomerCanAddChannel,
  } = useCustomerVerifyChannelPlan();

  const [
    isModalHireChannelAdditionalOpen,
    setIsModalHireChannelAdditionalOpen,
  ] = useState(false);

  const [selectedChannelOptions, setSelectedChannelOptions] = useState();

  const enableBSP =
    poliConfig?.bsp_enable ||
    poliConfig?.bsp_enable_custumer?.includes(Number(currentCustomer));

  // verifica se está na lista de customer liberados

  const { url } = useRouteMatch();

  const ChannelBSP = {
    id: 6,
    type: 'WhatsApp',
    icon: 'poli-icon pi-whatsapp-business-fill',
    color: '#00C48C',
    description:
      'Esse é a integração utilizada com o Meta para criar uma conta no WhatsApp Business API. Com isso você poderá utilizar os serviços oferecidos pelo Grupo Meta, como envio de Templates pré-aprovados.',
    action: `${url}/waba-by-poli`,
    additional_channel_id: 3,
    api: 10,
    canDisplay: whiteLabelConfig?.is_white_label ? whiteLabelConfig?.channels?.includes('waba-by-poli') : true,
  };

  let channelsDefault = [
    // {
    //   id: 6,
    //   type: enableBSP ? 'Whatsapp By 360' : 'Whatsapp Business API',
    //   icon: 'poli-icon pi-whatsapp-business-fill',
    //   color: '#00C48C',
    //   description:
    //     'Centralize seus atendimentos em um único número homologado pelo Facebook, com a possibilidade de colocar múltiplos atendentes, distribuir entre departamentos e criar sistema de carteiras.',
    //   action: `${url}/waba`,
    //   additional_channel_id: 3,
    // },

    {
      id: 1,
      type: 'WhatsApp',
      icon: 'poli-icon pi-whatsapp-fill',
      color: '#00C48C',
      description:
        'Centralize seus atendimentos em um único número de WhatsApp, com a possibilidade de colocar múltiplos atendentes, distribuir entre departamentos e criar sistema de carteiras.',
      action: `/tools/channels/add/broker`,
      additional_channel_id: 2,
      canDisplay: whiteLabelConfig?.is_white_label ? whiteLabelConfig?.channels?.includes('broker') : false,
    },

    {
      id: 5,
      type: 'Instagram',
      icon: 'poli-icon pi-instagram-fill',
      color: '#ff647c',
      description:
        'Integre a sua página do Instagram na plataforma do Polichat e atenda aos seus clientes com a possibilidade de colocar vários atendentes de forma simultânea.',
      // action: `${url}/instagram`,
      action: `${poliConfig.poliAppLaravelUrl}/channels/${currentCustomer}/instagram/create`,
      additional_channel_id: 6,
      canDisplay: whiteLabelConfig?.is_white_label ? whiteLabelConfig?.channels?.includes('instagram') : true,
    },
    {
      id: 4,
      type: 'Messenger',
      icon: 'poli-icon pi-messenger-fill',
      color: '#0084F4',
      description:
        'Integre a sua página do Facebook na plataforma do Polichat e atenda aos seus clientes com a possibilidade de colocar vários atendentes de forma simultânea.',
      action: `${url}/messenger`,
      additional_channel_id: 4,
      canDisplay: whiteLabelConfig?.is_white_label ? whiteLabelConfig?.channels?.includes('messenger') : true,
    },
    {
      id: 2,
      type: 'Webchat',
      icon: 'poli-icon pi-webchat-fill',
      color: '#FFCA5D',
      description:
        'Com o Polichat você tem um chat para colocar no seu site e falar com os clientes que estão navegando pelas suas páginas ou plataforma.',
      action: `${url}/webchat`,
      additional_channel_id: 5,
      canDisplay: whiteLabelConfig?.is_white_label ? whiteLabelConfig?.channels?.includes('webchat') : true,
    },
  ];

  const [channels, setChannels] = useState(channelsDefault);

  useEffect(() => {
    if (enableBSP) {
      const _channels = [...channelsDefault];
      _channels.unshift(ChannelBSP);
      setChannels([..._channels]);
    }
  }, [enableBSP]);

  async function handleChannelAddClick(action, type, additional_channel_id) {
    // se canal é broker vai para tela de integração
    /*
    if (type === 'WhatsApp') {
      history.push({ pathname: action });
      return;
    }
    */

    const {
      should_buy: customerShouldBuyPlan,
      error,
      blocked: customerAreBlockedByOverDue,
    } = await verifyCustomerCanAddChannel(
      currentCustomer,
      additional_channel_id
    );
    setSelectedChannelOptions({ type, action });

    //customer inadimplente
    if (customerAreBlockedByOverDue) {
      setIsModalHireChannelAdditionalOpen(true);
      return;
    }

    //verificar se precisar comprar plano adicional
    if (customerShouldBuyPlan) {
      setIsModalHireChannelAdditionalOpen(true);
    } else if (!error) {
      redirectAddChannelPage(type, action);
    }
  }

  function redirectAddChannelPage(type, action) {
    // se instagram - redireciona  para tela do laravel
    if (type === 'Instagram') {
      window.location.assign(action);
      return;
    }
    // continua com a criação de canal na SPA
    history.push({
      pathname: action,
      state: {
        validated_can_add_channel: true,
      },
    });
    return;
  }

  const handleCloseModalHireAndRedirect = () => {
    redirectAddChannelPage(
      selectedChannelOptions.type,
      selectedChannelOptions.action
    );
  };

  return (
    <>
      <PageMainHeader
        title="Adicionar Canal"
        icon="poli-icon pi-channels-line"
        iconSize="30"
      />

      <PageMainBody>
        {loadingCustomerChannelPlanInfo && (
          <div style={{ margin: '1rem' }}>
            <Alert color="info" style={{ padding: '0.5rem' }}>
              <Animation icon="loading" size="searchTiny" />
              <span style={{ flexGrow: 2, margin: 'auto', marginLeft: '1rem' }}>
                Carregando informações sobre o seu plano!
              </span>
            </Alert>
          </div>
        )}
        {!loadingCustomerChannelPlanInfo &&
          errorVerifyCustomerChannelPlanInfo && (
            <div style={{ margin: '1rem' }}>
              <Alert color="danger" style={{ padding: '0.5rem' }}>
                <Icon icon="poli-icon pi-sad-line" size={40} />
                <span
                  style={{ flexGrow: 2, margin: 'auto', marginLeft: '1rem' }}
                >
                  Erro ao buscar informações sobre o seu plano, tente novamente!
                </span>
              </Alert>
            </div>
          )}
        <ChannelListContainer>
          {channels.map(
            ({
              id,
              type,
              icon,
              color,
              description,
              action,
              additional_channel_id,
              api,
              canDisplay
            }) => canDisplay && (
              <ChannelItemContainer key={id}>
                <ChannelInfosContainer>
                  <LogoInfoContainer>
                    {id == 4 ? (
                      <IconMessenger size={40} />
                    ) : (
                      <Icon icon={icon} size={38} color={color} />
                    )}

                    <span>{type}</span>
                  </LogoInfoContainer>

                  <TextInfoContainer>
                    <span>{description}</span>
                  </TextInfoContainer>

                  <ActionButtonContainer>
                    {type === 'Instagram' ? (
                      <div style={{ textDecoration: 'none' }}>
                        <Button
                          color="primary"
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                          // onClick={() => window.location.assign(action)}
                          onClick={() =>
                            handleChannelAddClick(
                              action,
                              type,
                              additional_channel_id
                            )
                          }
                          disabled={loadingCustomerChannelPlanInfo}
                        >
                          <Icon icon={'poli-icon pi-add-line'} size={15} />

                          <span>Adicionar Canal</span>
                          <Icon
                            icon={'poli-icon pi-simple-arrow-right-line'}
                            size={13}
                          />
                        </Button>
                      </div>
                    ) : (
                      // <Link style={{ textDecoration: 'none' }} to={action}>
                      <div
                        data-tour={
                          api === 10 ? 'HelpHero_Migrate_WhatsApp_Step02' : ''
                        }
                      >
                        <Button
                          // color={type === 'WhatsApp' ? 'secondary' : 'primary'}
                          color="primary"
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                          onClick={() =>
                            handleChannelAddClick(
                              action,
                              type,
                              additional_channel_id
                            )
                          }
                          disabled={loadingCustomerChannelPlanInfo}
                        >
                          <Icon icon={'poli-icon pi-add-line'} size={15} />
                          <span>Adicionar Canal</span>
                          <Icon
                            icon={'poli-icon pi-simple-arrow-right-line'}
                            size={13}
                          />
                        </Button>
                      </div>
                      // </Link>
                    )}
                  </ActionButtonContainer>
                </ChannelInfosContainer>

                <hr />
              </ChannelItemContainer>
            )
          )}
        </ChannelListContainer>
        <Illustration imgName="add-channel-illustration" />
      </PageMainBody>

      {isModalHireChannelAdditionalOpen && (
        <ModalHireAdditionalChannel
          modalIsOpen={isModalHireChannelAdditionalOpen}
          toggleModal={() =>
            setIsModalHireChannelAdditionalOpen((prev) => !prev)
          }
          channelPlanInfo={channelPlanInfo}
          closeModalAction={handleCloseModalHireAndRedirect}
          customerId={currentCustomer}
          blocked={blocked}
        />
      )}
    </>
  );
};

export default AddChannel;
