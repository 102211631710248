import { actionNames } from './actions';
import checkAvatar from '../../../utils/checkAvatar';
import jwt from 'jwt-decode';

import {
  getTypeChannel,
} from '../chat/functions';

export default function general(state = {}, action) {
  switch (action.type) {
    case actionNames.STORE_SPA_API: {
      let dataSocket = jwt(action.payload.auth_token);
      dataSocket = dataSocket.poli_data.user;
      const userId = dataSocket.id;
      const customerId = dataSocket.customer_id;

      return {
        ...state,
        spa_api: action.payload,
        user_id: userId,
        customer_id: customerId,
      };
    }

    case actionNames.STORE_SPA_API_AUTH_TOKEN: {
      let tempState = { ...state };
      if (!tempState.api) tempState.api = {};
      tempState.spa_api.auth_token = action.payload;
      return tempState;
    }

    case actionNames.STORE_USER_DATA_ON_GENERAL: {
      action.payload.channels.map(channel => {
        channel.type = getTypeChannel(channel);
      })

      // Se vim canais repetidos no websocket
      let channels = [];
      channels = action?.payload?.channels?.filter((e, i) => {
        return action.payload.channels.indexOf(e) === i;
      });

      action.payload.channels = channels;

      return {
        ...state,
        user: action.payload,
      }
    }

    case actionNames.UPDATE_USER_DATA_ON_GENERAL: {
      let _dataUser = action.payload;
      if (state?.user?.id == _dataUser.id) {
        const _newData = { ...state.user,  ..._dataUser };
        _newData.picture = checkAvatar(_newData.name, _newData.picture);
        return {
          ...state,
          user: _newData,
        }
      }
      return { ...state }
    }

    case actionNames.SET_CURRENT_CUSTOMER_ID: {
      window.localStorage.setItem('spa_current_customer_id', action.payload);
      return {
        ...state,
        current_customer_id: action.payload,
      }
    }

    case actionNames.CHANGE_USER_STATUS: {
      let _newData = state.user;

      _newData.status = action.payload
      return {
        ...state,
        user: _newData,
      }
    }

    default:
      return state;
  }
}
