import { useCallback, useState } from 'react';
import { MetaService } from '../../../../../services/meta-business/meta';

export interface UseRegisterMetaServiceProps {
  phone_id: number;
}
export function useRegisterMetaService(props: UseRegisterMetaServiceProps) {
  const [loading, setLoading] = useState(false);

  // ---------------------------------------------
  // Functions

  const register = useCallback(
    async (onSuccess: () => void, onError: (value?: string) => void) => {
      try {
        setLoading(true);
        const response = await MetaService.Register(props.phone_id);
        onSuccess();
        setLoading(false);
      } catch (error) {
        // @ts-ignore
        onError(error?.response?.data?.message ? error?.response?.data?.message : null );
        setLoading(false);
      }
    },
    [props.phone_id]
  );

  // ---------------------------------------------
  // Effects
  // ---------------------------------------------
  // Transformations
  // ---------------------------------------------
  // API

  return {
    loading,
    register,
  };
}
