import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import * as Sentry from '@sentry/browser';

import { MessageVariables, QuickTypes } from '../../types/constants';
import { ChatStore, GeneralDepartment } from '../../types/store';
import { QuickMessage, QuickMessageSelectorOptions } from '../../types/types';

import { getMessageBuilderTemplateText } from '../../../functions/getMessageBuilderTemplateText';
import { parseMessageTemplate } from '../../../../../../../../utils/chat/parseMessageTemplate';
import { isMessageButtonsType, isMessageListOptionType } from '../utils';

export function useQuickMessage() {
  /**
   * OpenedChat - Redux Store
   * informações do chat que está aberto na tela
   */
  const chatContact = useSelector((state: any) => {
    const chat =
      state?.chat?.chats?.filter((chat: any) => {
        return chat.open === true;
      })[0] || [];
    return chat?.contact ?? null;
  }) as ChatStore['contact'];
  const chatProtocolStatus = useSelector((state: any) => {
    const chat =
      state?.chat?.chats?.filter((chat: any) => {
        return chat.open === true;
      })[0] || [];
    return chat?.loading_protocol ?? null;
  }) as ChatStore['loading_protocol'];

  const chatProtocolNumber = useSelector((state: any) => {
    const chat =
      state?.chat?.chats?.filter((chat: any) => {
        return chat.open === true;
      })[0] || [];
    return chat?.protocol_number ?? null;
  }) as ChatStore['protocol_number'];

  const chatDepartmentId = useSelector((state: any) => {
    const chat =
      state?.chat?.chats?.filter((chat: any) => {
        return chat.open === true;
      })[0] || [];
    return chat?.chat?.department_id ?? null;
  }) as number | null;

  const chatOperatorName = useSelector(
    (state: any) => state?.chat?.config?.user?.name ?? ''
  ) as string;

  /**
   * Department - Redux Store
   * Lista de departamentos ativos da empresa
   */
  const customerDepartments = useSelector(
    (state: any) => state?.chat?.config?.general.departments
  ) as GeneralDepartment[];

  // return true case user is adm or quick message owner
  const isQuickMessageEditable = useCallback(
    (
      messageUserID: QuickMessage['user_id'],
      userRole: string,
      userId: number | null
    ) => {
      if (
        userRole === 'adm' ||
        userRole === 'manager' ||
        messageUserID === userId
      ) {
        return true;
      }

      return false;
    },
    []
  );

  // return the message type
  const getMessageType = useCallback((message: QuickMessage) => {
    if (isMessageButtonsType(message.template_name ?? '')) {
      return QuickTypes.BUTTONS;
    }
    if (isMessageListOptionType(message.template_name ?? '')) {
      return QuickTypes.LIST_OPTION;
    }

    if (message.template !== null) {
      return QuickTypes.TEMPLATE;
    }

    return QuickTypes.QUICK;
  }, []);

  /**
   * Retorna o valor convertido para texto no caso de template, substituindo também as variaveis
   * @param {message} QuickMessage
   * @param {type} QuickMessageSelectorOptions
   * @return {text} string
   */
  function getMessageTextUI(
    message: string,
    type: QuickMessageSelectorOptions
  ) {
    switch (type) {
      case QuickTypes.TEMPLATE:
      case QuickTypes.LIST_OPTION:
      case QuickTypes.BUTTONS:
        return _replaceVariables(getMessageBuilderTemplateText(message) ?? '');
      default:
        return _replaceVariables(message ?? '');
    }
  }

  /**
   * return the message with the text converted if has variables
   * @param message
   * @returns
   */
  function getMessageTreated(message: QuickMessage) {
    const messageType = getMessageType(message);
    // utiliza a função para verificar se quickmensagem foi criar no message builder
    const originalTemplateObject = parseMessageTemplate(message.text);
    let handledMessage = { ...message };

    // mensagem que não foram criadas pelo MessageBuilder o campo *TEXT* preenchido com o texto puro da mensagem
    if (!originalTemplateObject.isFromMessageBuilder) {
      handledMessage['text'] = getMessageTextUI(message.text, messageType);
      return handledMessage;
    }

    // outras mensagens possui o JSON do template/lista/botões no campo *TEXT* que precisa ser convertido para substiruir as variaveis antes do envio da mensagem e transformado novamente em JSON
    try {
      const finalText = {
        ...originalTemplateObject.parsedMessage!,
        body: getMessageTextUI(
          originalTemplateObject.parsedMessage?.body as unknown as string,
          messageType
        ),
      };

      // alterar as variaveis no campo header do template se este for do tipo texto
      if (
        originalTemplateObject.hasHeader &&
        originalTemplateObject.parsedMessage?.header?.type === 'text'
      ) {
        const textHeader = getMessageTextUI(
          originalTemplateObject.parsedMessage?.header.text,
          messageType
        );
        finalText.header.text = textHeader;
      }

      handledMessage['text'] = JSON.stringify(finalText);
      return handledMessage;
    } catch (error) {
      console.error(
        'Erro ao converter o JSON de uma quickmessage >> id: ${message.id}',
        error
      );
      Sentry.captureMessage(
        `Erro ao converter o JSON de uma quickmessage >> id: ${message.id}`
      );
      Sentry.captureException(error);
      // retornar a mensagem sem tratamento
      return message;
    }
  }
  function _replaceVariables(text: string) {
    const bracketedTextRegex = /\[.*?\]/;
    if (!bracketedTextRegex.test(text)) {
      return text;
    }

    const currentDate = _getCurrentDateTime();
    const departmentName = _getDepartmentName();
    const currentProtocol = _getProtocolNumber();
    const currentContactName = chatContact.name ?? '';
    const currentContactEmail = chatContact.email ?? '';
    const currentContactPhone = chatContact.phone ?? '';

    return text
      .replaceAll(MessageVariables.CONTACT_NAME, currentContactName || '')
      .replaceAll(
        MessageVariables.CONTACT_FIRST_NAME,
        currentContactName?.split(' ')[0] || ''
      )
      .replaceAll(MessageVariables.CONTACT_EMAIL, currentContactEmail || '')
      .replaceAll(MessageVariables.CONTACT_PHONE, currentContactPhone || '')
      .replaceAll(MessageVariables.OPERATOR_NAME, chatOperatorName || '')
      .replaceAll(MessageVariables.DEPARTMENT_NAME, departmentName || '')
      .replaceAll(MessageVariables.DATE_CURRENT, currentDate.date)
      .replaceAll(MessageVariables.TIME_CURRENT, currentDate.time)
      .replaceAll(MessageVariables.PROTOCOL_NUMBER, currentProtocol);
  }
  const _getCurrentDateTime = useCallback(() => {
    const date_current = new Date();
    const nDay = date_current.getDate();
    const nMonth = date_current.getMonth() + 1;
    const nHour = date_current.getHours();
    const nMinutes = date_current.getMinutes();

    const date = `${nDay < 10 ? '0' : ''}${nDay}/${
      nMonth < 10 ? '0' : ''
    }${nMonth}/${date_current.getFullYear()}`;

    const time = `${nHour < 10 ? '0' : ''}${nHour}:${
      nMinutes < 10 ? '0' : ''
    }${nMinutes}`;

    return { date, time };
  }, []);
  function _getProtocolNumber() {
    if (chatProtocolStatus) return '...';
    if (chatProtocolNumber) return String(chatProtocolNumber);
    return 'Protocolo Inexistente';
  }

  const _getDepartmentName = useCallback(() => {
    if (!chatDepartmentId || !customerDepartments)
      return 'Contato sem Departamento';
    const department = customerDepartments?.find(
      (dept) => dept?.id === chatDepartmentId
    );
    return department ? department.name : 'Contato sem Departamento';
  }, [customerDepartments, chatDepartmentId]);

  return {
    getMessageType,
    getMessageTextUI,
    getMessageTreated,
    isQuickMessageEditable,
  };
}
