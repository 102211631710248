export type FormattingStyle = 'bold' | 'italic' | 'strikethrough' | 'code';

const FormattingStyles: Record<FormattingStyle, string> = {
  bold: '*',
  italic: '_',
  strikethrough: '~',
  code: '```',
};

const Whitespace = ' ';

export function applyStyle(text: string, style: FormattingStyle): string {
  if (!FormattingStyles[style]) {
    return text;
  }

  return `${FormattingStyles[style]}${text}${FormattingStyles[style]}`;
}

export function removeWhitespaceFromSelectionStart(
  text: string,
  selectionStart: number,
  selectionEnd: number
): number {
  while (
    selectionStart <= selectionEnd &&
    text[selectionStart] === Whitespace
  ) {
    selectionStart++;
  }

  return selectionStart;
}

export function removeWhitespaceFromSelectionEnd(
  text: string,
  selectionStart: number,
  selectionEnd: number
): number {
  while (
    selectionEnd >= selectionStart &&
    text[selectionEnd - 1] === Whitespace
  ) {
    selectionEnd--;
  }

  return selectionEnd;
}
