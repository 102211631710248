import React, { useRef, useEffect } from 'react';
import { Button, Icon } from '@polichat/flamboyant';
import { FaTimes } from 'react-icons/fa';

const TaskSecondStep = ({
  date,
  time,
  handleChange,
  nextStep,
  cancelScheduling,
}) => {
  const dateRef = useRef(null);
  const timeRef = useRef(null);

  // Função auxiliar para obter a data atual em formato YYYY-MM-DD
  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  // Função auxiliar para obter a hora atual no formato HH:MM
  const getCurrentTime = () => {
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
  };

  // Verificação se a data e hora são válidas
  const isDateTimeValid = (date, time) => {
    if (!date || !time) {
      return false; // Se algum dos campos não estiver preenchido, retorna false
    }
    const selectedDate = new Date(`${date}T${time}`);
    const now = new Date();
    return selectedDate > now;
  };

  // Função para manusear mudança no campo de horário
  const handleTimeChange = (e) => {
    handleChange(e);
    // Fecha o seletor de horário desfocando o campo de entrada
    if (timeRef.current) {
      timeRef.current.blur();
    }
  };

  return (
    // <div className='textBoxContainer'>

    <div className="schedule-review textBoxContainer">
      <button className="close-button" onClick={cancelScheduling}>
        <FaTimes size={20} />
      </button>

      <div className="d-flex flex-column main-text">
        <span className="icon-text-container">
          <Icon
            icon="poli-icon pi-day-fill"
            size={20}
            className="aligned-icon"
          />
          <strong className="aligned-text">
            Defina a data e o horário que a mensagem deverá ser enviada
          </strong>
        </span>
        <div className="message-container">
          <label htmlFor="date"></label>
          <input
            type="date"
            id="date"
            name="date"
            className="mr-3"
            value={date}
            onChange={handleChange}
            required
            min={getTodayDate()}
            ref={dateRef}
          />

          <label htmlFor="time"></label>
          <input
            type="time"
            id="time"
            name="time"
            value={time}
            onChange={handleTimeChange}
            required
            min={date === getTodayDate() ? getCurrentTime() : '00:00'} // Define a hora mínima para hoje
            ref={timeRef}
          />
        </div>
      </div>
      <div className="buttons nextbutton">
        <Button
          onClick={() => {
            if (!date || !time) {
              alert('Por favor, preencha ambos os campos de data e hora.');
            } else if (isDateTimeValid(date, time)) {
              nextStep();
            } else {
              alert('A data e hora selecionadas devem ser no futuro.');
            }
          }}
        >
          Próximo
        </Button>
      </div>
    </div>
  );
};

export default TaskSecondStep;
