export function wabaChannelHasCredits(
  channelId: number,
  data: any,
) {
  /**
   * Contas Wabas são separadas por grupos
   * https://poli-digital.atlassian.net/browse/PS-1576
   */
  const groupId = data[channelId].groupId ?? null
  if (!groupId) return false;

  const groupCredits = data.wabaExpenditureGroups[groupId];
  if(!groupCredits) return false;

  return groupCredits.used < groupCredits.total
}
