import { InfoAllByWabaId } from '../types/InfoAllByWabaId';
import { ReturnResponse } from '../types/ReturnResponse';

const mountReturnResponse = (
  infoAllData: InfoAllByWabaId[]
): ReturnResponse[] => {
  const _data: ReturnResponse[] = [];

  for (let i = 0; i < infoAllData.length; i++) {
    const item = infoAllData[i];

    if (item?.on_behalf_of_business_info) {
      const addBussiness = {
        name: item.on_behalf_of_business_info.name,
        id: item.on_behalf_of_business_info.id,
        status: item.on_behalf_of_business_info.status,
      };

      const addWabaAccount = {
        id: item?.id,
        name: item?.name,
        phone_numbers: item?.phone_numbers?.data ?? [],
      };

      const found = _data.findIndex((e) => e.id === addBussiness.id);
      if (found >= 0) {
        if (item?.id) {
          _data[found].waba_accounts.push(addWabaAccount);
        }
      } else {
        const add = {
          ...addBussiness,
          waba_accounts: item?.id ? [addWabaAccount] : [],
        };
        _data.push(add);
      }
    }
  }

  return _data;
};

export { mountReturnResponse };
